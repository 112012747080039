import { midGray3 } from '../../colors';

export default {
    tab: {
        color: midGray3,
        fontWeight: 200,
        fontSize: 15,
        textTransform: 'capitalize',
    },
    tabFlat: {
        height: '100%',
        lineHeight: '55px',
        borderRadius: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    link: {
        textDecoration: 'none',
    },
};
