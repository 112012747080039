import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import messages from '../messagesManageAccounts';
import Account from '../components/Account';
import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';

import { getAccountDataApi, submitAccountDataApi, deleteAccountApi } from '../../apiAdmin';
import styles from '../../stylesAdmin';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import { adminDataChanged } from '../../../Shopping/ShoppingSearch/actionsShoppingSearch';
import { safeDecode } from '../../../utils';

const FEES_MAX = 2;

const AccountForm = ({
    match,
    settings,
    intl,
    onAdminDataChanged,
    history,
}) => {
    const [stateData, setStateData] = useState(
        fromJS({
            account: {},
            isFetching: true,
            isProcessingSubmit: false,
            isProcessingDelete: false,
            alertText: '',
        }),
    );

    const transformAccountData = (data) => {
        let account = {}; // eslint-disable-line prefer-const
        if (Object.keys(data).length > 1) {
            if (data.AccountCustomData && data.AccountCustomData.length > 0) {
                account.hasCustomInfo = true;
                account.customInformationItems = data.AccountCustomData
                    .sort((a, b) => {
                        if (a.AccountCustomDataType < b.AccountCustomDataType) return -1;
                        if (a.AccountCustomDataType > b.AccountCustomDataType) return 1;
                        return 0;
                    })
                    .map((item) => ({
                        type: item.AccountCustomDataType,
                        value: item.AccountCustomDataValue,
                        required: item.AccountCustomDataRequired,
                    }));
            }

            if (data.AccountManagementAssocUsers) {
                account.associatedUsers = data.AccountManagementAssocUsers;
            }

            account.context = data.Context || '';
            account.name = data.Label;
            account.origName = account.name;
            account.address = {
                countryCode:
                    data.AccountManagementDefaultCountryCode
                        && data.AccountManagementDefaultCountryCode !== 'None'
                        && data.AccountManagementDefaultCountryCode !== 'null'
                        ? data.AccountManagementDefaultCountryCode
                        : null,
                address1: data.AccountManagementDefaultAddress1 || '',
                address2: data.AccountManagementDefaultAddress2 || '',
                city: data.AccountManagementDefaultCity || '',
                stateOrProvince: data.AccountManagementDefaultStateOrProvinceCode || '',
                postalCode: data.AccountManagementDefaultPostalCode || '',
            };
            account.contact = {
                phone: data.AccountManagementDefaultPhone || '',
                email: data.AccountManagementDefaultEmail || '',
            };
            account.fareSearchAllowed = data.AccountManagementFareSearchAllowed;
            account.merchantAccountId = data.AccountManagementMerchantAccountId || '';

            if (data.AccountManagementFeeTypeEnumVal1 !== 'none' || data.AccountManagementFeeTypeEnumVal2 !== 'none') {
                account.fees = [];
                account.hasFees = true;
                for (let i = 1; i <= FEES_MAX; ++i) {
                    account.fees.push({
                        type: data[`AccountManagementFeeTypeEnumVal${i}`],
                        amount: data[`AccountManagementFeeTypeAmount${i}`],
                        minValue: data[`AccountManagementFeeTypeMinAmount${i}`],
                    });
                }
            }

            account.isEditMode = true;
        }
        return account;
    };

    useEffect(() => {
        const accountName = match.params?.accountName ? safeDecode(match.params?.accountName) : '';
        getAccountDataApi(
            { accountName, getAllData: true },
            (response) => {
                setStateData((state) => state.merge({
                    alertText: response.errorResponse.message,
                    isFetching: false,
                }));
            },
            (response) => {
                const { data } = response.successResponse;
                setStateData((state) => state.merge({
                    account: transformAccountData(data),
                    isFetching: false,
                }));
            },
        );
    }, [match.params?.accountName]);

    const handleHomeRedirect = () => {
        history.goBack();
    };

    const handleRedirect = () => {
        setTimeout(() => {
            handleHomeRedirect();
        }, 1000);
    };

    const handleDelete = (accountName) => {
        setStateData((state) => state.merge({ isProcessingDelete: true }));
        deleteAccountApi(accountName, (response) => {
            setStateData((state) => state.merge({
                alertText: response.errorResponse.message,
                isProcessingDelete: false,
            }));
        }, () => {
            setStateData((state) => state.merge({
                alertText: intl.formatMessage(messages.accountDeleted),
            }));
            onAdminDataChanged();
            handleRedirect();
        });
    };

    const handleSubmit = (account) => {
        setStateData((state) => state.merge({ isProcessingSubmit: true }));
        submitAccountDataApi(
            account,
            (response) => {
                setStateData((state) => state.merge({
                    alertText: response.errorResponse.message,
                    isProcessingSubmit: false,
                }));
            },
            () => {
                setStateData((state) => state.merge({
                    alertText: state.getIn(['account', 'isEditMode'])
                        ? intl.formatMessage(messages.accountSaved)
                        : intl.formatMessage(messages.accountCreated),
                }));
                onAdminDataChanged();
                handleRedirect();
            },
        );
    };

    const handleSnackbarClose = () => {
        setStateData((state) => state.merge({ alertText: '' }));
    };

    return (
        <div id="Account">
            {!stateData.get('isFetching') && (
                <Account
                    data-testid="account"
                    account={stateData.get('account')}
                    delete={handleDelete}
                    submit={handleSubmit}
                    redirect={handleHomeRedirect}
                    settings={settings}
                    isProcessingAction={stateData.get('isProcessingSubmit') || stateData.get('isProcessingDelete')}
                />
            )}
            <RefreshIndicator
                size={36}
                status={(stateData.get('isFetching') || stateData.get('isProcessingSubmit')) ? 'loading' : 'hide'}
                style={styles.indicator}
            />
            <ExtendedSnackbar
                id="srtAccountSnackBar"
                open={stateData.get('alertText') !== ''}
                message={stateData.get('alertText')}
                onClose={handleSnackbarClose}
            />
        </div>
    );
};

AccountForm.propTypes = {
    match: PropTypes.object,
    settings: PropTypes.object,
    intl: PropTypes.object.isRequired,
    onAdminDataChanged: PropTypes.func.isRequired,
    history: PropTypes.object,
};

export { AccountForm as AccountFormAlias };

export default injectIntl(
    connect((state) => ({ settings: state.get('settings') }), { onAdminDataChanged: adminDataChanged })(AccountForm),
);
