export default {
    checkbox: {
        width: 'auto',
        marginRight: 5,
    },
    label: {
        display: 'inline',
        position: 'relative',
    },
};
