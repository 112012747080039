import validate from 'validate.js';
// eslint-disable-next-line import/no-cycle
import { sendEmailConfirmationApi } from './apiBooking';

export const confirmationOptions = {
    manual: 'MANUAL',
    none: 'NONE',
    manualEach: 'MANUALEACH',
    confirmed: 'CONFIRMED',
    manualNoInfo: 'MANUALNOCONFINFO',
    creditCard: 'CREDITCARD',
    debitCard: 'DEBITCARD',
    loyaltyCard: 'LOYALTYCARD',
    disabled: 'DISABLED',
};

export const findConfirmationValues = (type, confirmationInfo) => (confirmationInfo.BookingPaymentConfirmationOptions.find((item) => (
    item.BookingPaymentConfirmationType === type
)));

export const getFareRules = (segment, fareRulesDetails) => (
    fareRulesDetails?.filter(
        (rule) => rule?.BookingFareRulesTravelSegments?.includes(segment)
        || rule?.travelSegments?.includes(segment)
        || rule?.travelSubSegments?.includes(segment)
        || rule?.BookingFareRulesTravelSubSegments?.includes(segment),
    )
);

export const findCard = (method, allMethods) => {
    const card = allMethods.find((cardTemp) => cardTemp.BookingPaymentCode === method);
    if (!validate.isEmpty(card)) {
        return card;
    }
    return null;
};

export const handleAutoEmailConfirmation = (userEmail, booking, orderId) => {
    const primaryName = booking.BookingPrimaryPaxFirstName;
    const primaryLastName = booking.BookingPrimaryPaxLastName;
    const primaryPassenger = booking.BookingPassengers.find((passenger) => (
        passenger.BookingPaxFirstName.indexOf(primaryName) !== -1 && passenger.BookingPaxLastName.indexOf(primaryLastName) !== -1
    ));

    let email = userEmail !== '' ? userEmail : '';
    if (primaryPassenger !== undefined && primaryPassenger !== null && primaryPassenger !== '') {
        email = primaryPassenger.BookingPaxContactEmailAddresses[0].BookingPaxContactInfo;
    }
    return sendEmailConfirmationApi(
        {
            booking,
            email,
            orderId,
        },
        (response) => ({
            isProcessingSubmit: false,
            alertText: response.errorResponse.message,
        }),
        (response) => ({
            isProcessingSubmit: false,
            alertText: '',
            warning: response.successResponse.data.BookingConfirmationWarning || '',
        }),
    );
};

export const shouldDisableCustomerEmail = (disableCustomerEmailSending, ignoreDisableEmailSendingSuppliers, suppliers) => {
    let disableCustomerEmail = disableCustomerEmailSending;
    if (disableCustomerEmail && ignoreDisableEmailSendingSuppliers && ignoreDisableEmailSendingSuppliers.length > 0 && suppliers) {
        disableCustomerEmail = suppliers.every((item) => (
            !ignoreDisableEmailSendingSuppliers.includes(item.BookingSupplierCode)
        ));
    }
    return disableCustomerEmail;
};

export const getConditionsOfCarriageMap = (cocUrls, userLocale) => (cocUrls.split(',').reduce((map, value) => {
    const [supplier, url, locale] = value.split('|');
    if (!(supplier in map) || locale === userLocale) {
        map[supplier] = url; // eslint-disable-line no-param-reassign
    }
    return map;
}, {}));
