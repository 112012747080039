import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';

import messages from './messagesConfirm';
import styles from './styles';

const Confirm = (props) => (
    <div data-testid="Confirm">
        <FormattedMessage {...messages.areYouSure} />
        <Button
            id="srtConfirmYes"
            color="primary"
            style={styles.button}
            onClick={props.onYes}
            variant="contained"
        >
            <FormattedMessage {...messages.yes} />
        </Button>
        <Button
            id="srtConfirmNo"
            color="primary"
            style={styles.button}
            onClick={props.onNo}
            variant="contained"
        >
            <FormattedMessage {...messages.no} />
        </Button>
    </div>
);

Confirm.propTypes = {
    onYes: PropTypes.func.isRequired,
    onNo: PropTypes.func.isRequired,
};

export default Confirm;
