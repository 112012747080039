import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { paymentMethodsTypes } from '../../constants/constants';
import Braintree from '../Braintree/Braintree';
import Alert from '../Alert/Alert';
import { validateIsDebitCardHelper } from '../../utils';
import messages from './messagesPayment';
import inlineStyles from './styles';

const getCardYears = (year) => {
    const MAX_NUM_EXP_YEARS_SHOWN = 20;
    const expirationYears = [];
    const startYears = [];
    for (let loopCntr = -5; loopCntr < MAX_NUM_EXP_YEARS_SHOWN; ++loopCntr) {
        if (loopCntr <= 0) startYears.push((year + loopCntr).toString());
        if (loopCntr >= 0) expirationYears.push((year + loopCntr).toString());
    }

    return {
        expirationYears,
        startYears,
    };
};

class Payment extends PureComponent {
    static propTypes = {
        values: PropTypes.shape({
            method: PropTypes.string,
            cardName: PropTypes.string,
            cardNumber: PropTypes.string,
            cardNumberFirstSixDigits: PropTypes.string,
            cardNumberLastFourDigits: PropTypes.string,
            expiryDateMonth: PropTypes.string,
            expiryDateYear: PropTypes.string,
            startDateMonth: PropTypes.string,
            startDateYear: PropTypes.string,
            issueNumber: PropTypes.string,
            securityCode: PropTypes.string,
            sortCode: PropTypes.string,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            middleName: PropTypes.string,
            hideOnAccount: PropTypes.bool,
        }).isRequired,
        isConfirmation: PropTypes.bool,
        errors: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        paymentMethods: PropTypes.array.isRequired,
        disabled: PropTypes.bool,
        intl: PropTypes.object,
        id: PropTypes.string,
        getBraintreeNonceFunctionRef: PropTypes.func,
        braintreeClientToken: PropTypes.string,
    };

    static defaultProps = {
        isConfirmation: false,
        disabled: false,
        id: 'srtPayment',
    };

    handleChange = (name, value) => {
        this.props.onChange({ [name]: value });
    };

    handleChangeField = (event) => {
        this.handleChange(event.target.name, event.target.value);
    };

    handleChangeMethod = ({ target: { value } }) => {
        this.handleChange('method', value);
    };

    handleChangeExpiryDateMonth = ({ target: { value } }) => {
        this.handleChange('expiryDateMonth', value);
    };

    handleChangeExpiryDateYear = ({ target: { value } }) => {
        this.handleChange('expiryDateYear', value);
    };

    handleChangeStartDateMonth = ({ target: { value } }) => {
        this.handleChange('startDateMonth', value);
    };

    handleChangeStartDateYear = ({ target: { value } }) => {
        this.handleChange('startDateYear', value);
    };

    renderPaymentFields() {
        const years = getCardYears(new Date(Date.now()).getFullYear());
        const {
            id,
            values,
            paymentMethods,
            isConfirmation,
            disabled,
            intl: { formatMessage },
            getBraintreeNonceFunctionRef,
            braintreeClientToken,
            errors,
        } = this.props;

        const isDebit = validateIsDebitCardHelper(values.method);

        return (
            <>
                {(values.method !== paymentMethodsTypes.braintree || paymentMethods.length > 1) && (
                    <TextField
                        id={`${id}Method`}
                        label={formatMessage(messages.lblPaymentMethod)}
                        placeholder={formatMessage(messages.lblPaymentSelect)}
                        fullWidth
                        onChange={this.handleChangeMethod}
                        error={!!errors.method}
                        helperText={errors.method}
                        value={values.method}
                        disabled={disabled}
                        select
                        variant="standard"
                    >
                        {paymentMethods.map((item, index) => (
                            <MenuItem
                                id={`${id}Method_${item.name}`}
                                key={index}
                                value={item.code}
                            >
                                {item.name}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
                {!isConfirmation && values.hideOnAccount && values.method === paymentMethodsTypes.onaccount && (
                    <h4>
                        <FormattedMessage
                            {...messages.lblEndingNumber}
                            values={{ endingNumber: values.cardNumber.slice(values.cardNumber.length - 4) }}
                        />
                    </h4>
                )}
                {!isConfirmation && !values.hideOnAccount && (
                    <TextField
                        id={`${id}CardName`}
                        name="cardName"
                        label={formatMessage(messages.lblPaymentCardName)}
                        fullWidth
                        onChange={this.handleChangeField}
                        error={!!errors.cardName}
                        helperText={errors.cardName}
                        value={values.cardName}
                        disabled={disabled}
                        variant="standard"
                    />
                )}

                {values.method === paymentMethodsTypes.braintree
                    ? (
                        <Braintree
                            getNonceFunctionRef={getBraintreeNonceFunctionRef}
                            showEmptyError={!!errors.braintree}
                            disabled={disabled}
                            braintreeClientToken={braintreeClientToken}
                        />
                    ) : (
                        <>
                            {isConfirmation && (
                                <div>
                                    <TextField
                                        id={`${id}FirstName`}
                                        name="firstName"
                                        label={formatMessage(messages.lblPaymentFirstName)}
                                        fullWidth
                                        onChange={this.handleChangeField}
                                        error={!!errors.firstName}
                                        helperText={errors.firstName}
                                        value={values.firstName}
                                        disabled={disabled}
                                        variant="standard"
                                    />
                                    <TextField
                                        id={`${id}LastName`}
                                        name="lastName"
                                        label={formatMessage(messages.lblPaymentLastName)}
                                        fullWidth
                                        onChange={this.handleChangeField}
                                        error={!!errors.lastName}
                                        helperText={errors.lastName}
                                        value={values.lastName}
                                        disabled={disabled}
                                        variant="standard"
                                    />
                                    <TextField
                                        id={`${id}MiddleName`}
                                        name="middleName"
                                        label={formatMessage(messages.lblPaymentMiddleName)}
                                        fullWidth
                                        onChange={this.handleChangeField}
                                        error={errors.middleName}
                                        helperText={errors.middleName}
                                        value={values.middleName}
                                        disabled={disabled}
                                        variant="standard"
                                    />
                                    <div>
                                        <TextField
                                            id={`${id}CardNumberFirstSixDigits`}
                                            name="cardNumberFirstSixDigits"
                                            label={formatMessage(messages.lblPaymentCardNumberFirstSixDigits)}
                                            fullWidth
                                            onChange={this.handleChangeField}
                                            error={values?.cardNumberFirstSixDigits?.length > 6 || /[^0-9]/.test(values?.cardNumberFirstSixDigits)}
                                            value={values?.cardNumberFirstSixDigits}
                                            disabled={disabled}
                                            variant="standard"
                                        />

                                        <TextField
                                            id={`${id}cardNumberLastFourDigits`}
                                            name="cardNumberLastFourDigits"
                                            label={formatMessage(messages.lblPaymentCardNumberLastFourDigits)}
                                            fullWidth
                                            onChange={this.handleChangeField}
                                            error={values?.cardNumberLastFourDigits?.length > 4 || /[^0-9]/.test(values?.cardNumberLastFourDigits)}
                                            value={values?.cardNumberLastFourDigits}
                                            disabled={disabled}
                                            variant="standard"
                                        />
                                    </div>
                                </div>
                            )}

                            {(!values.hideOnAccount || values.method !== paymentMethodsTypes.onaccount) && !isConfirmation && (
                                <TextField
                                    id={`${id}CardNumber`}
                                    name="cardNumber"
                                    label={formatMessage(messages.lblPaymentCardNumber)}
                                    fullWidth
                                    onChange={this.handleChangeField}
                                    error={errors.cardNumber && !!errors.cardNumber[0]}
                                    helperText={errors.cardNumber && errors.cardNumber[0]}
                                    value={values.cardNumber}
                                    disabled={disabled}
                                    variant="standard"
                                />

                            )}

                            {values.method !== paymentMethodsTypes.onaccount
                                && (
                                    <div className="row" style={inlineStyles.row}>
                                        <div className="col-12 col-sm-6">
                                            <TextField
                                                id={`${id}ExpiryDateMonth`}
                                                label={formatMessage(messages.lblPaymentExpiryDate)}
                                                InputLabelProps={{ shrink: true }}
                                                placeholder={formatMessage(messages.lblMonth)}
                                                fullWidth
                                                onChange={this.handleChangeExpiryDateMonth}
                                                error={!!errors.expiryDateMonth}
                                                helperText={errors.expiryDateMonth}
                                                value={values.expiryDateMonth}
                                                disabled={disabled}
                                                select
                                                variant="standard"
                                            >
                                                <MenuItem id={`${id}Expiry1`} value="1">01</MenuItem>
                                                <MenuItem id={`${id}Expiry2`} value="2">02</MenuItem>
                                                <MenuItem id={`${id}Expiry3`} value="3">03</MenuItem>
                                                <MenuItem id={`${id}Expiry4`} value="4">04</MenuItem>
                                                <MenuItem id={`${id}Expiry5`} value="5">05</MenuItem>
                                                <MenuItem id={`${id}Expiry6`} value="6">06</MenuItem>
                                                <MenuItem id={`${id}Expiry7`} value="7">07</MenuItem>
                                                <MenuItem id={`${id}Expiry8`} value="8">08</MenuItem>
                                                <MenuItem id={`${id}Expiry9`} value="9">09</MenuItem>
                                                <MenuItem id={`${id}Expiry10`} value="10">10</MenuItem>
                                                <MenuItem id={`${id}Expiry11`} value="11">11</MenuItem>
                                                <MenuItem id={`${id}Expiry12`} value="12">12</MenuItem>
                                            </TextField>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <TextField
                                                id={`${id}ExpiryDateYear`}
                                                name="expiryDateYear"
                                                label="  "
                                                InputLabelProps={{ shrink: true }}
                                                placeholder={formatMessage(messages.lblYear)}
                                                fullWidth
                                                onChange={this.handleChangeExpiryDateYear}
                                                error={!!errors.expiryDateYear}
                                                helperText={errors.expiryDateYear}
                                                value={values.expiryDateYear}
                                                disabled={disabled}
                                                select
                                                variant="standard"
                                            >
                                                {years.expirationYears.map((item, index) => (
                                                    <MenuItem
                                                        id={`${id}ExpiryYear_${item}`}
                                                        key={index}
                                                        value={item}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                )}
                            {isDebit && (
                                <div className="row" style={inlineStyles.row}>
                                    {!isConfirmation && (
                                        <div>
                                            <div className="col-12 col-sm-6">
                                                <TextField
                                                    id={`${id}StartDateMonth`}
                                                    label={formatMessage(messages.lblPaymentStartDate)}
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder={formatMessage(messages.lblMonth)}
                                                    fullWidth
                                                    onChange={this.handleChangeStartDateMonth}
                                                    error={!!errors.startDateMonth}
                                                    helperText={errors.startDateMonth}
                                                    value={values.startDateMonth}
                                                    disabled={disabled}
                                                    select
                                                    variant="standard"
                                                >
                                                    <MenuItem id={`${id}Start1`} value="1">01</MenuItem>
                                                    <MenuItem id={`${id}Start2`} value="2">02</MenuItem>
                                                    <MenuItem id={`${id}Start3`} value="3">03</MenuItem>
                                                    <MenuItem id={`${id}Start4`} value="4">04</MenuItem>
                                                    <MenuItem id={`${id}Start5`} value="5">05</MenuItem>
                                                    <MenuItem id={`${id}Start6`} value="6">06</MenuItem>
                                                    <MenuItem id={`${id}Start7`} value="7">07</MenuItem>
                                                    <MenuItem id={`${id}Start8`} value="8">08</MenuItem>
                                                    <MenuItem id={`${id}Start9`} value="9">09</MenuItem>
                                                    <MenuItem id={`${id}Start10`} value="10">10</MenuItem>
                                                    <MenuItem id={`${id}Start11`} value="11">11</MenuItem>
                                                    <MenuItem id={`${id}Start12`} value="12">12</MenuItem>
                                                </TextField>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <TextField
                                                    id={`${id}StartDateYear`}
                                                    name="startDateYear"
                                                    label="  "
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder={formatMessage(messages.lblYear)}
                                                    fullWidth
                                                    onChange={this.handleChangeStartDateYear}
                                                    error={!!errors.startDateYear}
                                                    helperText={errors.startDateYear}
                                                    value={values.startDateYear}
                                                    disabled={disabled}
                                                    select
                                                    variant="standard"
                                                >
                                                    {years.startYears.map((item, index) => (
                                                        <MenuItem
                                                            id={`${id}StartYear_${item}`}
                                                            key={index}
                                                            value={item}
                                                        >
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-12">
                                        {
                                            isConfirmation
                                                ? (
                                                    <TextField
                                                        id={`${id}SortCode`}
                                                        name="sortCode"
                                                        label={formatMessage(messages.lblPaymentSortCode)}
                                                        fullWidth
                                                        onChange={this.handleChangeField}
                                                        error={!!errors.sortCode}
                                                        helperText={errors.sortCode}
                                                        value={values.sortCode}
                                                        disabled={disabled}
                                                        variant="standard"
                                                    />
                                                )
                                                : (
                                                    <TextField
                                                        id={`${id}IssueNumber`}
                                                        name="issueNumber"
                                                        label={formatMessage(messages.lblPaymentIssueNumber)}
                                                        fullWidth
                                                        onChange={this.handleChangeField}
                                                        error={!!errors.issueNumber}
                                                        helperText={errors.issueNumber}
                                                        value={values.issueNumber}
                                                        disabled={disabled}
                                                        variant="standard"
                                                    />
                                                )
                                        }
                                    </div>
                                </div>
                            )}
                            {!isConfirmation && !values.hideOnAccount && (
                                <TextField
                                    id={`${id}SecurityCode`}
                                    name="securityCode"
                                    label={formatMessage(messages.lblPaymentSecurityCode)}
                                    fullWidth
                                    onChange={this.handleChangeField}
                                    error={!!errors.securityCode}
                                    helperText={errors.securityCode}
                                    value={values.securityCode}
                                    disabled={disabled}
                                    variant="standard"
                                />
                            )}
                        </>
                    )}
            </>
        );
    }

    render() {
        const {
            errors,
        } = this.props;

        return (
            <div className="row">
                <div id="CardInfo" className="col-12">
                    {errors.payment ? (
                        <Alert>{errors.payment}</Alert>
                    ) : (
                        this.renderPaymentFields()
                    )}
                </div>
            </div>
        );
    }
}

// This alias will be used to access bare component for unit testing
export { Payment as PaymentAlias };

export default injectIntl(Payment);
