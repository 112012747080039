import { defineMessages } from 'react-intl';

export default defineMessages({
    lblPassengers: {
        id: 'app.shopping.manageBooking.orderPassenger.passengers',
        defaultMessage: 'Passengers',
    },
    lblPassengerName: {
        id: 'app.shopping.manageBooking.orderPassenger.passengerName',
        defaultMessage: 'Name',
    },
    lblPassengerAge: {
        id: 'app.shopping.manageBooking.orderPassenger.passengerAge',
        defaultMessage: 'Age',
    },
    lblPassengerPhone: {
        id: 'app.shopping.manageBooking.orderPassenger.passengerPhone',
        defaultMessage: 'Phone',
    },
    lblPassengerEmailAddress: {
        id: 'app.shopping.manageBooking.orderPassenger.passengerEmailAddress',
        defaultMessage: 'Email Address',
    },
    btnDeleteCardholderInformation: {
        id: 'app.shopping.manageBooking.orderPassenger.deleteCardholderInformation',
        defaultMessage: 'REMOVE PASSENGER DATA',
    },
    lblDeleteCardholderInformationConfirmation: {
        id: 'app.shopping.manageBooking.orderPassenger.deleteCardholderInformationConfirmation',
        defaultMessage: 'Removing passenger data information removes personally identifying information and '
            + 'sensitive payment information from the booking. This action cannot be undone. Are you sure you want to continue?',
    },
});
