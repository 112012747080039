import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const deepCompareEquals = (a, b) => isEqual(a, b);

const useDeepCompareMemoize = (value) => {
    const ref = useRef();
    // it can be done by using useMemo as well
    // but useRef is rather cleaner and easier
    if (!deepCompareEquals(value, ref.current)) {
        ref.current = value;
    }
    return ref.current;
};

export const useDeepCompareEffect = (callback, dependencies) => {
    useEffect(
        callback,
        dependencies.map(useDeepCompareMemoize),
    );
};

export default usePrevious;
