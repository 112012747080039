import { defineMessages } from 'react-intl';

export default defineMessages({
    lblTitle: {
        id: 'app.shopping.selectedFareSummary.title',
        defaultMessage: 'Selected Fares',
    },
    lblChangeSelection: {
        id: 'app.shopping.selectedFareSummary.changeSelection',
        defaultMessage: 'Change Selections',
    },
    lblCheckout: {
        id: 'app.shopping.selectedFareSummary.checkout',
        defaultMessage: 'Continue to checkout',
    },
    lblTotal: {
        id: 'app.shopping.selectedFareSummary.total',
        defaultMessage: 'Total:',
    },
});
