import validate from 'validate.js';
import { TEST_ENVS } from './constants/constants';

const BASE_PATH_DEFAULT = 'silveragent';
const BASE_PATH = window.location.pathname.split('/')[1] || BASE_PATH_DEFAULT;
export const BASE_PATH_SUFFIX = process.env.BASE_PATH_SUFFIX || '';
const SA_DEV_HOST = 'sadev.srt-dev.net';

// handle special case for sadev
export const getRootPath = () => ((window.location.hostname.indexOf(SA_DEV_HOST) === 0) ? '' : `/${BASE_PATH}`);

export const getBasePath = () => {
    const basePath = process.env.BASE_PATH || `${getRootPath()}/main/`;
    return basePath;
};
export const getServerPath = () => `${window.location.protocol}//${window.location.host}${getBasePath()}`;

export const getHomePageUrl = () => `${getBasePath()}`;

export const getCookiePath = () => getRootPath() || '/';

export const getCurrency = (currency, intl) => (
    currency ? intl.formatNumber(0, {
        style: 'currency',
        currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    }).replace(/\d/g, '') : ''
);

export const formatPrice = (number, currency, intl) => {
    let options;
    let suffix = '';

    if (Number.isNaN(Number(number))) return '';
    if (!currency) return intl.formatNumber(number);

    if (currency === 'SEK') {
        options = {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        };
        suffix = 'kr';
    } else {
        options = {
            style: 'currency',
            currency,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        };
    }

    return `${intl.formatNumber(number, options)}${suffix}`;
};

export const transformCustomInformation = (customInformationItems) => {
    const fieldDelimiter = ':!:';
    const itemDelimiter = ':::';

    const items = [];
    customInformationItems.forEach((item) => {
        items.push(`${item.type}${fieldDelimiter}${(item.value) ? item.value : ' '}${fieldDelimiter}${item.required}`);
    });
    return items.join(itemDelimiter);
};

export const decodeHtml = (encString) => {
    const textAreaDummy = document.createElement('textarea');
    textAreaDummy.innerHTML = encString;
    return textAreaDummy.value;
};

export const validateIsDebitCardHelper = (value) => (value === 'VD' || value === 'MD' || value === 'SD' || value === 'CD');

export const getFromPath = (path, object) => path.reduce((xs, x) => ((xs && xs[x]) ? xs[x] : null), object);

export const initializeValidateJS = () => {
    // Change isDefined function to also check that is not an empty string
    validate.isDefinedOld = validate.isDefined;
    validate.isDefined = (obj) => validate.isDefinedOld(obj) && (!validate.isString(obj) || !validate.EMPTY_STRING_REGEXP.test(obj));
};

// added following 2 methods to resolve issues related to having percentage char (%) in account or user search/edit
// this issue is caused by problems in history/react router handling of encoding and decoding % char
// see https://github.com/ReactTraining/history/issues/505 for explanation
// once this issue's resolution gets to react router/history release, update dependencies and remove these methods
// and use normal encodeURIComponent and decodeURIComponent methods in User and Account components
export const safeEncode = (pathname) => (
    encodeURIComponent(encodeURIComponent(pathname))
);

export const safeDecode = (pathname) => {
    try {
        return decodeURIComponent(pathname);
    } catch (_error) {
        return pathname;
    }
};

export const toTitleCase = (sentence) => {
    const ticleCase = sentence
        .split(' ')
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    return ticleCase;
};

export const calculateAmount = (balanceDue, balance) => (balance === 0 ? Math.abs(balanceDue) : Math.min(
    Math.abs(balanceDue),
    Math.abs(balance),
));

export const mixedFares = (fareCodes) => {
    const { fareTypeClass } = fareCodes[0];
    const { primaryColumnClass } = fareCodes[0];
    const { scheduleClass } = fareCodes[0];

    let sameFareTypeClass = true;
    let samePrimaryColumnClass = true;
    let sameScheduleClass = true;

    for (let i = 1; i < fareCodes.length; i++) {
        if (fareCodes[i].fareTypeClass !== fareTypeClass) {
            sameFareTypeClass = false;
        }
        if (fareCodes[i].primaryColumnClass !== primaryColumnClass) {
            samePrimaryColumnClass = false;
        }
        if (fareCodes[i].scheduleClass !== scheduleClass) {
            sameScheduleClass = false;
        }
    }
    const isMixedFares = !sameFareTypeClass || !samePrimaryColumnClass || !sameScheduleClass;

    return {
        sameFareTypeClass,
        samePrimaryColumnClass,
        sameScheduleClass,
        isMixedFares,
    };
};

export const isAdminKey = 'ws.system.security.isadminonly';

export const processTokenResponse = (response) => {
    const newToken = response.successResponse.data.SecurityAccessToken;
    const expiresIn = response.successResponse.data.SecurityExpiresIn;
    const currentTime = Date.now();
    const newExpiryTime = currentTime + expiresIn * 1000;

    return {
        newToken,
        newExpiryTime,
    };
};
export const isDevEnvironment = () => TEST_ENVS.includes(process.env.ENV);
