import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { styled } from '@mui/system';
import { ErrorBoundary } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';
import groupBy from 'lodash/groupBy';

import OrderDetails from '../OrderDetails/OrderDetails';
import OSDMCancelOrderDialog from '../../../components/OSDMCancelOrderDialog/OSDMCancelOrderDialog';
import CancelVoidOrderDialog from '../../../components/CancelVoidOrderDialog/CancelVoidOrderDialog';
import { TYPES as CANCEL_VOID_TYPES } from '../../../components/CancelVoidOrderDialog/constants';
import TrackingInfoDialog from '../../../components/TrackingInfoDialog/TrackingInfoDialog';
import ReturnCouponsDialog from '../../../components/ReturnCouponsDialog/ReturnCouponsDialog';
import AuthorizeRefundDialog from '../../../components/AuthorizeRefundDialog/AuthorizeRefundDialog';
import BookingEmailConfirmationDialog from '../../../components/BookingEmailConfirmationDialog/BookingEmailConfirmationDialog';
import StationInformationDialog from '../../../../components/StationInformation/StationInformationDialog';
import ConfirmedCardInfoDialog from '../../../components/ConfirmedCardInfo/ConfirmedCardInfoDialog';
import ResendTicketDialog from '../../../components/ResendTicketDialog/ResendTicketDialog';
import ExchangeOrderDialog from '../../../components/ExchangeOrderDialog/ExchangeOrderDialog';
import ConfirmOrderDialog from '../../../components/ConfirmOrderDialog/ConfirmOrderDialog';
import TicketDelivery from '../../../../components/TicketDelivery/TicketDeliveryDialog';
import SupplierNotesDialog from '../../../components/BookingNotes/SupplierNotesDialog';
import AmendSeatReservationsDialog from '../AmendSeatReservations/amendSeatReservationsDialog';
import { getContext } from '../../utils';
import { TDOS, notAutoSelectableTdos } from '../../../../constants/constants';
import messages from './messages';
import styles from './styles';
import ErrorFallBack from '../ErrorFallBack';

const StyledContentContainerDiv = styled('div')({
    ...styles.contentContainer,
});

const StyledSuperOrderDiv = styled('div')({
    ...styles.superOrder,
});

const defaultTdo = {
    tdoCode: '',
    tdoDesc: '',
    tdoFee: 0.0,
    tdoCurrency: '',
    tdoSmsCountry: null,
    tdoSmsNumber: '',
    tdoRegion: '',
    tdoMailName: '',
    tdoMailAddress: {
        countryCode: null,
        address1: '',
        address2: '',
        city: '',
        stateOrProvince: '',
        postalCode: '',
    },
    tdoSmartCards: [],
    tdoPickUpLocation: '',
    tdoPaperEmail: '',
};

function SuperOrdersView({
    booking,
    bookingViewData,
    disableCustomerEmailConfirmation,
    disableCustomerEmailSending,
    enableReturns,
    handleAllowAnyCardCollect,
    handleCloseOSDMCancelOrderDialog,
    handleOpenOSDMCancelOrderDialog,
    handleCloseAuthorizeRefundDialog,
    handleCloseCancelOrderDialog,
    handleCloseConfirmedCardDialog,
    handleCloseConfirmOrderDialog,
    handleCloseEmailConfirmationDialog,
    handleCloseExchangeOrderDialog,
    handleCloseResendTicketDialog,
    handleCloseReturnCouponsDialog,
    handleCloseStationInfoDialog,
    handleCloseTicketDelivery,
    handleCloseTrackingInfoDialog,
    handleCloseVoidOrderDialog,
    handleOpenAuthorizeRefundDialog,
    handleOpenCancelOrderDialog,
    handleOpenConfirmedCardDialog,
    handleOpenConfirmOrderDialog,
    handleOpenEmailConfirmationDialog,
    handleOpenExchangeOrderDialog,
    handleOpenResendTicketDialog,
    handleOpenReturnCouponsDialog,
    handleOpenStationInfoDialog,
    handleOpenTicketDelivery,
    handleOpenTrackingInfoDialog,
    handleOpenVoidOrderDialog,
    handleSupplierNotesDialogDisplay,
    handleCloseAmendSeatReservationDialog,
    handleOpenAmendSeatReservationDialog,
    ignoreDisableEmailSendingSuppliers,
    onAmendCTR,
    orderAccount,
    showAmendCTR,
    showExchangeOrderButton,
    showVoidOrderButton,
    voidIsExpired,
    isProcessingAction,
}) {
    const checkCurrentTDO = (order) => {
        const { BookingOrderTDOData: { BookingFulfillmentInfo: { BookingOrderTicketDeliveryDetails } } } = order;
        return BookingOrderTicketDeliveryDetails;
    };

    const isTDOAutoSelected = (order) => {
        let isTDOAutoSelectedReturn = false;
        const bookingTDOs = order.BookingOrderTDOData.BookingTDOs;
        if (order) {
            const currentTdo = checkCurrentTDO(order);
            const ticketDelivery = currentTdo?.Code;
            isTDOAutoSelectedReturn = (
                !!currentTdo?.Code
                && order.BookingOrderStatus === 'BOOKED'
                && bookingTDOs.length === 1
                && TDOS.includes(ticketDelivery)
                && !notAutoSelectableTdos.includes(ticketDelivery)
            );
        }
        return isTDOAutoSelectedReturn;
    };

    const getTDOType = (order) => {
        let label;

        if (isTDOAutoSelected(order)) {
            label = { message: messages.btnViewTicketDelivery, event: 'view' };
        } else if (order.BookingOrderStatus === 'BOOKED') {
            const currentTdo = checkCurrentTDO(order);
            label = currentTdo?.Code
                ? { message: messages.btnEditTicketDelivery, event: 'edit' }
                : { message: messages.btnAddTicketDelivery, event: 'add' };
        }

        return label;
    };

    const updateTdoObj = (
        tdoData,
        tdoSmsCountry,
        tdoSmsNumber,
        tdoRegion,
        tdoMailName,
        tdoMailAddress,
        tdoSmartCards,
        tdoPickUpLocation,
        tdoPaperEmail,
    ) => (
        {
            tdoCode: tdoData.Code,
            tdoDesc: tdoData.Description,
            tdoFee: parseFloat(tdoData.Fee.Value),
            tdoCurrency: tdoData.Fee.Currency,
            tdoSmsCountry,
            tdoSmsNumber,
            tdoRegion,
            tdoMailName,
            tdoMailAddress,
            tdoSmartCards,
            tdoPickUpLocation,
            tdoPaperEmail,
        }
    );

    const getPreSelectedTDO = (fulfillmentInfo, bookingPassengers) => {
        let preSelectedTDO = defaultTdo;
        const tdo = fulfillmentInfo.BookingOrderTicketDeliveryDetails;

        if (tdo) {
            const tdoSmsCountry = fulfillmentInfo.BookingFulfillmentPhoneCountryCode || '';
            const tdoSmsNumber = fulfillmentInfo.BookingFulfillmentPhoneNumber || '';
            const tdoRegion = tdo.Destination || '';
            const tdoMailName = fulfillmentInfo.BookingFulfillmentShippingName || '';
            const address1 = fulfillmentInfo.BookingFulfillmentShippingAddress1 || '';
            const address2 = fulfillmentInfo.BookingFulfillmentShippingAddress2 || '';
            const addressType = fulfillmentInfo.BookingFulfillmentShippingAddressType || '';
            const city = fulfillmentInfo.BookingFulfillmentShippingCity || '';
            const countryCode = fulfillmentInfo.BookingFulfillmentShippingCountry || '';
            const stateOrProvince = fulfillmentInfo.BookingFulfillmentShippingStateProv || '';
            const postalCode = fulfillmentInfo.BookingFulfillmentShippingZipCode || '';
            const tdoPickUpLocation = fulfillmentInfo.BookingOrderPickUpStation || '';
            const tdoSmartCards = bookingPassengers.map((item) => item.BookingSmartCardNumber);
            const tdoPaperEmail = fulfillmentInfo.BookingFulfillmentPaperEmail || '';
            tdo.Code = fulfillmentInfo.BookingOrderTicketDelivery || tdo.Code;

            const tdoMailAddress = {
                address1,
                address2,
                addressType,
                city,
                countryCode,
                stateOrProvince,
                postalCode,
            };
            preSelectedTDO = updateTdoObj(
                tdo,
                tdoSmsCountry,
                tdoSmsNumber,
                tdoRegion,
                tdoMailName,
                tdoMailAddress,
                tdoSmartCards,
                tdoPickUpLocation,
                tdoPaperEmail,
            );
        }

        return preSelectedTDO;
    };
    const amendSeatReservationOrderId = bookingViewData.get('openAmendSeatReservationDialog');
    const openOSDMCancelOrderDialog = bookingViewData.get('openOSDMCancelOrderDialog');
    const trackingInfoOrderId = bookingViewData.get('openTrackingInfoDialog');
    const returnCouponsOrderId = bookingViewData.get('openReturnCouponsDialog');
    const confirmedCardOrderId = bookingViewData.get('openConfirmedCardDialog');
    const authorizeRefundOrderId = bookingViewData.get('authorizeRefundDialog');
    const cancelOrderId = bookingViewData.get('openCancelOrderDialog');
    const voidOrderId = bookingViewData.get('openVoidOrderDialog');
    const confirmOrderId = bookingViewData.get('openConfirmOrderDialog');
    const tdOrder = bookingViewData.get('openTicketDelivery') && bookingViewData.get('openTicketDelivery').toJS();
    const preselectedTDOrder = tdOrder && getPreSelectedTDO(
        tdOrder.BookingOrderTDOData.BookingFulfillmentInfo,
        booking.BookingPassengers,
    );
    const emailConfirmationOrderId = bookingViewData.get('openEmailConfirmationDialog');
    const stationCode = bookingViewData.get('openStationInformationDialog');
    const resendTicketOrderId = bookingViewData.get('openResendTicketDialog');
    const exchangeOrderId = bookingViewData.get('openExchangeOrderDialog');
    const supplierNotesOrderId = bookingViewData.get('openSupplierNotesDialog');
    const currentTDOFirstOrder = checkCurrentTDO(booking.BookingOrders[0]);
    const superOrders = Object.entries(groupBy(booking.BookingOrders, (order) => order.BookingSuperOrderId));

    return (
        <StyledContentContainerDiv>
            {superOrders.map(([superOrderId, orders]) => (
                <StyledSuperOrderDiv
                    key={`superorder_${superOrderId}`}
                    id={`superorder_${superOrderId}`}
                >
                    {orders.map((order) => (
                        <OrderDetails
                            key={order.BookingOrderID}
                            booking={booking}
                            disableAllowAnyCardCollectButton={bookingViewData.get('disableAllowAnyCardCollectButton')}
                            disableCustomerEmailConfirmation={disableCustomerEmailConfirmation}
                            disableCustomerEmailSending={disableCustomerEmailSending}
                            enableReturns={enableReturns}
                            getTDOType={getTDOType}
                            handleOpenOSDMCancelOrderDialog={handleOpenOSDMCancelOrderDialog}
                            handleAllowAnyCardCollect={handleAllowAnyCardCollect}
                            handleOpenAuthorizeRefundDialog={handleOpenAuthorizeRefundDialog}
                            handleOpenCancelOrderDialog={handleOpenCancelOrderDialog}
                            handleOpenConfirmedCardDialog={handleOpenConfirmedCardDialog}
                            handleOpenConfirmOrderDialog={handleOpenConfirmOrderDialog}
                            handleOpenEmailConfirmationDialog={handleOpenEmailConfirmationDialog}
                            handleOpenExchangeOrderDialog={handleOpenExchangeOrderDialog}
                            handleOpenResendTicketDialog={handleOpenResendTicketDialog}
                            handleOpenReturnCouponsDialog={handleOpenReturnCouponsDialog}
                            handleOpenStationInfoDialog={handleOpenStationInfoDialog}
                            handleOpenTicketDelivery={handleOpenTicketDelivery}
                            handleOpenTrackingInfoDialog={handleOpenTrackingInfoDialog}
                            handleOpenVoidOrderDialog={handleOpenVoidOrderDialog}
                            handleCloseAmendSeatReservationDialog={handleCloseAmendSeatReservationDialog}
                            handleOpenAmendSeatReservationDialog={handleOpenAmendSeatReservationDialog}
                            handleSupplierNotesDialogDisplay={handleSupplierNotesDialogDisplay}
                            ignoreDisableEmailSendingSuppliers={ignoreDisableEmailSendingSuppliers}
                            onAmendCTR={onAmendCTR}
                            order={order}
                            showAmendCTR={showAmendCTR}
                            showExchangeOrderButton={showExchangeOrderButton}
                            showVoidOrderButton={showVoidOrderButton}
                            voidIsExpired={voidIsExpired}
                            isProcessingAction={isProcessingAction}
                        />
                    ))}
                </StyledSuperOrderDiv>
            ))}
            {trackingInfoOrderId && (
                <ErrorBoundary
                    fallbackRender={(props) => (
                        <ErrorFallBack
                            {...props}
                            messageComponent={<FormattedMessage {...messages.lblTrackingInfoDialogTitle} />}
                        />
                    )}
                >
                    <TrackingInfoDialog
                        open={Boolean(trackingInfoOrderId)}
                        orderId={trackingInfoOrderId}
                        booking={booking}
                        onClose={handleCloseTrackingInfoDialog}
                    />
                </ErrorBoundary>
            )}
            {returnCouponsOrderId && (
                <ErrorBoundary
                    fallbackRender={(props) => (
                        <ErrorFallBack
                            {...props}
                            messageComponent={<FormattedMessage {...messages.lblReturnCouponsDialogTitle} />}
                        />
                    )}
                >
                    <ReturnCouponsDialog
                        open={Boolean(returnCouponsOrderId)}
                        orderId={returnCouponsOrderId}
                        booking={booking}
                        onClose={handleCloseReturnCouponsDialog}
                    />
                </ErrorBoundary>
            )}
            <ErrorBoundary
                fallbackRender={(props) => (
                    <ErrorFallBack
                        {...props}
                        messageComponent={<FormattedMessage {...messages.lblConfirmedCardInfoDialogTitle} />}
                    />
                )}
            >
                <ConfirmedCardInfoDialog
                    booking={booking}
                    orderId={confirmedCardOrderId}
                    open={!!confirmedCardOrderId}
                    onClose={handleCloseConfirmedCardDialog}
                />
            </ErrorBoundary>
            {authorizeRefundOrderId && (
                <ErrorBoundary
                    fallbackRender={(props) => (
                        <ErrorFallBack
                            {...props}
                            messageComponent={<FormattedMessage {...messages.lblAuthorizeRefundDialogTitle} />}
                        />
                    )}
                >
                    <AuthorizeRefundDialog
                        open={!!authorizeRefundOrderId}
                        orderId={authorizeRefundOrderId}
                        booking={booking}
                        onClose={handleCloseAuthorizeRefundDialog}

                    />
                </ErrorBoundary>
            )}
            <ErrorBoundary
                fallbackRender={(props) => (
                    <ErrorFallBack
                        {...props}
                        messageComponent={<FormattedMessage {...messages.lblCancelTitle} />}
                    />
                )}
            >
                <CancelVoidOrderDialog
                    bookingBillingTotal={booking.BookingBillingTotal}
                    onCloseDialog={handleCloseCancelOrderDialog}
                    open={!!cancelOrderId}
                    orderId={cancelOrderId}
                    queryItems={booking.queryItems}
                    orders={booking.BookingOrders}
                    type={CANCEL_VOID_TYPES.CANCEL}
                />
            </ErrorBoundary>
            <ErrorBoundary
                fallbackRender={(props) => (
                    <ErrorFallBack
                        {...props}
                        messageComponent={<FormattedMessage {...messages.lblVoidTitle} />}
                    />
                )}
            >
                <CancelVoidOrderDialog
                    bookingBillingTotal={booking.BookingBillingTotal}
                    onCloseDialog={handleCloseVoidOrderDialog}
                    open={!!voidOrderId}
                    orderId={voidOrderId}
                    queryItems={booking.queryItems}
                    orders={booking.BookingOrders}
                    type={CANCEL_VOID_TYPES.VOID}
                />
            </ErrorBoundary>
            <ErrorBoundary
                fallbackRender={(props) => (
                    <ErrorFallBack
                        {...props}
                        messageComponent={<FormattedMessage {...messages.lblConfirmOrderDialogTitle} />}
                    />
                )}
            >
                <ConfirmOrderDialog
                    open={!!confirmOrderId}
                    onClose={handleCloseConfirmOrderDialog}
                    booking={booking}
                    orderId={confirmOrderId}
                />
            </ErrorBoundary>
            {tdOrder
                && (
                    <ErrorBoundary
                        fallbackRender={(props) => (
                            <ErrorFallBack
                                {...props}
                                messageComponent={<FormattedMessage {...messages.lblTdoTitle} />}
                            />
                        )}
                    >
                        <TicketDelivery
                            open={Boolean(tdOrder)}
                            handleClose={handleCloseTicketDelivery}
                            handleSubmit={handleCloseTicketDelivery}
                            tdOptions={fromJS(tdOrder.BookingOrderTDOData.BookingTDOs)}
                            suppliers={tdOrder.BookingOrderTDOData.BookingSupplierCodes}
                            smsCodeOptions={bookingViewData.get('smsCodeOptions').toJS()}
                            stationCode={tdOrder.BookingOrderTDOData.BookingTDOOrigStationCode}
                            stationName={tdOrder.BookingOrderTDOData.BookingTDOOrigStationName}
                            preSelectedTdo={preselectedTDOrder}
                            booking={booking}
                            order={tdOrder}
                            viewMode={isTDOAutoSelected(tdOrder)}
                            gaType={getTDOType(tdOrder).event}
                        />
                    </ErrorBoundary>
                )}
            <ErrorBoundary
                fallbackRender={(props) => (
                    <ErrorFallBack
                        {...props}
                        messageComponent={<FormattedMessage {...messages.lblOrderConfirmationTitle} />}
                    />
                )}
            >
                <BookingEmailConfirmationDialog
                    onClose={handleCloseEmailConfirmationDialog}
                    open={!!emailConfirmationOrderId}
                    booking={booking}
                    orderId={emailConfirmationOrderId}
                />
            </ErrorBoundary>
            <ErrorBoundary
                fallbackRender={(props) => (
                    <ErrorFallBack
                        {...props}
                        messageComponent={<FormattedMessage {...messages.lblStationInformationDialogTitle} />}
                    />
                )}
            >
                <StationInformationDialog
                    stationCode={stationCode}
                    open={!!stationCode}
                    onClose={handleCloseStationInfoDialog}
                />
            </ErrorBoundary>
            {resendTicketOrderId && (
                <ErrorBoundary
                    fallbackRender={(props) => (
                        <ErrorFallBack
                            {...props}
                            messageComponent={<FormattedMessage {...messages.lblSendTicketTitle} />}
                        />
                    )}
                >
                    <ResendTicketDialog
                        open={!!resendTicketOrderId}
                        orderId={resendTicketOrderId}
                        booking={booking}
                        onClose={handleCloseResendTicketDialog}
                        smsCodeOptions={bookingViewData.get('smsCodeOptions').toJS()}
                    />
                </ErrorBoundary>
            )}
            <ErrorBoundary
                fallbackRender={(props) => (
                    <ErrorFallBack
                        {...props}
                        messageComponent={<FormattedMessage {...messages.lblExchangeOrderDialogTitle} />}
                    />
                )}
            >
                <ExchangeOrderDialog
                    open={!!exchangeOrderId}
                    orderId={exchangeOrderId}
                    queryItems={booking.queryItems}
                    bookingBillingTotal={booking.BookingBillingTotal}
                    bookingBillingSummary={booking.BookingBillingSummary}
                    onCloseExchangeOrderDialog={handleCloseExchangeOrderDialog}
                    orderAccount={orderAccount}
                    passengers={booking.BookingPassengers}
                    orderTicketDeliveryOption={currentTDOFirstOrder && currentTDOFirstOrder.Code}
                    booking={booking}
                />
            </ErrorBoundary>
            <ErrorBoundary
                fallbackRender={(props) => (
                    <ErrorFallBack
                        {...props}
                        messageComponent={<FormattedMessage {...messages.lblSupplierNotes} />}
                    />
                )}
            >
                <SupplierNotesDialog
                    orderId={supplierNotesOrderId}
                    booking={booking}
                    onClose={() => { handleSupplierNotesDialogDisplay(null); }}
                />
            </ErrorBoundary>
            {amendSeatReservationOrderId && (
                <ErrorBoundary
                    fallbackRender={(props) => (
                        <ErrorFallBack
                            {...props}
                            messageComponent={<FormattedMessage {...messages.lblAmendSeatReservations} />}
                        />
                    )}
                >
                    <AmendSeatReservationsDialog
                        booking={booking}
                        passengers={booking.BookingPassengers}
                        queryItems={booking.queryItems}
                        orderAccount={orderAccount}
                        superOrders={superOrders}
                        open={!!amendSeatReservationOrderId}
                        handleClose={() => { handleCloseAmendSeatReservationDialog(); }}
                    />
                </ErrorBoundary>
            )}

            {openOSDMCancelOrderDialog && (
                <ErrorBoundary
                    fallbackRender={(props) => (
                        <ErrorFallBack
                            {...props}
                            messageComponent="OSDM Cancellation"
                        />
                    )}
                >
                    <OSDMCancelOrderDialog
                        onCloseDialog={handleCloseOSDMCancelOrderDialog}
                        open={openOSDMCancelOrderDialog}
                        bookingRecordLocator={booking.BookingRecordLoc}
                        accountContext={getContext(booking.queryItems)}
                        refreshBookingParams={booking.queryItems}
                    />
                </ErrorBoundary>
            )}
        </StyledContentContainerDiv>
    );
}

SuperOrdersView.propTypes = {
    booking: PropTypes.object.isRequired,
    bookingViewData: PropTypes.object.isRequired,
    disableCustomerEmailConfirmation: PropTypes.bool.isRequired,
    disableCustomerEmailSending: PropTypes.bool.isRequired,
    enableReturns: PropTypes.bool.isRequired,
    handleCloseOSDMCancelOrderDialog: PropTypes.func.isRequired,
    handleOpenOSDMCancelOrderDialog: PropTypes.func.isRequired,
    handleAllowAnyCardCollect: PropTypes.func.isRequired,
    handleCloseAuthorizeRefundDialog: PropTypes.func.isRequired,
    handleCloseCancelOrderDialog: PropTypes.func.isRequired,
    handleCloseConfirmedCardDialog: PropTypes.func.isRequired,
    handleCloseConfirmOrderDialog: PropTypes.func.isRequired,
    handleCloseEmailConfirmationDialog: PropTypes.func.isRequired,
    handleCloseExchangeOrderDialog: PropTypes.func.isRequired,
    handleCloseResendTicketDialog: PropTypes.func.isRequired,
    handleCloseReturnCouponsDialog: PropTypes.func.isRequired,
    handleCloseStationInfoDialog: PropTypes.func.isRequired,
    handleCloseTicketDelivery: PropTypes.func.isRequired,
    handleCloseTrackingInfoDialog: PropTypes.func.isRequired,
    handleCloseVoidOrderDialog: PropTypes.func.isRequired,
    handleOpenAuthorizeRefundDialog: PropTypes.func.isRequired,
    handleOpenCancelOrderDialog: PropTypes.func.isRequired,
    handleOpenConfirmedCardDialog: PropTypes.func.isRequired,
    handleOpenConfirmOrderDialog: PropTypes.func.isRequired,
    handleOpenEmailConfirmationDialog: PropTypes.func.isRequired,
    handleOpenExchangeOrderDialog: PropTypes.func.isRequired,
    handleOpenResendTicketDialog: PropTypes.func.isRequired,
    handleOpenReturnCouponsDialog: PropTypes.func.isRequired,
    handleOpenStationInfoDialog: PropTypes.func.isRequired,
    handleOpenTicketDelivery: PropTypes.func.isRequired,
    handleOpenTrackingInfoDialog: PropTypes.func.isRequired,
    handleOpenVoidOrderDialog: PropTypes.func.isRequired,
    handleSupplierNotesDialogDisplay: PropTypes.func.isRequired,
    handleCloseAmendSeatReservationDialog: PropTypes.func.isRequired,
    handleOpenAmendSeatReservationDialog: PropTypes.func.isRequired,
    ignoreDisableEmailSendingSuppliers: PropTypes.array.isRequired,
    onAmendCTR: PropTypes.func.isRequired,
    orderAccount: PropTypes.object.isRequired,
    showAmendCTR: PropTypes.bool.isRequired,
    showExchangeOrderButton: PropTypes.bool.isRequired,
    showVoidOrderButton: PropTypes.bool.isRequired,
    voidIsExpired: PropTypes.func.isRequired,
    isProcessingAction: PropTypes.bool.isRequired,
};

export { SuperOrdersView as SuperOrdersViewAlias };

export default SuperOrdersView;
