import React, { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getFeatureTogglesApi, toggleFeatureApi } from '../apiAdmin';
import ListSubheader from '@mui/material/ListSubheader';
import styles from '../FeatureToggles/styles';
import messages from '../messagesAdmin';
import Switch from '@mui/material/Switch';
import ExtendedSnackbar from '../../components/ExtendedSnackbar/ExtendedSnackbar';
import { FormattedMessage } from 'react-intl';

export const FeatureToggles = () => {
    const [featureToggles, setFeatureToggles] = useState([]);
    const [data, setData] = useState({ alertText: '', isProcessing: false });

    const getFeatureToggles = () => {
        getFeatureTogglesApi(
            (response) => {
                setData({ ...data, alertText: response, isProcessing: false });
            },
            (response) => {
                setFeatureToggles(response);
                setData({ ...data, isProcessing: false });
            },
        );
    };

    const handleSnackbarClose = () => {
        setData({ ...data, alertText: '' });
    };

    const handleFeatureToggle = (event, checked, key) => {
        toggleFeatureApi(key, (response) => {
            setData({
                ...data,
                alertText: response,
                isProcessing: false,
            });
        },
            (response) => {
                getFeatureToggles();
                setData({
                    ...data,
                    isProcessing: false,
                });
            });
    };

    useEffect(() => {
        getFeatureToggles();
    }, []);

    return (
        <div className="row">
            <div className="col-12">
                <div className="row">
                    <div className="col-6">
                        <ListSubheader style={styles.subheader}><FormattedMessage {...messages.lblFeatureToggles} /></ListSubheader>
                    </div>
                </div>
            </div>
            <div className="col-6">
                {Object.keys(featureToggles)?.map((key, index) => (
                    <FormControlLabel
                        key={key}
                        label={key}
                        style={styles.label}
                        control={(
                            <Switch
                                id={`srt${key}`}
                                checked={featureToggles[key]}
                                onChange={(event, checked) => handleFeatureToggle(event, checked, key)}
                            />
                        )}
                    />
                ))}
            </div>
            <ExtendedSnackbar
                id="srtHeaderSnackBar"
                open={data?.alertText !== ''}
                message={data?.alertText || ''}
                onClose={handleSnackbarClose}
            />
        </div>
    );
};

export default FeatureToggles;
