import React from 'react';
import PropTypes from 'prop-types';

import Switch from '@mui/material/Switch';
import { FormattedMessage } from 'react-intl';

import { formatPrice } from '../../../utils';
import messages from './messagesIssueRefund';
import IssueRefundTabSelector from './IssueRefundTabSelector';
import SendToEmail from '../SendToEmail/SendToEmail';

const checkForAlternativeEmail = (ticketDelivery) => ['ETK', 'PAH', 'EML'].includes(ticketDelivery);

const sendTicketData = (booking) => {
    const bookingOrder = booking.BookingOrders[0];
    const bookingOrderTicketDelivery = bookingOrder.BookingOrderTicketDelivery;
    const emails = bookingOrder.BookingEmailForClaimVD;
    const isSms = bookingOrderTicketDelivery.indexOf('SMS') === 0;

    const hasEmail = (
        emails
        && emails.length === 1
        && !checkForAlternativeEmail(bookingOrderTicketDelivery)
    );
    const hasMultipleEmail = (emails
        && emails.length > 1)
        || checkForAlternativeEmail(bookingOrderTicketDelivery);

    return {
        emails,
        isSms,
        hasEmail,
        hasMultipleEmail,
    };
};

const Content = ({
    data,
    booking,
    intl,
    issueRefund,
    sendTicket,
    smsCodeOptions,
    issueRefundDialog,
    handleChange,
    handleChangeEmail,
    handleChangeSms,
    onTabChange,
    showIssueRefundData,
}) => {
    if (issueRefund && sendTicket) {
        if (data.showWelcomeConfirmation) {
            return (
                <>
                    <div className="row">
                        <Switch
                            id="refund"
                            onChange={handleChange('Refund')}
                        />
                        <FormattedMessage
                            {...messages.lblWelcomeConfirmation}
                            values={{ amount: formatPrice(data.refundAmount, data.refundCurrency, intl) }}
                        />
                    </div>
                    <div className="row">
                        <Switch
                            id="sendTicket"
                            onChange={handleChange('SentTicket')}
                        />
                        <FormattedMessage
                            {...messages.lblWelcomeConfirmationSendTicket}
                        />
                    </div>
                </>
            );
        }
        if (data.showIssueRefund || issueRefundDialog) {
            return (
                <>
                    {
                        data.refundDetails.BookingRefundablePayments
                        && data.refundDetails.BookingRefundablePayments.length > 1
                        && (
                            <IssueRefundTabSelector
                                defaultSelecetedTabValue={data.refundDetails.BookingRefundablePayments[0].BookingBillingPaymentReceiptNumber}
                                refundablePayments={data.refundDetails.BookingRefundablePayments}
                                tabChange={onTabChange}
                            />
                        )
                    }
                    {showIssueRefundData(data)}
                </>
            );
        }
        if (data.showSendTicket) {
            const {
                emails,
                isSms,
                hasEmail,
                hasMultipleEmail,
            } = sendTicketData(booking);

            return (
                <SendToEmail
                    vdCurrEmail={data.vdEmail}
                    showAlternateEmail={data.showAlternateEmail}
                    onChangeEmail={handleChangeEmail}
                    onChangeSms={handleChangeSms}
                    hasEmail={hasEmail}
                    hasMultipleEmail={hasMultipleEmail}
                    isSms={isSms}
                    smsNumber={data.smsNumber}
                    smsCountryCode={data.smsCountryCode}
                    smsCodeOptions={smsCodeOptions}
                    emails={emails}
                    errors={data.errors}
                    disabled={data.isFetching || data.isProcessingSubmit}
                />
            );
        }
        return null;
    }

    if (issueRefund) {
        return (
            <>
                {
                    data.refundDetails.BookingRefundablePayments
                    && data.refundDetails.BookingRefundablePayments.length > 1
                    && (
                        <IssueRefundTabSelector
                            defaultSelecetedTabValue={data.refundDetails.BookingRefundablePayments[0].BookingBillingPaymentReceiptNumber}
                            refundablePayments={data.refundDetails.BookingRefundablePayments}
                            tabChange={onTabChange}
                        />
                    )
                }
                {showIssueRefundData(data)}
            </>
        );
    }

    if (sendTicket) {
        const {
            emails,
            isSms,
            hasEmail,
            hasMultipleEmail,
        } = sendTicketData(booking);

        return (
            <>
                {
                    data.showWelcomeConfirmation && (
                        <FormattedMessage
                            {...messages.lblWelcomeConfirmationSendTicket}
                        />
                    )
                }
                {
                    !data.showWelcomeConfirmation && (
                        <SendToEmail
                            vdCurrEmail={data.vdEmail}
                            showAlternateEmail={data.showAlternateEmail}
                            onChangeEmail={handleChangeEmail}
                            onChangeSms={handleChangeSms}
                            hasEmail={hasEmail}
                            hasMultipleEmail={hasMultipleEmail}
                            isSms={isSms}
                            smsNumber={data.smsNumber}
                            smsCountryCode={data.smsCountryCode}
                            smsCodeOptions={smsCodeOptions}
                            emails={emails}
                            errors={data.errors}
                            disabled={data.isFetching || data.isProcessingSubmit}
                        />
                    )
                }
            </>
        );
    }

    return null;
};

Content.propTypes = {
    data: PropTypes.object,
    booking: PropTypes.object,
    intl: PropTypes.object,
    issueRefund: PropTypes.bool,
    sendTicket: PropTypes.bool,
    smsCodeOptions: PropTypes.array,
    issueRefundDialog: PropTypes.bool,
    handleChange: PropTypes.func,
    handleChangeEmail: PropTypes.func,
    handleChangeSms: PropTypes.func,
    onTabChange: PropTypes.func,
    showIssueRefundData: PropTypes.func,
};

export default Content;
