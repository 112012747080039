import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import validate from 'validate.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import ExtendedSnackbar from '../../../../components/ExtendedSnackbar/ExtendedSnackbar';
import { updatePassengerCustomInformationApi } from '../../../apiBooking';
import { fetchBookingDetails } from '../../actionsManageBooking';
import CustomInfo from '../../../../components/CustomInfo/CustomInfo';
import { gaEvent } from '../../../../utils/googleAnalytics';

import messages from './messages';

validate.validators.duplicated = (value, options, key, attributes) => {
    const haveDuplicated = options.elements.some((element) => element !== attributes && element[key] === value);
    return haveDuplicated ? options.message : null;
};

class PassengerCustomInformation extends Component {
    state = {
        customInfoItems: fromJS(this.props.customInformation ? this.props.customInformation.map((item) => ({
            type: item.type || item.BookingPaxCustomInformationType,
            value: item.value || item.BookingPaxCustomInformationValue,
            required: false,
            typeReadOnly: this.props.source === 'MANAGE',
        })) : []),
        errors: fromJS([]),
        processingSubmit: false,
        alertText: '',
    }

    handleAddCustomInfo = () => {
        const { source } = this.props;
        this.setState((state) => ({
            customInfoItems: state.customInfoItems.push(
                fromJS({ type: '', value: '', required: false }),
            ),
        }));
        gaEvent('passengerCustomInformationAdd', source);
    };

    handleChangeCustomInfo = (index, updates) => {
        this.setState((state) => ({
            customInfoItems: state.customInfoItems.mergeIn([index], updates),
        }));
    };

    handleRemoveCustomInfo = (index) => {
        const { source } = this.props;
        this.setState((state) => ({
            customInfoItems: state.customInfoItems.delete(index),
        }));
        gaEvent('passengerCustomInformationRemove', source);
    };

    submit = () => {
        const {
            intl: { formatMessage },
            updateBooking,
            queryItems,
            passengerId,
            passengerName,
            dialogClose,
            source,
            onSave,
        } = this.props;
        const { customInfoItems } = this.state;
        this.setState({ processingSubmit: true });

        const customInfoItemsPlain = customInfoItems.toJS();
        const customInfoConstraints = {
            type: {
                length: {
                    maximum: 255,
                    tooLong: formatMessage(messages.errCustomInfoTypeValueTooLong),
                },
                presence: { message: formatMessage(messages.errCustomInfoType), allowEmpty: false },
                duplicated: {
                    elements: customInfoItemsPlain,
                    message: formatMessage(messages.errCustomInfoTypeDuplicate),
                },
            },
            value: {
                length: {
                    maximum: 255,
                    tooLong: formatMessage(messages.errCustomInfoTypeValueTooLong),
                },
                presence: { message: formatMessage(messages.errCustomInfoValue), allowEmpty: false },
            },
        };

        const errors = [];
        customInfoItemsPlain.forEach((customInfoItem, index) => {
            const error = validate(customInfoItem, customInfoConstraints);
            if (error) {
                error.index = index;
                errors.push(error);
            }
        });

        this.setState({ errors: fromJS(errors) });

        if (validate.isEmpty(errors)) {
            if (source === 'MANAGE') {
                updatePassengerCustomInformationApi(
                    {
                        queryItems,
                        passengerId,
                        passengerName,
                        passengerCustomInformation: customInfoItemsPlain,
                    },
                    (response) => {
                        this.setState({
                            alertText: response.errorResponse.message || response.errorResponse.errorMessage,
                            processingSubmit: false,
                        });
                    },
                    () => {
                        updateBooking(queryItems);
                        dialogClose(false);
                    },
                );
            } else {
                onSave(customInfoItemsPlain);
                dialogClose(false);
            }
        } else {
            this.setState({
                processingSubmit: false,
            });
        }
    }

    handleSnackBarClose = () => {
        this.setState({ alertText: '' });
    };

    render() {
        const {
            dialogClose,
            open,
            passengerName,
        } = this.props;
        const {
            customInfoItems,
            errors,
            alertText,
            processingSubmit,
        } = this.state;
        return (
            <>
                <Dialog open={open} onClose={dialogClose}>
                    <DialogTitle>
                        <FormattedMessage {...messages.lblTitle} values={{ passenger: passengerName }} />
                    </DialogTitle>
                    <DialogContent>
                        <CustomInfo
                            customInfoItems={customInfoItems.toJS()}
                            hideRequired
                            showAddButton
                            changeCustomInfo={this.handleChangeCustomInfo}
                            addCustomInfo={this.handleAddCustomInfo}
                            removeCustomInfo={this.handleRemoveCustomInfo}
                            showRemove
                            errors={errors.toJS()}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            className="srtPassengerCustomInformationClose"
                            onClick={dialogClose}
                            disabled={processingSubmit}
                        >
                            <FormattedMessage {...messages.lblCloseButton} />
                        </Button>
                        <Button
                            id="saveCustomInfoBtn"
                            variant="contained"
                            className="srtPassengerCustomInformationSave"
                            onClick={this.submit}
                            color="primary"
                            disabled={processingSubmit}
                        >
                            <FormattedMessage {...messages.lblSaveButton} />
                        </Button>
                    </DialogActions>
                </Dialog>
                <ExtendedSnackbar
                    id="srtPassengerCustomInformationSnackBar"
                    open={alertText !== ''}
                    message={alertText}
                    onClose={this.handleSnackBarClose}
                />
            </>
        );
    }
}

PassengerCustomInformation.propTypes = {
    customInformation: PropTypes.array,
    dialogClose: PropTypes.func.isRequired,
    intl: PropTypes.object,
    onSave: PropTypes.func,
    open: PropTypes.bool.isRequired,
    passengerId: PropTypes.string,
    passengerName: PropTypes.string,
    queryItems: PropTypes.object,
    source: PropTypes.oneOf(['CREATE', 'MANAGE']),
    updateBooking: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    updateBooking: fetchBookingDetails,
};

export { PassengerCustomInformation as PassengerCustomInformationAlias };

export default connect(undefined, mapDispatchToProps)(injectIntl(PassengerCustomInformation));
