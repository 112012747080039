import { defineMessages } from 'react-intl';

export default defineMessages({
    lblBillingSummary: {
        id: 'app.shopping.manageBooking.orderBillingSummary.billingSummary',
        defaultMessage: 'Billing Summary',
    },
    lblBillingTotal: {
        id: 'app.shopping.manageBooking.orderBillingSummary.billingTotal',
        defaultMessage: 'Total',
    },
    lblBillingDate: {
        id: 'app.shopping.manageBooking.orderBillingSummary.billingDate',
        defaultMessage: 'Transaction Date',
    },
    lblBillingDescription: {
        id: 'app.shopping.manageBooking.orderBillingSummary.billingDescription',
        defaultMessage: 'Description',
    },
    lblBillingPrice: {
        id: 'app.shopping.manageBooking.orderBillingSummary.billingPrice',
        defaultMessage: 'Price',
    },
});
