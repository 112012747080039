import { lightGray } from '../../../colors';

export default {
    dialogPaper: {
        overflowY: 'visible',
    },
    dialogActionsRoot: {
        width: '100%',
    },
    dialogContent: {
        borderTop: `1px solid ${lightGray}`,
        borderBottom: `1px solid ${lightGray}`,
    },
    refreshIndicator: {
        zIndex: 2000,
        marginTop: '35%',
        marginLeft: '48%',
    },
};
