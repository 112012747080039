import { defineMessages } from 'react-intl';

export default defineMessages({
    lblTitle: {
        id: 'app.shopping.makePayment.title',
        defaultMessage: 'Make Payment',
    },
    btnCancel: {
        id: 'app.shopping.makePayment.cancel',
        defaultMessage: 'Cancel',
    },
    btnSubmit: {
        id: 'app.shopping.makePayment.submit',
        defaultMessage: 'Submit Payment',
    },
    lblBalanceDue: {
        id: 'app.shopping.makePayment.balanceDue',
        defaultMessage: 'Balance Due',
    },
    lblMinimumDepositAmount: {
        id: 'app.shopping.makePayment.minimumDepositAmount',
        defaultMessage: 'Minimum Deposit Amount',
    },
    lblDepositDue: {
        id: 'app.shopping.makePayment.depositDue',
        defaultMessage: 'Deposit Due',
    },
    lblPaymentAmount: {
        id: 'app.shopping.makePayment.paymentAmount',
        defaultMessage: 'Payment Amount',
    },
    lblPaymentDate: {
        id: 'app.shopping.makePayment.paymentDate',
        defaultMessage: 'Payment Date',
    },
    lblPaymentInformation: {
        id: 'app.shopping.makePayment.paymentInformation',
        defaultMessage: 'Payment Information',
    },
    lblBillingAddress: {
        id: 'app.shopping.makePayment.billingAddress',
        defaultMessage: 'Billing Address',
    },
    lblTermsOfService: {
        id: 'app.shopping.makePayment.termsOfService',
        defaultMessage: 'Terms Of Service',
    },
    errCountryEmpty: {
        id: 'app.admin.makePayment.countryEmpty',
        defaultMessage: '^Please select a Country',
    },
    errStateEmpty: {
        id: 'app.admin.makePayment.stateEmpty',
        defaultMessage: '^Please select a State',
    },
    errProvinceEmpty: {
        id: 'app.admin.makePayment.provinceEmpty',
        defaultMessage: '^Please select a Province',
    },
    errAddressLine1Empty: {
        id: 'app.admin.makePayment.addressEmpty',
        defaultMessage: '^Please enter an Address',
    },
    errCityEmpty: {
        id: 'app.admin.makePayment.cityEmpty',
        defaultMessage: '^Please enter a City',
    },
    errPostalCodeEmpty: {
        id: 'app.admin.makePayment.postalCodeEmpty',
        defaultMessage: '^Please enter a Postal Code',
    },
    errPostalCodeInvalid: {
        id: 'app.admin.makePayment.postalCodeInvalid',
        defaultMessage: '^Please enter a valid Postal Code',
    },
    // errors payment
    errPaymentMethodEmpty: {
        id: 'app.admin.makePayment.paymentMethodEmpty',
        defaultMessage: '^Please select a Payment Method',
    },
    errPaymentCardNameEmpty: {
        id: 'app.admin.makePayment.paymentCardNameEmpty',
        defaultMessage: '^Please enter a Name',
    },
    errPaymentCardNumberEmpty: {
        id: 'app.admin.makePayment.paymentCardNumberEmpty',
        defaultMessage: '^Please enter a Card Number',
    },
    errPaymentCardNumberInvalid: {
        id: 'app.admin.makePayment.paymentCardNumberInvalid',
        defaultMessage: '^Please enter a valid Card Number',
    },
    errDateInvalid: {
        id: 'app.admin.makePayment.dateInvalid',
        defaultMessage: '^Please enter a valid Month',
    },
    errYearInvalid: {
        id: 'app.admin.makePayment.yearInvalid',
        defaultMessage: 'Please enter a valid Year',
    },
    errMonthInvalid: {
        id: 'app.admin.makePayment.monthInvalid',
        defaultMessage: 'Please enter a valid Month',
    },
    errDayInvalid: {
        id: 'app.admin.makePayment.dayInvalid',
        defaultMessage: 'Please enter a valid Day',
    },
    errPaymentIssueNumber: {
        id: 'app.admin.makePayment.paymentIssueNumberEmpty',
        defaultMessage: '^Please enter an Issue Number',
    },
    errSecurityCode: {
        id: 'app.admin.makePayment.paymentSecurityCodeInvalid',
        defaultMessage: '^Please enter a valid Security Code',
    },
    errTermsOfService: {
        id: 'app.admin.makePayment.termsOfServiceNotSelected',
        defaultMessage: '^You must agree to the Terms and Conditions and National Rail Conditions of Travel to make a confirmed payment.',
    },
    errConfirmationFirstName: {
        id: 'app.admin.makePayment.confirmationFirstNameEmpty',
        defaultMessage: '^Please enter a First Name',
    },
    errConfirmationLastName: {
        id: 'app.admin.makePayment.confirmationLastNameEmpty',
        defaultMessage: '^Please enter a Last Name',
    },
    errConfirmationSortCode: {
        id: 'app.admin.makePayment.confirmationSortCodeEmpty',
        defaultMessage: '^Please enter a valid Sort Code',
    },
    errConfirmationLoyaltyProgram: {
        id: 'app.admin.makePayment.confirmationLoyaltyProgramEmpty',
        defaultMessage: '^Please select a Loyalty Card Program',
    },
    errConfirmationLoyaltyCardIdentifier: {
        id: 'app.admin.makePayment.confirmationLoyaltyCardIdentifierEmpty',
        defaultMessage: '^Please enter a valid Loyalty Card Identifier',
    },
    errConfirmationEmail: {
        id: 'app.admin.makePayment.confirmationEmailEmpty',
        defaultMessage: '^Please enter a valid Email',
    },
    lblEmailSentTo: {
        id: 'app.booking.makePayment.emailSentTo',
        defaultMessage: 'Sent booking confirmation to: {email}.',
    },
    errNoPaymentMethods: {
        id: 'app.admin.makePayment.noPaymentMethods',
        defaultMessage: 'No payment methods have been configured for this account. Please contact your administrator.',
    },
});
