export default {
    buttons: {
        margin: 5,
    },
    dialogPaper: {
        overflowY: 'hidden',
        fontSize: 12,
    },
    content: {
        fontSize: 12,
        height: 'auto',
    },
};
