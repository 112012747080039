import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import '../../ManageBooking/components/BookingView/styleBookingView.css';

const SeasonSegmentRow = ({
    origin,
    origCode,
    destination,
    destCode,
    startDate,
    endDate,
    cabinClass,
    fare,
    onOpenFareRules,
    price,
    numberOfPasses,
    unusedPasses,
    handleOpenStationInfoDialog,
    isExchange,
    selectedTicketableFareId,
}) => {
    const [fareId, setFareId] = useState();

    useEffect(() => {
        if (selectedTicketableFareId) {
            setFareId(selectedTicketableFareId);
        }
    }, [selectedTicketableFareId]);

    const renderStation = (station, code) => (
        <a
            styleName="link"
            onClick={handleOpenStationInfoDialog(code)}
        >
            {station}
        </a>
    );

    const handleCheckField = (event, value) => {
        setFareId(value);
    };

    return (
        <TableRow id="srtSeasonSegmentRow">
            {
                isExchange && fareId && (
                    <TableCell styleName="bookingTableRow">
                        <RadioGroup
                            name="exchangeSeason"
                            onChange={handleCheckField}
                            defaultValue={fareId}
                            value={fareId}
                        >
                            <FormControlLabel
                                id="srtSpecificSeat"
                                key="srtSpecificSeat"
                                value={fareId}
                                control={<Radio />}
                                label=""
                            />
                        </RadioGroup>
                    </TableCell>
                )
            }
            <TableCell styleName="bookingTableRow">
                {renderStation(origin, origCode)}
            </TableCell>
            <TableCell styleName="bookingTableRow">
                {renderStation(destination, destCode)}
            </TableCell>
            <TableCell styleName="bookingTableRow">
                {startDate}
            </TableCell>
            <TableCell styleName="bookingTableRow">
                {endDate}
            </TableCell>
            <TableCell styleName="bookingTableRow">
                {cabinClass}
            </TableCell>
            <TableCell
                styleName="bookingTableRow"
                style={{ textTransform: 'capitalize' }}
            >
                <a styleName="link" onClick={onOpenFareRules}>
                    {fare}
                </a>
            </TableCell>
            <TableCell styleName="bookingTableRow">
                {numberOfPasses}
            </TableCell>
            <TableCell styleName="bookingTableRow">
                {unusedPasses}
            </TableCell>
            {price && (
                <TableCell styleName="bookingTableRow">
                    {price}
                </TableCell>
            )}
        </TableRow>
    );
};

SeasonSegmentRow.propTypes = {
    cabinClass: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    destCode: PropTypes.string,
    destination: PropTypes.string,
    fare: PropTypes.string,
    handleOpenStationInfoDialog: PropTypes.func.isRequired,
    onOpenFareRules: PropTypes.func,
    origCode: PropTypes.string,
    origin: PropTypes.string,
    price: PropTypes.string,
    numberOfPasses: PropTypes.string,
    unusedPasses: PropTypes.string,
    isExchange: PropTypes.bool,
    selectedTicketableFareId: PropTypes.string,
};

export default SeasonSegmentRow;
