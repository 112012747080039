import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import SeasonTable from '../OrderDetailsTable/SeasonTable';
import OrderDetailsTable from '../OrderDetailsTable/OrderDetailsTable';
import { ORIGINS } from '../OrderDetailsTable/constants';
import OrderSummaryBalance from '../../../components/OrderSummary/OrderSummaryBalance';
import summaryMessages from '../../../components/OrderSummary/messagesOrderSummary';
import messages from './messagesExchangeOrder';
import { formatPrice } from '../../../utils';
import './stylesExchangeOrder.css';

const ExchangeOrderView = ({
    intl,
    intl: { formatMessage },
    onTicketableFareChange,
    orderDetails,
    selectedTicketableFareId,
    ticketableFaresExchangeable,
    isSeasonTrip,
    seasonFareDetails,
    fareRulesDetails,
    bookingBillingSummary,
}) => {
    const getExchangeAmounts = (bookingOrder) => {
        const transactionFeeAmount = Number(bookingOrder.get('BookingTransactionFeeAmount'));
        const ticketingOptionAmount = Number(bookingOrder.get('BookingTicketingOptionAmount'));
        let nonExchangeableAmount = transactionFeeAmount + ticketingOptionAmount;

        let tfPenaltyAmount;
        let isPenaltyAmountTBD = false;

        ticketableFaresExchangeable.filter((tfExchangeable) => {
            isPenaltyAmountTBD = tfExchangeable.get('ticketableFarePenalty') === 'TBD';
            return null;
        });

        if (!isPenaltyAmountTBD) {
            tfPenaltyAmount = ticketableFaresExchangeable.reduce((sum, tfExchangeable) => (
                sum + (tfExchangeable.get('ticketableFareId') === selectedTicketableFareId
                    ? Number(tfExchangeable.get('ticketableFarePenalty'))
                    : 0)),
            0);
            nonExchangeableAmount += tfPenaltyAmount;
        } else {
            tfPenaltyAmount = 'TBD';
            nonExchangeableAmount = 0;
        }

        return {
            transactionFeeAmount,
            ticketingOptionAmount,
            tfPenaltyAmount,
            nonExchangeableAmount,
        };
    };

    const prepData = () => {
        let bookingOrder = {};
        let exchangeCurrency = '';
        let tfTotalPrice = 0.0;
        let tfPenaltyAmount = 0;
        let previousRevenue = 0;
        let paymentTotal = 0;
        let previousBalance = 0;

        const recapTableRows = [];
        const recapTableFooterRows = [];

        const isDataReceived = orderDetails.size > 0;

        if (isDataReceived) {
            bookingOrder = orderDetails.getIn(['BookingOrders', 0]);
            const ticketableFareGroups = bookingOrder.get('ticketableFareGroups');
            exchangeCurrency = ticketableFareGroups.getIn([0, 'ticketableFareCurrency']);

            const exchangeAmounts = getExchangeAmounts(bookingOrder);
            tfPenaltyAmount = exchangeAmounts.tfPenaltyAmount;

            bookingBillingSummary.forEach((item) => {
                previousRevenue += Number(item.BookingBillingRevenue);
                paymentTotal += Number(item.BookingBillingReceipt);
            });
            previousBalance = previousRevenue - paymentTotal;

            recapTableRows.push({
                id: 'srtExchangesPreviousRevenue',
                text: formatMessage(summaryMessages.previousRevenue),
                price: formatPrice(previousRevenue, exchangeCurrency, intl),
            });
            recapTableRows.push({
                id: 'srtExchangesPaymentTotal',
                text: formatMessage(summaryMessages.paymentTotal),
                price: formatPrice(paymentTotal, exchangeCurrency, intl),
            });
            recapTableRows.push({
                id: 'srtExchangesPreviousBalance',
                text: formatMessage(summaryMessages.previousBalance),
                price: formatPrice(previousBalance, exchangeCurrency, intl),
            });
            recapTableRows.push({
                id: 'srtExchangesReversalPreviousProductSale',
                text: formatMessage(summaryMessages.reversalPreviousProductSale),
                price: null,
            });
            recapTableRows.push({
                id: 'srtExchangesProductSale',
                text: formatMessage(summaryMessages.productSale),
                price: formatMessage(summaryMessages.toBeDefined),
            });
            recapTableRows.push({
                id: 'srtExchangesTicketDelivery',
                text: formatMessage(summaryMessages.tdoFee),
                price: formatMessage(summaryMessages.toBeDefined),
            });

            if (tfPenaltyAmount === 'TBD') {
                recapTableRows.push({
                    id: 'srtNonExchangeablePenalty',
                    text: formatMessage(summaryMessages.exchangePenalty),
                    price: formatMessage(summaryMessages.toBeDefined),
                });
            } else {
                recapTableRows.push({
                    id: 'srtNonExchangeablePenalty',
                    text: formatMessage(summaryMessages.exchangePenalty),
                    price: formatPrice(tfPenaltyAmount, exchangeCurrency, intl),
                });
            }
            recapTableFooterRows.push({
                id: 'srtExchangesRevenueTotal',
                text: formatMessage(summaryMessages.revenueTotal),
                price: formatMessage(summaryMessages.toBeDefined),
            });

            recapTableFooterRows.push({
                id: 'srtExchangesBalance',
                text: formatMessage(summaryMessages.balance),
                price: formatMessage(summaryMessages.toBeDefined),
            });

            ticketableFareGroups.forEach((ticketableFareGroup) => {
                const tfCurrency = ticketableFareGroups.get('ticketableFareCurrency') || exchangeCurrency;
                if (ticketableFareGroup.get('BookingDetailsLegTicketableFareIds') === selectedTicketableFareId) {
                    tfTotalPrice += Number(ticketableFareGroup.get('ticketableFarePrice'));
                }
                recapTableRows[3].price = `-${formatPrice(tfTotalPrice, tfCurrency, intl)}`;
            });
        }

        return {
            recapTableRows,
            recapTableFooterRows,
        };
    };

    const {
        recapTableRows,
        recapTableFooterRows,
    } = prepData();

    const bookingOrder = orderDetails.getIn(['BookingOrders', 0]);
    const promotionDetails = orderDetails.get('BookingOrderSilverPromos')?.toJS();

    return (
        <div className="container-fluid" styleName="srtExchangeContainer">
            {isSeasonTrip && (
                <SeasonTable
                    orderDetails={seasonFareDetails.toJS()}
                    origin="EXCHANGE"
                    fareRulesDetails={fareRulesDetails}
                    selectedTicketableFareId={selectedTicketableFareId}
                />
            )}
            {!isSeasonTrip && (
                <div className="row">
                    <div className="col-12" styleName="srtExchangeItemsContainer">
                        {bookingOrder && (
                            <OrderDetailsTable
                                fareRulesDetails={bookingOrder.get('BookingLegFareRulesDetails').toJS()}
                                isSingleTicketableFares={bookingOrder.get('BookingOrderIsSingleTicketableFares')}
                                orderDetails={orderDetails.getIn(['BookingOrders', 0]).toJS()}
                                origin={ORIGINS.EXCHANGE}
                                showSelectionRadio
                                onTicketableFareChange={onTicketableFareChange}
                                radioTitle={formatMessage(messages.lblExchangeItem)}
                                selectedTicketableFareId={selectedTicketableFareId}
                                ticketableFaresSelectable={ticketableFaresExchangeable.toJS()}
                                orderPriceKey="BookingTotalPrice"
                                promotionDetails={promotionDetails}
                                isExchange
                            />
                        )}
                    </div>
                    <div className="col-3" />
                    <div className="col-6">
                        <OrderSummaryBalance
                            intl={intl}
                            tableData={recapTableRows}
                            tableFooterData={recapTableFooterRows}
                            preliminaryExchangeRecap
                        />
                    </div>
                    <div className="col-3" />
                </div>
            )}
        </div>
    );
};

ExchangeOrderView.propTypes = {
    bookingBillingSummary: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
    onTicketableFareChange: PropTypes.func.isRequired,
    orderDetails: PropTypes.object.isRequired,
    selectedTicketableFareId: PropTypes.string,
    ticketableFaresExchangeable: PropTypes.object.isRequired,
    isSeasonTrip: PropTypes.bool,
    seasonFareDetails: PropTypes.object,
    fareRulesDetails: PropTypes.array,
};

const mapStateToProps = (state) => ({
    fareRulesDetails: state.getIn(['booking', 'orderDetails', 'BookingLegFareRulesDetails']).toJS(),
    seasonFareDetails: state.getIn(['booking', 'orderDetails', 'BookingSeasonFareDetails']),
});

export { ExchangeOrderView as ExchangeOrderViewAlias };

export default connect(mapStateToProps, null)(injectIntl(ExchangeOrderView));
