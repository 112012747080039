import {
    stationSearch,
    bookingValidate,
    tdoSmsCallingCodes,
    exchangeSummary,
    bookingCreate,
    bookingSetTdo,
    bookingAddOrder,
    shoppingSearch,
    exchangeSearch,
    refreshUserState,
    updateCustomInfo,
    getIntermediateTravelPoints,
} from '../api';

import {
    TRIP_TYPE_RETURN,
    TRIP_TYPE_SEASON,
    CORPORATE,
    PROMOTION,
    UK_CORPORATE,
    UK_TADA,
    getTravelCardIds,
} from './util';
import { transformCustomInformation } from '../utils';
import { gaSetAccount } from '../utils/googleAnalytics';
import { cleanDate } from '../utils/datetimeUtils';

const dateFormat = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
};
const timeFormat = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
};

export const stationSearchApi = (params, error, success, abortController) => stationSearch(
    {
        srtStationStartsWith: params.searchText,
        srtOtherStationCode: params.otherStationCode,
        srtDisableSupplierFiltering: false,
        srtIncludeTravelCards: params.includeTravelCards,
        itineraryStationsAllowed: 'Requestable',
    },
    error,
    success,
    abortController,
);

export const bookingValidateApi = (params, error, success) => bookingValidate(
    {
        srtPriceIds: params.priceIds.join(','),
        srtTripType: params.tripType,
        srtExchangeMode: params.exchangeMode,
        srtAccountName: params.accountName,
        requestorId: params.requestorId,
        dataStorageId: params.storageId,
    },
    error,
    success,
);

export const tdoSmsCallingCodesApi = (tdoCode, error, success) => tdoSmsCallingCodes(
    { bookingTDOCode: tdoCode },
    error,
    success,
);

export const exchangeSummaryApi = (paramsArguments, error, success) => {
    const {
        shoppingContext,
        tripType,
        faresSelected,
        sessionToken,
        selectedTdo,
        exchangeableTicketableFareIds,
        travelCards,
        seatReservations,
        recordLocator,
        orderId,
    } = paramsArguments;

    const priceIds = faresSelected.map((selectedFare) => (
        selectedFare.getIn(['fare', 'priceId'])
    ));

    const params = {
        srtTicketableFareIds: exchangeableTicketableFareIds.length > 0 ? exchangeableTicketableFareIds.join(',') : '',
        srtRecLocator: recordLocator,
        srtOrderId: orderId,
        srtPriceIds: priceIds.toJS().join(','),
        srtTripType: tripType,
        srtAccountName: shoppingContext,
        requestorId: `srtExchangeBooking.${Date.now()}`,
        dataStorageId: sessionToken,
        srtSelectedTDO: selectedTdo.tdoCode,
        srtSelectedTDODesc: selectedTdo.tdoDesc,
        srtSelectedTDOCurrency: selectedTdo.tdoCurrency,
        srtSelectedTDOFee: selectedTdo.tdoFee,
        srtTravelCardOptPriceID: getTravelCardIds(travelCards),
        srtChkSeatRes: [],
        srtNearbyCoach: [],
        srtNearbySeat: [],
        srtSeatResOptions: [],
    };

    if (!seatReservations.isEmpty()) {
        const travelSegments = seatReservations.keySeq().toList();
        travelSegments.forEach((segment) => {
            const passengers = seatReservations.get(segment).keySeq().toList();
            passengers.forEach((passenger) => {
                if (passenger !== 'enableAllSeats' && passenger !== 'price') {
                    const items = seatReservations.getIn([segment, passenger]).keySeq().toList();
                    items.forEach((item) => {
                        if (item !== 'price') {
                            if (item === 'direction' || item === 'position' || item === 'coach' || item === 'enablePassengerSeats'
                                || item === 'nearbyCoach' || item === 'nearbySeat') {
                                const value = seatReservations.getIn([segment, passenger, item]);
                                if (value) {
                                    const option = `${value}:${passenger}:${segment}`;
                                    if (item === 'enablePassengerSeats') {
                                        params.srtChkSeatRes.push(option);
                                    } else if (item === 'nearbyCoach') {
                                        params.srtNearbyCoach.push(option);
                                    } else if (item === 'nearbySeat') {
                                        params.srtNearbySeat.push(option);
                                    } else {
                                        params.srtSeatResOptions.push(option);
                                    }
                                }
                            } else {
                                const option = `${item}:${passenger}:${segment}`;
                                params.srtSeatResOptions.push(option);
                            }
                        }
                    });
                }
            });
        });
    }

    return exchangeSummary(
        params,
        error,
        success,
    );
};

export const bookingCreateApi = (paramsArguments, error, success) => {
    const {
        intl,
        data,
        faresSelected,
        tripType,
        accountName,
        sessionToken,
    } = paramsArguments;
    const params = {
        srtChkSeatRes: [],
        srtNearbyCoach: [],
        srtNearbySeat: [],
        srtSeatResOptions: [],
        srtMealOnboard: [],
        srtInternetOnboard: [],
        srtTripType: tripType,
        srtAccountName: accountName,
        dataStorageId: sessionToken,
        bikeReservations: [],
        plusBusSupplements: [],
    };

    params.passengers = data.get('passengerInfo').toJS().map((item) => ({
        srtPaxEmail: item.email || data.getIn(['accountData', 'accountEmail']) || '',
        srtPaxEmailType: 'HOME',
        srtPaxTitle: item.title,
        srtPaxFirstName: item.firstName,
        srtPaxLastName: item.lastName,
        srtPaxAddress1: item.address.address1 || '',
        srtPaxAddress2: item.address.address2 || '',
        srtPaxCity: item.address.city || '',
        srtPaxStateProv: item.address.stateOrProvince || '',
        srtPaxPostalCode: item.address.postalCode || '',
        srtPaxAddressType: 'HOME',
        srtPaxPhone: item.phoneNumber || data.getIn(['accountData', 'accountPhone']) || '',
        srtPaxPhoneType: 'HOME',
        srtPaxCountry: item.address.countryCode || '',
        srtTravelDocsGender: item.travelDocs.gender || '',
        srtTravelDocsDOB: item.travelDocs.DOB
            ? intl.formatDate(item.travelDocs.DOB, dateFormat) : '',
        srtTravelDocsType: item.travelDocs.documentType || '',
        srtTravelDocsPassportNumber: item.travelDocs.documentNumber || '',
        srtTravelDocsPassportExpDate: intl.formatDate(item.travelDocs.documentExpDate, dateFormat) || '',
        srtTravelDocsIssueCountry: item.travelDocs.issueCountry || '',
        srtPaxDiscountIdentifier: item.discountsLoyalties.discountIdentifier || '',
        srtPaxDiscountAuthorization: item.discountsLoyalties.discountAuthorization || '',
        srtPaxCorpIdentifier: item.discountsLoyalties.corporateIdentifier || '',
        srtPaxCorpAuthorization: item.discountsLoyalties.corporateAuthorization || '',
        srtPaxLoy: item.discountsLoyalties.loyaltyProgram || '',
        srtPaxLoyaltyIdentifier: item.discountsLoyalties.loyaltyIdentifier || '',
        customInformation: item.customInformation.map((customInformationItem) => ({
            type: customInformationItem.type,
            value: customInformationItem.value,
        })),
    }));

    const priceIds = faresSelected.map((selectedFare) => selectedFare.getIn(['fare', 'priceId']));
    params.srtPriceIds = priceIds.size ? priceIds.join(',') : undefined;
    params.srtTravelCardOptPriceID = getTravelCardIds(data.get('travelCards'));

    const bikeReservations = data?.get('bikeReservations').toJS().map((reservation) => reservation);

    const selectedBikeReservations = data?.get('selectedBikeReservations');
    if (!selectedBikeReservations?.isEmpty()) {
        bikeReservations.forEach((reservation) => {
            const travelSegments = selectedBikeReservations.keySeq().toList();
            travelSegments.forEach((travelSegment) => {
                if (reservation.supplementAvailabilities
                    .some((supplementAvailability) => (supplementAvailability.travelSegmentID === travelSegment))) {
                    const passengers = selectedBikeReservations.get(travelSegment).keySeq().toList();
                    passengers.forEach((passenger) => {
                        const { ...bikeReserved } = {
                            optionalPriceId: reservation.id, travelSegmentId: travelSegment, passengerId: passenger,
                        };
                        params.bikeReservations = [...params.bikeReservations, bikeReserved];
                    });
                }
            });
        });
    }

    const selectedPlusBusSupplements = data?.get('selectedPlusBusSupplements').toJS();

    if (selectedPlusBusSupplements?.length > 0) {
        params.plusBusSupplements = selectedPlusBusSupplements;
    }

    const seatReservations = data.get('seatReservations');
    if (!seatReservations.isEmpty()) {
        const multipleOptionsItems = ['direction', 'position', 'coach', 'level', 'layout', 'enablePassengerSeats', 'nearbyCoach', 'nearbySeat'];
        const travelSegments = seatReservations.keySeq().toList();
        travelSegments.forEach((segment) => {
            const passengers = seatReservations.get(segment).keySeq().toList();
            passengers.forEach((passenger) => {
                if (passenger !== 'enableAllSeats' && passenger !== 'price') {
                    const items = seatReservations.getIn([segment, passenger]).keySeq().toList();
                    items.forEach((item) => {
                        if (item !== 'price') {
                            if (multipleOptionsItems.includes(item)) {
                                const value = seatReservations.getIn([segment, passenger, item]);
                                if (value) {
                                    const option = `${value}:${passenger}:${segment}`;

                                    if (item === 'enablePassengerSeats') {
                                        params.srtChkSeatRes.push(option);
                                    } else if (item === 'nearbyCoach') {
                                        params.srtNearbyCoach.push(option);
                                        params.srtSeatResOptions.push(option);
                                    } else if (item === 'nearbySeat') {
                                        params.srtNearbySeat.push(option);
                                        params.srtSeatResOptions.push(option);
                                    } else {
                                        params.srtSeatResOptions.push(option);
                                    }
                                }
                            } else {
                                const option = `${item}:${passenger}:${segment}`;

                                if (seatReservations.getIn([segment, passenger])?.toJS()[item] && item !== null) {
                                    params.srtSeatResOptions.push(option);
                                }
                            }
                        }
                    });
                }
            });
        });
    }

    if (!data.get('onBoardServices').isEmpty()) {
        data.get('onBoardServices').toArray().forEach((servicesPrice) => {
            if (servicesPrice.get('onBoardServiceCategory') === 'MEAL') {
                params.srtMealOnboard.push(servicesPrice.get('ID'));
            } else if (servicesPrice.get('onBoardServiceCategory') === 'INTERNET') {
                params.srtInternetOnboard.push(servicesPrice.get('ID'));
            }
        });
    }

    if (data.get('referenceNumber') !== '') params.srtReferenceNumber = data.get('referenceNumber');
    if (data.get('purchaseOrderNumber') !== '') params.srtPurchaseOrderNumber = data.get('purchaseOrderNumber');
    if (data.get('customerNumber') !== '') params.srtCustomerNumber = data.get('customerNumber');

    const customInfoRaw = transformCustomInformation(data.get('customInformationItems').toJS());
    if (customInfoRaw !== '') params.srtCustomInfoRaw = customInfoRaw;

    return bookingCreate(
        params,
        error,
        success,
    );
};

export const updateBookingCustomInfo = (data, booking, faresSelected, source, sessionToken, error, success) => {
    const passengers = source === 'createBooking'
        ? booking.BookingPassengers.map((item) => (item.passengerID))
        : data.get('passengerInfo').map((item) => (item.get('passengerId'))).toJS();
    const srtTDOSmartCards = passengers.map((item, index) => `${item},${data.getIn(['selectedTdo', 'tdoSmartCards', index])}`).join(';');

    const params = {
        srtAgencyCode: booking.srtAgencyCode || booking.BookingAgencyCode,
        srtChannelCode: booking.srtChannelCode || booking.BookingChannelCode,
        srtCompanyCode: booking.srtCompanyCode || booking.BookingCompanyCode,
        srtDistCode: booking.srtDistCode || booking.BookingDistributorCode,
        srtPOSCode: booking.srtPOSCode || booking.BookingPOSCode,
        srtRecLocator: booking.srtRecLocator || booking.recordLocator,
        srtSelectedTDO: data.getIn(['selectedTdo', 'tdoCode']) || '',
        srtSelectedTDODesc: data.getIn(['selectedTdo', 'tdoDesc']) || '',
        srtDeliveryPrice: data.getIn(['selectedTdo', 'tdoFee']) || '0.00',
        srtTDOEmailAddress: data.getIn(['selectedTdo', 'tdoPaperEmail']) || '',
        srtDeliveryPriceCurrency: data.getIn(['selectedTdo', 'tdoCurrency']) || '',
        srtTDOSMSToCode: data.getIn(['selectedTdo', 'tdoSmsCountry']) || '',
        srtTDOSMSTo: data.getIn(['selectedTdo', 'tdoSmsNumber']) || '',
        srtTDOShippingName: data.getIn(['selectedTdo', 'tdoMailName']) || '',
        srtTDOShippingAddress: data.getIn(['selectedTdo', 'tdoMailAddress', 'address1']) || '',
        srtTDOShippingAddress2: data.getIn(['selectedTdo', 'tdoMailAddress', 'address2']) || '',
        srtTDOShippingCity: data.getIn(['selectedTdo', 'tdoMailAddress', 'city']) || '',
        srtTDOShippingState: data.getIn(['selectedTdo', 'tdoMailAddress', 'stateOrProvince']) || '',
        srtTDOShippingZip: data.getIn(['selectedTdo', 'tdoMailAddress', 'postalCode']) || '',
        srtTDOTixDeliveryCountry: data.getIn(['selectedTdo', 'tdoMailAddress', 'countryCode']) || '',
        srtOrigin: (source === 'createBooking'
            ? faresSelected.get(0).get('leg').get('ShoppingLegOrigStationName')
            : data.getIn(['validateBooking', 'BookingTDOOrigStationName'])) || '',
        srtDestination: (source === 'createBooking'
            ? faresSelected.get(0).get('leg').get('ShoppingLegDestStationName')
            : data.getIn(['validateBooking', 'BookingOrderDetailsLegTravelSegmentDestStationName'])) || '',
        srtTDOPickupLocation: data.getIn(['selectedTdo', 'tdoPickUpLocation']) || '',
        srtTDOSmartCards,
        srtSource: source,
        srtSessionToken: sessionToken,
    };
    return updateCustomInfo(params, error, success);
};

export const bookingSetTdoApi = (data, booking, faresSelected, source, sessionToken, error, success) => {
    const orderIds = source === 'createBooking'
        ? booking.BookingOrderIds.map((order) => order.BookingOrderId)
        : [booking.BookingOrderId];
    const passengers = source === 'createBooking'
        ? booking.BookingPassengers.map((item) => (item.passengerID))
        : data.get('passengerInfo').map((item) => (item.get('passengerId'))).toJS();
    const srtTDOSmartCards = passengers.map((item, index) => `${item},${data.getIn(['selectedTdo', 'tdoSmartCards', index])}`).join(';');

    const params = {
        srtAgencyCode: booking.srtAgencyCode || booking.BookingAgencyCode,
        srtChannelCode: booking.srtChannelCode || booking.BookingChannelCode,
        srtCompanyCode: booking.srtCompanyCode || booking.BookingCompanyCode,
        srtDistCode: booking.srtDistCode || booking.BookingDistributorCode,
        srtPOSCode: booking.srtPOSCode || booking.BookingPOSCode,
        srtRecLocator: booking.srtRecLocator || booking.recordLocator,
        srtSelectedTDO: data.getIn(['selectedTdo', 'tdoCode']) || '',
        srtSelectedTDODesc: data.getIn(['selectedTdo', 'tdoDesc']) || '',
        srtDeliveryPrice: data.getIn(['selectedTdo', 'tdoFee']) || '0.00',
        srtTDOEmailAddress: data.getIn(['selectedTdo', 'tdoPaperEmail']) || '',
        srtDeliveryPriceCurrency: data.getIn(['selectedTdo', 'tdoCurrency']) || '',
        srtTDOSMSToCode: data.getIn(['selectedTdo', 'tdoSmsCountry']) || '',
        srtTDOSMSTo: data.getIn(['selectedTdo', 'tdoSmsNumber']) || '',
        srtTDOShippingName: data.getIn(['selectedTdo', 'tdoMailName']) || '',
        srtTDOShippingAddress: data.getIn(['selectedTdo', 'tdoMailAddress', 'address1']) || '',
        srtTDOShippingAddress2: data.getIn(['selectedTdo', 'tdoMailAddress', 'address2']) || '',
        srtTDOShippingCity: data.getIn(['selectedTdo', 'tdoMailAddress', 'city']) || '',
        srtTDOShippingState: data.getIn(['selectedTdo', 'tdoMailAddress', 'stateOrProvince']) || '',
        srtTDOShippingZip: data.getIn(['selectedTdo', 'tdoMailAddress', 'postalCode']) || '',
        srtTDOTixDeliveryCountry: data.getIn(['selectedTdo', 'tdoMailAddress', 'countryCode']) || '',
        srtOrigin: (source === 'createBooking'
            ? faresSelected.get(0).get('leg').get('ShoppingLegOrigStationName')
            : data.getIn(['validateBooking', 'BookingTDOOrigStationName'])) || '',
        srtDestination: (source === 'createBooking'
            ? faresSelected.get(0).get('leg').get('ShoppingLegDestStationName')
            : data.getIn(['validateBooking', 'BookingOrderDetailsLegTravelSegmentDestStationName'])) || '',
        srtTDOPickupLocation: data.getIn(['selectedTdo', 'tdoPickUpLocation']) || '',
        srtTDOSmartCards,
        srtSource: source,
        srtSessionToken: sessionToken,
    };

    return Promise.all(orderIds.map((orderId) => bookingSetTdo({
        ...params,
        srtOrderId: orderId,
    })))
        .then((response) => {
            if (response[0].errorResponse) {
                error(response[0]);
            } else {
                success(response);
            }
        });
};

export const bookingAddOrderApi = (data, queryItems, faresSelected, tripType, accountName, sessionToken, error, success) => {
    const params = {
        ...queryItems,
        srtChkSeatRes: [],
        srtNearbyCoach: [],
        srtNearbySeat: [],
        srtSeatResOptions: [],
        srtMealOnboard: [],
        srtInternetOnboard: [],
        srtTripType: tripType,
        srtAccountName: accountName,
        dataStorageId: sessionToken,
    };

    const priceIds = faresSelected.map((selectedFare) => selectedFare.getIn(['fare', 'priceId']));
    params.srtPriceIds = priceIds.size ? priceIds.join(',') : undefined;
    params.srtTravelCardOptPriceID = getTravelCardIds(data.get('travelCards'));

    const seatReservations = data.get('seatReservations');
    if (!seatReservations.isEmpty()) {
        const travelSegments = seatReservations.keySeq().toList();
        travelSegments.forEach((segment) => {
            const passengers = seatReservations.get(segment).keySeq().toList();
            passengers.forEach((passenger) => {
                if (passenger !== 'enableAllSeats' && passenger !== 'price') {
                    const items = seatReservations.getIn([segment, passenger]).keySeq().toList();
                    items.forEach((item) => {
                        if (item !== 'price') {
                            if (item === 'direction' || item === 'position' || item === 'coach' || item === 'enablePassengerSeats'
                                || item === 'nearbyCoach' || item === 'nearbySeat') {
                                const value = seatReservations.getIn([segment, passenger, item]);
                                if (value) {
                                    const option = `${value}:${passenger}:${segment}`;
                                    if (item === 'enablePassengerSeats') {
                                        params.srtChkSeatRes.push(option);
                                    } else if (item === 'nearbyCoach') {
                                        params.srtNearbyCoach.push(option);
                                    } else if (item === 'nearbySeat') {
                                        params.srtNearbySeat.push(option);
                                    } else {
                                        params.srtSeatResOptions.push(option);
                                    }
                                }
                            } else {
                                const option = `${item}:${passenger}:${segment}`;

                                params.srtSeatResOptions.push(option);
                            }
                        }
                    });
                }
            });
        });
    }

    if (!data.get('onBoardServices').isEmpty()) {
        data.get('onBoardServices').toArray().forEach((servicesPrice) => {
            if (servicesPrice.get('onBoardServiceCategory') === 'MEAL') {
                params.srtMealOnboard.push(servicesPrice.get('ID'));
            } else if (servicesPrice.get('onBoardServiceCategory') === 'INTERNET') {
                params.srtInternetOnboard.push(servicesPrice.get('ID'));
            }
        });
    }

    return bookingAddOrder(
        params,
        error,
        success,
    );
};

const getDiscounts = (data, railCards, orderPassengers) => {
    const discounts = [];
    data.discounts.forEach(({
        discountProgram, discountNumber, railcardId, passenger,
    }) => {
        if (discountProgram !== null) {
            const discountCard = railCards[Object.keys(railCards).find((key) => railCards[key].RailCardProgram === discountProgram)];
            const isFRMilitaire = discountCard?.RailCardProgram?.includes('FR_MILITAIRE');
            const discountType = discountCard.RailCardType;
            const isDiscountIdReq = discountCard.RailCardIsIDRequired
                && (
                    discountType === PROMOTION || discountType === CORPORATE
                    || discountProgram === UK_CORPORATE || discountProgram === UK_TADA || isFRMilitaire
                );
            let finalDiscountProgram = discountProgram;
            if (discountProgram === UK_CORPORATE) {
                finalDiscountProgram = `UK_CORPORATE_${railcardId}`;
            } else if (discountProgram === UK_TADA) {
                finalDiscountProgram = `UK_${railcardId}`;
            }
            const discountIdentifier = discountProgram === UK_CORPORATE || discountProgram === UK_TADA ? '' : railcardId;
            // get the rail card based on the discount program. Look up for the flag ShouldMapRailCardToPassenger
            const passengerID = orderPassengers?.[passenger]?.BookingPassengerID ?? passenger;
            if (discountCard.ShouldMapRailCardToPassenger) {
                discounts.push(`${isDiscountIdReq},${discountType},${discountNumber},${finalDiscountProgram},${discountIdentifier},${passengerID}`);
            } else {
                discounts.push(`${isDiscountIdReq},${discountType},${discountNumber},${finalDiscountProgram},${discountIdentifier}`);
            }
        }
    });
    return discounts.join(';');
};

export const getIntermediateTravelPointsApi = (srtShoppingContext, srtLegSolutionId, error, success) => {
    const params = {
        srtShoppingContext,
        srtLegSolutionId,
    };
    return getIntermediateTravelPoints(params, error, success);
};

export const shoppingSearchApi = (query, intl, railCards, error, success) => {
    const params = {
        srtDepartCode: query.departCode,
        srtDepartCodeSuppliers: query.departCodeSuppliers,
        srtArriveCode: query.arriveCode,
        srtArriveCodeSuppliers: query.arriveCodeSuppliers,
        srtViaAvoidOutbound: query.viaAvoidCodeOutbound === '' ? '0;' : `${query.viaAvoidTypeOutbound};${query.viaAvoidCodeOutbound}`,
        srtViaAvoidInbound: query.viaAvoidCodeInbound === '' ? '0;' : `${query.viaAvoidTypeInbound};${query.viaAvoidCodeInbound}`,
        srtLondonTravelCard: false,
        srtDisableSupplierFiltering: false,
        srtTriptype: query.tripType,
        srtTimeOptionOutboundDate: query.timeOptionOutboundDate,
        srtTimeOptionInboundDate: query.timeOptionInboundDate,
        srtTxtDepartDate: cleanDate(intl.formatDate(query.departDate, dateFormat)),
        srtTxtDepartTime: cleanDate(intl.formatTime(query.departDate, timeFormat)),
        srtAccountName: query.accountName,
        srtShoppingContext: query.context,
        srtShoppingNumPax: query.paxAge.length,
        srtShoppingResultsRepresentation: 'LegSolutionOrdered',
        srtIncludeFareRules: true,
        requestorId: `srtShopping.${Date.now()}`,
        srtShoppingPaxAge: [],
        srtRailCards: getDiscounts(query, railCards),
        srtDepartMaxConnections: query.departMaxConnections,
        srtReturnMaxConnections: query.returnMaxConnections,
        srtPromoCode: query.promoCode,
        srtValidationCode: query.validationCode,
    };

    if (query.tripType === TRIP_TYPE_RETURN || (query.tripType === TRIP_TYPE_SEASON && query.returnDate !== null)) {
        params.srtTxtReturnDate = cleanDate(intl.formatDate(query.returnDate, dateFormat));
        params.srtTxtReturnTime = cleanDate(intl.formatTime(query.returnDate, timeFormat));
        if (query.tripType === TRIP_TYPE_RETURN) {
            params.srtIncludeReturnFares = query.openReturn;
        }
    }

    query.paxAge.forEach((age) => {
        params.srtShoppingPaxAge.push(age);
    });

    gaSetAccount(query.context);

    return shoppingSearch(
        params,
        error,
        success,
    );
};

export const exchangeSearchApi = (query, intl, railCards, exchangeableTicketableFareIds, recordLocator, orderId, orderPassengers, error, success) => {
    const params = {
        srtTicketableFareIds: exchangeableTicketableFareIds.length > 0 ? exchangeableTicketableFareIds.join(',') : '',
        srtRecLocator: recordLocator,
        srtOrderId: orderId,
        srtDepartCode: query.departCode,
        srtArriveCode: query.arriveCode,
        srtViaAvoidOutbound: query.viaAvoidCodeOutbound === '' ? '0;' : `${query.viaAvoidTypeOutbound};${query.viaAvoidCodeOutbound}`,
        srtViaAvoidInbound: query.viaAvoidCodeInbound === '' ? '0;' : `${query.viaAvoidTypeInbound};${query.viaAvoidCodeInbound}`,
        srtLondonTravelCard: false,
        srtDisableSupplierFiltering: false,
        srtTriptype: query.tripType,
        srtTimeOptionOutboundDate: query.timeOptionOutboundDate,
        srtTimeOptionInboundDate: query.timeOptionInboundDate,
        srtTxtDepartDate: cleanDate(intl.formatDate(query.departDate, dateFormat)),
        srtTxtDepartTime: cleanDate(intl.formatTime(query.departDate, timeFormat)),
        srtAccountName: query.accountName,
        srtShoppingContext: query.context,
        srtIncludeFareRules: true,
        requestorId: `srtShopping.${Date.now()}`,
        srtRailCards: getDiscounts(query, railCards, orderPassengers),
    };

    if (query.tripType === TRIP_TYPE_RETURN || (query.tripType === TRIP_TYPE_SEASON && query.returnDate !== null)) {
        params.srtTxtReturnDate = cleanDate(intl.formatDate(query.returnDate, dateFormat));
        params.srtTxtReturnTime = cleanDate(intl.formatTime(query.returnDate, timeFormat));
    }

    gaSetAccount(query.context);

    return exchangeSearch(
        params,
        error,
        success,
    );
};

export const refreshUserStateApi = (error, success) => refreshUserState(error, success);
