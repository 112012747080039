import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { injectIntl } from 'react-intl';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import Grid from '@mui/material/Grid';

import messages from './messagesPlusBusSupplements';
import { formatPrice } from '../../../../utils';
import styles from './styles';
import PlusBusSupplementTravelSegment from './PlusBusSupplementTravelSegment';

const StyledAccordion = styled(Accordion)(() => ({
    '&.Mui-expanded': {
        ...styles.expandedPanel,
    },
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
    background: 'linear-gradient(rgb(221, 221, 221), rgb(204, 204, 204))',
    fontWeight: 500,

    '&.Mui-expanded': {
        ...styles.expandedPanelSummary,
    },
    '& .MuiAccordionSummary-root': {
        ...styles.legSegment,
    },
    '& .MuiAccordionSummary-content': {
        ...styles.legSegmentSummary,
    },
}));

const PlusBusSupplementsView = ({
    faresSelected,
    plusBusSupplements,
    passengerNumber,
    intl,
    onCheckPlusBusSupplement,
    selectedPlusBusSupplements,
    isToggled,
    setIsToggled,
}) => {
    const legs = faresSelected.toJS().map((item) => item.leg);
    const travelSegments = faresSelected.toJS().map((item) => item.leg.ShoppingLegSolutions[0].ShoppingFareTravelSegments);

    const [expanded, setExpanded] = useState(legs[0].ShoppingLegId);

    const handleSummaryClick = (legID) => (event, expandedValue) => {
        setExpanded(expandedValue && legID);
    };

    const { formatMessage } = intl;

    const lblLeg = formatMessage(messages.lblLeg);
    const lblTo = formatMessage(messages.lblTo);
    const expandable = plusBusSupplements && plusBusSupplements.length > 0;

    const searchedPlusBusSupplements = plusBusSupplements.map((plusBusSupplement) => {
        const price = parseFloat(plusBusSupplement.value);
        const searchedPlusBusSupplement = {
            ...plusBusSupplement,
            id: plusBusSupplement.id,
            description: plusBusSupplement.description,
            linkedTravelSegments: plusBusSupplement.linkedTravelSegments,
            linkedPassengers: plusBusSupplement.linkedPassengers,
            priceDesc: formatPrice(price, plusBusSupplement.currency, intl),
            value: price,
            selected: plusBusSupplement.selected,
            numberOfPassengers: passengerNumber,
        };
        return searchedPlusBusSupplement;
    });

    const doesPlusBusSupplementExist = (legid) => {
        const filteredLeg = travelSegments[legid].filter(
            (item) => searchedPlusBusSupplements?.filter(
                (plusBusSupplement) => plusBusSupplement
                    && plusBusSupplement.linkedTravelSegments[0] === item.TravelSegmentID,
            )?.length > 0,
        );
        return filteredLeg?.length > 0;
    };

    return (
        <div className="container-fluid" key="plusBusSupplements-Dialog">
            {
                legs.map((leg, legid) => doesPlusBusSupplementExist(legid) && (

                    <StyledAccordion
                        key={`ExpansionPanel${legid}`}
                        expanded={expanded === leg.ShoppingLegId}
                        onChange={expandable ? handleSummaryClick(leg.ShoppingLegId) : undefined}
                    >
                        {travelSegments[legid] && travelSegments[legid].length >= 1 && (
                            <StyledAccordionSummary expandIcon={expandable && <ExpandMoreIcon />}>
                                {`${lblLeg} ${legid + 1}: ${leg.ShoppingLegOrigStationName} ${lblTo} ${leg.ShoppingLegDestStationName}`}
                            </StyledAccordionSummary>
                        )}
                        <AccordionDetails key={`ExpansionPanelDetails${legid}`}>
                            <Grid container>
                                <PlusBusSupplementTravelSegment
                                    key={`PlusBusSupplementTravelSegment${legid}`}
                                    searchedPlusBusSupplements={searchedPlusBusSupplements}
                                    onCheckPlusBusSupplement={onCheckPlusBusSupplement}
                                    selectedPlusBusSupplements={selectedPlusBusSupplements}
                                    travelSegments={travelSegments}
                                    legid={legid}
                                    isToggled={isToggled}
                                    setIsToggled={setIsToggled}
                                />
                            </Grid>
                        </AccordionDetails>
                    </StyledAccordion>
                ))
            }
        </div>
    );
};

PlusBusSupplementsView.propTypes = {
    intl: PropTypes.object,
    onCheckPlusBusSupplement: PropTypes.func.isRequired,
    faresSelected: PropTypes.object.isRequired,
    plusBusSupplements: PropTypes.array.isRequired,
    selectedPlusBusSupplements: PropTypes.array.isRequired,
    passengerNumber: PropTypes.number.isRequired,
    isToggled: PropTypes.array,
    setIsToggled: PropTypes.func,
};

export { PlusBusSupplementsView as PlusBusSupplementsViewAlias };

export default injectIntl(PlusBusSupplementsView);
