import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import validate from 'validate.js';

import RefreshIndicator from '../RefreshIndicator/RefreshIndicator';
import { changeUserPasswordApi, resetUserPasswordApi, resetUserPasswordTokenCheckApi } from '../../Login/apiLogin';

import './passwordUpdate.css';
import messages from './messagesPasswordUpdate';
import ExtendedSnackbar from '../ExtendedSnackbar/ExtendedSnackbar';
import inlineStyles from './styles';

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        ...inlineStyles.dialogPaper,
    },
}));
class PasswordUpdate extends Component {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        open: PropTypes.bool,
        handleClose: PropTypes.func,
        title: PropTypes.string,
        username: PropTypes.string,
        token: PropTypes.string,
        isPasswordChange: PropTypes.bool.isRequired, // if true then we are in Change Password mode, otherwise we are in Reset Password mode
        onPasswordChagneSuccess: PropTypes.func,
    };

    initialState = {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        processingSubmit: false,
        requestNewLinkOpen: false,
        alertText: '',
        errors: {},
    };

    constructor(props) {
        super(props);
        this.state = { data: fromJS(this.initialState) };
    }

    componentDidMount() {
        if (!this.props.isPasswordChange && this.props.username && this.props.token) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState((state) => ({ data: state.data.merge({ errors: {}, processingSubmit: true }) }));

            resetUserPasswordTokenCheckApi(
                {
                    username: this.props.username,
                    token: this.props.token,
                },
                (response) => {
                    let requestNewLinkOpen = false;
                    let alertText = response.errorResponse.message || '';
                    if (response.errorResponse && response.errorResponse.code && response.errorResponse.code === 401) {
                        requestNewLinkOpen = true;
                        alertText = '';
                    }
                    this.setState((state) => ({
                        data: state.data.merge({
                            processingSubmit: false,
                            alertText,
                            requestNewLinkOpen,
                        }),
                    }));
                },
                () => {
                    this.setState((state) => ({
                        data: state.data.merge({
                            processingSubmit: false,
                        }),
                    }));
                },
            );
        }
    }

    constraints = () => {
        const { intl: { formatMessage } } = this.props;
        return {
            currentPassword: () => (
                (this.props.isPasswordChange)
                    ? { presence: { message: formatMessage(messages.errCurrentPasswordEmpty), allowEmpty: false } } : null
            ),
            newPassword: {
                presence: {
                    message: formatMessage(messages.errNewPasswordEmpty),
                    allowEmpty: false,
                },
                format: {
                    pattern: /^(?=.*[\u0061-\u007A\u00DF-\u00F6\u00F8-\u00FF\u0107\u010D\u0111\u0161\u017E])(?=.*[\u0041-\u005A\u00C0-\u00D6\u00D8-\u00DE\u0106\u010C\u0110\u0160\u017D])(?=.*\d).{6,}$/, // eslint-disable-line
                    message: formatMessage(messages.errNewPasswordMinReq),
                },
            },
            confirmNewPassword: {
                presence: {
                    message: formatMessage(messages.errNewPasswordEmpty),
                    allowEmpty: false,
                },
                equality: {
                    attribute: 'newPassword',
                    message: formatMessage(messages.errNewPasswordDontMatch),
                },
            },
        };
    }

    constraintsPasswordChange = () => ({
        newPassword: {
            format: this.constraints().newPassword.format,
        },
        confirmNewPassword: {
            equality: this.constraints().confirmNewPassword.equality,
        },
    });

    handleCurrentPasswordChange = ({ target: { value } }) => {
        this.setState((state) => ({ data: state.data.merge({ currentPassword: value }) }));
    };

    handleNewPasswordChange = ({ target: { value } }) => {
        this.setState((state) => {
            let errors = validate({
                newPassword: value,
                confirmNewPassword: state.data.get('confirmNewPassword'),
            }, this.constraintsPasswordChange());

            if (validate.isEmpty(errors)) {
                errors = {};
            }

            return { data: state.data.merge({ newPassword: value, errors }) };
        });
    };

    handleConfirmNewPasswordChange = ({ target: { value } }) => {
        this.setState((state) => {
            let errors = validate({
                newPassword: state.data.get('newPassword'),
                confirmNewPassword: value,
            }, this.constraintsPasswordChange());

            if (validate.isEmpty(errors)) {
                errors = {};
            }

            return { data: state.data.merge({ confirmNewPassword: value, errors }) };
        });
    };

    handleSubmit = () => {
        const { intl: { formatMessage } } = this.props;
        this.setState((state) => ({ data: state.data.merge({ errors: {}, processingSubmit: true }) }));
        const errors = validate(this.state.data.toJS(), this.constraints());

        if (!validate.isEmpty(errors)) {
            this.setState((state) => ({ data: state.data.merge({ errors, processingSubmit: false }) }));
            return;
        }

        if (this.props.isPasswordChange) {
            changeUserPasswordApi(
                {
                    currentPassword: this.state.data.get('currentPassword'),
                    newPassword: this.state.data.get('newPassword'),
                    confirmNewPassword: this.state.data.get('confirmNewPassword'),
                },
                (response) => {
                    this.setState((state) => ({
                        data: state.data.merge({
                            alertText: response.errorResponse.message,
                            processingSubmit: false,
                        }),
                    }));
                },
                (response) => {
                    let alertText = '';

                    if (response.successResponse) {
                        alertText = formatMessage(messages.okChangePassword);
                    } else {
                        alertText = formatMessage(messages.errChangePassword);
                    }
                    this.setState({
                        data: fromJS({
                            ...this.initialState,
                            alertText,
                        }),
                    });
                    this.props.handleClose();
                },
            );
        } else {
            resetUserPasswordApi(
                {
                    username: this.props.username,
                    token: this.props.token,
                    newPassword: this.state.data.get('newPassword'),
                    confirmNewPassword: this.state.data.get('confirmNewPassword'),
                },
                (response) => {
                    this.setState((state) => ({
                        data: state.data.merge({
                            alertText: response.errorResponse.message,
                            processingSubmit: false,
                        }),
                    }));
                },
                (response) => {
                    let alertText = '';

                    if (response.successResponse) {
                        alertText = formatMessage(messages.okChangePassword);
                        if (this.props.onPasswordChagneSuccess) {
                            this.props.onPasswordChagneSuccess();
                        }
                    } else {
                        alertText = formatMessage(messages.errChangePassword);
                    }
                    this.setState((state) => ({
                        data: state.data.merge({
                            alertText,
                            processingSubmit: false,
                        }),
                    }));
                },
            );
        }
    };

    handleRequestNewLink = () => {
        this.setState({ data: fromJS(this.initialState) });
        this.props.handleClose(true);
    };

    handleClose = () => {
        this.setState({ data: fromJS(this.initialState) });
        this.props.handleClose();
    };

    handleSnackbarClose = () => {
        this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
    };

    render() {
        const { intl: { formatMessage }, username } = this.props;
        return (
            <div>
                <StyledDialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    disableEnforceFocus
                    maxWidth={false}
                >
                    <DialogTitle>{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form>
                            <input name="username" autoComplete="username" value={username} type="text" style={{ display: 'none' }} />
                            {!this.props.isPasswordChange
                                || (
                                    <div>
                                        <TextField
                                            id="srtCurrPassword"
                                            label={formatMessage(messages.lblCurrentPassword)}
                                            value={this.state.data.get('currentPassword')}
                                            onChange={this.handleCurrentPasswordChange}
                                            fullWidth
                                            type="password"
                                            error={!!this.state.data.getIn(['errors', 'currentPassword'])}
                                            helperText={this.state.data.getIn(['errors', 'currentPassword'])}
                                            autoComplete="current-password"
                                            variant="standard"
                                        />
                                    </div>
                                )}
                            <div>
                                <TextField
                                    id="srtNewPassword"
                                    label={formatMessage(messages.lblNewPassword)}
                                    value={this.state.data.get('newPassword')}
                                    onChange={this.handleNewPasswordChange}
                                    fullWidth
                                    type="password"
                                    error={!!this.state.data.getIn(['errors', 'newPassword'])}
                                    helperText={this.state.data.getIn(['errors', 'newPassword'])}
                                    autoComplete="new-password"
                                    variant="standard"
                                />
                            </div>
                            <div>
                                <TextField
                                    id="srtConfirmPassword"
                                    label={formatMessage(messages.lblConfirmNewPassword)}
                                    value={this.state.data.get('confirmNewPassword')}
                                    onChange={this.handleConfirmNewPasswordChange}
                                    fullWidth
                                    type="password"
                                    error={!!this.state.data.getIn(['errors', 'confirmNewPassword'])}
                                    helperText={this.state.data.getIn(['errors', 'confirmNewPassword'])}
                                    autoComplete="new-password"
                                    variant="standard"
                                />
                            </div>
                            <div styleName="passwordRules">
                                {formatMessage(messages.lblPasswordRules)}
                            </div>
                            <ul>
                                <li>{formatMessage(messages.lblPasswordSix)}</li>
                                <li>{formatMessage(messages.lblPasswordLowercase)}</li>
                                <li>{formatMessage(messages.lblPasswordUppercase)}</li>
                                <li>{formatMessage(messages.lblPasswordNumber)}</li>
                            </ul>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <PasswordActions
                            handlePasswordSubmit={this.handleSubmit}
                            handlePasswordClose={this.handleClose}
                            processingSubmit={this.state.data.get('processingSubmit')}
                        />
                    </DialogActions>
                </StyledDialog>
                <RequestNewLinkModal
                    open={this.state.data.get('requestNewLinkOpen')}
                    handleRequestNewLink={this.handleRequestNewLink}
                />
                <ExtendedSnackbar
                    id="srtPasswordChangeDialogSnackBar"
                    open={(this.state.data.get('alertText') !== '')}
                    message={this.state.data.get('alertText')}
                    onClose={this.handleSnackbarClose}
                />
            </div>
        );
    }
}

const PasswordActions = injectIntl((props) => {
    const {
        handlePasswordSubmit,
        handlePasswordClose,
        processingSubmit,
    } = props;
    const { formatMessage } = props.intl;
    return (
        <div className="row" style={inlineStyles.dialogActionsRoot}>
            <div className="col-4 offset-3 col-sm-3 offset-sm-6">
                <Button
                    variant="contained"
                    onClick={handlePasswordClose}
                    fullWidth
                >
                    {formatMessage(messages.btnClose)}
                </Button>
            </div>
            <div className="col-4 col-sm-3">
                {
                    processingSubmit
                        ? (
                            <RefreshIndicator
                                size={36}
                                left={40}
                                top={0}
                                status="loading"
                            />
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handlePasswordSubmit}
                                fullWidth
                            >
                                {formatMessage(messages.btnSave)}
                            </Button>
                        )
                }
            </div>
        </div>
    );
});

PasswordActions.propTypes = {
    handlePasswordSubmit: PropTypes.func.isRequired,
    handlePasswordClose: PropTypes.func.isRequired,
    processingSubmit: PropTypes.bool.isRequired,
    intl: PropTypes.object,
};

const RequestNewLinkModal = injectIntl((props) => {
    const { formatMessage } = props.intl;
    const actions = (
        <Button
            variant="contained"
            color="primary"
            onClick={props.handleRequestNewLink}
        >
            {formatMessage(messages.btnRequestNewLink)}
        </Button>
    );

    return (
        <Dialog
            open={props.open}
            disableEnforceFocus
            maxWidth={false}
        >
            <DialogContent>
                {formatMessage(messages.lblRequestNewLink)}
            </DialogContent>
            <DialogActions>
                {actions}
            </DialogActions>
        </Dialog>
    );
});

RequestNewLinkModal.propTypes = {
    open: PropTypes.bool,
    handleRequestNewLink: PropTypes.func.isRequired,
    intl: PropTypes.object,
};

// This alias will be used to access bare component for unit testing
export { PasswordUpdate as PasswordUpdateAlias };
export { PasswordActions as PasswordActionsAlias };

export default injectIntl(PasswordUpdate);
