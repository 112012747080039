import { defineMessages } from 'react-intl';

export default defineMessages({
    btnVoidOrderError: {
        id: 'app.shopping.manageBooking.view.voidOrderError',
        defaultMessage: 'The order can no longer be voided as the expiration date/time has passed.',
    },
    noAccountError: {
        id: 'app.shopping.manageBooking.view.noAccountError',
        defaultMessage: 'Internal Application Error. Please contact your SilverAgent system administrator (no matching account).',
    },
    anyCardCollectHasBeenAdded: {
        id: 'app.shopping.manageBooking.view.anyCardCollectHasBeenAdded',
        defaultMessage: 'Any card collect has been added.',
    },
    anyCardCollectError: {
        id: 'app.shopping.manageBooking.view.anyCardCollectError',
        defaultMessage: 'Error adding any card collect: {error}',
    },
    noDescriptionError: {
        id: 'app.shopping.manageBooking.view.noDescriptionError',
        defaultMessage: 'Could not get transaction/payment data.',
    },
    unableToRetrievePayment: {
        id: 'app.shopping.manageBooking.view.unableToRetrievePayment',
        defaultMessage: 'Unable to retrieve payment transaction at this time. Please try again later.',
    },
    lblOrderDetailsTitle: {
        id: 'app.shopping.OrderDetailsDetails.title',
        defaultMessage: 'Order Details',
    },
    lblCustomInformation: {
        id: 'app.shopping.manageBooking.customInformation.customInformation',
        defaultMessage: 'Custom Information',
    },
    lblNotes: {
        id: 'app.shopping.manageBooking.orderNotes.notes',
        defaultMessage: 'Notes',
    },
    lblPassengers: {
        id: 'app.shopping.manageBooking.orderPassenger.passengers',
        defaultMessage: 'Passengers',
    },
    lblBookingSummary: {
        id: 'app.shopping.manageBooking.bookingSummary.bookingSummary',
        defaultMessage: 'Booking Summary',
    },
});
