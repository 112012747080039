import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import messages from './messagesSelectLocale';

const LOCALES = [
    { locale: 'en_GB', name: 'English (UK)' },
    { locale: 'en_US', name: 'English (US)' },
    { locale: 'fr_FR', name: 'Français' },
];

function SelectLocale({
    id,
    value,
    onChange,
    hideLabel = false,
}) {
    return (
        <TextField
            data-testid="select-locale"
            variant="standard"
            id={id}
            value={value}
            onChange={onChange}
            label={hideLabel ? '' : <FormattedMessage {...messages.selectLocale} />}
            margin={hideLabel ? 'dense' : 'none'}
            fullWidth
            select
        >
            {LOCALES.map((item, index) => (
                <MenuItem
                    id={`${id}_locale${index}`}
                    key={item.locale}
                    value={item.locale}
                >
                    <FormattedMessage
                        {...messages.selectLocaleLang}
                        values={{
                            language: item.name,
                        }}
                    />
                </MenuItem>
            ))}
        </TextField>
    );
}

SelectLocale.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    id: PropTypes.string.isRequired,
    hideLabel: PropTypes.bool,
};

export default SelectLocale;
