import validate from 'validate.js';
import { nameRegex } from '../../utils/regex';
import messages from './messagesCreateBooking';

export default (formatMessage, data) => {
    // TODO 0.11.0 validate js seems to have a problem where the format and length are called on empty values
    // this can be overridden by using a function and checking the value before sending constraints
    // for now reverting validate js to 0.10.0 version
    // investigate if something can be done
    const constructBasicConstraints = (tripType) => {
        const constraints = {
            firstName: {
                presence: { message: formatMessage(messages.errEmptyFirstName), allowEmpty: false },
                format: {
                    pattern: nameRegex,
                    flags: 'i',
                    message: formatMessage(messages.errInvalidFirstName),
                },
                length: {
                    minimum: 2,
                    maximum: 50,
                    tooLong: formatMessage(messages.errLongFirstLastName),
                    tokenizer: (value) => (value + data.get('lastName')),
                },
            },
            lastName: {
                presence: { message: formatMessage(messages.errEmptyLastName), allowEmpty: false },
                format: {
                    pattern: nameRegex,
                    flags: 'i',
                    message: formatMessage(messages.errInvalidLastName),
                },
                length: {
                    minimum: 2,
                    maximum: 50,
                    tooLong: formatMessage(messages.errLongFirstLastName),
                    tokenizer: (value) => (value + data.get('firstName')),
                },
            },
        };

        const additionalConstraints = {
            title: {
                presence: { message: formatMessage(messages.errEmptyTitle), allowEmpty: false },
                containsValidCharacters: { message: formatMessage(messages.errInvalidTitle) },
            },
        };

        return (tripType === formatMessage(messages.lblSeason)) ? Object.assign(constraints, additionalConstraints) : constraints;
    };

    const contactConstraints = {
        phoneNumber: {
            presence: { message: formatMessage(messages.errEmptyPhoneNumber), allowEmpty: false },
            format: {
                pattern: '^[0-9,(,),+,_,#,~,-]{1,45}$',
                flags: 'g',
                message: formatMessage(messages.errInvalidPhoneNumber),
            },
            length: {
                maximum: 32,
            },
        },
    };

    const passengersEmail = {
        email: {
            presence: {
                message: formatMessage(messages.errEmptyEmail), allowEmpty: false,
            },
            email: {
                message: formatMessage(messages.errInvalidEmail),
            },
        },
    };

    // Travel Docs constraints
    const constructTravelDocsConstraints = (tripType, travelDocsRequired) => {
        const constraints = {
            DOB: {
                presence: {
                    message: formatMessage(messages.errDOBEmpty), allowEmpty: false,
                },
                datetime: {
                    latest: new Date(),
                    message: formatMessage(messages.errDOBInvalid),
                },
            },
        };

        const documentConstraints = travelDocsRequired ? {
            documentNumber: {
                presence: {
                    message: formatMessage(messages.errDocNumberEmpty), allowEmpty: false,
                },
            },
            documentExpDate: {
                presence: {
                    message: formatMessage(messages.errDocExpDateEmpty), allowEmpty: false,
                },
                datetime: {
                    earliest: new Date().setDate(new Date().getDate() + 2),
                    message: formatMessage(messages.errDocExpDateInvalid),
                },
            },
        } : {};

        const additionalConstraints = (travelDocsRequired) ? {
            documentType: {
                presence: {
                    message: formatMessage(messages.errDocTypeEmpty), allowEmpty: false,
                },
            },
            issueCountry: {
                presence: {
                    message: formatMessage(messages.errIssueCountryEmpty), allowEmpty: false,
                },
            },
        } : {};

        return Object.assign(constraints, documentConstraints, additionalConstraints);
    };

    // Discounts and Loyalties constraints
    const constructDiscountsLoyaltiesConstraints = (suppliers) => (
        {
            discountIdentifier: (identifier, attr) => {
                if (
                    attr.aggregatedDiscounts.length > 0
                    && attr.aggregatedDiscounts[0].ShoppingLegFareDiscountIsIdentifierRequired
                    && attr.aggregatedDiscounts[0].ShoppingLegFareDiscountType === 'DISCOUNT_CARD'
                ) {
                    const req = {
                        presence: { message: formatMessage(messages.errDiscountIdentifierEmpty), allowEmpty: false },
                    };
                    if (identifier === 'AAA') {
                        req.length = {
                            minimum: 3,
                            tooLong: formatMessage(messages.errDiscountIdentifierShort),
                        };
                    }
                    return req;
                }
                return null;
            },
            discountAuthorization: (authorization, attr) => {
                if (attr.aggregatedDiscounts.length > 0
                    && attr.aggregatedDiscounts[0].ShoppingLegFareDiscountIsAuthorizationRequired
                    && attr.aggregatedDiscounts[0].ShoppingLegFareDiscountType === 'DISCOUNT_CARD') {
                    return {
                        presence: { message: formatMessage(messages.errDiscountAuthorizationEmpty), allowEmpty: false },
                    };
                }
                return null;
            },
            corporateIdentifier: (identifier, attr) => {
                if (attr.aggregatedDiscounts.length > 0
                    && attr.aggregatedDiscounts[0].ShoppingLegFareDiscountIsIdentifierRequired
                    && (attr.aggregatedDiscounts[0].ShoppingLegFareDiscountType === 'CORPORATE'
                        || attr.aggregatedDiscounts[0].ShoppingLegFareDiscountType === 'PROMOTION'
                    )
                ) {
                    return {
                        presence: { message: formatMessage(messages.errDiscountIdentifierEmpty), allowEmpty: false },
                    };
                }
                return null;
            },
            corporateAuthorization: (authorization, attr) => {
                if (attr.aggregatedDiscounts.length > 0
                    && attr.aggregatedDiscounts[0].ShoppingLegFareDiscountIsAuthorizationRequired
                    && (attr.aggregatedDiscounts[0].ShoppingLegFareDiscountType === 'CORPORATE'
                        || attr.aggregatedDiscounts[0].ShoppingLegFareDiscountType === 'PROMOTION'
                    )) {
                    const req = {
                        presence: { message: formatMessage(messages.errDiscountAuthorizationEmpty), allowEmpty: false },
                    };
                    if (attr.aggregatedDiscounts[0].ShoppingLegFareDiscountDescription.toLowerCase().indexOf('tan1') !== -1) {
                        req.format = {
                            pattern: '(.{9}|.{11})$',
                            message: formatMessage(messages.errFalseTanConfirmIdentifier),
                        };
                    }
                    return req;
                }
                return null;
            },
            loyaltyIdentifier: (loyaltyIdentifier, attr) => {
                const constraints = {
                    presence: { message: formatMessage(messages.errLoyaltyIdentifierEmpty), allowEmpty: false },
                };

                if (suppliers.find((supplier) => supplier.BookingSupplierCode === 'REN')) {
                    constraints.numericality = { message: formatMessage(messages.errLoyaltyIdentifierOnlyNumber) };
                }

                if (attr.loyaltyProgram) {
                    return constraints;
                }
                return null;
            },
        }
    );

    // Address constraints are repeated but required to do so because
    // error messages could be different for different components
    const addressConstraints = {
        countryCode: {
            presence: {
                message: formatMessage(messages.errCountryEmpty), allowEmpty: false,
            },
        },
        address1: {
            presence: {
                message: formatMessage(messages.errAddressLine1Empty), allowEmpty: false,
            },
        },
        city: {
            presence: {
                message: formatMessage(messages.errCityEmpty), allowEmpty: false,
            },
        },
        stateOrProvince: (state, attr) => {
            const code = attr.countryCode;
            const isCanada = (code === 'CA');
            if ((isCanada || code === 'US')) {
                const message = (isCanada) ? formatMessage(messages.errProvinceEmpty) : formatMessage(messages.errStateEmpty);
                return {
                    presence: { message, allowEmpty: false },
                };
            }
            return null;
        },
        postalCode: (postalCode, attr) => {
            const code = attr.countryCode;
            const req = {
                presence: { message: formatMessage(messages.errPostalCodeEmpty), allowEmpty: false },
            };
            switch (code) {
                case 'CA':
                    req.format = {
                        pattern: /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/,
                        message: formatMessage(messages.errPostalCodeInvalid),
                    };
                    break;
                case 'US':
                    req.format = {
                        pattern: /^\d{5}(-\d{4})?$/,
                        message: formatMessage(messages.errPostalCodeInvalid),
                    };
                    break;
                default:
                    req.length = {
                        maximum: 15,
                        wrongLength: formatMessage(messages.errPostalCodeInvalid),
                    };
            }
            return req;
        },
    };

    const customInfoConstraints = {
        referenceNumber: {
            length: {
                maximum: 25,
                tooLong: formatMessage(messages.errCustomInfoTooLong),
            },
        },
        purchaseOrderNumber: {
            length: {
                maximum: 25,
                tooLong: formatMessage(messages.errCustomInfoTooLong),
            },
        },
        customerNumber: {
            length: {
                maximum: 25,
                tooLong: formatMessage(messages.errCustomInfoTooLong),
            },
        },
    };

    const ticketDeliveryConstraints = {
        tdoCode: {
            presence: {
                message: formatMessage(messages.errTdoShouldBeSelected), allowEmpty: false,
            },
        },
    };

    // TODO no array validators, should be available on next validate js version
    const customInfoConstraintsValidation = (items) => {
        const errors = [];
        const types = [];
        items.forEach((item, index) => {
            const error = {};
            const noType = (validate.isEmpty(item.type));
            let notValid = false;

            if (noType) {
                notValid = true;
                error.type = formatMessage(messages.errCustomInfoType);
            }

            if (item.type.length > 255) {
                notValid = true;
                error.type = formatMessage(messages.errCustomInfoTypeValueTooLong);
            }

            if (item.value.length > 255) {
                notValid = true;
                error.value = formatMessage(messages.errCustomInfoTypeValueTooLong);
            }

            if (item.required && validate.isEmpty(item.value)) {
                notValid = true;
                error.value = formatMessage(messages.errCustomInfoValue);
            }

            const isDuplicate = types.some((typeItem) => (
                item.type === typeItem
            ));

            if (isDuplicate) {
                notValid = true;
                error.type = formatMessage(messages.errCustomInfoTypeDuplicate, { errorType: item.type });
            }

            if (item.type.length > 0) {
                types.push(item.type);
            }

            if (notValid) {
                error.index = index;
                errors.push(error);
            }
        });
        return errors;
    };

    return {
        constructBasicConstraints,
        contactConstraints,
        passengersEmail,
        constructTravelDocsConstraints,
        constructDiscountsLoyaltiesConstraints,
        addressConstraints,
        customInfoConstraints,
        ticketDeliveryConstraints,
        customInfoConstraintsValidation,
    };
};
