import colors, { lightGray } from '../../../../colors';
import commonStyles from '../../../../commonStyles';
import bookingViewStyles from '../BookingView/styles';

export default {
    tableWrapper: commonStyles.tableWrapper,
    paperContainer: bookingViewStyles.paperContainer,
    contentContainer: bookingViewStyles.contentContainer,
    bookingTable: bookingViewStyles.bookingTable,
    bookingTableRow: bookingViewStyles.bookingTableRow,
    orangeButton: bookingViewStyles.orangeButton,
    redButton: bookingViewStyles.redButton,
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    status: {
        marginLeft: 10,
        fontWeight: 'bold',
    },
    statusBalanced: {
        backgroundColor: colors.buttonGreenDisabled,
    },
    statusClosed: {
        backgroundColor: lightGray,
    },
    statusCredit: {
        backgroundColor: colors.buttonRed,
    },
    statusDebit: {
        backgroundColor: colors.buttonRed,
    },
    refresh: {
        marginRight: 10,
    },
};
