import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import TableView from '../../../../components/TableView/TableView';
import { formatPrice, decodeHtml } from '../../../../utils';

import { TYPES } from './constants';
import messages from './messages';

function BillingTable({
    addFooter,
    billingItems,
    currency,
    intl,
    intl: { formatMessage },
    type,
    isSortable,
    orders,
}) {
    let total = 0;
    const isOverView = type === TYPES.OVERVIEW;
    const billingSummaryData = billingItems.map((item, index) => {
        const row = [];

        row.push({
            id: `srtBookingBillingDate_${index}`,
            value: item.BookingBillingDate,
        });

        if (isOverView) {
            const orderNumber = item.BookingBillingOrderId && orders
                .find((order) => order.BookingOrderID === item.BookingBillingOrderId)
                .BookingOrderNumber;
            row.push({
                id: `srtBookingBillingOrder_${index}`,
                value: orderNumber,
            });
        }

        row.push({
            id: `srtBookingBillingDesc_${index}`,
            value: decodeHtml(item.BookingBillingDesc),
        });

        let amount;
        if (isOverView) {
            row.push({
                id: `srtBookingBillingCharge_${index}`,
                value: item.BookingBillingRevenue && formatPrice(
                    item.BookingBillingRevenue,
                    item.BookingBillingCurrency,
                    intl,
                ),
                styleName: 'bookingTableRowRight',
            });
            row.push({
                id: `srtBookingBillingPayment_${index}`,
                value: item.BookingBillingReceipt && formatPrice(
                    item.BookingBillingReceipt,
                    item.BookingBillingCurrency,
                    intl,
                ),
                styleName: 'bookingTableRowRight',
            });

            amount = parseFloat(item.BookingBillingRevenue) || 0 - parseFloat(item.BookingBillingReceipt) || 0;
        } else {
            amount = item[type === TYPES.CHARGE ? 'BookingBillingRevenue' : 'BookingBillingReceipt'];
            row.push({
                id: `srtBookingBillingPrice_${index}`,
                value: formatPrice(
                    amount,
                    item.BookingBillingCurrency,
                    intl,
                ),
                styleName: 'bookingTableRowRight',
            });
        }
        total += parseFloat(amount) || 0;

        return row;
    });
    const billingSummaryDataFooter = addFooter && [
        [
            {},
            {
                id: 'srtBookingBillingTotal',
                styleName: 'bookingTableRow bookingTableRowHighlight bookingTableRowRight',
                value: formatMessage(messages.lblBillingTotal),

            },
            {
                id: 'srtBookingBillingTotalReceipts',
                styleName: 'bookingTableRow bookingTableRowRight',
                value: formatPrice(total, currency, intl),

            },
        ],
    ];

    const tableHeaders = isOverView ? [
        formatMessage(messages.lblBillingDate),
        formatMessage(messages.lblBillingOrder),
        formatMessage(messages.lblBillingDescription),
        {
            text: formatMessage(messages.lblBillingCharge),
            align: 'right',
        },
        {
            text: formatMessage(messages.lblBillingPayment),
            align: 'right',
        },
    ] : [
        formatMessage(messages.lblBillingDate),
        formatMessage(messages.lblBillingDescription),
        {
            text: formatMessage(messages.lblBillingAmount),
            align: 'right',
        },
    ];

    return (
        <TableView
            id="billingTable"
            tableHeaders={tableHeaders}
            data={billingSummaryData}
            wrapperHeight="auto"
            showFilter={false}
            tableFooter={billingSummaryDataFooter}
            isSortable={isSortable}
        />
    );
}

BillingTable.propTypes = {
    addFooter: PropTypes.bool,
    billingItems: PropTypes.array.isRequired,
    currency: PropTypes.string,
    intl: PropTypes.object,
    type: PropTypes.oneOf([TYPES.CHARGE, TYPES.PAYMENT, TYPES.OVERVIEW]),
    isSortable: PropTypes.bool,
    orders: PropTypes.array,
};

export { BillingTable as BillingTableAlias };

export default injectIntl(BillingTable);
