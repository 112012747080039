import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@mui/material/CircularProgress';
import messages from './messagesConfirmDialog';
import styles from './styles';

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        ...styles.dialogPaper,
    },
}));

const ConfirmDialog = ({
    onNo,
    onYes,
    yesButtonLabel = <FormattedMessage {...messages.btnYes} />,
    noButtonLabel = <FormattedMessage {...messages.btnNo} />,
    open,
    title = <FormattedMessage {...messages.lblTitle} />,
    message = <FormattedMessage {...messages.lblMessage} />,
    isProcessingSubmit,
}) => {
    const handleClose = () => onNo();
    const actions = [
        <Button
            id="srtConfirmDialogYes"
            key="srtConfirmDialogYes"
            color="primary"
            style={styles.button}
            onClick={onYes}
            variant="contained"
            disabled={isProcessingSubmit}
        >
            {yesButtonLabel}
        </Button>,
        <Button
            id="srtConfirmDialogNo"
            key="srtConfirmDialogNo"
            style={styles.button}
            onClick={handleClose}
            variant="contained"
            disabled={isProcessingSubmit}
        >
            {noButtonLabel}
        </Button>,
    ];

    return (
        <StyledDialog open={open} onClose={handleClose}>
            {isProcessingSubmit && (
                <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                    <CircularProgress />
                </div>
            )}
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions>
                {actions}
            </DialogActions>
        </StyledDialog>
    );
};

ConfirmDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.node,
    message: PropTypes.node,
    yesButtonLabel: PropTypes.node,
    noButtonLabel: PropTypes.node,
    onYes: PropTypes.func.isRequired,
    onNo: PropTypes.func.isRequired,
    isProcessingSubmit: PropTypes.bool,
};

export default ConfirmDialog;
