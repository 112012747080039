import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from './messagesFareRulesMatrixContent';

const FareTerminals = ({
    fareTerminals,
}) => (fareTerminals && (
    <>
        <FormattedMessage {...messages.lblFareTerminalsValidityText} />
        <br />
        {fareTerminals.replace(/ *\([^)]*\) */g, '')}
    </>
)) || '';

FareTerminals.propTypes = {
    intl: PropTypes.object,
    fareTerminals: PropTypes.string,
};

export { FareTerminals as FareTerminalsAlias };
export default FareTerminals;
