import { redLightGray } from '../../../colors';

export default {
    indicator: {
        zIndex: 2000,
        marginTop: '10%',
        marginLeft: '48%',
    },
    dialogPaper: {
        overflowX: 'hidden',
        overflowY: 'visible',
        maxWidth: 768,
    },
    dialogActionsRoot: {
        width: '100%',
    },
    titleLabelContainer: {
        fontSize: 19,
        color: redLightGray,
        fontWeight: 200,
    },
};
