export default {
    dialogPaper: {
        overflowY: 'visible',
        width: '85%',
    },
    dialogActionsRoot: {
        width: '100%',
    },
    dialogActionsShoppingSearch: {
        display: 'flex',
        gap: '20px',
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableHead: {
        whiteSpace: 'nowrap',
    },
    h2: {
        margin: 0,
    },
    arrow: {
        verticalAlign: 'text-bottom',
    },
    seatButtons: {
        display: 'flex',
        gap: 30,
        marginBottom: 15,
        marginTop: 15,
    },
    confirmButton: {
        marginLeft: 10,
    },
    buttonActions: {
        marginRight: 20,
    },
};
