import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { styled } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import BillingTable from '../BillingTable/BillingTable';
import { TYPES as BILLING_TABLE_TYPES } from '../BillingTable/constants';
import OrderTitle from '../OrderTitle/OrderTitle';
import { formatPrice } from '../../../../utils';
import messages from './messages';
import styles from './styles';
import { gaEvent } from '../../../../utils/googleAnalytics';
import { setOpenIssueRefundDialog } from '../../actionsManageBooking';

const StyledSpanTotalAmount = styled('span')(() => ({
    ...styles.totalAmount,
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
    '& .MuiAccordionSummary-content': {
        ...styles.expansionPanelSummaryContent,
    },
}));

const StyledNoMarginH2 = styled('h2')(() => ({
    ...styles.noMargin,
}));

const StyledOrangeButton = styled(Button)(() => ({
    ...styles.orangeButton,
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
    ...styles.expansionPanelDetailsCharges,
}));

const StyledRedButton = styled(Button)(() => ({
    ...styles.redButton,
}));

const StyledDivBalanceContainer = styled('div')(() => ({
    ...styles.expansionPanelSummaryContent,
    ...styles.balanceContainer,
}));

const BillingSummaryItemized = ({
    booking,
    enableBookingAddFee,
    handleOpenAddFeeDiscountDialog,
    handleOpenIssueRefundDialog,
    handleOpenMakePaymentDialog,
    intl,
}) => {
    const dispatch = useDispatch();

    const billingSummaryWithIndex = booking.BookingBillingSummary.map((item, index) => ({
        ...item,
        index,
    }));

    const getTotalSubCharges = (billingCharges) => billingCharges
        .reduce((total, row) => total + parseFloat(row.BookingBillingRevenue), 0)
        .toFixed(2)
        .replace('-0.00', '0.00');

    const getTotalAmount = (amount) => {
        const amountFormatted = formatPrice(amount, booking.BookingBillingTotal.BookingBillingTotalCurrency, intl);
        return (
            <FormattedMessage {...messages.lblTotalAmount} values={{ amount: amountFormatted }}>
                {(txt) => <StyledSpanTotalAmount>{txt}</StyledSpanTotalAmount>}
            </FormattedMessage>
        );
    };

    const handleIssueRefund = () => {
        handleOpenIssueRefundDialog();
        dispatch(setOpenIssueRefundDialog());
    };

    const getOrderPanels = () => (
        booking.BookingOrders.map((order) => {
            const billingChargesOrder = billingSummaryWithIndex.filter((row) => row.BookingBillingOrderId === order.BookingOrderID);
            const orderTotal = getTotalSubCharges(billingChargesOrder);
            return (
                <Accordion
                    key={order.BookingOrderID}
                    onChange={(event, expanded) => gaEvent(expanded ? 'expandOrderSection' : 'collapseOrderSection')}
                >
                    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div><OrderTitle order={order} /></div>
                        {getTotalAmount(orderTotal)}
                    </StyledAccordionSummary>
                    <AccordionDetails>
                        <BillingTable
                            billingItems={billingChargesOrder}
                            currency={booking.BookingBillingTotal.BookingBillingTotalCurrency}
                            type={BILLING_TABLE_TYPES.CHARGE}
                        />
                    </AccordionDetails>
                </Accordion>
            );
        })
    );
    const billingPayments = billingSummaryWithIndex.filter((row) => row.BookingBillingReceipt);
    const billingAdditionalCharges = billingSummaryWithIndex.filter((row) => !row.BookingBillingOrderId && row.BookingBillingRevenue);
    const billingAdditionalChargesTotal = getTotalSubCharges(billingAdditionalCharges);

    return (
        <>
            {/* Charges */}
            <Accordion
                onChange={(event, expanded) => gaEvent(expanded ? 'expandTotalChargesSection' : 'collapseTotalChargesSection')}
            >
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container>
                        <StyledNoMarginH2>
                            <FormattedMessage {...messages.lblCharges} />
                        </StyledNoMarginH2>
                        <Grid item xs={12} sm={6} md={4} lg={3} style={{ marginLeft: 12 }}>
                            <StyledOrangeButton
                                disabled={!enableBookingAddFee}
                                variant="contained"
                                id="srtBookingAddFeeDiscount"
                                fullWidth
                                onClick={handleOpenAddFeeDiscountDialog}
                            >
                                <FormattedMessage {...messages.btnAddFeeDiscount} />
                            </StyledOrangeButton>
                        </Grid>
                    </Grid>
                    {getTotalAmount(booking.BookingBillingTotal.BookingBillingTotalRevenue)}
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    {getOrderPanels()}
                    {/* Additional Charges */}
                    <Accordion
                        onChange={(event, expanded) => gaEvent(expanded ? 'expandAdditionalChargesSection' : 'collapseAdditionalChargesSection')}
                    >
                        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <FormattedMessage {...messages.lblAdditionalCharges}>
                                {(txt) => <StyledNoMarginH2>{txt}</StyledNoMarginH2>}
                            </FormattedMessage>
                            {getTotalAmount(billingAdditionalChargesTotal)}
                        </StyledAccordionSummary>
                        <AccordionDetails>
                            <BillingTable
                                billingItems={billingAdditionalCharges}
                                currency={booking.BookingBillingTotal.BookingBillingTotalCurrency}
                                type={BILLING_TABLE_TYPES.CHARGE}
                            />
                        </AccordionDetails>
                    </Accordion>
                </StyledAccordionDetails>
            </Accordion>
            {/* Payments */}
            <Accordion
                onChange={(event, expanded) => gaEvent(expanded ? 'expandTotalPaymentsSection' : 'collapseTotalPaymentsSection')}
            >
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container>
                        <StyledNoMarginH2>
                            <FormattedMessage {...messages.lblPayments} />
                        </StyledNoMarginH2>
                        {booking.BookingBillingTotal.BookingBillingPaymentPossible && (
                            <Grid item xs={12} sm={6} md={4} lg={3} style={{ marginLeft: 12 }}>
                                <Button
                                    variant="contained"
                                    id="srtBookingMakePayment"
                                    color="primary"
                                    fullWidth
                                    onClick={handleOpenMakePaymentDialog}
                                >
                                    <FormattedMessage {...messages.btnMakePayment} />
                                </Button>
                            </Grid>
                        )}
                        {booking.BookingBillingTotal.BookingBillingRefundPossible && (
                            <Grid item xs={12} sm={6} md={4} lg={3} style={{ marginLeft: 12 }}>
                                <StyledRedButton
                                    variant="contained"
                                    id="srtBookingIssueRefund"
                                    fullWidth
                                    onClick={handleIssueRefund}
                                >
                                    <FormattedMessage {...messages.btnIssueRefund} />
                                </StyledRedButton>
                            </Grid>
                        )}
                    </Grid>

                    {getTotalAmount(booking.BookingBillingTotal.BookingBillingTotalReceipts)}
                </StyledAccordionSummary>
                <AccordionDetails>
                    <BillingTable
                        billingItems={billingPayments}
                        currency={booking.BookingBillingTotal.BookingBillingTotalCurrency}
                        type={BILLING_TABLE_TYPES.PAYMENT}
                    />
                </AccordionDetails>
            </Accordion>
            {/* Balance */}
            <StyledDivBalanceContainer>
                <StyledNoMarginH2>
                    <FormattedMessage {...messages.lblBillingBalance} />
                </StyledNoMarginH2>
                {getTotalAmount(booking.BookingBillingTotal.BookingBillingTotalBalance)}
            </StyledDivBalanceContainer>
        </>
    );
};

BillingSummaryItemized.propTypes = {
    booking: PropTypes.object.isRequired,
    handleOpenAddFeeDiscountDialog: PropTypes.func,
    enableBookingAddFee: PropTypes.bool,
    handleOpenMakePaymentDialog: PropTypes.func,
    handleOpenIssueRefundDialog: PropTypes.func,
    intl: PropTypes.object,
};

export { BillingSummaryItemized as BillingSummaryItemizedAlias };

export default injectIntl(BillingSummaryItemized);
