import { defineMessages } from 'react-intl';

export default defineMessages({
    lblOrderCanceled: {
        id: 'app.shopping.manageBooking.orderDetails.orderCanceled',
        defaultMessage: 'Order has been cancelled',
    },
    lblOrderCantCancel: {
        id: 'app.shopping.manageBooking.orderDetails.orderCantCancel',
        defaultMessage: 'Order can not be cancelled',
    },
    btnCancelOrderPenalty: {
        id: 'app.shopping.manageBooking.orderDetails.cancelOrderPenalty',
        defaultMessage: '{amount} Penalty',
    },
    btnCancelOrder: {
        id: 'app.shopping.manageBooking.orderDetails.cancelOrder',
        defaultMessage: 'Cancel Order',
    },
    btnCancelOrderNoPenalty: {
        id: 'app.shopping.manageBooking.orderDetails.cancelOrderNoPenalty',
        defaultMessage: 'Cancel Order',
    },
    lblConfirmationInformation: {
        id: 'app.shopping.manageBooking.orderDetails.confirmationInformation',
        defaultMessage: 'Confirmation Information',
    },
    lblConfirmationNotRequired: {
        id: 'app.shopping.manageBooking.orderDetails.confirmationNotRequired',
        defaultMessage: 'not required',
    },
    lblConfirmationCreditCard: {
        id: 'app.shopping.manageBooking.orderDetails.confirmationCreditCard',
        defaultMessage: 'Credit Card',
    },
    lblConfirmationDebitCard: {
        id: 'app.shopping.manageBooking.orderDetails.confirmationDebitCard',
        defaultMessage: 'Debit Card',
    },
    lblConfirmationLoyaltyCard: {
        id: 'app.shopping.manageBooking.orderDetails.confirmationLoyaltyCard',
        defaultMessage: 'Loyalty Card',
    },
    lblConfirmationUsing: {
        id: 'app.shopping.manageBooking.orderDetails.confirmationUsing',
        defaultMessage: 'Confirmed using: {cardType}',
    },
    lblOrderNumber: {
        id: 'app.shopping.manageBooking.orderDetails.orderNumber',
        defaultMessage: 'Order #{index}',
    },
    lblOrderConfirmBy: {
        id: 'app.shopping.manageBooking.orderDetails.orderConfirmBy',
        defaultMessage: ' - confirm by ',
    },
    lblCancellationReason: {
        id: 'app.shopping.manageBooking.orderDetails.cancellationReason',
        defaultMessage: 'Cancellation Reason:',
    },
    btnViewTicketDelivery: {
        id: 'app.shopping.manageBooking.orderDetails.viewTicketDelivery',
        defaultMessage: 'View Ticket Delivery',
    },
    btnEditTicketDelivery: {
        id: 'app.shopping.manageBooking.orderDetails.editTicketDelivery',
        defaultMessage: 'Edit Ticket Delivery',
    },
    btnAddTicketDelivery: {
        id: 'app.shopping.manageBooking.orderDetails.addTicketDelivery',
        defaultMessage: 'Add Ticket Delivery',
    },
    lblOrderCantVoid: {
        id: 'app.shopping.manageBooking.orderDetails.orderCantVoid',
        defaultMessage: 'Order can not be voided',
    },
    btnVoidOrder: {
        id: 'app.shopping.manageBooking.orderDetails.voidOrder',
        defaultMessage: 'Void Order',
    },
    btnExchangeOrder: {
        id: 'app.shopping.manageBooking.orderDetails.exchangeOrder',
        defaultMessage: 'Exchange Order',
    },
    btnExchangeSeasonTicket: {
        id: 'app.shopping.manageBooking.orderDetails.exchangeSeasonTicket',
        defaultMessage: 'Exchange Season Ticket',
    },
    btnConfirmOrder: {
        id: 'app.shopping.manageBooking.orderDetails.confirmOrder',
        defaultMessage: 'Confirm Order',
    },
    btnReturnCoupons: {
        id: 'app.shopping.manageBooking.orderDetails.returnCoupons',
        defaultMessage: 'Return Coupons',
    },
    btnEmailOrderConfirmation: {
        id: 'app.shopping.manageBooking.orderDetails.emailOrderConfirmation',
        defaultMessage: 'Email Order Confirmation',
    },
    btnAllowAnyCardCollect: {
        id: 'app.shopping.manageBooking.orderDetails.allowAnyCardCollect',
        defaultMessage: 'Allow Any Card Collect',
    },
    btnResendTicket: {
        id: 'app.shopping.manageBooking.orderDetails.resendTicket',
        defaultMessage: 'Resend Ticket',
    },
    btnSendTicket: {
        id: 'app.shopping.manageBooking.orderDetails.sendTicket',
        defaultMessage: 'Send Ticket',
    },
    btnAmendCTR: {
        id: 'app.shopping.manageBooking.orderDetails.AmendCTR',
        defaultMessage: 'Amend CTR',
    },
    btnAuthorizeRefund: {
        id: 'app.shopping.manageBooking.orderDetails.authorizeRefund',
        defaultMessage: 'Authorize Refund',
    },
    btnEditTrackingInfo: {
        id: 'app.shopping.manageBooking.orderDetails.editTrackingInfo',
        defaultMessage: 'Edit Tracking Info',
    },
    lblDateBooked: {
        id: 'app.shopping.manageBooking.orderDetails.dateBooked',
        defaultMessage: 'Date Booked',
    },
    lblCarrierLocator: {
        id: 'app.shopping.manageBooking.orderDetails.carrierLocator',
        defaultMessage: 'Carrier Locator',
    },
    lblExternalSystemLocator: {
        id: 'app.shopping.manageBooking.orderDetails.externalSystemLocator',
        defaultMessage: 'External System Locator',
    },
    btnSupplierNotes: {
        id: 'app.shopping.manageBooking.orderDetails.supplierNotes',
        defaultMessage: 'Supplier Notes',
    },
    btnAmendSeatReservation: {
        id: 'app.shopping.manageBooking.orderDetails.amendSeatReservation',
        defaultMessage: 'Amend Seat Reservation',
    },
    lblFulfillmentTitle: {
        id: 'app.shopping.manageBooking.fulfillmentInformation.title',
        defaultMessage: 'Fulfillment Information',
    },
    lblBillingSummary: {
        id: 'app.shopping.manageBooking.orderBillingSummary.billingSummary',
        defaultMessage: 'Billing Summary',
    },
    lblOrderDetailsTitle: {
        id: 'app.shopping.OrderDetailsDetails.title',
        defaultMessage: 'Order Details',
    },
});
