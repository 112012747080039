export default {
    cardText: {
        fontSize: 16,
        paddingTop: 10,
        paddingBottom: 7,
    },
    corporateRow: {
        paddingBottom: 10,
    },
    rowBottomAlign: {
        display: 'inline-block',
        verticalAlign: 'bottom',
        float: 'none',
    },
};
