import { defineMessages } from 'react-intl';

export default defineMessages({
    lblTitle: {
        id: 'app.shopping.confirmedCardViewDialog.title',
        defaultMessage: 'Selected Credit Card Confirmation Information',
    },
    btnClose: {
        id: 'app.shopping.confirmedCardViewDialog.close',
        defaultMessage: 'Close',
    },
    lblFirstName: {
        id: 'app.shopping.confirmedCardViewDialog.firstName',
        defaultMessage: 'First Name',
    },
    lblLastName: {
        id: 'app.shopping.confirmedCardViewDialog.lastName',
        defaultMessage: 'Last Name',
    },
    lblMiddleName: {
        id: 'app.shopping.confirmedCardViewDialog.middleName',
        defaultMessage: 'Middle Name',
    },
    lblCardNumber: {
        id: 'app.shopping.confirmedCardViewDialog.cardNumber',
        defaultMessage: 'Card Number',
    },
    lblExpirationDate: {
        id: 'app.shopping.confirmedCardViewDialog.expirationDate',
        defaultMessage: 'Expiration Date',
    },
    lblLoyaltyCardProgram: {
        id: 'app.shopping.confirmedCardViewDialog.loyaltyCardProgram',
        defaultMessage: 'Loyalty Card Program',
    },
    lblLoyaltyCardIdentifier: {
        id: 'app.shopping.confirmedCardViewDialog.LoyaltyCardIdentifier',
        defaultMessage: 'Loyalty Card Identifier',
    },
});
