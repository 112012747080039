import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { initializeValidateJS } from './utils';
import App from './App';

initializeValidateJS();

const container = document.getElementById('silverAgentApp');
const root = createRoot(container);

root.render(<App />);

const spinner = document.getElementById('srt-spinner-container');
if (spinner) spinner.style.display = 'none';
