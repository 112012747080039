import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import validate from 'validate.js';
import sortBy from 'lodash/sortBy';

import ReactSelectWrapped from '../../../components/ReactSelectWrapped/ReactSelectWrapped';
import {
    CORPORATE,
    UK_CORPORATE,
    UK_TADA,
    PROMOTION,
    additionalRailcards,
} from '../../util';

import messages from '../messagesShoppingSearch';
import '../shoppingSearch.css';

const Discounts = ({
    journey,
    railCards,
    availableSuppliers,
    discountProgram,
    railcardId,
    discountId,
    totalDiscounts,
    maxNumDiscountRows,
    disabled,
    onAddDiscount,
    onRemoveDiscount,
    onChangeDiscountProgram,
    onChangeRailcardId,
    errors,
    index,
    intl,
    passenger,
    onChangePassenger,
    paxAge,
}) => {
    const { formatMessage } = intl;

    Object.assign(railCards, additionalRailcards(intl));

    const discountCard = railCards[Object.keys(railCards).find((key) => railCards[key].RailCardProgram === discountProgram)] || {};
    const isCorporate = discountCard.RailCardType === CORPORATE;
    const isFarePromotion = discountCard.RailCardType === PROMOTION;
    const isUKCorporate = discountCard.RailCardProgram === UK_CORPORATE;
    const isTADA = discountCard.RailCardProgram === UK_TADA;
    const isFRMilitaire = discountCard?.RailCardProgram?.includes('FR_MILITAIRE'); // FR_FAMILLE_MILITAIRE
    const maxNumDiscountCards = isCorporate || isFarePromotion ? 1 : paxAge.length;

    const discountCardsNr = [];
    for (let i = 1; i <= maxNumDiscountCards; i++) {
        discountCardsNr.push(<MenuItem id={`srtRailcardNumber${discountId}_${i}`} value={i} key={i}>{i}</MenuItem>);
    }

    const departSuppliers = journey.departStation.supplier?.split(',');
    const arriveSuppliers = journey.arriveStation.supplier?.split(',');
    const railCardSuppliers = departSuppliers.concat(arriveSuppliers.filter((item) => !departSuppliers.includes(item)))
        .filter((supplier) => availableSuppliers.includes(supplier));

    let railCardOptions = [];
    Object.keys(railCards).forEach((key) => {
        const railCard = railCards[key];
        if (railCardSuppliers.includes(railCard.RailCardSupplier)) {
            railCardOptions.push({ value: railCard.RailCardProgram, label: railCard.RailCardDesc });
        }
    });
    railCardOptions = [
        { value: '', label: formatMessage(messages.lblDiscountsSelect) },
        ...sortBy(railCardOptions, ['label']),
    ];

    const discountsEnabled = (journey.departStation.code !== '' || journey.arriveStation.code !== '') && railCardOptions.length;
    const discountErrors = !validate.isEmpty(errors.discounts)
        ? errors.discounts.find((item) => item.index === index) : undefined;

    let placeholder = messages.lblDiscountsCorporate;
    if (isTADA) {
        placeholder = messages.lblDiscountsTADA;
    }
    if (isFRMilitaire) {
        placeholder = messages.lblFRMilitaire;
    }

    return discountsEnabled && (
        <div className="row">
            <div className="col-5 col-sm-4 col-md-5 col-lg-5 col-xl-5">
                <ReactSelectWrapped
                    id={`srtDiscount${discountId}`}
                    fullWidth
                    value={discountProgram || ''}
                    onChange={onChangeDiscountProgram}
                    disabled={disabled}
                    options={railCardOptions}
                    windowing
                    menuPlacement="top"
                />
            </div>
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
                {discountCard.ShouldMapRailCardToPassenger && (
                    <TextField
                        id={`srtDiscountPassenger${discountId}`}
                        fullWidth
                        placeholder={formatMessage(messages.lblDiscountsPassengerPlaceholder)}
                        value={passenger}
                        disabled={disabled}
                        onChange={onChangePassenger}
                        select
                        variant="standard"
                    >
                        {paxAge.map((_, passengerIndex) => (
                            <MenuItem id={`srtRailcardPassenger${discountId}_${passengerIndex}`} value={passengerIndex} key={passengerIndex}>
                                <FormattedMessage {...messages.lblPassengerLabel} values={{ id: passengerIndex + 1 }} />
                            </MenuItem>
                        ))}
                    </TextField>
                )}
            </div>
            <div className="col-3 col-sm-4 col-md-3 col-lg-3 col-xl-2" styleName="buttons-verticalAlign">
                {(discountId > 0) && (
                    <Button
                        variant="contained"
                        id={`srtRemoveDiscount${discountId}`}
                        fullWidth
                        onClick={onRemoveDiscount}
                        disabled={disabled}
                    >
                        <FormattedMessage {...messages.lblDiscountsRemove} />
                    </Button>
                )}
                {(discountId === 0 && totalDiscounts < maxNumDiscountRows) && (
                    <Button
                        variant="contained"
                        id="srtAddDiscount"
                        color="secondary"
                        fullWidth
                        onClick={onAddDiscount}
                        disabled={disabled}
                    >
                        <FormattedMessage {...messages.lblDiscountsAdd} />
                    </Button>
                )}
            </div>
            {(discountCard.RailCardIsIDRequired && (isCorporate || isUKCorporate || isTADA || isFarePromotion || isFRMilitaire)) && (
                <div className="col-6 col-sm-8">
                    <TextField
                        id={`srtRailcardId${discountId}`}
                        value={railcardId}
                        onChange={onChangeRailcardId}
                        fullWidth
                        placeholder={formatMessage(placeholder)}
                        disabled={disabled}
                        error={discountErrors && !!discountErrors.error}
                        helperText={discountErrors && discountErrors.error}
                        variant="standard"
                    />
                </div>
            )}
        </div>
    );
};

Discounts.propTypes = {
    availableSuppliers: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    discountId: PropTypes.number,
    discountProgram: PropTypes.string,
    errors: PropTypes.object,
    index: PropTypes.number,
    intl: PropTypes.object,
    journey: PropTypes.object.isRequired,
    maxNumDiscountRows: PropTypes.number,
    maxNumPax: PropTypes.number,
    onAddDiscount: PropTypes.func,
    onChangeDiscountProgram: PropTypes.func,
    onChangePassenger: PropTypes.func,
    onChangeRailcardId: PropTypes.func,
    onRemoveDiscount: PropTypes.func,
    passenger: PropTypes.number,
    paxAge: PropTypes.array,
    railcardId: PropTypes.string,
    railCards: PropTypes.object.isRequired,
    totalDiscounts: PropTypes.number,
};

export { Discounts as DiscountsAlias };

export default injectIntl(Discounts);
