import { lightShadow, darkShadow } from '../../colors';

export default {
    chipContainer: {
        boxShadow: `${lightShadow} 0px 3px 10px, ${darkShadow} 0px 3px 5px`,
        borderRadius: 7,
        padding: 5,
    },
    chip: {
        margin: '5px 10px 5px 0px',
        fontSize: 12,
        maxWidth: '100%',
    },
    chipLabel: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
    },
    subheader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    filter: {
        marginTop: 6,
        marginBottom: 10,
    },
};
