import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import BookingNotesTable from '../../../components/BookingNotes/BookingNotesTable';
import BookingNotesDialog from '../../../components/BookingNotes/BookingNotesDialog';

import messages from './messages';
import styles from './styles';

const StyledContentContainerDiv = styled('div')({
    ...styles.contentContainer,
});

const StyledPaper = styled(Paper)({
    ...styles.paperContainer,
});

function OrderNotes({
    booking,
    handleNotesDialogDisplay,
    openNotesDialog,
}) {
    return (
        <StyledContentContainerDiv>
            <StyledPaper elevation={1}>
                <h2><FormattedMessage {...messages.lblNotes} /></h2>
                <BookingNotesTable
                    id="srtBookingNotes"
                    notes={booking.BookingNotes}
                    isDialog={false}
                />
                <Button
                    variant="contained"
                    id="srtBookingAddNote"
                    color="secondary"
                    onClick={() => { handleNotesDialogDisplay(true); }}
                >
                    <FormattedMessage {...messages.btnAddNote} />
                </Button>
                <BookingNotesDialog
                    open={openNotesDialog}
                    booking={booking}
                    onClose={() => { handleNotesDialogDisplay(false); }}
                />
            </StyledPaper>
        </StyledContentContainerDiv>
    );
}

OrderNotes.propTypes = {
    booking: PropTypes.object.isRequired,
    handleNotesDialogDisplay: PropTypes.func.isRequired,
    openNotesDialog: PropTypes.bool.isRequired,
};

export default OrderNotes;
