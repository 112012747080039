import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import getStyles from './styles';

const StyledPaper = styled(Paper)(() => ({
    minWidth: 'unset !important',
}));

const RefreshIndicator = ({
    left = 0,
    size = 40,
    status = 'hide',
    style,
    top = 0,
    thickness = 5,
}) => {
    const styles = Object.assign(getStyles(size, top, left), style);

    return (
        status !== 'hide' && (
            <StyledPaper style={styles} data-testid="refresh-indicator">
                <CircularProgress size={size * 0.6} thickness={thickness} data-testid="circular-progress" />
            </StyledPaper>
        )
    );
};

RefreshIndicator.propTypes = {
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size: PropTypes.number,
    status: PropTypes.oneOf(['loading', 'hide']),
    style: PropTypes.object,
    top: PropTypes.number,
    thickness: PropTypes.number,
};

export default RefreshIndicator;
