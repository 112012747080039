import React, { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { inputStyles } from './styles';

const StyledDiv = styled('div')(() => ({
    ...inputStyles.root,
    '& > iframe': {
        border: 'none',
        width: '100%',
        height: '100%',
    },
}));

const Input = forwardRef(({
    hostedFieldsInstance,
    id,
    onBlur,
    onFocus,
    ...other
}, ref) => {
    useEffect(() => {
        if (hostedFieldsInstance) {
            const field = id.split('braintree-')[1];
            hostedFieldsInstance.on('blur', (event) => {
                if (event.emittedBy === field) {
                    onBlur();
                }
            });
            hostedFieldsInstance.on('focus', (event) => {
                if (event.emittedBy === field) {
                    onFocus();
                }
            });
        }
    }, [hostedFieldsInstance, id, onBlur, onFocus]);

    return (
        <StyledDiv ref={ref} id={id} data-testid={id} {...other} />
    );
});

Input.propTypes = {
    hostedFieldsInstance: PropTypes.object,
    id: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
};

Input.displayName = 'Input';

export { Input as InputAlias };

export default Input;
