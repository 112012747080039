import { defineMessages } from 'react-intl';

export default defineMessages({
    lblFare: {
        id: 'app.shopping.fareRulesMatrixContent.fare',
        defaultMessage: 'Fare: {displayName}',
    },
    lblPaxTitle: {
        id: 'app.shopping.fareRulesMatrixContent.paxTitle',
        defaultMessage: 'Fare Rules Matrix for fare(s)',
    },
    lblFareValidityTitle: {
        id: 'app.shopping.fareRulesMatrixContent.fareValidityTitle',
        defaultMessage: 'Fare Validity',
    },
    lblFareValidityText: {
        id: 'app.shopping.fareRulesMatrixContent.fareValidityText',
        defaultMessage: 'This ticket is valid from ',
    },
    lblFareValidityToText: {
        id: 'app.shopping.fareRulesMatrixContent.fareValidityToText',
        defaultMessage: 'This ticket is valid to ',
    },
    lblFareTerminalsValidityText: {
        id: 'app.shopping.fareRulesMatrixContent.fareTerminalsValidityText',
        defaultMessage: ', including the following stations: ',
    },
    lblSeasonTitle: {
        id: 'app.shopping.fareRulesMatrixContent.seasonTitle',
        defaultMessage: 'Fare Rules Matrix for Season Pass',
    },
    lblAnytimeFareTicketFAQPart1: {
        id: 'app.shopping.fareRulesMatrixContent.lblAnytimeFareTicketFAQPart1',
        defaultMessage: 'When can I use an Anytime ticket?',
    },
    lblAnytimeFareTicketFAQPart2: {
        id: 'app.shopping.fareRulesMatrixContent.lblAnytimeFareTicketFAQPart2',
        defaultMessage: 'Anytime Day (Single and Return)',
    },
    lblAnytimeFareTicketFAQPart2Desc: {
        id: 'app.shopping.fareRulesMatrixContent.lblAnytimeFareTicketFAQPart2Desc',
        defaultMessage: 'tickets must be used on the date shown on your ticket and up to 04:29 the following day.',
    },
    lblAnytimeFareTicketFAQPart3: {
        id: 'app.shopping.fareRulesMatrixContent.lblAnytimeFareTicketFAQPart3',
        defaultMessage: 'Anytime Single',
    },
    lblAnytimeFareTicketFAQPart3Desc: {
        id: 'app.shopping.fareRulesMatrixContent.lblAnytimeFareTicketFAQPart3Desc',
        defaultMessage: 'tickets must be used within 2 days of the date shown on the ticket and up until 04:29 after the last day of validity.',
    },
    lblAnytimeFareTicketFAQPart4: {
        id: 'app.shopping.fareRulesMatrixContent.lblAnytimeFareTicketFAQPart4',
        defaultMessage: 'Anytime Return',
    },
    lblAnytimeFareTicketFAQPart4Desc: {
        id: 'app.shopping.fareRulesMatrixContent.lblAnytimeFareTicketFAQPart4Desc', // eslint-disable-next-line max-len
        defaultMessage: 'tickets, the outward journey must be made within 5 days and up until 04:29 after the last day of validity; the return portion must be used within one calendar month and up until 04:29 after the last day of validity.',
    },
});
