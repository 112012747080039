import { defineMessages } from 'react-intl';

export default defineMessages({
    lblCustomFields: {
        id: 'app.shopping.customFields.lblCustomFields',
        defaultMessage: 'Custom Fields',
    },
    lblReferenceNo: {
        id: 'app.shopping.customFields.lblReferenceNo',
        defaultMessage: 'Reference #',
    },
    lblPONumber: {
        id: 'app.shopping.customFields.lblPONumber',
        defaultMessage: 'PO #',
    },
    lblCustomerNo: {
        id: 'app.shopping.customFields.lblCustomerNo',
        defaultMessage: 'Customer #',
    },
    lblAddCustomInfo: {
        id: 'app.shopping.customFields.addCustomInfo',
        defaultMessage: 'Add Custom Information?',
    },
});
