import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { injectIntl } from 'react-intl';

import '../stylesManageAccounts.css';
import Searchbar from '../../components/Searchbar';
import TableView from '../../../components/TableView/TableView';

import { accountSearchApi } from '../../apiAdmin';
import { getBasePath, safeEncode, safeDecode } from '../../../utils';
import { gaEvent } from '../../../utils/googleAnalytics';
import messages from '../messagesManageAccounts';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';

const basePath = `${getBasePath()}admin/ManageAccounts/`;
const userPath = `${getBasePath()}admin/ManageUsers/EditUser/`;

class AccountSearch extends Component {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        match: PropTypes.object,
        settings: PropTypes.object,
        history: PropTypes.object,
    };

    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            data: fromJS({
                searchStr: '',
                accounts: [],
                alertText: '',
                isProcessingSearch: false,
            }),
        };
    }

    componentDidMount() {
        const searchStr = this.props.match.params?.searchStr;
        if (searchStr) {
            this.initiateSearch(safeDecode(searchStr));
        }
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.match.params && (!this.props.match.params || this.props.match.params?.searchStr !== nextProps.match.params?.searchStr)) {
            const searchStr = nextProps.match.params?.searchStr;
            if (searchStr) {
                this.initiateSearch(safeDecode(searchStr));
            }
        }
    }

    tableHeaders = (formatMessage) => ([
        formatMessage(messages.lblTableAccountType),
        formatMessage(messages.lblTableAccountName),
        formatMessage(messages.lblTableAssocAccount),
        formatMessage(messages.lblTableAssocUsers),
    ]);

    initiateSearch = (searchStr) => {
        const { intl: { formatMessage } } = this.props;
        this.setState((state) => ({
            data: state.data.merge({
                accounts: [],
                searchStr,
                isProcessingSearch: true,
            }),
        }));
        accountSearchApi(
            searchStr.trim(),
            () => {
                this.setState((state) => ({
                    data: state.data.merge({
                        alertText: formatMessage(messages.errSearch),
                        isProcessingSearch: false,
                    }),
                }));
            },
            (response) => {
                const results = response.successResponse.data.AccountManagementSearchResults;
                if (results.length) {
                    this.setState((state) => ({
                        data: state.data.merge({
                            accounts: this.transformListToTableViewStructure(results),
                            searchStr,
                            alertText: '',
                            isProcessingSearch: false,
                        }),
                    }));
                } else {
                    this.setState((state) => ({
                        data: state.data.merge({
                            alertText: formatMessage(messages.searchNoResults),
                            isProcessingSearch: false,
                        }),
                    }));
                }
            },
        );
    };

    transformAssocAccountsColumnTableViewHelper = (hasMaxAccounts, accounts, groupName) => {
        const { intl: { formatMessage } } = this.props;
        let column = null;
        if (hasMaxAccounts) {
            column = [{
                id: groupName,
                value: `${accounts.length} ${formatMessage(messages.lblTableAssocAccount).toLowerCase()}`,
                link: `${basePath}AccountGroup/${groupName}`,
            }];
        } else {
            column = (accounts.map((account) => {
                const accountName = account.AccountManagementAccountName;
                return {
                    id: accountName,
                    value: accountName,
                    link: `${basePath}Account/${accountName}`,
                };
            }));
        }
        return column;
    };

    transformAssocUsersColumnTableViewHelper = (hasMaxUsers, users, accountLink, index) => {
        const { intl: { formatMessage } } = this.props;
        let column = null;
        if (hasMaxUsers) {
            column = [{
                id: `associatedUsersNumber${index}`,
                value: `${users.length} ${formatMessage(messages.lblTableAssocUsers).toLowerCase()}`,
                link: accountLink,
            }];
        } else {
            column = (users.map((user) => {
                const username = user.AccountManagementAssocUserLoginId;
                const fullName = `${user.AccountManagementAssocUserDataUsernameFirst} `
                    + `${user.AccountManagementAssocUserDataUsernameLast} `
                    + `[${user.AccountManagementAssocUserLoginId}]`;
                return {
                    id: username,
                    value: fullName,
                    link: `${userPath}${username}`,
                };
            }));
        }
        return column;
    };

    transformListToTableViewStructure = (results) => {
        const { intl: { formatMessage } } = this.props;
        return results.map((row, index) => {
            const typeLabel = row.AccountManagementIsGroup ? formatMessage(messages.lblGroup) : formatMessage(messages.lblAccount);
            const accountName = row.Label;
            const accountLink = `${basePath}${row.AccountManagementIsGroup ? 'AccountGroup' : 'Account'}/${safeEncode(accountName)}`;
            const accounts = row.AccountManagementAssocAccounts;
            const users = row.AccountManagementAssocUsers;
            const associatedAccounts = this.transformAssocAccountsColumnTableViewHelper((accounts.length >= 50), accounts, accountName);
            const associatedUsers = this.transformAssocUsersColumnTableViewHelper((users.length >= 50), users, accountLink, index);
            return ([
                {
                    id: `${typeLabel}${index}`,
                    value: typeLabel,
                },
                {
                    id: accountName,
                    value: accountName,
                    link: accountLink,
                },
                {
                    id: `associatedAccounts${index}`,
                    value: associatedAccounts,
                },
                {
                    id: `associatedUsers${index}`,
                    value: associatedUsers,
                },
            ]);
        });
    };

    handleChangeSearch = ({ target: { value } }) => {
        this.setState((state) => ({ data: state.data.merge({ searchStr: value }) }));
    };

    handleClickSearch = () => {
        gaEvent('adminSearchAccountsGroups');
        this.props.history.push(`${basePath}Search/${safeEncode(this.state.data.get('searchStr'))}`);
    };

    handleEnterSearch = (event) => {
        if (event.keyCode === 13) {
            this.handleClickSearch();
        }
    };

    handleTableClick = (item, columnId) => {
        if (item.link.includes('/Account/')) {
            if (columnId && columnId.includes('Accounts')) {
                gaEvent('adminAccountGroupOpenAccount');
            } else {
                gaEvent('adminOpenAccount');
            }
        } else if (item.link.includes('/AccountGroup/')) {
            gaEvent('adminOpenAccountGroup');
        } else if (item.link.includes('/EditUser/')) {
            gaEvent('adminAccountGroupOpenUser');
        }
    };

    handleTableExpandClick = (columnId) => {
        if (columnId.includes('Accounts')) gaEvent('adminAccountGroupViewAccountList');
        else if (columnId.includes('Users')) gaEvent('adminAccountGroupViewUserList');
    };

    handleClickNewAccount = () => {
        gaEvent('adminAddAccount');
        this.props.history.push(`${basePath}Account/`);
    };

    handleClickNewAccountGroup = () => {
        gaEvent('adminAddAccountGroup');
        this.props.history.push(`${basePath}AccountGroup/`);
    };

    handleCloseSnackBar = () => {
        this.setState((state) => ({ data: state.data.set('alertText', '') }));
    };

    render() {
        const { intl: { formatMessage } } = this.props;
        let allowSiloAcctMgt = true;
        if (this.props.settings) {
            allowSiloAcctMgt = this.props.settings.get('ws.system.security.allowsiloacctmgt') === 'true';
        }
        return (
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            <Searchbar
                                id="srtAccountSearchBar"
                                searchButtonLabel={formatMessage(messages.lblSearch)}
                                placeholder={formatMessage(messages.lblSearchTerm)}
                                label={formatMessage(messages.lblSearch)}
                                onChange={this.handleChangeSearch}
                                onClick={this.handleClickSearch}
                                onKeyUp={this.handleEnterSearch}
                                value={this.state.data.get('searchStr')}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div id="srtAccountSearchTable" className="col-12">
                            <TableView
                                id="srtAccountSearchTable"
                                tableHeaders={this.tableHeaders(formatMessage)}
                                data={this.state.data.get('accounts').toJS()}
                                clickHandler={this.handleTableClick}
                                expandClickHandler={this.handleTableExpandClick}
                                isProcessing={this.state.data.get('isProcessingSearch')}
                                fixedHeader
                                isSortable
                                wrapperHeight="500px"
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="row" styleName="space-fix">
                        {!allowSiloAcctMgt && (
                            <div className="col-12 col-sm-4 col-lg-3 col-xl-2">
                                <Button
                                    variant="contained"
                                    id="srtAddAccount"
                                    fullWidth
                                    color="secondary"
                                    onClick={this.handleClickNewAccount}
                                >
                                    {formatMessage(messages.lblAddAccount)}
                                </Button>
                            </div>
                        )}
                        <div className="col-12 col-sm-5 col-lg-3">
                            <Button
                                variant="contained"
                                id="srtAddAccountGroup"
                                fullWidth
                                color="secondary"
                                onClick={this.handleClickNewAccountGroup}
                            >
                                {formatMessage(messages.lblAddAccountGroup)}
                            </Button>
                        </div>
                    </div>
                </div>
                <ExtendedSnackbar
                    id="srtAccountSearchSnackBar"
                    open={(this.state.data.get('alertText') !== '')}
                    message={this.state.data.get('alertText')}
                    onClose={this.handleCloseSnackBar}
                />
            </div>
        );
    }
}

// This alias will be used to access bare component for unit testing
export { AccountSearch as AccountSearchAlias };

export default injectIntl(connect((state) => ({
    settings: state.get('settings'),
}))(AccountSearch));
