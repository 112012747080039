import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import messages from './messagesDiscountsAndLoyalties';
import styles from './styles';

const StyledDivWithCardText = styled('div')(() => ({
    ...styles.rowBottomAlign,
    ...styles.cardText,
}));

const StyledDiv = styled('div')(() => ({
    ...styles.rowBottomAlign,
}));

const StyledDivCorporateRow = styled('div')(() => ({
    ...styles.corporateRow,
}));

const DiscountsAndLoyalties = ({
    paxInfo,
    disabled,
    intl: { formatMessage },
    onChange,
    errors,
}) => {
    const aggregatedDiscounts = paxInfo.getIn(['discountsLoyalties', 'aggregatedDiscounts']);
    const loyaltyItems = [];
    paxInfo.getIn(['discountsLoyalties', 'aggregatedLoyalties']).forEach((item) => {
        if (item.get('ShoppingLegFareDiscountType') === 'LOYALTY') {
            const alreadyAdded = loyaltyItems.find((loyaltyItem) => (
                loyaltyItem.value === item.get('ShoppingLegFareDiscountProgram')
            ));

            if (!alreadyAdded) {
                loyaltyItems.push({ value: item.get('ShoppingLegFareDiscountProgram'), name: item.get('ShoppingLegFareDiscountDescription') });
            }
        }
    });

    const loyaltyList = [{ value: '', name: formatMessage(messages.lblSelectProgram) }].concat(loyaltyItems);

    const handleChange = (name, value) => {
        const updates = { [name]: value };
        onChange(updates);
    };

    const handleChangeField = (event) => {
        handleChange(event.target.name, event.target.value);
    };

    const handleChangeLoyaltyProgram = ({ target: { value } }) => {
        handleChange('loyaltyProgram', value);
    };

    return (
        <div className="col-12">
            <h5>{formatMessage(messages.lblDiscountsLoyalties)}</h5>
            {aggregatedDiscounts.map((item) => {
                const isFRMilitaire = item.get('ShoppingLegFareDiscountProgram').includes('FR_MILITAIRE');
                if (item.get('ShoppingLegFareDiscountType') === 'DISCOUNT_CARD') {
                    return (
                        <div className="row" key={item.get('ShoppingLegFareDiscountProgram')}>
                            <div className="col-12">
                                {formatMessage(messages.lblDiscountCards)}
                            </div>
                            <StyledDivWithCardText className="col-6">
                                {item.get('ShoppingLegFareDiscountDescription')}
                            </StyledDivWithCardText>
                            <StyledDiv className="col-6">
                                <TextField
                                    id="srtDiscountIdentifier"
                                    name="discountIdentifier"
                                    label={formatMessage(messages.lblDiscountCardNumber)}
                                    fullWidth
                                    disabled={isFRMilitaire || disabled}
                                    value={paxInfo.getIn(['discountsLoyalties', isFRMilitaire ? 'corporateIdentifier' : 'discountIdentifier'])}
                                    onChange={handleChangeField}
                                    error={!!errors.discountIdentifier}
                                    helperText={errors.discountIdentifier}
                                    variant="standard"
                                />
                            </StyledDiv>
                            {item.get('ShoppingLegFareDiscountIsAuthorizationRequired') && (
                                <StyledDiv className="col-6">
                                    <TextField
                                        id="srtDiscountAuthorization"
                                        name="discountAuthorization"
                                        label={formatMessage(messages.lblDiscountAuthorization)}
                                        fullWidth
                                        disabled={disabled}
                                        value={paxInfo.getIn(['discountsLoyalties', 'discountAuthorization'])}
                                        onChange={handleChangeField}
                                        error={!!errors.discountAuthorization}
                                        helperText={errors.discountAuthorization}
                                        variant="standard"
                                    />
                                </StyledDiv>
                            )}
                        </div>
                    );
                }
                if (item.get('ShoppingLegFareDiscountType') === 'CORPORATE' || item.get('ShoppingLegFareDiscountType') === 'PROMOTION') {
                    return (
                        <StyledDivCorporateRow className="row" key={item.get('ShoppingLegFareDiscountProgram')}>
                            <div className="col-12">
                                {formatMessage(messages.lblCorporateCard)}
                            </div>
                            <StyledDivWithCardText className="col-6">
                                {item.get('ShoppingLegFareDiscountDescription')}
                            </StyledDivWithCardText>
                            {(item.get('ShoppingLegFareDiscountIsIdentifierRequired')
                                || !!paxInfo.getIn(['discountsLoyalties', 'corporateIdentifier'])) && (
                                    <StyledDiv className="col-6">
                                        <TextField
                                            id="srtCorporateIdentifier"
                                            name="corporateIdentifier"
                                            label={formatMessage(messages.lblDiscountCardNumber)}
                                            fullWidth
                                            disabled={disabled || !item.get('ShoppingLegFareDiscountIsIdentifierRequired')}
                                            value={paxInfo.getIn(['discountsLoyalties', 'corporateIdentifier'])}
                                            onChange={handleChangeField}
                                            error={!!errors.corporateIdentifier}
                                            helperText={errors.corporateIdentifier}
                                            variant="standard"
                                        />
                                    </StyledDiv>
                                )}
                            {item.get('ShoppingLegFareDiscountIsAuthorizationRequired') && (
                                <StyledDiv className="col-6">
                                    <TextField
                                        id="srtCorporateAuthorization"
                                        name="corporateAuthorization"
                                        label={formatMessage(messages.lblDiscountAuthorization)}
                                        fullWidth
                                        disabled={disabled}
                                        value={paxInfo.getIn(['discountsLoyalties', 'corporateAuthorization'])}
                                        onChange={handleChangeField}
                                        error={!!errors.corporateAuthorization}
                                        helperText={errors.corporateAuthorization}
                                        variant="standard"
                                    />
                                </StyledDiv>
                            )}
                        </StyledDivCorporateRow>
                    );
                }
                return null;
            }).toJS()}
            {!!loyaltyItems.length && (
                <div className="row">
                    <div className="col-12">
                        {formatMessage(messages.lblLoyaltyCard)}
                    </div>
                    <div className="col-6">
                        <TextField
                            id="srtLoyaltyProgram"
                            disabled={disabled}
                            value={paxInfo.getIn(['discountsLoyalties', 'loyaltyProgram']) || ''}
                            onChange={handleChangeLoyaltyProgram}
                            SelectProps={{
                                autoWidth: true,
                                displayEmpty: true,
                            }}
                            select
                            variant="standard"
                        >
                            {loyaltyList.map((item) => (
                                <MenuItem
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    {paxInfo.getIn(['discountsLoyalties', 'loyaltyProgram']) && (
                        <div className="col-6">
                            <TextField
                                id="srtLoyaltyIdentifier"
                                name="loyaltyIdentifier"
                                placeholder={formatMessage(messages.lblLoyaltyCardNumber)}
                                fullWidth
                                disabled={disabled}
                                value={paxInfo.getIn(['discountsLoyalties', 'loyaltyIdentifier'])}
                                onChange={handleChangeField}
                                error={!!errors.loyaltyIdentifier}
                                helperText={errors.loyaltyIdentifier}
                                variant="standard"
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

DiscountsAndLoyalties.propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    paxInfo: PropTypes.object,
    intl: PropTypes.object,
};

// This alias will be used to access bare component for unit testing
export { DiscountsAndLoyalties as DiscountsAndLoyaltiesAlias };

export default injectIntl(DiscountsAndLoyalties);
