import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';

class TextFieldWithMenu extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        errorText: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
            PropTypes.array,
        ]),
        label: PropTypes.node,
        onChange: PropTypes.func,
        onClose: PropTypes.func,
        onSelectFirst: PropTypes.func,
        onKeyDown: PropTypes.func,
        placeholder: PropTypes.string,
        refreshing: PropTypes.bool,
        value: PropTypes.string,
    };

    static getDerivedStateFromProps(props, state) {
        let newState = state;
        if (props.children && !state.open) newState = { ...newState, open: true };
        if (!props.refreshing && state.selectFirstAfterRefresh) newState = { ...newState, selectFirstAfterRefresh: false };
        return newState;
    }

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.state = {
            open: false,
            selectFirstAfterRefresh: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectFirstAfterRefresh } = this.state;
        if (prevState.selectFirstAfterRefresh && !selectFirstAfterRefresh) this.selectFirst();
    }

    handlePopoverClose = () => {
        const { onClose } = this.props;
        this.setState({ open: false });
        if (onClose) onClose();
    };

    handleKeyDown = (event) => {
        const { onKeyDown, refreshing } = this.props;
        const { open } = this.state;
        const keyCode = keycode(event);
        if (onKeyDown) onKeyDown(keyCode, event);
        switch (keyCode) {
            case 'tab':
            case 'enter':
                if (open && !refreshing) this.selectFirst();
                else this.setState({ selectFirstAfterRefresh: true });
                break;
            case 'esc':
                if (open) this.handlePopoverClose();
                break;
            default:
                break;
        }
    };

    selectFirst() {
        const { onSelectFirst } = this.props;
        const { open } = this.state;
        if (onSelectFirst && open) onSelectFirst();
    }

    render() {
        const {
            children,
            className,
            errorText,
            label,
            onChange,
            onClose, // eslint-disable-line no-unused-vars
            onKeyDown, // eslint-disable-line no-unused-vars
            onSelectFirst, // eslint-disable-line no-unused-vars
            placeholder,
            refreshing, // eslint-disable-line no-unused-vars
            value,
            ...other
        } = this.props;
        const { open } = this.state;

        const paperPropsStyle = {
            width: this.inputRef.current && this.inputRef.current.clientWidth,
            maxHeight: this.inputRef.current
                && (window.innerHeight - this.inputRef.current.getBoundingClientRect().top - this.inputRef.current.offsetHeight - 20),
        };

        return (
            <div className={className}>
                <TextField
                    {...other}
                    inputRef={this.inputRef}
                    variant="standard"
                    autoComplete="off"
                    value={value || ''}
                    onChange={onChange}
                    label={label}
                    placeholder={placeholder}
                    fullWidth
                    multiline={false}
                    error={!!errorText}
                    helperText={errorText}
                    InputProps={{ onKeyDown: this.handleKeyDown }}
                />
                <Popover
                    open={open && !!children}
                    anchorEl={this.inputRef.current}
                    onClose={this.handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    disableAutoFocus
                    PaperProps={{
                        style: paperPropsStyle,
                    }}
                >
                    {children}
                </Popover>
            </div>
        );
    }
}

export default TextFieldWithMenu;
