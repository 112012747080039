import { defineMessages } from 'react-intl';

export default defineMessages({
    lblTitle: {
        id: 'app.booking.resendTicketDialog.title',
        defaultMessage: 'Resend Ticket',
    },
    lblTitleClaim: {
        id: 'app.booking.resendTicketDialog.titleClaim',
        defaultMessage: 'Send Ticket',
    },
    btnCancel: {
        id: 'app.booking.resendTicketDialog.cancel',
        defaultMessage: 'Cancel',
    },
    btnSend: {
        id: 'app.booking.resendTicketDialog.send',
        defaultMessage: 'Send',
    },
    errConfirmationEmail: {
        id: 'app.booking.resendTicketDialog.emailEmpty',
        defaultMessage: '^Please enter a valid Email',
    },
});
