import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import { connect } from 'react-redux';

import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Info from '@mui/icons-material/InfoOutlined';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import DicountInformation from './DiscountInformation';
import FareRulesMatrix from './FareRulesMatrix';
import FareAmenities from './FareAmenities';
import FareRules from './FareRules';
import MixedFares from './MixedFares';
import './fare.css';
import messages from './messagesFare';
import { clearExchangeValidate } from '../../../Booking/ManageBooking/actionsManageBooking';
import { formatPrice, mixedFares } from '../../../utils';
import inlineStyles from './fareStyles';
import { gaEvent } from '../../../utils/googleAnalytics';
import splitTicket from '../../../images/splitTicket.png';
import FareDisplay from './FareDisplay';

const StyledPaper = styled(Paper)(() => ({
    ...inlineStyles.fareDetails,
}));

const SPLIT_TICKET_PRICE_ID = 'VP_SF';

export const initialState = new Map({
    openFareDetails: false,
    fareDetailsAnchorEl: null,
    openFareRulesMatrix: false,
});

const Fare = ({
    fare,
    isCreateBookingPage,
    addOrderMode,
    exchangeOrderMode,
    intl,
    intl: { formatMessage },
    disabled = false,
    resetExchangeValidate,
    selected = false,
    onChangeSelection,
    onSelection,
    openFareRulesMatrix,
}) => {
    const isSplitTicketFare = fare.priceId.startsWith(SPLIT_TICKET_PRICE_ID);

    const [data, setData] = React.useState(initialState);
    const infoButton = useRef();

    useEffect(() => {
        if (openFareRulesMatrix) {
            setData((state) => state.merge({ openFareDetails: false, fareDetailsAnchorEl: null, openFareRulesMatrix: true }));
        }
    }, [openFareRulesMatrix]);

    const handleOpenFareDetails = (event) => {
        if (!disabled) {
            event.stopPropagation();
            if (addOrderMode) {
                gaEvent('addOrderViewFareInformation');
            } else if (exchangeOrderMode) {
                gaEvent('exchangeOrderViewFareInformation');
            } else if (isCreateBookingPage) {
                gaEvent('createBookingViewFareInformation');
            } else {
                gaEvent('fareSearchViewFareInformation');
            }
            setData((state) => state.merge({ openFareDetails: true, fareDetailsAnchorEl: infoButton.current }));
        }
    };

    const handleOpenFareRulesMatrix = () => {
        if (addOrderMode) {
            gaEvent('addOrderViewFareRules');
        } else if (exchangeOrderMode) {
            gaEvent('exchangeOrderViewFareRules');
        } else if (isCreateBookingPage) {
            gaEvent('createBookingViewFareRules');
        } else {
            gaEvent('fareSearchViewFareRules');
        }
        setData((state) => state.merge({ openFareDetails: false, fareDetailsAnchorEl: null, openFareRulesMatrix: true }));
    };

    const handleCloseFareRulesMatrix = () => {
        if (addOrderMode) {
            gaEvent('addOrderCloseFareRules');
        } else if (exchangeOrderMode) {
            gaEvent('exchangeOrderCloseFareRules');
        } else if (isCreateBookingPage) {
            gaEvent('createBookingCloseFareRules');
        } else {
            gaEvent('fareSearchCloseFareRules');
        }
        setData((state) => state.set('openFareRulesMatrix', false));
    };

    const handleCloseFareDetails = () => {
        setData((state) => state.merge({ openFareDetails: false, fareDetailsAnchorEl: null }));
    };

    const handleClickSelection = () => {
        if (!disabled) {
            resetExchangeValidate();
            setData((state) => state.merge({ fareDetailsAnchorEl: null }));
            if (selected) {
                onChangeSelection(fare);
            } else {
                onSelection(fare);
            }
        }
    };

    const renderIconButton = () => {
        let buttonState = '';
        if (disabled) {
            buttonState = 'iconButtonDisabled';
        } else if (data.get('openFareDetails')) {
            buttonState = 'iconButtonActive';
        } else {
            buttonState = 'iconButton';
        }

        const buttonClass = `info_button ${buttonState}`;
        return (
            <div styleName="info">
                <IconButton
                    className="infoButton" // infoButton is used for automation/test purpose
                    onClick={handleOpenFareDetails}
                    ref={infoButton}
                    disabled={disabled}
                    styleName={buttonClass}
                >
                    <Info />
                </IconButton>
            </div>
        );
    };

    const showSeatsWarning = !selected && (fare.seatsAvailable || fare.isOverbooked);
    const campaignPrice = (!fare?.campaignInfo?.message && fare?.campaignInfo?.success) ? fare?.campaignInfo?.value?.value : '';
    const formattedPrice = formatPrice((campaignPrice !== '') ? campaignPrice : fare.price, fare.currency, intl);
    const farePriceSmall = (formattedPrice.length >= 9 && selected) ? ' priceSmall' : '';

    const mixedFaresObj = mixedFares(fare.fareCodes);
    let { fareTypeClass, primaryColumnClass, scheduleClass } = fare.fareCodes[0];
    const { expirationDate } = fare.fareCodes[0];

    const seasonAttribute = expirationDate ? formatMessage(messages.lblEnds, { expirationDate }) : null;

    const multipleText = formatMessage(messages.lblMultiple);
    if (!mixedFaresObj.sameFareTypeClass) {
        fareTypeClass = multipleText;
    }
    if (!mixedFaresObj.samePrimaryColumnClass) {
        primaryColumnClass = multipleText;
    }
    if (!mixedFaresObj.sameScheduleClass) {
        scheduleClass = multipleText;
    }
    const refundableIndicator = fare?.fareRulesDetails?.some(
        (fareRulesDetail) => fareRulesDetail?.fareMatrix?.some((fareMat) => fareMat?.refundableIndicator),
    );

    return (
        <div id={`Fare_${fare.priceId}`} styleName="fareContainer">

            <FareDisplay
                disabled={disabled}
                selected={selected}
                isSplitTicketFare={isSplitTicketFare}
                showSeatsWarning={showSeatsWarning}
                fare={fare}
                formattedPrice={formattedPrice}
                farePriceSmall={farePriceSmall}
                fareTypeClass={fareTypeClass}
                primaryColumnClass={primaryColumnClass}
                scheduleClass={scheduleClass}
                seasonAttribute={seasonAttribute}
                refundableIndicator={refundableIndicator}
                messages={messages}
                splitTicket={splitTicket}
                handleClickSelection={handleClickSelection}
                formatMessage={formatMessage}
                renderIconButton={renderIconButton}
            />
            {selected && !isCreateBookingPage && (
                <Button
                    variant="text"
                    color="secondary"
                    style={inlineStyles.changeSelectionButton}
                    styleName="fareButton"
                    onClick={handleClickSelection}
                    disableRipple
                    className="Fare_ChangeSelection"
                >
                    {formatMessage(messages.btnChangeSelection)}
                </Button>
            )}
            <Popper
                open={data.get('openFareDetails')}
                anchorEl={data.get('fareDetailsAnchorEl')}
                style={inlineStyles.popper}
            >

                <ClickAwayListener onClickAway={handleCloseFareDetails} mouseEvent="onMouseDown">
                    <StyledPaper>
                        <div id={`FareDetails_${fare.priceId}`} styleName="fareDetails">
                            <div>
                                <DicountInformation
                                    fare={fare}
                                    discountHeaderLabel={formatMessage(messages.lblDiscountedFare)}
                                    discountCardLabel={formatMessage(messages.lblDiscountCard)}
                                    promoCodeLabel={formatMessage(messages.lblPromoCode)}
                                    validationCodeLabel={formatMessage(messages.lblValidationCode)}
                                />
                                {
                                    !mixedFaresObj.isMixedFares
                                    || fare.fareCodes.map((item, index) => (
                                        <MixedFares
                                            key={index}
                                            index={index}
                                            currency={fare.currency}
                                            fareCode={item}
                                            mixedFaresObj={mixedFaresObj}
                                            intl={intl}
                                        />
                                    ))
                                }
                                <FareAmenities fareAmenities={fare.amenities} />
                                <div>
                                    <div className="fareRulesText">
                                        {fare.price === 0
                                            ? <FormattedMessage {...messages.lblFareReturnInboundRulesMessage} />
                                            : <FareRules rules={fare.rules} />}
                                    </div>
                                </div>
                                {
                                    fare.fareRulesDetails?.length > 0 && (
                                        <Button
                                            variant="text"
                                            color="secondary"
                                            style={inlineStyles.fareDetailsButton}
                                            styleName="fareButton"
                                            onClick={handleOpenFareRulesMatrix}
                                            disableRipple
                                        >
                                            {formatMessage(messages.btnViewFareDetails)}
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                    </StyledPaper>
                </ClickAwayListener>
            </Popper>
            <FareRulesMatrix
                open={data.get('openFareRulesMatrix')}
                handleClose={handleCloseFareRulesMatrix}
                fareRulesDetails={fare.fareRulesDetails}
                paxTitle={formatMessage(messages.lblFare)}
                isSeason={fareTypeClass === 'Season'}
                isCreateBookingPage={isCreateBookingPage}
                addOrderMode={addOrderMode}
                exchangeOrderMode={exchangeOrderMode}
            />
        </div>
    );
};

Fare.propTypes = {
    intl: PropTypes.object,
    resetExchangeValidate: PropTypes.func,
    fare: PropTypes.shape({
        priceId: PropTypes.string,
        currency: PropTypes.string,
        amenities: PropTypes.arrayOf(PropTypes.shape({
            code: PropTypes.string.isRequired,
            desc: PropTypes.string.isRequired,
        })),
        fareCodes: PropTypes.arrayOf(PropTypes.shape({
            farePrice: PropTypes.number,
            fareClass: PropTypes.string,
            fareClassDesc: PropTypes.string,
            cabinClass: PropTypes.string,
            serviceClass: PropTypes.string,
            primaryColumnClass: PropTypes.string,
            openReturn: PropTypes.bool,
            reservableCode: PropTypes.string,
            reservableDesc: PropTypes.string,
            applicabilityCode: PropTypes.string,
            applicabilityDesc: PropTypes.string,
            scheduleClass: PropTypes.string,
            fareTypeClass: PropTypes.string,
            expirationDate: PropTypes.string,
        })),
        price: PropTypes.number,
        seatsAvailable: PropTypes.string,
        linkedReturnPrices: PropTypes.array,
        rules: PropTypes.array,
        discountApplied: PropTypes.bool,
        campaignInfo: PropTypes.object,
        discountDescription: PropTypes.string,
        fareRulesDetails: PropTypes.arrayOf(PropTypes.shape(
            {
                paxRefs: PropTypes.string,
                fareClasses: PropTypes.string,
                displayName: PropTypes.string,
                originTerminals: PropTypes.string,
                destinationTerminals: PropTypes.string,
                fareMatrix: PropTypes.arrayOf(PropTypes.shape({
                    isTextRule: PropTypes.bool,
                    priceType: PropTypes.string,
                    applicableTicketingOption: PropTypes.string,
                    expirationDateTime: PropTypes.string,
                    refundAllowedConfirmed: PropTypes.string,
                    refundAllowedConfirmedPenalty: PropTypes.string,
                    refundAllowedTicketed: PropTypes.string,
                    refundableIndicator: PropTypes.bool,
                })),
            },
        )),
        isOverbooked: PropTypes.bool,
    }).isRequired,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    isCreateBookingPage: PropTypes.bool,
    marketingCarrier: PropTypes.shape({
        name: PropTypes.string.isRequired,
        supplier: PropTypes.string.isRequired,
    }).isRequired,
    onChangeSelection: PropTypes.func.isRequired,
    onSelection: PropTypes.func.isRequired,
    onOpenFareDetails: PropTypes.func.isRequired,
    openFareRulesMatrix: PropTypes.bool,
    addOrderMode: PropTypes.bool,
    exchangeOrderMode: PropTypes.bool,
};

const mapDispatchToProps = {
    resetExchangeValidate: clearExchangeValidate,
};

export { Fare as FareAlias };
export default connect(null, mapDispatchToProps)(injectIntl(Fare));
