export default {
    refreshIndicator: {
        zIndex: 2000,
        marginTop: '10%',
        marginLeft: '48%',
    },
    dialogActionsRoot: {
        width: '100%',
    },
    dialog: {
        maxWidth: 700,
    },
};
