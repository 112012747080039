export default {
    refreshIndicator: {
        zIndex: 2000,
        marginTop: '10%',
        marginLeft: '48%',
    },
    dialogPaper: {
        overflowY: 'visible',
        maxWidth: 768,
    },
    dialogActionsRoot: {
        width: '100%',
    },
    textBold: {
        fontWeight: 500,
    },
    fixHeight: {
        height: 72,
        lineHeight: '72px',
    },
    textRight: {
        textAlign: 'right',
    },
    labelHighlighted: {
        '& label': {
            fontWeight: 700,
            color: '#777!important',
        },
    },
};
