import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.shopping.createBooking.title',
        defaultMessage: 'Create Booking',
    },
    lblChangeJourney: {
        id: 'app.shopping.createBooking.lblChangeJourney',
        defaultMessage: 'Change Journey',
    },
    lblCreateBooking: {
        id: 'app.shopping.createBooking.lblCreateBooking',
        defaultMessage: 'Create Booking',
    },
    lblAgeAdult: {
        id: 'app.shopping.createBooking.lblAgeAdult',
        defaultMessage: 'Adult Age group 26-54',
    },
    lblAge: {
        id: 'app.shopping.createBooking.lblAge',
        defaultMessage: 'Age',
    },
    lblAddress: {
        id: 'app.shopping.createBooking.lblAddress',
        defaultMessage: 'Address',
    },
    lblUnknownContext: {
        id: 'app.shopping.createBooking.unknownContext',
        defaultMessage: 'Unknown Context',
    },
    lblTDONone: {
        id: 'app.shopping.createBooking.tdo.none',
        defaultMessage: 'None entered',
    },
    errCustomInfoType: {
        id: 'app.shopping.createBooking.customInfoTypeEmpty',
        defaultMessage: 'Type can\'t be blank',
    },
    errCustomInfoValue: {
        id: 'app.shopping.createBooking.customInfoValueEmpty',
        defaultMessage: 'Value can\'t be blank when required is checked',
    },
    errEmptyTitle: {
        id: 'app.shopping.createBooking.emptyTitle',
        defaultMessage: '^Please enter a title',
    },
    errInvalidTitle: {
        id: 'app.shopping.createBooking.invalidTitle',
        defaultMessage: '^Please enter a valid title',
    },
    errEmptyFirstName: {
        id: 'app.shopping.createBooking.emptyFirstName',
        defaultMessage: '^Please enter a passenger first name',
    },
    errInvalidFirstName: {
        id: 'app.shopping.createBooking.invalidFirstName',
        defaultMessage: '^Please enter a valid passenger first name',
    },
    errEmptyLastName: {
        id: 'app.shopping.createBooking.emptyLastName',
        defaultMessage: '^Please enter a passenger last name',
    },
    errInvalidLastName: {
        id: 'app.shopping.createBooking.invalidLastName',
        defaultMessage: '^Please enter a valid passenger last name',
    },
    errLongFirstLastName: {
        id: 'app.shopping.createBooking.longFirstLastName',
        defaultMessage: '^Combined passenger name is too long. Must be less than 50 characters long.',
    },
    errEmptyPhoneNumber: {
        id: 'app.shopping.createBooking.emptyPhoneNumber',
        defaultMessage: '^Please enter a passenger phone number',
    },
    errInvalidPhoneNumber: {
        id: 'app.shopping.createBooking.invalidPhoneNumber',
        defaultMessage: '^Please enter a valid passenger phone number',
    },
    errEmptyEmail: {
        id: 'app.shopping.createBooking.emptyEmail',
        defaultMessage: '^Please enter an email address',
    },
    errInvalidEmail: {
        id: 'app.shopping.createBooking.invalidEmail',
        defaultMessage: '^Please enter a properly formatted email address (for example: anyuser@anycompany.com)',
    },
    errCountryEmpty: {
        id: 'app.shopping.createBooking.countryEmpty',
        defaultMessage: '^Please select a Country',
    },
    errStateEmpty: {
        id: 'app.shopping.createBooking.stateEmpty',
        defaultMessage: '^Please select a State',
    },
    errProvinceEmpty: {
        id: 'app.shopping.createBooking.provinceEmpty',
        defaultMessage: '^Please select a Province',
    },
    errAddressLine1Empty: {
        id: 'app.shopping.createBooking.addressEmpty',
        defaultMessage: '^Please enter an Address',
    },
    errCityEmpty: {
        id: 'app.shopping.createBooking.cityEmpty',
        defaultMessage: '^Please enter a City',
    },
    errPostalCodeEmpty: {
        id: 'app.shopping.createBooking.postalCodeEmpty',
        defaultMessage: '^Please enter a Postal Code',
    },
    errDOBEmpty: {
        id: 'app.shopping.createBooking.travelDocs.errDOBEmpty',
        defaultMessage: '^Please enter Date of Birth',
    },
    errDOBInvalid: {
        id: 'app.shopping.createBooking.travelDocs.errDOBInvalid',
        defaultMessage: '^Please enter valid Date of Birth',
    },
    errDocTypeEmpty: {
        id: 'app.shopping.createBooking.travelDocs.errDocTypeEmpty',
        defaultMessage: '^Please select Travel Document Type',
    },
    errDocNumberEmpty: {
        id: 'app.shopping.createBooking.travelDocs.errDocNumberEmpty',
        defaultMessage: '^Please enter Document Number',
    },
    errIssueCountryEmpty: {
        id: 'app.shopping.createBooking.travelDocs.errIssueCountryEmpty',
        defaultMessage: '^Please select an issuing country',
    },
    errDocExpDateEmpty: {
        id: 'app.shopping.createBooking.travelDocs.errDocExpDateEmpty',
        defaultMessage: '^Please enter Document Expiration Date',
    },
    errDocExpDateInvalid: {
        id: 'app.shopping.createBooking.travelDocs.errDocExpDateInvalid',
        defaultMessage: '^Please enter valid Document Expiration Date',
    },
    errPostalCodeInvalid: {
        id: 'app.shopping.createBooking.travelDocs.postalCodeInvalid',
        defaultMessage: '^Please enter a valid Postal Code',
    },
    errGeneralText: {
        id: 'app.shopping.createBooking.errorGeneralText',
        defaultMessage: 'There are some validation errors. Check the form.',
    },
    errDiscountIdentifierEmpty: {
        id: 'app.shopping.createBooking.discountsLoyalties.discountIdentifierEmpty',
        defaultMessage: '^Please enter a Discount Card Number',
    },
    errDiscountIdentifierShort: {
        id: 'app.shopping.createBooking.discountsLoyalties.discountIdentifierShort',
        defaultMessage: '^An AAA Discount Identifier must be at least 4 characters long',
    },
    errDiscountAuthorizationEmpty: {
        id: 'app.shopping.createBooking.discountsLoyalties.discountAuthorizationEmpty',
        defaultMessage: '^Please enter an Authorization Number',
    },
    errFalseTanConfirmIdentifier: {
        id: 'app.shopping.createBooking.discountsLoyalties.falseTanConfirmIdentifier',
        defaultMessage: '^Invalid VIA Rail Government TAN1 Authorization Number. Please check and re-enter',
    },
    errLoyaltyIdentifierEmpty: {
        id: 'app.shopping.createBooking.discountsLoyalties.loyaltyIdentifierEmpty',
        defaultMessage: '^Please enter a Loyalty Card Identifier',
    },
    errLoyaltyIdentifierOnlyNumber: {
        id: 'app.shopping.createBooking.discountsLoyalties.loyaltyIdentifierOnlyNumber',
        defaultMessage: '^A Loyalty Card Identifier can only be numeric.',
    },
    errCustomInfoTooLong: {
        id: 'app.shopping.createBooking.customInfoTooLong',
        defaultMessage: '^Reference Number, PO Number and Customer Number fields must be 25 characters or less.',
    },
    errCustomInfoTypeValueTooLong: {
        id: 'app.shopping.createBooking.customInfo.customInfoTypeValueTooLong',
        defaultMessage: 'Custom Information type and value fields must be less than 256 characters long.',
    },
    errCustomInfoTypeDuplicate: {
        id: 'app.shopping.createBooking.customInfo.customInfoTypeDuplicate',
        defaultMessage: 'Type {errorType} is already contained on this booking. Please enter a new Type.',
    },
    errTdoShouldBeSelected: {
        id: 'app.shopping.createBooking.ticketDelivery.tdoShouldBeSelected',
        defaultMessage: '^Ticked delivery option should be selected.',
    },
    lblSeason: {
        id: 'app.shopping.season',
        defaultMessage: 'Season Pass',
    },
    lblAmtrakTandC: {
        id: 'app.shopping.amtrakTandC',
        defaultMessage: 'By clicking "CREATE BOOKING", I have read and agreed to {TermsAndConditionsLink}, including the binding arbitration agreement.',
    },
    lblTermsAndConditions: {
        id: 'app.shopping.termsAndConditions',
        defaultMessage: "Amtrak's Terms and Conditions",
    },
});
