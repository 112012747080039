import { defineMessages } from 'react-intl';

export default defineMessages({
    lblCancel: {
        id: 'app.admin.manageAccount.cancel',
        defaultMessage: 'Cancel',
    },
    lblSave: {
        id: 'app.admin.manageAccount.save',
        defaultMessage: 'Save',
    },
    lblSearch: {
        id: 'app.admin.manageAccount.search',
        defaultMessage: 'Search',
    },
    lblNoneText: {
        id: 'app.admin.manageAccount.noneText',
        defaultMessage: 'None',
    },
    lblCheapestOnly: {
        id: 'app.admin.manageAccount.cheapestOnly',
        defaultMessage: 'Cheapest Only',
    },
    lblCheapestTravelCards: {
        id: 'app.admin.manageAccount.cheapestTravelCard',
        defaultMessage: 'Cheapest + TravelCards',
    },
    lblCheapestOneWayRoundTrip: {
        id: 'app.admin.manageAccount.cheapestOneWayRoundTrip',
        defaultMessage: 'Cheapest OneWay/Roundtrip ',
    },
    lblCheapestOneWayRoundTripTravelCard: {
        id: 'app.admin.manageAccount.cheapestOneWayRoundTripTravelCard',
        defaultMessage: 'Cheapest OneWay/Roundtrip + TravelCards',
    },
    lblNull: {
        id: 'app.admin.manageAccount.null',
        defaultMessage: 'Default to Supply Channel Setting',
    },
    lblopenFaresOnly: {
        id: 'app.admin.manageAccount.openFaresOnly',
        defaultMessage: 'Open Fares Only',
    },
    lblCheapestOnlyOpen: {
        id: 'app.admin.manageAccount.CheapestOnlyOpen',
        defaultMessage: 'Cheapest Open',
    },
    lblGBFares: {
        id: 'app.admin.manageAccount.GBFares',
        defaultMessage: 'UK Market Fares',
    },
    lblFareFilterTitle: {
        id: 'app.admin.manageAccount.fareFilterTitle',
        defaultMessage: 'Fare Filter',
    },
    lblCheapestOneWayCorporateFares: {
        id: 'app.admin.manageAccount.cheapestCorporateFares',
        defaultMessage: 'Cheapest OneWay/Roundtrip + TravelCards + Corporate Fares',
    },
    lblSearchTerm: {
        id: 'app.admin.manageAccount.searchTerm',
        defaultMessage: 'Enter Search',
    },
    lblFareFilter: {
        id: 'app.admin.manageAccount.fareFilter',
        defaultMessage: 'Fare Search Settings:',
    },
    lblAuthorizeRefundSettings: {
        id: 'app.admin.manageAccount.authorizeRefundSettings',
        defaultMessage: 'Authorize Refund Settings:',
    },
    errSearch: {
        id: 'app.admin.manageAccount.searchError',
        defaultMessage: '^There was problem getting the account\'s list. Please try again!',
    },
    searchNoResults: {
        id: 'app.admin.manageAccount.searchNoResults',
        defaultMessage: 'Unable to find a matching Account(s) - please check your search criteria and try again.',
    },
    lblAddAccount: {
        id: 'app.admin.manageAccount.addAccount',
        defaultMessage: 'Add Account',
    },
    lblAddAccountGroup: {
        id: 'app.admin.manageAccount.addAccountGroup',
        defaultMessage: 'Add Account Group',
    },
    lblGroupName: {
        id: 'app.admin.manageAccount.groupName',
        defaultMessage: 'Account Group Name',
    },
    lblSeatmapApiKey: {
        id: 'app.admin.manageAccount.seatmapApiKey',
        defaultMessage: 'Seatmap API Key',
    },
    lblSeatmapDetails: {
        id: 'app.admin.manageAccount.seatmapDetails',
        defaultMessage: 'Seatmap Details',
    },
    errGroupName: {
        id: 'app.admin.manageAccount.errGroupName',
        defaultMessage: '^Please enter an Account Group Name',
    },
    errPartnerName: {
        id: 'app.admin.manageAccount.errPartnerName',
        defaultMessage: '^Please enter a Partner Name',
    },
    errOnAccountCardName: {
        id: 'app.admin.manageAccount.errOnAccountCardName',
        defaultMessage: '^Please enter a Card Name for On Account Payment',
    },
    errOnAccountCardNumber: {
        id: 'app.admin.manageAccount.errOnAccountCardNumber',
        defaultMessage: '^Please enter a Card Number for On Account Payment',
    },
    lblAssocUsers: {
        id: 'app.admin.manageAccount.assocUsers',
        defaultMessage: 'Associated Users:',
    },
    lblAssocUser: {
        id: 'app.admin.manageAccount.assocUser',
        defaultMessage: 'Associate User',
    },
    lblAddAssocUser: {
        id: 'app.admin.manageAccount.addAssocUser',
        defaultMessage: 'Add user',
    },
    lblAssocAccounts: {
        id: 'app.admin.manageAccount.assocAccounts',
        defaultMessage: 'Associated Accounts:',
    },
    lblAssocAccount: {
        id: 'app.admin.manageAccount.assocAccount',
        defaultMessage: 'Associate Account',
    },
    lblAddAssocAccount: {
        id: 'app.admin.manageAccount.addAssocAccount',
        defaultMessage: 'Add Account',
    },
    lblDeleteAccountGroup: {
        id: 'app.admin.manageAccount.deleteGroup',
        defaultMessage: 'Delete account Group',
    },
    lblGroupAssociatedOnAccountDetails: {
        id: 'app.admin.manageAccount.groupAssociatedOnAccountDetails',
        defaultMessage: 'Associated On Account Details:',
    },
    lblGroupOnAccountName: {
        id: 'app.admin.manageAccount.groupOnAccountName',
        defaultMessage: 'Name on card',
    },
    lblGroupOnAccountCard: {
        id: 'app.admin.manageAccount.groupOnAccountCard',
        defaultMessage: 'Card Number',
    },
    lblGroupOnAccountSecurityCode: {
        id: 'app.admin.manageAccount.groupOnAccountSecurityCode',
        defaultMessage: 'Security Code',
    },
    groupCreated: {
        id: 'app.admin.manageAccount.groupCreated',
        defaultMessage: 'Account Group created.',
    },
    groupDeleted: {
        id: 'app.admin.manageAccount.groupDeleted',
        defaultMessage: 'Account Group deleted.',
    },
    groupSaved: {
        id: 'app.admin.manageAccount.groupSaved',
        defaultMessage: 'Account Group saved.',
    },
    accountCreated: {
        id: 'app.admin.manageAccount.accountCreated',
        defaultMessage: 'Account created.',
    },
    accountDeleted: {
        id: 'app.admin.manageAccount.accountDeleted',
        defaultMessage: 'Account deleted.',
    },
    accountSaved: {
        id: 'app.admin.manageAccount.accountSave',
        defaultMessage: 'Account saved.',
    },
    lblCustomInfoHeader: {
        id: 'app.admin.manageAccount.customInfoHeader',
        defaultMessage: 'Custom Information',
    },
    lblRemoveCustomInfo: {
        id: 'app.admin.manageAccount.removeCustomInfo',
        defaultMessage: 'Remove Custom Info',
    },
    lblFeesHeader: {
        id: 'app.admin.manageAccount.feesHeader',
        defaultMessage: 'Fees',
    },
    lblRemoveFees: {
        id: 'app.admin.manageAccount.removeFees',
        defaultMessage: 'Remove Fees',
    },
    lblGeneralHeader: {
        id: 'app.admin.manageAccount.generalHeader',
        defaultMessage: 'General Information',
    },
    lblContactHeader: {
        id: 'app.admin.manageAccount.contactHeader',
        defaultMessage: 'Contact Information',
    },
    lblFareSearchAllowed: {
        id: 'app.admin.manageAccount.fareSearchAllowed',
        defaultMessage: 'Allow Fare Search',
    },
    lblOptionsHeader: {
        id: 'app.admin.manageAccount.optionsHeader',
        defaultMessage: 'Additional Options',
    },
    lblAddCustomInfo: {
        id: 'app.admin.manageAccount.addCustomInfo',
        defaultMessage: 'Add Custom Information',
    },
    lblAddFees: {
        id: 'app.admin.manageAccount.addFees',
        defaultMessage: 'Add Fees',
    },
    lblDeleteAccount: {
        id: 'app.admin.manageAccount.deleteAccount',
        defaultMessage: 'Delete Account',
    },
    // Fees
    lblFeeType: {
        id: 'app.admin.manageAccount.feeType',
        defaultMessage: 'Type',
    },
    lblFeeValue: {
        id: 'app.admin.manageAccount.feeValue',
        defaultMessage: 'Value',
    },
    lblFeeMinValue: {
        id: 'app.admin.manageAccount.feeMinValue',
        defaultMessage: 'Min Value',
    },
    // Account table
    lblTableAccountType: {
        id: 'app.admin.manageAccount.tableAccountType',
        defaultMessage: 'Type',
    },
    lblTableAccountName: {
        id: 'app.admin.manageAccount.tableAccountName',
        defaultMessage: 'Name',
    },
    lblTableAssocAccount: {
        id: 'app.admin.manageAccount.tableAssocAccount',
        defaultMessage: 'Associated Accounts',
    },
    lblTableAssocUsers: {
        id: 'app.admin.manageAccount.tableAssocUsers',
        defaultMessage: 'Associated Users',
    },
    lblName: {
        id: 'app.admin.manageAccount.name',
        defaultMessage: 'Account Name',
    },
    errNameEmpty: {
        id: 'app.admin.manageAccount.nameEmpty',
        defaultMessage: '^Please enter an Account Name',
    },
    errNameInvalid: {
        id: 'app.admin.manageAccount.nameInvalid',
        defaultMessage: '^Please enter a valid Account Name (alphanumeric and special characters .@:-_ are allowed)',
    },
    lblContext: {
        id: 'app.admin.manageAccount.context',
        defaultMessage: 'Context (Distributor:Channel:POS[:Agency[:Company]])',
    },
    errContextEmpty: {
        id: 'app.admin.manageAccount.contextEmpty',
        defaultMessage: '^Please enter an Account Context',
    },
    errContextInvalid: {
        id: 'app.admin.manageAccount.contextInvalid',
        defaultMessage: '^Please enter an Account Context with format "Distributor:Channel:POS[:Agency[:Company]]"',
    },
    lblGroup: {
        id: 'app.admin.manageAccount.group',
        defaultMessage: 'group',
    },
    lblAccount: {
        id: 'app.admin.manageAccount.account',
        defaultMessage: 'account',
    },
    lblExternalPaymentProviderDetails: {
        id: 'app.admin.manageAccount.externalPaymentProviderDetails',
        defaultMessage: 'External Payment Provider Details:',
    },
    lblExternalPaymentMerchantAccountId: {
        id: 'app.admin.manageAccount.externalPaymentMerchantAccountId',
        defaultMessage: 'Merchant Account ID',
    },
    lblExternalPaymentAuthorization: {
        id: 'app.admin.manageAccount.externalPaymentAuthorization',
        defaultMessage: 'Authorization',
    },
    lblExternalPaymentDynamicDescriptorName: {
        id: 'app.admin.manageAccount.externalPaymentDynamicDescriptorName',
        defaultMessage: 'Name Dynamic Descriptor for Payment',
    },
    lblExternalPaymentDynamicDescriptorNameHelp: {
        id: 'app.admin.manageAccount.externalPaymentDynamicDescriptorNameHelp',
        defaultMessage: 'The value in the business name field of a customer\'s statement. The company name/DBA section must be exactly 3,7 or 12 characters long, and the product section can be up to 18, 14 or 9 characters respectively (with an * between sections, for a total name descriptor of 22 characters).',
    },
    lblExternalPaymentDynamicDescriptorPhoneNumber: {
        id: 'app.admin.manageAccount.externalPaymentDynamicDescriptorPhoneNumber',
        defaultMessage: 'Phone Number Dynamic Descriptor for Payment',
    },
    lblExternalPaymentDynamicDescriptorPhoneNumberHelp: {
        id: 'app.admin.manageAccount.externalPaymentDynamicDescriptorPhoneNumberHelp',
        defaultMessage: 'The value in the phone number field of a customer\'s statement. The phone number must be between 10 and 14 characters, and contain only numbers.',
    },
    lblAuthorizeRefundDescription: {
        id: 'app.admin.manageAccount.authorizationDescription',
        defaultMessage: 'Default Authorization Description',
    },
    lblAuthorizeRefundCode: {
        id: 'app.admin.manageAccount.authorizationCode',
        defaultMessage: 'Default Authorization Code',
    },
    lblGroupBookingSyncDetails: {
        id: 'app.admin.manageAccount.bookingSyncDetails',
        defaultMessage: 'Booking Sync Details:',
    },
    lblGroupBookingSyncAllowed: {
        id: 'app.admin.manageAccount.bookingSyncAllowed',
        defaultMessage: 'Booking Sync allowed',
    },
    lblGroupSyncUrl: {
        id: 'app.admin.manageAccount.syncUrl',
        defaultMessage: 'Sync Url',
    },
    lblGroupPartnerName: {
        id: 'app.admin.manageAccount.accGroupPartnerName',
        defaultMessage: 'Partner Name',
    },
    lblGroupPermissions: {
        id: 'app.admin.manageAccount.permissions',
        defaultMessage: 'Permissions:',
    },
    lblGroupAddOrderAllowed: {
        id: 'app.admin.manageAccount.addOrderAllowed',
        defaultMessage: 'Allow "Add Order"',
    },
    lblSplitTicketingDetails: {
        id: 'app.admin.manageAccount.splitTicketingDetails',
        defaultMessage: 'Split Ticketing Details',
    },
    lblNumOfSplitsTitle: {
        id: 'app.admin.manageAccount.numOfSplitsTitle',
        defaultMessage: 'Max number of splits',
    },
    lblSplitTicketingAllowed: {
        id: 'app.admin.manageAccount.splitTicketingAllowed',
        defaultMessage: 'Split ticketing allowed',
    },
    lblSummatedFaresTitle: {
        id: 'app.admin.manageAccount.summatedFaresTitle',
        defaultMessage: 'Max number of summated fares',
    },
    lblSplitAtTitle: {
        id: 'app.admin.manageAccount.splitAtTitle',
        defaultMessage: 'Split at',
    },
    lblAnyCallingStations: {
        id: 'app.admin.manageAccount.anyCallingStations',
        defaultMessage: 'Any calling stations of the journey',
    },
    lblAnyInterchangeStations: {
        id: 'app.admin.manageAccount.anyInterchangeStations',
        defaultMessage: 'Any interchange stations of the journey',
    },
    lblAddSummatedFareFilter: {
        id: 'app.admin.manageAccount.summatedFareFiltersTitle',
        defaultMessage: 'Add Summated Fare Filter',
    },
    lblFareCategoryTitle: {
        id: 'app.admin.manageAccount.fareCategoryTitle',
        defaultMessage: 'Fare category',
    },
    lblAll: {
        id: 'app.admin.manageAccount.all',
        defaultMessage: 'All',
    },
    lblWalkOn: {
        id: 'app.admin.manageAccount.walkOn',
        defaultMessage: 'WalkOn',
    },
    lblAdvance: {
        id: 'app.admin.manageAccount.advance',
        defaultMessage: 'Advance',
    },
    lblStandard: {
        id: 'app.admin.manageAccount.standard',
        defaultMessage: 'Standard',
    },
    lblFirst: {
        id: 'app.admin.manageAccount.first',
        defaultMessage: 'First',
    },
    lblFareClassTitle: {
        id: 'app.admin.manageAccount.fareClassTitle',
        defaultMessage: 'Fare class',
    },
    lblExcludeReturnFaresTitle: {
        id: 'app.admin.manageAccount.excludeReturnFaresTitle',
        defaultMessage: 'exclude return fares',
    },
    lblFilterName: {
        id: 'app.admin.manageAccount.summatedFareFilterName',
        defaultMessage: 'Filter Name',
    },
    // errors
    errCountryEmpty: {
        id: 'app.admin.manageAccount.countryEmpty',
        defaultMessage: '^Please select a Country',
    },
    errStateEmpty: {
        id: 'app.admin.manageAccount.stateEmpty',
        defaultMessage: '^Please select a State',
    },
    errProvinceEmpty: {
        id: 'app.admin.manageAccount.provinceEmpty',
        defaultMessage: '^Please select a Province',
    },
    errAddressLine1Empty: {
        id: 'app.admin.manageAccount.addressEmpty',
        defaultMessage: '^Please enter an Address',
    },
    errCityEmpty: {
        id: 'app.admin.manageAccount.cityEmpty',
        defaultMessage: '^Please enter a City',
    },
    errPostalCodeEmpty: {
        id: 'app.admin.manageAccount.postalCodeEmpty',
        defaultMessage: '^Please enter a Postal Code',
    },
    errPostalCodeInvalid: {
        id: 'app.admin.manageAccount.postalCodeInvalid',
        defaultMessage: '^Please enter a valid Postal Code',
    },
    // errors contact
    errPhoneInvalid: {
        id: 'app.admin.manageAccount.phoneInvalid',
        defaultMessage: '^Please enter a valid Phone number',
    },
    errEmailInvalid: {
        id: 'app.admin.manageAccount.emailInvalid',
        defaultMessage: '^Please enter a valid Email',
    },
    lblCorrectErrors: {
        id: 'app.admin.manageAccount.correctErrors',
        defaultMessage: 'Please correct the errors above',
    },
    errCustomInfoType: {
        id: 'app.admin.manageAccount.customInfoTypeEmpty',
        defaultMessage: 'Type can\'t be blank',
    },
    errPercentageFeeValue: {
        id: 'app.admin.manageAccount.PercentageFeeValueInvalid',
        defaultMessage: 'Percentage value must be in range from 0.01 to 99.99',
    },
    errCustomInfoTypeValueTooLong: {
        id: 'app.admin.manageAccount.customInfo.customInfoTypeValueTooLong',
        defaultMessage: 'Custom Information type and value fields must be less than 256 characters long.',
    },
    errCustomInfoTypeDuplicate: {
        id: 'app.admin.manageAccount.customInfo.customInfoTypeDuplicate',
        defaultMessage: 'Type {errorType} is already contained on this booking. Please enter a new Type.',
    },
    // errors booking sync
    errSyncUrlInvalid: {
        id: 'app.admin.manageAccount.syncUrlInvalid',
        defaultMessage: '^Please enter a valid Sync Url',
    },
});
