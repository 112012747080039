import { defineMessages } from 'react-intl';

export default defineMessages({
    lblTDO: {
        id: 'app.shopping.ticketDelivery.tdo',
        defaultMessage: 'Ticket Delivery',
    },
    lblSelectedTDO: {
        id: 'app.shopping.ticketDelivery.tdo.selected',
        defaultMessage: 'Selected Ticket Delivery',
    },
    lblTDOPrice: {
        id: 'app.shopping.ticketDelivery.tdo.price',
        defaultMessage: 'Price',
    },
    lblTDOEdit: {
        id: 'app.shopping.ticketDelivery.tdo.edit',
        defaultMessage: 'Edit Ticket Delivery',
    },
    lblTDOAdd: {
        id: 'app.shopping.ticketDelivery.tdo.add',
        defaultMessage: 'Add Ticket Delivery',
    },
    lblTDOView: {
        id: 'app.shopping.ticketDelivery.tdo.view',
        defaultMessage: 'View Ticket Delivery',
    },
});
