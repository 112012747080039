import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import SwapHoriz from '@mui/icons-material/SwapHoriz';

import ViaAvoid from './ViaAvoid';
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import StationAutoComplete from '../../components/StationAutoComplete/StationAutoComplete';
import ReactSelectWrapped from '../../../components/ReactSelectWrapped/ReactSelectWrapped';
import { gaEvent } from '../../../utils/googleAnalytics';
import messages from '../messagesShoppingSearch';
import '../shoppingSearch.css';

import { maxConnectionsDropdownOptions, viaAvoidDirection } from './constants';

function SingleReturnView(props) {
    const { formatMessage } = props.intl;
    const {
        disabled,
        errors,
        exchangeOrderMode,
        isSingle,
        journey,
        onChangeArriveStation,
        onChangeDepartDate,
        onChangeDepartMaxConnections,
        onChangeDepartStation,
        onChangeOpenReturn,
        onChangeReturnDate,
        onChangeReturnMaxConnections,
        onChangeViaAvoidStation,
        onChangeViaAvoidType,
        onClearArriveStation,
        onClearDepartStation,
        onClearViaAvoidStation,
        onQuickSearchSwitch,
        onSwapStations,
        onViaAvoidToggle,
        quickSearch,
        showViaAvoid,
    } = props;

    const quickSearchClickAction = (quickSearch) ? onQuickSearchSwitch : undefined;
    const dateNow = (journey.minDate) ? new Date(journey.minDate) : new Date(new Date().setHours(0, 0, 0, 0));

    const handleDepartMaxConnectionsChange = (item) => {
        onChangeDepartMaxConnections(item.value);
    };

    const handleReturnMaxConnectionsChange = (item) => {
        onChangeReturnMaxConnections(item.value);
    };

    const handleOpenReturnChange = () => {
        gaEvent('fareSearchOpenReturn', !journey.openReturn);
        onChangeOpenReturn();
    };

    return (
        <div className="col-12">
            <div className="row">
                <div
                    className="col-12 col-sm-5"
                    onClick={quickSearchClickAction}
                >
                    <StationAutoComplete
                        id="srtDepartStationAutoComplete"
                        hintText={formatMessage(messages.lblStationSelect)}
                        floatingLabelText={formatMessage(messages.lblDepartStation)}
                        station={journey.departStation}
                        otherStationCode={journey.arriveStation.code}
                        onNewRequest={onChangeDepartStation}
                        errorText={errors.departCode}
                        disabled={disabled}
                        clearStation={onClearDepartStation}
                        styleName={(quickSearch) ? 'quickSearch' : ''}
                        includeTravelCards
                    />
                </div>
                <div className="col-12 col-sm-2" onClick={quickSearchClickAction} styleName="single-verticalAlign-center">
                    <IconButton onClick={onSwapStations} disabled={disabled}>
                        <SwapHoriz />
                    </IconButton>
                </div>
                <div
                    className="col-12 col-sm-5"
                    onClick={quickSearchClickAction}
                >
                    <StationAutoComplete
                        id="srtArriveStationAutoComplete"
                        hintText={formatMessage(messages.lblStationSelect)}
                        floatingLabelText={formatMessage(messages.lblArriveStation)}
                        station={journey.arriveStation}
                        otherStationCode={journey.departStation.code}
                        onNewRequest={onChangeArriveStation}
                        errorText={errors.arriveCode}
                        disabled={disabled}
                        clearStation={onClearArriveStation}
                        styleName={(quickSearch) ? 'quickSearch' : ''}
                        includeTravelCards
                    />
                </div>
                <div className="col-12">
                    <ViaAvoid
                        id="srtViaAvoidDepart"
                        journey={journey}
                        errors={errors}
                        onChangeViaAvoidStation={onChangeViaAvoidStation}
                        onChangeViaAvoidType={onChangeViaAvoidType}
                        onViaAvoidToggle={onViaAvoidToggle}
                        showViaAvoid={showViaAvoid[viaAvoidDirection.OUTBOUND]}
                        disabled={disabled}
                        onClearStation={onClearViaAvoidStation}
                        quickSearch={quickSearch}
                        onQuickSearchSwitch={quickSearchClickAction}
                        direction={viaAvoidDirection.OUTBOUND}
                    />
                    {!isSingle && !journey.openReturn && (
                        <ViaAvoid
                            id="srtViaAvoidDepart"
                            journey={journey}
                            errors={errors}
                            onChangeViaAvoidStation={onChangeViaAvoidStation}
                            onChangeViaAvoidType={onChangeViaAvoidType}
                            onViaAvoidToggle={onViaAvoidToggle}
                            showViaAvoid={showViaAvoid[viaAvoidDirection.INBOUND]}
                            disabled={disabled}
                            onClearStation={onClearViaAvoidStation}
                            quickSearch={quickSearch}
                            onQuickSearchSwitch={quickSearchClickAction}
                            direction={viaAvoidDirection.INBOUND}
                        />
                    )}
                </div>
                {!isSingle && !exchangeOrderMode && (
                    <div className="col-12 col-sm-6 offset-sm-6">
                        <FormControlLabel
                            control={<Checkbox />}
                            label={<FormattedMessage {...messages.lblOpenReturn} />}
                            disabled={disabled}
                            checked={journey.openReturn}
                            onChange={handleOpenReturnChange}
                        />
                    </div>
                )}
                <div
                    className="col-12 col-sm-6"
                    onClick={quickSearchClickAction}
                >
                    <DateTimePicker
                        id="srtDepartDate"
                        label={formatMessage(messages.lblDepartDate)}
                        defaultDate={new Date(journey.departDate)}
                        minDate={dateNow}
                        value={journey.departDate && new Date(journey.departDate)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        timeType={journey.timeOptionOutboundDate}
                        onChange={onChangeDepartDate}
                        errorText={errors.departDate}
                        disabled={disabled}
                        pickerType="Datetime"
                        addTimeOption
                    />
                    <ReactSelectWrapped
                        styleName="maxConnectionsDropdown"
                        fullWidth
                        placeholder={formatMessage(messages.lblMaximumConnections)}
                        onChange={handleDepartMaxConnectionsChange}
                        value={journey.departMaxConnections}
                        options={maxConnectionsDropdownOptions}
                        disabled={disabled}
                    />
                </div>
                {isSingle ? (
                    <div id="srtJourneySingleLabel" className="col-12 col-sm-1 offset-sm-2" styleName="single-verticalAlign">
                        {formatMessage(messages.lblSingle)}
                    </div>
                ) : !journey.openReturn && (
                    <div
                        className="col-12 col-sm-6"
                        onClick={quickSearchClickAction}
                    >
                        <DateTimePicker
                            id="srtReturnDate"
                            label={formatMessage(messages.lblReturnDate)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            defaultDate={new Date(journey.returnDate)}
                            minDate={journey.departDate}
                            value={journey.returnDate && new Date(journey.returnDate)}
                            timeType={journey.timeOptionInboundDate}
                            onChange={onChangeReturnDate}
                            errorText={errors.returnDate}
                            disabled={disabled}
                            pickerType="Datetime"
                            addTimeOption
                        />
                        <ReactSelectWrapped
                            styleName="maxConnectionsDropdown"
                            fullWidth
                            placeholder={formatMessage(messages.lblMaximumConnections)}
                            onChange={handleReturnMaxConnectionsChange}
                            value={journey.returnMaxConnections}
                            options={maxConnectionsDropdownOptions}
                            disabled={disabled}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

SingleReturnView.propTypes = {
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    exchangeOrderMode: PropTypes.bool,
    intl: PropTypes.object,
    isSingle: PropTypes.bool,
    journey: PropTypes.object,
    onChangeArriveStation: PropTypes.func.isRequired,
    onChangeDepartDate: PropTypes.func.isRequired,
    onChangeDepartMaxConnections: PropTypes.func,
    onChangeDepartStation: PropTypes.func.isRequired,
    onChangeOpenReturn: PropTypes.func,
    onChangeReturnDate: PropTypes.func.isRequired,
    onChangeReturnMaxConnections: PropTypes.func,
    onChangeViaAvoidStation: PropTypes.func,
    onChangeViaAvoidType: PropTypes.func,
    onClearArriveStation: PropTypes.func,
    onClearDepartStation: PropTypes.func,
    onClearViaAvoidStation: PropTypes.func,
    onQuickSearchSwitch: PropTypes.func,
    onSwapStations: PropTypes.func,
    onViaAvoidToggle: PropTypes.func,
    quickSearch: PropTypes.bool,
    showViaAvoid: PropTypes.object,
};

export { SingleReturnView as SingleReturnViewAlias };

export default injectIntl(SingleReturnView);
