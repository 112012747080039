import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material/Warning';

import messages from './messages';
import styles from './styles';

const StyledWarningDiv = styled('div')({
    ...styles.warning,
});

const StyledWarningIcon = styled(WarningIcon)({
    ...styles.icon,
});

const renderStationTime = (stationWithTime, depart) => {
    const station = stationWithTime && stationWithTime.substring(0, stationWithTime.indexOf(' @ '));
    const time = stationWithTime && stationWithTime.split(' @ ').pop();
    return {
        station,
        time,
        stationTime: `${station}, ${depart} ${time}`,
    };
};

const statusMessage = {
    CANCELLED: { ...messages.lblCancelled },
    DELAYED: { ...messages.lblDelayed },
    BOARDING_NOT_POSSIBLE: { ...messages.lblBoardingNotPossible },
    ALIGHTING_NOT_POSSIBLE: { ...messages.lblAlightingNotPossible },
    RESCHEDULED: { ...messages.lblRescheduled },
};

const RevisionDialog = ({
    onClose,
    open,
    revision = {},
    origin,
    destination,
    depart,
}) => {
    const {
        code,
        reason,
        revisedDepartureDateTime,
        revisedArrivalDateTime,
        revisedOriginTravelPoint,
        revisedDestinationTravelPoint,
        status,
    } = revision;
    const revisionMessage = code === '685' && reason.includes('delayed by overcrowding') ? 'Delay' : 'Cancel';
    const message = messages[`lblRevision${code === '685' ? revisionMessage : ''}${code}`];

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <StyledWarningDiv>
                    <StyledWarningIcon />
                    <strong><FormattedMessage {...statusMessage[status]} /></strong>
                </StyledWarningDiv>
                <p>
                    {message
                        ? (
                            <>
                                {`${code} : `}
                                <FormattedMessage {...message} />
                            </>
                        )
                        : reason}
                </p>
                {revisedOriginTravelPoint || revisedDepartureDateTime
                    ? (
                        <p>
                            <div><FormattedMessage {...messages.lblOriginModifications} /></div>
                            <div>
                                <FormattedMessage {...messages.lblOriginalScheduledDepart} />
                                {` ${renderStationTime(origin, depart)?.stationTime}`}
                            </div>
                            <div>
                                <FormattedMessage {...messages.lblNowDepart} />
                                {` ${revisedOriginTravelPoint || renderStationTime(origin, depart)?.station}, ${revisedDepartureDateTime}`}
                            </div>
                        </p>
                    )
                    : (
                        <p>
                            <div><FormattedMessage {...messages.lblOriginNoModifications} /></div>
                            <div>
                                <FormattedMessage {...messages.lblDepart} />
                                {` ${renderStationTime(origin, depart)?.stationTime}`}
                            </div>
                        </p>
                    )}
                {revisedDestinationTravelPoint || revisedArrivalDateTime
                    ? (
                        <p>
                            <div><FormattedMessage {...messages.lblDestinationModifications} /></div>
                            <div>
                                <FormattedMessage {...messages.lblOriginalArrive} />
                                {` ${renderStationTime(destination, depart)?.stationTime}`}
                            </div>
                            <div>
                                <FormattedMessage {...messages.lblNowArriving} />
                                {` ${revisedDestinationTravelPoint || renderStationTime(destination, depart)?.station}, ${revisedArrivalDateTime}`}
                            </div>
                        </p>
                    )
                    : (
                        <p>
                            <div><FormattedMessage {...messages.lblDestinationNoModifications} /></div>
                            <div>
                                {`Scheduled to arrive ${renderStationTime(destination, depart)?.stationTime}`}
                            </div>
                        </p>
                    )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    variant="contained"
                    color="primary"
                >
                    <FormattedMessage {...messages.lblClose} />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

RevisionDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    revision: PropTypes.object,
    origin: PropTypes.string,
    destination: PropTypes.string,
    depart: PropTypes.string,
};

export { RevisionDialog as RevisionDialogAlias };

export default RevisionDialog;
