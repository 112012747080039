import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { injectIntl } from 'react-intl';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import styles from './styles';
import messages from './messagesPlusBusSupplements';
import RenderPlusBusSupplements from './RenderPlusBusSupplements';

const StyledAccordion = styled(Accordion)(() => ({
    '&.Mui-expanded': {
        ...styles.expandedPanel,
    },
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
    ...styles.legSegment,
    '&.Mui-expanded': {
        ...styles.expandedPanelSummary,
    },
    '& .MuiAccordionSummary-content': {
        ...styles.legSegmentSummary,
    },
}));

const StyledGridItem = styled(Grid)(() => ({
    paddingTop: '0px !important',
}));

const PlusBusSupplementTravelSegment = ({
    legid,
    travelSegments,
    searchedPlusBusSupplements,
    onCheckPlusBusSupplement,
    intl,
    isToggled,
    setIsToggled,
}) => {

    const { formatMessage } = intl;
    const getExpandedTravelSegmentId = () => {
        const segmentIds = travelSegments && travelSegments[legid].map((travelSegment) => travelSegment?.TravelSegmentID);
        return segmentIds && segmentIds[0];
    };
    const [expanded, setExpanded] = useState(getExpandedTravelSegmentId());
    const lblTo = formatMessage(messages.lblTo);
    const expandable = searchedPlusBusSupplements && searchedPlusBusSupplements.length > 0;

    const getPassengerLabel = (label) => `Passenger ${parseInt(label.substring(label.lastIndexOf('_') + 1), 10) + 1}`;

    const handleSummaryClick = (tsID) => (event, expandedValue) => {
        setExpanded(expandedValue && tsID);
    };
    return (
        <>
            {
                travelSegments && travelSegments[legid].map((travelSegment) => (
                    <StyledAccordion
                        key={`ExpansionPanel${legid}${travelSegment.TravelSegmentID}`}
                        expanded={expanded === travelSegment.TravelSegmentID}
                        onChange={expandable ? handleSummaryClick(travelSegment.TravelSegmentID) : undefined}
                    >
                        <StyledAccordionSummary expandIcon={expandable && <ExpandMoreIcon />} >
                            {`${travelSegment.ShoppingFareDepartStationSegment} ${lblTo} ${travelSegment.ShoppingFareArriveStationSegment}`}
                        </StyledAccordionSummary>
                        <AccordionDetails key={`ExpansionPanelDetails${legid}${travelSegment.TravelSegmentID}`}>
                            <Grid container spacing={8}>
                                <Grid item xs={8} />
                                {searchedPlusBusSupplements?.map((plusBusSupplement, index) => plusBusSupplement && plusBusSupplement.linkedTravelSegments[0] === travelSegment.TravelSegmentID && (
                                    <StyledGridItem item xs={false} key={`Grid${index}`}>
                                        <RenderPlusBusSupplements
                                            key={`RenderPlusBusSupplements${index}`}
                                            plusBusSupplement={plusBusSupplement}
                                            searchedPlusBusSupplements={searchedPlusBusSupplements}
                                            onCheckPlusBusSupplement={onCheckPlusBusSupplement}
                                            isToggled={isToggled}
                                            setIsToggled={setIsToggled}
                                            passengerLabel={getPassengerLabel(plusBusSupplement.linkedPassengers[0])}
                                        />
                                    </StyledGridItem>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </StyledAccordion>
                ))
            }
        </>
    );
};

PlusBusSupplementTravelSegment.propTypes = {
    intl: PropTypes.object,
    onCheckPlusBusSupplement: PropTypes.func.isRequired,
    travelSegments: PropTypes.array.isRequired,
    searchedPlusBusSupplements: PropTypes.array.isRequired,
    legid: PropTypes.number.isRequired,
    isToggled: PropTypes.array,
    setIsToggled: PropTypes.func,
};

export { PlusBusSupplementTravelSegment as PlusBusSupplementTravelSegmentAlias };

export default injectIntl(PlusBusSupplementTravelSegment);
