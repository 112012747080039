import { defineMessages } from 'react-intl';

export default defineMessages({
    lblOrderNumber: {
        id: 'app.shopping.manageBooking.orderTitle.orderNumber',
        defaultMessage: 'Order #{index}',
    },
    lblCancellationReason: {
        id: 'app.shopping.manageBooking.orderTitle.cancellationReason',
        defaultMessage: '({reason} on {date} {internalReason})',
    },
    lblOrderConfirmBy: {
        id: 'app.shopping.manageBooking.orderTitle.orderConfirmBy',
        defaultMessage: '- confirm by {date}',
    },
    BOOKED: {
        id: 'app.shopping.manageBooking.orderTitle.BOOKED',
        defaultMessage: 'BOOKED',
    },
    CONFIRMED: {
        id: 'app.shopping.manageBooking.orderTitle.CONFIRMED',
        defaultMessage: 'CONFIRMED',
    },
    TICKETED: {
        id: 'app.shopping.manageBooking.orderTitle.TICKETED',
        defaultMessage: 'TICKETED',
    },
    ABANDONED: {
        id: 'app.shopping.manageBooking.orderTitle.ABANDONED',
        defaultMessage: 'ABANDONED',
    },
    RELEASED: {
        id: 'app.shopping.manageBooking.orderTitle.RELEASED',
        defaultMessage: 'RELEASED',
    },
    CANCELED: {
        id: 'app.shopping.manageBooking.orderTitle.CANCELED',
        defaultMessage: 'CANCELED',
    },
    VOID_EXCEPTION: {
        id: 'app.shopping.manageBooking.orderTitle.voidException',
        defaultMessage: 'Void Exception',
    },
    RETURNED: {
        id: 'app.shopping.manageBooking.orderTitle.RETURNED',
        defaultMessage: 'RETURNED',
    },
    WITHDRAWN: {
        id: 'app.shopping.manageBooking.orderTitle.WITHDRAWN',
        defaultMessage: 'WITHDRAWN',
    },
    INVALID: {
        id: 'app.shopping.manageBooking.orderTitle.INVALID',
        defaultMessage: 'INVALID',
    },
    PRINTING: {
        id: 'app.shopping.manageBooking.orderTitle.PRINTING',
        defaultMessage: 'PRINTING',
    },
    REPLACED: {
        id: 'app.shopping.manageBooking.orderTitle.REPLACED',
        defaultMessage: 'REPLACED',
    },
    PENDING: {
        id: 'app.shopping.manageBooking.orderTitle.PENDING',
        defaultMessage: 'PENDING',
    },
    CANCELED_WITH_FRAUD_PENALTY: {
        id: 'app.shopping.manageBooking.orderTitle.CANCELED_WITH_FRAUD_PENALTY',
        defaultMessage: 'Fraud Detected',
    },
    DELAY_CANCEL: {
        id: 'app.shopping.manageBooking.orderTitle.DELAY_CANCEL',
        defaultMessage: 'Fraud Detection Pending - {refundAvailable}',
    },
    CANCELED_FRAUD_NOT_FOUND: {
        id: 'app.shopping.manageBooking.orderTitle.CANCELLED',
        defaultMessage: 'Fraud Not Found',
    },
    RELEASE_SEAT: {
        id: 'app.shopping.manageBooking.orderTitle.RELEASE_SEAT',
        defaultMessage: 'Seats Released. Awaiting coupons/tickets to be returned.',
    },
    RELEASE_SEAT_NORMAL: {
        id: 'app.shopping.manageBooking.orderTitle.RELEASE_SEAT_NORMAL',
        defaultMessage: 'Seats Released.',
    },
    RELEASE_SEAT_RETURNED: {
        id: 'app.shopping.manageBooking.orderTitle.RELEASE_SEAT_RETURNED',
        defaultMessage: 'Seats Released. Coupons have been returned. Awaiting refund to be authorized.',
    },
});
