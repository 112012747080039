import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';

import { gaEvent } from '../../../../utils/googleAnalytics';
import ActionButtons from './ActionButtons';
import messages from './messages';
import styles from './styles';
import { getReleaseStatus } from '../../utils';

const TitleContainerDiv = styled('div')({
    ...styles.titleContainer,
});

const ButtonsGrid = styled(Grid)({
    marginLeft: 'unset !important',
    marginTop: 'unset !important',
});

const TitleSpan = styled('span')({
    ...styles.title,
});

const ValueDocumentCoupons = ({
    coupons,
    orderId,
    returnCoupons,
    authorizeRefund,
    handleOpenReturnCouponsDialog,
    handleOpenAuthorizeRefundDialog,
}) => {
    const [expanded, setExpanded] = useState(false);
    const handleExpand = () => {
        gaEvent(expanded ? 'collapseValueDocumentCoupons' : 'expandValueDocumentCoupons');
        setExpanded(!expanded);
    };

    return (
        <>
            {!coupons && (
                <TitleContainerDiv>
                    <ButtonsGrid container spacing={8} alignItems="center">
                        <TitleSpan><FormattedMessage {...messages.lblTitle} /></TitleSpan>
                        <ActionButtons
                            orderId={orderId}
                            returnCoupons={returnCoupons}
                            authorizeRefund={authorizeRefund}
                            handleOpenReturnCouponsDialog={handleOpenReturnCouponsDialog}
                            handleOpenAuthorizeRefundDialog={handleOpenAuthorizeRefundDialog}
                        />
                    </ButtonsGrid>
                </TitleContainerDiv>
            )}
            {!!coupons && !!coupons.length && (
                <>
                    <TitleContainerDiv>
                        <ButtonsGrid container spacing={8} alignItems="center">
                            <TitleSpan><FormattedMessage {...messages.lblTitle} /></TitleSpan>
                            <IconButton onClick={handleExpand}>
                                {expanded ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                            <ActionButtons
                                orderId={orderId}
                                returnCoupons={returnCoupons}
                                authorizeRefund={authorizeRefund}
                                handleOpenReturnCouponsDialog={handleOpenReturnCouponsDialog}
                                handleOpenAuthorizeRefundDialog={handleOpenAuthorizeRefundDialog}
                            />
                        </ButtonsGrid>
                    </TitleContainerDiv>
                    <Collapse in={expanded}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><FormattedMessage {...messages.lblNumber} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.lblType} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.lblReleaseStatus} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.lblReturnStartDateTime} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.lblUniqueTicketNumber} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.lblTravelSegments} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.lblOriginalCouponNumber} /></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {coupons.map((coupon) => (
                                    <TableRow key={coupon.couponNumber}>
                                        <TableCell>{coupon.couponNumber}</TableCell>
                                        <TableCell>{coupon.couponType}</TableCell>
                                        <TableCell>{getReleaseStatus(coupon.isReleasable, coupon.releasedAt) || ''}</TableCell>
                                        <TableCell>{coupon?.BookingCouponsReturnStartDateTime || '-'}</TableCell>
                                        <TableCell>{coupon.BookingCouponsUniqueTicketNumber}</TableCell>
                                        <TableCell>{coupon.description}</TableCell>
                                        <TableCell>{coupon.originalCouponNumber}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </>
            )}
        </>
    );
};

ValueDocumentCoupons.propTypes = {
    coupons: PropTypes.arrayOf(PropTypes.shape({
        couponNumber: PropTypes.string,
        couponType: PropTypes.string,
        isReleasable: PropTypes.any,
        couponReceivedDate: PropTypes.string,
        origCode: PropTypes.any,
        origStationName: PropTypes.any,
        destCode: PropTypes.any,
        destStationName: PropTypes.any,
        description: PropTypes.string,
        numPax: PropTypes.number,
        numPaxCouponsIssued: PropTypes.number,
        tsDepartTime: PropTypes.number,
        releasedAt: PropTypes.any,
        originalCouponNumber: PropTypes.any,
        BookingCouponsUniqueTicketNumber: PropTypes.any,
        BookingCouponsReturnStartDateTime: PropTypes.string,
    })),
    orderId: PropTypes.string.isRequired,
    returnCoupons: PropTypes.bool.isRequired,
    authorizeRefund: PropTypes.bool.isRequired,
    handleOpenReturnCouponsDialog: PropTypes.func.isRequired,
    handleOpenAuthorizeRefundDialog: PropTypes.func.isRequired,
};

export { ValueDocumentCoupons as ValueDocumentCouponsAlias };

export default ValueDocumentCoupons;
