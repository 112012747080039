import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import validate from 'validate.js';

import { injectIntl } from 'react-intl';
import messages from './messagesOnboardServices';

import OnBoardServicesView from './OnboardServicesView';
import inlineStyles from './styles';
import { gaEvent } from '../../../../utils/googleAnalytics';

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        ...inlineStyles.dialogPaper,
    },
}));
class OnBoardServicesDialog extends Component {
    static propTypes = {
        intl: PropTypes.object,
        open: PropTypes.bool,
        onDiscardOnBoardServices: PropTypes.func.isRequired,
        onChangeOnBoardServices: PropTypes.func.isRequired,
        selectedOnBoardServices: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object,
        ]),
        onBoardServices: PropTypes.array.isRequired,
        passengers: PropTypes.array.isRequired,
        legs: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedOnBoardServices: {},
        };
    }

    // eslint-disable-next-line camelcase,react/sort-comp
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ selectedOnBoardServices: nextProps.selectedOnBoardServices });
    }

    handleChangeService = (segmentId, paxSpec, category, service) => {
        this.setState((state) => {
            let segmentService = state.selectedOnBoardServices[segmentId];
            if (service !== null) {
                if (segmentService) segmentService[paxSpec] = { [category]: [service] };
                else segmentService = { [paxSpec]: { [category]: [service] } };
            } else if (segmentService[paxSpec] && segmentService[paxSpec][category]) {
                delete segmentService[paxSpec][category];
                if (validate.isEmpty(segmentService[paxSpec])) delete segmentService[paxSpec];
            } else if (segmentService[paxSpec]) {
                delete segmentService[paxSpec];
            }

            return {
                selectedOnBoardServices: {
                    ...state.selectedOnBoardServices,
                    [segmentId]: segmentService,
                },
            };
        });
    };

    handleDiscard = () => {
        gaEvent('createBookingOnboardServicesDiscard');
        this.props.onDiscardOnBoardServices();
    };

    handleSave = () => {
        const { selectedOnBoardServices } = this.state;
        const services = [];

        // list by segment
        Object.keys(selectedOnBoardServices).forEach((segmentKey) => (
            // list by passenger
            Object.keys(selectedOnBoardServices[segmentKey]).forEach((paxPrefKey) => (
                // list by category
                Object.keys(selectedOnBoardServices[segmentKey][paxPrefKey]).reduce((array, category) => {
                    array.push(selectedOnBoardServices[segmentKey][paxPrefKey][category][0]);
                    return array;
                }, services)))
        ));

        gaEvent('createBookingOnboardServicesSave');
        this.props.onChangeOnBoardServices(services);
    };

    render() {
        const { intl: { formatMessage } } = this.props;
        const actions = (
            <div className="row" style={inlineStyles.dialogActionsRoot}>
                <div className="col-4 offset-2 offset-md-5">
                    <Button
                        variant="contained"
                        id="srtOnBoardServicesDiscardChanges"
                        onClick={this.handleDiscard}
                        fullWidth
                    >
                        {formatMessage(messages.lblDiscard)}
                    </Button>
                </div>
                <div className="col-4 col-md-3">
                    <Button
                        variant="contained"
                        id="srtOnBoardServicesSave"
                        onClick={this.handleSave}
                        fullWidth
                        color="primary"
                    >
                        {formatMessage(messages.lblSave)}
                    </Button>
                </div>
            </div>
        );

        return (
            <StyledDialog
                open={this.props.open}
                onClose={this.handleDiscard}
                disableEnforceFocus
                maxWidth={false}
            >
                <DialogTitle>{formatMessage(messages.lblTitle)}</DialogTitle>
                <DialogContent>
                    <OnBoardServicesView
                        onBoardServices={this.props.onBoardServices}
                        selectedOnBoardServices={this.state.selectedOnBoardServices}
                        passengers={this.props.passengers}
                        onChangeService={this.handleChangeService}
                        legs={this.props.legs}
                    />
                </DialogContent>
                <DialogActions>
                    {actions}
                </DialogActions>
            </StyledDialog>
        );
    }
}

export { OnBoardServicesDialog as OnBoardServicesDialogAlias };
export default injectIntl(OnBoardServicesDialog);
