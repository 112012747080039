import { defineMessages } from 'react-intl';

export default defineMessages({
    lblBookingSummary: {
        id: 'app.shopping.manageBooking.bookingSummary.bookingSummary',
        defaultMessage: 'Booking Summary',
    },
    lblBookingLocator: {
        id: 'app.shopping.manageBooking.bookingSummary.bookingLocator',
        defaultMessage: 'Booking Locator',
    },
    lblBookingAccount: {
        id: 'app.shopping.manageBooking.bookingSummary.bookingAccount',
        defaultMessage: 'Booking Account',
    },
    lblBookingAccountUnknown: {
        id: 'app.shopping.manageBooking.bookingSummary.bookingAccountUnknown',
        defaultMessage: 'Unknown',
    },
    lblBookingDate: {
        id: 'app.shopping.manageBooking.bookingSummary.bookingDate',
        defaultMessage: 'Booking Date',
    },
    lblBookingTotalCharges: {
        id: 'app.shopping.manageBooking.bookingSummary.bookingTotalCharges',
        defaultMessage: 'Total Charges',
    },
    lblBookingTotalPayments: {
        id: 'app.shopping.manageBooking.bookingSummary.bookingTotalPayments',
        defaultMessage: 'Total Payments',
    },
    lblBookingBalance: {
        id: 'app.shopping.manageBooking.bookingSummary.bookingBalance',
        defaultMessage: 'Balance',
    },
    lblBalanced: {
        id: 'app.shopping.manageBooking.bookingSummary.status.balanced',
        defaultMessage: 'BALANCED',
    },
    lblCredit: {
        id: 'app.shopping.manageBooking.bookingSummary.status.credit',
        defaultMessage: 'CREDIT',
    },
    lblDebit: {
        id: 'app.shopping.manageBooking.bookingSummary.status.debit',
        defaultMessage: 'DEBIT',
    },
    lblClosed: {
        id: 'app.shopping.manageBooking.bookingSummary.status.closed',
        defaultMessage: 'CLOSED',
    },
    btnAddOrder: {
        id: 'app.shopping.manageBooking.bookingSummary.addOrder',
        defaultMessage: 'Add Order',
    },
    lblRefresh: {
        id: 'app.shopping.manageBooking.bookingSummary.refresh',
        defaultMessage: 'Refresh Booking',
    },
    lblBillingSummary: {
        id: 'app.shopping.manageBooking.billingSummary.billingSummary',
        defaultMessage: 'Billing Summary',
    },
    lblRefundDueTitle: {
        id: 'app.booking.issueRefundDialog.refundDueTitle',
        defaultMessage: 'Suggested Followup Actions',
    },
    lblAddFeeDialogTitle: {
        id: 'app.booking.addFeeDiscountDialog.title',
        defaultMessage: 'Add Fee/Discount',
    },
    lblAmendSeatReservationsDialogTitle: {
        id: 'app.booking.amendSeatReservationsDialog.title',
        defaultMessage: 'Amend Seat Reservations',
    },
    lblMakePaymentTitle: {
        id: 'app.shopping.makePayment.title',
        defaultMessage: 'Make Payment',
    },
    lblAddOrderDialogTitle: {
        id: 'app.shopping.manageBooking.addOrder.title',
        defaultMessage: 'Add Order to Booking',
    },
});
