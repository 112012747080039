import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';

import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import messages from './messagesIssueRefund';
import inlineStyles from './styles';

const StyledDialogActionsRootDiv = styled('div')({
    ...inlineStyles.dialogActionsRoot,
});

const Actions = ({
    closeAction,
    data: {
        showIssueRefund,
        showSendTicket,
        showWelcomeConfirmation,
        isFetching,
        isProcessingSubmit,
        isEPNotSettled,
        currentReceipt: {
            BookingBillingPaymentDetailBalance,
            BookingIssueRefundEligibility,
        },
        refundAmount,
        reverseRefundMutation,
    },
    handleContinue,
    handleSubmitSend,
    handleSubmitRefund,
    issueRefund,
    sendTicket,
    issueRefundDialog,
}) => {
    let submitBtn;
    let id;
    let handleSubmit;
    if (issueRefund && sendTicket) {
        submitBtn = <FormattedMessage {...messages.btnYes} />;
        if (showIssueRefund) {
            id = 'srtRefund';
            submitBtn = <FormattedMessage {...messages[showWelcomeConfirmation ? 'btnYes' : 'btnSubmit']} />;
            handleSubmit = handleSubmitRefund;
        } else if (showSendTicket) {
            id = 'srtSendTicket';
            submitBtn = <FormattedMessage {...messages[showWelcomeConfirmation ? 'btnYes' : 'btnSend']} />;
            handleSubmit = handleSubmitSend;
        } else {
            id = 'srtRefund';
            submitBtn = <FormattedMessage {...messages[showWelcomeConfirmation ? 'btnYes' : 'btnSubmit']} />;
            handleSubmit = handleSubmitRefund;
        }
    } else if (issueRefund) {
        id = 'srtRefund';
        submitBtn = <FormattedMessage {...messages[showWelcomeConfirmation ? 'btnYes' : 'btnSubmit']} />;
        handleSubmit = handleSubmitRefund;
    } else if (sendTicket) {
        id = 'srtSendTicket';
        submitBtn = <FormattedMessage {...messages[showWelcomeConfirmation ? 'btnYes' : 'btnSend']} />;
        handleSubmit = handleSubmitSend;
    }

    const disabled = isFetching || isProcessingSubmit || BookingIssueRefundEligibility === false;

    return (
        <div>
            {((!BookingBillingPaymentDetailBalance && refundAmount === 0) || BookingBillingPaymentDetailBalance === 0) && (
                <div style={{ maxWidth: '170px', whiteSpace: 'normal' }}><FormattedMessage {...messages.lblRefunded} /></div>
            )}
            <StyledDialogActionsRootDiv className="row">
                <div className="col-6">
                    <Button
                        variant="contained"
                        id={`${id}Close`}
                        onClick={closeAction}
                        fullWidth
                        disabled={isFetching || isProcessingSubmit}
                    >
                        <FormattedMessage {...messages[showWelcomeConfirmation ? 'btnNo' : 'btnCancel']} />
                    </Button>
                </div>
                <div className="col-6">
                    {BookingBillingPaymentDetailBalance === undefined ? (
                        <Button
                            variant="contained"
                            id={`${id}Submit`}
                            onClick={showWelcomeConfirmation ? handleContinue : handleSubmit}
                            color="primary"
                            fullWidth
                            disabled={disabled || reverseRefundMutation || isEPNotSettled || (!showIssueRefund && !showSendTicket && !issueRefundDialog && issueRefund && sendTicket)}
                        >
                            {submitBtn}
                        </Button>
                    )
                        : BookingBillingPaymentDetailBalance > 0 && (
                            <Button
                                variant="contained"
                                id={`${id}Submit`}
                                onClick={showWelcomeConfirmation ? handleContinue : handleSubmit}
                                color="primary"
                                fullWidth
                                disabled={disabled || reverseRefundMutation || isEPNotSettled || (!showIssueRefund && !showSendTicket && !issueRefundDialog && issueRefund && sendTicket)}
                            >
                                {submitBtn}
                            </Button>
                        )}
                </div>
            </StyledDialogActionsRootDiv>
        </div>
    );
};

Actions.propTypes = {
    closeAction: PropTypes.func.isRequired,
    data: PropTypes.object,
    handleContinue: PropTypes.func.isRequired,
    handleSubmitRefund: PropTypes.func,
    handleSubmitSend: PropTypes.func,
    issueRefund: PropTypes.bool,
    sendTicket: PropTypes.bool,
    issueRefundDialog: PropTypes.bool,
};

export default Actions;
