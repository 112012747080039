import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import { FormattedMessage } from 'react-intl';

import messages from './messagesIssueRefund';

const BillingExternalProviderFields = ({ id, receiptDetails, offlineSync }) => (
    <>
        <TextField
            id={`${id}_ExternalProvider`}
            label={<FormattedMessage {...messages.lblExternalProvider} />}
            fullWidth
            value={receiptDetails.BookingBillingExternalProvider || ''}
            disabled
            variant="standard"
        />
        <TextField
            id={`${id}_ExternalReference`}
            label={<FormattedMessage {...messages.lblExternalReference} />}
            fullWidth
            value={[
                receiptDetails.BookingBillingExternalReference,
                receiptDetails.BookingBillingExternalReferenceTwo,
            ].filter(Boolean).join(' ')}
            disabled
            variant="standard"
        />
        {!offlineSync && (
            <TextField
                id={`${id}_Status`}
                label={<FormattedMessage {...messages.lblStatus} />}
                fullWidth
                value={receiptDetails.BookingBillingTransactionStatus || ''}
                disabled
                variant="standard"
            />
        )}
    </>
);

BillingExternalProviderFields.propTypes = {
    id: PropTypes.string.isRequired,
    receiptDetails: PropTypes.object,
    offlineSync: PropTypes.bool,
};

export default BillingExternalProviderFields;
