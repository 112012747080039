import { defineMessages } from 'react-intl';

export default defineMessages({
    areYouSure: {
        id: 'app.common.confirm.areYouSure',
        defaultMessage: 'Are you sure?',
    },
    yes: {
        id: 'app.yes',
        defaultMessage: 'Yes',
    },
    no: {
        id: 'app.no',
        defaultMessage: 'No',
    },
});
