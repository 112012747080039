import { lightGray } from '../../../../colors';
import bookingViewStyles from '../BookingView/styles';

export default {
    redButton: bookingViewStyles.redButton,
    orangeButton: bookingViewStyles.orangeButton,
    bookingTableBillingButtons: {
        marginTop: 15,
    },
    expansionPanelSummaryContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    expansionPanelDetailsCharges: {
        flexWrap: 'wrap',
    },
    balanceContainer: {
        margin: '10px 55px 0 25px',
    },
    totalAmount: {
        fontWeight: 'bold',
    },
    noMargin: {
        margin: 0,
    },
    table: {
        rowItemStyle: {
            whiteSpace: 'normal',
            paddingLeft: 15,
            paddingRight: 15,
            borderRight: `1px solid ${lightGray}`,
        },
    },
};
