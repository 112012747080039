import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import TravelDocs from '../TravelDocs/TravelDocs';
import DiscountsAndLoyalties from '../DiscountsAndLoyalties/DiscountsAndLoyalties';
import Address from '../../../../components/Address/Address';
import AddAddressDialog from '../AddAddressDialog/AddAddressDialog';
import PassengerCustomInformation from '../../../../Booking/ManageBooking/components/PassengerCustomInformation/PassengerCustomInformation';
import messages from './messagesPassenger';
import styles from './styles';

const StyledPaper = styled(Paper)(() => ({
    ...styles.paperContainer,
}));

const StyledButton = styled(Button)(() => ({
    ...styles.buttons,
}));

const Passenger = ({
    addOrderMode,
    canadianProvinces,
    countries,
    createBookingData,
    exchangeOrderMode,
    faresSelected,
    handleAddAddress,
    handleChangeAddress,
    handleChangeCustomInformation,
    handleChangeDiscountsAndLoyalties,
    handleChangeField,
    handleChangeTravelDocs,
    handleCreateBooking,
    handleWithoutAddress,
    index,
    paxInfo,
    states,
    tripType,
    requiredFields,
    updateOpenPassengerCustomInformation,
    intl: { formatMessage },
}) => {
    const legSolutions = faresSelected.map((item) => item.getIn(['leg', 'ShoppingLegSolutions']));
    let travelDocsTypes = [];
    legSolutions.forEach((item) => {
        if (item.get('0').has('ShoppingPassengerTravelDocs')) {
            travelDocsTypes = item.get('0').get('ShoppingPassengerTravelDocs');
        }
    });

    const disabled = createBookingData.get('isProcessingSubmit') || createBookingData.get('isFetching') || addOrderMode || exchangeOrderMode;

    const hasDiscountsLoyalties = paxInfo.getIn(['discountsLoyalties', 'aggregatedDiscounts']).size > 0
        || paxInfo.getIn(['discountsLoyalties', 'aggregatedLoyalties']).size > 0;
    const address = paxInfo.get('address').toJS();
    const addressRequired = requiredFields.PASSENGER_ADDRESS;
    const errorAddress = createBookingData.getIn(['errors', 'passengerInfo', index, 'address']);
    const openAddAddressDialog = createBookingData.get('openAddAddressDialog');

    const addAddressAndCustomInformation = !addOrderMode && !exchangeOrderMode && (
        <div className="row">
            {!(paxInfo.get('addAddress') || addressRequired) && (
                <StyledButton
                    variant="contained"
                    id="srtAddAddress"
                    color="secondary"
                    onClick={() => handleAddAddress(index)}
                    disabled={disabled}
                >
                    <FormattedMessage {...messages.lblAddAddress} />
                </StyledButton>
            )}
            <StyledButton
                variant="contained"
                id="srtAddCustomInformation"
                color="secondary"
                onClick={() => updateOpenPassengerCustomInformation(index)}
                disabled={disabled}
            >
                <FormattedMessage {...messages.lblAddCustomInformation} />
            </StyledButton>
        </div>
    );

    return (
        <StyledPaper key={index} elevation={1}>
            <div className="row">
                <div className="col-12">
                    <h2><FormattedMessage {...messages.lblPassengers} /></h2>
                    <h3>
                        <FormattedMessage
                            {...messages.lblPassenger}
                            values={{
                                index: index + 1,
                                age: paxInfo.get('ageDesc'),
                            }}
                        />
                    </h3>
                </div>
                {(tripType === formatMessage(messages.lblSeason)) && (
                    <div className="col-6">
                        <TextField
                            id="srtTitle"
                            name="title"
                            label={<FormattedMessage {...messages.lblPaxTitle} />}
                            fullWidth
                            value={paxInfo.get('title')}
                            error={!!createBookingData.getIn(['errors', 'passengerInfo', index, 'title'])}
                            helperText={createBookingData.getIn(['errors', 'passengerInfo', index, 'title'])}
                            onChange={(event) => handleChangeField(event, ['passengerInfo', index])}
                            disabled={disabled}
                            variant="standard"
                        />
                    </div>
                )}
                <div className="col-6">
                    <TextField
                        id="srtFirstName"
                        name="firstName"
                        label={<FormattedMessage {...messages.lblFirstName} />}
                        fullWidth
                        value={paxInfo.get('firstName')}
                        error={!!createBookingData.getIn(['errors', 'passengerInfo', index, 'firstName'])}
                        helperText={createBookingData.getIn(['errors', 'passengerInfo', index, 'firstName'])}
                        onChange={(event) => handleChangeField(event, ['passengerInfo', index])}
                        disabled={disabled}
                        variant="standard"
                    />
                </div>
                <div className="col-6">
                    <TextField
                        id="srtLastName"
                        name="lastName"
                        label={<FormattedMessage {...messages.lblLastName} />}
                        fullWidth
                        value={paxInfo.get('lastName')}
                        error={!!createBookingData.getIn(['errors', 'passengerInfo', index, 'lastName'])}
                        helperText={createBookingData.getIn(['errors', 'passengerInfo', index, 'lastName'])}
                        onChange={(event) => handleChangeField(event, ['passengerInfo', index])}
                        disabled={disabled}
                        variant="standard"
                    />
                </div>
                <div className="col-6">
                    <TextField
                        id="srtPhoneNumber"
                        name="phoneNumber"
                        label={<FormattedMessage {...messages[!index ? 'lblPhoneNumber' : 'lblPhoneNumberOptional']} />}
                        fullWidth
                        value={paxInfo.get('phoneNumber')}
                        error={!!createBookingData.getIn(['errors', 'passengerInfo', index, 'phoneNumber'])}
                        helperText={createBookingData.getIn(['errors', 'passengerInfo', index, 'phoneNumber'])}
                        onChange={(event) => handleChangeField(event, ['passengerInfo', index])}
                        disabled={disabled}
                        variant="standard"
                    />
                </div>
                <div className="col-6">
                    <TextField
                        id="srtEmail"
                        name="email"
                        label={<FormattedMessage {...messages[(index === 0 || requiredFields.PASSENGER_EMAIL) ? 'lblEmail' : 'lblEmailOptional']} />}
                        fullWidth
                        value={paxInfo.get('email')}
                        error={!!createBookingData.getIn(['errors', 'passengerInfo', index, 'email'])}
                        helperText={createBookingData.getIn(['errors', 'passengerInfo', index, 'email'])}
                        onChange={(event) => handleChangeField(event, ['passengerInfo', index])}
                        disabled={disabled}
                        variant="standard"
                    />
                </div>
                {(requiredFields.TRAVEL_DOCUMENTS || requiredFields.DATE_OF_BIRTH || createBookingData.get('isTravelDocsRequired'))
                    && paxInfo.has('travelDocs') && (
                        <div className="col-12">
                            <TravelDocs
                                idPrefix="srtPaxTravelDocs"
                                index={index}
                                countries={countries}
                                travelDocsValues={paxInfo.get('travelDocs').toJS()}
                                travelDocTypes={travelDocsTypes}
                                onChange={(updates) => handleChangeTravelDocs(index, updates)}
                                disabled={disabled}
                                errors={
                                    createBookingData.getIn(['errors', 'passengerInfo', index, 'travelDocs'])
                                        ? createBookingData.getIn(['errors', 'passengerInfo', index, 'travelDocs']).toJS() : {}
                                }
                            />
                        </div>
                    )}
                {(paxInfo.get('addAddress') || addressRequired) && (
                    <div className="col-12">
                        <>
                            <Address
                                idPrefix="srtPax"
                                values={address}
                                countries={countries}
                                states={states}
                                provinces={canadianProvinces}
                                onChange={(updates) => handleChangeAddress(updates, index)}
                                disabled={disabled}
                                errors={
                                    errorAddress ? errorAddress.toJS() : {}
                                }
                                useDefaults
                            />
                            <AddAddressDialog
                                open={openAddAddressDialog}
                                addressValues={address}
                                addressRequired={addressRequired}
                                countries={countries}
                                states={states}
                                provinces={canadianProvinces}
                                onAddressChange={(updates) => handleChangeAddress(updates, index)}
                                onContinueWith={handleCreateBooking}
                                onContinueWithout={() => handleWithoutAddress(index)}
                                errors={
                                    errorAddress ? errorAddress.toJS() : {}
                                }
                                disabled={disabled}
                            />
                        </>
                    </div>
                )}
                <div className="col-12">
                    {addAddressAndCustomInformation}
                </div>
                {hasDiscountsLoyalties && (
                    <DiscountsAndLoyalties
                        idPrefix="srtPaxDiscountsLoyalties"
                        index={index}
                        paxInfo={paxInfo}
                        onChange={(updates) => handleChangeDiscountsAndLoyalties(index, updates)}
                        disabled={createBookingData.get('isProcessingSubmit') || createBookingData.get('isFetching')}
                        errors={createBookingData.getIn(['errors', 'passengerInfo', index, 'discountsLoyalties'])
                            ? createBookingData.getIn(['errors', 'passengerInfo', index, 'discountsLoyalties']).toJS()
                            : {}}
                    />
                )}
            </div>
            <PassengerCustomInformation
                key={`${createBookingData.get('openPassengerCustomInformation', '')}`}
                customInformation={paxInfo.get('customInformation').toJS()}
                dialogClose={(registerEvent) => updateOpenPassengerCustomInformation(null, registerEvent)}
                onSave={(customInformation) => handleChangeCustomInformation(customInformation, index)}
                open={createBookingData.get('openPassengerCustomInformation') === index}
                passengerName={formatMessage(messages.lblPassenger, { index: index + 1, age: paxInfo.get('ageDesc') })}
                source="CREATE"
            />
        </StyledPaper>
    );
};

Passenger.propTypes = {
    addOrderMode: PropTypes.bool,
    canadianProvinces: PropTypes.object,
    countries: PropTypes.object,
    createBookingData: PropTypes.object.isRequired,
    exchangeOrderMode: PropTypes.bool,
    faresSelected: PropTypes.object.isRequired,
    handleAddAddress: PropTypes.func.isRequired,
    handleChangeAddress: PropTypes.func.isRequired,
    handleChangeCustomInformation: PropTypes.func.isRequired,
    handleChangeDiscountsAndLoyalties: PropTypes.func.isRequired,
    handleChangeField: PropTypes.func.isRequired,
    handleChangeTravelDocs: PropTypes.func.isRequired,
    handleCreateBooking: PropTypes.func.isRequired,
    handleWithoutAddress: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    intl: PropTypes.object,
    paxInfo: PropTypes.object.isRequired,
    states: PropTypes.object,
    tripType: PropTypes.string,
    updateOpenPassengerCustomInformation: PropTypes.func.isRequired,
    requiredFields: PropTypes.object,
};

export { Passenger as PassengerAlias };
export default injectIntl(Passenger);
