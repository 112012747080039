import { defineMessages } from 'react-intl';

export default defineMessages({
    lblTitle: {
        id: 'app.shopping.manageBooking.valueDocumentCoupons.title',
        defaultMessage: 'Value Document Coupons',
    },
    lblNumber: {
        id: 'app.shopping.manageBooking.valueDocumentCoupons.number',
        defaultMessage: 'Coupon Number',
    },
    lblType: {
        id: 'app.shopping.manageBooking.valueDocumentCoupons.type',
        defaultMessage: 'Coupon Type',
    },
    lblUniqueTicketNumber: {
        id: 'app.shopping.manageBooking.valueDocumentCoupons.uniqueTicketNumber',
        defaultMessage: 'Unique Ticket Number',
    },
    lblReleaseStatus: {
        id: 'app.shopping.manageBooking.valueDocumentCoupons.releaseStatus',
        defaultMessage: 'Release Status',
    },
    lblReturnStartDateTime: {
        id: 'app.shopping.manageBooking.valueDocumentCoupons.returnStartDateTime',
        defaultMessage: 'Return Start Date/Time',
    },
    lblTravelSegments: {
        id: 'app.shopping.manageBooking.valueDocumentCoupons.travelSegments',
        defaultMessage: 'Travel Segments',
    },
    lblOriginalCouponNumber: {
        id: 'app.shopping.manageBooking.valueDocumentCoupons.originalCouponNumber',
        defaultMessage: 'Original Coupon Number',
    },
    lblEligibleForRelease: {
        id: 'app.shopping.manageBooking.valueDocumentCoupons.eligibleForRelease',
        defaultMessage: 'Eligible for release',
    },
    lblNotEligibleForRelease: {
        id: 'app.shopping.manageBooking.valueDocumentCoupons.notEligibleForRelease',
        defaultMessage: 'Not eligible for release',
    },
    lblReleasedOnline: {
        id: 'app.shopping.manageBooking.valueDocumentCoupons.releasedOnline',
        defaultMessage: 'Released online',
    },
    lblReleasedOffline: {
        id: 'app.shopping.manageBooking.valueDocumentCoupons.releasedOffline',
        defaultMessage: 'Released offline',
    },
});
