import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import messages from './messagesReservations';
import './reservations.css';
import { formatPrice } from '../../../../utils';

const BikeReservation = (props) => {
    const {
        travelSegments,
        bikeReservations,
        selectedBikeReservations,
        passengerId,
        onChangeBikeReservation,
    } = props;

    const { formatMessage } = props.intl;

    const getPriceAndBikeAvailability = (reservations, segmentId) => {
        let price = null;
        // eslint-disable-next-line consistent-return
        reservations.forEach((reservation) => {
            if (reservation?.linkedTravelSegments?.some((travelSegmentId) => (travelSegmentId === segmentId))
                && reservation?.supplementAvailabilities?.some((supplementAvailability) => (supplementAvailability.travelSegmentID === segmentId
                    && supplementAvailability.quantity > 0))) {
                price = reservation.value;
            }
        });

        return price;
    };

    return (
        <div className="row">
            <div className="col-12">
                {travelSegments.map((item) => {
                    const handlePassengerBikeToggle = (event, value) => {
                        onChangeBikeReservation(item.TravelSegmentID, passengerId, value, parseFloat(event.target.value));
                    };

                    const renderBikeForPassenger = (price, formattedPrice, enabled) => (
                        <div className="row">
                            <div className="col-6 col-lg-5">
                                <FormControlLabel
                                    label={<FormattedMessage {...messages.lblRequestBike} />}
                                    control={(
                                        <Switch
                                            id={`srtRequestBike${item.TravelSegmentID}_${passengerId}`}
                                            checked={enabled}
                                            onChange={handlePassengerBikeToggle}
                                            value={price.toString()}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-4 col-lg-4">
                                {formattedPrice}
                            </div>
                        </div>
                    );

                    // Bike for Passenger
                    let bikeForPassenger = null;
                    const segmentReservation = selectedBikeReservations?.[item.TravelSegmentID] ?? null;
                    const selectedReservation = segmentReservation?.[passengerId] ?? null;
                    const currency = bikeReservations?.[0]?.currency ?? '';
                    const price = getPriceAndBikeAvailability(bikeReservations, item.TravelSegmentID);
                    if (price) {
                        const disabled = (selectedReservation && selectedReservation.enablePassengerBikes)
                            ? !selectedReservation.enablePassengerBikes : true;
                        bikeForPassenger = renderBikeForPassenger(price, formatPrice(price, currency, props.intl), !disabled);
                    } else {
                        bikeForPassenger = (<span>{formatMessage(messages.lblBikeNotSpec)}</span>);
                    }

                    const lblTo = formatMessage(messages.lblTo);

                    return (
                        <div className="row" key={item.TravelSegmentID}>
                            <div className="col-12" styleName="segment journeySegment">
                                {`${item.ShoppingFareDepartStationSegment} ${lblTo} ${item.ShoppingFareArriveStationSegment}`}
                            </div>
                            <div className="col-12" styleName="rightContent">
                                {bikeForPassenger}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

BikeReservation.propTypes = {
    intl: PropTypes.object,
    travelSegments: PropTypes.array.isRequired,
    bikeReservations: PropTypes.array,
    selectedBikeReservations: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    passengerId: PropTypes.string,
    onChangeBikeReservation: PropTypes.func,
};

export { BikeReservation as BikeReservationAlias };

export default injectIntl(BikeReservation);
