import React from 'react';
import PropTypes from 'prop-types';
import './fareItinerary.css';

export default function TwoColRow({
    firstColLabel,
    secondColLabel,
    styleNameRow = 'station',
    warnings,
}) {
    const allWarnings = warnings?.map((warning) => (<div styleName="warning" key={warning}>{warning.warningMessage}</div>));
    return (
        <div className="row" styleName={styleNameRow}>
            <div className="col-8 col-lg-7 col-xl-8">
                {firstColLabel}
                {allWarnings}
            </div>
            <div className="col-4 col-lg-5 col-xl-4" styleName="rightAlign">
                {secondColLabel}
            </div>
        </div>
    );
}

TwoColRow.propTypes = {
    firstColLabel: PropTypes.node,
    secondColLabel: PropTypes.node,
    styleNameRow: PropTypes.string,
    warnings: PropTypes.array,
};
