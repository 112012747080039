import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fromJS } from 'immutable';
import validate from 'validate.js';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { paymentMethodsTypes } from '../../../constants/constants';
import { fetchBookingDetails } from '../../ManageBooking/actionsManageBooking';
import { submitPaymentApi } from '../../apiBooking';
import {
    confirmationOptions,
    findConfirmationValues,
    getConditionsOfCarriageMap,
    handleAutoEmailConfirmation,
} from '../../utils';
import { formatPrice } from '../../../utils';
import MakePayment from './MakePayment';
import messages from './messagesMakePayment';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import inlineStyles from './styles';
import { paymentConstraints, validateConfirmationHelper } from './makePaymentDialogConstraints';
import { gaEvent } from '../../../utils/googleAnalytics';

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        ...inlineStyles.dialogPaper,
    },
}));

const StyledDialogContent = styled(DialogContent)({
    ...inlineStyles.dialogContent,
});

class MakePaymentDialog extends Component {
    static propTypes = {
        addressTypes: PropTypes.object,
        booking: PropTypes.object,
        cocMap: PropTypes.object,
        configBasedAuth: PropTypes.bool,
        countries: PropTypes.object,
        disableCustomerEmailSending: PropTypes.bool,
        exchangeMode: PropTypes.bool,
        id: PropTypes.string,
        ignoreDisableEmailSendingSuppliers: PropTypes.array,
        intl: PropTypes.object,
        onClose: PropTypes.func.isRequired,
        onExchangePay: PropTypes.func,
        onPaymentSuccess: PropTypes.func,
        open: PropTypes.bool.isRequired,
        provinces: PropTypes.object,
        states: PropTypes.object,
        exchangeBalance: PropTypes.string,
        handleWarning: PropTypes.func,
    };

    static defaultProps = {
        id: 'srtMakePayment',
        disableCustomerEmailSending: false,
        ignoreDisableEmailSendingSuppliers: [],
        exchangeMode: false,
    };

    initialConfirmationState = {
        type: '',
        method: '',
        firstName: '',
        middleName: '',
        lastName: '',
        cardNumber: '',
        cardNumberFirstSixDigits: '',
        cardNumberLastFourDigits: '',
        expiryDateDay: '1',
        expiryDateMonth: '1',
        expiryDateYear: '',
        sortCode: '',
        loyaltyCardProgram: '',
        loyaltyCardIdentifier: '',
        vdEmail: '',
        showAlternateEmail: false,
        isCardTypeAvailable: false,
        language: '',
    };

    initialState = {
        method: '',
        cardName: '',
        cardNumber: '',
        cardNumberFirstSixDigits: '',
        cardNumberLastFourDigits: '',
        expiryDateMonth: '',
        expiryDateYear: '',
        startDateMonth: '',
        startDateYear: '',
        issueNumber: '',
        securityCode: '',
        token: '',
        countryCode: null,
        address1: '',
        address2: '',
        city: '',
        postalCode: '',
        addressType: 'BUSINESS',
        stateOrProvince: '',
        paymentDue: {},
        errors: {},
        alertText: '',
        warning: '',
        bookingBillingWarningMessages: '',
        isFetching: false,
        isProcessingSubmit: false,
        confirmation: this.initialConfirmationState,
        termsOfServiceCheck: false,
        account: undefined,
        hideOnAccount: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            data: fromJS(this.initialState),
        };
    }

    setConfirmationValues = (confirmationInfo, type) => {
        const availableTypes = confirmationInfo.BookingPaymentConfirmationOptions.map((option) => option.BookingPaymentConfirmationType);

        const isCardTypeAvailable = availableTypes.includes(confirmationOptions.debitCard)
            || availableTypes.includes(confirmationOptions.creditCard)
            || availableTypes.includes(confirmationOptions.loyaltyCard);

        const confirmationType = type || availableTypes[0];

        const optionValues = findConfirmationValues(confirmationType, confirmationInfo);
        const refDate = new Date(Date.now());
        const cardTypeValue = (optionValues && optionValues.BookingPaymentConfirmationValues)
            ? optionValues.BookingPaymentConfirmationValues[0].BookingPaymentConfirmationValueType
            : '';

        return {
            type: confirmationType,
            method: (optionValues && optionValues.BookingPaymentConfirmationType !== confirmationOptions.loyaltyCard)
                ? cardTypeValue : '',
            loyaltyCardProgram: (optionValues && optionValues.BookingPaymentConfirmationType === confirmationOptions.loyaltyCard)
                ? cardTypeValue : '',
            language: (confirmationInfo.BookingPaymentConfirmationTicketLanguages.length)
                ? confirmationInfo.BookingPaymentConfirmationTicketLanguages[0].BookingPaymentConfirmationTicketLanguageType : '',
            expiryDateYear: `${refDate.getFullYear()}`,
            expiryDateMonth: `${refDate.getMonth() + 1}`,
            expiryDateDay: `${refDate.getDate() + 1}`,
            isCardTypeAvailable,
        };
    };

    getBraintreeNonceFunctionRef = (getBraintreeNonce) => {
        this.getBraintreeNonce = getBraintreeNonce;
    }

    handleInit = (paymentInformation, account) => {
        const refDate = new Date(Date.now());
        const paymentDue = paymentInformation;

        const confirmationInfo = Object.assign(
            this.initialConfirmationState,
            this.setConfirmationValues(paymentDue.BookingConfirmationInformation),
        );

        confirmationInfo.vdEmail = (!this.props.exchangeMode && paymentDue.BookingEmailForClaimVD && paymentDue.BookingEmailForClaimVD?.length > 0)
            ? paymentDue.BookingEmailForClaimVD[0] : '';
        const paymentMethod = paymentDue.BookingPaymentAcceptablePaymentTypes[0]
            && paymentDue.BookingPaymentAcceptablePaymentTypes[0].BookingPaymentCode;
        const hasOnaccount = paymentDue.BookingPaymentAcceptablePaymentTypes.find((paymentMethodsType) => paymentMethodsType.BookingPaymentCode === paymentMethodsTypes.onaccount);

        paymentDue.paymentMethods = paymentDue.BookingPaymentAcceptablePaymentTypes.map((item) => {
            let suChargeFee = '';
            if (paymentDue.BookingPaymentHasCreditCardSurchargeFee) {
                suChargeFee = ` (${formatPrice(
                    Number(item.BookingPaymentCreditCardSurchargeFeeValue),
                    item.BookingPaymentCreditCardSurchargeFeeCurrency,
                    this.props.intl,
                )})`;
            }

            return {
                code: item.BookingPaymentCode,
                name: `${item.BookingPaymentName}${suChargeFee}`,
                type: item.BookingPaymentType,
            };
        });

        const paymentAccountData = (account && hasOnaccount)
            ? {
                cardName: account.AccountGroupPaymentInfoCardholder ?? '',
                cardNumber: account.AccountGroupPaymentInfoCardNumber ?? '',
                securityCode: account.AccountGroupPaymentInfoSecurityCode ?? '',
            }
            : {};

        const hideOnAccount = !!(paymentAccountData
            && paymentAccountData.cardName
            && paymentAccountData.cardNumber
            && paymentAccountData.securityCode);

        const errors = paymentDue.paymentMethods.length
            ? {}
            : { payment: this.props.intl.formatMessage(messages.errNoPaymentMethods) };

        this.initialState = {
            ...this.initialState,
            paymentDue,
            method: paymentMethod,
            expiryDateMonth: `${refDate.getMonth() + 1}`,
            expiryDateYear: `${refDate.getFullYear()}`,
            addressType: 'BUSINESS',
            confirmation: confirmationInfo,
            account,
            errors,
            hideOnAccount,
        };

        const updates = { ...this.initialState, ...paymentAccountData };

        this.setState((state) => ({
            data: state.data.merge({
                ...updates,
                isFetching: false,
            }),
        }));
    };

    isTermsOfServiceVisible = (type) => {
        const visibleOptions = [
            confirmationOptions.none,
            confirmationOptions.confirmed,
            confirmationOptions.manualNoInfo,
            confirmationOptions.creditCard,
            confirmationOptions.debitCard,
            confirmationOptions.loyaltyCard,
        ];
        return visibleOptions.includes(type);
    };

    // TODO update all components to use (path, values) signature for onChange method
    handleChangeField = (updates, path) => {
        this.setState((state) => {
            const { data } = state;
            let pendingChanges = updates;
            if (updates.type) {
                const hasCardTypeAvailable = data.getIn(['confirmation', 'isCardTypeAvailable']);
                if (hasCardTypeAvailable && updates.type === confirmationOptions.manualNoInfo) return null;
                pendingChanges = Object.assign(
                    pendingChanges,
                    this.setConfirmationValues(data.getIn(['paymentDue', 'BookingConfirmationInformation']).toJS(), pendingChanges.type),
                    { errors: {} },
                );
            }
            const newData = path ? data.mergeIn(path, pendingChanges) : data.merge(pendingChanges);
            return { data: newData };
        });
    };

    handleSnackBarClose = () => {
        this.setState((state) => ({ data: state.data.merge({ alertText: '', warning: '' }) }));
    };

    handleClosePaymentDialog = (queryItems) => {
        const { onPaymentSuccess, onClose } = this.props;

        if (this.state.data.get('bookingBillingWarningMessages').length) {
            onPaymentSuccess(queryItems).then(() => {
                setTimeout(() => {
                    onClose();
                }, 6000);
            });
        } else {
            onPaymentSuccess(queryItems).then(() => { onClose(); });
        }
    };

    handleSubmitPayment = async () => {
        const { data } = this.state;
        const {
            exchangeMode,
            onExchangePay,
            booking,
            disableCustomerEmailSending,
            intl: { formatMessage },
        } = this.props;
        this.setState((state) => ({ data: state.data.merge({ isProcessingSubmit: true, errors: {} }) }));
        const dataState = data.toJS();
        const isBraintree = dataState.method === paymentMethodsTypes.braintree;
        const updates = {
            isProcessingSubmit: false,
            errors: validate(dataState, paymentConstraints(formatMessage, this.isTermsOfServiceVisible, isBraintree), { fullMessages: false }) || {},
        };
        let confirmationErrors;
        if (!exchangeMode) {
            const hasEmail = dataState.paymentDue.BookingEmailForClaimVD && dataState.paymentDue.BookingEmailForClaimVD?.length > 0;
            const confirmationConstraints = validateConfirmationHelper(dataState.confirmation.type, hasEmail, formatMessage);
            confirmationErrors = validate(dataState.confirmation, confirmationConstraints, { fullMessages: false });
        }

        if (isBraintree) {
            if (this.getBraintreeNonce) {
                try {
                    const payload = await this.getBraintreeNonce({
                        cardholderName: dataState.cardName,
                        billingAddress: {
                            postalCode: dataState.postalCode,
                            streetAddress: dataState.address1,
                            locality: dataState.city,
                            countryCode: dataState.countryCodeAlpha2,
                        },
                    });
                    dataState.braintreeNonce = payload.nonce;
                } catch (err) {
                    updates.errors.braintree = err;
                }
            } else {
                updates.errors.braintree = true;
            }
        }

        if (!validate.isEmpty(confirmationErrors)) {
            updates.errors = { ...updates.errors, confirmation: confirmationErrors };
            if (updates.errors.termsOfServiceCheck) updates.alertText = updates.errors.termsOfServiceCheck;
        }

        if (!validate.isEmpty(updates.errors)) {
            this.setState((state) => ({
                data: state.data.merge(updates),
            }));
        } else if (exchangeMode) {
            onExchangePay(dataState);
        } else {
            const { queryItems } = booking;
            updates.errors = {};

            submitPaymentApi(
                {
                    payment: dataState,
                    booking,
                },
                (response) => {
                    updates.alertText = response.errorResponse.message;

                    this.setState((state) => ({
                        data: state.data.merge(updates),
                    }));
                },
                (successRes) => {
                    this.setState((state) => ({
                        data: state.data.merge({
                            alertText: 'Payment Success',
                            isProcessingSubmit: true,
                        }),
                    }));

                    if (successRes.successResponse?.data.BookingBillingWarningMessages?.length) {
                        const joinedMsg = successRes.successResponse.data.BookingBillingWarningMessages.join('\n');
                        updates.bookingBillingWarningMessages = joinedMsg;
                    } else {
                        updates.bookingBillingWarningMessages = '';
                    }

                    const confirmationType = dataState.confirmation.type;
                    const isConfirmed = booking.BookingOrders.length <= 1
                        && ![confirmationOptions.manualEach, confirmationOptions.confirmed, confirmationOptions.manual].includes(confirmationType);
                    const sendEmail = isConfirmed && (booking.BookingOrders[0]?.BookingOrderStatus === 'BOOKED' || booking.BookingOrders[0]?.BookingOrderStatus === 'TICKETED');

                    if (!disableCustomerEmailSending && isConfirmed && sendEmail) {
                        const userEmail = dataState.confirmation.vdEmail;
                        handleAutoEmailConfirmation(
                            userEmail,
                            booking,
                        ).then((response) => {
                            this.setState((state) => ({ data: state.data.merge(Object.assign(updates, response)) }));
                            if (updates.warning !== '') this.props.handleWarning(updates.warning);
                            if (updates.alertText === '') this.handleClosePaymentDialog(queryItems);
                        });
                    } else {
                        this.handleClosePaymentDialog(queryItems);
                    }
                },
            );
        }
    };

    render() {
        const {
            addressTypes,
            booking,
            cocMap,
            configBasedAuth,
            countries,
            disableCustomerEmailSending,
            exchangeMode,
            ignoreDisableEmailSendingSuppliers,
            onClose,
            open,
            provinces,
            states,
            exchangeBalance,
        } = this.props;
        const cancelAction = () => {
            gaEvent(exchangeMode ? 'exchangeSubmitPaymentCancel' : 'orderSubmitPaymentCancel');
            onClose();
        };
        const data = this.state.data.toJS();
        const disabled = (data.isFetching || data.isProcessingSubmit);
        const actions = (
            <div className="row" style={inlineStyles.dialogActionsRoot}>
                <div className="col-12 col-sm-2 offset-sm-6">
                    <Button
                        variant="contained"
                        id="srtMakePaymentClose"
                        onClick={cancelAction}
                        fullWidth
                        disabled={disabled}
                    >
                        <FormattedMessage {...messages.btnCancel} />
                    </Button>
                </div>
                <div className="col-12 col-sm-4">
                    <Button
                        variant="contained"
                        id="srtMakePaymentSubmit"
                        onClick={this.handleSubmitPayment}
                        fullWidth
                        color="primary"
                        disabled={disabled || !!data.errors.payment || !!data.errors.accountGroup}
                    >
                        <FormattedMessage {...messages.btnSubmit} />
                    </Button>
                </div>
            </div>
        );

        return (
            <div>
                <StyledDialog
                    open={open}
                    onClose={cancelAction}
                    disableEnforceFocus
                    maxWidth={false}
                >
                    <DialogTitle><FormattedMessage {...messages.lblTitle} /></DialogTitle>
                    <StyledDialogContent>
                        <MakePayment
                            booking={booking}
                            exchangeBalance={exchangeBalance}
                            paymentInfo={data}
                            addressTypes={addressTypes}
                            countries={countries}
                            states={states}
                            provinces={provinces}
                            cocMap={cocMap}
                            onChange={this.handleChangeField}
                            onInit={this.handleInit}
                            showTermsOfService={this.isTermsOfServiceVisible(data.confirmation.type)}
                            disableCustomerEmailSending={disableCustomerEmailSending}
                            ignoreDisableEmailSendingSuppliers={ignoreDisableEmailSendingSuppliers}
                            exchangeMode={exchangeMode}
                            configBasedAuth={configBasedAuth}
                            getBraintreeNonceFunctionRef={this.getBraintreeNonceFunctionRef}
                            braintreeClientToken={data.paymentDue.BookingPaymentClientToken}
                        />
                    </StyledDialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </StyledDialog>
                <ExtendedSnackbar
                    id="srtBookingPaymentSnackBar"
                    open={(data.alertText !== '') && (data.bookingBillingWarningMessages === '')}
                    message={data.alertText}
                    onClose={this.handleSnackBarClose}
                />
                {data.bookingBillingWarningMessages !== ''
                    && (
                        <ExtendedSnackbar
                            id="srtBookingBillingWarningMessages"
                            open={data.bookingBillingWarningMessages !== ''}
                            message={data.bookingBillingWarningMessages}
                            onClose={() => {
                                this.setState((state) => ({ data: state.data.merge({ bookingBillingWarningMessages: '' }) }));
                            }}
                        />
                    )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    addressTypes: state.getIn(['settings', 'AddressTypeList']),
    countries: state.getIn(['settings', 'BillingCountries']),
    states: state.getIn(['settings', 'StateProvinceList']),
    provinces: state.getIn(['settings', 'CanadianProvincesList']),
    cocMap: getConditionsOfCarriageMap(state.getIn(['settings', 'ws.interface.conditionsofcarriage_fqps']),
        state.getIn(['shopping', 'query', 'locale'])),
    disableCustomerEmailSending: state.getIn(['settings', 'ws.feature.disable_customer_email_sending']) === 'true',
    ignoreDisableEmailSendingSuppliers: state.getIn(['settings', 'ws.feature.ignore_disable_email_sending_suppliers'])
        ? state.getIn(['settings', 'ws.feature.ignore_disable_email_sending_suppliers']).split(',').map((item) => (item)) : null,
    configBasedAuth: state.getIn(['settings', 'ws.interface.config_based.authentication_enabled']) === 'true',
});

const mapDispatchToProps = (dispatch) => ({
    onPaymentSuccess: bindActionCreators(fetchBookingDetails, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MakePaymentDialog));
