import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { injectIntl, FormattedMessage } from 'react-intl';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import DatetimePicker from '../../../../components/DateTimePicker/DateTimePicker';
import DateOfBirth from '../../../../components/DateOfBirth/DateOfBirth';
import ReactSelectWrapped from '../../../../components/ReactSelectWrapped/ReactSelectWrapped';

import messages from './messagesTravelDocs';

const genderList = ['Male', 'Female'];

const PassportView = (props) => {
    const { formatMessage } = props.intl;
    const {
        countries, travelDocsValues, travelDocTypes, disabled, errors, idPrefix, index, ...other
    } = props;
    const countriesList = fromJS([{ code: null, country: formatMessage(messages.lblCountrySelect) }]).concat(countries);
    const docTypes = fromJS([{ value: '', description: formatMessage(messages.lblDocumentTypeSelect) }]).concat(travelDocTypes);

    const handleChangeField = (event) => {
        props.onChange(event.target.name, event.target.value);
    };

    const handleDOEChange = (date) => {
        props.onChange('documentExpDate', date);
    };

    const handleChangeCountry = ({ value }) => {
        props.onChange('issueCountry', value);
    };

    const handleChangeGender = ({ target: { value } }) => {
        props.onChange('gender', value);
    };

    const handleChangeDocType = ({ target: { value } }) => {
        props.onChange('documentType', value || null);
    };

    return (
        <div className="row">
            <div className="col-6">
                <TextField
                    id={`${idPrefix}Gender_${index}`}
                    label={<FormattedMessage {...messages.lblGender} />}
                    fullWidth
                    onChange={handleChangeGender}
                    value={travelDocsValues.gender}
                    disabled={disabled}
                    select
                    SelectProps={{ displayEmpty: true }}
                    variant="standard"
                >
                    {genderList.map((item, ind) => (
                        <MenuItem
                            id={`${idPrefix}_${item}`}
                            key={ind}
                            value={item.toUpperCase()}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div className="col-6">
                <DateOfBirth
                    {...other}
                    errorText={errors.DOB}
                    dateOfBirth={travelDocsValues.DOB}
                />
            </div>
            <span className="clearfix" />
            <div className="col-6">
                <TextField
                    id={`${idPrefix}DocumentType_${index}`}
                    label={<FormattedMessage {...messages.lblDocumentType} />}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    onChange={handleChangeDocType}
                    error={!!errors.documentType}
                    helperText={errors.documentType}
                    value={travelDocsValues.documentType || ''}
                    disabled={disabled}
                    select
                    SelectProps={{ displayEmpty: true }}
                    variant="standard"
                >
                    {docTypes.map((item, ind) => (
                        <MenuItem
                            id={`${idPrefix}_${item.get('description')}`}
                            key={ind}
                            value={item.get('value')}
                        >
                            {item.get('description')}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div className="col-6">
                <TextField
                    id={`${idPrefix}DocumentNumber_${index}`}
                    name="documentNumber"
                    label={<FormattedMessage {...messages.lblDocumentNumber} />}
                    fullWidth
                    onChange={handleChangeField}
                    error={!!errors.documentNumber}
                    helperText={errors.documentNumber}
                    value={travelDocsValues.documentNumber}
                    disabled={disabled}
                />
            </div>
            <span className="clearfix" />
            <div className="col-6">
                <ReactSelectWrapped
                    id={`${idPrefix}IssueCountry_${index}`}
                    label={<FormattedMessage {...messages.lblCountry} />}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    onChange={handleChangeCountry}
                    error={!!errors.issueCountry}
                    helperText={errors.issueCountry}
                    value={travelDocsValues.issueCountry}
                    disabled={disabled}
                    options={countriesList.toJS().map((item) => ({
                        value: item.code,
                        label: item.country,
                    }))}
                    hasEmpty
                    windowing
                />
            </div>
            <div className="col-6">
                <DatetimePicker
                    id={`${idPrefix}DocumentExpDate_${index}`}
                    name="documentExpDate"
                    label={<FormattedMessage {...messages.lblDocumentExpDate} />}
                    fullWidth
                    errorText={errors.documentExpDate}
                    value={travelDocsValues.documentExpDate}
                    onChange={handleDOEChange}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

PassportView.propTypes = {
    travelDocsValues: PropTypes.shape({
        gender: PropTypes.string,
        DOB: PropTypes.instanceOf(Date),
        documentType: PropTypes.string,
        documentNumber: PropTypes.string,
        issueCountry: PropTypes.string,
        documentExpDate: PropTypes.instanceOf(Date),
    }).isRequired,
    travelDocTypes: PropTypes.object.isRequired,
    countries: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    idPrefix: PropTypes.string,
    index: PropTypes.number,
    intl: PropTypes.object,
};

// This alias will be used to access bare component for unit testing
export { PassportView as PassportViewAlias };

export default injectIntl(PassportView);
