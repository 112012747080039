import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import messages from './messagesSelectedFareSummary';
import FaresSelectedView from '../../../components/FaresSelectedView';
import { extractOrderDetails } from '../../../util';
import { formatPrice } from '../../../../utils';
import inlineStyles from './styles';

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        ...inlineStyles.dialogPaper,
    },
}));

const SelectedFareSummary = ({
    intl, open, onClearSelection, onCheckout, selectedFares, ...other
}) => {
    const { formatMessage } = intl;
    const orderInfo = extractOrderDetails(selectedFares);
    const selectedFareSummaryActions = (
        <div className="row" style={inlineStyles.dialogActionsRoot}>
            <div className="col-4 offset-4">
                <Button
                    variant="contained"
                    id="srtChangeSelectionFareSummary"
                    style={inlineStyles.buttons}
                    onClick={onClearSelection}
                    fullWidth
                >
                    {formatMessage(messages.lblChangeSelection)}
                </Button>
            </div>
            <div className="col-4">
                <Button
                    variant="contained"
                    id="srtContinueCheckoutFareSummary"
                    color="primary"
                    style={inlineStyles.buttons}
                    onClick={onCheckout}
                    fullWidth
                >
                    {formatMessage(messages.lblCheckout)}
                </Button>
            </div>
        </div>
    );
    const currency = selectedFares.first().get('fare').get('currency');
    const formattedPrice = selectedFares.size > 0 ? formatPrice(orderInfo.orderTotalPrice, currency, intl) : '';

    return (
        <StyledDialog
            open={open}
            onClose={onClearSelection}
            disableEnforceFocus
            maxWidth={false}
        >
            <DialogTitle>
                <div className="row">
                    <div className="col-6">
                        {formatMessage(messages.lblTitle)}
                    </div>
                    <div className="col-6" style={inlineStyles.titleTotal}>
                        {`${formatMessage(messages.lblTotal)} ${formattedPrice}`}
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <FaresSelectedView
                    id="srtFaresSelectedSummary"
                    selectedFares={selectedFares}
                    onChangeSelection={onClearSelection}
                    {...other}
                />
            </DialogContent>
            <DialogActions>
                {selectedFareSummaryActions}
            </DialogActions>
        </StyledDialog>
    );
};

SelectedFareSummary.propTypes = {
    intl: PropTypes.object,
    open: PropTypes.bool,
    onClearSelection: PropTypes.func,
    onCheckout: PropTypes.func,
    selectedFares: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    departDateString: PropTypes.string,
    returnDateString: PropTypes.string,
    inboundMsg: PropTypes.string,
    outboundMsg: PropTypes.string,
    accountDetails: PropTypes.object,
    tripType: PropTypes.number,
};

export { SelectedFareSummary as SelectedFareSummaryAlias };
export default injectIntl(SelectedFareSummary);
