import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import AssocAutoCompleteList from './AssocAutoCompleteList';
import ReactSelectWrapped from '../ReactSelectWrapped/ReactSelectWrapped';

export default class AssocAutoComplete extends PureComponent {
    getFilteredOptions = () => {
        const { values, options } = this.props;
        const valuesValues = values.map((value) => JSON.stringify(value.value));
        return options.filter((option) => valuesValues.indexOf(JSON.stringify(option.value)) === -1);
    }

    removeOption = (removedIndex) => {
        const { onChange, values } = this.props;
        onChange(values.filter((value, index) => index !== removedIndex));
    }

    addOption = (item) => {
        const { onChange, values } = this.props;
        onChange(values.concat(item));
    }

    render() {
        const {
            errorText,
            headerTitle,
            icon,
            id,
            isLoading,
            label,
            loadOptions,
            placeholder,
            searchValue,
            values,
            dataTestId,
        } = this.props;

        const assocValues = !!values.length && (
            <AssocAutoCompleteList
                id={`${id}Item`}
                values={values}
                headerLabel={headerTitle}
                removeOption={this.removeOption}
                icon={icon}
            />
        );

        const filteredOptions = this.getFilteredOptions();

        return (
            <>
                <ReactSelectWrapped
                    dataTestId={dataTestId}
                    id={`${id}AutoComplete`}
                    options={filteredOptions}
                    getOptionLabel={(option) => option.text}
                    fullWidth
                    maxSearchResults={10}
                    error={!!errorText}
                    helperText={errorText}
                    placeholder={placeholder}
                    onChange={this.addOption}
                    label={label}
                    innerRef={(ref) => { this.refAutocomplete = ref; }}
                    value={null}
                    deleteDropdownIndicator
                    openMenuOnClick={false}
                    fastFilter={!loadOptions}
                    fastFilterSearchValue={searchValue}
                    backspaceRemovesValue={false}
                    isLoading={isLoading}
                    loadOptions={loadOptions}
                />
                {assocValues}
            </>
        );
    }
}

AssocAutoComplete.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.array,
    values: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    headerTitle: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    errorText: PropTypes.string,
    searchValue: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadOptions: PropTypes.func,
    dataTestId: PropTypes.string,
};

AssocAutoComplete.defaultProps = {
    options: [],
};
