import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ListSubheader from '@mui/material/ListSubheader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import People from '@mui/icons-material/People';
import Account from '@mui/icons-material/AccountBalance';
import validate from 'validate.js';
import moment from 'moment';

import { getDateTimeFormat24H } from '../../utils/datetimeUtils';

import AssocAutoComplete from '../../components/AssocAutoComplete/AssocAutoComplete';
import ReactSelectWrapped from '../../components/ReactSelectWrapped/ReactSelectWrapped';
import RefreshIndicator from '../../components/RefreshIndicator/RefreshIndicator';
import SelectLocale from '../../components/SelectLocale/SelectLocale';
import Confirm from '../../components/Confirm/Confirm';
import HomeLink from './HomeLink';
import messages from '../messagesAdmin';

import styles from './userStyles';
import SelectUserStatus from './SelectUserStatus';


const INIT_DATA = fromJS({
    acceptTNC: "",
    username: "",
    firstName: "",
    lastName: "",
    businessEmail: "",
    locale: "",
    originalLocale: "",
    assignedRole: "",
    role: "",
    userRoles: [],
    suppliers: [],
    sendWelcomeEmail: true,
    assocAccounts: [],
    assocAccGroups: [],
    showConfirm: false,
    errors: {},
    allAccountsGroup: [],
    allAccounts: [],
});


const User = ({
    addMode,
    handleCancel,
    handleDelete,
    handleSubmit,
    processingAction,
    suppliers,
    user,
    locale,
    intl,
}) => {
    const [data, setData] = useState(INIT_DATA);

    useEffect(() => {
        if (user) {
            setData((prevData) => prevData.merge(fromJS(user)));
        }
    }, [user]);


    function handleRoleChange({ value }) {
        setData((prevData) => prevData.set('role', value));
    }

    function handleUsernameChange({ target: { value } }) {
        setData((prevData) => prevData.set("username", value));
    }

    function handleEmailChange({ target: { value } }) {
        setData((prevData) => prevData.set("businessEmail", value));
    }

    function handleLocaleChange({ target: { value } }) {
        setData((prevData) => prevData.set("locale", value));
    }

    function handleFirstNameChange({ target: { value } }) {
        setData((prevData) => prevData.set("firstName", value));
    }

    function handleLastNameChange({ target: { value } }) {
        setData((prevData) => prevData.set("lastName", value));
    }

    function handleKeyDown(event) {
        if (event.keyCode === 13) {
            handleLocalSubmit();
        }
    }

    function handleLocalSubmit() {
        const constraints = {
            businessEmail: {
                presence: {
                    message: intl.formatMessage(messages.errEmptyEmail),
                    allowEmpty: false,
                },
                email: {
                    message: intl.formatMessage(messages.errInvalidEmail),
                },
            },
            username: {
                presence: {
                    message: intl.formatMessage(messages.errEmptyUsername),
                    allowEmpty: false,
                },
            },
            role: {
                presence: {
                    message: intl.formatMessage(messages.errEmptyUserRole),
                    allowEmpty: false,
                },
            },
        };

        let newErrors = validate(data.toJS(), constraints);

        if (newErrors) {
            setData((prevData) =>
                prevData.withMutations((mutableData) => {
                    mutableData.setIn(["errors", "businessEmail"], newErrors.businessEmail || undefined);
                    mutableData.setIn(["errors", "username"], newErrors.username || undefined);
                    mutableData.setIn(["errors", "role"], newErrors.role || undefined);
                    return mutableData;
                })
            );
        } else {
            setData((prevData) =>
                prevData.withMutations((mutableData) => {
                    mutableData.set("errors", {});
                    return mutableData;
                })
            );

            handleSubmit(data);
        }
    }

    function handleLocalCancel() {
        setData(INIT_DATA);
        handleCancel();
    }

    function handleSupplierToggle(event, checked, item) {
        const idx = data.get('suppliers').indexOf(item.get('value'));
        setData((prevData) => prevData.update('suppliers', (list) => (checked ? list.push(item.get('value')) : list.delete(idx))));
    }

    function handleVoidExceptionToggle(event, checked) {
        setData((prevData) => prevData.set('userAllowedVoidException', checked ? 'true' : 'false'));
    }

    function handleGroupChange(groups) {
        setData((prevData) => prevData.set('assocAccGroups', fromJS(groups.map((group) => group.value))));
    }

    function handleAcctChange(accounts) {
        setData((prevData) => prevData.set('assocAccounts', fromJS(accounts.map((account) => account.value))));
    }

    function handleUserStatusChange({ target: { value } }) {
        setData((prevData) => prevData.set('userStatus', value));
    }

    const { formatMessage } = intl

    const selectRoles = (
        <ReactSelectWrapped
            id="srtUserRole"
            value={data.get('role')}
            onChange={handleRoleChange}
            label={formatMessage(messages.lblUserRole)}
            fullWidth
            error={!!data.getIn(['errors', 'role'])}
            helperText={data.getIn(['errors', 'role'])}
            options={data.get('userRoles').toJS().map((item) => ({
                value: item,
                label: item,
            }))}
            windowing
        />
    );

    const userStatus = data.get('userStatus');
    const formattedUserStatus = userStatus || 'Active';
    const userStatusElement = (
        <SelectUserStatus
            id="srtSelectUserStatus"
            value={formattedUserStatus}
            locale={locale}
            onChange={handleUserStatusChange}
        />
    );
    const userAllowedVoidExceptionElement = (
        <FormControlLabel
            label={formatMessage(messages.lblUserAllowedVoidException)}
            style={styles.labelWithMargins}
            control={(
                <Switch
                    id="srtUserAllowedVoidException"
                    checked={data.get('userAllowedVoidException') === 'true'}
                    onChange={(event, checked) => handleVoidExceptionToggle(event, checked)}
                />
            )}
        />
    );
    const lastSuccessfulLogin = data.get('lastSuccessfulLogin');
    const userLastSuccessfulLogin = !addMode && (
        <TextField
            id="srtUserLastSuccessfulLoginTime"
            label={formatMessage(messages.lblLastLoginTime)}
            value={
                lastSuccessfulLogin ? moment(lastSuccessfulLogin, getDateTimeFormat24H(intl, true)).utc(lastSuccessfulLogin).utcOffset(moment().utcOffset()).format('MMMM DD, YYYY HH:mm:ss') : '--'
            }
            fullWidth
            disabled
            variant="standard"
        />
    );
    const selectSuppliers = (
        <div className="row">
            <div className="col-12">
                <div className="row">
                    <div className="col-6">
                        <ListSubheader style={styles.subheader}>{formatMessage(messages.lblSuppliers)}</ListSubheader>
                    </div>
                    <div className="col-2">
                        {userStatusElement}
                    </div>
                </div>
            </div>
            <div className="col-6">
                {suppliers?.map((item, index) => (
                    <FormControlLabel
                        key={item.get('value')}
                        label={item.get('label')}
                        style={styles.label}
                        control={(
                            <Switch
                                id={`srt${item.get('label').charAt(0).toUpperCase() + item.get('label').slice(1)}_${index}`}
                                checked={data.get('suppliers').includes(item.get('value'))}
                                onChange={(event, checked) => handleSupplierToggle(event, checked, item)}
                            />
                        )}
                    />
                )).toJS()}
            </div>
            <div className="col-4">
                <div className="row">
                    <div className="col-6">{userLastSuccessfulLogin}</div>
                </div>
                <div className="row">
                    <div className="col-12">{userAllowedVoidExceptionElement}</div>
                </div>
            </div>
        </div>
    );


    const deleteButton = !addMode && (data.get('showConfirm')
        ? (
            <Confirm
                onYes={() => {
                    if (handleDelete) handleDelete();
                    setData((prevData) => prevData.set('showConfirm', false));
                }}
                onNo={() => setData((prevData) => prevData.set('showConfirm', false))}
            />
        )
        : (
            <Button
                variant="contained"
                id="srtUserDelete"
                color="primary"
                style={styles.button}
                onClick={() => setData((prevData) => prevData.set('showConfirm', true))}
                disabled={processingAction}
            >
                {formatMessage(messages.btnDeleteUser)}
            </Button>
        ));

    const acceptTNC = !addMode && (
        <TextField
            id="srtUserAcceptTNC"
            value={data.get('acceptTNC')}
            label={formatMessage(messages.lblAcceptTNC)}
            disabled
            fullWidth
            variant="standard"
        />
    );

    const sendWelcomeEmail = addMode && (
        <FormControlLabel
            id="srtSendWelcomeEmailControl"
            label={formatMessage(messages.lblSendWelcomeEmail)}
            control={(
                <Switch
                    id="srtSendWelcomeEmail"
                    checked={data.get('sendWelcomeEmail')}
                    onChange={(event, checked) => setData((prevData) => prevData.set('sendWelcomeEmail', checked))}
                />
            )}
        />
    );

    const assocGroups = (
        <div style={styles.AssocAutoComplete}>
            <AssocAutoComplete
                id="srtUserAccountGroup"
                options={data.get('allAccountsGroup').toJS().map((accountGroup) => ({
                    value: accountGroup,
                    text: accountGroup,
                }))}
                values={data.get('assocAccGroups').toJS().map((accountGroup) => ({
                    value: accountGroup,
                    text: accountGroup,
                }))}
                headerTitle={formatMessage(messages.lblAssocGroups)}
                placeholder={formatMessage(messages.lblAssocGroupsPlaceholder)}
                label={formatMessage(messages.lblAssocGroupsLabel)}
                onChange={handleGroupChange}
                icon={<People />}
            />
        </div>
    );

    const assocAccounts = (
        <div style={styles.AssocAutoComplete}>
            <AssocAutoComplete
                id="srtUserAccount"
                options={data.get('allAccounts').toJS().map((account) => ({
                    value: account.Label,
                    text: account.Label,
                }))}
                values={data.get('assocAccounts').toJS().map((account) => ({
                    value: account,
                    text: account,
                }))}
                headerTitle={formatMessage(messages.lblAssocAccounts)}
                placeholder={formatMessage(messages.lblAssocAccountsPlaceholder)}
                label={formatMessage(messages.lblAssocAccountsLabel)}
                onChange={handleAcctChange}
                icon={<Account />}
            />
        </div>
    );

    const homeLinkButton = !addMode && (
        <HomeLink
            id="srtUserHome"
            className="col-12"
            style={styles.homeLink}
            text={data.get('username')}
            onClick={handleCancel}
        />
    );

    return (
        <div className="row">
            <br />
            <br />
            <div className="col-12" data-testid="user">
                {homeLinkButton}
                {selectSuppliers}
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div>{acceptTNC}</div>
                    </div>
                </div>
                <div>{selectRoles}</div>
                <div>
                    <SelectLocale
                        id="srtUserLocale"
                        value={data.get('locale')}
                        onChange={handleLocaleChange}
                    />
                </div>
                <div>
                    <TextField
                        id="srtUsername"
                        value={data.get('username')}
                        label={formatMessage(messages.lblUsername)}
                        onChange={handleUsernameChange}
                        InputProps={{ onKeyDown: handleKeyDown }}
                        error={!!data.getIn(['errors', 'username'])}
                        helperText={data.getIn(['errors', 'username'])}
                        disabled={!addMode}
                        fullWidth
                        variant="standard"
                    />
                </div>
                <div>
                    <TextField
                        id="srtUserEmail"
                        label={formatMessage(messages.lblEmail)}
                        value={data.get('businessEmail')}
                        onChange={handleEmailChange}
                        InputProps={{ onKeyDown: handleKeyDown }}
                        error={!!data.getIn(['errors', 'businessEmail'])}
                        helperText={data.getIn(['errors', 'businessEmail'])}
                        fullWidth
                        variant="standard"
                    />
                </div>
                <div>
                    <TextField
                        id="srtUserFirstName"
                        label={formatMessage(messages.lblFirstName)}
                        value={data.get('firstName')}
                        onChange={handleFirstNameChange}
                        InputProps={{ onKeyDown: handleKeyDown }}
                        fullWidth
                        variant="standard"
                    />
                </div>
                <div>
                    <TextField
                        id="srtUserLastName"
                        label={formatMessage(messages.lblLastName)}
                        value={data.get('lastName')}
                        onChange={handleLastNameChange}
                        InputProps={{ onKeyDown: handleKeyDown }}
                        fullWidth
                        variant="standard"
                    />
                </div>
                <br />
                <div>{assocGroups}</div>
                <div>{assocAccounts}</div>
                <div>{sendWelcomeEmail}</div>
                <br />
                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                        {deleteButton}
                    </div>
                    <div className="col-12 col-sm-2 offset-sm-2 offset-lg-4 offset-xl-5">
                        <Button
                            variant="contained"
                            id="srtUserCancel"
                            style={styles.button}
                            onClick={handleLocalCancel}
                            disabled={processingAction}
                            fullWidth
                        >
                            {(addMode) ? formatMessage(messages.btnClear) : formatMessage(messages.btnCancel)}
                        </Button>
                    </div>
                    {processingAction ? (
                        <div style={styles.refreshContainer} data-testid="refreshIndicator">
                            <RefreshIndicator
                                style={styles.button}
                                size={36}
                                top={0}
                                left={30}
                                status="loading"
                            />
                        </div>
                    ) : (
                        <div className="col-12 col-sm-2" data-testid="userSubmitButton">
                            <Button
                                variant="contained"
                                id="srtUserSubmit"
                                style={styles.button}
                                color="primary"
                                onClick={handleLocalSubmit}
                                fullWidth
                            >
                                {formatMessage(messages.btnSave)}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

User.propTypes = {
    addMode: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleDelete: PropTypes.func,
    handleSubmit: PropTypes.func,
    processingAction: PropTypes.bool,
    suppliers: PropTypes.object,
    user: PropTypes.object,
    locale: PropTypes.string,
    intl: PropTypes.object,
};

const mapStateToProps = (state) => ({
    locale: state.getIn(['settings', 'ws.system.localization.language_country']),
});

// This alias will be used to access bare component for unit testing
export { User as UserAlias };

export default connect(mapStateToProps, null)(injectIntl(User));
