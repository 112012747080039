export const isTDOElectronic = (tdoCode) => (
    tdoCode === 'PAH' || tdoCode === 'EML' || tdoCode === 'ETK' || tdoCode === 'XVD' || tdoCode.indexOf('SMS') > -1
);

export const isTDOPickup = (tdoCode) => (
    tdoCode === 'TVM' || tdoCode === 'TML' || tdoCode === 'TOF' || tdoCode === 'TBC'
);

export const isTDOPrint = (tdoCode) => (
    ['TOP', 'TVP', 'PRN'].indexOf(tdoCode) >= 0
);

export const isTDOSmartCard = (tdoCode) => (
    tdoCode === 'SCT'
);

export const isTDOMultiTicket = (tdoCode) => (
    tdoCode === 'MTD'
);

export const isTDOETicket = (tdoCode) => (
    tdoCode === 'MVD'
);

export const isTDOMail = (tdoCode) => (
    !isTDOElectronic(tdoCode) && !isTDOPickup(tdoCode) && !isTDOPrint(tdoCode)
    && !isTDOSmartCard(tdoCode) && !isTDOMultiTicket(tdoCode) && !isTDOETicket(tdoCode)
);

export const isTDOAvailAtDepartureStation = (tdOptions, tdoToCheck) => {
    let isAvailable = true;
    tdOptions.forEach((tdo) => {
        const currTDO = tdo.BookingTDOCode;
        if (currTDO === tdoToCheck && !tdo.BookingTDOisTOD) {
            isAvailable = false;
        }
    });
    return isAvailable;
};

export const getTdoByType = (tdOptions) => {
    // sort the options based on Electronic, Pickup at station and Deliver to

    const electronicOptions = [];
    const pickupAtStationOptions = [];
    const mailToOptions = [];
    const printOptions = [];
    const smartCardOptions = [];
    const multiTicketOptions = [];
    const eTicketOptions = [];
    const tdoRegions = [];

    tdOptions.forEach((tdo) => {
        if (isTDOElectronic(tdo.BookingTDOCode)) {
            electronicOptions.push(tdo);
        } else if (isTDOPickup(tdo.BookingTDOCode)) {
            pickupAtStationOptions.push(tdo);
        } else if (isTDOPrint(tdo.BookingTDOCode)) {
            printOptions.push(tdo);
        } else if (isTDOSmartCard(tdo.BookingTDOCode)) {
            smartCardOptions.push(tdo);
        } else if (isTDOMultiTicket(tdo.BookingTDOCode)) {
            multiTicketOptions.push(tdo);
        } else if (isTDOETicket(tdo.BookingTDOCode)) {
            eTicketOptions.push(tdo);
        } else {
            mailToOptions.push(tdo);
            const regionExists = tdoRegions.find((tdoRegion) => (
                tdoRegion === tdo.BookingTDODestination
            ));
            if (!regionExists) {
                tdoRegions.push(tdo.BookingTDODestination);
            }
        }
    });

    return {
        electronicOptions,
        pickupAtStationOptions,
        mailToOptions,
        printOptions,
        smartCardOptions,
        multiTicketOptions,
        eTicketOptions,
        tdoRegions,
    };
};

export const hasSupplier = (supplierCodeToMatch, suppliers) => (
    suppliers.find((supplier) => (
        supplier.BookingSupplierCode.toUpperCase() === supplierCodeToMatch.toUpperCase()
    ))
);

export const findTDOSMSBookingCode = (bookingTDOs) => {
    const tdoSms = bookingTDOs.find((bookingTDO) => (
        bookingTDO.BookingTDOCode.indexOf('SMS') === 0
    ));
    return tdoSms ? tdoSms.BookingTDOCode : null;
};
