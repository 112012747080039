import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { injectIntl } from 'react-intl';

import messages from './messagesContact';

const Contact = ({
    columnClass, values, onChange, errors, index = 0, dataTestId, intl: { formatMessage },
}) => {
    const handleChangeField = (event) => {
        onChange({ [event.target.name]: event.target.value });
    };

    return (
        <div className="row" data-testid={dataTestId}>
            <div className={columnClass}>
                <TextField
                    id={`srtContactPhone${index}`}
                    className="srtContactPhone"
                    name="phone"
                    label={formatMessage(messages.lblPhone)}
                    fullWidth
                    onChange={handleChangeField}
                    error={!!errors.phone}
                    helperText={errors.phone}
                    value={values.phone}
                    variant="standard"
                />
            </div>
            <div className={columnClass}>
                <TextField
                    id={`srtContactEmail${index}`}
                    className="srtContactEmail"
                    name="email"
                    label={formatMessage(messages.lblEmail)}
                    fullWidth
                    onChange={handleChangeField}
                    error={!!errors.email}
                    helperText={errors.email}
                    value={values.email}
                    variant="standard"
                />
            </div>
        </div>
    );
};

Contact.propTypes = {
    columnClass: PropTypes.string.isRequired,
    values: PropTypes.shape({
        phone: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object,
    index: PropTypes.number,
    dataTestId: PropTypes.string,
    intl: PropTypes.object,

};

Contact.defaultProps = {
    index: 0,
    errors: {},
};

export { Contact as ContactAlias };

export default injectIntl(Contact);
