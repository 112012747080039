import Sha256 from 'sha.js/sha256';

import { BASE_PATH_SUFFIX } from '../utils';

export default (action, queryString, body, gaParameters) => {
    const timestampString = parseInt(performance.timing.navigationStart + performance.now(), 10).toString();
    const template = {
        call: {
            dimension1: gaParameters.account,
            dimension2: gaParameters.userId,
            dimension3: timestampString,
        },
        success: {
            dimension1: gaParameters.account,
            dimension2: gaParameters.userId,
            dimension3: timestampString,
        },
        fail: (error) => ({
            label: (error && error.errorResponse && error.errorResponse.message) || '',
            value: (error && error.errorResponse && error.errorResponse.code) || 0,
            dimension1: gaParameters.account,
            dimension2: gaParameters.userId,
            dimension3: timestampString,
        }),
    };

    const exchangeFail = (error) => ({
        ...template.fail(error),
        category: 'Order',
        action: 'Exchange Order Error',
    });

    const options = {
        [`${BASE_PATH_SUFFIX}loginJSONP`]: {
            success: () => ({
                category: 'Authentication',
                action: 'User Login',
                label: 'User Login',
                dimension2: new Sha256().update(body.srtLoginUsername).digest('hex'),
                dimension3: timestampString,
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Authentication',
                action: 'User Login Error',
            }),
        },
        [`${BASE_PATH_SUFFIX}shoppingSearchJSONP`]: {
            success: () => ({
                ...template.success,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Search for Fares',
                dimension1: queryString.srtShoppingContext,
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Shopping',
                action: 'Fare Search Error',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingValidateJSONP`]: {
            success: () => ({
                ...template.success,
                category: queryString.srtExchangeMode ? 'Order' : 'Shopping',
                action: queryString.srtExchangeMode ? 'Exchange Order' : 'Fare Search',
                label: 'Select Fare',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: queryString.srtExchangeMode ? 'Order' : 'Shopping',
                action: queryString.srtExchangeMode ? 'Exchange Order Error' : 'Fare Search Error',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingCreateJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Create Booking',
                label: 'Initiate Create Booking',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Booking',
                action: 'Create Booking Error',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingOrderExchangeDataJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Initiate Exchange Order',
            }),
            fail: exchangeFail,
        },
        requestExchange: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Request Exchange',
            }),
        },
        requestExchangeClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Close Request Exchange Dialog',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingExchangeSearchJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Search for Fares',
            }),
            fail: exchangeFail,
        },
        exchangeSearchFareClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Close Search for Fares',
            }),
        },
        exchangeSelectFareClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Close Select Fare Dialog',
            }),
        },
        exchangeOrder: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Exchange Order',
            }),
        },
        exchangeOrderClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Close Exchange Summary',
            }),
        },
        exchangeOrderModeClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Select Exchange Type',
                label: 'No Selection',
            }),
        },
        exchangeOrderModalContinue: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Continue Exchange Order',
            }),
        },
        exchangeOrderModalClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Cancel Exchange Order',
            }),
        },
        exchangeOrderSwapStations: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Swap Stations',
            }),
        },
        exchangeOrderAddViaAvoidStation: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Add Via/Avoid Station',
            }),
        },
        exchangeOrderRemoveViaAvoidStation: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Remove Via/Avoid Station',
            }),
        },
        exchangeOrderAddDiscountCard: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Add Discount Card',
            }),
        },
        exchangeOrderRemoveDiscountCard: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Remove Discount Card',
            }),
        },
        exchangeOrderViewFareInformation: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'View Fare Information',
            }),
        },
        exchangeOrderViewFareRules: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'View Fare Rule Details',
            }),
        },
        exchangeOrderCloseFareRules: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Close Fare Rule Details',
            }),
        },
        exchangeOrderViewDetails: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'View Itinerary Details',
            }),
        },
        exchangeOrderHideDetails: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Hide Itinerary Details',
            }),
        },
        exchangeOrderTicketDeliveryEdit: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Edit Ticket Delivery',
            }),
        },
        exchangeOrderTicketDeliveryAdd: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Add Ticket Delivery',
            }),
        },
        exchangeOrderTicketDeliverySubmit: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Submit Ticket Delivery',
            }),
        },
        exchangeOrderTicketDeliveryView: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'View Ticket Delivery',
            }),
        },
        exchangeOrderCloseTicketDelivery: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Close Ticket Delivery',
            }),
        },
        exchangeOrderEditSeatReservations: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Edit Seat Reservations',
            }),
        },
        exchangeOrderAddSeatReservations: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Add Seat Reservations',
            }),
        },
        exchangeOrderClearSeatReservations: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Clear Seat Reservation Selections',
            }),
        },
        exchangeOrderDiscardSeatReservations: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Discard Seat Reservations',
            }),
        },
        exchangeOrderRequestSeatReservations: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Request Seat Reservations',
            }),
        },
        exchangeOrderEditTravelcards: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Edit Travelcards',
            }),
        },
        exchangeOrderDiscardTravelcards: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Discard Travelcards',
            }),
        },
        exchangeOrderSaveTravelcards: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Save Travelcards',
            }),
        },
        exchangeOrderSeatMapApplicable: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'SeatMap UX Applicable',
            }),
        },

        exchangeOrderSeatMapNotApplicable: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'SeatMap UX NOT Applicable',
            }),
        },

        exchangeOrderInitiateSeatMap: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Initiate SeatMap UX',
            }),
        },

        exchangeOrderSeatMapRequestSeats: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Request SeatMap UX Seats',
            }),
        },

        exchangeOrderSeatMapDiscardSeats: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Discard SeatMap UX',
            }),
        },

        [`${BASE_PATH_SUFFIX}processExchangeJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Submit Payment',
            }),
            fail: exchangeFail,
        },
        exchangeSubmitPaymentCancel: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Exchange Order',
                label: 'Cancel Submit Payment',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingGetPaymentsDueJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Billing',
                action: 'Add Payment',
                label: 'Initiate Add Payment',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Billing',
                action: 'Add Payment Error',
            }),
        },
        orderSubmitPaymentCancel: {
            call: () => ({
                ...template.call,
                category: 'Billing',
                action: 'Add Payment',
                label: 'Cancel Submit Payment',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingAddPaymentJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Billing',
                action: 'Add Payment',
                label: 'Submit Payment',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Billing',
                action: 'Add Payment Error',
            }),
        },
        cancelOrder: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Cancel Order',
                label: 'Initiate Cancel Order',
            }),
        },
        voidOrder: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Void Order',
                label: 'Initiate Void Order',
            }),
        },
        cancelPenaltyOpen: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View Cancel Penalty',
                label: 'Initiate View Cancel Penalty',
            }),
        },
        cancelPenaltyClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View Cancel Penalty',
                label: 'Close View Cancel Penalty',
            }),
        },
        cancelPenaltyError: {
            call: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'View Cancel Penalty Error',
            }),
        },
        cancelOrderViewFareRules: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Cancel Order',
                label: 'View Fare Rule Details',
            }),
        },
        cancelOrderCloseFareRules: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Cancel Order',
                label: 'Close Fare Rule Details',
            }),
        },
        voidOrderViewFareRules: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Void Order',
                label: 'View Fare Rule Details',
            }),
        },
        voidOrderCloseFareRules: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Void Order',
                label: 'Close Fare Rule Details',
            }),
        },
        cancelOrderClose: {
            call: (mode) => ({
                ...template.call,
                category: 'Order',
                action: 'Cancel Order',
                label: mode ? 'Close Cancel' : 'No Selection',
            }),
        },
        cancelOrderSelectMode: {
            call: (mode) => ({
                ...template.call,
                category: 'Order',
                action: 'Select Cancel Type',
                label: mode === 'all' ? 'Full Cancel' : 'Partial Cancel',
            }),
        },
        voidOrderSelectMode: {
            call: (mode) => ({
                ...template.call,
                category: 'Order',
                action: 'Select Void Type',
                label: mode === 'all' ? 'Full Void' : 'Partial Void',
            }),
        },
        [`${BASE_PATH_SUFFIX}cancelBooking`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: body.srtVoidMode ? 'Void Order' : 'Cancel Order',
                label: body.srtVoidMode ? 'Request Void' : 'Request Cancellation',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: body.srtVoidMode ? 'Void Order Error' : 'Cancel Order Error',
            }),
        },
        voidOrderClose: {
            call: (mode) => ({
                ...template.call,
                category: 'Order',
                action: 'Void Order',
                label: mode ? 'Close Void' : 'No Selection',
            }),
        },
        issueRefundInitiate: {
            call: () => ({
                ...template.call,
                category: 'Billing',
                action: 'Issue Refund',
                label: 'Initiate Issue Refund',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Billing',
                action: 'Issue Refund Error',
            }),
        },
        issueRefundClose: {
            call: () => ({
                ...template.call,
                category: 'Billing',
                action: 'Issue Refund',
                label: 'Cancel Issue Refund',
            }),
        },
        issueRefundYes: {
            call: (source) => ({
                ...template.call,
                category: 'Order',
                action: source === 'cancelOrder' ? 'Cancel Order' : 'Void Order',
                label: 'Issue Refund Now',
            }),
        },
        issueRefundNo: {
            call: (source) => ({
                ...template.call,
                category: 'Order',
                action: source === 'cancelOrder' ? 'Cancel Order' : 'Void Order',
                label: 'Do Not Issue Refund Now',
            }),
        },
        [`${BASE_PATH_SUFFIX}updateBookingIssueRefundJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Billing',
                action: 'Issue Refund',
                label: 'Submit Refund',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Billing',
                action: 'Issue Refund Error',
            }),
        },

        '/bookingEmailConfirmation': {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Email Order Confirmation',
                label: 'Initiate Email Order Confirmation',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'Email Order Confirmation Error',
            }),
        },
        emailConfirmationClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Email Order Confirmation',
                label: 'Close Confirmation Email',
            }),
        },
        '/BookingEmailConfirmSendJSONP': {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Email Order Confirmation',
                label: 'Send Confirmation Email',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'Email Order Confirmation Error',
            }),
        },
        supplierNotesOpen: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Supplier Notes',
                label: 'Initiate Supplier Notes',
            }),
        },
        supplierNotesClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Supplier Notes',
                label: 'Close Supplier Notes',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingAddSupplierNoteJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Supplier Notes',
                label: 'Submit Supplier Note',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'Supplier Notes Error',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingSearchIssuedCouponDetailsJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Authorize Refund',
                label: 'Initiate Authorize Refund',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'Authorize Refund Error',
            }),
        },

        authorizeRefundClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Authorize Refund',
                label: 'Close Authorize Refund',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingAuthorizeRefundJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Authorize Refund',
                label: 'Submit Authorize Refund',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'Authorize Refund Error',
            }),
        },
        addNotesOpen: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Notes',
                label: 'Initiate Add Notes',
            }),
        },
        addNotesClose: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Notes',
                label: 'Close Add Note',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingAddNoteJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Notes',
                label: 'Submit Note',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Booking',
                action: 'Add Notes Error',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingSearchCouponDetailsJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Return Coupons',
                label: 'Initiate Return Coupons',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'Return Coupons Error',
            }),
        },
        returnCouponsClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Return Coupons',
                label: 'Close Return Coupons',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingReceiveCouponsJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Return Coupons',
                label: 'Submit Coupons as Returned',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'Return Coupons Error',
            }),
        },
        viewFareRules: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View Order Details',
                label: 'View Fare Rules',
            }),
        },
        closeFareRules: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View Order Details',
                label: 'Close Fare Rules',
            }),
        },
        viewServiceAlerts: {
            call: (open) => ({
                ...template.call,
                category: 'Order',
                action: 'View Order Details',
                label: `${open ? 'View' : 'Close'} Service Alerts`,
            }),
        },
        callingPointsOpen: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View Order Details',
                label: 'View Calling Points',
            }),
        },
        callingPointsClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View Order Details',
                label: 'Hide Calling Points',
            }),
        },
        AddFeeDiscountOpen: {
            call: () => ({
                ...template.call,
                category: 'Billing',
                action: 'Add Fee/Discount',
                label: 'Initiate Add Fee/Discount',
            }),
        },
        AddFeeDiscountClose: {
            call: () => ({
                ...template.call,
                category: 'Billing',
                action: 'Add Fee/Discount',
                label: 'Close Add Fee/Discount',
            }),
        },
        [`${BASE_PATH_SUFFIX}updateBookingApplyFeeDiscountJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Billing',
                action: 'Add Fee/Discount',
                label: 'Submit Add Fee/Discount',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Billing',
                action: 'Add Fee/Discount Error',
            }),
        },
        [`${BASE_PATH_SUFFIX}stationDataRequestJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View Station Details',
                label: 'Initiate View Station Details',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'View Station Details Error',
            }),
        },
        stationInformationClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View Station Details',
                label: 'Close View Station Details',
            }),
        },
        searchAgainBookingSearch: {
            call: () => ({
                ...template.call,
                category: 'Booking Search',
                action: 'Search Again',
                label: 'Initiate Search Again',
            }),
        },
        bookingSearchLocatorsHelp: {
            call: () => ({
                ...template.call,
                category: 'Booking Search',
                action: 'Locators Help',
                label: 'Initiate Locators Help',
            }),
        },
        resetFieldsBookingSearch: {
            call: () => ({
                ...template.call,
                category: 'Booking Search',
                action: 'Reset Fields',
                label: 'Initiate Reset Fields',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingsSearchJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Booking Search',
                action: 'Retrieve Booking',
                label: 'Initiate Retrieve Booking',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Booking Search',
                action: 'Retrieve Booking Error',
            }),
        },
        confirmationInformationOpen: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View Confirmation Information',
                label: 'Initiate View Confirmation Information',
            }),
        },
        confirmationInformationClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View Confirmation Information',
                label: 'Close View Confirmation Information',
            }),
        },
        openBookingFromSearch: {
            call: (auto) => ({
                ...template.call,
                category: 'Booking Search',
                action: 'Open Booking',
                label: `${auto ? 'Auto-initiate' : 'Initiate'} Open Booking`,
            }),
        },
        openConfirmationEmailFromSearch: {
            call: () => ({
                ...template.call,
                category: 'Booking Search',
                action: 'View Confirmation Email',
                label: 'Initiate View Confirmation Email',
            }),
        },
        bookingCustomInformationOpen: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Edit Custom Information',
                label: 'Initiate Edit Custom Information',
            }),
        },
        bookingCustomInformationAdd: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Edit Custom Information',
                label: 'Add Custom Information',
            }),
        },
        bookingCustomInformationRemove: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Edit Custom Information',
                label: 'Remove Custom Information',
            }),
        },
        bookingCustomInformationClose: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Edit Custom Information',
                label: 'Close Custom Information',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingAddCustomInformationJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Edit Custom Information',
                label: 'Save Custom Information',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Booking',
                action: 'Edit Custom Information Error',
            }),
        },
        viewTDOOpen: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View TDO',
                label: 'Initiate View TDO',
            }),
        },
        viewTDOClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View TDO',
                label: 'Close View TDO',
            }),
        },
        addTDOOpen: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Add TDO',
                label: 'Initiate Add TDO',
            }),
        },
        addTDOClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Add TDO',
                label: 'Close Add TDO',
            }),
        },
        addTDOSubmit: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Add TDO',
                label: 'Submit Add TDO',
            }),
        },
        addTDOError: {
            call: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'Add TDO Error',
            }),
        },
        editTDOOpen: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Edit TDO',
                label: 'Initiate Edit TDO',
            }),
        },
        editTDOClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Edit TDO',
                label: 'Close Edit TDO',
            }),
        },
        editTDOSubmit: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Edit TDO',
                label: 'Submit Edit TDO',
            }),
        },
        editTDOError: {
            call: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'Edit TDO Error',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingAmmendCTRJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Amend CTR',
                label: 'Initiate Amend CTR',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'Amend CTR Error',
            }),
        },
        fareSearchAddPassenger: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Add Passenger',
            }),
        },
        fareSearchRemovePassenger: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Remove Passenger',
            }),
        },
        fareSearchOpenReturn: {
            call: (selected) => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: `${selected ? 'Select' : 'Deselect'} Open Return`,
            }),
        },
        passengerInformationOpen: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'View Passenger Details',
                label: 'Initiate View Passenger Details',
            }),
        },
        passengerInformationClose: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'View Passenger Details',
                label: 'Close View Passenger Details',
            }),
        },
        fareSearchSwapStations: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Swap Stations',
            }),
        },
        fareSearchAddViaAvoidStation: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Add Via/Avoid Station',
            }),
        },
        fareSearchRemoveViaAvoidStation: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Remove Via/Avoid Station',
            }),
        },
        resendTicketOpen: {
            call: (isClaim) => ({
                ...template.call,
                category: 'Order',
                action: `${isClaim ? 'Send' : 'Resend'} Ticket`,
                label: `Initiate ${isClaim ? 'Send' : 'Resend'} Ticket`,
            }),
        },
        resendTicketClose: {
            call: (isClaim) => ({
                ...template.call,
                category: 'Order',
                action: `${isClaim ? 'Send' : 'Resend'} Ticket`,
                label: `Cancel ${isClaim ? 'Send' : 'Resend'} Ticket`,
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingResendClaimVDUrlJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: `${body.srtClaimVDMode ? 'Send' : 'Resend'} Ticket`,
                label: `Submit ${body.srtClaimVDMode ? 'Send' : 'Resend'} Ticket`,
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: `${body.srtClaimVDMode ? 'Send' : 'Resend'} Ticket Error`,
            }),
        },
        fareSearchRecentSearches: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Initiate Recent Searches',
            }),
        },
        fareSearchSelectRecent: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Select Recent Searches',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingSearchConfirmDataJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Confirm Order',
                label: 'Initiate Confirm Order',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'Confirm Order Error',
            }),
        },
        confirmOrderClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Confirm Order',
                label: 'Cancel Confirm Order',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingConfirmOrderJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Confirm Order',
                label: 'Confirm Order',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'Confirm Order Error',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingGetTrackingInfoJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Edit Tracking Info',
                label: 'Initiate Edit Tracking Info',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'Edit Tracking Info Error',
            }),
        },
        editTrackingInfoClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Edit Tracking Info',
                label: 'Close Edit Tracking Info',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingSetTrackingInfoJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Edit Tracking Info',
                label: 'Submit Edit Tracking Info',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'Edit Tracking Info Error',
            }),
        },
        fareSearchResetFields: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Initiate Reset Fields',
            }),
        },
        updateBookingRecordAllowAnyCardCollect: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'Allow Any Card Collect',
                label: 'Initiate Allow Any Card Collect',
            }),
        },
        updateBookingRecordAllowAnyCardCollectError: {
            call: (error) => ({
                ...template.fail(error),
                category: 'Order',
                action: 'Allow Any Card Collect Error',
                label: (error && error.errorResponse && error.errorResponse.message) || '',
            }),
        },
        addOrderOpenDialog: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Initiate Add Order Dialog',
            }),
        },
        addOrderCloseDialog: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Close Add Order Dialog',
            }),
        },
        addOrderFareSearch: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Fare Search',
            }),
        },
        addOrderSwapStations: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Swap Stations',
            }),
        },
        addOrderAddViaStation: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Add Via/Avoid Station',
            }),
        },
        addOrderRemoveViaStation: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Remove Via/Avoid Station',
            }),
        },
        addOrderAddDiscountCard: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Add Discount Card',
            }),
        },
        addOrderRemoveDiscountCard: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Remove Discount Card',
            }),
        },
        addOrderViewDetails: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'View Itinerary Details',
            }),
        },
        addOrderHideDetails: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Hide Itinerary Details',
            }),
        },
        addOrderViewFareInformation: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'View Fare Information',
            }),
        },
        addOrderViewFareRules: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'View Fare Rule Details',
            }),
        },
        addOrderCloseFareRules: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Close Fare Rule Details',
            }),
        },
        addOrderAddOrderButton: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Add Order',
            }),
        },
        addOrderEditSeatReservations: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Edit Seat Reservations',
            }),
        },
        addOrderAddSeatReservations: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Add Seat Reservations',
            }),
        },
        addOrderClearSeatReservations: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Clear Seat Reservation Selections',
            }),
        },
        addOrderDiscardSeatReservations: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Discard Seat Reservations',
            }),
        },
        addOrderRequestSeatReservations: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Request Seat Reservations',
            }),
        },
        addOrderTicketDeliveryEdit: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Edit Ticket Delivery',
            }),
        },
        addOrderTicketDeliveryAdd: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Add Ticket Delivery',
            }),
        },
        addOrderTicketDeliveryView: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'View Ticket Delivery',
            }),
        },
        addOrderCloseTicketDelivery: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Close Ticket Delivery',
            }),
        },
        addOrderSubmitTicketDelivery: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Submit Ticket Delivery',
            }),
        },
        addOrderEditTravelcards: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Edit Travelcards',
            }),
        },
        addOrderDiscardTravelcards: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Discard Travelcards',
            }),
        },
        addOrderSaveTravelcards: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Save Travelcards',
            }),
        },
        selectFareSearchSingle: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Select Single',
            }),
        },
        selectFareSearchReturn: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Select Return',
            }),
        },
        selectFareSearchSeason: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Select Season',
            }),
        },
        fareSearchViewFareInformation: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'View Fare Information',
            }),
        },
        fareSearchViewFareRules: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'View Fare Rule Details',
            }),
        },
        fareSearchCloseFareRules: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Close Fare Rule Details',
            }),
        },
        fareSearchAddDiscountCard: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Add Discount Card',
            }),
        },
        fareSearchRemoveDiscountCard: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Remove Discount Card',
            }),
        },
        fareSearchSearchAgain: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Search Again',
            }),
        },
        shoppingViewStops: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'View Stops',
                label: 'Show Intermediate Stops',
            }),
        },
        fareSearchViewDetails: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Show Itinerary Details',
            }),
        },
        fareSearchHideDetails: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Hide Itinerary Details',
            }),
        },
        fareSearchInitiateFilterFares: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Initiate Filter Fares',
            }),
        },
        fareSearchFilterFareSelection: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Filter Fare Selection',
            }),
        },
        updatePassengerInformationInitiated: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Update Passenger Details',
                label: 'Update Passenger Details Initiated',
            }),
        },
        [`${BASE_PATH_SUFFIX}updateBookingPassengerInformationJSON`]: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Update Passenger Details',
                label: 'Update Passenger Details Success',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Booking',
                action: 'Update Passenger Details Error',
            }),
        },
        updatePassengerInformationAbandon: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Update Passenger Details',
                label: 'Update Passenger Details Abandon',
            }),
        },
        filterStationCountry: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Fare Search',
                label: 'Filter Station Country',
            }),
        },
        changeJourney: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Change Journey',
            }),
        },
        addAddress: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Add Address',
            }),
        },
        serviceAlerts: {
            call: (gaCategory, gaAction) => ({
                ...template.call,
                category: gaCategory,
                action: gaAction,
                label: 'Service Alerts Initiate',
            }),
        },
        [`${BASE_PATH_SUFFIX}bookingCreateJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Initiate Create Booking',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Booking',
                action: 'Create Booking Error',
            }),
        },
        createBookingError: {
            call: (error) => ({
                ...template.fail(error),
                category: 'Booking',
                action: 'Create Booking Error',
            }),
        },
        createBookingEditTDOOpen: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Edit Ticket Delivery',
            }),
        },
        createBookingEditTDOClose: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Close Ticket Delivery',
            }),
        },
        createBookingEditTDOSubmit: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Submit Ticket Delivery',
            }),
        },
        createBookingAddSeatReservations: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Add Seat Reservations',
            }),
        },
        createBookingClearSeatReservations: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Clear Seat Reservation Selections',
            }),
        },
        createBookingDiscardSeatReservations: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Discard Seat Reservations',
            }),
        },
        createBookingRequestSeatReservations: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Request Seat Reservations',
            }),
        },
        createBookingEditTravelcards: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Edit Travelcards',
            }),
        },
        createBookingDiscardTravelcards: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Discard Travelcards',
            }),
        },
        createBookingSaveTravelcards: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Save Travelcards',
            }),
        },
        createBookingOnboardServicesAdd: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Add Additional Services',
            }),
        },
        createBookingOnboardServicesDiscard: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Discard Additional Services',
            }),
        },
        createBookingOnboardServicesSave: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Save Additional Services',
            }),
        },
        createBookingCustomInformationAdd: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Add Custom Information',
            }),
        },
        createBookingCustomInformationRemove: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Remove Custom Information',
            }),
        },
        createBookingViewFareRules: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'View Fare Rule Details',
            }),
        },
        createBookingCloseFareRules: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Close Fare Rule Details',
            }),
        },
        createBookingViewFareInformation: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'View Fare Information',
            }),
        },
        createBookingViewDetails: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Show Itinerary Details',
            }),
        },
        createBookingHideDetails: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Hide Itinerary Details',
            }),
        },
        searchBookingTab: {
            call: () => ({
                ...template.call,
                category: 'Booking Search',
                action: 'Search Booking',
                label: 'Initiate Search Booking',
            }),
        },
        openBookingTab: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Booking Tab',
                label: 'Open Booking Tab',
            }),
        },
        closeBookingTab: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Booking Tab',
                label: 'Close Booking Tab',
            }),
        },
        refreshBookingSummary: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Booking Summary',
                label: 'Refresh Booking',
            }),
        },
        expandTotalPaymentsSection: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Expand Total Payments Section',
            }),
        },
        selectBillingSummaryTab: {
            call: (tab) => ({
                ...template.call,
                category: 'Booking',
                action: 'Billing Summary Tabs',
                label: `Select ${tab === 0 ? 'Itemized' : 'Overview'} Tab`,
            }),
        },
        collapseTotalPaymentsSection: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Collapse Total Payments Section',
            }),
        },
        expandTotalChargesSection: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Expand Total Charges Section',
            }),
        },
        collapseTotalChargesSection: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Collapse Total Charges Section',
            }),
        },
        expandOrderSection: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Expand Order Section',
            }),
        },
        collapseOrderSection: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Collapse Order Section',
            }),
        },
        expandValueDocumentCoupons: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Expand Value Document Coupons Section',
            }),
        },
        collapseValueDocumentCoupons: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Collapse Value Document Coupons Section',
            }),
        },
        expandTDOAdditionalDetails: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Expand TDO Additional details',
            }),
        },
        collapseTDOAdditionalDetails: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Collapse TDO Additional details',
            }),
        },
        expandAdditionalChargesSection: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Expand Additional Charges Section',
            }),
        },
        collapseAdditionalChargesSection: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Collapse Additional Charges Section',
            }),
        },
        expandOrderDetailsSection: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Expand Order Details Section',
            }),
        },
        collapseOrderDetailsSection: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Collapse Order Details Section',
            }),
        },
        selectNavBookingTab: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Shopping Tab',
                label: 'Select Book Tab',
            }),
        },
        selectNavManageTab: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Booking Tab',
                label: 'Select Manage Tab',
            }),
        },
        selectNavAdminTab: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Admin Tab',
                label: 'Select Admin Tab',
            }),
        },
        adminManageAccounts: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Initiate Manage Accounts',
            }),
        },
        adminCreateUser: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Create User',
                label: 'Initiate Create User',
            }),
        },
        adminManageUsers: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Users',
                label: 'Initiate Manage Users',
            }),
        },
        adminManageUsersSearchUsers:
        {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Users',
                label: 'Search Users',
            }),
        },
        adminManageUsersOpenUser:
        {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Users',
                label: 'Open User',
            }),
        },
        [`${BASE_PATH_SUFFIX}accountGroupCreateEditJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: body.srtEditMode ? 'Manage Accounts/Groups' : 'Create Account Group',
                label: 'Save Account Group',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Admin',
                action: body.srtEditMode ? 'Manage Accounts/Groups Error' : 'Create Account Group Error',
            }),
        },
        accountGroupClose: {
            call: (editMode) => ({
                ...template.call,
                category: 'Admin',
                action: editMode ? 'Manage Accounts/Groups' : 'Create Account Group',
                label: 'Close Account Group',
            }),
        },
        accountGroupDeleteConfirmation: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Initiate Delete Account Group',
            }),
        },
        accountGroupDeleteCancel: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Cancel Delete Account Group',
            }),
        },
        [`${BASE_PATH_SUFFIX}deleteAccountGroupAMJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Confirm Delete Account Group',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Admin',
                action: 'Manage Accounts/Groups Error',
            }),
        },
        [`${BASE_PATH_SUFFIX}accountCreateEditJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: body.srtAcctEditMode ? 'Manage Accounts/Groups' : 'Create Account',
                label: 'Save Account',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Admin',
                action: body.srtAcctEditMode ? 'Manage Accounts/Groups Error' : 'Create Account Error',
            }),
        },
        accountClose: {
            call: (editMode) => ({
                ...template.call,
                category: 'Admin',
                action: editMode ? 'Manage Accounts/Groups' : 'Create Account',
                label: 'Close Account',
            }),
        },
        accountDeleteConfirmation: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Initiate Delete Account',
            }),
        },
        accountDeleteCancel: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Cancel Delete Account',
            }),
        },
        [`${BASE_PATH_SUFFIX}deleteAccountAMJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Confirm Delete Account',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Admin',
                action: 'Manage Accounts/Groups Error',
            }),
        },
        accountAddCustomInfo: {
            call: (editMode) => ({
                ...template.call,
                category: 'Admin',
                action: editMode ? 'Manage Accounts/Groups' : 'Create Account',
                label: 'Add Custom Info',
            }),
        },
        accountAddAdditionalCustomInfo: {
            call: (editMode) => ({
                ...template.call,
                category: 'Admin',
                action: editMode ? 'Manage Accounts/Groups' : 'Create Account',
                label: 'Add Additional Custom Info',
            }),
        },
        accountRemoveAdditionalCustomInfo: {
            call: (editMode) => ({
                ...template.call,
                category: 'Admin',
                action: editMode ? 'Manage Accounts/Groups' : 'Create Account',
                label: 'Remove Additional Custom Info',
            }),
        },
        accountRemoveCustomInfo: {
            call: (editMode) => ({
                ...template.call,
                category: 'Admin',
                action: editMode ? 'Manage Accounts/Groups' : 'Create Account',
                label: 'Remove Custom Info',
            }),
        },
        accountAddFees: {
            call: (editMode) => ({
                ...template.call,
                category: 'Admin',
                action: editMode ? 'Manage Accounts/Groups' : 'Create Account',
                label: 'Add Fees',
            }),
        },
        accountRemoveFees: {
            call: (editMode) => ({
                ...template.call,
                category: 'Admin',
                action: editMode ? 'Manage Accounts/Groups' : 'Create Account',
                label: 'Remove Fees',
            }),
        },
        loginForgotPasswordInitiate: {
            call: () => ({
                ...template.call,
                category: 'Authentication',
                action: 'Forgot Password',
                label: 'Initiate Forgot Password',
            }),
        },
        loginForgotPasswordClose: {
            call: () => ({
                ...template.call,
                category: 'Authentication',
                action: 'Forgot Password',
                label: 'Close Forgot Password',
            }),
        },
        [`${BASE_PATH_SUFFIX}forgotPasswordAMJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Authentication',
                action: 'Forgot Password',
                label: 'Send Password Instruction',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Authentication',
                action: 'Forgot Password Error',
            }),
        },
        changePasswordInitiate: {
            call: () => ({
                ...template.call,
                category: 'Authentication',
                action: 'Change Password',
                label: 'Initiate Change Password',
            }),
        },
        changePasswordClose: {
            call: () => ({
                ...template.call,
                category: 'Authentication',
                action: 'Change Password',
                label: 'Close Change Password',
            }),
        },
        [`${BASE_PATH_SUFFIX}changePasswordAMJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Authentication',
                action: 'Change Password',
                label: 'Save Password',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Authentication',
                action: 'Change Password Error',
            }),
        },
        aboutOpen: {
            call: () => ({
                ...template.call,
                category: 'General',
                action: 'About',
                label: 'Initiate About',
            }),
        },
        aboutClose: {
            call: () => ({
                ...template.call,
                category: 'General',
                action: 'About',
                label: 'Close About',
            }),
        },
        aboutTermsConditions: {
            call: () => ({
                ...template.call,
                category: 'General',
                action: 'About',
                label: 'View Terms & Conditions',
            }),
        },
        myProfileOpen: {
            call: () => ({
                ...template.call,
                category: 'General',
                action: 'My Profile',
                label: 'Initiate User Profile',
            }),
        },
        myProfileClose: {
            call: () => ({
                ...template.call,
                category: 'General',
                action: 'My Profile',
                label: 'Close User Profile',
            }),
        },
        myProfileSave: {
            call: () => ({
                ...template.call,
                category: 'General',
                action: 'My Profile',
                label: 'Save User Profile',
            }),
        },
        myProfileError: {
            call: (error) => ({
                ...template.fail(error),
                category: 'General',
                action: 'My Profile Error',
            }),
        },
        adminSearchAccountsGroups: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Search Accounts/Groups',
            }),
        },
        adminOpenAccount: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Open Account',
            }),
        },
        adminOpenAccountGroup: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Open Account Group',
            }),
        },
        adminAddAccount: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Initiate Add Account',
            }),
        },
        adminAddAccountGroup: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Initiate Add Account Group',
            }),
        },
        adminAccountGroupViewAccountList: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'View Account List for Account Group',
            }),
        },
        adminAccountGroupOpenAccount: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Open Account for Account Group',
            }),
        },
        adminAccountGroupViewUserList: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'View User List for Account/Group',
            }),
        },
        adminAccountGroupOpenUser: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Open User for Account/Group',
            }),
        },
        userMenuOpen: {
            call: () => ({
                ...template.call,
                category: 'General',
                action: 'Logged In User',
                label: 'Logged In User',
            }),
        },
        applicationLogoClick: {
            call: () => ({
                ...template.call,
                category: 'General',
                action: 'SA Logo',
                label: 'SA Logo',
            }),
        },
        [`${BASE_PATH_SUFFIX}logoutJSONP`]: {
            call: () => ({
                ...template.call,
                category: 'Authentication',
                action: 'User Logout',
                label: 'User Logout',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Authentication',
                action: 'User Logout Error',
            }),
        },
        adminCreateUserCancel: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Create User',
                label: 'Close User',
            }),
        },
        adminCreateUserSave: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Create User',
                label: 'Save User',
            }),
        },
        bookingPassengerCustomInformationOpen: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Edit Pax Custom Information',
                label: 'Initiate Edit Pax Custom Information',
            }),
        },
        bookingPassengerCustomInformationClose: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Edit Pax Custom Information',
                label: 'Close Custom Information',
            }),
        },
        [`${BASE_PATH_SUFFIX}updatePassengerCustomInformationJSON`]: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Edit Pax Custom Information',
                label: 'Save Custom Information',
            }),
            fail: (error) => ({
                ...template.fail(error),
                category: 'Booking',
                action: 'Edit Pax Custom Information Error',
            }),
        },
        passengerCustomInformationAdd: {
            call: (source) => ({
                ...template.call,
                category: source === 'MANAGE' ? 'Booking' : 'Shopping',
                action: source === 'MANAGE' ? 'Edit Pax Custom Information' : 'Create Booking',
                label: source === 'MANAGE' ? 'Add Custom Information' : 'Add Pax Custom Information',
            }),
        },
        passengerCustomInformationRemove: {
            call: (source) => ({
                ...template.call,
                category: source === 'MANAGE' ? 'Booking' : 'Shopping',
                action: source === 'MANAGE' ? 'Edit Pax Custom Information' : 'Create Booking',
                label: source === 'MANAGE' ? 'Remove Custom Information' : 'Remove Pax Custom Information',
            }),
        },
        shoppingPassengerCustomInformationOpen: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Initiate Add Pax Custom Information',
            }),
        },
        shoppingPassengerCustomInformationClose: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Close Pax Custom Information',
            }),
        },
        shoppingPassengerCustomInformationSave: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Save Pax Custom Information',
            }),
        },
        sidebarSelectNavBooking: {
            call: () => ({
                ...template.call,
                category: 'General',
                action: 'Sidebar',
                label: 'Select Book Link',
            }),
        },
        sidebarSelectNavManage: {
            call: () => ({
                ...template.call,
                category: 'General',
                action: 'Sidebar',
                label: 'Select Manage Link',
            }),
        },
        sidebarSelectNavAdmin: {
            call: () => ({
                ...template.call,
                category: 'General',
                action: 'Sidebar',
                label: 'Select Admin Link',
            }),
        },
        sidebarAdminManageAccounts: {
            call: () => ({
                ...template.call,
                category: 'General',
                action: 'Sidebar',
                label: 'Initiate Manage Accounts',
            }),
        },
        sidebarAdminCreateUser: {
            call: () => ({
                ...template.call,
                category: 'General',
                action: 'Sidebar',
                label: 'Initiate Create User',
            }),
        },
        sidebarAdminManageUsers: {
            call: () => ({
                ...template.call,
                category: 'General',
                action: 'Sidebar',
                label: 'Initiate Manage Users',
            }),
        },
        addOrderSavePlusBusSupplements: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Save Local Bus Services',
            }),
        },
        exchangeOrderSavePlusBusSupplements: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Exchnage Order',
                label: 'Save Local Bus Services',
            }),
        },
        createBookingSavePlusBusSupplements: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Create Booking',
                label: 'Save Local Bus Services',
            }),
        },
        addOrderDiscardPlusBusSupplements: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Discard Local Bus Services',
            }),
        },
        exchangeOrderDiscardPlusBusSupplements: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Exchange Order',
                label: 'Discard Local Bus Services',
            }),
        },
        createBookingDiscardPlusBusSupplements: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Discard Local Bus Services',
            }),
        },
        addOrderEditPlusBusSupplements: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Add Order',
                label: 'Edit Local Bus Services',
            }),
        },
        exchangeOrderPlusBusSupplements: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Exchange Order',
                label: 'Edit Local Bus Services',
            }),
        },
        createBookingEditPlusBusSupplements: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Edit Local Bus Services',
            }),
        },
        expandPromotionDetailsSection: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Expand Discounts Section',
            }),
        },
        collapsePromotionDetailsSection: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Collapse Discounts Section',
            }),
        },
        expandSupplementDetailsSection: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Expand Additional Products Section',
            }),
        },
        collapseSupplementDetailsSection: {
            call: () => ({
                ...template.call,
                category: 'Booking',
                action: 'Expand/Collapse',
                label: 'Collapse Additional Products Section',
            }),
        },
        viewRevisionData: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View Revision Data',
                label: 'Initiate View Revision Data',
            }),
        },
        closeRevisionData: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View Revision Data',
                label: 'Close View Revision Data',
            }),
        },
        initiatesSeatmapComponent: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Initiate SeatMap UX',
            }),
        },
        seatmapComponentRequestSeats: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Request SeatMap UX Seats',
            }),
        },
        seatmapComponentDiscard: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'Discard SeatMap UX',
            }),
        },
        seatMapUxApplicable: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'SeatMap UX Applicable',
            }),
        },
        seatMapUxNotApplicable: {
            call: () => ({
                ...template.call,
                category: 'Shopping',
                action: 'Create Booking',
                label: 'SeatMap UX NOT Applicable',
            }),
        },
        splitFareDialogOpen: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View Multiple Tickets',
                label: 'Initiate View Multiple Tickets',
            }),
        },
        splitFareDialogClose: {
            call: () => ({
                ...template.call,
                category: 'Order',
                action: 'View Multiple Tickets',
                label: 'Close View Multiple Tickets',
            }),
        },
        splitFareDialogrror: {
            call: (props) => ({
                ...template.fail(props.error.message),
                category: 'Order',
                action: 'View Multiple Tickets Error',
            }),
        },
        addSummatedFareFilter: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Add Summated Fare Filter',
            }),
        },
        removeSummatedFareFilter: {
            call: () => ({
                ...template.call,
                category: 'Admin',
                action: 'Manage Accounts/Groups',
                label: 'Remove Summated Fare Filter',
            }),
        },
    };

    return options[action] || {};
};
