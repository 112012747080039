import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const FeeInfoItem = ({
    feeTypes,
    labels,
    index,
    type = 'none',
    amount,
    errors,
    minValue = '',
    handleChange,
}) => {
    const handleChangeType = ({ target: { value } }) => {
        if (value) {
            let updates = null;
            switch (value) {
                case 'percentage':
                    updates = { type: value };
                    break;
                case 'fixed_amount':
                    updates = { type: value, minValue: '' };
                    break;
                default:
                    updates = { type: value, amount: '', minValue: '' };
            }
            handleChange(index, updates);
        }
    };

    const handleChangeField = (value) => (event) => {
        const fieldValue = !event.target.validity.valid ? value : event.target.value;
        handleChange(index, { [event.target.name]: fieldValue });
    };

    const feeTypeKeys = feeTypes.keySeq();

    const amountTextField = (type !== 'none')
        && (
            <div className="col-2">
                <TextField
                    id={`srtFeeValue${index}`}
                    type="number"
                    name="amount"
                    inputProps={{
                        min: '0',
                        step: '0.01',
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={labels.lblFeeValue}
                    fullWidth
                    onChange={handleChangeField(amount)}
                    value={amount}
                    error={!!errors.amount}
                    helperText={errors.amount}
                    variant="standard"
                />
            </div>
        );

    const minValueTextField = (type === 'percentage')
        && (
            <div className="col-2">
                <TextField
                    id={`srtFeeMinValue${index}`}
                    type="number"
                    name="minValue"
                    inputProps={{
                        min: '0',
                        step: '0.01',
                    }}
                    label={labels.lblFeeMinValue}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    onChange={handleChangeField(minValue)}
                    value={minValue}
                    variant="standard"
                />
            </div>
        );

    return (
        <div className="row" data-testid="FeeInfoItem">
            <div className="offset-2 col-3">
                <TextField
                    id={`srtFeeType${index}`}
                    name="type"
                    label={labels.lblFeeType}
                    fullWidth
                    onChange={handleChangeType}
                    value={type}
                    select
                    margin="normal"
                    variant="standard"
                >
                    {feeTypeKeys.map((item, indexItem) => (
                        <MenuItem
                            id={`srtFeeType${index}_${item}`}
                            key={indexItem}
                            value={item}
                        >
                            {feeTypes.get(item)}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            {amountTextField}
            {minValueTextField}
        </div>
    );
};

FeeInfoItem.propTypes = {
    index: PropTypes.number.isRequired,
    type: PropTypes.string,
    amount: PropTypes.string,
    minValue: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    labels: PropTypes.object.isRequired,
    feeTypes: PropTypes.object.isRequired,
    errors: PropTypes.object,
};

export default FeeInfoItem;
