import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import recentSearchesReducer from '../Shopping/ShoppingSearch/components/RecentSearches/reducerRecentSearches';
import shoppingSearchReducer from '../Shopping/ShoppingSearch/reducersShoppingSearch';
import shoppingResultsReducer from '../Shopping/ShoppingResults/reducerShoppingResults';

import { USER_LOGOUT } from '../constants/constants';

const initialState = fromJS({});

const shoppingReducer = (state = initialState, action) => {
    let currentState = state;
    if (action.type === USER_LOGOUT) {
        currentState = initialState;
    }

    return combineReducers({
        query: shoppingSearchReducer,
        results: shoppingResultsReducer,
        recentSearches: recentSearchesReducer,
    })(currentState, action);
};

export default shoppingReducer;
