import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import IconButton from '@mui/material/IconButton';
// import Snackbar from '@mui/material/Snackbar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

import Truncate from './Truncate';

import inlineStyles from './styles';

const ExtendedSnackbar = ({
    id, open, message, onClose, autoHideDuration = 6000, action,
}) => {
    const [truncated, setTruncated] = useState(true);

    const handleTruncate = useCallback((isTruncated) => {
        if (isTruncated && isTruncated !== truncated) {
            setTruncated(isTruncated);
        }
    }, [truncated]);

    const handleExpand = useCallback(() => setTruncated(false), []);

    const expandableMessage = (
        <Truncate
            trimWhitespace
            onTruncate={handleTruncate}
            ellipsis={(
                <span>
                    ...
                    <IconButton style={inlineStyles.expandIcon} onClick={handleExpand}>
                        <ExpandMoreIcon />
                    </IconButton>
                </span>
            )}
            lines={truncated && 1}
            width={(window.innerWidth || document.body.clientWidth) - 58}
        >
            {message}
        </Truncate>
    );

    const handleClose = useCallback(() => {
        setTruncated(true);
        if (onClose) onClose();
    }, [onClose]);

    return (
        <Snackbar
            data-testid="extended-snackbar"
            id={id}
            open={open}
            message={expandableMessage}
            onClose={handleClose}
            autoHideDuration={autoHideDuration}
            action={action}
        />
    );
};

ExtendedSnackbar.propTypes = {
    id: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    autoHideDuration: PropTypes.number,
    action: PropTypes.object,
};

export default ExtendedSnackbar;
