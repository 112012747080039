import { defineMessages } from 'react-intl';

export default defineMessages({
    lblDays: {
        id: 'app.utils.dateTime.days',
        defaultMessage: '{value} days',
    },
    lblHours: {
        id: 'app.utils.dateTime.hours',
        defaultMessage: '{value} hours',
    },
    lblMinutes: {
        id: 'app.utils.dateTime.minutes',
        defaultMessage: '{value} minutes',
    },
    lblMinutes0: {
        id: 'app.utils.dateTime.minutes0',
        defaultMessage: '0 minutes',
    },
});
