export default {
    fareRulesMatrixContainer: {
        backgroundColor: '#E5E5E5',
        color: '#000000',
    },
    fareRulesMatrixContent: {
        backgroundColor: '#FFF',
    },
    fareRulesMatrixTitle: {
        color: '#fff',
        fontWeight: 500,
        backgroundColor: '#748490',
        padding: '10px 20px',
    },
    ruleRow: {
        padding: '10px 20px',
        margin: '10px 0',
        backgroundColor: '#E5E5E5',
        border: '1px solid #000',
        borderRadius: '10px',
    },
    fareRulesRuleCategory: {
        fontWeight: 'bold',
        margin: '5px 0',
    },
    tabRoot: {
        textTransform: 'initial',
    },
};
