import { defineMessages } from 'react-intl';

export default defineMessages({
    userProfile: {
        id: 'app.common.header.userProfile.userProfile',
        defaultMessage: 'User Profile',
    },
    lblLoginId: {
        id: 'app.common.header.userProfile.loginId',
        defaultMessage: 'User Login Id',
    },
    lblLastLoginTime: {
        id: 'app.common.header.userProfile.lastLoginTime',
        defaultMessage: 'Last Successful Login',
    },
    lblUserStatus: {
        id: 'app.common.header.userProfile.userStatus',
        defaultMessage: 'Status',
    },
    lblEmail: {
        id: 'app.common.header.userProfile.email',
        defaultMessage: 'Email Address',
    },
    errEmptyEmail: {
        id: 'app.common.header.userProfile.emptyEmail',
        defaultMessage: '^Please enter an email address',
    },
    errInvalidEmail: {
        id: 'app.common.header.userProfile.invalidEmail',
        defaultMessage: '^Please enter a properly formatted email address (for example: anyuser@anycompany.com)',
    },
    errGetUserProfile: {
        id: 'app.common.header.userProfile.errorGetUserProfile',
        defaultMessage: 'There was problem loading user\'s profile. Please try again!',
    },
    errUpdateUserProfile: {
        id: 'app.common.header.userProfile.errorUpdateUserProfile',
        defaultMessage: 'There was problem updating user\'s profile. Please try again!',
    },
    okUpdateUserProfile: {
        id: 'app.common.header.userProfile.successUpdateUserProfile',
        defaultMessage: 'User profile updated.',
    },
    okUpdateUserProfileLocale: {
        id: 'app.common.header.userProfile.successUpdateUserProfileLocale',
        defaultMessage: 'User profile updated. Locale change will take effect on your next login.',
    },
    lblFirstName: {
        id: 'app.common.header.userProfile.firstName',
        defaultMessage: 'First Name',
    },
    lblLastName: {
        id: 'app.common.header.userProfile.lastName',
        defaultMessage: 'Last Name',
    },
    btnClose: {
        id: 'app.common.header.userProfile.close',
        defaultMessage: 'Close',
    },
    btnSave: {
        id: 'app.common.header.userProfile.save',
        defaultMessage: 'Save',
    },
    lblOn: {
        id: 'app.common.header.userProfile.On',
        defaultMessage: 'On',
    },
    lblOff: {
        id: 'app.common.header.userProfile.Off',
        defaultMessage: 'Off',
    },
    lblUserAllowedVoidException: {
        id: 'app.admin.userAllowedVoidException',
        defaultMessage: 'Allow "Void Exception"',
    },
});
