import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { injectIntl } from 'react-intl';

import { gaEvent } from '../../../utils/googleAnalytics';
import ConfirmedCardInfoView from './ConfirmedCardInfoView';
import messages from './messagesConfirmedCardInfo';
import inlineStyles from './styles';

const StyledDialog = styled(Dialog)({
    '& .MuiPaper-root': {
        ...inlineStyles.dialogPaper,
    },
});

const StyledDialogContent = styled(DialogContent)({
    ...inlineStyles.dialogContent,
});

function ConfirmedCardInfoDialog({
    open,
    onClose,
    intl,
    orderId,
    booking,
}) {
    const closeAction = () => {
        gaEvent('confirmationInformationClose');
        onClose();
    };

    let confirmedCardInfo = null;
    if (orderId) {
        const order = booking.BookingOrders.find((orderItem) => orderItem.BookingOrderID === orderId);
        confirmedCardInfo = (order) ? order.BookingOrderConfirmationTypeInfo : null;
    }

    return (
        <StyledDialog
            open={open}
            onClose={closeAction}
            disableEnforceFocus
            maxWidth={false}
        >
            <DialogTitle>{intl.formatMessage(messages.lblTitle)}</DialogTitle>
            <StyledDialogContent>
                {confirmedCardInfo !== null && (
                    <ConfirmedCardInfoView
                        confirmedCardInfo={confirmedCardInfo}
                    />
                )}
            </StyledDialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    id="srtConfirmedCardViewClose"
                    onClick={closeAction}
                >
                    {intl.formatMessage(messages.btnClose)}
                </Button>
            </DialogActions>
        </StyledDialog>
    );
}

ConfirmedCardInfoDialog.propTypes = {
    intl: PropTypes.object,
    booking: PropTypes.object,
    orderId: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

export default injectIntl(ConfirmedCardInfoDialog);
