import React from 'react';
import PropTypes from 'prop-types';
import './fare.css';

const DicountInformation = ({
    fare,
    discountHeaderLabel,
    discountCardLabel,
    promoCodeLabel,
    validationCodeLabel,
}) => (
    <>
        {
            (!fare.discountApplied && !fare.discountDescription) || (
                <div>
                    <span styleName="discountBlock">
                        {discountHeaderLabel}
                    </span>
                    <span styleName="discountBlock">
                        {`${discountCardLabel} ${fare.discountDescription}`}
                    </span>
                </div>
            )
        }
        {
            (!fare?.campaignInfo?.success || (fare.discountApplied)) || (
                <div>
                    {discountHeaderLabel}
                </div>
            )
        }
        {
            (!fare?.campaignInfo?.success) || (
                <div>
                    <span styleName="discountBlock">
                        {`${promoCodeLabel}: ${fare?.campaignInfo?.promoCode}`}
                    </span>
                    <span styleName="discountBlock">
                        {`${validationCodeLabel}: ${fare?.campaignInfo?.validationCode}`}
                    </span>
                </div>
            )
        }
    </>
);

DicountInformation.propTypes = {
    fare: PropTypes.object,
    discountHeaderLabel: PropTypes.string,
    discountCardLabel: PropTypes.string,
    promoCodeLabel: PropTypes.string,
    validationCodeLabel: PropTypes.string,
};

export default DicountInformation;
