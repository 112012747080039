import React from 'react';
import PropTypes from 'prop-types';
import { urlRegex } from '../../utils/regex';

export default function Linkify({ children }) {
    let parts = null;
    if (children) {
        parts = children
            .replace(urlRegex, '@@$&@@')
            .split('@@')
            .filter((part) => part !== '')
            .map((part, index) => (
                urlRegex.test(part) ? (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a>
                ) : part
            ));
    }

    return parts;
}

Linkify.propTypes = {
    children: PropTypes.string.isRequired,
};
