import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export default function Searchbar({
    onChange,
    onClick,
    onKeyUp,
    searchButtonLabel,
    ...other
}) {
    return (
        <div className="row">
            <div className="col-10">
                <TextField
                    id="srtSearchTextField"
                    InputProps={{ onKeyUp }}
                    onChange={onChange}
                    variant="standard"
                    fullWidth
                    {...other}
                />
            </div>
            <div className="col-2">
                <Button
                    variant="contained"
                    id="srtSearchButton"
                    onClick={onClick}
                    color="primary"
                    fullWidth
                >
                    {searchButtonLabel}
                </Button>
            </div>
        </div>
    );
}

Searchbar.propTypes = {
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onKeyUp: PropTypes.func.isRequired,
    searchButtonLabel: PropTypes.string.isRequired,
    styles: PropTypes.object,
};
