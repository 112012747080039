import { defineMessages } from 'react-intl';

export default defineMessages({
    lblTitle: {
        id: 'app.shopping.OrderDetailsDetails.title',
        defaultMessage: 'Order Details',
    },
    lblViewIntermediateStops: {
        id: 'app.shopping.OrderDetailsDetails.viewIntermediateStops',
        defaultMessage: 'View Intermediate Stops',
    },
    lblHideIntermediateStops: {
        id: 'app.shopping.OrderDetailsDetails.hideIntermediateStops',
        defaultMessage: 'Hide Intermediate Stops',
    },
    lblFareRules: {
        id: 'app.shopping.OrderDetailsDetails.fareRules',
        defaultMessage: 'Fare Rules',
    },
    lblStation: {
        id: 'app.shopping.OrderDetailsDetails.station',
        defaultMessage: 'Station',
    },
    lblArrival: {
        id: 'app.shopping.OrderDetailsDetails.arrival',
        defaultMessage: 'Arrival',
    },
    lblDeparture: {
        id: 'app.shopping.OrderDetailsDetails.departure',
        defaultMessage: 'Departure',
    },
    lblPlatform: {
        id: 'app.shopping.OrderDetailsDetails.platform',
        defaultMessage: 'Platform',
    },
    lblStatus: {
        id: 'app.shopping.OrderDetailsDetails.status',
        defaultMessage: 'Status',
    },
    lblCabinClass: {
        id: 'app.shopping.OrderDetailsDetails.cabinClass',
        defaultMessage: 'Cabin Class',
    },
    lblOrderTotalPrice: {
        id: 'app.shopping.OrderDetailsDetails.orderTotalPrice',
        defaultMessage: 'Order Total Price:',
    },
    lblSeasonPrice: {
        id: 'app.shopping.OrderDetailsDetails.seasonPrice',
        defaultMessage: 'Season Price:',
    },
});
