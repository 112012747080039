import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FormattedMessage } from 'react-intl';
import { fromJS } from 'immutable';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';

import { stationSearchApi } from '../../apiShopping';
import TextFieldWithMenu from '../../../components/TextFieldWithMenu/TextFieldWithMenu';
import messages from './messagesStationAutoComplete';
import { AUTOCOMPLETE_MIN_LENGTH } from '../../util';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import { gaEvent } from '../../../utils/googleAnalytics';
import withStylesTheme from './styles';

const StyledTabs = styled(Tabs)(({ theme }) => ({
    backgroundColor: withStylesTheme(theme).tabsRoot.backgroundColor,
    '& .MuiTabs-indicator': {
        backgroundColor: withStylesTheme(theme).tabsIndicator.backgroundColor,
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    minWidth: withStylesTheme(theme).tabRoot.minWidth,
    color: withStylesTheme(theme).tabRoot.color,
    [theme.breakpoints.up('md')]: {
        minWidth: withStylesTheme(theme).tabRoot.minWidth,
    },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    overflow: withStylesTheme(theme).innerDiv.overflow,
    fontSize: withStylesTheme(theme).innerDiv.fontSize,
}));

const StyledDiv = styled('div')(({ theme }) => ({
    display: withStylesTheme(theme).root.display,
    position: withStylesTheme(theme).root.position,
    width: withStylesTheme(theme).root.width,
}));

const allFlags = [
    'all',
    'at',
    'be',
    'bg',
    'by',
    'ca',
    'ch',
    'cz',
    'de',
    'dk',
    'ee',
    'es',
    'fi',
    'fr',
    'gb',
    'gr',
    'hr',
    'hu',
    'ie',
    'it',
    'lt',
    'lu',
    'lv',
    'mk',
    'nl',
    'no',
    'pl',
    'pt',
    'ro',
    'ru',
    'se',
    'si',
    'sk',
    'tr',
    'ua',
    'uk',
    'us',
];

const allFlagsImages = allFlags.reduce((source, item) => {
    source[item] = require(`../../../images/flags/${item}.svg`).default; // eslint-disable-line
    return source;
}, {});

class StationAutoComplete extends PureComponent {
    static propTypes = {
        errorText: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
            PropTypes.array,
        ]),
        floatingLabelText: PropTypes.string,
        hintText: PropTypes.string,
        maxSearchResults: PropTypes.number,
        onBlur: PropTypes.func,
        onClose: PropTypes.func,
        onFocus: PropTypes.func,
        onKeyDown: PropTypes.func,
        onNewRequest: PropTypes.func,
        onUpdateInput: PropTypes.func,
        clearStation: PropTypes.func,
        otherStationCode: PropTypes.string,
        minLength: PropTypes.number,
        station: PropTypes.shape({
            code: PropTypes.string,
            countryCode: PropTypes.string,
            name: PropTypes.string,
            supplier: PropTypes.string,
        }),
        id: PropTypes.string,
        includeTravelCards: PropTypes.bool,
    };

    static defaultProps = {
        maxSearchResults: 25,
        otherStationCode: '',
        minLength: AUTOCOMPLETE_MIN_LENGTH,
    };

    constructor(props) {
        super(props);
        this.state = {
            data: fromJS({
                searchText: props.station.name,
                results: [],
                resultsOriginal: [],
                countryFilters: ['all'],
                alertText: '',
                refreshing: false,
            }),
            tab: 0,
        };
    }

    // eslint-disable-next-line camelcase,react/sort-comp
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.station.name !== nextProps.station.name) {
            this.setState((state) => ({ data: state.data.set('searchText', nextProps.station.name) }));
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timerClickCloseId);
        clearTimeout(this.timerSearchTimeout);
    }

    initiateSearch = () => {
        const searchText = this.state.data.get('searchText');
        if (searchText.length >= this.props.minLength) {
            if (this.abortController) this.abortController.abort();
            this.abortController = new AbortController();

            stationSearchApi(
                {
                    searchText,
                    otherStationCode: this.props.otherStationCode,
                    includeTravelCards: this.props.includeTravelCards,
                },
                (response) => {
                    this.abortController = null;
                    this.setState((state) => {
                        let data = state.data.set('refreshing', true);
                        if (response.errorResponse.code !== 20) {
                            data = data.set('alertText', response.errorResponse.message);
                        }
                        return { data };
                    });
                },
                (response) => {
                    this.abortController = null;
                    let countryFilters = new Set();
                    const responseResults = response.successResponse.data.StationSearchResults;
                    const results = responseResults.length
                        ? responseResults.map((item) => {
                            countryFilters.add(item.StationSearchResultCountryCode);

                            return {
                                text: item.StationSearchResultName,
                                station: {
                                    code: item.StationSearchResultCode,
                                    countryCode: item.StationSearchResultCountryCode,
                                    name: item.StationSearchResultName,
                                    supplier: item.StationSearchResultSupplier,
                                },
                            };
                        })
                        : [{
                            text: <FormattedMessage {...messages.lblNoSuggestions} />,
                            station: {
                                code: '',
                                countryCode: '',
                                name: '',
                                supplier: '',
                            },
                        }];
                    countryFilters = Array.from(countryFilters);
                    if (countryFilters.length > 1) countryFilters.unshift('all');
                    this.setState((state) => ({
                        data: state.data.merge({
                            results,
                            resultsOriginal: Array.from(results),
                            countryFilters,
                            refreshing: false,
                        }),
                    }));
                },
                this.abortController,
            );
        } else {
            this.close();
        }
    };

    selectItem = (index) => {
        const chosenRequest = this.state.data.get('results').get(index);
        if (chosenRequest) {
            this.setState((state) => ({
                data: state.data.set('searchText', chosenRequest && chosenRequest.getIn(['station', 'code']) ? chosenRequest.get('text') : ''),
            }), () => {
                this.timerClickCloseId = setTimeout(() => {
                    clearTimeout(this.timerClickCloseId);
                    this.timerClickCloseId = null;
                    this.close();
                    if (this.props.onNewRequest && chosenRequest) this.props.onNewRequest(chosenRequest.get('station').toJS());
                }, 300);
            });
        }
    };

    filter = (event, tab) => {
        gaEvent('filterStationCountry');
        this.setState((state) => {
            const { data } = state;
            const country = data.getIn(['countryFilters', tab]);
            const resultsOriginal = data.get('resultsOriginal').toJS();
            const results = country === 'all' ? resultsOriginal : resultsOriginal.filter((item) => item.station.countryCode === country);
            return {
                data: data.set('results', fromJS(results)),
                tab,
            };
        });
    };

    close = () => {
        this.setState((state) => ({
            data: state.data.merge({
                results: [],
                resultsOriginal: [],
            }),
        }));
        if (this.props.onClose) this.props.onClose();
    };

    handleItemClick = (key) => () => {
        this.selectItem(parseInt(key, 10));
    };

    handleChange = ({ target: { value: searchText } }) => {
        if (searchText === this.state.data.get('searchText')) {
            return;
        }
        if (this.props.clearStation && this.props.station.name !== '' && this.props.station.name !== searchText) {
            this.props.clearStation(searchText);
        }
        this.setState((state) => ({
            data: state.data.merge({
                searchText,
                refreshing: state.data.get('refreshing') || (searchText.length >= this.props.minLength),
            }),
        }), () => {
            if (!this.timerSearchTimeout) {
                this.timerSearchTimeout = setTimeout(() => {
                    this.initiateSearch();
                    if (this.props.onUpdateInput) this.props.onUpdateInput(searchText, this.state.data.get('results'));
                    clearTimeout(this.timerSearchTimeout);
                    this.timerSearchTimeout = null;
                }, 800);
            }
        });
    };

    handleSnackBarClose = () => {
        this.setState((state) => ({ data: state.data.set('alertText', '') }));
    };

    render() {
        const {
            floatingLabelText,
            hintText,
            maxSearchResults,
            station, // eslint-disable-line no-unused-vars
            onUpdateInput, // eslint-disable-line no-unused-vars
            onNewRequest, // eslint-disable-line no-unused-vars
            otherStationCode, // eslint-disable-line no-unused-vars
            clearStation, // eslint-disable-line no-unused-vars
            includeTravelCards, // eslint-disable-line no-unused-vars
            errorText,
            ...other
        } = this.props;

        const {
            data,
            tab,
        } = this.state;

        const results = data.get('results').toJS().slice(0, maxSearchResults - 1);
        const tabs = data.get('results').size > 0 && (
            <div>
                <StyledTabs
                    id={`${this.props.id}_containerFlags`}
                    value={tab}
                    onChange={this.filter}
                    variant="fullWidth"
                    action={(actions) => {
                        if (actions) {
                            setTimeout(() => {
                                actions.updateIndicator();
                            });
                        }
                    }}
                >
                    {data.get('countryFilters').map((item, index) => (
                        <StyledTab
                            key={`tab-${index}`}
                            value={index}
                            icon={
                                (
                                    <img
                                        width={28}
                                        height={28}
                                        src={allFlagsImages[item]}
                                        alt={`flag_${item}`}
                                    />
                                )
                            }
                            label={(item === 'all' ? <FormattedMessage {...messages.all} /> : item)}
                            onMouseEnter={this.mouseEnter}
                            onMouseLeave={this.mouseLeave}
                        />
                    ))}
                </StyledTabs>
                {results.map((item, index) => (
                    <StyledMenuItem
                        value={item.station.code}
                        key={index}
                        onClick={this.handleItemClick(index)}
                        dense
                    >
                        {item.text}
                    </StyledMenuItem>
                ))}
            </div>
        );

        return (
            <StyledDiv>
                <TextFieldWithMenu
                    errorText={errorText}
                    label={floatingLabelText}
                    onChange={this.handleChange}
                    onClose={this.close}
                    placeholder={hintText}
                    value={data.get('searchText')}
                    onSelectFirst={() => this.selectItem(0)}
                    refreshing={data.get('refreshing')}
                    {...other}
                >
                    {tabs}
                </TextFieldWithMenu>
                <ExtendedSnackbar
                    id="srtStationAutoCompleteSnackBar"
                    open={(data.get('alertText') !== '')}
                    message={data.get('alertText')}
                    onClose={this.handleSnackBarClose}
                />
            </StyledDiv>
        );
    }
}

export { StationAutoComplete as StationAutoCompleteAlias };

export default StationAutoComplete;
