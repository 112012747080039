import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

import messages from './messages';
import styles from './styles';

const getTitleLabel = (ticketableFareGroup) => {
    if (ticketableFareGroup.startDate) {
        return 'lblLegHeaderSeason';
    }
    if (ticketableFareGroup.containsTravelCard) {
        return 'lblLegHeaderTravelCard';
    }
    return 'lblLegHeaderRail';
};

const StyledBoldTextDiv = styled('div')(() => ({
    ...styles.boldText,
}));

const StyledSubBoldTextDiv = styled('div')(() => ({
    ...styles.subBoldText,
}));

function TicketableFareGroupTitle({
    ticketableFareGroup,
    showSplitTitle,
    departStation,
    arriveStation,
}) {
    return (
        <StyledBoldTextDiv>
            <FormattedMessage
                {...messages[getTitleLabel(ticketableFareGroup)]}
                values={{
                    description: showSplitTitle ? `${departStation} to ${arriveStation}` : ticketableFareGroup.description,
                    marketingCarrierReference: ticketableFareGroup.marketingCarrierReference || ticketableFareGroup.referenceCode,
                    hasMarketingCarrierReference: !!(ticketableFareGroup.marketingCarrierReference || ticketableFareGroup.referenceCode),
                }}
            />
            {!!ticketableFareGroup.passengers?.length && (
                <StyledSubBoldTextDiv>
                    <FormattedMessage
                        {...messages.lblPassengers}
                        values={{
                            passengers: ticketableFareGroup.passengers
                                .sort((a, b) => (a.passengerId > b.passengerId ? 1 : -1))
                                .map((passenger) => `${passenger.firstName} ${passenger.lastName}`)
                                .join(', '),
                        }}
                    />
                </StyledSubBoldTextDiv>
            )}
        </StyledBoldTextDiv>
    );
}

TicketableFareGroupTitle.propTypes = {
    ticketableFareGroup: PropTypes.object,
    showSplitTitle: PropTypes.bool,
    departStation: PropTypes.string,
    arriveStation: PropTypes.string,
};
export { TicketableFareGroupTitle as TicketableFareGroupTitleAlias };

export default TicketableFareGroupTitle;
