import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { styled } from '@mui/system';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import RefreshIcon from '@mui/icons-material/Refresh';

import { ErrorBoundary } from 'react-error-boundary';

import { fetchBookingDetails } from '../../actionsManageBooking';
import BillingSummary from '../BillingSummary/BillingSummary';
import AddOrderDialog from '../../../components/AddOrderDialog/AddOrderDialog';
import MakePaymentDialog from '../../../components/MakePaymentDialog/MakePaymentDialog';
import IssueRefundDialog from '../../../components/IssueRefundDialog/IssueRefundDialog';
import AddFeeDiscountDialog from '../../../components/AddFeeDiscountDialog/AddFeeDiscountDialog';
import { formatPrice } from '../../../../utils';
import { getContext } from '../../utils';
import { gaEvent } from '../../../../utils/googleAnalytics';
import messages from './messages';
import styles from './styles';
import ErrorFallBack from '../ErrorFallBack';

const StyledDivContainer = styled('div')(() => ({
    ...styles.contentContainer,
    ...styles.tableWrapper,
}));

const StyledPaper = styled(Paper)(() => ({
    ...styles.paperContainer,
}));

const StyledDivTitle = styled('div')(() => ({
    ...styles.title,
}));

const StyledChip = styled(Chip)(() => ({
    ...styles.status,
}));

const StyledIconButton = styled(IconButton)(() => ({
    ...styles.refresh,
}));

const StyledTable = styled(Table)(() => ({
    ...styles.bookingTable,
}));

const StyledTableBody = styled(TableBody)(() => ({
    ...styles.tableWrapper,
}));

const StyledTableCell = styled(TableCell)(() => ({
    ...styles.bookingTableRow,
}));

function BookingSummary({
    booking,
    bookingViewData,
    handleCloseAddFeeDiscountDialog,
    handleCloseAddOrderDialog,
    handleCloseIssueRefundDialog,
    handleCloseMakePaymentDialog,
    handleOpenAddFeeDiscountDialog,
    handleOpenAddOrderDialog,
    handleOpenIssueRefundDialog,
    handleOpenMakePaymentDialog,
    intl,
    onRefresh,
    orderAccount,
    enableBookingAddFee,
    handleMakePaymentWarning,
}) {
    const addOrderDialogRef = useRef(null);

    const statusData = {
        BALANCED: {
            styles: styles.statusBalanced,
            message: messages.lblBalanced,
        },
        CREDIT: {
            styles: styles.statusCredit,
            message: messages.lblCredit,
        },
        DEBIT: {
            styles: styles.statusDebit,
            message: messages.lblDebit,
        },
        CLOSED: {
            styles: styles.statusClosed,
            message: messages.lblClosed,
        },
    }[booking?.BookingStatus];

    const handleRefresh = () => {
        gaEvent('refreshBookingSummary');
        onRefresh(booking.queryItems);
    };

    return (
        <StyledDivContainer>
            <StyledPaper elevation={1}>
                <div className="row">
                    <StyledDivTitle className="col-auto mr-auto">
                        <h2><FormattedMessage {...messages.lblBookingSummary} /></h2>
                        <StyledChip
                            label={<FormattedMessage {...statusData?.message} />}
                            sx={{ ...statusData?.styles }}
                        />
                    </StyledDivTitle>
                    <Tooltip title={<FormattedMessage {...messages.lblRefresh} />}>
                        <StyledIconButton className="col-auto" onClick={handleRefresh} id="srtBookingRefresh">
                            <RefreshIcon fontSize="large" />
                        </StyledIconButton>
                    </Tooltip>
                </div>
                <StyledTable>
                    <TableHead>
                        <TableRow>
                            <TableCell><FormattedMessage {...messages.lblBookingLocator} /></TableCell>
                            <TableCell><FormattedMessage {...messages.lblBookingAccount} /></TableCell>
                            <TableCell><FormattedMessage {...messages.lblBookingDate} /></TableCell>
                            <TableCell><FormattedMessage {...messages.lblBookingTotalCharges} /></TableCell>
                            <TableCell><FormattedMessage {...messages.lblBookingTotalPayments} /></TableCell>
                            <TableCell><FormattedMessage {...messages.lblBookingBalance} /></TableCell>
                        </TableRow>
                    </TableHead>
                    <StyledTableBody>
                        <TableRow>
                            <StyledTableCell id="srtBookingRecLocator">
                                {booking.BookingRecordLoc}
                            </StyledTableCell>
                            <StyledTableCell id="srtBookingAccountName">
                                <Tooltip title={getContext(booking.queryItems)}>
                                    <span>
                                        {orderAccount.text || <FormattedMessage {...messages.lblBookingAccountUnknown} />}
                                    </span>
                                </Tooltip>
                            </StyledTableCell>
                            <TableCell id="srtBookingDate">
                                {booking.BookingDate}
                            </TableCell>
                            <TableCell id="srtCharges">
                                {formatPrice(
                                    booking.BookingBillingTotal?.BookingBillingTotalRevenue,
                                    booking.BookingBillingTotal?.BookingBillingTotalCurrency,
                                    intl,
                                )}
                            </TableCell>
                            <TableCell id="srtPayments">
                                {formatPrice(
                                    booking.BookingBillingTotal?.BookingBillingTotalReceipts,
                                    booking.BookingBillingTotal?.BookingBillingTotalCurrency,
                                    intl,
                                )}
                            </TableCell>
                            <TableCell id="srtBalance">
                                {formatPrice(
                                    booking.BookingBillingTotal?.BookingBillingTotalBalance,
                                    booking.BookingBillingTotal?.BookingBillingTotalCurrency,
                                    intl,
                                )}
                            </TableCell>
                        </TableRow>
                    </StyledTableBody>
                </StyledTable>

                <Grid container spacing={8} style={{ marginTop: '10px !important' }}>
                    {booking.BookingAddOrderAllowed && !booking.BookingOrders[0].BookingOrderIsSeasonPass && (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Button
                                variant="contained"
                                id="srtBookingAddOrder"
                                color="secondary"
                                fullWidth
                                onClick={handleOpenAddOrderDialog}
                            >
                                <FormattedMessage {...messages.btnAddOrder} />
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <ErrorBoundary
                    fallbackRender={(props) => (
                        <ErrorFallBack
                            {...props}
                            messageComponent={<FormattedMessage {...messages.lblBillingSummary} />}
                        />
                    )}
                >
                    <BillingSummary
                        booking={booking}
                        handleOpenAddFeeDiscountDialog={handleOpenAddFeeDiscountDialog}
                        enableBookingAddFee={enableBookingAddFee}
                        handleOpenMakePaymentDialog={handleOpenMakePaymentDialog}
                        handleOpenIssueRefundDialog={handleOpenIssueRefundDialog}
                    />
                </ErrorBoundary>
            </StyledPaper>
            {booking.BookingAddOrderAllowed && !booking.BookingOrders[0].BookingOrderIsSeasonPass && (
                <ErrorBoundary
                    fallbackRender={(props) => (
                        <ErrorFallBack
                            {...props}
                            messageComponent={<FormattedMessage {...messages.lblAddOrderDialogTitle} />}
                        />
                    )}
                >
                    <AddOrderDialog
                        ref={addOrderDialogRef}
                        queryItems={booking.queryItems}
                        onCloseAddOrderDialog={handleCloseAddOrderDialog}
                        open={bookingViewData.get('openAddOrderDialog')}
                        orderAccount={orderAccount}
                        passengers={booking.BookingPassengers}
                    />
                </ErrorBoundary>
            )}
            {bookingViewData.get('openMakePayment') && (
                <ErrorBoundary
                    fallbackRender={(props) => (
                        <ErrorFallBack
                            {...props}
                            messageComponent={<FormattedMessage {...messages.lblMakePaymentTitle} />}
                        />
                    )}
                >
                    <MakePaymentDialog
                        booking={booking}
                        onClose={handleCloseMakePaymentDialog}
                        open={bookingViewData.get('openMakePayment')}
                        handleWarning={handleMakePaymentWarning}
                    />
                </ErrorBoundary>
            )}
            <ErrorBoundary
                fallbackRender={(props) => (
                    <ErrorFallBack
                        {...props}
                        messageComponent={<FormattedMessage {...messages.lblRefundDueTitle} />}
                    />
                )}
            >
                <IssueRefundDialog
                    onClose={handleCloseIssueRefundDialog}
                    open={bookingViewData.get('openIssueRefundDialog')}
                    booking={booking}
                    issueRefund={booking.BookingBillingTotal?.BookingBillingRefundPossible}
                    smsCodeOptions={bookingViewData.get('smsCodeOptions').toJS()}
                />
            </ErrorBoundary>
            <ErrorBoundary
                fallbackRender={(props) => (
                    <ErrorFallBack
                        {...props}
                        messageComponent={<FormattedMessage {...messages.lblAddFeeDialogTitle} />}
                    />
                )}
            >
                <AddFeeDiscountDialog
                    onClose={handleCloseAddFeeDiscountDialog}
                    open={bookingViewData.get('openAddFeeDiscountDialog')}
                    booking={booking}
                />
            </ErrorBoundary>
        </StyledDivContainer>
    );
}

BookingSummary.propTypes = {
    booking: PropTypes.object.isRequired,
    bookingViewData: PropTypes.object.isRequired,
    handleCloseAddFeeDiscountDialog: PropTypes.func.isRequired,
    handleCloseAddOrderDialog: PropTypes.func.isRequired,
    handleCloseIssueRefundDialog: PropTypes.func.isRequired,
    handleCloseMakePaymentDialog: PropTypes.func.isRequired,
    handleOpenAddFeeDiscountDialog: PropTypes.func.isRequired,
    handleOpenAddOrderDialog: PropTypes.func.isRequired,
    handleOpenIssueRefundDialog: PropTypes.func.isRequired,
    handleOpenMakePaymentDialog: PropTypes.func.isRequired,
    intl: PropTypes.object,
    onRefresh: PropTypes.func.isRequired,
    orderAccount: PropTypes.object.isRequired,
    enableBookingAddFee: PropTypes.bool.isRequired,
    handleMakePaymentWarning: PropTypes.func.isRequired,
};

export { BookingSummary as BookingSummaryAlias };

export default connect(null, { onRefresh: fetchBookingDetails })(injectIntl(BookingSummary));
