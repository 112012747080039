import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS, is } from 'immutable';
import { injectIntl } from 'react-intl';
import isPlainObject from 'lodash/isPlainObject';

import { styled } from '@mui/system';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Flag from '@mui/icons-material/Flag';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';

import TableDataCell from './TableDataCell';
import RefreshIndicator from '../RefreshIndicator/RefreshIndicator';
import './tableView.css';
import messages from './messagesTableView';
import inlineStyles from './styles';
import SortableTableCell from './SortableTableCell';

const StyledTable = styled(Table)(() => ({
    ...inlineStyles.fixedTable,
}));

const StyledTableCell = styled(TableCell)(() => ({
    ...inlineStyles.rowItemBase,
}));

const getSizeOrString = (object) => (
    object.length === 1 ? object[0].value : object.length.toString()
);

// function for sorting various data types, adjusted to cover some of our special cases
const sortFunc = (a, b, key) => {
    let aa = a[key].value;
    let bb = b[key].value;
    if (typeof (aa) === 'number' || aa instanceof Date) {
        return aa - bb;
    }
    if (typeof (aa) === 'object') {
        aa = aa.length === 0 ? '' : getSizeOrString(aa);
        bb = bb.length === 0 ? '' : getSizeOrString(bb);
    }

    const ax = [];
    const bx = [];

    aa.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
        ax.push([$1 || Infinity, $2 || '']);
    });
    bb.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
        bx.push([$1 || Infinity, $2 || '']);
    });

    while (ax.length && bx.length) {
        const an = ax.shift();
        const bn = bx.shift();
        const nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
        if (nn) return nn;
    }

    return ax.length - bx.length;
};
class TableView extends Component {
    static propTypes = {
        clickHandler: PropTypes.func,
        expandClickHandler: PropTypes.func,
        data: PropTypes.array.isRequired,
        id: PropTypes.string,
        intl: PropTypes.object.isRequired,
        isProcessing: PropTypes.bool,
        isSortable: PropTypes.bool,
        responsive: PropTypes.bool,
        responsiveWidth: PropTypes.number,
        showFilter: PropTypes.bool,
        style: PropTypes.object,
        tableFooter: PropTypes.array,
        tableHeaders: PropTypes.array,
        useVirtualizedTable: PropTypes.bool,
        wrapperHeight: PropTypes.string.isRequired,
    };

    static defaultProps = {
        responsive: false,
        showFilter: true,
        useVirtualizedTable: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            dataStore: fromJS({
                data: props.data,
                isAsc: false,
                orderBy: null,
                showRowHover: true,
                filterValue: '',
            }),
        };
    }

    // eslint-disable-next-line react/sort-comp,camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!is(fromJS(this.props.data), fromJS(nextProps.data))) {
            this.setState((state) => ({ dataStore: state.dataStore.merge({ data: nextProps.data, filterValue: '' }) }));
        }
    }

    onFilterChange = ({ target: { value } }) => {
        const filterBy = value.toString().toLowerCase();

        const filteredList = this.props.data.filter((row) => {
            const rowFiltered = row.filter((element) => {
                const currValue = element.value;
                if (currValue instanceof Array && currValue.length > 0) {
                    return currValue.filter((newrow) => newrow.value.toString().toLowerCase().indexOf(filterBy) !== -1);
                }

                return element.value.toString().toLowerCase().indexOf(filterBy) !== -1;
            });

            return rowFiltered.length > 0;
        });

        this.setState((state) => ({ dataStore: state.dataStore.merge({ data: filteredList, filterValue: value }) }));
    };

    sortByColumn = (columnId) => (event) => {
        event.preventDefault();
        this.setState((state) => {
            const sortedData = state.dataStore.get('data').toJS();
            sortedData.sort((a, b) => sortFunc(a, b, columnId));
            const isAsc = state.dataStore.get('orderBy') === columnId ? !state.dataStore.get('isAsc') : true;
            if (!isAsc) {
                sortedData.reverse();
            }
            return {
                dataStore: state.dataStore.merge({
                    data: sortedData,
                    orderBy: columnId,
                    isAsc,
                }),
            };
        });
    };

    cellRenderer = ({ rowData, columnIndex = 0 }) => {
        const { clickHandler, expandClickHandler } = this.props;
        return (
            <TableDataCell
                id={`srtTableDataCell${columnIndex}`}
                column={Object.values(rowData)[columnIndex]}
                clickHandler={clickHandler}
                expandClickHandler={expandClickHandler}
                styles={this.props.style}
            />
        );
    };

    render() {
        const {
            clickHandler,
            expandClickHandler,
            isSortable,
            showFilter,
            tableFooter,
            tableHeaders,
            useVirtualizedTable,
            intl: { formatMessage },
        } = this.props;
        const responsiveWidth = this.props.responsiveWidth || 1500;
        const orderBy = this.state.dataStore.get('orderBy') !== null ? +this.state.dataStore.get('orderBy') : null;
        const isAsc = this.state.dataStore.get('isAsc');
        const order = isAsc ? 'asc' : 'desc';
        const rowItemStyle = this.props.style && this.props.style.rowItemStyle
            ? this.props.style.rowItemStyle : {
                ...inlineStyles.rowItemBase,
                ...inlineStyles.rowItem,
            };

        const tableData = this.state.dataStore.get('data').toJS();

        const detail = (
            <StyledTable className={`${this.props.id}_body`}>
                <TableBody>
                    {tableData.map((row, index) => (
                        <TableRow
                            id={`srtTableRow${index}`}
                            key={index}
                            className={`srtTableRow${index}`}
                            hover={this.state.dataStore.get('showRowHover')}
                        >
                            {Object.keys(row).map((prop, ind) => (
                                <TableCell
                                    id={`srtTableColumn${ind}`}
                                    key={ind}
                                    style={rowItemStyle}
                                    styleName={row[prop].styleName}
                                >
                                    <div>
                                        {row[0].displayFlag && ind === 0
                                            && <Flag styleName="flagIcon" color="error" style={{ left: 2 }} />}

                                        <TableDataCell
                                            id={`srtTableDataCell${ind}`}
                                            column={row[prop]}
                                            clickHandler={clickHandler}
                                            expandClickHandler={expandClickHandler}
                                            styles={this.props.style}
                                        />
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        );

        const wrapperStyle = {
            overflowY: useVirtualizedTable ? 'inherit' : 'auto',
            height: this.props.wrapperHeight,
            minWidth: this.props.responsive ? responsiveWidth : '100%',
        };

        return (
            <div>
                {
                    showFilter
                        ? (
                            <div styleName="filterRows">
                                <TextField
                                    id="srtTableFilter"
                                    placeholder={formatMessage(messages.lblFilter)}
                                    onChange={this.onFilterChange}
                                    value={this.state.dataStore.get('filterValue')}
                                    variant="standard"
                                />
                            </div>
                        ) : null
                }
                <div style={this.props.responsive ? { overflowX: 'auto' } : undefined}>
                    <div style={wrapperStyle}>
                        <StyledTable className={`${this.props.id}_header`}>
                            <TableHead>
                                <TableRow>
                                    {tableHeaders.map((row, index) => {
                                        const rowObject = isPlainObject(row) && !React.isValidElement(row) ? row : { text: row };
                                        return isSortable ? (
                                            <SortableTableCell
                                                key={index}
                                                index={index}
                                                orderBy={orderBy}
                                                order={order}
                                                sortByColumn={this.sortByColumn}
                                                rowObject={rowObject}
                                            />
                                        ) : (
                                            <TableCell
                                                key={index}
                                                align={rowObject.align}
                                            >
                                                {rowObject.text}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                        </StyledTable>
                        {detail}
                        {tableFooter
                            ? (
                                <StyledTable>
                                    <TableBody>
                                        {tableFooter.map((row, index) => (
                                            <TableRow key={index} styleName="tableRowTopBorder">
                                                {tableFooter[index].map((item, key) => (
                                                    <StyledTableCell
                                                        id={item.id}
                                                        styleName={item.styleName}
                                                        key={key}
                                                    >
                                                        {item.value}
                                                    </StyledTableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </StyledTable>
                            )
                            : null}
                    </div>
                </div>
                <div className="container-fluid">
                    <RefreshIndicator
                        size={36}
                        top={0}
                        left={0}
                        status={this.props.isProcessing ? 'loading' : 'hide'}
                    // className={classes.refresh}
                    />
                </div>
            </div>
        );
    }
}

// This alias will be used to access bare component for unit testing
export { TableView as TableViewAlias };

export default injectIntl(TableView);
