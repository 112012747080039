import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import '../../ManageBooking/components/BookingView/styleBookingView.css';
import { getFareRules } from '../../utils';
import FareRulesMatrix from '../../../Shopping/components/Fare/FareRulesMatrix';
import StationInformationDialog from '../../../components/StationInformation/StationInformationDialog';
import TravelSegments from './TravelSegments';
import { gaEvent } from '../../../utils/googleAnalytics';

import { StyledTablCell, InfoBox } from './styled';
import messages from './messages';
import { ORIGINS, closeFareRulesGA, viewFareRulesGA } from './constants';

const OrderDetailsTable = ({
    fareRulesDetails,
    intl,
    isExchange,
    isSingleTicketableFares,
    orderDetails,
    showIntermediateStops = false,
    orderPriceKey = 'BookingDetailsOrderTotalPrice',
    defaultSelectionState = false,
    onTicketableFareChange,
    selectedTicketableFareId,
    origin,
    radioTitle,
    handleSelectAll,
    ticketableFaresSelectable,
    promotionDetails,
    bookingOrderRevisions,
    isPartialCancel,
    isSplitTicketFare,
}) => {
    const initialFareRulesState = {
        fareRulesTicketableFareGroupIndex: null,
        fareRulesTravelSegment: null,
    };
    const [fareRules, setFareRules] = useState(initialFareRulesState);
    const [stationCode, setStationCode] = useState(null);

    const handleCloseFareRulesMatrix = () => {
        const event = closeFareRulesGA[origin];
        gaEvent(event);
        setFareRules(initialFareRulesState);
    };

    const handleOpenFareRulesMatrix = (travelSegment, ticketableFareGroupIndex) => () => {
        const event = viewFareRulesGA[origin];
        gaEvent(event);

        setFareRules({
            fareRulesTicketableFareGroupIndex: ticketableFareGroupIndex,
            fareRulesTravelSegment: travelSegment,
        });
    };

    const handleCloseStationInfoDialog = () => {
        setStationCode(null);
    };

    const handleOpenStationInfoDialog = (code) => () => {
        setStationCode(code);
    };

    const {
        fareRulesTicketableFareGroupIndex,
        fareRulesTravelSegment,
    } = fareRules;

    const ticketableFareGroups = orderDetails?.ticketableFareGroups ?? [];

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        {radioTitle
                            && (
                                <TableCell>
                                    {radioTitle}
                                </TableCell>
                            )}
                        <TableCell>
                            <FormattedMessage {...messages.lblCarrier} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblType} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblDesignator} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblOrigin} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblDestination} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblDuration} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblCabinClass} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblFare} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblSeats} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblAmenitiesServices} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblPrice} />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ticketableFaresSelectable && !isExchange
                        && (
                            <>
                                <TableRow>
                                    {isPartialCancel && (
                                        <StyledTablCell colSpan="2">
                                            <div styleName="selectAll">
                                                <a styleName="linkSelect" onClick={() => handleSelectAll(true)}>
                                                    <FormattedMessage {...messages.lblSelectAll} />
                                                </a>
                                                <a styleName="linkSelect" onClick={() => handleSelectAll(false)}>
                                                    <FormattedMessage {...messages.lblDeselectAll} />
                                                </a>
                                            </div>
                                        </StyledTablCell>
                                    )}
                                    {!isPartialCancel && (
                                        <StyledTablCell colSpan="12">
                                            <InfoBox><FormattedMessage {...messages.lblAllTicketsCancel} /></InfoBox>
                                        </StyledTablCell>
                                    )}
                                </TableRow>
                            </>
                        )}
                    <TravelSegments
                        fareRulesDetails={fareRulesDetails}
                        intl={intl}
                        isSingleTicketableFares={isSingleTicketableFares}
                        orderDetails={orderDetails}
                        showIntermediateStops={showIntermediateStops}
                        orderPriceKey={orderPriceKey}
                        defaultSelectionState={defaultSelectionState}
                        onTicketableFareChange={onTicketableFareChange}
                        selectedTicketableFareId={selectedTicketableFareId}
                        ticketableFaresSelectable={ticketableFaresSelectable}
                        origin={origin}
                        handleOpenFareRulesMatrix={handleOpenFareRulesMatrix}
                        handleOpenStationInfoDialog={handleOpenStationInfoDialog}
                        promotionDetails={promotionDetails}
                        radioTitle={radioTitle}
                        bookingOrderRevisions={bookingOrderRevisions}
                        isPartialCancel={isPartialCancel}
                        isSplitTicketFare={isSplitTicketFare}
                    />
                </TableBody>
            </Table>
            <FareRulesMatrix
                open={fareRulesTicketableFareGroupIndex !== null}
                handleClose={handleCloseFareRulesMatrix}
                fareRulesDetails={fareRulesTicketableFareGroupIndex !== null ? getFareRules(fareRulesTravelSegment, fareRulesDetails) : []}
                paxTitle={fareRulesTicketableFareGroupIndex !== null
                    ? ticketableFareGroups[fareRulesTicketableFareGroupIndex]?.description
                    : ''}
                isBookingPage={origin === ORIGINS.BOOKING}
                exchangeOrderMode={origin === ORIGINS.EXCHANGE}
                isCancelOrderPage={origin === ORIGINS.CANCEL}
                isVoidOrderPage={origin === ORIGINS.VOID}
            />
            <StationInformationDialog
                stationCode={stationCode}
                open={!!stationCode}
                onClose={handleCloseStationInfoDialog}
            />
        </>
    );
};

OrderDetailsTable.propTypes = {
    fareRulesDetails: PropTypes.array,
    intl: PropTypes.object,
    isSingleTicketableFares: PropTypes.bool,
    onTicketableFareChange: PropTypes.func,
    orderDetails: PropTypes.object,
    handleSelectAll: PropTypes.func,
    orderPriceKey: PropTypes.string,
    origin: PropTypes.oneOf(Object.values(ORIGINS)),
    radioTitle: PropTypes.node,
    selectedTicketableFareId: PropTypes.string,
    showIntermediateStops: PropTypes.bool,
    defaultSelectionState: PropTypes.bool,
    ticketableFaresSelectable: PropTypes.array,
    isExchange: PropTypes.bool,
    promotionDetails: PropTypes.array,
    bookingOrderRevisions: PropTypes.array,
    isPartialCancel: PropTypes.bool,
    isSplitTicketFare: PropTypes.bool,
};

export { OrderDetailsTable as OrderDetailsTableAlias };

export default injectIntl(OrderDetailsTable);
