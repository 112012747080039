import { fromJS } from 'immutable';
import {
    INITIATE_REQUEST,
    RECEIVE_BOOKING,
    RECEIVE_BOOKING_SEARCH,
    CLEAR_SEARCH_RESULTS,
    CLOSE_BOOKING,
    NEW_BOOKING_REQUEST,
    CLEAR_NEW_BOOKING_REQUEST,
    CLEAR_ERROR_MESSAGE,
    SET_LAST_ACTIVE_BOOKING_TAB,
    CLEAR_CANCEL_ORDER_ID,
    SET_CANCEL_ORDER_ID,
    CLEAR_SEND_TICKET_NEW_ORDER,
    SET_SEND_TICKET_NEW_ORDER,
    SET_OPEN_ISSUE_REFUND_DIALOG,
    CLEAR_OPEN_ISSUE_REFUND_DIALOG,
    SET_ORDER_DETAILS,
    SET_SEATMAP_DATA,
    SET_EXCHANGE_VALIDATE,
    CLEAR_EXCHANGE_VALIDATE,
} from './actionsManageBooking';
import { USER_LOGOUT } from '../../constants/constants';
import { gaSetAccount } from '../../utils/googleAnalytics';
import { getContext } from './utils';

const initialState = fromJS({
    isFetching: false,
    lastActiveTab: '',
    searchResults: [],
    bookingDetails: {},
    errorMessage: '',
    warningMessage: '',
    newBookingDetails: {},
    bookingAccountData: {},
    cancelOrderId: '',
    sendTicketNewOrderId: '',
    isSendTicketPossible: false,
    dialogs: {
        issueRefundDialog: false,
    },
    orderDetails: {},
    seatMap: [],
    isExchangeValidateFailed: false,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_OPEN_ISSUE_REFUND_DIALOG:
            return state.setIn(['dialogs', 'issueRefundDialog'], false);
        case SET_OPEN_ISSUE_REFUND_DIALOG:
            return state.setIn(['dialogs', 'issueRefundDialog'], true);
        case CLEAR_CANCEL_ORDER_ID:
            return state.merge({
                cancelOrderId: '',
            });
        case SET_CANCEL_ORDER_ID:
            return state.merge({
                cancelOrderId: action.cancelOrderId,
            });
        case CLEAR_SEND_TICKET_NEW_ORDER:
            return state.merge({
                sendTicketNewOrderId: '',
                isSendTicketPossible: false,
            });
        case SET_SEND_TICKET_NEW_ORDER:
            return state.merge({
                sendTicketNewOrderId: action.sendTicketNewOrderId,
                isSendTicketPossible: action.isSendTicketPossible,
            });
        case NEW_BOOKING_REQUEST:
            return state.merge({ newBookingDetails: action.queryItems });
        case CLEAR_NEW_BOOKING_REQUEST:
            return state.merge({ newBookingDetails: {} });
        case INITIATE_REQUEST:
            return state.merge({
                isFetching: true,
            });
        case SET_LAST_ACTIVE_BOOKING_TAB: {
            const bookingDetail = state.getIn(['bookingDetails', action.activeTab]);
            if (bookingDetail) {
                const queryItems = bookingDetail.toJS ? bookingDetail.get('queryItems').toJS() : bookingDetail.queryItems;
                gaSetAccount(getContext(queryItems));
            }

            return state.merge({
                lastActiveTab: action.activeTab,
            });
        }
        case RECEIVE_BOOKING_SEARCH: {
            const updates = {
                isFetching: false,
                errorMessage: '',
                searchResults: [],
            };

            if (action.error) {
                updates.errorMessage = action.payload;
            } else {
                updates.searchResults = action.payload;
            }

            return state.merge(updates);
        }
        case RECEIVE_BOOKING: {
            const updates = {
                isFetching: false,
                errorMessage: '',
                warningMessage: '',
            };

            const details = Object.assign(action.payload, { queryItems: action.queryItems });

            if (action.error) {
                updates.errorMessage = action.payload;
            } else {
                updates.warningMessage = action?.warningMessage || '';
                updates.bookingDetails = state.get('bookingDetails').setIn([details.BookingRecordLoc], details);
            }

            return state.merge(updates);
        }
        case CLEAR_SEARCH_RESULTS:
            return state.merge({
                searchResults: [],
                isFetching: false,
                errorMessage: '',
            });
        case CLEAR_ERROR_MESSAGE:
            return state.merge({
                errorMessage: '',
                warningMessage: '',
            });
        case CLOSE_BOOKING:
            return state.deleteIn(['bookingDetails', action.recordLocator]);
        case USER_LOGOUT:
            return initialState;
        case SET_ORDER_DETAILS:
            return state.merge({ orderDetails: action.payload });
        case SET_SEATMAP_DATA:
            return state.merge({ seatMap: action.payload });
        case SET_EXCHANGE_VALIDATE:
            return state.merge({ isExchangeValidateFailed: action.payload });
        case CLEAR_EXCHANGE_VALIDATE:
            return state.merge({ isExchangeValidateFailed: false });
        default:
            return state;
    }
};
