import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { StyledFab } from './styled';

const FloatingButton = ({ settings }) => {
    const handleClick = () => {
        if (settings['ws.system.userfeedback.url']) {
            window.open(settings['ws.system.userfeedback.url']);
        }
    };

    if (!settings['ws.system.userfeedback.url']) return null;

    return (
        <StyledFab variant="extended" onClick={handleClick}>
            Feedback
        </StyledFab>
    );
};

FloatingButton.propTypes = {
    settings: PropTypes.object,
};

const mapStateToProps = (state) => ({
    settings: state.get('settings').toJS(),
});

export default connect(mapStateToProps, null)(FloatingButton);
