import validate from 'validate.js';

import messages from './messagesShoppingSearch';
import {
    TRIP_TYPE_RETURN,
    TRIP_TYPE_SEASON,
    CORPORATE,
    UK_CORPORATE,
    UK_TADA,
    PROMOTION,
} from '../util';

const weekInMillis = 604800000;
const yearWeeksInMillis = 52 * weekInMillis;

validate.extend(validate.validators.datetime, {
    parse: (date) => new Date(date),
    format: (timestamp) => new Date(timestamp).toLocaleDateString(),
});

const constructStationExclusionList = (attributes, checkItem) => {
    const checkAttributes = ['departCode', 'arriveCode', 'viaAvoidCodeOutbound', 'viaAvoidCodeInbound'];
    const needsCheck = checkAttributes.filter((item) => (!checkItem.includes(item) && attributes[item] !== ''));
    return needsCheck.map((item) => attributes[item]);
};

const station = (formatMessage, attributes, type) => ({
    presence: {
        message: formatMessage(messages.errNoStation), allowEmpty: false,
    },
    exclusion: {
        within: constructStationExclusionList(attributes, [type]),
        message: formatMessage(messages.errStationNoMatch),
    },
});

const dateOptions = {
    departDate: () => ({
        earliest: Date.now(),
        earliestSeasonAdd: 0,
        tooLateMessageSeason: messages.errStartDateAfterLimitSeason,
        tooEarlyMessage: messages.errNotValidDate,
        returnCondition: true,
    }),
    returnDate: (attributes) => ({
        earliest: attributes.departDate,
        earliestSeasonAdd: 7,
        latestSeasonAdd: yearWeeksInMillis,
        tooLateMessageSeason: messages.errEndDateAfterLimitSeason,
        tooEarlyMessage: messages.errReturnDateEarlier,
        returnCondition: attributes.tripType === TRIP_TYPE_RETURN || (attributes.tripType === TRIP_TYPE_SEASON && attributes.returnDate),
    }),
};

const dateObject = (formatMessage, attributes, option) => {
    const optionValues = option(attributes);
    const earliest = new Date(optionValues.earliest);
    let latest;
    let tooLateMessage;
    const isSeason = attributes.tripType === TRIP_TYPE_SEASON;
    if (isSeason) {
        earliest.setHours(0, 0, 0, 0);
        earliest.setDate(earliest.getDate() + optionValues.earliestSeasonAdd);
        latest = new Date(earliest.getTime() + optionValues.latestSeasonAdd);
        tooLateMessage = formatMessage(optionValues.tooLateMessageSeason);
    }
    if (optionValues.returnCondition) {
        return {
            presence: {
                message: formatMessage(messages.errNotValidDate), allowEmpty: false,
            },
            datetime: {
                earliest,
                latest,
                notValid: `^${formatMessage(messages.errNotValidDate)}`,
                tooEarly: `^${formatMessage(optionValues.tooEarlyMessage)}`,
                tooLate: `^${tooLateMessage}`,
            },
        };
    }
    return null;
};

export default (formatMessage) => ({
    departCode: (attribute, attributes) => station(formatMessage, attributes, 'departCode'),
    departDate: (attribute, attributes) => dateObject(formatMessage, attributes, dateOptions.departDate),
    arriveCode: (attribute, attributes) => station(formatMessage, attributes, 'arriveCode'),
    returnDate: (attribute, attributes) => dateObject(formatMessage, attributes, dateOptions.returnDate),
    accountName: {
        presence: {
            message: formatMessage(messages.errNoAccount), allowEmpty: false,
        },
    },
    viaAvoidCodeOutbound: (attribute, attributes) => ({
        exclusion: {
            within: constructStationExclusionList(attributes, ['viaAvoidCodeOutbound', 'viaAvoidCodeInbound']),
            message: formatMessage(messages.errStationNoMatch),
        },
    }),
    viaAvoidCodeInbound: (attribute, attributes) => ({
        exclusion: {
            within: constructStationExclusionList(attributes, ['viaAvoidCodeOutbound', 'viaAvoidCodeInbound']),
            message: formatMessage(messages.errStationNoMatch),
        },
    }),
});

export const paxAgeConstraints = (items) => (
    // make sure at least one passenger is older then 16
    items.some((item) => item > 15));

export const discountConstraints = (discounts, formatMessage, railCards) => {
    const discountErrors = [];
    const errorMsg = formatMessage(messages.errNoRailcardId);
    discounts.forEach((discount, index) => {
        if (discount.discountProgram !== null) {
            const discountCard = railCards[Object.keys(railCards).find((key) => railCards[key].RailCardProgram === discount.discountProgram)];
            const discountType = discountCard.RailCardType;
            const discountIdReq = discountCard.RailCardIsIDRequired;
            const isDiscountIdRequired = discountIdReq && (discountType === CORPORATE
                || discountType === PROMOTION || discountCard.RailCardProgram === UK_CORPORATE || discountCard.RailCardProgram === UK_TADA);
            if (isDiscountIdRequired && discount.railcardId === '') {
                discountErrors.push({ index, error: errorMsg });
            }
        }
    });
    return discountErrors;
};
