import commonStyles from '../../../../commonStyles';
import bookingViewStyles from '../BookingView/styles';
import { black } from '../../../../colors';

export default {
    tableWrapper: commonStyles.tableWrapper,
    bookingTable: bookingViewStyles.bookingTable,
    redButton: bookingViewStyles.redButton,
    orangeButton: bookingViewStyles.orangeButton,
    linkTitle: {
        cursor: 'pointer',
    },
    orderTitle: {
        fontSize: '1.5em',
    },
    orderSubtitle: {
        fontSize: '0.9em',
        fontWeight: 500,
        color: black,
    },
    orderSubtitleFirst: {
        marginLeft: 8,
    },
    buttons: {
        marginTop: 10,
    },
    summary: {
        alignItems: 'center',
    },
    panelDetails: {
        flexDirection: 'column',
    },
    expansionPanelExpanded: {
        margin: 0,
        '&:before': {
            opacity: 1,
        },
    },
    expansionPanel: {
        '&:before': {
            display: 'block !important',
        },
    },
};
