import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { injectIntl } from 'react-intl';
import messages from './messagesPlusBusSupplements';
import inlineStyles from './styles';

const PlusBusSupplementActions = ({
    handleSave,
    handleDiscard,
    intl: { formatMessage },
}) => (
    <div className="row" style={inlineStyles.dialogActionsRoot}>
        <div className="col-4 offset-3 offset-md-4">
            <Button
                variant="contained"
                id="srtPlusBusSupplementDiscardChanges"
                onClick={handleDiscard}
                fullWidth
            >
                {formatMessage(messages.btnDiscardChanges)}
            </Button>
        </div>
        <div className="col-5 col-md-4">
            <Button
                variant="contained"
                id="srtSavePlusBusSupplement"
                onClick={handleSave}
                color="primary"
                fullWidth
            >
                {formatMessage(messages.btnSavePlusBusSupplements)}
            </Button>
        </div>
    </div>
);

PlusBusSupplementActions.propTypes = {
    handleSave: PropTypes.func.isRequired,
    handleDiscard: PropTypes.func.isRequired,
    intl: PropTypes.object,
};

export { PlusBusSupplementActions as PlusBusSupplementActionsAlias };

export default injectIntl(PlusBusSupplementActions);
