import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { fromJS } from 'immutable';
import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import messages from './messagesExchangeOrder';

import { formatPrice } from '../../../utils';

import styles from './styles';

const StyledAppBar = styled(AppBar)({
    ...styles.headerSelectedAppBar,
});

const StyledTable = styled('table')({
    ...styles.table,
});

const StyledHeaderSelectedLabel = styled(Typography)({
    ...styles.headerSelectedLabel,
});

const StyledPassengers = styled(Typography)({
    ...styles.passengers,
});

const StyledHeaderTable = styled(Table)({
    ...styles.headerTable,
});

const StyledHeaderTableSelectedRow = styled(TableRow)({
    ...styles.headerSelectedRow,
});

const ExchangeOrderTitle = ({
    intl,
    intl: {
        formatMessage,
    },
    exchangeableTicketableFareIds,
    orderDetails,
    isSeasonTrip,
    seasonFareDetails,
    ticketableFareGroups,
    showSeatsAndServices,
    selectedSeat,
}) => {
    const immutableTicketableFareGroups = ticketableFareGroups?.length
        ? fromJS(ticketableFareGroups)
        : orderDetails.getIn(['BookingOrders', 0, 'ticketableFareGroups']);

    const selectedTicketableFareGroup = immutableTicketableFareGroups.filter(
        (ticketableFareGroup) => exchangeableTicketableFareIds.includes(ticketableFareGroup.get('BookingDetailsLegTicketableFareIds')),
    );

    return (
        <StyledAppBar position="static" color="default">
            <Toolbar variant="dense">
                <StyledTable>
                    <tbody>
                        <tr>
                            <th>
                                <StyledHeaderSelectedLabel variant="body1">
                                    <FormattedMessage {...messages.lblPassengers} />
                                </StyledHeaderSelectedLabel>
                            </th>
                            <th>
                                <StyledPassengers variant="caption">
                                    {selectedTicketableFareGroup
                                        .getIn([0, 'passengers'])
                                        ?.toJS()
                                        .sort((a, b) => (a.passengerId > b.passengerId ? 1 : -1))
                                        .map((passenger) => `${passenger.firstName} ${passenger.lastName}`)
                                        .join(', ')}
                                </StyledPassengers>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <StyledHeaderSelectedLabel variant="body1">
                                    <FormattedMessage {...messages.lblExchangedHeader} />
                                </StyledHeaderSelectedLabel>

                            </th>
                            <th>
                                <StyledHeaderTable>
                                    <TableHead>
                                        <StyledHeaderTableSelectedRow>
                                            <TableCell>
                                                {formatMessage(isSeasonTrip ? messages.lblOrigin : messages.lblDepartStation)}
                                            </TableCell>
                                            <TableCell>
                                                {formatMessage(isSeasonTrip ? messages.lblDestination : messages.lblDepartDate)}
                                            </TableCell>
                                            <TableCell>
                                                {formatMessage(isSeasonTrip ? messages.lblSeasonStartDate : messages.lblArriveStation)}
                                            </TableCell>
                                            <TableCell>
                                                {formatMessage(isSeasonTrip ? messages.lblSeasonEndDate : messages.lblArriveDate)}
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage {...messages.lblPrice} />
                                            </TableCell>
                                            {showSeatsAndServices && (
                                                <>
                                                    <TableCell>
                                                        Seats
                                                    </TableCell>
                                                    <TableCell>
                                                        Amenities & Services
                                                    </TableCell>
                                                </>
                                            )}
                                        </StyledHeaderTableSelectedRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedTicketableFareGroup.map((selectedFare) => (
                                            <StyledHeaderTableSelectedRow
                                                key={selectedFare.get('BookingDetailsLegTicketableFareIds')}
                                            >
                                                <TableCell>{isSeasonTrip ? seasonFareDetails.get('departStationName') : selectedFare.get('departStationLongName')}</TableCell>
                                                <TableCell>{isSeasonTrip ? seasonFareDetails.get('arriveStationName') : selectedFare.get('departDate')}</TableCell>
                                                <TableCell>{isSeasonTrip ? seasonFareDetails.get('startDate') : selectedFare.get('arriveStationLongName')}</TableCell>
                                                <TableCell>{isSeasonTrip ? seasonFareDetails.get('endDate') : selectedFare.get('arriveDate')}</TableCell>
                                                <TableCell>
                                                    {formatPrice(
                                                        selectedFare.get('ticketableFarePrice'),
                                                        selectedFare.get('ticketableFareCurrency'),
                                                        intl,
                                                    )}
                                                </TableCell>
                                                {showSeatsAndServices && (
                                                    <>
                                                        <TableCell>
                                                            {selectedSeat ? selectedSeat : selectedFare.get('BookingLegTravelSegments').toJS()[0].BookingLegTravelSegmentSeats}
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedFare.get('BookingLegTravelSegments').toJS()[0].BookingOrderServicesAndAmenities.map(
                                                                (item) => <span>{item.BookingOrderServicesAndAmenitiesDesc} <br /></span>
                                                            )}
                                                        </TableCell>
                                                    </>
                                                )}
                                            </StyledHeaderTableSelectedRow>
                                        ))}
                                    </TableBody>
                                </StyledHeaderTable>
                            </th>
                        </tr>
                    </tbody>
                </StyledTable>
            </Toolbar>
        </StyledAppBar>
    );
};

ExchangeOrderTitle.propTypes = {
    intl: PropTypes.object,
    exchangeableTicketableFareIds: PropTypes.array,
    orderDetails: PropTypes.object,
    isSeasonTrip: PropTypes.bool,
    seasonFareDetails: PropTypes.object,
    ticketableFareGroups: PropTypes.array,
    showSeatsAndServices: PropTypes.bool,
    selectedSeat: PropTypes.string,
};

export { ExchangeOrderTitle as ExchangeOrderTitleAlias };

export default injectIntl(ExchangeOrderTitle);
