import { defineMessages } from 'react-intl';

export default defineMessages({
    lblPedestrian: {
        id: 'app.shopping.fare.fareLeg.pedestrian',
        defaultMessage: 'Pedestrian',
    },
    lblTrain: {
        id: 'app.shopping.fare.fareLeg.train',
        defaultMessage: 'Train',
    },
    lblConnectionTime: {
        id: 'app.shopping.fare.fareLeg.connectionTime',
        defaultMessage: 'connection time',
    },
    lblDays: {
        id: 'app.shopping.fare.fareLeg.days',
        defaultMessage: 'days',
    },
    lblDay: {
        id: 'app.shopping.fare.fareLeg.day',
        defaultMessage: 'day',
    },
    lblFareRules: {
        id: 'app.shopping.fare.fareLeg.fareRules',
        defaultMessage: '{legType} Fare Rules',
    },
    lblPassenger: {
        id: 'app.shopping.fare.fareLeg.passenger',
        defaultMessage: 'Passenger',
    },
    lblOvertaken: {
        id: 'app.shopping.fare.fareLeg.overtaken',
        defaultMessage: '(o) indicates an overtaken journey',
    },
    lblUnconfirmedSchedule: {
        id: 'app.shopping.fare.fareLeg.unconfirmedSchedule',
        defaultMessage: '(u) indicates an unconfirmed schedule',
    },
    lblJourney: {
        id: 'app.shopping.fare.fareLeg.journey',
        defaultMessage: 'Journey',
    },
});
