import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import qs from 'qs';
import { injectIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Language from '@mui/icons-material/Language';
import { styled } from '@mui/system';
import validate from 'validate.js';
import { connect } from 'react-redux';

import { changeLanguage } from '../actions/settingsActions';

import {
    getAccessTokenApi,
    getRefreshTokenApi,
    isAuthApi,
} from './apiLogin';

import { getBasePath, processTokenResponse } from '../utils';
import RefreshIndicator from '../components/RefreshIndicator/RefreshIndicator';
import Header from '../components/Header/Header';
import ForgotPassword from './components/ForgotPassword/ForgotPasswordDialog';
import PasswordUpdate from '../components/PasswordUpdate/PasswordUpdate';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import { getAuthorizedRoutes } from './components/SecureRoute';
import messages from './messagesLogin';
import ExtendedSnackbar from '../components/ExtendedSnackbar/ExtendedSnackbar';
import SelectLocale from '../components/SelectLocale/SelectLocale';
import { gaEvent } from '../utils/googleAnalytics';
import inlineStyles from './styles';

import { setAccessToken, setAccessTokenExpiryTime } from '../actions/authActions';
import { LOCAL_STORAGE_KEYS } from '../constants/constants';

const FlatButton = styled(Button)(() => ({
    ...inlineStyles.flatButton,
    '& .MuiButton-label': {
        ...inlineStyles.flatButtonLabel,
    },
}));
class Login extends Component {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        setAccessToken: PropTypes.func,
        changeLanguage: PropTypes.func,
        setAccessTokenExpiryTime: PropTypes.func,
        language: PropTypes.string,
        location: PropTypes.object,
        history: PropTypes.object,
    };

    initialState = {
        username: '',
        password: '',
        processingSubmit: false,
        isFetching: false,
        forgotPasswordDlgOpen: false,
        passwordUpdateDlgOpen: false,
        tnCDlgOpen: false,
        alertText: '',
        errors: {},
    };

    constructor(props) {
        super(props);
        this.state = { data: fromJS(this.initialState) };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.handleInitialAuthCheck();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleInitialAuthCheck = () => {
        const { location } = this.props;
        const query = qs.parse(location?.search, { ignoreQueryPrefix: true });

        if (query?.username && query?.token) {
            this.handlePasswordUpdateDlgOpen();
        } else if (query?.action === 'forgotuserpassword') {
            this.handleForgotPasswordDlgOpen();
        } else {
            this.checkAuthAndRedirect();
        }
    };

    checkAuthAndRedirect = () => {
        const { history, location } = this.props;
        const isLoggedOut = localStorage.getItem(LOCAL_STORAGE_KEYS.loggedOut);
        const hasTriedRefresh = localStorage.getItem(LOCAL_STORAGE_KEYS.hasTriedRefresh);

        if (hasTriedRefresh !== 'true') {
            getRefreshTokenApi((err) => {
                this.setState((state) => ({ data: state.data.merge({ isFetching: false }) }));
                throw err;
            }, (response) => {
                this.setState((state) => ({ data: state.data.merge({ isFetching: false }) }));

                localStorage.removeItem(LOCAL_STORAGE_KEYS.hasTriedRefresh);

                const { newToken, newExpiryTime } = processTokenResponse(response);

                this.props.setAccessToken(newToken);
                this.props.setAccessTokenExpiryTime(newExpiryTime);

                isAuthApi(() => {
                }, (res) => {
                    if (res?.successResponse?.data?.SecurityAuthenticated === 'true') {
                        const routes = getAuthorizedRoutes(res.successResponse.data.SecurityAuthorizedCategories);
                        if (isLoggedOut === 'true') {
                            history.push('/');
                        }
                        if (!isLoggedOut || isLoggedOut === 'false') {
                            history.push(location.state?.from || `${getBasePath()}${routes[0]}`);
                        }
                    }
                });
            });
        }
    };

    constraints = () => {
        const { intl: { formatMessage } } = this.props;
        return {
            username: {
                presence: {
                    message: formatMessage(messages.errUsernameEmpty), allowEmpty: false,
                },
            },
            password: {
                presence: {
                    message: formatMessage(messages.errPasswordEmpty), allowEmpty: false,
                },
            },
        };
    }

    handleUsernameChange = ({ target: { value } }) => {
        this.setState((state) => ({ data: state.data.merge({ username: value }) }));
    };

    handlePasswordChange = ({ target: { value } }) => {
        this.setState((state) => ({ data: state.data.merge({ password: value }) }));
    };

    handleLanguageChange = ({ target: { value } }) => {
        this.props.changeLanguage(value);
    };

    handleSubmit = () => {
        const { intl: { formatMessage }, history, location } = this.props;
        this.setState((state) => ({ data: state.data.merge({ errors: {}, processingSubmit: true }) }));
        const errors = validate(this.state.data.toJS(), this.constraints());

        if (!validate.isEmpty(errors)) {
            this.setState((state) => ({ data: state.data.merge({ errors, processingSubmit: false }) }));
            return;
        }

        const params = {
            username: this.state.data.get('username'),
            password: this.state.data.get('password'),
        };

        getAccessTokenApi(
            params,
            (response) => {
                this.setState((state) => ({
                    data: state.data.merge({
                        alertText: response.errorResponse.message,
                        processingSubmit: false,
                    }),
                }));
            },
            (response) => {
                let alertText = '';
                this.setState((state) => ({
                    data: state.data.merge({
                        processingSubmit: false,
                    }),
                }));
                if (response.successResponse) {
                    const authenticated = response.successResponse.data.SecurityAuthenticated;
                    const token = response.successResponse.data.SecurityAccessToken;
                    const expiresIn = response.successResponse.data.SecurityExpiresIn;

                    if (token) {
                        const currentTime = Date.now();
                        const tokenExpiryTime = currentTime + (expiresIn * 1000);

                        this.props.setAccessToken(token);
                        this.props.setAccessTokenExpiryTime(tokenExpiryTime);
                    }

                    if (authenticated === 'true') {
                        localStorage.setItem(LOCAL_STORAGE_KEYS.loggedOut, 'false');
                        localStorage.removeItem(LOCAL_STORAGE_KEYS.hasTriedRefresh);
                        // Redirect to shopping page which will redirect to proper authorized page
                        history.push(location.state?.from || `${getBasePath()}shopping`);
                    } else {
                        const needTandC = response.successResponse.data.SecurityTandCNeeded;
                        if (needTandC) {
                            this.setState((state) => ({
                                data: state.data.merge({
                                    tnCDlgOpen: true,
                                }),
                            }));
                        } else {
                            // unknown error
                            alertText = formatMessage(messages.errLoginAcceptTerms);
                            this.setState((state) => ({
                                data: state.data.merge({
                                    alertText,
                                }),
                            }));
                        }
                    }
                } else {
                    alertText = formatMessage(messages.errPerformLogin);
                    this.setState((state) => ({
                        data: state.data.merge({
                            alertText,
                        }),
                    }));
                }
            },
        );
    };

    handleForgotPasswordDlgOpen = () => {
        gaEvent('loginForgotPasswordInitiate');
        this.setState((state) => ({ data: state.data.merge({ forgotPasswordDlgOpen: true }) }));
    };

    handleForgotPasswordDlgClose = () => {
        gaEvent('loginForgotPasswordClose');
        this.setState((state) => ({ data: state.data.merge({ forgotPasswordDlgOpen: false }) }));
    };

    handleTnCDlgClose = () => {
        this.setState((state) => ({ data: state.data.merge({ tnCDlgOpen: false }) }));
    };

    handlePasswordUpdateDlgOpen = () => {
        this.setState((state) => ({ data: state.data.merge({ passwordUpdateDlgOpen: true }) }));
    };

    handlePasswordUpdateDlgClose = (requestNewLink = false) => {
        this.setState((state) => ({
            data: state.data.merge({
                forgotPasswordDlgOpen: requestNewLink,
                passwordUpdateDlgOpen: false,
            }),
        }));
    };

    handleSnackbarClose = () => {
        this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
    };

    render() {
        const { intl: { formatMessage }, location } = this.props;

        const query = qs.parse(location?.search, { ignoreQueryPrefix: true });
        const username = query?.username ?? '';
        const token = query?.token ?? '';
        return (
            !this.state.data.get('isFetching') && (
                <div>
                    <Header loginMode settings={fromJS({})} />
                    <div className="row justify-content-md-center">
                        <div className="col-12 col-sm-6 col-md-4">
                            <Paper className="container-fluid" elevation={1} style={inlineStyles.paper}>
                                <div style={inlineStyles.refreshContainer}>
                                    <RefreshIndicator
                                        style={inlineStyles.refresh}
                                        size={36}
                                        left={0}
                                        top={12}
                                        status={this.state.data.get('processingSubmit') ? 'loading' : 'hide'}
                                    />
                                </div>
                                <div>
                                    <h2 style={inlineStyles.h2}>{formatMessage(messages.lblTitle)}</h2>
                                </div>
                                <form>
                                    <div>
                                        <TextField
                                            id="srtLoginUsername"
                                            label={formatMessage(messages.lblUsername)}
                                            value={this.state.data.get('username')}
                                            onChange={this.handleUsernameChange}
                                            fullWidth
                                            variant="standard"
                                            type="text"
                                            error={!!this.state.data.getIn(['errors', 'username'])}
                                            helperText={this.state.data.getIn(['errors', 'username'])}
                                            autoComplete="off"
                                            inputProps={{ tabIndex: 1 }}
                                            InputProps={{
                                                onKeyPress: (ev) => {
                                                    if (ev.key === 'Enter') {
                                                        this.handleSubmit();
                                                        ev.preventDefault();
                                                    }
                                                },
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            id="srtLoginPassword"
                                            label={formatMessage(messages.lblPassword)}
                                            value={this.state.data.get('password')}
                                            onChange={this.handlePasswordChange}
                                            fullWidth
                                            variant="standard"
                                            type="password"
                                            error={!!this.state.data.getIn(['errors', 'password'])}
                                            helperText={this.state.data.getIn(['errors', 'password'])}
                                            autoComplete="off"
                                            inputProps={{ tabIndex: 2 }}
                                            InputProps={{
                                                onKeyPress: (ev) => {
                                                    if (ev.key === 'Enter') {
                                                        this.handleSubmit();
                                                        ev.preventDefault();
                                                    }
                                                },
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <h2>
                                            <Button
                                                variant="contained"
                                                id="srtLogin"
                                                onClick={this.handleSubmit}
                                                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                                                tabIndex={3}
                                                disableRipple
                                                fullWidth
                                                color="primary"
                                            >
                                                {formatMessage(messages.btnSignIn)}
                                            </Button>
                                        </h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-5">
                                            <Grid container alignItems="flex-end">
                                                <Grid item xs={2}>
                                                    <Language />
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <SelectLocale
                                                        id="srtUserLocale"
                                                        value={this.props.language}
                                                        onChange={this.handleLanguageChange}
                                                        hideLabel
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="col-7">
                                            <FlatButton
                                                id="srtLoginForgotPasswordLink"
                                                disableRipple
                                                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                                                tabIndex={4}
                                                onClick={this.handleForgotPasswordDlgOpen}
                                            >
                                                {formatMessage(messages.lblForgotPassword)}
                                            </FlatButton>
                                        </div>
                                    </div>
                                </form>
                            </Paper>
                        </div>
                    </div>
                    <ForgotPassword
                        onClose={this.handleForgotPasswordDlgClose}
                        open={this.state.data.get('forgotPasswordDlgOpen')}
                    />
                    <PasswordUpdate
                        title={formatMessage(messages.lblResetPassword)}
                        handleClose={this.handlePasswordUpdateDlgClose}
                        open={this.state.data.get('passwordUpdateDlgOpen')}
                        isPasswordChange={false}
                        username={username}
                        token={token}
                        onPasswordChagneSuccess={this.handlePasswordUpdateDlgClose}
                    />
                    <TermsAndConditions
                        onClose={this.handleTnCDlgClose}
                        open={this.state.data.get('tnCDlgOpen')}
                        username={this.state.data.get('username')}
                        password={this.state.data.get('password')}
                        onAccept={this.handleSubmit}
                    />
                    <ExtendedSnackbar
                        id="srtLoginSnackBar"
                        open={(this.state.data.get('alertText') !== '')}
                        message={this.state.data.get('alertText')}
                        onClose={this.handleSnackbarClose}
                    />
                </div>
            )
        );
    }
}

// This alias will be used to access bare component for unit testing
export { Login as LoginAlias };

const mapStateToProps = (state) => ({
    language: state.getIn(['settings', 'ws.system.localization.language_country']),
});

const mapDispatchToProps = {
    changeLanguage,
    setAccessToken,
    setAccessTokenExpiryTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Login));
