import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import DatetimePicker from '../../../components/DateTimePicker/DateTimePicker';

import messages from './messagesAuthorizeRefund';
import '../../ManageBooking/components/BookingView/styleBookingView.css';
import inlineStyles from './styles';
import CouponsTF from '../Coupons/CouponsTF';

const AuthorizeRefund = ({
    id,
    disabled,
    errors,
    intl: { formatMessage },
    onChange,
    authRefunds,
    values,
    availableSuppliers,
}) => {
    const handleChangeField = (event) => {
        onChange({ [event.target.name]: event.target.value });
    };

    const handleChangeReceiptDate = (date) => {
        onChange({ receiptDate: date.getTime() });
    };

    const handleChangeCoupon = (key, value) => {
        const newCoupons = values.authRefunds;
        newCoupons[key] = value;
        onChange({ authRefunds: newCoupons });
    };

    const handleChangeCurrency = ({ target: { value } }) => {
        onChange({ penaltyCurrency: value });
    };

    const handleApproveDeny = (_, value) => {
        if (value === 'true') {
            onChange({
                code: '',
                isApproved: true,
                refundAmount: '',
                penaltyAmount: '',
            });
        } else {
            onChange({
                code: formatMessage(messages.lblDenied).toUpperCase(),
                isApproved: false,
                refundAmount: 0,
                penaltyAmount: 0,
            });
        }
    };

    const compressCoupons = (withTF, couponsArray) => {
        const newArray = {};

        couponsArray.forEach((value) => {
            const couponCode = !withTF ? (value.BookingCouponsOrigCode + value.BookingCouponsDestCode) : value.BookingCouponsNumber;
            const couponTS = `${value.BookingCouponsOrigStationName} ${formatMessage(messages.lblTo)} ${value.BookingCouponsDestStationName}`;

            if (newArray[couponCode]) {
                newArray[couponCode].BookingCouponsIssuedNumPax++;
            } else {
                newArray[couponCode] = value;
                newArray[couponCode].BookingCouponsSegment = [];
                newArray[couponCode].BookingCouponsTypes = [];
            }

            newArray[couponCode].BookingCouponsSegment.push(couponTS);
            if (!newArray[couponCode].BookingCouponsTypes.includes(value.BookingCouponsType)) {
                newArray[couponCode].BookingCouponsTypes.push(value.BookingCouponsType);
            }
        });
        return Object.entries(newArray);
    };

    const findCorrectCoupon = (currentCompressedElement, withTF, origArray, currNum) => {
        let iter = currNum;
        const compressedValue = !withTF ? (currentCompressedElement.BookingCouponsOrigCode + currentCompressedElement.BookingCouponsDestCode)
            : currentCompressedElement.BookingCouponsNumber;
        for (let i = 0; i < origArray.length; i++) {
            const origValue = !withTF ? (origArray[i].BookingCouponsOrigCode + origArray[i].BookingCouponsDestCode)
                : origArray[i].BookingCouponsNumber;
            if (compressedValue === origValue) {
                if (iter > 0) {
                    iter--;
                } else {
                    return origArray[i];
                }
            }
        }
        return null;
    };
    const coupons = compressCoupons(authRefunds.BookingCouponsWithTF, authRefunds.BookingCouponsTravelSegmentCoupons);

    const hasSNCF = availableSuppliers.includes('SNCF');

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <RadioGroup
                        name="srtAuthorizedRefundApprove"
                        onChange={handleApproveDeny}
                        value={values.isApproved.toString()}
                    >
                        <FormControlLabel
                            id="srtAuthorizedRefundApproved"
                            key={formatMessage(messages.lblApproved)}
                            control={<Radio />}
                            value="true"
                            label={formatMessage(messages.lblApproved)}
                        />
                        <FormControlLabel
                            id="srtAuthorizedRefundDenied"
                            key={formatMessage(messages.lblDenied)}
                            control={<Radio />}
                            value="false"
                            label={formatMessage(messages.lblDenied)}
                        />
                    </RadioGroup>
                    <DatetimePicker
                        id={`${id}_receiptDate`}
                        name="receiptDate"
                        onChange={handleChangeReceiptDate}
                        errorText={errors && errors.receiptDate ? errors.receiptDate : ''}
                        label={formatMessage(messages.lblReceiptDate)}
                        fullWidth
                        value={values.receiptDate && new Date(values.receiptDate)}
                    />
                    <TextField
                        id={`${id}_code`}
                        name="code"
                        onChange={handleChangeField}
                        error={errors && !!errors.code}
                        helperText={errors && errors.code}
                        label={formatMessage(messages.lblCode)}
                        fullWidth
                        value={values.code}
                        disabled={!values.isApproved}
                        variant="standard"
                    />
                    <TextField
                        id={`${id}_description`}
                        name="description"
                        onChange={handleChangeField}
                        error={errors && !!errors.description}
                        helperText={errors && errors.description}
                        label={formatMessage(messages.lblCouponDescription)}
                        fullWidth
                        value={values.description}
                        variant="standard"
                    />
                    <div style={inlineStyles.tableContainer}>
                        {!authRefunds.BookingCouponsWithTF
                            ? (
                                <Table styleName="bookingTable">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {formatMessage(messages.lblTravelSegments)}
                                            </TableCell>
                                            <TableCell style={inlineStyles.couponsToAuthorizeColumn}>
                                                {formatMessage(messages.lblCouponsToAuthorize)}
                                            </TableCell>
                                            <TableCell style={inlineStyles.authorizedCouponsColumn}>
                                                {formatMessage(messages.lblAuthorizedCoupons)}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            coupons.map(([, travelSegment], index) => {
                                                const totalCoupons = travelSegment.BookingCouponsIssuedNumPax + 1;

                                                const items = Array.from({ length: totalCoupons }, (value, i) => i);

                                                const totalCouponItems = items.map((value) => (findCorrectCoupon(travelSegment,
                                                    authRefunds.BookingCouponsWithTF,
                                                    authRefunds.BookingCouponsTravelSegmentCoupons,
                                                    value)));
                                                const availableCoupons = totalCouponItems.filter((item) => (!item.BookingCouponsIsAuthorized));

                                                return (
                                                    <TableRow key={`segment_${index}`}>
                                                        <TableCell styleName="bookingTableRow">
                                                            {`${travelSegment.BookingCouponsOrigStationName} ${formatMessage(messages.lblTo)}
                                                    ${travelSegment.BookingCouponsDestStationName}`}
                                                        </TableCell>
                                                        <TableCell styleName="bookingTableRowNoScroll">
                                                            {
                                                                (availableCoupons.length !== 0)
                                                                    ? (
                                                                        <TextField
                                                                            id="srtNumberOfReturnedCoupons"
                                                                            value={
                                                                                values.authRefunds[travelSegment.BookingCouponsOrigCode
                                                                                + travelSegment.BookingCouponsDestCode]
                                                                            }
                                                                            placeholder={formatMessage(messages.lblNumberOfCoupons)}
                                                                            fullWidth
                                                                            onChange={({ target: { value } }) => handleChangeCoupon(
                                                                                travelSegment.BookingCouponsOrigCode
                                                                                + travelSegment.BookingCouponsDestCode, value,
                                                                            )}
                                                                            select
                                                                            variant="standard"
                                                                        >
                                                                            <MenuItem
                                                                                id="srtCoupon_0"
                                                                                key={0}
                                                                                value={0}
                                                                            >
                                                                                0
                                                                            </MenuItem>
                                                                            {availableCoupons.map((val, idx) => (
                                                                                <MenuItem
                                                                                    id={`srtCoupon_${idx + 1}`}
                                                                                    key={idx + 1}
                                                                                    value={idx + 1}
                                                                                >
                                                                                    {idx + 1}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                    ) : 0
                                                            }
                                                        </TableCell>
                                                        <TableCell styleName="bookingTableRow">
                                                            {totalCouponItems.length - availableCoupons.length}
                                                        </TableCell>

                                                    </TableRow>
                                                );
                                            })
                                        }

                                    </TableBody>
                                </Table>
                            )
                            : (
                                <CouponsTF
                                    isAuthorize
                                    coupons={coupons}
                                    onChangeCoupon={handleChangeCoupon}
                                />
                            )}
                    </div>
                    {!hasSNCF && (
                        <>
                            <TextField
                                id={`${id}_refundAmount`}
                                name="refundAmount"
                                onChange={handleChangeField}
                                error={errors && !!errors.refundAmount}
                                helperText={errors && errors.refundAmount}
                                label={formatMessage(messages.lblAmount)}
                                fullWidth
                                value={values.refundAmount}
                                disabled={!values.isApproved}
                                variant="standard"
                            />
                            <TextField
                                id={`${id}_refundDescription`}
                                name="refundDescription"
                                onChange={handleChangeField}
                                error={errors && !!errors.refundDescription}
                                helperText={errors && errors.refundDescription}
                                label={formatMessage(messages.lblRefundDescription)}
                                fullWidth
                                value={values.refundDescription}
                                variant="standard"
                            />
                            <div className="row">
                                <div className="col-8">
                                    <TextField
                                        id={`${id}_cancellationPenalty`}
                                        name="penaltyAmount"
                                        onChange={handleChangeField}
                                        error={errors && !!errors.penaltyAmount}
                                        helperText={errors && errors.penaltyAmount}
                                        label={formatMessage(messages.lblPenalty)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        value={values.penaltyAmount}
                                        disabled={!values.isApproved}
                                        variant="standard"
                                    />
                                </div>
                                <div className="col-4">
                                    <TextField
                                        id={`${id}_cancellationPenaltyCurrency`}
                                        onChange={handleChangeCurrency}
                                        value={values.penaltyCurrency || ''}
                                        error={errors && !!errors.penaltyCurrency}
                                        helperText={errors && errors.penaltyCurrency}
                                        label={formatMessage(messages.lblPenaltyCurrency)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        select
                                        variant="standard"
                                    >
                                        <MenuItem
                                            id="srtCurrency_0"
                                            key="GBP"
                                            value="GBP"
                                        >
                                            GBP
                                        </MenuItem>
                                        <MenuItem
                                            id="srtCurrency_1"
                                            key="EUR"
                                            value="EUR"
                                        >
                                            EUR
                                        </MenuItem>
                                    </TextField>
                                </div>
                            </div>
                        </>
                    )}
                    <TextField
                        id={`${id}_notes`}
                        name="notes"
                        label={formatMessage(messages.lblNotes)}
                        fullWidth
                        onChange={handleChangeField}
                        error={errors && !!errors.notes}
                        helperText={errors && errors.notes}
                        value={values.notes}
                        disabled={disabled}
                        multiline
                        rows={4}
                        rowsMax={10}
                        variant="standard"
                    />
                </div>
            </div>
        </div>
    );
};

AuthorizeRefund.propTypes = {
    id: PropTypes.string,
    intl: PropTypes.object,
    authRefunds: PropTypes.object,
    errors: PropTypes.object,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    values: PropTypes.object,
    availableSuppliers: PropTypes.array,
};

AuthorizeRefund.defaultProps = {
    id: 'srtAuthorizeRefunds',
};

export { AuthorizeRefund as AuthorizeRefundAlias };
export default injectIntl(AuthorizeRefund);
