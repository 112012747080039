import { defineMessages } from 'react-intl';

export default defineMessages({
    lblFirstLastName: {
        id: 'app.shopping.manageBooking.search.firstLastName',
        defaultMessage: 'Passenger First or Last Name',
    },
    lblEmail: {
        id: 'app.shopping.manageBooking.search.email',
        defaultMessage: 'Email',
    },
    lblPhone: {
        id: 'app.shopping.manageBooking.search.phone',
        defaultMessage: 'Phone',
    },
    lblSmartCardNumber: {
        id: 'app.shopping.manageBooking.search.smartCardNumber',
        defaultMessage: 'Smart Card Number',
    },
    lblDepartureDate: {
        id: 'app.shopping.manageBooking.search.departureDate',
        defaultMessage: 'Departure Date',
    },
    lblBookingLocator: {
        id: 'app.shopping.manageBooking.search.bookingLocator',
        defaultMessage: 'Booking Locator',
    },
    lblSupplierLocator: {
        id: 'app.shopping.manageBooking.search.supplierLocator',
        defaultMessage: 'External System Locator',
    },
    lblCarrierLocator: {
        id: 'app.shopping.manageBooking.search.carrierLocator',
        defaultMessage: 'Carrier Locator',
    },
    lblTicketLocator: {
        id: 'app.shopping.manageBooking.search.ticketLocator',
        defaultMessage: 'Ticket Locator',
    },
    lblUniqueTicketNumber: {
        id: 'app.shopping.manageBooking.search.uniqueTicketNumber',
        defaultMessage: 'Unique Ticket Number',
    },
    lblReferenceNumber: {
        id: 'app.shopping.manageBooking.search.referenceNumber',
        defaultMessage: 'Reference #',
    },
    lblCustomerNumber: {
        id: 'app.shopping.manageBooking.search.customerNumber',
        defaultMessage: 'Customer #',
    },
    lblFrom: {
        id: 'app.shopping.manageBooking.search.from',
        defaultMessage: 'From',
    },
    lblName: {
        id: 'app.shopping.manageBooking.search.name',
        defaultMessage: 'Name',
    },
    lblOrderStatus: {
        id: 'app.shopping.manageBooking.search.orderStatus',
        defaultMessage: 'Order Status',
    },
    lblReceipt: {
        id: 'app.shopping.manageBooking.search.receipt',
        defaultMessage: 'Receipt',
    },
    lblDepartDate: {
        id: 'app.shopping.manageBooking.search.departDate',
        defaultMessage: 'Depart Date',
    },
    lblResults: {
        id: 'app.shopping.manageBooking.search.results',
        defaultMessage: 'Results',
    },
    lblBookingDetails: {
        id: 'app.shopping.manageBooking.search.bookingDetails',
        defaultMessage: 'Booking Details',
    },
    lblPassengerDetails: {
        id: 'app.shopping.manageBooking.search.passengerDetails',
        defaultMessage: 'Passenger Details',
    },
    lblCustomInformation: {
        id: 'app.shopping.manageBooking.search.customInformation',
        defaultMessage: 'Custom Information',
    },
    btnNeedHelp: {
        id: 'app.shopping.manageBooking.search.needHelp',
        defaultMessage: 'Need Help?',
    },
    lblAllMarkets: {
        id: 'app.shopping.manageBooking.search.allMarkets',
        defaultMessage: 'All markets',
    },
    lblFrenchMarket: {
        id: 'app.shopping.manageBooking.search.frenchMarket',
        defaultMessage: 'French market',
    },
    lblHelpUTNToolText: {
        id: 'app.shopping.manageBooking.search.helpUTNToolText',
        defaultMessage: 'UK market, barcode tickets only',
    },
});
