import {
    darkGray3, lightPink, midBlue, defaultFareColorClassSchema,
} from '../../../colors';

export default {
    itinerary: {
        margin: '10px 0px 0px',
        padding: '5px 0',
        color: darkGray3,
        fontWeight: 200,
    },
    fareCard: {
        height: 144,
        width: 152,
        margin: 4,
        padding: 4,
        display: 'inline-block',
        backgroundColor: lightPink,
    },
    toggleIcon: {
        display: 'none',
    },
    toggleLabel: {
        width: '100%',
        color: midBlue,
        whiteSpace: 'nowrap',
    },
    returnJourneyFares: {
        padding: '10px 0',
    },
    noPadding: {
        padding: 0,
    },
    noMargin: {
        margin: 0,
    },
    leftRow: {
        marginRight: -15,
    },
    itineraryNumber: {
        fontSize: 21,
        marginRight: 14,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: -15,
    },
    elapsed: {
        fontSize: 12,
        whiteSpace: 'nowrap',
    },
    itineraryDetailsContainer: {
        padding: '10px 0',
    },
    serviceAlert: {
        color: defaultFareColorClassSchema.orange,
    },
};
