import { defineMessages } from 'react-intl';

export default defineMessages({
    lblAuthorizeRefund: {
        id: 'app.shopping.coupons.authorizeRefund',
        defaultMessage: 'Authorize Refund?',
    },
    lblReturnCoupon: {
        id: 'app.shopping.coupons.returnCoupon',
        defaultMessage: 'Return Coupon?',
    },
    lblCouponNumber: {
        id: 'app.shopping.coupons.couponNumber',
        defaultMessage: 'Coupon Number',
    },
    lblCouponType: {
        id: 'app.shopping.coupons.couponType',
        defaultMessage: 'Coupon Type',
    },
    lblReleaseStatus: {
        id: 'app.shopping.coupons.releaseStatus',
        defaultMessage: 'Release Status',
    },
    lblUniqueTicketNumber: {
        id: 'app.shopping.coupons.uniqueTicketNumber',
        defaultMessage: 'Unique Ticket Number',
    },
    lblTravelSegments: {
        id: 'app.shopping.coupons.travelSegments',
        defaultMessage: 'Travel Segments',
    },
    lblPrevAuthorized: {
        id: 'app.shopping.coupons.prevAuthorized',
        defaultMessage: 'Previously Authorized',
    },
    lblPrevReturned: {
        id: 'app.shopping.coupons.prevReturned',
        defaultMessage: 'Previously Returned',
    },
    lblNotYetReturned: {
        id: 'app.shopping.coupons.notYetReturned',
        defaultMessage: 'Coupon(s) not yet returned',
    },
    lblOriginalCouponNumber: {
        id: 'app.shopping.coupons.originalCouponNumber',
        defaultMessage: 'Original Coupon Number',
    },
});
