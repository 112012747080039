import { whiteSmoke3 } from '../../colors';

export default {
    gridList: {
        width: 292,
        margin: '0 auto',
    },
    dialogPaper: {
        overflowY: 'hidden',
        width: 768,
    },
    dialogActionsRoot: {
        width: '100%',
    },
    tab: {
        color: '#777',
        fontWeight: 200,
        fontSize: 15,
        textTransform: 'lowercase',
    },
    toolbar: {
        backgroundColor: whiteSmoke3,
        width: '100%',
        justifyContent: 'space-between',
    },
    welcomeButton: {
        height: '100%',
    },
    logo: {
        marginLeft: '-2%',
        display: 'flex',
        alignItems: 'center',
    },
    userMenuItem: {
        fontWeight: 200,
        fontSize: 15,
    },
    appBarTitleStyle: {
        flex: null,
    },
    menuButton: {
        height: 48,
    },
    drawer: {
        width: 256,
    },
    actionsContainer: {
        height: 36,
        width: '100%',
    },
    refresh: {
        marginTop: '30%',
        marginLeft: '50%',
    },
};
