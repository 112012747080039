// eslint-disable-next-line import/prefer-default-export
export const ORIGINS = {
    BOOKING: 'BOOKING',
    EXCHANGE: 'EXCHANGE',
    CANCEL: 'CANCEL',
    VOID: 'VOID',
};

export const closeFareRulesGA = {
    [ORIGINS.BOOKING]: 'closeFareRules',
    [ORIGINS.EXCHANGE]: 'exchangeOrderCloseFareRules',
    [ORIGINS.CANCEL]: 'cancelOrderCloseFareRules',
    [ORIGINS.VOID]: 'voidOrderCloseFareRules',
};

export const viewFareRulesGA = {
    [ORIGINS.BOOKING]: 'viewFareRules',
    [ORIGINS.EXCHANGE]: 'exchangeOrderViewFareRules',
    [ORIGINS.CANCEL]: 'cancelOrderViewFareRules',
    [ORIGINS.VOID]: 'voidOrderViewFareRules',
};
