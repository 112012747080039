import { defineMessages } from 'react-intl';

export default defineMessages({
    lblStationsTicketTitle: {
        id: 'app.common.ticketDelivery.stationsTicket.title',
        defaultMessage: 'Stations to Retrieve Tickets',
    },
    btnStationsTicketClose: {
        id: 'app.common.ticketDelivery.stationsTicket.close',
        defaultMessage: 'Close',
    },
    lblStationsTicketName: {
        id: 'app.common.ticketDelivery.stationsTicket.name',
        defaultMessage: 'Station Name / Code',
    },
    lblStationsTicketOffice: {
        id: 'app.common.ticketDelivery.stationsTicket.office',
        defaultMessage: 'Ticket Office',
    },
    lblStationsTicketVendingMachine: {
        id: 'app.common.ticketDelivery.stationsTicket.vendingMachine',
        defaultMessage: 'Ticket Vending Machine',
    },
    lblStationsTicketYes: {
        id: 'app.common.ticketDelivery.stationsTicket.yes',
        defaultMessage: 'Yes',
    },
    lblStationsTicketNo: {
        id: 'app.common.ticketDelivery.stationsTicket.no',
        defaultMessage: 'No',
    },
    errStationsTicketNoData: {
        id: 'app.common.ticketDelivery.stationsTicket.noData',
        defaultMessage: 'No Stations to Retrieve Tickets found',
    },
    errStationsTicketErrorResponse: {
        id: 'app.common.ticketDelivery.stationsTicket.errorResponse',
        defaultMessage: 'We could not find an available list of stations',
    },
});
