import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { SeatMap, TrainMap, SeatMapVariant } from '@silverrailtech/srtlibrary';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { getSeatMap } from '../../../../../../api';
import { gaEvent } from '../../../../../../utils/googleAnalytics';
import { getAvailableDeck, getAvailableCoach, getDeckLevel } from '../util';
import messages from '../../messagesReservations';
import RefreshIndicator from '../../../../../../components/RefreshIndicator/RefreshIndicator';
import '../../reservations.css';
import ExtendedSnackbar from '../../../../../../components/ExtendedSnackbar/ExtendedSnackbar';
import messagesSeatMap from './messagesSeatMap';
import inlineStyles from './styles';
import { Wrapper, SeatMapWrapper, Title } from './styled';

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        ...inlineStyles.dialogPaper,
    },
}));

export const fetchSeatMapData = async (languageCode, travelSegments, setSeatMap, setErrorText, setIsProcessing, shoppingContext) => {
    travelSegments.forEach(
        async (segment) => {
            if (segment.SeatMapInformation) {
                return getSeatMap(
                    {
                        externalproposal: segment.SeatMapInformation.ExternalProposal,
                        externaltravelsegment: segment.SeatMapInformation.ExternalTravelSegment,
                        externalSupplier: segment.SeatMapInformation.ExternalSupplier,
                        externalSearchToken: segment.SeatMapInformation.ExternalSearchToken,
                        carrier: segment.SeatMapInformation.Carrier,
                        vehicleId: segment.SeatMapInformation.VehicleId,
                        boardLocation: segment.SeatMapInformation.BoardLocation,
                        alightLocation: segment.SeatMapInformation.AlightLocation,
                        departureDateTime: segment.SeatMapInformation.DepartureDateTime,
                        ticketClass: segment.SeatMapInformation.TicketClass,
                        inventoryCode: segment.SeatMapInformation.InventoryCode, // new field
                        context: shoppingContext,
                    },
                    (e) => {
                        const errorMessage = e?.errorResponse?.message || e;
                        setErrorText(errorMessage);
                        setIsProcessing(false);
                    },
                    (data) => {
                        const dataParsed = {
                            ...data,
                            vehicle: {
                                ...data.vehicle,
                                coaches: data.vehicle.coaches.map((coach) => ({
                                    ...coach,
                                    decks: coach.decks.map((deck) => ({
                                        ...deck,
                                        id: deck.id ?? deck.cacheId,
                                    })),
                                })),
                            },
                        };

                        setSeatMap(
                            (prev) => ({
                                ...prev,
                                [segment.TravelSegmentID]: dataParsed,
                            }),
                        );
                        setIsProcessing(false);
                    },
                );
            }
            return null;
        },
    );
};

const RESIZE_WIDTH = 1600;

const SeatSelectorDialog = ({
    open,
    intl: { formatMessage, locale },
    onDiscardSeatSelector,
    onRequestSeats,
    seatMapData,
    travelSegmentID,
    exchangeOrderMode,
    departStation,
    arriveStation,
    selectedPax,
    passengerId,
    selectedSeatReservations,
    paxLength,
    shoppingContext,
}) => {
    const [seatMap, setSeatMap] = useState({});
    const [errorText, setErrorText] = useState('');
    const [selectedCoach, setSelectedCoach] = useState();
    const [selectedDeck, setSelectedDeck] = useState({});
    const [selectedSeat, setSelectedSeat] = useState({});
    const [selectedDeckLevel, setSelectedDeckLevel] = useState();
    const [isProcessing, setIsProcessing] = useState(true);
    const localeFiltered = locale.split('-');

    const [isTabletOrMobile, setIsTabletOrMobile] = useState(window.innerWidth <= RESIZE_WIDTH);

    useEffect(() => {
        const handleResize = () => {
            setIsTabletOrMobile(window.innerWidth <= RESIZE_WIDTH);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (seatMapData && open) {
            fetchSeatMapData(localeFiltered[0], seatMapData, setSeatMap, setErrorText, setIsProcessing, shoppingContext);
            setSelectedCoach(null);
            setSelectedDeckLevel(null);
            setSelectedDeck({});
            setSelectedSeat({});
        }
    }, [seatMapData, open]);

    const seats = seatMap[travelSegmentID];
    const coachesDecks = seats?.vehicle?.coaches?.map((coach) => coach.decks?.length);
    const isDoubleDeckers = (coachesDecks && Math.max(...coachesDecks)) > 1;
    const availableCoach = getAvailableCoach(seats?.vehicle?.coaches);
    const availableDeck = getAvailableDeck(availableCoach?.decks);

    const coach = seats?.vehicle?.coaches?.find((x) => x.id === (selectedCoach ?? availableCoach?.id));

    const selectedDeckId = selectedDeck[selectedCoach ?? availableCoach?.id];

    const deck = coach?.decks?.find((x) => x.id === (selectedDeckId ?? availableDeck?.id));

    const initialDeckLevel = getDeckLevel(availableCoach, availableDeck);

    const handleSetSelectedDeckMap = (e) => {
        setSelectedCoach(e.coachId);
        setSelectedDeckLevel(e.deckLevel);
        setSelectedDeck({
            ...selectedDeck,
            [e.coachId]: e.deckId,
        });
    };

    const handleSetSelectedSeatMap = (seat) => {
        const deckId = selectedDeckId ?? availableDeck?.id;

        if (!selectedDeckId) {
            setSelectedCoach(availableCoach.id);
            setSelectedDeck({
                ...selectedDeck,
                [availableCoach.id]: deckId,
            });
        }

        setSelectedSeat({
            [deckId]: seat,
        });
    };

    const handleDiscard = () => {
        if (exchangeOrderMode) {
            gaEvent('exchangeOrderSeatMapDiscardSeats');
        } else {
            gaEvent('seatmapComponentDiscard');
        }
        onDiscardSeatSelector();
    };

    const handleRequestSeats = () => {
        if (exchangeOrderMode) {
            gaEvent('exchangeOrderSeatMapRequestSeats');
        } else {
            gaEvent('seatmapComponentRequestSeats');
        }

        try {
            const deckId = selectedDeckId ?? availableDeck?.id;
            if (deckId !== undefined && selectedSeat[deckId] && selectedSeat[deckId].length > 0) {
                const firstSeatOfDeck = selectedSeat[deckId]?.[0];
                const seatId = firstSeatOfDeck?.id;

                onRequestSeats(
                    travelSegmentID,
                    selectedCoach,
                    seatId,
                    selectedDeckLevel || initialDeckLevel,
                );
            } else {
                console.log('No seats chosen');
            }
        } catch (error) {
            console.error('Error in handleRequestSeats:', error);
        }
    };

    const handleSnackbarClose = () => {
        setErrorText('');
    };

    const seatReservations = selectedSeatReservations[travelSegmentID];

    const otherPassengerSeats = seatReservations
        ? Object.entries(seatReservations).filter(([key]) => key !== passengerId)
            .map((seat) => formatMessage(
                messagesSeatMap.lblOtherPassengerSeats,
                { deckLevel: isDoubleDeckers && seat[1].deckLevel, coach: seat[1].nearbyCoach, seat: seat[1].nearbySeat },
            )).join(', ')
        : '';

    const actions = [
        <Button
            variant="contained"
            id="srtReservationsDiscard"
            key="srtReservationsDiscard"
            onClick={handleDiscard}
            style={inlineStyles.buttons}
        >
            {formatMessage(messages.btnDiscard)}
        </Button>,
        <Button
            variant="contained"
            id="srtReservationsRequest"
            key="srtReservationsRequest"
            onClick={handleRequestSeats}
            color="primary"
            style={inlineStyles.buttons}
        >
            {formatMessage(messages.btnReqestSeats)}
        </Button>,
    ];

    return (
        <>
            <StyledDialog
                open={open}
                onClose={handleDiscard}
                disableEnforceFocus
                maxWidth={false}
            >
                <DialogTitle>
                    <FormattedMessage {...messagesSeatMap.lblSeatMapTitle} values={{ departStation, arriveStation, selectedPax }} />
                </DialogTitle>
                <DialogContent>
                    <Wrapper>
                        {paxLength > 1 && (
                            <Title>
                                <FormattedMessage {...messagesSeatMap.lblOtherPassengerSelectedSeats} values={{ seats: otherPassengerSeats }} />
                            </Title>
                        )}
                        {seats && (
                            <>
                                <Title><FormattedMessage {...messagesSeatMap.lblSelectPreferredCoach} /></Title>
                                <TrainMap
                                    vehicle={seats?.vehicle}
                                    selectedDeckId={selectedDeckId ?? availableDeck?.id}
                                    setSelectedDeck={handleSetSelectedDeckMap}
                                    selectedCoachId={selectedCoach ?? availableCoach?.id}
                                />
                                {deck && (
                                    <SeatMapWrapper>
                                        <Title><FormattedMessage {...messagesSeatMap.lblSelectPreferredSeat} /></Title>
                                        <SeatMap
                                            deck={deck}
                                            deckLevel={selectedDeckLevel ?? initialDeckLevel}
                                            selectedSeats={selectedSeat[selectedDeckId] || []}
                                            setSelectedSeats={handleSetSelectedSeatMap}
                                            scaleFactor={1}
                                            seatMapLabels={{
                                                selected: {
                                                    label: <FormattedMessage {...messagesSeatMap.lblSelected} />,
                                                },
                                                unavailable: {
                                                    label: <FormattedMessage {...messagesSeatMap.lblUnavailable} />,
                                                },
                                                available: {
                                                    label: <FormattedMessage {...messagesSeatMap.lblAvailable} />,
                                                },
                                                direction: {
                                                    label: <FormattedMessage {...messagesSeatMap.lblDirectionOfTravel} />,
                                                },
                                            }}
                                            variant={isTabletOrMobile ? SeatMapVariant.VERTICAL : SeatMapVariant.HORIZONTAL} // only supported in New SeatMap
                                        />
                                    </SeatMapWrapper>
                                )}
                            </>
                        )}
                        <RefreshIndicator
                            size={36}
                            top={0}
                            left={0}
                            status={isProcessing ? 'loading' : 'hide'}
                            style={{
                                marginTop: '15%',
                                marginLeft: '49%',
                            }}
                        />
                    </Wrapper>
                </DialogContent>
                <DialogActions>
                    {actions}
                </DialogActions>
            </StyledDialog>
            <ExtendedSnackbar
                id="srtSeatMapSnackBar"
                open={(errorText !== '')}
                message={errorText}
                onClose={handleSnackbarClose}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    const seatMapData = state.getIn(['booking', 'seatMap']);
    return ({
        seatMapData: seatMapData ? seatMapData.toJS() : seatMapData,
    });
};

SeatSelectorDialog.propTypes = {
    intl: PropTypes.object,
    open: PropTypes.bool.isRequired,
    onDiscardSeatSelector: PropTypes.func.isRequired,
    onRequestSeats: PropTypes.func.isRequired,
    seatMapData: PropTypes.array,
    travelSegmentID: PropTypes.string,
    exchangeOrderMode: PropTypes.bool,
    departStation: PropTypes.string,
    arriveStation: PropTypes.string,
    selectedPax: PropTypes.number,
    passengerId: PropTypes.string,
    selectedSeatReservations: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    paxLength: PropTypes.number,
    shoppingContext: PropTypes.string,
};

export { SeatSelectorDialog as SeatSelectorDialogAlias };

export default connect(mapStateToProps, null)(injectIntl(SeatSelectorDialog));
