import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';


import Searchbar from '../components/Searchbar';
import TableView from '../../components/TableView/TableView';
import messages from './messagesSearchUsers';
import { getBasePath, safeEncode, safeDecode, isAdminKey } from '../../utils';
import {
    getAccountGroupLabelsApi,
    getAccountLabelsApi,
    getRolesApi,
    userSearchApi,
} from '../apiAdmin';
import ExtendedSnackbar from '../../components/ExtendedSnackbar/ExtendedSnackbar';
import { gaEvent } from '../../utils/googleAnalytics';
import { getExportedUsers } from '../../api';

const basePath = `${getBasePath()}admin/ManageUsers/`;

const initialState = {
    searchStr: '',
    searchRole: '',
    searchAccountGroup: '',
    searchAccount: '',
    alertText: '',
    isProcessing: false,
    userData: [],
    roles: [],
};

const onUsersTableCellClick = () => {
    gaEvent('adminManageUsersOpenUser');
};

const tableHeaders = (formatMessage) => ([
    formatMessage(messages.userId),
    formatMessage(messages.firstName),
    formatMessage(messages.lastName),
    formatMessage(messages.emailAddress),
]);

const SearchUsers = ({
    match,
    intl: { formatMessage },
    history,
    children,
    settings
}) => {
    const previousMatch = useRef(match);
    const [data, setData] = useState(fromJS(initialState));
    const [roles, setRoles] = useState(fromJS([]));
    const [accountGroups, setAccountGroups] = useState(fromJS([]));
    const [accounts, setAccounts] = useState(fromJS([]));

    const isAdmin = settings && settings.get(isAdminKey, 'false') === 'true';

    const getRoles = () => {
        getRolesApi(
            (response) => {
                setData(data.merge({
                    alertText: response.errorResponse.message,
                    isProcessing: false,
                }));
            },
            (response) => {
                setRoles(roles.merge(['', ...response.successResponse.data.roles]));
                setData(data.merge({
                    isProcessing: false,
                }));
            },
        );
    };

    const getAccountGroupLabels = () => {
        getAccountGroupLabelsApi(
            '',
            (response) => {
                setData(data.merge({
                    alertText: response.errorResponse.message,
                    isFetching: false,
                }));
            },
            (response) => {
                setAccountGroups(accountGroups.merge(['', ...response.successResponse.data.AccountGroupLabels]));
                setData(data.merge({
                    isProcessing: false,
                }));
            },
        );
    };

    const getAccountLabels = () => {
        getAccountLabelsApi(
            '',
            (response) => {
                setData(data.merge({
                    alertText: response.errorResponse.message,
                    isFetching: false,
                }));
            },
            (response) => {
                setAccounts(accounts.merge(['', ...response.successResponse.data.AccountLabels]));
                setData(data.merge({
                    isProcessing: false,
                }));
            },
        );
    };

    const performUserSearch = (searchStr) => {
        const searchRole = data.get('searchRole');
        const searchAccountGroup = data.get('searchAccountGroup');
        const searchAccount = data.get('searchAccount');
        if (!(searchStr.trim() || searchRole || searchAccountGroup || searchAccount)) {
            setData(data.merge({
                alertText: formatMessage(messages.noSearchCriteria),
            }));
            return;
        }

        setData(data.merge({
            searchStr,
            isProcessing: true,
            userData: [],
        }));

        userSearchApi(
            searchStr.trim(),
            searchRole,
            searchAccountGroup,
            searchAccount,
            (response) => {
                setData(data.merge({
                    alertText: response.errorResponse.message,
                    isProcessing: false,
                }));
            },
            (response) => {
                const results = response.successResponse.data.UserManagementMatchingUsers;
                if (results.length) {
                    const userData = results.map((item) => [
                        {
                            value: item.UserManagementUserLoginId,
                            link: `${basePath}EditUser/${safeEncode(item.UserManagementUserLoginId)}`,
                        },
                        { value: item.UserManagementUsernameFirst },
                        { value: item.UserManagementUsernameLast },
                        { value: item.UserManagementUserEmailAddress },
                    ]);

                    setData(data.merge({
                        userData,
                        errorText: '',
                        isProcessing: false,
                    }));
                } else {
                    setData(data.merge({
                        userData: [],
                        alertText: formatMessage(messages.searchNoResults),
                        isProcessing: false,
                    }));
                }
            },
        );
    };

    useEffect(() => {
        getRoles();
        getAccountGroupLabels();
        getAccountLabels();
        if (match.params?.searchStr) {
            performUserSearch(safeDecode(match.params?.searchStr));
        }
    }, []);

    useEffect(() => {
        const searchRole = data.get('searchRole');
        const searchAccountGroup = data.get('searchAccountGroup');
        const searchAccount = data.get('searchAccount');
        if ((match.params?.searchStr || searchRole || searchAccountGroup || searchAccount) || (previousMatch.params?.searchStr !== match.params?.searchStr)) {
            const searchStr = match.params.searchStr ?? '';
            performUserSearch(safeDecode(searchStr));
        }
    }, [match.params]);

    const handleSnackbarClose = () => {
        setData(data.merge({ alertText: '' }));
    };

    const handleSearch = () => {
        gaEvent('adminManageUsersSearchUsers');
        const searchRole = data.get('searchRole');
        const searchAccountGroup = data.get('searchAccountGroup');
        const searchAccount = data.get('searchAccount');
        const searchStr = data.get('searchStr');
        if (!(searchStr.trim() || searchRole || searchAccountGroup || searchAccount)) {
            setData(data.merge({
                userData: [],
                alertText: formatMessage(messages.noSearchCriteria),
            }));
            return;
        }
        history.push(`${basePath}Search/${safeEncode(data.get('searchStr'))}`);
    };

    const handleChange = ({ target: { value } }) => setData(data.merge({ searchStr: value }));

    const handleRoleChange = ({ target: { value } }) => setData(data.merge({ searchRole: value }));
    const handleAccountGroupChange = ({ target: { value } }) => setData(data.merge({ searchAccountGroup: value }));
    const handleAccountChange = ({ target: { value } }) => setData(data.merge({ searchAccount: value }));

    const handleKeyup = (event) => {
        if (event.keyCode === 13) handleSearch();
    };

    const downloadArrayBuffer = (buffer, filename, mimeType) => {
        const text = new TextDecoder().decode(buffer);
        const blob = new Blob([text], { type: mimeType });

        const url = URL.createObjectURL(blob);
        console.log('url', url);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };


    const fetchUsers = async () => {
        try {
            const arrayBuffer = await getExportedUsers();
            downloadArrayBuffer(arrayBuffer, 'users-exported.csv', 'text/csv');
        } catch (err) {
            console.log('error downloading users csv: ', err);
        }
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="container-fluid">
                    {children}
                    <div>
                        <Searchbar
                            id="srtUserSearchBar"
                            onKeyUp={handleKeyup}
                            onChange={handleChange}
                            onClick={handleSearch}
                            searchButtonLabel={formatMessage(messages.search)}
                            label={formatMessage(messages.search)}
                            value={data.get('searchStr')}
                        />
                    </div>

                    <div>
                        <TextField
                            id="searchRoleid"
                            value={data.get('searchRole')}
                            onChange={handleRoleChange}
                            label={<FormattedMessage {...messages.userRole} />}
                            select
                            style={{ width: 250, marginRight: 20 }}
                            variant="standard"
                        >
                            {roles?.toJS()?.map((item, index) => (
                                <MenuItem
                                    id={`$roles${index}`}
                                    key={item}
                                    value={item}
                                >
                                    {item}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            id="accountGroupid"
                            value={data.get('searchAccountGroup')}
                            onChange={handleAccountGroupChange}
                            label={<FormattedMessage {...messages.accountGroup} />}
                            select
                            style={{ width: 250, marginRight: 20 }}
                            variant="standard"
                        >
                            {accountGroups?.toJS()?.map((item, index) => (
                                <MenuItem
                                    id={`$accountGroup${index}`}
                                    key={item}
                                    value={item}
                                >
                                    {item}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            id="accountId"
                            value={data.get('searchAccount')}
                            onChange={handleAccountChange}
                            label={<FormattedMessage {...messages.account} />}
                            select
                            style={{ width: 250, marginRight: 20 }}
                            variant="standard"
                        >
                            {accounts?.toJS()?.map((item, index) => (
                                <MenuItem
                                    id={`$account${index}`}
                                    key={item}
                                    value={item}
                                >
                                    {item}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>

                    <div id="srtUserSearchTable">
                        {isAdmin &&
                            <div className="row">
                                <div className="col-10">
                                    <div />
                                </div>
                                <div className="col-2">
                                    <Button
                                        variant="contained"
                                        id="srtCSVDownloadUsersButton"
                                        onClick={fetchUsers}
                                        color="primary"
                                        fullWidth
                                    >
                                        {formatMessage(messages.downloadUsersCSV)}
                                    </Button>

                                </div>
                            </div>
                        }
                        <TableView
                            id="srtUserSearchTable"
                            data={data.get('userData').toJS()}
                            tableHeaders={tableHeaders(formatMessage)}
                            isProcessing={data.get('isProcessing')}
                            isSortable
                            wrapperHeight="550px"
                            useVirtualizedTable
                            clickHandler={onUsersTableCellClick}
                        />

                    </div>
                    <ExtendedSnackbar
                        id="srtUserSearchSnackBar"
                        open={(data.get('alertText') !== '')}
                        message={data.get('alertText')}
                        onClose={handleSnackbarClose}
                    />
                </div>

            </div>
        </div>
    );
};

SearchUsers.propTypes = {
    intl: PropTypes.object.isRequired,
    children: PropTypes.node,
    match: PropTypes.object,
    history: PropTypes.object,
    settings: PropTypes.object,
};

SearchUsers.contextTypes = {
    router: PropTypes.object,
};

// This alias will be used to access bare component for unit testing
export { SearchUsers as SearchUsersAlias };

export default injectIntl(connect((state) => ({
    settings: state.get('settings'),
}))(SearchUsers));
