import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { getBasePath } from '../../utils';
import { grayShadow } from '../../colors';
import messages from './messagesNavBar';

import inlineStyles from './styles';
import { gaEvent } from '../../utils/googleAnalytics';

const NavBar = ({ showShopping, showManage, showAdmin, activeTab = 0, className, intl }) => {
    const selectedTabColor = grayShadow;

    const { formatMessage } = intl;

    return (
        <div className={className}>
            {!showShopping || (
                <Link to={`${getBasePath()}shopping`} style={inlineStyles.link}>
                    <Button
                        id="srtShoppingLink_high"
                        style={({ ...inlineStyles.tabFlat, ...(activeTab === 0 ? { backgroundColor: selectedTabColor } : {}) })}
                        onClick={() => {
                            gaEvent('selectNavBookingTab');
                        }}
                    >
                        <span style={inlineStyles.tab}>
                            {formatMessage(messages.lblNavBook)}
                        </span>
                    </Button>
                </Link>
            )}
            {!showManage || (
                <Link to={`${getBasePath()}booking`} style={inlineStyles.link}>
                    <Button
                        id="srtManageLink_high"
                        style={({ ...inlineStyles.tabFlat, ...(activeTab === 1 ? { backgroundColor: selectedTabColor } : {}) })}
                        onClick={() => {
                            gaEvent('selectNavManageTab');
                        }}
                    >
                        <span style={inlineStyles.tab}>
                            {formatMessage(messages.lblNavManage)}
                        </span>
                    </Button>
                </Link>
            )}
            {!showAdmin || (
                <Link to={`${getBasePath()}admin`} style={inlineStyles.link}>
                    <Button
                        id="srtAdminLink_high"
                        style={({ ...inlineStyles.tabFlat, ...(activeTab === 2 ? { backgroundColor: selectedTabColor } : {}) })}
                        onClick={() => {
                            gaEvent('selectNavAdminTab');
                        }}
                    >
                        <span style={inlineStyles.tab}>
                            {formatMessage(messages.lblNavAdmin)}
                        </span>
                    </Button>
                </Link>
            )}
        </div>
    );
};

NavBar.propTypes = {
    activeTab: PropTypes.number,
    className: PropTypes.string,
    intl: PropTypes.object,
    showShopping: PropTypes.bool,
    showManage: PropTypes.bool,
    showAdmin: PropTypes.bool,
};

const injectedNavBar = injectIntl(NavBar);
export { NavBar as NavBarAlias };
export default injectedNavBar;
