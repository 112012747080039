import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import CustomInfo from '../../../../components/CustomInfo/CustomInfo';

import styles from './styles';
import messages from './messagesCustomFields';

const StyledPaper = styled(Paper)(() => ({
    ...styles.paperContainer,
}));

const StyledButton = styled(Button)(() => ({
    ...styles.buttons,
}));

function CustomFields(props) {
    const {
        createBookingData,
        disabled,
        handleAddCustomInfo,
        handleChangeCustomInfo,
        handleChangeField,
        handleRemoveCustomInfo,
    } = props;
    return (
        <StyledPaper elevation={1}>
            <div className="row">
                <div className="col-12">
                    <h2><FormattedMessage {...messages.lblCustomFields} /></h2>
                    <TextField
                        id="srtReferenceNo"
                        name="referenceNumber"
                        label={<FormattedMessage {...messages.lblReferenceNo} />}
                        disabled={disabled}
                        fullWidth
                        onChange={(event) => handleChangeField(event, [])}
                        value={createBookingData.get('referenceNumber')}
                        error={!!createBookingData.getIn(['errors', 'referenceNumber'])}
                        helperText={createBookingData.getIn(['errors', 'referenceNumber'])}
                        variant="standard"
                    />
                    <TextField
                        id="srtPONumber"
                        name="purchaseOrderNumber"
                        label={<FormattedMessage {...messages.lblPONumber} />}
                        disabled={disabled}
                        fullWidth
                        onChange={(event) => handleChangeField(event, [])}
                        value={createBookingData.get('purchaseOrderNumber')}
                        error={!!createBookingData.getIn(['errors', 'purchaseOrderNumber'])}
                        helperText={createBookingData.getIn(['errors', 'purchaseOrderNumber'])}
                        variant="standard"
                    />
                    <TextField
                        id="srtCustomerNo"
                        name="customerNumber"
                        label={<FormattedMessage {...messages.lblCustomerNo} />}
                        disabled={disabled}
                        fullWidth
                        onChange={(event) => handleChangeField(event, [])}
                        value={createBookingData.get('customerNumber')}
                        error={!!createBookingData.getIn(['errors', 'customerNumber'])}
                        helperText={createBookingData.getIn(['errors', 'customerNumber'])}
                        variant="standard"
                    />
                    <CustomInfo
                        customInfoItems={createBookingData.get('customInformationItems').toJS()}
                        changeCustomInfo={handleChangeCustomInfo}
                        removeCustomInfo={handleRemoveCustomInfo}
                        showAddButton={false}
                        disabled={disabled}
                        disableRequired
                        showRemove
                        errors={
                            createBookingData.hasIn(['errors', 'customInformationItems'])
                                ? createBookingData.getIn(['errors', 'customInformationItems']).toJS() : []
                        }
                    />
                    <div className="row">
                        <StyledButton
                            variant="contained"
                            id="srtAddCustomInfo"
                            color="secondary"
                            onClick={handleAddCustomInfo}
                            disabled={disabled}
                        >
                            <FormattedMessage {...messages.lblAddCustomInfo} />
                        </StyledButton>
                    </div>
                </div>
            </div>
        </StyledPaper>
    );
}

CustomFields.propTypes = {
    createBookingData: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    handleAddCustomInfo: PropTypes.func.isRequired,
    handleChangeCustomInfo: PropTypes.func.isRequired,
    handleChangeField: PropTypes.func.isRequired,
    handleRemoveCustomInfo: PropTypes.func.isRequired,
};

export { CustomFields as CustomFieldsAlias };
export default CustomFields;
