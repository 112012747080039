import { defineMessages } from 'react-intl';

export default defineMessages({
    lblPassenger: {
        id: 'app.shopping.createBooking.Passenger.lblPassenger',
        defaultMessage: 'Passenger #{index} ({age})',
    },
    lblAddAddress: {
        id: 'app.shopping.createBooking.Passenger.addAddress',
        defaultMessage: 'Add Address',
    },
    lblAddCustomInformation: {
        id: 'app.shopping.createBooking.Passenger.addCustomInformation',
        defaultMessage: 'Add Custom Information',
    },
    lblSeason: {
        id: 'app.shopping.season',
        defaultMessage: 'Season Pass',
    },
    lblPassengers: {
        id: 'app.shopping.createBooking.Passenger.lblPassengers',
        defaultMessage: 'Passengers',
    },
    lblPaxTitle: {
        id: 'app.shopping.createBooking.Passenger.paxTitle',
        defaultMessage: 'Title',
    },
    lblFirstName: {
        id: 'app.shopping.createBooking.Passenger.firstName',
        defaultMessage: 'First Name',
    },
    lblLastName: {
        id: 'app.shopping.createBooking.Passenger.lastName',
        defaultMessage: 'Last Name',
    },
    lblPhoneNumber: {
        id: 'app.shopping.createBooking.Passenger.phoneNumber',
        defaultMessage: 'Phone Number',
    },
    lblPhoneNumberOptional: {
        id: 'app.shopping.createBooking.Passenger.phoneNumberOptional',
        defaultMessage: 'Phone Number (Optional)',
    },
    lblEmail: {
        id: 'app.shopping.createBooking.Passenger.email',
        defaultMessage: 'Email',
    },
    lblEmailOptional: {
        id: 'app.shopping.createBooking.Passenger.emailOptional',
        defaultMessage: 'Email (Optional)',
    },
});
