import React from 'react';
import PropTypes from 'prop-types';

import IssueRefoundCreditDebitCard from './IssueRefundCreditDebitCard';
import IssueRefundOnAccount from './IssueRefundOnAccount';
import IssueRefundBrainTree from './IssueRefundBraintree';
import IssueRefundOfflineSync from './IssueRefundOfflineSync';

const PaymentIssueRefundType = ({
    type,
    params: {
        id,
        data: {
            currentReceipt,
            refundAmount,
            refundCurrency,
            isFetching,
            isProcessingSubmit,
            showWelcomeConfirmation,
            reverseRefundMutation,
            refundDetails,
        },
    },
    updateRefundAmountOnReceiptChange,
}) => {
    switch (type) {
        case 'TRADITIONAL_CREDIT_DEBIT_CARD':
            return (
                <IssueRefoundCreditDebitCard
                    id={id}
                    receiptDetails={currentReceipt}
                    refundAmount={refundAmount}
                    refundCurrency={refundCurrency}
                    onUpdateRefundAmount={updateRefundAmountOnReceiptChange}
                    disabled={isFetching || isProcessingSubmit}
                    showWelcomeConfirmation={showWelcomeConfirmation}
                />
            );
        case 'BRAINTREE':
            return (
                <IssueRefundBrainTree
                    id={id}
                    receiptDetails={currentReceipt}
                    refundAmount={refundAmount}
                    refundDetails={refundDetails}
                    refundCurrency={refundCurrency}
                    onUpdateRefundAmount={updateRefundAmountOnReceiptChange}
                    disabled={isFetching || isProcessingSubmit || reverseRefundMutation}
                    isProcessingSubmit={isProcessingSubmit}
                    reverseRefundMutation={reverseRefundMutation}
                    showWelcomeConfirmation={showWelcomeConfirmation}
                />
            );
        case 'ON_ACCOUNT':
            return (
                <IssueRefundOnAccount
                    id={id}
                    receiptDetails={currentReceipt}
                    refundAmount={refundAmount}
                    refundCurrency={refundCurrency}
                    onUpdateRefundAmount={updateRefundAmountOnReceiptChange}
                    disabled={isFetching || isProcessingSubmit}
                    showWelcomeConfirmation={showWelcomeConfirmation}
                />
            );
        case 'OFFLINE_SYNC':
            return (
                <IssueRefundOfflineSync
                    id={id}
                    receiptDetails={currentReceipt}
                    refundAmount={refundAmount}
                    refundCurrency={refundCurrency}
                    onUpdateRefundAmount={updateRefundAmountOnReceiptChange}
                    disabled={isFetching || isProcessingSubmit}
                    showWelcomeConfirmation={showWelcomeConfirmation}
                />
            );
        default:
            return null;
    }
};

PaymentIssueRefundType.propTypes = {
    type: PropTypes.string,
    params: PropTypes.object,
    updateRefundAmountOnReceiptChange: PropTypes.func,
};

export default PaymentIssueRefundType;
