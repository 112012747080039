import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import DatetimePicker from '../DateTimePicker/DateTimePicker';
import { dateStrToDate } from '../../utils/datetimeUtils';

import messages from './messagesDateOfBirth';

const DateOfBirth = ({
    dateOfBirth, disabled, onChange, errorText, idPrefix, index,
}) => {
    const handleDOBChange = (date) => {
        onChange('DOB', date);
    };

    return (
        <DatetimePicker
            id={`${idPrefix}DOB_${index}`}
            name="DOB"
            label={<FormattedMessage {...messages.lblDOB} />}
            fullWidth
            onChange={handleDOBChange}
            errorText={errorText}
            value={typeof dateOfBirth === 'string' ? dateStrToDate(dateOfBirth, 'yyyy-mm-dd') : dateOfBirth}
            disabled={disabled}
        />
    );
};

DateOfBirth.propTypes = {
    dateOfBirth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
    ]),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    errorText: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
    ]),
    idPrefix: PropTypes.string,
    index: PropTypes.number,
};

export default DateOfBirth;
