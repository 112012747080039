import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';

import messages from '../messagesAdmin';
import inlineStyles from './homeLinkStyles';

export default function HomeLink({
    className,
    id,
    onClick,
    style,
    text,
}) {
    return (
        <div className={className} style={style} data-testid="srtUserHome">
            <Button
                variant="text"
                id={id}
                color="secondary"
                onClick={onClick}
            >
                <FormattedMessage {...messages.lblHome} />
            </Button>
            <span style={inlineStyles.text}>{text}</span>
        </div>
    );
}

HomeLink.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    style: PropTypes.object,
    text: PropTypes.string.isRequired,
};

HomeLink.defaultProps = {
    className: 'row',
};
