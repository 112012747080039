import { defineMessages } from 'react-intl';

export default defineMessages({
    lblDeparting: {
        id: 'app.component.dateTimePicker.departing',
        defaultMessage: 'Departing @',
    },
    lblDepartingShort: {
        id: 'app.component.dateTimePicker.departingShort',
        defaultMessage: '@',
    },
    lblArriving: {
        id: 'app.component.dateTimePicker.arriving',
        defaultMessage: 'Arriving by',
    },
    lblArrivingShort: {
        id: 'app.component.dateTimePicker.arrivingShort',
        defaultMessage: 'by',
    },
    lblOk: {
        id: 'app.component.dateTimePicker.ok',
        defaultMessage: 'Ok',
    },
    lblCancel: {
        id: 'app.component.dateTimePicker.cancel',
        defaultMessage: 'Cancel',
    },
    errInvalid: {
        id: 'app.component.dateTimePicker.invalid',
        defaultMessage: 'Invalid Date Format',
    },
    errMaxDate: {
        id: 'app.component.dateTimePicker.maxDate',
        defaultMessage: 'Date must be before maximum date',
    },
    errMinDate: {
        id: 'app.component.dateTimePicker.minDate',
        defaultMessage: 'Date must be after minimum date',
    },
});
