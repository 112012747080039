import { widgetList, GET_SETTINGS, CLEAR_SETTINGS } from '../constants/constants';

import { getUserSettings } from '../api';
import { gaSetUserId } from '../utils/googleAnalytics';

export const clearSettings = () => (dispatch) => dispatch({ type: CLEAR_SETTINGS });

export const getSettings = () => (dispatch) => {
    const settings = getUserSettings(widgetList);
    return settings.then((data) => {
        if (data.errorResponse) {
            dispatch({
                type: GET_SETTINGS,
                payload: data.errorResponse.message,
                error: true,
            });
        } else {
            dispatch({
                type: GET_SETTINGS,
                payload: data.successResponse.data,
            });
            gaSetUserId(data.successResponse.data.UserLoginId);
        }
    });
};

export const changeLanguage = (language) => (dispatch) => dispatch({
    type: GET_SETTINGS,
    payload: {
        'ws.system.localization.language_country': language,
    },
});
