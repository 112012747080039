export default {
    ciRequiredContainer: {
        paddingTop: 34,
    },
    ciRequired: {
        width: 30,
    },
    ciRemoveButtonContainer: {
        paddingTop: 22,
    },
    customInfoContainer: {
        marginTop: 10,
    },
};
