import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { getHomePageUrl, getBasePath, isAdminKey } from '../../utils';
import About from './About';
import { logOutApi } from '../../Login/apiLogin';
import { userLogout } from '../../store';
import UserProfile from './UserProfile';
import PasswordUpdate from '../PasswordUpdate/PasswordUpdate';
import Sidebar from '../Sidebar/Sidebar';
import NavBar from '../NavBar/NavBar';
import UserMenu from './UserMenu';
import { gaEvent } from '../../utils/googleAnalytics';

import messages from './messagesHeader';
import messagesNav from '../NavBar/messagesNavBar';

import './header.css';
import ExtendedSnackbar from '../ExtendedSnackbar/ExtendedSnackbar';
import inlineStyles from './styles';
import logoImage from '../../images/logoSA.svg';

const basePath = getBasePath();

const adminNavItems = [];

const buildNavItems = (isAdminOnly, showShopping, showManage, showAdmin, propsIntl) => {
    const { formatMessage } = propsIntl;
    const navItems = [];
    adminNavItems.length = 0;

    if (showAdmin) {
        adminNavItems.push({
            id: 'srtAdminManageAccounts',
            text: formatMessage(messagesNav.manageAccounts),
            value: `${basePath}admin/ManageAccounts`,
        }, {
            id: 'srtAdminCreateUser',
            text: formatMessage(messagesNav.createUser),
            value: `${basePath}admin/CreateUser`,
        }, {
            id: 'srtAdminManageUsers',
            text: formatMessage(messagesNav.manageUsers),
            value: `${basePath}admin/ManageUsers`,
        });
    }

    if (isAdminOnly) {
        adminNavItems.push({
            id: 'srtAdminFeatureToggles',
            text: 'Toggle Features',
            value: `${basePath}admin/FeatureToggles`,
        });
    }

    if (showShopping) {
        navItems.push({
            id: 'srtShoppingLink',
            text: formatMessage(messagesNav.lblNavBook),
            value: `${basePath}shopping`,
            outsideLink: true,
        });
    }

    if (showManage) {
        navItems.push({
            id: 'srtManageLink',
            text: formatMessage(messagesNav.lblNavManage),
            value: `${basePath}booking`,
            outsideLink: true,
        });
    }

    if (showAdmin) {
        navItems.push({
            id: 'srtAdminLink',
            text: formatMessage(messagesNav.lblNavAdmin),
            value: `${basePath}admin`,
            nestedItems: adminNavItems,
        });
    }

    return navItems;
};

class Header extends Component {
    static propTypes = {
        loginId: PropTypes.string,
        settings: PropTypes.object,
        activeTab: PropTypes.number,
        loginMode: PropTypes.bool,
        intl: PropTypes.object.isRequired,
        history: PropTypes.object,
        userLogout: PropTypes.func,
    };

    // static contextTypes = {
    //     router: PropTypes.object,
    // };

    constructor() {
        super();
        this.onResize = this.onResize.bind(this);
        this.state = {
            data: fromJS({
                aboutDialogOpen: false,
                userProfileDialogOpen: false,
                changePasswordDialogOpen: false,
                drawerOpen: false,
                userMenuOpen: false,
                userMenuAnchorEl: null,
                alertText: '',
                width: window.innerWidth,
            }),
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        this.setState((state) => ({ data: state.data.merge({ width: window.innerWidth }) }));
    }

    handleAboutDialogClose = () => {
        gaEvent('aboutClose');
        this.setState((state) => ({ data: state.data.merge({ aboutDialogOpen: false }) }));
    };

    handleAboutDialogOpen = () => {
        gaEvent('aboutOpen');
        this.setState((state) => ({ data: state.data.merge({ aboutDialogOpen: true, userMenuOpen: false }) }));
    };

    handleProfileDialogClose = () => {
        gaEvent('myProfileClose');
        this.setState((state) => ({ data: state.data.merge({ userProfileDialogOpen: false }) }));
    };

    handleChangePasswordDialogClose = () => {
        gaEvent('changePasswordClose');
        this.setState((state) => ({ data: state.data.merge({ changePasswordDialogOpen: false }) }));
    };

    handleUserProfileDialogOpen = () => {
        gaEvent('myProfileOpen');
        this.setState((state) => ({ data: state.data.merge({ userProfileDialogOpen: true, userMenuOpen: false }) }));
    };

    handleChangePasswordDialogOpen = () => {
        gaEvent('changePasswordInitiate');
        this.setState((state) => ({ data: state.data.merge({ changePasswordDialogOpen: true, userMenuOpen: false }) }));
    };

    handleUserMenuClose = () => {
        this.setState((state) => ({ data: state.data.merge({ userMenuOpen: false }) }));
    };

    handleUserMenuOpen = ({ currentTarget }) => {
        gaEvent('userMenuOpen');
        this.setState((state) => ({ data: state.data.merge({ userMenuOpen: true, userMenuAnchorEl: currentTarget }) }));
    };

    handleHeaderLogout = () => {
        const { history } = this.props;
        logOutApi(
            (response) => {
                this.setState((state) => ({ data: state.data.merge({ alertText: response.errorResponse.message }) }));
                this.props.userLogout();
            },
            () => {
                history.push(basePath);
                this.props.userLogout();
                this.props.history.push(basePath);
            },
        );
    };

    handleAppBarClick = () => {
        this.setState((state) => ({ data: state.data.merge({ drawerOpen: true }) }));
    };

    handleSidebarClick = (item) => {
        this.setState((state) => ({ data: state.data.merge({ drawerOpen: false }) }));

        const links = {
            srtAdminManageAccounts: 'sidebarAdminManageAccounts',
            srtAdminCreateUser: 'sidebarAdminCreateUser',
            srtAdminManageUsers: 'sidebarAdminManageUsers',
            srtShoppingLink: 'sidebarSelectNavBooking',
            srtManageLink: 'sidebarSelectNavManage',
            srtAdminLink: 'sidebarSelectNavAdmin',
        };
        if (links[item.id]) gaEvent(links[item.id]);
    };

    handleSnackbarClose = () => {
        this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
    };

    handleLogoClick = () => {
        gaEvent('applicationLogoClick');
    }

    render() {
        const { settings, loginId, loginMode } = this.props;

        const configBasedAuth = (settings.get('ws.interface.config_based.authentication_enabled') === 'true');
        const allowAddUser = (settings.get('ws.system.security.allowadduser') === 'true');
        const allowAcctmgt = (settings.get('ws.system.security.allowacctmgt') === 'true');
        const allowSiloAcctmgt = (settings.get('ws.system.security.allowsiloacctmgt') === 'true');
        const showShopping = (settings.get('ws.feature.shopping.show') === 'true');
        const showManage = (settings.get('ws.feature.managebookings.show') === 'true');
        const showAdmin = allowAddUser || allowAcctmgt || allowSiloAcctmgt;
        const isAdminOnly = settings?.get(isAdminKey) === 'true';

        const navItems = buildNavItems(isAdminOnly, showShopping, showManage, showAdmin, this.props.intl);

        const logo = (
            <div>
                <Link id="srt_logo_link" to={getHomePageUrl()} onClick={this.handleLogoClick}>
                    <img styleName="silveragentLogo" alt="SilverAgent" src={logoImage} />
                </Link>
            </div>
        );
        const userMenu = (loginId && !loginMode && (
            <UserMenu
                id="srtUserMenu"
                handleOpenAbout={this.handleAboutDialogOpen}
                handleOpenChangePassword={this.handleChangePasswordDialogOpen}
                handleOpenUserProfile={this.handleUserProfileDialogOpen}
                handleHeaderLogout={this.handleHeaderLogout}
                handleOpenUserMenu={this.handleUserMenuOpen}
                handleCloseUserMenu={this.handleUserMenuClose}
                userLoginId={loginId}
                openUserMenu={this.state.data.get('userMenuOpen')}
                menuAnchorEl={this.state.data.get('userMenuAnchorEl')}
                configBasedAuth={configBasedAuth}
            />
        ));
        return (
            <div className="container-fluid" styleName="headerEl">
                <div className="container">
                    <AppBar id="srtToolBarHeader" elevation={0} position="static">
                        <Toolbar style={inlineStyles.toolbar} variant="dense" disableGutters>
                            <div style={inlineStyles.logo}>
                                {this.state.data.get('width') < 992 && !loginMode && (
                                    <IconButton onClick={this.handleAppBarClick} style={inlineStyles.menuButton}>
                                        <MenuIcon />
                                    </IconButton>
                                )}
                                {logo}
                                {(this.state.data.get('width') >= 992 || loginMode) && (
                                    <NavBar
                                        showShopping={showShopping}
                                        showManage={showManage}
                                        showAdmin={showAdmin}
                                        activeTab={this.props.activeTab}
                                    />
                                )}
                            </div>
                            <div>
                                {userMenu}
                            </div>
                        </Toolbar>
                    </AppBar>
                    <About
                        open={this.state.data.get('aboutDialogOpen')}
                        handleClose={this.handleAboutDialogClose}
                        projectVersion={this.props.settings.get('ws.system.versioninfo.projectversion') || ''}
                    />
                    {
                        loginId && !loginMode && !configBasedAuth && (
                            <UserProfile
                                open={this.state.data.get('userProfileDialogOpen')}
                                handleClose={this.handleProfileDialogClose}
                                loginId={loginId}
                            />
                        )
                    }
                    <PasswordUpdate
                        title={this.props.intl.formatMessage(messages.lblChangePasswordTitle)}
                        open={this.state.data.get('changePasswordDialogOpen')}
                        handleClose={this.handleChangePasswordDialogClose}
                        isPasswordChange
                    />
                    <Drawer
                        open={this.state.data.get('drawerOpen')}
                        onClose={() => this.setState((state) => ({ data: state.data.merge({ drawerOpen: false }) }))}
                        PaperProps={{
                            style: inlineStyles.drawer,
                        }}
                    >
                        <Sidebar
                            items={navItems}
                            handleClick={this.handleSidebarClick}
                        />
                    </Drawer>
                    <ExtendedSnackbar
                        id="srtHeaderSnackBar"
                        open={(this.state.data.get('alertText') !== '')}
                        message={this.state.data.get('alertText')}
                        onClose={this.handleSnackbarClose}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loginId: state.getIn(['settings', 'UserLoginId']),
    settings: state.get('settings'),
});

const mapDispatchToProps = (dispatch) => ({
    userLogout: () => dispatch(userLogout()),
});

export { Header as HeaderAlias };
export { adminNavItems };

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withRouter(Header)));
