import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';
import validate from 'validate.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { styled } from '@mui/system';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import SendToEmail from '../SendToEmail/SendToEmail';
import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import messages from './messagesResendTicket';
import { resendClaimTicketApi } from '../../apiBooking';
import { fetchBookingDetails } from '../../ManageBooking/actionsManageBooking';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import { gaEvent } from '../../../utils/googleAnalytics';
import inlineStyles from './styles';

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        ...inlineStyles.dialogPaper,
    },
}));

const StyledDialogContent = styled(DialogContent)({
    ...inlineStyles.dialogContent,
});

class ResendTicketDialog extends Component {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        open: PropTypes.bool,
        booking: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired,
        orderId: PropTypes.string,
        onResendTicketSuccess: PropTypes.func,
        smsCodeOptions: PropTypes.array,
    };

    initialState = {
        processingSubmit: false,
        alertText: '',
        vdEmail: '',
        smsNumber: this.props.booking.BookingOrders[0].BookingOrderTDOData.BookingFulfillmentInfo.BookingFulfillmentPhoneNumber,
        smsCountryCode: this.props.booking.BookingOrders[0].BookingOrderTDOData.BookingFulfillmentInfo.BookingFulfillmentPhoneCountryCode,
        showAlternateEmail: false,
        errors: {},
    };

    constructor(props) {
        super(props);
        this.state = { data: fromJS(this.initialState) };
    }

    // eslint-disable-next-line camelcase,react/sort-comp
    UNSAFE_componentWillMount() {
        if (this.props.open) {
            const { booking } = this.props;
            const { orderId } = this.props;
            const bookingOrder = orderId ? booking.BookingOrders.find((item) => item.BookingOrderID === orderId) : booking.BookingOrders[0];
            const vdEmail = (bookingOrder.BookingEmailForClaimVD && bookingOrder.BookingEmailForClaimVD?.length > 0)
                ? bookingOrder.BookingEmailForClaimVD[0] : '';

            this.setState((state) => ({
                data: state.data.merge({ vdEmail }),
            }));
            gaEvent('resendTicketOpen', this.getIsClaim());
        }
    }

    getIsClaim = () => {
        const {
            booking,
            orderId,
        } = this.props;
        const bookingOrder = orderId
            ? booking.BookingOrders.find((item) => item.BookingOrderID === orderId)
            : booking.BookingOrders[0];
        return bookingOrder.BookingOrderStatus === 'CONFIRMED' && bookingOrder.BookingOrderTicketDelivery !== 'EML';
    }

    constraints = () => {
        const { intl: { formatMessage } } = this.props;
        return {
            vdEmail: {
                presence: {
                    message: formatMessage(messages.errConfirmationEmail), allowEmpty: false,
                },
                email: {
                    message: formatMessage(messages.errConfirmationEmail),
                },
            },
        };
    }

    checkForAlternativeEmail = () => {
        const ticketDelivery = this.props.booking.BookingOrders[0].BookingOrderTicketDelivery;
        return ['ETK', 'PAH', 'EML'].includes(ticketDelivery);
    };

    handleSubmit = () => {
        const {
            booking, orderId, onClose, onResendTicketSuccess,
        } = this.props;
        this.setState((state) => ({ data: state.data.merge({ errors: {}, processingSubmit: true }) }));

        const data = this.state.data.toJS();
        const errors = validate(data, this.constraints());
        if (!validate.isEmpty(errors)) {
            this.setState((state) => ({
                data: state.data.merge({
                    isProcessingSubmit: false,
                    errors,
                }),
            }));
            return;
        }
        const { queryItems } = booking;
        const bookingOrder = orderId ? booking.BookingOrders.find((item) => item.BookingOrderID === orderId) : booking.BookingOrders[0];
        const vdUrl = bookingOrder.BookingResponseValueDocumentUrl;
        const tdo = bookingOrder?.BookingOrderTicketDelivery;
        const vdSms = data.smsCountryCode ? `${data.smsCountryCode}:${data.smsNumber}` : '';

        resendClaimTicketApi(
            {
                queryItems,
                orderId,
                email: data.vdEmail,
                vdUrl,
                vdSms,
                isClaim: this.getIsClaim(),
                tdo,
            },
            (response) => {
                this.setState((state) => ({
                    data: state.data.merge({
                        isProcessingSubmit: false,
                        alertText: response.errorResponse.message,
                    }),
                }));
            },
            () => {
                this.setState((state) => ({
                    data: state.data.merge({
                        isProcessingSubmit: false,
                    }),
                }), () => {
                    onClose();
                    onResendTicketSuccess(queryItems);
                });
            },
        );
    };

    handleClose = () => {
        gaEvent('resendTicketClose', this.getIsClaim());
        this.props.onClose();
    };

    handleSnackbarClose = () => {
        this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
    };

    handleChangeEmail = (updates) => {
        this.setState((state) => ({ data: state.data.merge(updates) }));
    };

    handleChangeSms = ({ smsCountryCode, smsNumber }) => {
        this.setState((state) => ({
            data: state.data.merge({
                smsCountryCode,
                smsNumber,
            }),
        }));
    };

    render() {
        const { data } = this.state;
        const disabled = data.get('isProcessingSubmit');
        const {
            booking,
            open,
            intl: { formatMessage },
            smsCodeOptions,
        } = this.props;

        const emails = booking.BookingOrders[0].BookingEmailForClaimVD;
        const isSms = booking.BookingOrders[0].BookingOrderTicketDelivery.indexOf('SMS') === 0;

        const hasEmail = (
            emails
            && emails.length === 1
            && !this.checkForAlternativeEmail()
        );
        const hasMultipleEmail = (emails
            && emails.length > 1)
            || this.checkForAlternativeEmail();

        const isClaim = this.getIsClaim();

        const actions = (
            <div className="row" style={inlineStyles.dialogActionsRoot}>
                <div className="col-12 col-sm-4 offset-sm-4">
                    <Button
                        variant="contained"
                        id="srtResendTicketClose"
                        onClick={this.handleClose}
                        fullWidth
                        disabled={disabled}
                    >
                        {formatMessage(messages.btnCancel)}
                    </Button>
                </div>
                <div className="col-12 col-sm-4">
                    {
                        data.get('processingSubmit')
                            ? (
                                <RefreshIndicator
                                    size={36}
                                    left={40}
                                    top={0}
                                    status="loading"
                                />
                            ) : (
                                <Button
                                    variant="contained"
                                    id="srtResendTicketSubmit"
                                    onClick={this.handleSubmit}
                                    fullWidth
                                    color="primary"
                                    disabled={disabled}
                                >
                                    {formatMessage(messages.btnSend)}
                                </Button>
                            )
                    }
                </div>
            </div>
        );

        return (
            <div>
                <StyledDialog
                    open={open}
                    onClose={this.handleClose}
                    disableEnforceFocus
                    maxWidth={false}
                >
                    <DialogTitle>{formatMessage(messages[isClaim ? 'lblTitleClaim' : 'lblTitle'])}</DialogTitle>
                    <StyledDialogContent>
                        <SendToEmail
                            vdCurrEmail={data.get('vdEmail')}
                            showAlternateEmail={data.get('showAlternateEmail')}
                            onChangeEmail={this.handleChangeEmail}
                            onChangeSms={this.handleChangeSms}
                            hasEmail={hasEmail}
                            hasMultipleEmail={hasMultipleEmail}
                            isSms={isSms}
                            smsNumber={data.get('smsNumber')}
                            smsCountryCode={data.get('smsCountryCode')}
                            smsCodeOptions={smsCodeOptions}
                            emails={emails}
                            errors={data.get('errors').toJS()}
                            disabled={disabled}
                        />
                    </StyledDialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </StyledDialog>
                <ExtendedSnackbar
                    id="srtResendTicketSnackBar"
                    open={(data.get('alertText') !== '')}
                    message={data.get('alertText')}
                    onClose={this.handleSnackbarClose}
                />
            </div>
        );
    }
}

export { ResendTicketDialog as ResendTicketDialogAlias };

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => ({
    onResendTicketSuccess: bindActionCreators(fetchBookingDetails, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ResendTicketDialog));
