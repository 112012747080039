import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Header from '../components/Header/Header';
import ManageBooking from './ManageBooking/ManageBooking';

const Booking = ({ match, loginId, settings }) => {
    const hasSettings = !!(loginId && settings);

    return (
        <div>
            {hasSettings && (
                <>
                    <Header activeTab={1} />
                    <Switch>
                        <Route path={`${match.path}/Manage/:bookingLocator?`} component={ManageBooking} />
                        <Redirect path="*" to={`${match.path}/Manage`} />
                    </Switch>
                </>
            )}
        </div>
    );
};

Booking.propTypes = {
    loginId: PropTypes.string,
    settings: PropTypes.object,
    match: PropTypes.object,
};

export default injectIntl(connect((state) => ({
    loginId: state.getIn(['settings', 'UserLoginId']),
    settings: state.get('settings'),
}))(Booking));
