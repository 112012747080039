export default {
    messageText: {
        marginTop: 15,
        fontSize: 14,
    },
    dialogPaper: {
        overflowY: 'hidden',
    },
    dialogActionsRoot: {
        width: '100%',
    },
};
