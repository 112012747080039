import { defineMessages } from 'react-intl';

export default defineMessages({
    lblAmendSeatReservationsDialogTitle: {
        id: 'app.booking.amendSeatReservationsDialog.title',
        defaultMessage: 'Amend Seat Reservations',
    },
    btnClose: {
        id: 'app.close',
        defaultMessage: 'Close',
    },
    lblAmendSeatReservationsDialogAddSeat: {
        id: 'app.booking.amendSeatReservationsDialog.addSeat',
        defaultMessage: 'Keep Train and Add Seat',
    },
    lblAmendSeatReservationsDialogChangeSeat: {
        id: 'app.booking.amendSeatReservationsDialog.changeSeat',
        defaultMessage: 'Keep Train and Change Seat',
    },
    lblAmendSeatReservationsDialogChangeTrainAndSeat: {
        id: 'app.booking.amendSeatReservationsDialog.changeTrainAndSeat',
        defaultMessage: 'Change Train and Seat',
    },
    lblAmendSeatReservationsDialogSearchForTrains: {
        id: 'app.booking.amendSeatReservationsDialog.searchForTrains',
        defaultMessage: 'Search For Trains',
    },
    lblAmendSeatReservationsDialogSelectThisJourney: {
        id: 'app.booking.amendSeatReservationsDialog.selectThisJourney',
        defaultMessage: 'SELECT THIS JOURNEY',
    },
    lblAmendSeatReservationsDialogOutbound: {
        id: 'app.booking.amendSeatReservationsDialog.outbound',
        defaultMessage: 'Outbound',
    },
    lblAmendSeatReservationsDialogChanges: {
        id: 'app.booking.amendSeatReservationsDialog.changes',
        defaultMessage: 'Changes',
    },
    lblAmendSeatReservationsDialogDuration: {
        id: 'app.booking.amendSeatReservationsDialog.duration',
        defaultMessage: 'Duration',
    },
});
