import { defineMessages } from 'react-intl';

export default defineMessages({
    lblRefundDueTitle: {
        id: 'app.booking.issueRefundDialog.refundDueTitle',
        defaultMessage: 'Suggested Followup Actions',
    },
    lblIssueRefundTitle: {
        id: 'app.booking.issueRefundDialog.issueRefundTitle',
        defaultMessage: 'Issue Refund',
    },
    btnCancel: {
        id: 'app.booking.issueRefundDialog.cancel',
        defaultMessage: 'Cancel',
    },
    btnSubmit: {
        id: 'app.booking.issueRefundDialog.submit',
        defaultMessage: 'Submit refund',
    },
    btnSend: {
        id: 'app.booking.issueRefundDialog.send',
        defaultMessage: 'Send',
    },
    btnNo: {
        id: 'app.booking.issueRefundDialog.no',
        defaultMessage: 'No',
    },
    btnYes: {
        id: 'app.booking.issueRefundDialog.yes',
        defaultMessage: 'Yes',
    },
    lblReceiptNumber: {
        id: 'app.booking.issueRefundDialog.receiptNumber',
        defaultMessage: 'Receipt Number',
    },
    lblPaymentDate: {
        id: 'app.booking.issueRefundDialog.paymentDate',
        defaultMessage: 'Payment Date',
    },
    lblAmountPaid: {
        id: 'app.booking.issueRefundDialog.amountPaid',
        defaultMessage: 'Original Payment amount',
    },
    lblBalance: {
        id: 'app.booking.issueRefundDialog.balance',
        defaultMessage: 'Payment Balance',
    },
    lblPaymentProcessor: {
        id: 'app.booking.issueRefundDialog.paymentProcessor',
        defaultMessage: 'Payment Processor',
    },
    lblApprovalNumber: {
        id: 'app.booking.issueRefundDialog.approvalNumber',
        defaultMessage: 'Approval Number',
    },
    lblError: {
        id: 'app.booking.issueRefundDialog.error',
        defaultMessage: 'Error',
    },
    lblExternalProvider: {
        id: 'app.booking.issueRefundDialog.externalProvider',
        defaultMessage: 'External Provider',
    },
    lblExternalReference: {
        id: 'app.booking.issueRefundDialog.externalReference',
        defaultMessage: 'External Reference',
    },
    lblStatus: {
        id: 'app.booking.issueRefundDialog.status',
        defaultMessage: 'Status',
    },
    lblPaymentMethod: {
        id: 'app.booking.issueRefundDialog.paymentMethod',
        defaultMessage: 'Payment Method',
    },
    lblRefundAmount: {
        id: 'app.booking.issueRefundDialog.refundAmount',
        defaultMessage: 'Refund Amount',
    },
    lblWelcomeConfirmation: {
        id: 'app.booking.issueRefundDialog.welcomeConfirmation',
        defaultMessage: 'Refund {amount} to the customer.',
    },
    lblWelcomeConfirmationSendTicket: {
        id: 'app.booking.issueRefundDialog.welcomeConfirmationSendTicket',
        defaultMessage: 'Send new ticket to the customer.',
    },
    lblSendTicketTitle: {
        id: 'app.booking.issueRefundDialog.sendTicketTitle',
        defaultMessage: 'Send Ticket',
    },
    lblRefunded: {
        id: 'app.booking.issueRefundDialog.refunded',
        defaultMessage: 'Selected payment has already been refunded',
    },
    lblOfflineSync: {
        id: 'app.booking.issueRefundDialog.offlineSync',
        defaultMessage: 'Offline Sync',
    },
    errRefundAmount: {
        id: 'app.booking.issueRefundDialog.invalidRefundAmount',
        defaultMessage: '^The Refund Amount can not be negative or greater than the Balance.',
    },
    errEmptyRefundAmount: {
        id: 'app.booking.issueRefundDialog.emptyRefundAmount',
        defaultMessage: '^The Refund Amount can not be empty.',
    },
    errExternalPaymentStatus: {
        id: 'app.booking.issueRefundDialog.externalPaymentStatus',
        defaultMessage: 'The payment is still waiting to be settled by the bank and cannot be refunded at this time. Please try again later.',
    },
    errConfirmationEmail: {
        id: 'app.booking.resendTicketDialog.emailEmpty',
        defaultMessage: '^Please enter a valid Email',
    },
});
