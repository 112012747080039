import { defineMessages } from 'react-intl';

export default defineMessages({
    lblNotes: {
        id: 'app.shopping.manageBooking.orderNotes.notes',
        defaultMessage: 'Notes',
    },
    btnAddNote: {
        id: 'app.shopping.manageBooking.orderNotes.notesAdd',
        defaultMessage: 'Add note',
    },
});
