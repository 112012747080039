import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import validate from 'validate.js';

import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import WarningIcon from '@mui/icons-material/Warning';

import { getOrderDetailsApi } from '../../apiBooking';
import { gaEvent } from '../../../utils/googleAnalytics';
import { setOrderDetails } from '../../ManageBooking/actionsManageBooking';
import OrderDetailsView from './OrderDetailsView';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import ServiceAlerts from '../ServiceAlerts/ServiceAlerts';

import messages from './messagesOrderDetails';
import inlineStyles from './styles';

const StyledButton = styled(Button)({
    ...inlineStyles.intermediateStepsButton,
});

const StyledWarningIcon = styled(WarningIcon)({
    ...inlineStyles.serviceAlert,
});

const StyledTableWrapperDiv = styled('div')({
    ...inlineStyles.tableWrapper,
});

export const transformFareRulesDetails = (order) => {
    const tempOrder = order;
    tempOrder.BookingLegFareRulesDetails = tempOrder.BookingLegFareRulesDetails.map(
        (fareRulesDetails) => {
            if (fareRulesDetails.BookingFareRulesTravelSegments === undefined) return fareRulesDetails;
            return ({
                paxRefs: '',
                travelSegments: fareRulesDetails.BookingFareRulesTravelSegments,
                travelSubSegments: fareRulesDetails.BookingFareRulesTravelSubSegments,
                fareClasses: fareRulesDetails.ShoppingFareRulesFareClasses,
                displayName: fareRulesDetails.ShoppingLegFareDisplayName,
                fareOrigins: fareRulesDetails.ShoppingLegFareOrigin,
                fareDestinations: fareRulesDetails.ShoppingLegFareDestination,
                originTerminals: fareRulesDetails.ShoppingLegFareOriginTerminals,
                destinationTerminals: fareRulesDetails.ShoppingLegFareDestinationTerminals,
                fareCodes: fareRulesDetails.ShoppingLegFareCodes,
                fareMatrix: fareRulesDetails.ShoppingFareRulesFareMatrix?.map((matrix) => ({
                    isTextRule: matrix.ShoppingFareRuleRuleIsTextRule,
                    category: matrix.FareRuleCategory,
                    textRuleDesc: matrix.FareRulesDescription && matrix.FareRulesDescription?.map((description) => description.Description),
                    priceType: matrix.ShoppingFareRulePriceType,
                    applicableTicketingOption: matrix.ShoppingFareRuleApplicableTicketingOption,
                    expirationDateTime: matrix.ShoppingFareRuleExpirationDateTime,
                    refundAllowedConfirmed: matrix.ShoppingFareRuleRefundAllowedConfirmed,
                    refundAllowedConfirmedPenalty: matrix.ShoppingFareRuleRefundAllowedConfirmedPenalty,
                    refundAllowedTicketed: matrix.ShoppingFareRuleRefundAllowedTicketed,
                    refundAllowedTicketedPenalty: matrix.ShoppingFareRuleRefundAllowedTicketedPenalty,
                    exchangeAllowedConfirmed: matrix.ShoppingFareRuleExchangeAllowedConfirmed,
                    exchangeAllowedConfirmedPenalty: matrix.ShoppingFareRuleExchangeAllowedConfirmedPenalty,
                    exchangeAllowedTicketed: matrix.ShoppingFareRuleExchangeAllowedTicketed,
                    exchangeAllowedTicketedPenalty: matrix.ShoppingFareRuleExchangeAllowedTicketedPenalty,
                    refundableIndicator: matrix.ShoppingFareRuleRefundableIndicator || false,
                })),
            });
        },
    );
    return tempOrder;
};

const OrderDetailsDetails = ({
    booking,
    orderId,
    expanded,
    onSetOrderDetails,
    intl,
    intl: { formatMessage },
    id,
}) => {
    const [data, setData] = useState(fromJS({
        order: {},
        alertText: '',
        isFetching: false,
        showIntermediateStops: false,
        serviceAlertsOpen: false,
    }));

    const getDetails = () => {
        if (expanded && !data.get('order').size && !data.get('isFetching')) {
            setData((state) => state.set('isFetching', true));
            if (!booking.BookingOrders.length) {
                getOrderDetailsApi(
                    {
                        queryItems: booking.queryItems,
                        orderId,
                    },
                    (response) => {
                        setData((state) => state.merge({
                            isFetching: false,
                            alertText: response.errorResponse.message,
                        }));
                    },
                    (response) => {
                        const fareRulesDetails = transformFareRulesDetails(response.successResponse.data);
                        onSetOrderDetails(fareRulesDetails);
                        setData((state) => state.merge({
                            isFetching: false,
                            order: fareRulesDetails,
                        }));
                    },
                );
            } else {
                const fareRulesDetails = transformFareRulesDetails(booking.BookingOrders.filter((order) => order.BookingOrderID === orderId)[0]);
                onSetOrderDetails(fareRulesDetails);
                setData((state) => state.merge({
                    isFetching: false,
                    order: fareRulesDetails,
                }));
            }
        }
    };

    useEffect(() => {
        getDetails();
    });

    const handleSnackBarClose = () => {
        setData((state) => state.set('alertText', ''));
    };

    const switchShowIntermediateStops = () => {
        gaEvent(!data.get('showIntermediateStops') ? 'callingPointsOpen' : 'callingPointsClose');
        setData(data.set('showIntermediateStops', !data.get('showIntermediateStops')));
    };

    const switchServiceAlertsOpen = () => {
        setData((state) => {
            gaEvent('viewServiceAlerts', !state.get('serviceAlertsOpen'));
            return state.set('serviceAlertsOpen', !state.get('serviceAlertsOpen'));
        });
    };

    const dataJS = data.toJS();
    const hasData = (!dataJS.isFetching && !validate.isEmpty(dataJS.order));
    const hasIntermediateStops = hasData
        && dataJS.order.BookingOrderLegDetails.ticketableFareGroups.some(
            (ticketableFareGroup) => ticketableFareGroup.BookingLegTravelSegments.some(
                (travelSegment) => travelSegment?.IntermediateTravelPoints?.length,
            ),
        );

    const intermediateStopsButton = hasIntermediateStops && (
        <StyledButton
            color="secondary"
            size="small"
            onClick={switchShowIntermediateStops}
        >
            {formatMessage(messages[dataJS.showIntermediateStops ? 'lblHideIntermediateStops' : 'lblViewIntermediateStops'])}
            {dataJS.showIntermediateStops ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </StyledButton>
    );

    return (
        <div id={id}>
            <h2>
                {formatMessage(messages.lblTitle)}
                {intermediateStopsButton}
                {dataJS.order.BookingOrderServiceAlerts && (
                    <IconButton onClick={switchServiceAlertsOpen}>
                        <StyledWarningIcon />
                    </IconButton>
                )}
            </h2>
            {dataJS.isFetching && (
                <CircularProgress />
            )}
            <StyledTableWrapperDiv>
                <OrderDetailsView
                    id="srtBookingOrderDetails"
                    promotionDetails={(hasData)
                        ? dataJS.order.BookingOrderSilverPromos : []}
                    bookingDetails={(hasData)
                        ? dataJS.order.BookingOrderLegDetails : {}}
                    fareRulesDetails={(hasData)
                        ? dataJS.order.BookingLegFareRulesDetails : []}
                    seasonFareDetails={(hasData)
                        ? dataJS.order.BookingSeasonFareDetails : undefined}
                    disabled={dataJS.isFetching}
                    showIntermediateStops={dataJS.showIntermediateStops}
                    isSingleTicketableFares={hasData && (
                        dataJS.order.BookingOrderIsSingleTicketableFares || dataJS.order.BookingOrderLegDetails.ticketableFareGroups.length === 1
                    )}
                    intl={intl}
                    bookingOrderRevisions={(hasData)
                        ? dataJS.order.BookingOrderRevisions : []}
                    isSplitTicketFare={hasData && dataJS.order.BookingOrderIsSplitTicketFare}
                />
            </StyledTableWrapperDiv>
            <ExtendedSnackbar
                id="srtBookingOrderDetailsSnackBar"
                open={dataJS.alertText !== ''}
                message={dataJS.alertText}
                onClose={handleSnackBarClose}
            />
            <ServiceAlerts
                open={dataJS.serviceAlertsOpen}
                serviceAlerts={dataJS.order.BookingOrderServiceAlerts}
                onClose={switchServiceAlertsOpen}
            />
        </div>
    );
};

OrderDetailsDetails.propTypes = {
    booking: PropTypes.object.isRequired,
    expanded: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    intl: PropTypes.object,
    orderId: PropTypes.string,
    onSetOrderDetails: PropTypes.func,
};

const mapDispatchToProps = {
    onSetOrderDetails: setOrderDetails,
};

export default connect(null, mapDispatchToProps)(injectIntl(OrderDetailsDetails));
