import { defineMessages } from 'react-intl';

export default defineMessages({
    lblTitle: {
        id: 'app.booking.addFeeDiscountDialog.title',
        defaultMessage: 'Add Fee/Discount',
    },
    btnCancel: {
        id: 'app.booking.addFeeDiscountDialog.cancel',
        defaultMessage: 'Close',
    },
    btnSubmit: {
        id: 'app.booking.addFeeDiscountDialog.submit',
        defaultMessage: 'Submit',
    },
    lblDiscount: {
        id: 'app.booking.addFeeDiscountDialog.discount',
        defaultMessage: 'Discount',
    },
    lblFee: {
        id: 'app.booking.addFeeDiscountDialog.fee',
        defaultMessage: 'Fee',
    },
    lblFeeType: {
        id: 'app.booking.addFeeDiscountDialog.feeType',
        defaultMessage: 'Type',
    },
    lblAmount: {
        id: 'app.booking.addFeeDiscountDialog.amount',
        defaultMessage: 'Amount {currency}',
    },
    lblNotes: {
        id: 'app.booking.addFeeDiscountDialog.notes',
        defaultMessage: 'Notes',
    },
    errAmountInvalid: {
        id: 'app.booking.addFeeDiscountDialog.amountInvalid',
        defaultMessage: '^Please enter a valid amount.',
    },
    errBookingAmountInvalid: {
        id: 'app.booking.addFeeDiscountDialog.bookingAmountInvalid',
        defaultMessage: '^A discount of more than {amount} cannot be issued for this booking.',
    },
    errFeeAmountInvalid: {
        id: 'app.booking.addFeeDiscountDialog.feeAmountInvalid',
        defaultMessage: '^Fee amount must be greater than or equal to - {amount}.',
    },
    errType: {
        id: 'app.booking.addFeeDiscountDialog.errType',
        defaultMessage: '^Please select a type.',
    },
    errFeeDisc: {
        id: 'app.booking.addFeeDiscountDialog.errFeeDisc',
        defaultMessage: '^Please select a Fee or Discount.',
    },
    errNotes: {
        id: 'app.booking.addFeeDiscountDialog.errNotes',
        defaultMessage: '^Please enter a Fee/Discount description in the Notes field.',
    },
});
