import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import messages from './messagesTermsAndConditions';

import './termsAndConditions.css';
import { acceptTandCApi } from '../../apiLogin';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import inlineStyles from './styles';
import logoImage from '../../../images/logoSRFirst.png';

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        ...inlineStyles.dialogPaper,
    },
}));

class TermsAndConditions extends Component {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        open: PropTypes.bool,
        onClose: PropTypes.func,
        username: PropTypes.string,
        password: PropTypes.string,
        onAccept: PropTypes.func,
    };

    initialState = {
        processingSubmit: false,
        alertText: '',
    };

    constructor(props) {
        super(props);
        this.state = { data: fromJS(this.initialState) };
    }

    handleSubmit = () => {
        const { username, password } = this.props;
        this.setState((state) => ({ data: state.data.merge({ errors: {}, processingSubmit: true }) }));

        acceptTandCApi(
            {
                username,
                password,
            },
            (response) => {
                this.setState((state) => ({
                    data: state.data.merge({
                        alertText: response.errorResponse.message,
                        processingSubmit: false,
                    }),
                }));
            },
            () => {
                this.setState((state) => ({
                    data: state.data.merge({
                        processingSubmit: false,
                    }),
                }));
                this.props.onAccept();
            },
        );
    };

    handleClose = () => {
        this.props.onClose();
    };

    handleSnackbarClose = () => {
        this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
    };

    render() {
        const { data } = this.state;
        const { intl: { formatMessage } } = this.props;

        const actions = (
            <div className="row" style={inlineStyles.dialogActionsRoot}>
                <div className="col-4 offset-8 col-sm-3 offset-sm-9">
                    <Button
                        variant="contained"
                        onClick={this.handleSubmit}
                        fullWidth
                        color="primary"
                    >
                        {formatMessage(messages.lblAccept)}
                    </Button>
                </div>
            </div>
        );

        return (
            <div>
                <StyledDialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    disableEnforceFocus
                    maxWidth={false}
                >
                    <DialogTitle>{formatMessage(messages.lblTitle)}</DialogTitle>
                    <DialogContent>
                        <div><img styleName="logo" alt="SilverRail" src={logoImage} /></div>
                        <div styleName="tnc">
                            <div styleName="tnc-main">{formatMessage(messages.lblTitle)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblAgreementTitle)}</div>
                            <div>{formatMessage(messages.lblAgreement)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblTocTitle)}</div>
                            <div>{formatMessage(messages.lblToc1)}</div>
                            <div>{formatMessage(messages.lblToc2)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblAboutSrtTitle)}</div>
                            <div>{formatMessage(messages.lblAboutSrt)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblUseSALiteTitle)}</div>
                            <div>{formatMessage(messages.lblUseSALite1)}</div>
                            <div>{formatMessage(messages.lblUseSALite2)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblFeesChargesTitle)}</div>
                            <div>{formatMessage(messages.lblFeesCharges1)}</div>
                            <div>{formatMessage(messages.lblFeesCharges2)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblLiabilityDisclaimerTitle)}</div>
                            <div>{formatMessage(messages.lblLiabilityDisclaimer1)}</div>
                            <div>{formatMessage(messages.lblLiabilityDisclaimer2)}</div>
                            <div>{formatMessage(messages.lblLiabilityDisclaimer3)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblOtherTermsTitle)}</div>
                            <div>{formatMessage(messages.lblOtherTerms1)}</div>
                            <div>{formatMessage(messages.lblOtherTerms2)}</div>
                            <div>{formatMessage(messages.lblOtherTerms3)}</div>
                            <div>{formatMessage(messages.lblOtherTerms4)}</div>
                            <div>{formatMessage(messages.lblOtherTerms5)}</div>
                            <div>{formatMessage(messages.lblOtherTerms6)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblPrivacyPolicyTitle)}</div>
                            <div>{formatMessage(messages.lblPrivacyPolicy)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblYourConsentTitle)}</div>
                            <div>{formatMessage(messages.lblYourConsent)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblActiveCollectionTitle)}</div>
                            <div>{formatMessage(messages.lblActiveCollection)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblPassiveCollectionTitle)}</div>
                            <div>{formatMessage(messages.lblPassiveCollection1)}</div>
                            <div>{formatMessage(messages.lblPassiveCollection2)}</div>
                            <div>{formatMessage(messages.lblPassiveCollection3)}</div>
                            <div>{formatMessage(messages.lblPassiveCollection4)}</div>
                            <div>{formatMessage(messages.lblPassiveCollection5)}</div>
                            <div>{formatMessage(messages.lblPassiveCollection6)}</div>
                            <div>{formatMessage(messages.lblPassiveCollection7)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblUseOfInfoTitle)}</div>
                            <div>{formatMessage(messages.lblUseOfInfo)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblSharingOfInfoTitle)}</div>
                            <div>{formatMessage(messages.lblSharingOfInfo1)}</div>
                            <div>{formatMessage(messages.lblSharingOfInfo2)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblSecurityTitle)}</div>
                            <div>{formatMessage(messages.lblSecurity)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblChangesPrivacyPolicyTitle)}</div>
                            <div>{formatMessage(messages.lblChangesPrivacyPolicy)}</div>
                            <div styleName="tnc-title">{formatMessage(messages.lblLiabilityDisclaimerFinalTitle)}</div>
                            <div>{formatMessage(messages.lblLiabilityDisclaimerFinal1)}</div>
                            <div>{formatMessage(messages.lblLiabilityDisclaimerFinal2)}</div>
                            <div>{formatMessage(messages.lblLiabilityDisclaimerFinal3)}</div>
                            <div>{formatMessage(messages.lblLiabilityDisclaimerFinal4)}</div>
                            <div>{formatMessage(messages.lblLiabilityDisclaimerFinal5)}</div>
                            <div>{formatMessage(messages.lblLiabilityDisclaimerFinal6)}</div>
                            <div>{formatMessage(messages.lblLiabilityDisclaimerFinal7)}</div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </StyledDialog>
                <ExtendedSnackbar
                    id="srtAcceptTnCSnackBar"
                    open={(data.get('alertText') !== '')}
                    message={data.get('alertText')}
                    onClose={this.handleSnackbarClose}
                />
            </div>
        );
    }
}

// This alias will be used to access bare component for unit testing
export { TermsAndConditions as TermsAndConditionsAlias };

export default injectIntl(TermsAndConditions);
