import { emphasize } from '@mui/material/styles';

export default (theme) => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
    },
    valueContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    },
    chip: {
        margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        fontSize: 16,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
        fontWeight: 200,
    },
});

export const menuStyle = {
    zIndex: 10,
};

export const dropdownIndicatorStyle = {
    padding: 4,
};

export const valueContainerStyle = {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
};
