import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import validate from 'validate.js';

import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';

import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import { getAuthRefundsApi, setAuthRefundsApi } from '../../apiBooking';
import { fetchBookingDetails } from '../../ManageBooking/actionsManageBooking';
import { fetchUserData } from '../../../Shopping/ShoppingSearch/actionsShoppingSearch';
import { gaEvent } from '../../../utils/googleAnalytics';

import AuthorizeRefund from './AuthorizeRefund';
import messages from './messagesAuthorizeRefund';
import inlineStyles from './styles';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';

validate.extend(validate.validators.datetime, {
    parse: (date) => date,
    format: (timestamp) => new Date(timestamp).toLocaleDateString(),
});

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        ...inlineStyles.dialogPaper,
    },
}));

class AuthorizeRefundDialog extends Component {
    static propTypes = {
        intl: PropTypes.object,
        booking: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool,
        orderId: PropTypes.string,
        onAuthorizeRefundChanged: PropTypes.func,
        onFetchUserData: PropTypes.func,
        availableSuppliers: PropTypes.array,
    };

    initialState = {
        isFetching: false,
        isProcessingSubmit: false,
        alertText: '',
        authRefundDetails: {},
        receiptDate: 0,
        authRefunds: {},
        notes: '',
        errors: {},
        isApproved: true,
    };

    state = { data: fromJS(this.initialState) };

    // eslint-disable-next-line camelcase,react/sort-comp
    UNSAFE_componentWillMount() {
        const { open, booking, orderId } = this.props;
        if (open) {
            this.setState((state) => ({
                data: state.data.merge({
                    isFetching: true,
                    alertText: '',
                }),
            }));

            getAuthRefundsApi(
                {
                    queryItems: booking.queryItems,
                    orderId,
                },
                (response) => {
                    this.setState((state) => ({
                        data: state.data.merge({
                            isFetching: false,
                            alertText: response.errorResponse.message,
                        }),
                    }));
                },
                (response) => {
                    const { data } = response.successResponse;
                    this.setState((state) => ({
                        data: state.data.merge({
                            isFetching: false,
                            authRefundDetails: data,
                            receiptDate: new Date(Date.now()),
                            notes: '',
                            code: data?.BookingCouponsAuthorizeRefundCode,
                            description: data?.BookingCouponsAuthorizeRefundDescription,
                        }),
                    }));
                },
            );
        }
    }

    componentDidMount() {
        const { onFetchUserData } = this.props;
        onFetchUserData();
    }

    constraints = (formatMessage) => ({
        receiptDate: {
            presence: {
                message: formatMessage(messages.errNoReceiptDate),
                allowEmpty: false,
            },
        },
        code: {
            presence: {
                message: formatMessage(messages.errNoCode),
                allowEmpty: false,
            },
        },
        description: {
            presence: {
                message: formatMessage(messages.errNoDescription),
                allowEmpty: false,
            },
        },
        refundAmount: {
            numericality: {
                greaterThanOrEqualTo: 0,
                message: formatMessage(messages.errRefundNotNumber),
            },
        },
        penaltyAmount: {
            numericality: {
                greaterThanOrEqualTo: 0,
                message: formatMessage(messages.errPenaltyNotNumber),
            },
        },
    });

    handleSubmitAuthRefund = () => {
        const {
            booking, orderId, intl, onClose, onAuthorizeRefundChanged, intl: { formatMessage },
        } = this.props;
        const data = this.state.data.toJS();
        const errors = validate(data, this.constraints(formatMessage));
        const authsSelected = Object.entries(data.authRefunds);

        const anyCoupons = authsSelected.some(([item]) => (item > 0));

        if (!validate.isEmpty(errors) || !anyCoupons) {
            const updates = { errors };
            if (!anyCoupons) {
                updates.alertText = formatMessage(messages.errNoCouponsSelected);
            }

            this.setState((state) => ({
                data: state.data.merge(updates),
            }));
            return;
        }

        this.setState((state) => ({
            data: state.data.merge({
                isProcessingSubmit: true,
                alertText: '',
                errors: {},
            }),
        }));

        const order = booking.BookingOrders.find((item) => item.BookingOrderID === orderId);

        setAuthRefundsApi(
            {
                queryItems: booking.queryItems,
                orderId,
                authRefundData: data,
                intl,
                docLocator: order.BookingValueDocLocator,
            },
            (response) => {
                this.setState((state) => ({
                    data: state.data.merge({
                        isProcessingSubmit: false,
                        alertText: response.errorResponse.message,
                    }),
                }));
            },
            () => {
                this.setState((state) => ({
                    data: state.data.merge({
                        isProcessingSubmit: false,
                    }),
                }), () => {
                    onClose();
                    onAuthorizeRefundChanged(booking.queryItems);
                });
            },
        );
    };

    handleChangeField = (updates) => {
        this.setState((state) => ({ data: state.data.merge(updates) }));
    };

    handleSnackBarClose = () => {
        this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
    };

    render() {
        const data = this.state.data.toJS();
        const {
            isFetching,
            isProcessingSubmit,
            alertText,
            authRefundDetails,
            errors,
            ...values
        } = data;
        const {
            open,
            onClose,
            intl: { formatMessage },
            availableSuppliers,
        } = this.props;

        const disabled = isFetching || isProcessingSubmit;

        const closeAction = () => {
            gaEvent('authorizeRefundClose');
            onClose();
        };
        const actions = (
            <div className="row" style={inlineStyles.dialogActionsRoot}>
                <div className="col-12 col-sm-4 offset-sm-2">
                    <Button
                        variant="contained"
                        id="srtAuthorizeRefundClose"
                        onClick={closeAction}
                        fullWidth
                        disabled={disabled}
                    >
                        {formatMessage(messages.btnClose)}
                    </Button>
                </div>
                <div className="col-12 col-sm-6">
                    <Button
                        variant="contained"
                        id="srtAuthorizeRefundSubmit"
                        onClick={this.handleSubmitAuthRefund}
                        fullWidth
                        color="primary"
                        disabled={disabled}
                    >
                        {formatMessage(messages.btnSubmit)}
                    </Button>
                </div>
            </div>
        );

        return (
            <div>
                <StyledDialog
                    open={open}
                    onClose={closeAction}
                    disableEnforceFocus
                    maxWidth={false}
                >
                    <DialogTitle>{formatMessage(messages.lblTitle)}</DialogTitle>
                    <DialogContent>
                        {
                            !validate.isEmpty(authRefundDetails) ? (
                                <AuthorizeRefund
                                    authRefunds={authRefundDetails}
                                    errors={errors}
                                    onChange={this.handleChangeField}
                                    disabled={disabled}
                                    values={values}
                                    availableSuppliers={availableSuppliers}
                                />
                            ) : null
                        }
                        <RefreshIndicator
                            size={36}
                            top={0}
                            left={0}
                            status={(disabled) ? 'loading' : 'hide'}
                            style={inlineStyles.refreshIndicator}
                        />
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </StyledDialog>
                <ExtendedSnackbar
                    id="srtAuthorizeRefundSnackBar"
                    open={(alertText !== '')}
                    message={alertText}
                    onClose={this.handleSnackBarClose}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    availableSuppliers: state.getIn(['shopping', 'query', 'availableSuppliers']).toJS(),
});

const mapDispatchToProps = (dispatch) => ({
    onAuthorizeRefundChanged: bindActionCreators(fetchBookingDetails, dispatch),
    onFetchUserData: bindActionCreators(fetchUserData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AuthorizeRefundDialog));
