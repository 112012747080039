import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { formatPrice } from '../../../../utils';
import TicketableFareGroupTitle from '../../../../components/TicketableFareGroupTitle/TicketableFareGroupTitle';
import {
    Title,
    Link,
    StationLink,
    TableWrapper,
    TicketableFareGroupTitleCell,
} from './styled';
import messages from './messages';

const SplitFareDialog = ({
    open,
    onClose,
    travelSubSegments,
    intl,
    onOpenFareRules,
    ticketableFareGroupIndex,
    handleOpenStationInfoDialog,
    ticketableFareGroup,
    showSplitTitle,
    departStation,
    arriveStation,
}) => (
    <Dialog open={open} onClose={onClose}>
        <DialogContent>
            <Title><FormattedMessage {...messages.lblTitle} /></Title>
            <TableWrapper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><FormattedMessage {...messages.lblOrigin} /></TableCell>
                            <TableCell><FormattedMessage {...messages.lblDestination} /></TableCell>
                            <TableCell><FormattedMessage {...messages.lblFare} /></TableCell>
                            <TableCell><FormattedMessage {...messages.lblPrice} /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TicketableFareGroupTitleCell
                                id={`srtBookingTicketableFareGroup_${ticketableFareGroupIndex}`}
                                colSpan={4}
                            >
                                <TicketableFareGroupTitle
                                    ticketableFareGroup={ticketableFareGroup}
                                    showSplitTitle={showSplitTitle}
                                    departStation={departStation}
                                    arriveStation={arriveStation}
                                />
                            </TicketableFareGroupTitleCell>
                        </TableRow>
                        {travelSubSegments.map((travelSubSegment) => (
                            <TableRow key={travelSubSegment.BookingLegTravelSubSegmentId}>
                                <TableCell>
                                    <StationLink onClick={handleOpenStationInfoDialog(travelSubSegment.BookingLegTravelSubSegmentOrigStationCode)}>
                                        {travelSubSegment.BookingLegTravelSubSegmentOrigStationName}
                                    </StationLink>
                                    <div>{travelSubSegment.BookingLegTravelSubSegmentDepartDateTime}</div>
                                </TableCell>
                                <TableCell>
                                    <StationLink onClick={handleOpenStationInfoDialog(travelSubSegment.BookingLegTravelSubSegmentDestStationCode)}>
                                        {travelSubSegment.BookingLegTravelSubSegmentDestStationName}
                                    </StationLink>
                                    <div>{travelSubSegment.BookingLegTravelSubSegmentArriveDateTime}</div>
                                </TableCell>
                                <TableCell>
                                    <Link onClick={onOpenFareRules(travelSubSegment.BookingLegTravelSubSegmentId, ticketableFareGroupIndex)}>
                                        {travelSubSegment.BookingLegTravelSubSegmentFare}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    {formatPrice(
                                        Number(travelSubSegment.BookingLegTravelSubSegmentFarePrice) || 0,
                                        travelSubSegment.BookingLegTravelSubSegmentFareCurrency,
                                        intl,
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableWrapper>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={onClose}
                variant="contained"
            >
                <FormattedMessage {...messages.lblClose} />
            </Button>
        </DialogActions>
    </Dialog>
);

SplitFareDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    travelSubSegments: PropTypes.array,
    intl: PropTypes.object,
    onOpenFareRules: PropTypes.func,
    ticketableFareGroupIndex: PropTypes.number,
    handleOpenStationInfoDialog: PropTypes.func,
    ticketableFareGroup: PropTypes.object,
    showSplitTitle: PropTypes.bool,
    departStation: PropTypes.string,
    arriveStation: PropTypes.string,
};

export default SplitFareDialog;
