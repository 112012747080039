import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import BillingSummaryItemized from './BillingSummaryItemized';
import BillingSummaryOverview from './BillingSummaryOverview';
import { gaEvent } from '../../../../utils/googleAnalytics';
import messages from './messages';

function BillingSummary({
    booking,
    handleOpenAddFeeDiscountDialog,
    enableBookingAddFee,
    handleOpenMakePaymentDialog,
    handleOpenIssueRefundDialog,
}) {
    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (_, value) => {
        setSelectedTab(value);
        gaEvent('selectBillingSummaryTab', value);
    };
    return (
        <div>
            <h2><FormattedMessage {...messages.lblBillingSummary} /></h2>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
            >
                <Tab label={<FormattedMessage {...messages.lblItemized} />} />
                <Tab label={<FormattedMessage {...messages.lblOverview} />} />
            </Tabs>
            {selectedTab === 0 && (
                <BillingSummaryItemized
                    booking={booking}
                    handleOpenAddFeeDiscountDialog={handleOpenAddFeeDiscountDialog}
                    enableBookingAddFee={enableBookingAddFee}
                    handleOpenMakePaymentDialog={handleOpenMakePaymentDialog}
                    handleOpenIssueRefundDialog={handleOpenIssueRefundDialog}
                />
            )}
            {selectedTab === 1 && <BillingSummaryOverview booking={booking} />}
        </div>
    );
}

BillingSummary.propTypes = {
    booking: PropTypes.object.isRequired,
    enableBookingAddFee: PropTypes.bool,
    handleOpenAddFeeDiscountDialog: PropTypes.func,
    handleOpenMakePaymentDialog: PropTypes.func,
    handleOpenIssueRefundDialog: PropTypes.func,
};

export default BillingSummary;

// onChange={(event, expanded) => gaEvent(expanded ? 'expandOrderSection' : 'collapseOrderSection')}
