import { defineMessages } from 'react-intl';

export default defineMessages({
    lblTitle: {
        id: 'app.shopping.trackingInfoDialog.title',
        defaultMessage: 'Tracking Info',
    },
    btnClose: {
        id: 'app.shopping.trackingInfoDialog.close',
        defaultMessage: 'Close',
    },
    btnSubmit: {
        id: 'app.shopping.trackingInfoDialog.Submit',
        defaultMessage: 'Submit',
    },
    lblCountry: {
        id: 'app.shopping.trackingInfoDialog.country',
        defaultMessage: 'Country',
    },
    lblProvinceState: {
        id: 'app.shopping.trackingInfoDialog.provinceState',
        defaultMessage: 'Province/State',
    },
    lblName: {
        id: 'app.shopping.trackingInfoDialog.name',
        defaultMessage: 'Name',
    },
    lblEmail: {
        id: 'app.shopping.trackingInfoDialog.email',
        defaultMessage: 'Email',
    },
    lblCompany: {
        id: 'app.shopping.trackingInfoDialog.company',
        defaultMessage: 'Company',
    },
    lblAddress1: {
        id: 'app.shopping.trackingInfoDialog.address1',
        defaultMessage: 'Address 1',
    },
    lblAddress2: {
        id: 'app.shopping.trackingInfoDialog.address2',
        defaultMessage: 'Address 2',
    },
    lblCity: {
        id: 'app.shopping.trackingInfoDialog.city',
        defaultMessage: 'City',
    },
    lblPostalCode: {
        id: 'app.shopping.trackingInfoDialog.postalCode',
        defaultMessage: 'Postal Code',
    },
    lblDeliveryMethod: {
        id: 'app.shopping.trackingInfoDialog.deliveryMethod',
        defaultMessage: 'Delivery Method',
    },
    lblTrackingNumber: {
        id: 'app.shopping.trackingInfoDialog.trackingNumber',
        defaultMessage: 'Tracking Number',
    },
    lblShipmentDate: {
        id: 'app.shopping.trackingInfoDialog.shipmentDate',
        defaultMessage: 'Shipment Date',
    },
    lblShipperUrl: {
        id: 'app.shopping.trackingInfoDialog.shipperUrl',
        defaultMessage: 'Shipper URL',
    },
    lblShippingAddress: {
        id: 'app.shopping.trackingInfoDialog.shippingAddress',
        defaultMessage: 'Shipping Address',
    },
    lblTrackingInfo: {
        id: 'app.shopping.trackingInfoDialog.trackingInfo',
        defaultMessage: 'Tracking Info',
    },
    errInvalidShipDate: {
        id: 'app.shopping.trackingInfoDialog.invalidShipDate',
        defaultMessage: '^Please enter a valid date.',
    },
    errInvalidShipperUrl: {
        id: 'app.shopping.trackingInfoDialog.invalidShipperUrl',
        defaultMessage: '^Please enter a valid URL.',
    },
});
