import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Paper from '@mui/material/Paper';
import validate from 'validate.js';

import FareItinerary from './FareItinerary';
import FareSeason from './FareSeason';
import messages from './messagesFareLeg';
import getLegItinerary from './getLegItinerary';
import localeSettings from '../../../localeSettings';

import {
    TRIP_TYPE_SEASON,
} from '../../util';

import inlineStyles from './fareLegStyles';

class FareLeg extends PureComponent {
    static propTypes = {
        leg: PropTypes.object,
        legId: PropTypes.string,
        booking: PropTypes.bool,
        isReturnType: PropTypes.bool,
        outboundMsg: PropTypes.string,
        inboundMsg: PropTypes.string,
        departDateString: PropTypes.string,
        returnDateString: PropTypes.string,
        endDateString: PropTypes.string,
        intl: PropTypes.object,
        onChangeSelection: PropTypes.func,
        onSelection: PropTypes.func.isRequired,
        selectedFare: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.object,
        ]),
        selectedFares: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.object,
        ]),
        otherFareSelected: PropTypes.object,
        explicitFareCompats: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object,
        ]),
        fareMatchingBasedOnSameCabinClass: PropTypes.bool,
        fareFilter: PropTypes.func,
        tripType: PropTypes.number,
        addOrderMode: PropTypes.bool,
        exchangeOrderMode: PropTypes.bool,
        fareIndex: PropTypes.number,
        isCreateBookingPage: PropTypes.bool,
    };

    getFooterNote = (hasOvertakenJourney, hasUnconfirmedSchedule) => {
        const {
            intl: { formatMessage },
        } = this.props;

        const notes = [];
        if (hasOvertakenJourney) {
            notes.push(formatMessage(messages.lblOvertaken));
        }
        if (hasUnconfirmedSchedule) {
            notes.push(formatMessage(messages.lblUnconfirmedSchedule));
        }
        return !!notes.length && (
            <Paper className="col-12" style={inlineStyles.overtakenContainer} elevation={1}>
                {notes.join('; ')}
            </Paper>
        );
    }

    handleFareChangeSelection = (fare) => {
        if (this.props.onChangeSelection) {
            this.props.onChangeSelection(fare, this.props.legId);
        }
    };

    handleFareSelection = (fare, legSolutionId) => {
        this.props.onSelection(this.props.leg, fare, this.props.legId, legSolutionId);
    };

    render() {
        const {
            leg,
            legId,
            booking,
            isReturnType,
            selectedFare,
            selectedFares,
            otherFareSelected,
            explicitFareCompats,
            fareMatchingBasedOnSameCabinClass,
            fareFilter,
            tripType,
            addOrderMode,
            exchangeOrderMode,
            intl: { formatMessage },
        } = this.props;

        const dateFormat = formatMessage(localeSettings.dateFormat);
        const timeFormat = formatMessage(localeSettings.timeFormat);
        let hasOvertakenJourney = false;
        let hasUnconfirmedSchedule = false;

        const renderItinerary = (itinerary, index, fare, mapIndex) => {
            const {
                departDateString,
                returnDateString,
                endDateString,
                outboundMsg,
                inboundMsg,
                fareIndex,
                isCreateBookingPage,
            } = this.props;
            const marketingCarrier = {
                name: leg.ShoppingLegOrigStationSuppliers,
                supplier: leg.ShoppingLegSolutionCarrierSupplyChannelCode || '',
            };
            const departDate = (fareIndex === 1 || mapIndex === 1) ? returnDateString : departDateString;
            const legItinerary = getLegItinerary(
                itinerary,
                (index + 1),
                booking,
                departDate,
                dateFormat,
                timeFormat,
                formatMessage,
                fareFilter,
                !validate.isEmpty(fare),
            );

            legItinerary.marketingCarrier = marketingCarrier;
            let itineraryComp = null;
            const itinerarySelected = !!fare && fare.legSolutionId === legItinerary.legSolutionId;

            hasOvertakenJourney = hasOvertakenJourney || legItinerary.overtaken;
            hasUnconfirmedSchedule = hasUnconfirmedSchedule || legItinerary.unconfirmedSchedule;

            const sharedProps = {
                key: index,
                itinerary: legItinerary,
                onChangeSelection: this.handleFareChangeSelection,
                onSelection: this.handleFareSelection,
                selectedFare: fare,
                singleFareMode: booking,
                legTitle: (fareIndex === 1 || mapIndex === 1) ? inboundMsg : outboundMsg,
                isCreateBookingPage,
            };
            if (!fare || itinerarySelected) {
                itineraryComp = tripType === TRIP_TYPE_SEASON ? (
                    <FareSeason
                        departDate={departDate}
                        endDate={endDateString}
                        {...sharedProps}
                    />
                ) : (
                    <FareItinerary
                        otherFareSelected={otherFareSelected}
                        explicitFareCompats={explicitFareCompats}
                        fareMatchingBasedOnSameCabinClass={fareMatchingBasedOnSameCabinClass}
                        isReturnType={isReturnType}
                        addOrderMode={addOrderMode}
                        exchangeOrderMode={exchangeOrderMode}
                        {...sharedProps}
                    />
                );
            }
            return itineraryComp;
        };

        return (
            <div id={`Leg_${legId}`} style={inlineStyles.leg}>
                {
                    isReturnType
                        ? selectedFares.map((fare, mapIndex) => (
                            fare.leg.ShoppingLegSolutions.map((itinerary, index) => (
                                renderItinerary(itinerary, index, fare, mapIndex)))
                        )) : leg.ShoppingLegSolutions.map((itinerary, index) => (
                            renderItinerary(itinerary, index, selectedFare)))
                }
                {this.getFooterNote(hasOvertakenJourney, hasUnconfirmedSchedule)}
            </div>
        );
    }
}

// This alias will be used to access bare component for unit testing
export { FareLeg as FareLegAlias, getLegItinerary };

export default injectIntl(FareLeg);
