import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { injectIntl } from 'react-intl';

import messages from './AssocAutoCompleteMessage';
import styles from './AssocAutoCompleteListStyle';

const StyledChip = styled(Chip)(() => ({
    ...styles.chip,
    '& .MuiChip-label': {
        ...styles.chipLabel,
    },
}));

const StyledListSubheader = styled(ListSubheader)(() => ({
    ...styles.subheader,
}));

const StyledList = styled(List)(() => ({
    ...styles.chipContainer,
}));

class AssocAutoCompleteList extends PureComponent {
    state = {
        filterValue: '',
    }

    onFilterChange = ({ target: { value: filterValue } }) => {
        this.setState({ filterValue });
    }

    render() {
        const {
            id,
            values,
            headerLabel,
            removeOption,
            icon,
            intl: { formatMessage },
        } = this.props;

        const { filterValue } = this.state;

        const filteredValues = values.filter((value) => {
            const valueText = validate.isObject(value) ? value.text : value;
            return valueText && RegExp(filterValue, 'i').test(valueText);
        });

        const chipRenderer = ({
            index, key, style, isScrolling,
        }) => {
            let chip = '...';
            if (!isScrolling) {
                const value = filteredValues[index];
                const valueText = validate.isObject(value) ? value.text : value;
                chip = (
                    <StyledChip
                        id={`${id}_${valueText}`}
                        onDelete={() => removeOption(index)}
                        avatar={icon && <Avatar>{icon}</Avatar>}
                        label={valueText}
                    />
                );
            }

            return (
                <div key={key} style={style}>
                    {chip}
                </div>
            );
        };

        return (
            <div id={id}>
                <StyledListSubheader disableSticky>
                    {headerLabel}
                    <TextField
                        id="srtAssocAutoCompleteListTableFilter"
                        style={styles.filter}
                        placeholder={formatMessage(messages.lblFilter)}
                        onChange={this.onFilterChange}
                        value={filterValue}
                        variant="standard"
                    />
                </StyledListSubheader>
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <StyledList
                            height={240}
                            rowCount={filteredValues.length}
                            rowHeight={42}
                            rowRenderer={chipRenderer}
                            width={width}
                        />
                    )}
                </AutoSizer>
            </div>
        );
    }
}

AssocAutoCompleteList.propTypes = {
    id: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
    headerLabel: PropTypes.string.isRequired,
    removeOption: PropTypes.func.isRequired,
    icon: PropTypes.node.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(AssocAutoCompleteList);
