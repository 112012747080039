import { defineMessages } from 'react-intl';

export default defineMessages({
    lblTitle: {
        id: 'app.shopping.createBooking.onBoardServices.title',
        defaultMessage: 'Onboard Services',
    },
    lblSelectMeal: {
        id: 'app.shopping.createBooking.onBoardServices.selectMeal',
        defaultMessage: 'Select Meal',
    },
    lblSelectInternet: {
        id: 'app.shopping.createBooking.onBoardServices.selectInternet',
        defaultMessage: 'Select Internet',
    },
    lblLeg: {
        id: 'app.shopping.createBooking.onBoardServices.leg',
        defaultMessage: 'Leg',
    },
    lblPassenger: {
        id: 'app.shopping.createBooking.onBoardServices.passenger',
        defaultMessage: 'Passenger',
    },
    lblTo: {
        id: 'app.shopping.createBooking.onBoardServices.to',
        defaultMessage: 'to',
    },
    lblNoService: {
        id: 'app.shopping.createBooking.onBoardServices.noService',
        defaultMessage: 'No service can be selected for this segment',
    },
    lblDiscard: {
        id: 'app.shopping.createBooking.onBoardServices.discard',
        defaultMessage: 'Discard',
    },
    lblSave: {
        id: 'app.shopping.createBooking.onBoardServices.save',
        defaultMessage: 'Save',
    },
});
