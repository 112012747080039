import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fromJS } from 'immutable';
import { injectIntl, FormattedMessage } from 'react-intl';
import validate from 'validate.js';

import { getRefundDetailsApi, submitRefundApi, sendTicketApi } from '../../apiBooking';
import {
    fetchBookingDetails,
    clearCancelOrderId,
    clearSendTicketNewOrder,
    clearOpenIssueRefundDialog,
} from '../../ManageBooking/actionsManageBooking';
import { formatPrice, calculateAmount } from '../../../utils';

import Content from './Content';
import PaymentIssueRefundType from './PaymentIssueRefundType';
import Actions from './Actions';
import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';

import messages from './messagesIssueRefund';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import { gaEvent } from '../../../utils/googleAnalytics';
import inlineStyles from './styles';
import { EP_STATUS } from './constants';
import { calculateOriginalAmount } from './utils';

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        ...inlineStyles.dialogPaper,
    },
}));

const IssueRefundDialog = ({
    id = 'srtIssueRefund',
    open = false,
    booking,
    issueRefund,
    cancelOrderId,
    intl: { formatMessage },
    sendTicket,
    onRefundSuccess,
    onClose,
    onClearOpenIssueRefundDialog,
    onSendTicketSuccess,
    onClearCancelOrderId,
    sendTicketNewOrderId,
    onClearSendTicketNewOrder,
    intl,
    smsCodeOptions,
    issueRefundDialog,
}) => {
    const initialState = {
        data: fromJS({
            refundDetails: {},
            alertText: '',
            isFetching: false,
            currentReceipt: {},
            refundAmount: 0,
            refundCurrency: '',
            amount: 0,
            isProcessingSubmit: false,
            showWelcomeConfirmation: false,
            isEPNotSettled: false,
            vdEmail: '',
            smsNumber: booking.BookingOrders[0].BookingOrderTDOData.BookingFulfillmentInfo.BookingFulfillmentPhoneNumber,
            smsCountryCode: booking.BookingOrders[0].BookingOrderTDOData.BookingFulfillmentInfo.BookingFulfillmentPhoneCountryCode,
            showAlternateEmail: false,
            errors: {},
            showIssueRefund: false,
            showSendTicket: false,
        }),
    };

    const [state, setState] = React.useState(initialState);

    useEffect(() => {
        if (sendTicket) {
            const bookingOrder = cancelOrderId ? booking.BookingOrders.find((item) => item.BookingOrderID === cancelOrderId)
                : booking.BookingOrders[0];
            const vdEmail = (bookingOrder.BookingEmailForClaimVD && bookingOrder.BookingEmailForClaimVD?.length > 0)
                ? bookingOrder.BookingEmailForClaimVD[0] : '';
            setState((prevState) => ({
                data: prevState.data.merge({ vdEmail }),
            }));
        }
    }, []);

    const getRefundDetails = (queryItems) => {
        setState((prevState) => ({
            data: prevState.data.merge({
                isFetching: true,
            }),
        }));

        getRefundDetailsApi(
            queryItems,
            (response) => {
                setState((prevState) => ({
                    data: prevState.data.merge({
                        isFetching: false,
                        alertText: response.errorResponse.message,
                    }),
                }));
            },
            (response) => {
                const { data } = response.successResponse;

                const currentReceipt = data.BookingRefundablePayments[0];

                const issueRefundEligibility = currentReceipt.BookingIssueRefundEligibility;

                const epStatus = [EP_STATUS.SETTLING, EP_STATUS.SETTLED, EP_STATUS.VOIDED, undefined, null, EP_STATUS.SUBMITTED_FOR_SETTLEMENT]
                    .includes(currentReceipt.BookingBillingTransactionStatus);

                const isEPNotSettled = !issueRefundEligibility && !epStatus;

                const reverseRefundMutation = issueRefundEligibility && !epStatus;

                const amount = calculateAmount(
                    data.BookingPaymentSummary.BookingPaymentSummaryBalanceDue.replace('-', ''),
                    currentReceipt.BookingBillingPaymentDetailBalance,
                );

                setState((prevState) => ({
                    data: prevState.data.merge({
                        isFetching: false,
                        refundDetails: data,
                        currentReceipt,
                        refundAmount: amount,
                        amount,
                        isEPNotSettled,
                        reverseRefundMutation,
                        alertText: isEPNotSettled ? formatMessage(messages.errExternalPaymentStatus) : '',
                    }),
                }));
            },
        );
    };

    useEffect(() => {
        if (open && issueRefund) {
            const billingTotal = booking.BookingBillingTotal;
            if (open === true) gaEvent('issueRefundInitiate');
            setState((prevState) => ({
                data: prevState.data.merge({
                    showWelcomeConfirmation: open !== true,
                    refundAmount: billingTotal && calculateOriginalAmount(billingTotal),
                    refundCurrency: billingTotal && billingTotal.BookingBillingTotalCurrency,
                }),
            }));
            if (open === true) getRefundDetails(booking.queryItems);
        } else if (open && sendTicket) {
            setState((prevState) => ({
                data: prevState.data.merge({
                    showWelcomeConfirmation: open !== true,
                }),
            }));
        }
    }, [open]);

    const updateRefoundAmountOnReceiptSelected = (value) => {
        const { data } = state;
        const refundAmount = calculateAmount(
            data.getIn(['refundDetails', 'BookingPaymentSummary', 'BookingPaymentSummaryBalanceDue']).replace('-', ''),
            value,
        );
        setState((prevState) => ({
            data: prevState.data.merge({ amount: refundAmount, refundAmount }),
        }));
    };

    const onTabChange = (value) => {
        setState((prevState) => ({ data: prevState.data.set('currentReceipt', value) }));
        updateRefoundAmountOnReceiptSelected(value.BookingBillingPaymentDetailBalance);
    };

    const constraints = () => {
        if (issueRefund) {
            return {
                refundAmount: (attr, attributes) => ({
                    numericality: {
                        lessThanOrEqualTo: attributes.amount,
                        greaterThan: 0,
                        message: formatMessage(messages.errRefundAmount),
                    },
                    presence: {
                        message: formatMessage(messages.errEmptyRefundAmount),
                        allowEmpty: false,
                    },
                }),
            };
        }
        return {
            vdEmail: {
                presence: {
                    message: formatMessage(messages.errConfirmationEmail), allowEmpty: false,
                },
                email: {
                    message: formatMessage(messages.errConfirmationEmail),
                },
            },
        };
    };

    const updateRefundAmountOnReceiptChange = (value) => {
        setState((prevState) => ({ data: prevState.data.merge({ refundAmount: value }) }));
    };

    const handleContinue = () => {
        if (issueRefund) {
            setState((prevState) => ({ data: prevState.data.set('showWelcomeConfirmation', false) }));
            getRefundDetails(booking.queryItems);
            gaEvent('issueRefundYes', open);
        }
        if (sendTicket) {
            setState((prevState) => ({ data: prevState.data.set('showWelcomeConfirmation', false) }));
            gaEvent('sendTicketYes', open);
        }
    };

    const handleSubmitRefund = () => {
        setState((prevState) => ({ data: prevState.data.merge({ isProcessingSubmit: true }) }));
        const data = state.data.toJS();
        const error = validate(data, constraints());
        if (!validate.isEmpty(error)) {
            setState((prevState) => ({
                data: prevState.data.merge({
                    isProcessingSubmit: false,
                    alertText: error.refundAmount ? error.refundAmount[0] : '',
                }),
            }));
            return;
        }

        submitRefundApi(
            {
                queryItems: booking.queryItems,
                amount: data.refundAmount,
                currency: data.currentReceipt.BookingBillingPaymentDetailCurrency,
                receiptNumber: data.currentReceipt.BookingBillingPaymentReceiptNumber,
                externalProvider: data.currentReceipt.BookingBillingExternalProvider,
                externalReference: data.currentReceipt.BookingBillingExternalReference,
                reverseRefundMutation: data.reverseRefundMutation,
            },
            (response) => {
                setState((prevState) => ({
                    data: prevState.data.merge({
                        isProcessingSubmit: false,
                        alertText: response.errorResponse.message,
                    }),
                }));
            },
            () => {
                onRefundSuccess(booking.queryItems).then(() => {
                    if (!data.showSendTicket) {
                        onClose();
                    }
                    onClearOpenIssueRefundDialog();
                    setState((prevState) => ({ data: prevState.data.merge({ isProcessingSubmit: false, showIssueRefund: false }) }));
                });
            },
        );
    };

    const handleSnackBarClose = () => {
        setState((prevState) => ({ data: prevState.data.set('alertText', '') }));
    };

    const showIssueRefundData = (data) => {
        if (data && !data.isFetching && data.currentReceipt && Object.keys(data.currentReceipt).length > 0) {
            return (
                <PaymentIssueRefundType
                    type={data.currentReceipt.BookingBillingPaymentModel}
                    params={{ id, data }}
                    updateRefundAmountOnReceiptChange={updateRefundAmountOnReceiptChange}
                />
            );
        }

        if (data.showWelcomeConfirmation) {
            return (
                <FormattedMessage
                    {...messages.lblWelcomeConfirmation}
                    values={{ amount: formatPrice(data.refundAmount, data.refundCurrency, intl) }}
                />
            );
        }
        return null;
    };

    const handleSubmitSend = () => {
        setState((prevState) => ({ data: prevState.data.merge({ errors: {}, isProcessingSubmit: true }) }));

        const data = state.data.toJS();
        const errors = validate(data, constraints());
        if (!validate.isEmpty(errors)) {
            setState((prevState) => ({
                data: prevState.data.merge({
                    isProcessingSubmit: false,
                    errors,
                }),
            }));
            return;
        }
        const { queryItems } = booking;
        const orderId = sendTicketNewOrderId;
        const bookingOrder = orderId ? booking?.BookingOrders?.find((item) => item?.BookingOrderID === orderId) : booking?.BookingOrders[0];
        const filteredVdUrl = booking.BookingOrders.map((item) => item.BookingResponseValueDocumentUrl)
            .flat(1).filter((item) => item !== 'REDELIVERABLE_TOKEN');
        const tdo = bookingOrder?.BookingOrderTicketDelivery;
        const vdSms = data.smsCountryCode ? `${data.smsCountryCode}:${data.smsNumber}` : '';
        const vdUrl = filteredVdUrl.length > 0 ? filteredVdUrl : ['REDELIVERABLE_TOKEN'];

        sendTicketApi(
            {
                queryItems,
                orderId,
                email: data.vdEmail,
                vdUrl,
                vdSms,
                tdo,
            },
            (response) => {
                setState((prevState) => ({
                    data: prevState.data.merge({
                        isProcessingSubmit: false,
                        alertText: response.errorResponse.message,
                    }),
                }));
            },
            () => {
                setState((prevState) => ({
                    data: prevState.data.merge({
                        isProcessingSubmit: false,
                        showSendTicket: false,
                    }),
                }));
                onClose();
                onSendTicketSuccess(queryItems);
                onClearCancelOrderId();
                onClearSendTicketNewOrder();
            },
        );
    };

    const handleChangeEmail = (updates) => {
        setState((prevState) => ({ data: prevState.data.merge(updates) }));
    };

    const handleChangeSms = ({ smsCountryCode, smsNumber }) => {
        setState((prevState) => ({
            data: prevState.data.merge({
                smsCountryCode,
                smsNumber,
            }),
        }));
    };

    const handleChange = (type) => (event) => {
        const { checked } = event.target;
        if (type === 'Refund') {
            setState((prevState) => ({
                data: prevState.data.merge({ showIssueRefund: checked }),
            }));
        }
        if (type === 'SentTicket') {
            setState((prevState) => ({
                data: prevState.data.merge({ showSendTicket: checked }),
            }));
        }
    };

    const data = state.data.toJS();

    let title = messages.lblRefundDueTitle;

    if (issueRefund && sendTicket) {
        if (data.showIssueRefund) {
            title = data.showWelcomeConfirmation ? messages.lblRefundDueTitle : messages.lblIssueRefundTitle;
        } else if (data.showSendTicket) {
            title = data.showWelcomeConfirmation ? messages.lblRefundDueTitle : messages.lblSendTicketTitle;
        } else {
            title = data.showWelcomeConfirmation ? messages.lblRefundDueTitle : messages.lblIssueRefundTitle;
        }
    } else if (issueRefund) {
        title = data.showWelcomeConfirmation ? messages.lblRefundDueTitle : messages.lblIssueRefundTitle;
    } else if (sendTicket) {
        title = data.showWelcomeConfirmation ? messages.lblRefundDueTitle : messages.lblSendTicketTitle;
    }

    const closeAction = () => {
        if (issueRefund && sendTicket) {
            if (data.showIssueRefund) {
                setState((prevState) => ({
                    data: prevState.data.merge({ showIssueRefund: false }),
                }));
                gaEvent(data.showWelcomeConfirmation ? 'issueRefundNo' : 'issueRefundClose', open);
            }
            if (data.showSendTicket) {
                setState((prevState) => ({
                    data: prevState.data.merge({ showSendTicket: false }),
                }));
                gaEvent(data.showWelcomeConfirmation ? 'sendTicketNo' : 'sendTicketClose', open);
            }
        } else if (issueRefund) {
            gaEvent(data.showWelcomeConfirmation ? 'issueRefundNo' : 'issueRefundClose', open);
        } else if (sendTicket) {
            gaEvent(data.showWelcomeConfirmation ? 'sendTicketNo' : 'sendTicketClose', open);
        }
        onClose();
    };

    return (
        <div>
            <StyledDialog
                open={!!open}
                onClose={closeAction}
                disableEnforceFocus
                maxWidth={false}
            >
                <DialogTitle>{formatMessage(title)}</DialogTitle>
                <DialogContent>
                    <Content
                        data={data}
                        booking={booking}
                        intl={intl}
                        issueRefund={issueRefund}
                        sendTicket={sendTicket}
                        smsCodeOptions={smsCodeOptions}
                        issueRefundDialog={issueRefundDialog}
                        handleChange={handleChange}
                        handleChangeEmail={handleChangeEmail}
                        handleChangeSms={handleChangeSms}
                        onTabChange={onTabChange}
                        showIssueRefundData={showIssueRefundData}
                    />
                </DialogContent>
                {data.isFetching
                    && (
                        <RefreshIndicator
                            size={36}
                            left="50%"
                            top={50}
                            status="loading"
                        />
                    )}
                <DialogActions>
                    <Actions
                        closeAction={closeAction}
                        data={data}
                        handleContinue={handleContinue}
                        handleSubmitSend={handleSubmitSend}
                        handleSubmitRefund={handleSubmitRefund}
                        issueRefund={issueRefund}
                        sendTicket={sendTicket}
                        issueRefundDialog={issueRefundDialog}
                    />
                </DialogActions>
            </StyledDialog>
            <ExtendedSnackbar
                id="srtBookingIssueRefundSnackBar"
                open={data.alertText !== ''}
                message={data.alertText}
                onClose={handleSnackBarClose}
            />
        </div>
    );
};

IssueRefundDialog.propTypes = {
    id: PropTypes.string,
    intl: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    booking: PropTypes.object.isRequired,
    onRefundSuccess: PropTypes.func,
    issueRefund: PropTypes.bool,
    sendTicket: PropTypes.bool,
    smsCodeOptions: PropTypes.array,
    onSendTicketSuccess: PropTypes.func,
    onClearCancelOrderId: PropTypes.func,
    onClearOpenIssueRefundDialog: PropTypes.func,
    onClearSendTicketNewOrder: PropTypes.func,
    cancelOrderId: PropTypes.string,
    sendTicketNewOrderId: PropTypes.string,
    issueRefundDialog: PropTypes.bool,
};

export { IssueRefundDialog as IssueRefundDialogAlias };

const mapStateToProps = (state) => ({
    cancelOrderId: state.getIn(['booking', 'cancelOrderId']),
    sendTicketNewOrderId: state.getIn(['booking', 'sendTicketNewOrderId']),
    sendTicket: state.getIn(['booking', 'isSendTicketPossible']),
    issueRefundDialog: state.getIn(['booking', 'dialogs', 'issueRefundDialog']),
});

const mapDispatchToProps = (dispatch) => ({
    onRefundSuccess: bindActionCreators(fetchBookingDetails, dispatch),
    onSendTicketSuccess: bindActionCreators(fetchBookingDetails, dispatch),
    onClearCancelOrderId: bindActionCreators(clearCancelOrderId, dispatch),
    onClearSendTicketNewOrder: bindActionCreators(clearSendTicketNewOrder, dispatch),
    onClearOpenIssueRefundDialog: bindActionCreators(clearOpenIssueRefundDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(IssueRefundDialog));
