import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import messages from './messagesConfirmedCardInfo';

const ConfirmedCardInfoView = (props) => {
    const { formatMessage } = props.intl;
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-6">
                    <b>{formatMessage(messages.lblFirstName)}</b>
                </div>
                <div className="col-6">
                    {props.confirmedCardInfo.BookingOrderConfirmationFirstName}
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <b>{formatMessage(messages.lblLastName)}</b>
                </div>
                <div className="col-6">
                    {props.confirmedCardInfo.BookingOrderConfirmationLastName}
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <b>{formatMessage(messages.lblMiddleName)}</b>
                </div>
                <div className="col-6">
                    {props.confirmedCardInfo.BookingOrderConfirmationMiddleName}
                </div>
            </div>
            {
                !(props.confirmedCardInfo.BookingOrderConfirmationType === 'DC' || props.confirmedCardInfo.BookingOrderConfirmationType === 'CC')
                || (
                    <div>
                        <div className="row">
                            <div className="col-6">
                                <b>{formatMessage(messages.lblCardNumber)}</b>
                            </div>
                            <div className="col-6">
                                {props.confirmedCardInfo.BookingOrderConfirmationCardNumber}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <b>{formatMessage(messages.lblExpirationDate)}</b>
                            </div>
                            <div className="col-6">
                                {props.confirmedCardInfo.BookingOrderConfirmationExpirationDate}
                            </div>
                        </div>
                    </div>
                )
            }
            {props.confirmedCardInfo.BookingOrderConfirmationType === 'LC' && (
                <div>
                    <div className="row">
                        <div className="col-6">
                            <b>{formatMessage(messages.lblLoyaltyCardProgram)}</b>
                        </div>
                        <div className="col-6">
                            {props.confirmedCardInfo.BookingOrderConfirmationProgram}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <b>{formatMessage(messages.lblLoyaltyCardIdentifier)}</b>
                        </div>
                        <div className="col-6">
                            {props.confirmedCardInfo.BookingOrderConfirmationIdentifier}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

ConfirmedCardInfoView.propTypes = {
    intl: PropTypes.object,
    confirmedCardInfo: PropTypes.object,
};

export { ConfirmedCardInfoView as ConfirmedCardInfoViewAlias };
export default injectIntl(ConfirmedCardInfoView);
