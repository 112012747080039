import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';

import Address from '../Address/Address';

import messages from './messagesTicketDelivery';

function ShippingAddress({
    intl: { formatMessage },
    canadianProvinces,
    countries,
    errors,
    onTdoAddressChange,
    onTdoMailNameChange,
    states,
    tdoMailAddress,
    tdoMailName,
}) {
    return (
        <div>
            <div>
                <FormattedMessage {...messages.lblTDOMailShipping} />
            </div>
            <div>
                <TextField
                    id="srtTdoMailName"
                    fullWidth
                    placeholder={formatMessage(messages.lblTDOMailName)}
                    value={tdoMailName}
                    onChange={onTdoMailNameChange}
                    error={!!errors.tdoMailName}
                    helperText={errors.tdoMailName}
                    variant="standard"
                />
            </div>
            <div>
                <Address
                    idPrefix="srtTdoMailAddress"
                    values={tdoMailAddress}
                    countries={countries}
                    states={states}
                    provinces={canadianProvinces}
                    onChange={onTdoAddressChange}
                    errors={errors.address ? errors.address : {}}
                    useDefaults
                />
            </div>
        </div>
    );
}

ShippingAddress.propTypes = {
    intl: PropTypes.object,
    tdoMailName: PropTypes.string,
    onTdoMailNameChange: PropTypes.func,
    tdoMailAddress: PropTypes.object,
    onTdoAddressChange: PropTypes.func,
    countries: PropTypes.object,
    states: PropTypes.object,
    canadianProvinces: PropTypes.object,
    errors: PropTypes.object,
};

export default injectIntl(ShippingAddress);
