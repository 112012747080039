import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';

import '../../ManageBooking/components/BookingView/styleBookingView.css';
import inlineStyles from './styles';

const NotesTable = (props) => {
    const { tableHeaders, tableRows } = props;

    return (
        <Table style={inlineStyles.tableWrapper} styleName="bookingTable">
            <TableHead style={inlineStyles.tableHead}>
                {tableHeaders}
            </TableHead>
            <TableBody>
                {tableRows}
            </TableBody>
        </Table>
    );
};

NotesTable.propTypes = {
    tableHeaders: PropTypes.object.isRequired,
    tableRows: PropTypes.array.isRequired,
};

export default NotesTable;
