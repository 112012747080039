import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import { styled } from '@mui/system';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import validate from 'validate.js';
import { connect } from 'react-redux';
import moment from 'moment';

import RefreshIndicator from '../RefreshIndicator/RefreshIndicator';
import SelectLocale from '../SelectLocale/SelectLocale';
import { gaEvent } from '../../utils/googleAnalytics';

import messages from './messagesUserProfile';

import { getUserProfileApi, updateUserProfileApi } from '../../Admin/apiAdmin';
import ExtendedSnackbar from '../ExtendedSnackbar/ExtendedSnackbar';
import { getDateTimeFormat24H } from '../../utils/datetimeUtils';

import styles from './styles';

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        ...styles.dialogPaper,
    },
}));

class UserProfile extends Component {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        open: PropTypes.bool,
        handleClose: PropTypes.func,
        loginId: PropTypes.string,
    };

    initialState = {
        user: {
            UserManagementUserLoginId: '',
            UserManagementProfileFirstName: '',
            UserManagementProfileLastName: '',
            UserManagementProfileBusinessEmail: '',
            UserManagementProfileSrtLocale: '',
            UserManagementProfileBrandNames: [],
        },
        originalUser: {},
        processingSubmit: false,
        alertText: '',
        canSubmit: true,
        errors: {},
        isFetching: false,
    };

    constructor(props) {
        super(props);
        this.state = { data: fromJS(this.initialState) };
    }

    componentDidMount() {
        if (this.props.open) {
            this.updateProfileState();
        }
    }

    // eslint-disable-next-line camelcase,react/sort-comp
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.open && nextProps.open) {
            this.updateProfileState();
        }
    }

    // Validation constraints
    constraints = () => {
        const { intl: { formatMessage } } = this.props;
        return {
            businessEmail: {
                presence: {
                    message: formatMessage(messages.errEmptyEmail),
                    allowEmpty: false,
                },
                email: {
                    message: formatMessage(messages.errInvalidEmail),
                },
            },
        };
    }

    updateProfileState = () => {
        const { intl: { formatMessage } } = this.props;
        this.setState((state) => ({ data: state.data.merge({ isFetching: true, user: this.initialState.user }) }));
        getUserProfileApi(
            this.props.loginId,
            () => {
                this.setState((state) => ({
                    data: state.data.merge({
                        alertText: formatMessage(messages.errGetUserProfile),
                        isFetching: false,
                    }),
                }));
            },
            (response) => {
                const user = response.successResponse.data;
                this.setState((state) => ({
                    data: state.data.merge({
                        user,
                        originalUser: user,
                        isFetching: false,
                    }),
                }));
            },
        );
    };

    handleEmailChange = ({ target: { value } }) => {
        this.setState((state) => ({ data: state.data.mergeIn(['user', 'UserManagementProfileBusinessEmail'], value) }));
    };

    handleLocaleChange = ({ target: { value } }) => {
        this.setState((state) => ({ data: state.data.mergeIn(['user', 'UserManagementProfileSrtLocale'], value) }));
    };

    handleFirstNameChange = ({ target: { value } }) => {
        this.setState((state) => ({ data: state.data.mergeIn(['user', 'UserManagementProfileFirstName'], value) }));
    };

    handleLastNameChange = ({ target: { value } }) => {
        this.setState((state) => ({ data: state.data.mergeIn(['user', 'UserManagementProfileLastName'], value) }));
    };

    handleSubmit = () => {
        const { intl: { formatMessage } } = this.props;
        const { data, user } = this.state;
        const errors = validate({ businessEmail: data.getIn(['user', 'UserManagementProfileBusinessEmail']) }, this.constraints());

        if (errors && errors.businessEmail && errors.businessEmail.length) {
            this.setState({ data: data.setIn(['errors', 'businessEmail'], errors.businessEmail[0]) });
            return;
        }

        const formData = {
            profileMode: 'edit',
            applyAcctAssocs: 'false',
            profileBrand: data.getIn(['user', 'UserManagementProfileBrandNames'])
                && data.getIn(['user', 'UserManagementProfileBrandNames', '0']),
            username: data.getIn(['user', 'UserManagementUserLoginId']) || '',
            businessEmail: data.getIn(['user', 'UserManagementProfileBusinessEmail']) || '',
            locale: data.getIn(['user', 'UserManagementProfileSrtLocale']) || '',
            userStatus: data.getIn(['user', 'SecurityUserStatus']) || '',
            userAllowedVoidException: data.getIn(['user', 'SecurityUserAllowedVoidException']) || false,
            firstName: data.getIn(['user', 'UserManagementProfileFirstName']) || '',
            lastName: data.getIn(['user', 'UserManagementProfileLastName']) || '',
            suppliers: data.getIn(['user', 'UserManagementUserProfileSuppliers'])
                && data.getIn(['user', 'UserManagementUserProfileSuppliers']).split(','),
        };

        const nextState = { data: data.setIn(['errors', 'businessEmail'], '') };

        this.setState({ data: nextState.data.merge({ processingSubmit: true }) });
        gaEvent('myProfileSave');
        updateUserProfileApi(
            formData,
            (error) => {
                this.setState({
                    data: data.merge({
                        alertText: formatMessage(messages.errUpdateUserProfile),
                        processingSubmit: false,
                    }),
                });
                gaEvent('myProfileError', error);
            },
            () => {
                const alertText = (
                    data.getIn(['user', 'UserManagementProfileSrtLocale']) === data.getIn(['originalUser', 'UserManagementProfileSrtLocale'])
                        ? formatMessage(messages.okUpdateUserProfile) : formatMessage(messages.okUpdateUserProfileLocale)
                );

                this.setState({
                    data: data.merge({
                        alertText,
                        processingSubmit: false,
                        originalUser: user,
                    }),
                });
            },
        );
    };

    handleClose = () => {
        this.setState((state) => ({ data: state.data.merge({ user: state.originalUser }) }));
        this.props.handleClose();
    };

    handleSnackbarClose = () => {
        this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
    };

    render() {
        const { intl: { formatMessage } } = this.props;
        const actions = (
            <div className="row" style={styles.actionsContainer}>
                <div className="col-4 offset-3 col-sm-3 offset-sm-6">
                    <Button
                        variant="contained"
                        id="srtUserProfileClose"
                        onClick={this.handleClose}
                        fullWidth
                    >
                        {formatMessage(messages.btnClose)}
                    </Button>
                </div>
                <div className="col-4 col-sm-3">
                    {
                        this.state.data.get('processingSubmit')
                            ? (
                                <RefreshIndicator
                                    size={36}
                                    left={40}
                                    top={0}
                                    status="loading"
                                />
                            ) : (
                                <Button
                                    variant="contained"
                                    id="srtUserProfileSubmit"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    fullWidth
                                >
                                    {formatMessage(messages.btnSave)}
                                </Button>
                            )
                    }
                </div>
            </div>
        );
        const lastSuccessfulLogin = this.state.data.getIn(['user', 'SecurityLastSuccessfulLogin']);
        const formattedLastSuccfulLogin = lastSuccessfulLogin
            ? moment(lastSuccessfulLogin, getDateTimeFormat24H(this.props.intl, true))
                .utc(lastSuccessfulLogin)
                .utcOffset(moment().utcOffset())
                .format('MMMM DD, YYYY HH:mm:ss')
            : '--';

        const userStatus = this.state.data.getIn(['user', 'SecurityUserStatus']);
        const formattedUserStatus = userStatus || '--';

        const userAllowedVoidException = this.state.data.getIn(['user', 'SecurityUserAllowedVoidException']);
        const formattedUserAllowedVoidException = formatMessage(userAllowedVoidException === 'true' ? messages.lblOn : messages.lblOff);

        return (
            <div>
                <StyledDialog
                    id="srtUserProfile"
                    open={this.props.open}
                    onClose={this.handleClose}
                    disableEnforceFocus
                    maxWidth={false}
                >
                    <DialogTitle>{formatMessage(messages.userProfile)}</DialogTitle>
                    <DialogContent>
                        {
                            this.state.data.get('isFetching') ? (
                                <RefreshIndicator
                                    size={36}
                                    top={0}
                                    left={0}
                                    status="loading"
                                    style={styles.refresh}
                                />
                            ) : null
                        }
                        <div>
                            <div>
                                <TextField
                                    id="srtUserProfileLogin"
                                    disabled={this.state.data.getIn(['user', 'UserManagementUserLoginId']) !== ''}
                                    value={this.state.data.getIn(['user', 'UserManagementUserLoginId'])}
                                    label={formatMessage(messages.lblLoginId)}
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div>
                                <TextField
                                    id="srtUserLastLoginTime"
                                    label={formatMessage(messages.lblLastLoginTime)}
                                    value={formattedLastSuccfulLogin}
                                    fullWidth
                                    disabled
                                    variant="standard"
                                />
                            </div>
                            <div>
                                <TextField
                                    id="srtUserProfileStatus"
                                    label={formatMessage(messages.lblUserStatus)}
                                    value={formattedUserStatus}
                                    fullWidth
                                    disabled
                                    variant="standard"
                                />
                            </div>
                            <div>
                                <TextField
                                    id="srtUserProfileAllowedVoidException"
                                    label={formatMessage(messages.lblUserAllowedVoidException)}
                                    value={formattedUserAllowedVoidException}
                                    fullWidth
                                    disabled
                                    variant="standard"
                                />
                            </div>
                            <div>
                                <TextField
                                    id="srtUserProfileEmail"
                                    label={formatMessage(messages.lblEmail)}
                                    value={this.state.data.getIn(['user', 'UserManagementProfileBusinessEmail'])}
                                    fullWidth
                                    onChange={this.handleEmailChange}
                                    error={!!this.state.data.getIn(['errors', 'businessEmail'])}
                                    helperText={this.state.data.getIn(['errors', 'businessEmail'])}
                                    variant="standard"
                                />
                            </div>
                            <div>
                                <SelectLocale
                                    id="srtUserProfileLocale"
                                    value={this.state.data.getIn(['user', 'UserManagementProfileSrtLocale'])}
                                    onChange={this.handleLocaleChange}
                                />
                            </div>
                            <div>
                                <TextField
                                    id="srtUserProfileFirstName"
                                    label={formatMessage(messages.lblFirstName)}
                                    value={this.state.data.getIn(['user', 'UserManagementProfileFirstName'])}
                                    fullWidth
                                    onChange={this.handleFirstNameChange}
                                    variant="standard"
                                />
                            </div>
                            <div>
                                <TextField
                                    id="srtUserProfileLastName"
                                    label={formatMessage(messages.lblLastName)}
                                    value={this.state.data.getIn(['user', 'UserManagementProfileLastName'])}
                                    fullWidth
                                    onChange={this.handleLastNameChange}
                                    variant="standard"
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {(!this.state.data.get('isFetching')) ? actions : []}
                    </DialogActions>

                </StyledDialog>
                <ExtendedSnackbar
                    id="srtUserProfileSnackBar"
                    open={(this.state.data.get('alertText') !== '')}
                    message={this.state.data.get('alertText')}
                    onClose={this.handleSnackbarClose}
                />
            </div>
        );
    }
}

export { UserProfile as UserProfileAlias };

export default injectIntl(connect((state) => ({
    loginId: state.getIn(['settings', 'UserLoginId']),
}))(UserProfile));
