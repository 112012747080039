import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { injectIntl } from 'react-intl';

import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import messages from './messagesAddFeeDiscount';
import inlineStyles from './styles';
import { formatPrice } from '../../../utils';

const AddFeeDiscount = (props) => {
    const { formatMessage } = props.intl;
    const {
        id,
        disabled,
        feeTypes,
        currency,
        onChange,
        values,
        errors,
    } = props;

    const handleChangeField = (event) => {
        onChange({ [event.target.name]: event.target.value });
    };

    const handleChangeFeeType = ({ target: { value } }) => {
        onChange({ type: value });
    };

    const handleCheckField = (event, value) => {
        onChange({ feeDisc: value });
    };

    const currencySymbol = (currency) ? formatPrice(0, currency, props.intl).replace(/\d|\./g, '') : '';

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-11 offset-1">
                    <RadioGroup
                        name="feeDiscCheck"
                        onChange={handleCheckField}
                        value={values.feeDisc}
                    >
                        <FormControlLabel
                            id="srtAddDiscountCheck"
                            key="srtAddDiscountCheck"
                            value="disc"
                            control={<Radio />}
                            label={formatMessage(messages.lblDiscount)}
                            disabled={disabled}
                        />
                        <FormControlLabel
                            id="srtAddFeeCheck"
                            key="srtAddFeeCheck"
                            value="fee"
                            control={<Radio />}
                            label={formatMessage(messages.lblFee)}
                            disabled={disabled}
                        />
                    </RadioGroup>
                </div>
                <div className="col-12" id="srtAddFeeDiscountDropdownContainer">
                    <TextField
                        id="srtAddFeeDiscountType"
                        label={formatMessage(messages.lblFeeType)}
                        fullWidth
                        onChange={handleChangeFeeType}
                        error={!!errors.type}
                        helperText={errors.type}
                        value={values.type}
                        disabled={disabled}
                        select
                        variant="standard"
                    >
                        {feeTypes.map((type) => (
                            <MenuItem
                                id={`${id}_${type.code}`}
                                key={`srtFee_${type.code}`}
                                value={type.code}
                            >
                                {type.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="col-12">
                    <TextField
                        id="srtAddFeeDiscountAmount"
                        name="amount"
                        type="number"
                        label={formatMessage(messages.lblAmount, { currency: currencySymbol })}
                        fullWidth
                        onChange={handleChangeField}
                        error={!!errors.amount}
                        helperText={errors.amount}
                        value={values.amount}
                        disabled={disabled}
                        inputProps={{
                            step: 0.01,
                            min: 0,
                        }}
                        variant="standard"
                    />
                </div>
                <div className="col-12">
                    <TextField
                        id="srtAddFeeDiscountNotes"
                        name="notes"
                        label={formatMessage(messages.lblNotes)}
                        fullWidth
                        onChange={handleChangeField}
                        error={!!errors.notes}
                        helperText={errors.notes}
                        value={values.notes}
                        disabled={disabled}
                        multiline
                        minRows={4}
                        maxRows={10}
                        variant="standard"
                    />
                </div>
                <RefreshIndicator
                    size={36}
                    top={0}
                    left={0}
                    status={(props.disabled) ? 'loading' : 'hide'}
                    style={inlineStyles.refreshIndicator}
                />
            </div>
        </div>
    );
};

AddFeeDiscount.propTypes = {
    id: PropTypes.string.isRequired,
    intl: PropTypes.object,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    feeTypes: PropTypes.array,
    currency: PropTypes.string,
    values: PropTypes.object,
    errors: PropTypes.object,
};

export { AddFeeDiscount as AddFeeDiscountAlias };
export default injectIntl(AddFeeDiscount);
