import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import orderDetailsMessages from '../OrderDetails/messages';
import styles from './styles';

const RedButton = styled(Button)(() => ({
    ...styles.redButton,
}));

const ActionButtons = ({
    orderId,
    returnCoupons,
    authorizeRefund,
    handleOpenReturnCouponsDialog,
    handleOpenAuthorizeRefundDialog,
}) => (
    <>
        {returnCoupons && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Button
                    variant="contained"
                    id={`srtBookingReturnCoupons_${orderId}`}
                    color="primary"
                    fullWidth
                    onClick={() => {
                        handleOpenReturnCouponsDialog(orderId);
                    }}
                >
                    <FormattedMessage {...orderDetailsMessages.btnReturnCoupons} />
                </Button>
            </Grid>
        )}
        {authorizeRefund && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <RedButton
                    variant="contained"
                    id={`srtBookingAuthorizeRefund_${orderId}`}
                    fullWidth
                    onClick={() => { handleOpenAuthorizeRefundDialog(orderId); }}
                >
                    <FormattedMessage {...orderDetailsMessages.btnAuthorizeRefund} />
                </RedButton>
            </Grid>
        )}
    </>
);

ActionButtons.propTypes = {
    orderId: PropTypes.string.isRequired,
    returnCoupons: PropTypes.bool.isRequired,
    authorizeRefund: PropTypes.bool.isRequired,
    handleOpenReturnCouponsDialog: PropTypes.func.isRequired,
    handleOpenAuthorizeRefundDialog: PropTypes.func.isRequired,
};

export { ActionButtons as ActionButtonsAlias };

export default ActionButtons;
