import React from 'react';
import PropTypes from 'prop-types';

import { calculateReservationPrice } from '../../../../util';
import { formatPrice } from '../../../../../utils';
import Seats from './Seats';
import messages from '../messagesReservations';

const SeatForAll = ({
    numTravelSegments,
    index,
    strategy,
    fareOptionalPrices,
    paxLength,
    currency,
    intl,
    enableAll,
    handleLegAllSeatsToggle,
    handleTravelSegmentAllSeatsToggle,
    TravelSegmentID,
    reservable,
    prefPresentString,
}) => {
    const { formatMessage } = intl;

    if (strategy.legBasedReservation && numTravelSegments === index) {
        const price = calculateReservationPrice(strategy, fareOptionalPrices, -1, -1, paxLength);
        return (
            <Seats
                price={price}
                formattedPrice={formatPrice(price, currency, intl)}
                enabled={enableAll}
                handleLegAllSeatsToggle={handleLegAllSeatsToggle}
                handleTravelSegmentAllSeatsToggle={handleTravelSegmentAllSeatsToggle}
                formatMessage={formatMessage}
                travelSegmentID={TravelSegmentID}
                type="all"
                legBasedReservation
            />
        );
    }
    if (!strategy.legBasedReservation && reservable.reservable === 'OPTIONAL') {
        const price = calculateReservationPrice(strategy, fareOptionalPrices, -1, TravelSegmentID, paxLength, false);
        return (
            <Seats
                price={price}
                formattedPrice={formatPrice(price, currency, intl)}
                enabled={enableAll}
                handleLegAllSeatsToggle={handleLegAllSeatsToggle}
                handleTravelSegmentAllSeatsToggle={handleTravelSegmentAllSeatsToggle}
                formatMessage={formatMessage}
                travelSegmentID={TravelSegmentID}
                type="all"
            />
        );
    }
    if (!strategy.legBasedReservation && (reservable.reservable === 'MANDATORY' || reservable.reservable === 'INCLUDED')) {
        return (<span>{`${formatMessage(messages.lblIncluded)}${prefPresentString}`}</span>);
    }

    return null;
};

SeatForAll.propTypes = {
    numTravelSegments: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    strategy: PropTypes.object.isRequired,
    fareOptionalPrices: PropTypes.array,
    paxLength: PropTypes.number,
    currency: PropTypes.string,
    intl: PropTypes.object.isRequired,
    enableAll: PropTypes.bool,
    handleLegAllSeatsToggle: PropTypes.func,
    handleTravelSegmentAllSeatsToggle: PropTypes.func,
    TravelSegmentID: PropTypes.string,
    reservable: PropTypes.object.isRequired,
    prefPresentString: PropTypes.string,
};

export default SeatForAll;
