import {
    getStationData,
    bookingSetTdo,
    getSmartCardTdoStations,
    getStationTOD,
} from '../api';

export const getStationDataApi = (stationCode, error, success) => getStationData({ srtStationCode: stationCode }, error, success);

export const bookingSetTdoApi = (params, booking, order, error, success) => {
    const query = {
        srtRecLocator: booking.BookingRecordLoc || '',
        srtDistCode: booking.queryItems.srtDistCode,
        srtChannelCode: booking.queryItems.srtChannelCode,
        srtPOSCode: booking.queryItems.srtPOSCode,
        srtAgencyCode: booking.queryItems.srtAgencyCode,
        srtCompanyCode: booking.queryItems.srtCompanyCode,
        srtOrderId: order.BookingOrderID || '',
        srtSelectedTDO: params.tdoCode || '',
        srtSelectedTDODesc: params.tdoDesc || '',
        srtDeliveryPrice: params.tdoFee || '0.00',
        srtDeliveryPriceCurrency: params.tdoCurrency || '',
        srtTDOSMSToCode: params.tdoSmsCountry || '',
        srtTDOSMSTo: params.tdoSmsNumber || '',
        srtTDOEmailAddress: params.tdoPaperEmail || '',
        srtTDOShippingName: params.tdoMailName || '',
        srtTDOShippingAddress: params.tdoMailAddress.address1 || '',
        srtTDOShippingAddress2: params.tdoMailAddress.address2 || '',
        srtTDOShippingCity: params.tdoMailAddress.city || '',
        srtTDOShippingState: params.tdoMailAddress.stateOrProvince || '',
        srtTDOShippingZip: params.tdoMailAddress.postalCode || '',
        srtTDOTixDeliveryCountry: params.tdoMailAddress.countryCode || '',
        srtTDOPickupLocation: params.tdoPickUpLocation || '',
        srtTDOSmartCards: params.tdoSmartCards.size
            ? booking.BookingPassengers.map((item, index) => `${item.BookingPassengerID},${params.tdoSmartCards.get(index)}`).join(';')
            : '',
        srtOrigin: (order.BookingOrderLegDetails.BookingOrderLegs.length > 0
            ? order.BookingOrderLegDetails.BookingOrderLegs[0].BookingOrderDetailsLegTravelSegmentOrigStationName
            : order.BookingOrderDepartureLocation) || '',
        srtDestination: (order.BookingOrderLegDetails.BookingOrderLegs.length > 0
            ? order.BookingOrderLegDetails.BookingOrderLegs[0].BookingOrderDetailsLegTravelSegmentDestStationName
            : order.BookingOrderDestination) || '',
        srtAreStationCodes: order.BookingOrderLegDetails.BookingOrderLegs.length > 0 ? undefined : true,
    };
    return bookingSetTdo(
        query,
        error,
        success,
    );
};

export const getSmartCardTdoStationsApi = (params, error, success) => getSmartCardTdoStations(
    {
        srtSupplierName: params.suppliers,
        srtStationCode: params.stationCode,
    },
    error,
    success,
);

export const getStationTODApi = (params, error, success) => getStationTOD(
    {
        srtSupplierName: params.suppliers,
        srtStationCode: params.stationCode,
    },
    error,
    success,
);
