import React from 'react';
import PropTypes from 'prop-types';
// import Typography from '@mui/material/Typography';
import Typography from '@mui/material/Typography';
import styles from './singleValueStyles';

export default function SingleValue(props) {
    return (
        <Typography
            className={props.selectProps.classes.singleValue}
            {...props.innerProps}
            style={props.selectProps.isDisabled ? styles.disabled : undefined}
        >
            {props.children}
        </Typography>
    );
}

SingleValue.propTypes = {
    innerProps: PropTypes.object,
    selectProps: PropTypes.object,
    children: PropTypes.node,
};
