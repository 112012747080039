/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { fromJS, is } from 'immutable';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled } from '@mui/system';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import validate from 'validate.js';

import RefreshIndicator from '../../components/RefreshIndicator/RefreshIndicator';
import ExtendedSnackbar from '../../components/ExtendedSnackbar/ExtendedSnackbar';
import { getBasePath } from '../../utils';
import {
    TRIP_TYPE_SINGLE,
    TRIP_TYPE_SEASON,
    additionalRailcards,
} from '../util';

import {
    addDiscount,
    addPassenger,
    changeAccount,
    changeArriveStation,
    changeDepartDate,
    changeDepartMaxConnections,
    changeDepartStation,
    changeDiscountNumber,
    changeDiscountPassenger,
    changeDiscountProgram,
    changeOpenReturn,
    changePassenger,
    changeRailcardId,
    changeReturnDate,
    changeReturnMaxConnections,
    changeTripType,
    changeViaAvoidStation,
    changeViaAvoidType,
    clearArriveStation,
    clearDepartStation,
    clearEndDate,
    clearFields,
    clearPassengers,
    clearViaAvoidStation,
    dismissError,
    fetchUserData,
    removeDiscount,
    removePassenger,
    removeViaAvoid,
    swapStations,
    updateSearchQuery,
    changePromoCode,
    changeValidationCode,
    changeFareQualifier,
} from './actionsShoppingSearch';

import {
    submitSearch,
    dismissSearchError,
    clearSearchInitiated,
    submitExchangeSearch,
} from '../ShoppingResults/actionsShoppingResults';

import { addToRecentSearches } from './components/RecentSearches/actionsRecentSearches';
import SubNavbar from '../../components/SubNavbar/SubNavbar';
import RecentSearches from './components/RecentSearches/RecentSearches';
import SeasonView from './components/SeasonView';
import SingleReturnView from './components/SingleReturnView';
import DiscountCard from './components/DiscountCard';
import Passengers from './components/Passengers';

import messages from './messagesShoppingSearch';
import './shoppingSearch.css';
import constraints, { paxAgeConstraints, discountConstraints } from './constraints';
import inlineStyles, { withStylesTheme } from './styles';
import { gaEvent, gaSetAccount } from '../../utils/googleAnalytics';
import { getViaAvoidSuffix, viaAvoidDirection } from './components/constants';

const StyledTabs = styled(Tabs)(({ theme }) => ({
    backgroundColor: withStylesTheme(theme).tabsRoot.backgroundColor,
    '& .MuiTabs-indicator': {
        backgroundColor: withStylesTheme(theme).tabsIndicator.backgroundColor,
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    minWidth: withStylesTheme(theme).tabRoot.minWidth,
    color: withStylesTheme(theme).tabRoot.color,
    [theme.breakpoints.up('md')]: {
        minWidth: withStylesTheme(theme).tabRoot.minWidth,
    },
}));

class ShoppingSearch extends Component {
    static propTypes = {
        accountContexts: PropTypes.array,
        addOrderMode: PropTypes.bool,
        addToRecentSearches: PropTypes.func,
        arriveStationData: PropTypes.object,
        amendSeatReservationMode: PropTypes.bool,
        clearArriveStation: PropTypes.func,
        clearDepartStation: PropTypes.func,
        clearViaAvoidStation: PropTypes.func,
        configBasedAuth: PropTypes.bool,
        departStationData: PropTypes.object,
        exchangeableTicketableFareIds: PropTypes.array,
        exchangeOrderMode: PropTypes.bool,
        exchangeOrderTripType: PropTypes.number,
        intl: PropTypes.object,
        isSearchInitiated: PropTypes.bool,
        maxNumDiscountRows: PropTypes.number,
        onAddDiscount: PropTypes.func,
        onAddPassenger: PropTypes.func,
        onChangeAccount: PropTypes.func,
        onChangeArriveStation: PropTypes.func,
        onChangeDepartDate: PropTypes.func,
        onChangeDepartMaxConnections: PropTypes.func,
        onChangeDepartStation: PropTypes.func,
        onChangeDiscountNumber: PropTypes.func,
        onChangeDiscountPassenger: PropTypes.func,
        onChangeDiscountProgram: PropTypes.func,
        onChangeOpenReturn: PropTypes.func,
        onChangePassenger: PropTypes.func,
        onChangeRailcardId: PropTypes.func,
        onChangeFareQualifier: PropTypes.func,
        onChangeReturnDate: PropTypes.func,
        onChangeReturnMaxConnections: PropTypes.func,
        onChangeTripType: PropTypes.func,
        onChangeViaAvoidStation: PropTypes.func,
        onChangeViaAvoidType: PropTypes.func,
        onClearEndDate: PropTypes.func,
        onClearFields: PropTypes.func,
        onClearPassengers: PropTypes.func,
        onClearSearchInitiated: PropTypes.func,
        onDismissError: PropTypes.func,
        onDismissSearchError: PropTypes.func,
        onFetchUserData: PropTypes.func,
        onQuickSearchSwitch: PropTypes.func,
        onRemoveDiscount: PropTypes.func,
        onRemovePassenger: PropTypes.func,
        onRemoveViaAvoid: PropTypes.func,
        onSwapStations: PropTypes.func,
        onUpdateSearchQuery: PropTypes.func,
        orderAccount: PropTypes.object,
        orderId: PropTypes.string,
        orderPassengers: PropTypes.array,
        query: PropTypes.object,
        quickSearch: PropTypes.bool,
        quickSearchEnabled: PropTypes.bool,
        railCards: PropTypes.object,
        recordLocator: PropTypes.string,
        searchError: PropTypes.string,
        shouldCallRefreshUserState: PropTypes.bool,
        showSeasonPass: PropTypes.bool,
        submitExchangeSearch: PropTypes.func,
        submitSearch: PropTypes.func,
        history: PropTypes.object.isRequired,
        promoCode: PropTypes.string,
        validationCode: PropTypes.string,
        onChangePromoCode: PropTypes.func,
        onChangeValidationCode: PropTypes.func,
        selectedFareQualifiers: PropTypes.array,
        supplyChannelCode: PropTypes.string,
        isSeasonTrip: PropTypes.bool,
        forwardRef: PropTypes.func,
    };

    static defaultProps = {
        addOrderMode: false,
        amendSeatReservationMode: false,
        arriveStationData: {},
        departStationData: {},
        exchangeableTicketableFareIds: [],
        exchangeOrderMode: false,
        orderAccount: {},
        orderId: null,
        orderPassengers: [],
        quickSearch: false,
        quickSearchEnabled: false,
        recordLocator: null,
        selectedFareQualifiers: [],
        supplyChannelCode: '',
    };

    enabledTripTypes = [
        { name: 'single', value: 2 },
        { name: 'return', value: 0 },
    ];

    static getDerivedStateFromProps(props, state) {
        let newState = null;
        if (props.searchError && state.searchError !== props.searchError && !state.data.get('showAlert')) {
            newState = {
                data: state.data.merge({
                    showAlert: true,
                    searchError: props.searchError,
                }),
            };
        }
        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            data: fromJS({
                errors: {},
                showAlert: false,
                showViaAvoidOutbound: props.query.get('viaAvoidCodeOutbound') !== '',
                showViaAvoidInbound: props.query.get('viaAvoidCodeInbound') !== '',
                searchError: props.searchError,
            }),
        };
    }

    componentDidMount() {
        if (this.props.forwardedRef) {
            this.props.forwardedRef.current = this;
        }
        const {
            accountContexts,
            addOrderMode,
            arriveStationData,
            configBasedAuth,
            departStationData,
            exchangeOrderMode,
            exchangeOrderTripType,
            onAddPassenger,
            onChangeAccount,
            onChangeArriveStation,
            onChangeDepartStation,
            onChangePassenger,
            onChangeTripType,
            onClearSearchInitiated,
            onFetchUserData,
            onRemovePassenger,
            onUpdateSearchQuery,
            orderAccount,
            orderPassengers,
            query,
            quickSearch,
            quickSearchEnabled,
            shouldCallRefreshUserState,
            showSeasonPass,
        } = this.props;

        if (showSeasonPass && !addOrderMode) this.enabledTripTypes.push({ name: 'season', value: 4 });

        if (!quickSearch && !configBasedAuth && shouldCallRefreshUserState) onFetchUserData();

        if (quickSearchEnabled && quickSearch) onUpdateSearchQuery(query);

        onClearSearchInitiated();

        if (addOrderMode || exchangeOrderMode) {
            onChangeAccount(orderAccount.text, orderAccount.value);
            if (orderPassengers) {
                onRemovePassenger(0);
                orderPassengers.forEach((item, index) => {
                    onAddPassenger();
                    onChangePassenger(this.determinePaxAge(item.BookingPaxAgeAtTimeOfTravel), index);
                });
            }
            if (exchangeOrderMode) {
                this.enabledTripTypes = this.enabledTripTypes
                    .filter((tripType) => tripType.value === exchangeOrderTripType);
                onChangeTripType(this.enabledTripTypes[0].value);
                onChangeDepartStation(departStationData);
                onChangeArriveStation(arriveStationData);
            }
        } else if (configBasedAuth) {
            onChangeAccount(accountContexts[0].text, accountContexts[0].value);
        }
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        // comparison function to avoid circular reference issues
        const isEqual = (obj1, obj2) => {
            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);

            if (keys1.length !== keys2.length) {
                return false;
            }

            for (let key of keys1) {
                if (key === 'forwardedRef') continue;
                if (typeof obj1[key] === 'function' && typeof obj2[key] === 'function') {
                    continue;
                }
                if (obj1[key] !== obj2[key]) {
                    return false;
                }
            }

            return true;
        };

        return !isEqual(this.props, nextProps) || !is(this.state.data, nextState.data);
    };


    componentWillUnmount() {
        const {
            addOrderMode, exchangeOrderMode, orderPassengers, onRemovePassenger,
        } = this.props;
        if ((addOrderMode || exchangeOrderMode) && orderPassengers && orderPassengers.length > 1) {
            orderPassengers.forEach(() => { onRemovePassenger(0); });
        }
    }

    setMessage = (alert) => (
        `${alert}${alert.endsWith('.') ? '' : '.'}`
    );

    // used in Add order to determine paxAge
    determinePaxAge = (ageDesc) => (
        Number.isNaN(Number(ageDesc)) // eslint-disable-line no-nested-ternary
            ? (ageDesc.includes('65+') ? 65 : 30)
            : Number(ageDesc)
    );

    formatAlertMessages = (alerts) => alerts
        .filter((alert) => !validate.isEmpty(alert))
        .map((alert) => (Array.isArray(alert) ? alert.map((alertItem) => this.setMessage(alertItem)) : this.setMessage(alert)))
        .join(' ');

    handleAccountChange = (accountName, context) => {
        gaSetAccount(context);
        this.handleSnackBarClose();
        this.props.onChangeAccount(accountName, context);
    };

    handleClearFields = () => {
        gaEvent('fareSearchResetFields');
        this.setState((state) => ({ data: state.data.merge({ errors: {} }) }));
        this.props.onClearFields();
    };

    handleChangeTripType = (event, type) => {
        const types = {
            0: 'selectFareSearchReturn',
            2: 'selectFareSearchSingle',
            4: 'selectFareSearchSeason',
        };
        gaEvent(types[type]);

        if (type === 4) this.props.onClearPassengers();

        if (!this.props.isSearchInitiated) this.props.onChangeTripType(type);
    };

    handleSubmitSearch = () => {
        const {
            intl,
            intl: { formatMessage },
            addOrderMode,
            exchangeOrderMode,
            query,
            railCards,
            exchangeableTicketableFareIds,
            recordLocator,
            orderId,
            history,
            orderPassengers,
        } = this.props;
        if (addOrderMode) {
            gaEvent('addOrderFareSearch');
        }
        this.setState((state) => ({
            data: state.data.merge({ errors: {}, showAlert: false }),
        }));
        // eslint-disable-next-line max-len
        // const queryJS = { ...query.toJS(), ...{ promoCode: this.state.data.get('promoCode'), validationCode: this.state.data.get('validationCode') } };
        const queryJS = query.toJS();
        const errors = { ...validate(queryJS, constraints(formatMessage)) };
        const hasAdultPassenger = paxAgeConstraints(queryJS.paxAge);
        const discountErrors = discountConstraints(queryJS.discounts, formatMessage, railCards);
        if (!validate.isEmpty(errors) || (!hasAdultPassenger && !exchangeOrderMode) || !validate.isEmpty(discountErrors)) {
            if (!hasAdultPassenger) {
                errors.passengers = formatMessage(messages.errNoAdult);
            }
            if (discountErrors.length > 0) {
                errors.discounts = Array.from(discountErrors);
            }
            this.setState((state) => ({
                data: state.data.merge({ errors, showAlert: true }),
            }));
        } else if (exchangeOrderMode) {
            this.props.submitExchangeSearch(
                queryJS,
                intl,
                railCards,
                exchangeableTicketableFareIds,
                recordLocator,
                orderId,
                orderPassengers,
            );
            this.props.addToRecentSearches(query);
        } else if (this.props.quickSearchEnabled) {
            this.props.submitSearch(queryJS, intl, railCards).then(() => {
                this.props.onQuickSearchSwitch();
            });
            this.props.addToRecentSearches(query);
        } else {
            this.props.submitSearch(queryJS, intl, railCards).then((success) => {
                if (!addOrderMode && !exchangeOrderMode && success) {
                    this.props.addToRecentSearches(query);
                    history.push(`${getBasePath()}shopping/ShoppingResults`);
                }
            });
        }
    };

    handleSnackBarClose = () => {
        this.setState((state) => ({
            data: state.data.merge({ showAlert: false }),
        }), () => {
            this.props.onDismissError();
            this.props.onDismissSearchError();
        });
    };

    handleViaAvoidToggle = (direction) => {
        const {
            addOrderMode,
            exchangeOrderMode,
            onRemoveViaAvoid,
        } = this.props;
        const key = `showViaAvoid${getViaAvoidSuffix(direction)}`;
        const showViaAvoid = this.state.data.get(key);

        if (addOrderMode) {
            gaEvent(showViaAvoid ? 'addOrderRemoveViaStation' : 'addOrderAddViaStation');
        } else if (exchangeOrderMode) {
            gaEvent(showViaAvoid ? 'exchangeOrderRemoveViaAvoidStation' : 'exchangeOrderAddViaAvoidStation');
        } else {
            gaEvent(showViaAvoid ? 'fareSearchRemoveViaAvoidStation' : 'fareSearchAddViaAvoidStation');
        }

        if (showViaAvoid) {
            onRemoveViaAvoid(direction);
        }
        this.setState((state) => ({
            data: state.data.merge({ [key]: !showViaAvoid }),
        }));
    };

    handleSwapStations = (direction) => {
        const {
            addOrderMode,
            exchangeOrderMode,
            onSwapStations,
        } = this.props;

        let prefix = 'fareSearch';
        if (addOrderMode) {
            prefix = 'addOrder';
        } else if (exchangeOrderMode) {
            prefix = 'exchangeOrder';
        }
        gaEvent(`${prefix}SwapStations`);
        onSwapStations(direction);
    };

    handleAddDiscount = () => {
        const {
            addOrderMode,
            exchangeOrderMode,
            onAddDiscount,
        } = this.props;
        if (addOrderMode) {
            gaEvent('addOrderAddDiscountCard');
        } else if (exchangeOrderMode) {
            gaEvent('exchangeOrderAddDiscountCard');
        } else {
            gaEvent('fareSearchAddDiscountCard');
        }
        onAddDiscount();
    };

    handleRemoveDiscount = (index) => {
        const {
            addOrderMode,
            exchangeOrderMode,
            onRemoveDiscount,
        } = this.props;
        if (addOrderMode) {
            gaEvent('addOrderRemoveDiscountCard');
        } else if (exchangeOrderMode) {
            gaEvent('exchangeOrderRemoveDiscountCard');
        } else {
            gaEvent('fareSearchRemoveDiscountCard');
        }
        onRemoveDiscount(index);
    };

    handleChangeDepartDate = (...args) => {
        this.setState((state) => ({
            data: state.data.setIn(['errors', 'departDate'], undefined),
        }));
        this.props.onChangeDepartDate(...args);
    };

    handleChangeReturnDate = (...args) => {
        this.setState((state) => ({
            data: state.data.setIn(['errors', 'returnDate'], undefined),
        }));
        this.props.onChangeReturnDate(...args);
    };

    handleChangeOpenReturn = () => {
        this.setState((state) => ({
            data: state.data.set('showViaAvoidInbound', false),
        }));
        this.props.onChangeOpenReturn();
    };

    handleChangeField = (event) => {
        const name = event.target?.name;
        const value = event.target?.value;
        this.setState((state) => ({
            data: state.data.set(name, value),
        }));
        if (name === 'promoCode') {
            this.props.onChangePromoCode(value);
        }
        if (name === 'validationCode') {
            this.props.onChangeValidationCode(value);
        }
    };

    renderJourneyDetails = (searchQuery, quickSearch) => (
        <div className="col-12">
            <SingleReturnView
                disabled={searchQuery.disable}
                errors={searchQuery.errors}
                exchangeOrderMode={this.props.exchangeOrderMode}
                isSingle={searchQuery.tripType === TRIP_TYPE_SINGLE}
                journey={searchQuery.journeyDetails}
                onChangeArriveStation={this.props.onChangeArriveStation}
                onChangeDepartDate={this.handleChangeDepartDate}
                onChangeDepartMaxConnections={this.props.onChangeDepartMaxConnections}
                onChangeDepartStation={this.props.onChangeDepartStation}
                onChangeOpenReturn={this.handleChangeOpenReturn}
                onChangeReturnDate={this.handleChangeReturnDate}
                onChangeReturnMaxConnections={this.props.onChangeReturnMaxConnections}
                onChangeViaAvoidStation={this.props.onChangeViaAvoidStation}
                onChangeViaAvoidType={this.props.onChangeViaAvoidType}
                onClearArriveStation={this.props.clearArriveStation}
                onClearDepartStation={this.props.clearDepartStation}
                onClearViaAvoidStation={this.props.clearViaAvoidStation}
                onQuickSearchSwitch={this.props.onQuickSearchSwitch}
                onSwapStations={this.handleSwapStations}
                onViaAvoidToggle={this.handleViaAvoidToggle}
                quickSearch={quickSearch}
                showViaAvoid={searchQuery.showViaAvoid}
            />
        </div>
    );

    renderSeasonDetails = (searchQuery, quickSearch) => (
        <div className="col-12">
            <SeasonView
                journey={searchQuery.journeyDetails}
                errors={searchQuery.errors}
                onChangeDepartDate={this.handleChangeDepartDate}
                onChangeDepartStation={this.props.onChangeDepartStation}
                onChangeArriveStation={this.props.onChangeArriveStation}
                onSwapStations={this.handleSwapStations}
                onChangeReturnDate={this.handleChangeReturnDate}
                onClearEndDate={this.props.onClearEndDate}
                onClearDepartStation={this.props.clearDepartStation}
                onClearArriveStation={this.props.clearArriveStation}
                disabled={searchQuery.disable}
                quickSearch={quickSearch}
                onQuickSearchSwitch={this.props.onQuickSearchSwitch}
                exchangeOrderMode={this.props.exchangeOrderMode}
            />
        </div>
    );

    renderSearch = (searchQuery, quickSearch) => {
        const { intl: { formatMessage } } = this.props;
        return (
            <div>
                {!quickSearch && (
                    <SubNavbar
                        heading={formatMessage(messages.lblSearchAction)}
                        accounts={this.props.accountContexts ? this.props.accountContexts : searchQuery.availableAccounts}
                        onAccountChange={this.handleAccountChange}
                        selectedAccount={(this.props.addOrderMode || this.props.exchangeOrderMode) ? this.props.orderAccount : {
                            text: searchQuery.accountName,
                            value: searchQuery.context,
                        }}
                        disabled={this.props.addOrderMode || this.props.exchangeOrderMode || searchQuery.disable}
                        addOrderMode={this.props.addOrderMode}
                        configBasedAuth={this.props.configBasedAuth}
                        exchangeOrderMode={this.props.exchangeOrderMode}
                    >
                        {!this.props.addOrderMode && !this.props.exchangeOrderMode && (
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#e0e0e0',
                                    color: '#333',
                                    '&:hover': {
                                        backgroundColor: '#e0e0e0',
                                    },
                                    fontWeight: 500,
                                }}
                                id="srtResetFields"
                                styleName="subNavBarButtons"
                                onClick={this.handleClearFields}
                                disabled={searchQuery.disable}
                            >
                                <FormattedMessage {...messages.lblResetFields} />
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            id="srtSearchForFares"
                            styleName="subNavBarButtons"
                            color="primary"
                            onClick={this.handleSubmitSearch}
                            disabled={searchQuery.disable}
                        >
                            <FormattedMessage {...messages.lblSearchAction} />
                        </Button>
                    </SubNavbar>
                )}
                <div
                    className={(this.props.addOrderMode || this.props.exchangeOrderMode) ? '' : 'container'}
                    styleName="container-marginAdjust"
                >
                    <div className="row" styleName="contentContainer">
                        <div className="col-12" styleName="journeyContainer">
                            <div className="row">
                                <div className="col-9">
                                    <h2><FormattedMessage {...messages.lblJourneyDetails} /></h2>
                                </div>
                                <div className="col-3" styleName="recentSearches-verticalAlign">
                                    {!(this.props.addOrderMode || this.props.exchangeOrderMode)
                                        && <RecentSearches disable={searchQuery.disable} />}
                                </div>
                            </div>
                            <div>
                                <div styleName="journeySelector">
                                    <StyledTabs
                                        value={searchQuery.tripType}
                                        onChange={this.handleChangeTripType}
                                    >
                                        {this.enabledTripTypes.map((item, index) => (
                                            <StyledTab
                                                id={`journeyTab${index}`}
                                                key={`tab-${index}`}
                                                label={
                                                    <FormattedMessage {...messages[`lbl${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`]} />
                                                }
                                                value={item.value}
                                            />
                                        ))}
                                    </StyledTabs>
                                </div>
                                {searchQuery.tripType === TRIP_TYPE_SEASON
                                    ? this.renderSeasonDetails(searchQuery, false) : this.renderJourneyDetails(searchQuery, false)}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6" styleName="passengerColumn-paddingAdjust">
                            <div styleName="contentContainer passengerContainer">
                                <h2><FormattedMessage {...messages.lblPassengerAges} /></h2>
                                <Passengers
                                    paxAge={searchQuery.paxAge}
                                    disable={this.props.addOrderMode || this.props.exchangeOrderMode || searchQuery.disable}
                                    onAddPassenger={this.props.onAddPassenger}
                                    onRemovePassenger={this.props.onRemovePassenger}
                                    onChangePassenger={this.props.onChangePassenger}
                                    addOrderMode={this.props.addOrderMode}
                                    exchangeOrderMode={this.props.exchangeOrderMode}
                                    isSeason={searchQuery.tripType === TRIP_TYPE_SEASON}
                                />
                            </div>
                        </div>
                        {!this.props.amendSeatReservationMode
                            && (
                                <DiscountCard
                                    searchQuery={searchQuery}
                                    promoCodeLabel={formatMessage(messages.lblPromoCode)}
                                    onChangeField={this.handleChangeField}
                                    classes={this.props.classes}
                                    validationCodeLabel={formatMessage(messages.lblValidationCode)}
                                    onChangeDiscountNumber={this.props.onChangeDiscountNumber}
                                    onChangeDiscountPassenger={this.props.onChangeDiscountPassenger}
                                    onChangeDiscountProgram={this.props.onChangeDiscountProgram}
                                    onChangeRailcardId={this.props.onChangeRailcardId}
                                    onChangeFareQualifier={this.props.onChangeFareQualifier}
                                    handleAddDiscount={this.handleAddDiscount}
                                    handleRemoveDiscount={this.handleRemoveDiscount}
                                />
                            )}
                    </div>
                    {!(this.props.addOrderMode || this.props.exchangeOrderMode) && (
                        <div className="row" styleName="contentContainer srtSearchForFaresBtnContainer">
                            <div className="col-12">
                                <Button
                                    variant="contained"
                                    id="srtSearchForFares"
                                    styleName="srtSearchForFaresBtn"
                                    color="primary"
                                    onClick={this.handleSubmitSearch}
                                    disabled={searchQuery.disable}
                                >
                                    <FormattedMessage {...messages.lblSearchAction} />
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        const {
            query,
            maxNumDiscountRows,
            railCards,
            exchangeOrderMode,
            departStationData,
            arriveStationData,
            quickSearchEnabled,
            quickSearch,
            isSearchInitiated,
            searchError,
            selectedFareQualifiers,
            supplyChannelCode,
        } = this.props;

        const {
            errors,
            showAlert,
            showViaAvoidOutbound,
            showViaAvoidInbound,
        } = this.state.data.toJS();
        const quickSearchActive = (quickSearchEnabled && quickSearch);
        const disable = query.get('isFetchingUserState') || isSearchInitiated;
        const departStation = exchangeOrderMode ? departStationData
            : {
                name: query.get('txtDepart'),
                code: query.get('departCode'),
                supplier: query.get('departCodeSuppliers'),
                countryCode: query.get('departCountryCode'),
            };

        const arriveStation = exchangeOrderMode ? arriveStationData
            : {
                name: query.get('txtArrive'),
                code: query.get('arriveCode'),
                supplier: query.get('arriveCodeSuppliers'),
                countryCode: query.get('arriveCountryCode'),
            };

        // const foundDiscounts = [];
        let updateQuery = query;

        const populateExistingDiscounts = () => {
            const {
                intl,
            } = this.props;
            selectedFareQualifiers.forEach((selectedFareQualifier, index) => {
                Object.assign(railCards, additionalRailcards(intl));
                const selectedRailCards = fromJS(railCards).filter(
                    (railCard) => railCard.toJS().RailCardSupplier?.trim() === supplyChannelCode?.trim()
                        && railCard.toJS().RailCardDesc?.trim().toLowerCase()
                        === selectedFareQualifier.BookingFareQualifiersType?.trim().toLowerCase(),
                );
                const passenger = selectedFareQualifier?.BookingFareQualifiersPassengerRef?.trim();
                const passengerIndex = passenger ? parseInt(passenger[passenger.length - 1], 2) : index;
                selectedRailCards.forEach((selectedRailCard) => {
                    const railCardId = selectedFareQualifier?.BookingFareQualifiersValue ?? '';
                    const discountUpdated = updateQuery.getIn(['discounts', index])?.toJS()?.changed;
                    updateQuery = updateQuery.setIn(['discounts', index, 'passenger'], passengerIndex);
                    if (!discountUpdated) {
                        updateQuery = updateQuery.setIn(['discounts', index], fromJS({
                            discountProgram: selectedRailCard.toJS().RailCardProgram.trim(),
                            discountNumber: 1,
                            railcardId: railCardId,
                        }));
                    }
                });
            });
        };

        if (exchangeOrderMode) {
            populateExistingDiscounts();
        }

        const searchQuery = {
            // accounts
            availableAccounts: query.get('availableAccounts').toJS().filter((account) => account.fareSearchAllowed),
            accountName: query.get('accountName'),
            context: query.get('context'),
            // journey details
            journeyDetails: {
                departStation,
                openReturn: query.get('openReturn'),
                departDate: query.get('departDate'),
                timeOptionOutboundDate: query.get('timeOptionOutboundDate'),
                arriveStation,
                returnDate: query.get('returnDate'),
                timeOptionInboundDate: query.get('timeOptionInboundDate'),
                // via/avoid
                viaAvoidStation: {
                    [viaAvoidDirection.OUTBOUND]: {
                        name: query.get('txtViaAvoidOutbound'),
                        code: query.get('viaAvoidCodeOutbound'),
                    },
                    [viaAvoidDirection.INBOUND]: {
                        name: query.get('txtViaAvoidInbound'),
                        code: query.get('viaAvoidCodeInbound'),
                    },
                },
                viaAvoidType: {
                    [viaAvoidDirection.OUTBOUND]: query.get('viaAvoidTypeOutbound'),
                    [viaAvoidDirection.INBOUND]: query.get('viaAvoidTypeInbound'),
                },
                departMaxConnections: query.get('departMaxConnections'),
                returnMaxConnections: query.get('returnMaxConnections'),
            },
            tripType: query.get('tripType'),
            // via/avoid
            showViaAvoid: {
                [viaAvoidDirection.OUTBOUND]: showViaAvoidOutbound,
                [viaAvoidDirection.INBOUND]: showViaAvoidInbound,
            },
            // passengers
            paxAge: query.get('paxAge').toJS ? query.get('paxAge').toJS() : query.get('paxAge'),
            // discounts
            discounts: updateQuery.get('discounts').toJS(),
            railCards,
            maxNumDiscountRows,
            availableSuppliers: query.get('availableSuppliers').toJS(),
            // helpers and validation
            disable: (quickSearchActive || disable),
            alertMsg: this.formatAlertMessages([
                query.get('errorResponse') || searchError,
                (errors.accountName || ''),
                (errors.passengers || ''),
            ]),
            errors,
            promoCode: query.get('promoCode'),
            validationCode: query.get('validationCode'),
        };

        return (
            <div>
                {(quickSearchActive)
                    ? (
                        <div className="container" styleName="container-marginAdjust">
                            <div className="row" styleName="contentContainer quickSearchContainer">
                                <div className="col-12">
                                    <h2><FormattedMessage {...messages.lblJourneyDetails} /></h2>
                                </div>
                                {searchQuery.tripType === TRIP_TYPE_SEASON
                                    ? this.renderSeasonDetails(searchQuery, quickSearchActive)
                                    : this.renderJourneyDetails(searchQuery, quickSearchActive)}
                            </div>
                        </div>
                    )
                    : this.renderSearch(searchQuery, quickSearchEnabled)}
                <RefreshIndicator
                    size={40}
                    top={0}
                    left={0}
                    status={(disable) ? 'loading' : 'hide'}
                    style={inlineStyles.indicator}
                />
                <ExtendedSnackbar
                    id="srtShoppingSearchSnackBar"
                    open={showAlert && searchQuery.alertMsg !== ''}
                    message={searchQuery.alertMsg}
                    onClose={this.handleSnackBarClose}
                />
            </div>
        );
    }
}

export { ShoppingSearch as ShoppingSearchAlias };

const mapStateToProps = (state, ownProps) => ({
    query: (ownProps.quickSearch && ownProps.quickSearchEnabled)
        ? state.getIn(['shopping', 'recentSearches', 'searches']).first()
        : state.getIn(['shopping', 'query']),
    maxNumDiscountRows: Number(state.getIn(['settings', 'ws.feature.shopping.max_num_railcards'])),
    railCards: state.getIn(['settings', 'Railcards']).get('RailCardTypes').reduce((source, item) => (
        Object.assign(source, { [`${item.get('RailCardSupplier')}_${item.get('RailCardProgram')}`]: item.toJS() })
    ), {}),
    searchError: state.getIn(['shopping', 'results', 'errorResponse']),
    isSearchInitiated: state.getIn(['shopping', 'results', 'isSearchInitiated']),
    showSeasonPass: state.getIn(['settings', 'ws.feature.shopping.seasonpass_enabled']) === 'true',
    configBasedAuth: state.getIn(['settings', 'ws.interface.config_based.authentication_enabled']) === 'true',
    accountContexts: state.getIn(['settings', 'ws.interface.config_based.authentication_enabled']) === 'true'
        && state.getIn(['settings', 'ws.app.contexts'])
        ? state.getIn(['settings', 'ws.app.contexts']).split(',').map((item) => ({
            text: item,
            value: item,
        })) : null,
    shouldCallRefreshUserState: state.getIn(['shopping', 'query', 'shouldCallRefreshUserState']),
});

const mapDispatchToProps = {
    addToRecentSearches,
    clearArriveStation,
    clearDepartStation,
    clearViaAvoidStation,
    onAddDiscount: addDiscount,
    onAddPassenger: addPassenger,
    onChangeAccount: changeAccount,
    onChangeArriveStation: changeArriveStation,
    onChangeDepartDate: changeDepartDate,
    onChangeDepartMaxConnections: changeDepartMaxConnections,
    onChangeDepartStation: changeDepartStation,
    onChangeDiscountNumber: changeDiscountNumber,
    onChangeFareQualifier: changeFareQualifier,
    onChangeDiscountPassenger: changeDiscountPassenger,
    onChangeDiscountProgram: changeDiscountProgram,
    onChangeOpenReturn: changeOpenReturn,
    onChangePassenger: changePassenger,
    onChangeRailcardId: changeRailcardId,
    onChangeReturnDate: changeReturnDate,
    onChangeReturnMaxConnections: changeReturnMaxConnections,
    onChangeTripType: changeTripType,
    onChangeViaAvoidStation: changeViaAvoidStation,
    onChangeViaAvoidType: changeViaAvoidType,
    onClearEndDate: clearEndDate,
    onClearFields: clearFields,
    onClearPassengers: clearPassengers,
    onClearSearchInitiated: clearSearchInitiated,
    onDismissError: dismissError,
    onDismissSearchError: dismissSearchError,
    onFetchUserData: fetchUserData,
    onRemoveDiscount: removeDiscount,
    onRemovePassenger: removePassenger,
    onRemoveViaAvoid: removeViaAvoid,
    onSwapStations: swapStations,
    onUpdateSearchQuery: updateSearchQuery,
    submitExchangeSearch,
    submitSearch,
    onChangePromoCode: changePromoCode,
    onChangeValidationCode: changeValidationCode,
};

// need to use forwardRef: true so AddOrderDialog can capture component reference correctly
const WithHOCs = withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShoppingSearch)));
export default React.forwardRef((props, ref) => <WithHOCs {...props} forwardedRef={ref} />);
