import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';
import inlineStyles from './accountGroupStyles';
import messages from '../messagesManageAccounts';

function SeatMapDetails(
    {
        handleChangeSeatmapApiKey,
        srtAccountGroupSeatmapApiKey,
        intl: { formatMessage },
    },
) {
    return (
        <div>
            <ListSubheader style={inlineStyles.subHeader}>
                <FormattedMessage {...messages.lblSeatmapDetails} />
            </ListSubheader>
            <FormControl fullWidth>
                <TextField
                    id="srtAccountGroupSeatmapApiKey"
                    label={formatMessage(messages.lblSeatmapApiKey)}
                    fullWidth
                    value={srtAccountGroupSeatmapApiKey}
                    onChange={handleChangeSeatmapApiKey}
                    variant="standard"
                />
            </FormControl>
        </div>
    );
}

SeatMapDetails.propTypes = {
    intl: PropTypes.object,
    handleChangeSeatmapApiKey: PropTypes.func,
    srtAccountGroupSeatmapApiKey: PropTypes.string,
};

// This alias will be used to access bare component for unit testing
export { SeatMapDetails as SeatMapDetailsAlias };

export default injectIntl(SeatMapDetails);
