import { defineMessages } from 'react-intl';

export default defineMessages({
    lblTitle: {
        id: 'app.shopping.createBooking.addAddress.title',
        defaultMessage: 'Add Address',
    },
    lblMessage1: {
        id: 'app.shopping.createBooking.addAddress.message1',
        defaultMessage: 'In order to save an address to the booking all highlighted fields must be filled in.',
    },
    lblMessage2: {
        id: 'app.shopping.createBooking.addAddress.message2',
        defaultMessage: 'Please fill out the following form',
    },
    lblMessage3: {
        id: 'app.shopping.createBooking.addAddress.message3',
        defaultMessage: ', or select \'Continue without address\'',
    },
    btnContinueWithout: {
        id: 'app.shopping.createBooking.addAddress.continueWithout',
        defaultMessage: 'Continue Without Address',
    },
    btnContinueWith: {
        id: 'app.shopping.createBooking.addAddress.continueWith',
        defaultMessage: 'Continue With Address',
    },
});
