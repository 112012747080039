import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TextField from '@mui/material/TextField';

import messages from './messagesTicketDelivery';

function PaperTicket({
    intl: { formatMessage }, errors, onTdoPaperEmailChange, tdoPaperEmail,
}) {
    return (
        <TextField
            id="srtTdoPaperEmail"
            fullWidth
            placeholder={formatMessage(messages.lblTDOPaperEmail)}
            value={tdoPaperEmail}
            onChange={onTdoPaperEmailChange}
            error={!!errors.tdoPaperEmail}
            helperText={errors.tdoPaperEmail}
            variant="standard"
        />
    );
}

PaperTicket.propTypes = {
    intl: PropTypes.object,
    errors: PropTypes.object,
    onTdoPaperEmailChange: PropTypes.func,
    tdoPaperEmail: PropTypes.string,
};

export { PaperTicket as PaperTicketAlias };

export default injectIntl(PaperTicket);
