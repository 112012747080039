import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './tableView.css';
import messages from './messagesTableView';
import inlineStyles from './styles';

class TableDataCell extends Component {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        id: PropTypes.string,
        clickHandler: PropTypes.func,
        expandClickHandler: PropTypes.func,
        column: PropTypes.object,
        styles: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    getFormatedValue = (value) => (
        (value instanceof Date)
            ? this.props.intl.formatDate(value, { day: '2-digit', month: '2-digit', year: 'numeric' })
            : value
    );

    handleExpandClick = (columnId) => (event) => {
        const { expandClickHandler } = this.props;
        if (expandClickHandler) expandClickHandler(columnId);
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const {
            id, column, clickHandler, styles,
        } = this.props;
        const srtStyle = column.link && 'propLink';
        let output = null;

        if (column.value instanceof Array) {
            const outputItems = column.value.map((entry) => {
                const entryLink = entry.link || column.link;
                const content = entryLink
                    ? (
                        <Link
                            to={entryLink}
                            styleName={srtStyle}
                            key={entry.id}
                            onClick={() => (clickHandler ? clickHandler(entry, column.id) : '')}
                        >
                            {this.getFormatedValue(entry.value)}
                        </Link>
                    ) : <div styleName={srtStyle} key={entry.id}>{this.getFormatedValue(entry.value)}</div>;

                return column.value.length > 1
                    ? <MenuItem key={entry.id} style={inlineStyles.expandableList}>{content}</MenuItem> : content;
            });
            if (column.value.length > 1) {
                const expandTitle = `${column.value.length} ${this.props.intl.formatMessage(messages.lblItems)}`;
                const { anchorEl } = this.state;

                output = (
                    <div>
                        <Button
                            disableRipple
                            disableFocusRipple
                            sx={{ ...inlineStyles.flatButton }}
                            aria-owns={anchorEl !== null ? `fade-menu-${id}` : null}
                            aria-haspopup="true"
                            onClick={this.handleExpandClick(column.id)}
                        >
                            <span style={inlineStyles.expandable}>{expandTitle}</span>
                            <ExpandMoreIcon />
                        </Button>
                        <Menu
                            id={`fade-menu-${id}`}
                            anchorEl={anchorEl}
                            open={anchorEl !== null}
                            onClose={this.handleClose}
                        >
                            {outputItems}
                        </Menu>
                    </div>
                );
            } else {
                output = outputItems;
            }
        } else {
            const columnValue = this.getFormatedValue(column.value);
            if (column.clickOnly && clickHandler) {
                output = (
                    <div
                        key={column.id}
                        onClick={() => clickHandler(this.props.column)}
                        style={styles ? styles.propLink : {}}
                    >
                        {columnValue}
                    </div>
                );
            } else if (column.link) {
                output = (
                    <Link
                        key={column.id}
                        styleName={srtStyle}
                        to={column.link}
                        onClick={() => (clickHandler ? clickHandler(this.props.column) : '')}
                    >
                        {columnValue}
                    </Link>
                );
            } else {
                output = <span key={column.id} styleName={srtStyle}>{columnValue}</span>;
            }
        }

        return (
            <div>
                {' '}
                {output}
                {' '}
            </div>
        );
    }
}

// This alias will be used to access bare component for unit testing
export { TableDataCell as TableDataCellAlias };

export default injectIntl(TableDataCell);
