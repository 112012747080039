import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import messages from './messagesNotes';
import NotesDialog from './NotesDialog';
import BookingNotesTable from './BookingNotesTable';

import { addBookingNoteApi } from '../../apiBooking';

const BookingNotesDialog = (props) => {
    const {
        open, booking, onClose, dateFormat,
    } = props;
    const { formatMessage, formatDate } = props.intl;

    const title = formatMessage(messages.lblNotes);
    const constraints = {
        noteType: {
            presence: { message: formatMessage(messages.errNoteTypeEmpty), allowEmpty: false },
        },
        note: {
            presence: { message: formatMessage(messages.errNoteEmpty), allowEmpty: false },
            length: {
                maximum: 1500,
                tooLong: formatMessage(messages.errNoteTooLong),
            },
        },
    };
    const noteAddedAlert = formatMessage(messages.okNoteAdded);

    const filterNotes = (filterBy) => booking.BookingNotes.filter((note) => (
        formatDate(new Date(Number(note.BookingNoteDateTimeT)), dateFormat).toLowerCase().indexOf(filterBy) !== -1
            || note.BookingNoteAgent.toLowerCase().indexOf(filterBy) !== -1
            || note.BookingNoteType.toLowerCase().indexOf(filterBy) !== -1
            || note.BookingNoteMsg.toLowerCase().indexOf(filterBy) !== -1
    ));

    const addNote = (queryItems, data, error, success) => {
        addBookingNoteApi({
            queryItems,
            noteType: data.get('noteType'),
            noteData: data.get('note'),
        }, error, success);
    };

    return (
        <NotesDialog
            open={open}
            title={title}
            booking={booking}
            notes={booking.BookingNotes}
            constraints={constraints}
            filterNotes={filterNotes}
            addNote={addNote}
            noteAddedAlert={noteAddedAlert}
            onClose={onClose}
            NotesTableComponent={BookingNotesTable}
            notesTableId="srtBookingNotesTable"
            selectNoteType
            multiline
        />
    );
};

BookingNotesDialog.propTypes = {
    intl: PropTypes.object,
    open: PropTypes.bool,
    booking: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    dateFormat: PropTypes.object,
};

BookingNotesDialog.defaultProps = {
    dateFormat: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
    },
};

export { BookingNotesDialog as BookingNotesDialogAlias };

export default injectIntl(BookingNotesDialog);
