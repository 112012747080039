import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import styles from './styles';

const StyledTextDiv = styled('div')(() => ({
    ...styles.text,
}));
const StyledTooltip = styled(Tooltip)(() => ({
    ...styles.tooltip,
}));

const OverflowTooltipText = ({ children }) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const [open, setOpen] = useState(false);
    const textRef = useRef(null);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        const checkOverflow = () => {
            if (textRef.current) {
                const isOverflowing = textRef.current.scrollWidth > textRef.current.clientWidth;
                setIsOverflowed(isOverflowing);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, [children]);

    return (
        <StyledTooltip
            open={open && isOverflowed}
            title={isOverflowed ? children : ''}
            data-testid="tooltip"
        >
            <StyledTextDiv ref={textRef}>
                <span
                    onMouseEnter={handleTooltipOpen}
                    onMouseLeave={handleTooltipClose}
                    data-testid="tooltip-span"
                >
                    {children}
                </span>
            </StyledTextDiv>
        </StyledTooltip>
    );
};

OverflowTooltipText.propTypes = {
    children: PropTypes.node,
};

export { OverflowTooltipText as OverflowTooltipTextAlias };
export default OverflowTooltipText;
