import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import SmsOptions from '../../../components/TicketDelivery/SmsOptions';
import messages from './messagesSendToSms';
import inlineStyles from './styles';

const SendToSms = ({
    smsNumber,
    smsCountryCode,
    onChangeSms,
    smsCodeOptions,
}) => {
    const [data, setData] = useState({
        tdoSmsCountry: smsCountryCode,
        tdoSmsNumber: smsNumber,
        errors: {},
    });

    const handleSmsCountryChange = useCallback(({ target: { value } }) => {
        setData((prevState) => {
            const newData = {
                ...prevState,
                tdoSmsCountry: value,
            };
            onChangeSms({ smsCountryCode: value, smsNumber: newData.tdoSmsNumber });
            return newData;
        });
    }, [onChangeSms]);

    const handleSmsNumberChange = useCallback(({ target: { value } }) => {
        setData((prevState) => {
            const newData = {
                ...prevState,
                tdoSmsNumber: value,
            };
            onChangeSms({ smsCountryCode: newData.tdoSmsCountry, smsNumber: value });
            return newData;
        });
    }, [onChangeSms]);

    return (
        <div>
            <div style={inlineStyles.section}>
                <b><FormattedMessage {...messages.lblSmsWillBeSent} /></b>
                <div id="srtConfirmationSmsSingle">
                    <SmsOptions
                        smsCodeOptions={smsCodeOptions}
                        tdoSmsCountry={data.tdoSmsCountry}
                        tdoSmsNumber={data.tdoSmsNumber}
                        onSmsCountryChange={handleSmsCountryChange}
                        onSmsNumberChange={handleSmsNumberChange}
                        errors={data.errors}
                    />
                </div>
            </div>
        </div>
    );
};

SendToSms.propTypes = {
    intl: PropTypes.object,
    smsNumber: PropTypes.string,
    smsCountryCode: PropTypes.string,
    onChangeSms: PropTypes.func,
    smsCodeOptions: PropTypes.array,
};

export { SendToSms as SendToSmsAlias };

export default injectIntl(SendToSms);
