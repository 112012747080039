import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import FilterListIcon from '@mui/icons-material/FilterList';
import messages from './messagesShoppingResults';
import { getBasePath } from '../../utils';
import { useDeepCompareEffect } from '../../utils/hooks';
import { gaEvent } from '../../utils/googleAnalytics';
import SubNavbar from '../../components/SubNavbar/SubNavbar';
import FareLegsContainer from '../components/Fare/FareLegsContainer';
import FareLeg from '../components/Fare/FareLeg';
import './shoppingResults.css';
import { onFaresSelected as onFaresSelectedAction, clearChangeJourney } from './actionsShoppingResults';

import ShoppingSearch from '../ShoppingSearch/ShoppingSearch';
import SelectedFareSummary from './components/SelectedFareSummary/SelectedFareSummary';
import FilterList from './components/FilterList/FilterList';
import ExtendedSnackbar from '../../components/ExtendedSnackbar/ExtendedSnackbar';
import inlineStyles from './styles';
import {
    TRIP_TYPE_RETURN,
    TRIP_TYPE_SINGLE,
    TRIP_TYPE_SEASON,
    constructFareFilterFunction,
} from '../util';

const ShoppingResults = ({
    tripType,
    showSelectedFareSummary,
    addOrderMode = false,
    exchangeOrderMode = false,
    onFaresSelected,
    openReturn,
    history,
    legDepartDate,
    legReturnDate,
    selectedAccount,
    settings,
    faresSelected,
    intl: {
        formatDate,
        formatMessage,
    },
    results: shoppingData,
    isSearchInitiated,
    changeJourney,
    onClearChangeJourney,
}) => {
    const initialActiveFilters = {
        type: [],
        cabin: [],
        schedule: [],
    };

    const initialState = fromJS({
        openSelectedFareSummary: false,
        quickSearch: true,
        activeFilters: initialActiveFilters,
        openFilters: false,
        filterAnchorEl: null,
        showAlert: true,
    });

    const [data, setData] = React.useState(initialState);
    const [selectedFares, setSelectedFares] = React.useState([]);

    const handleCheckout = useCallback(() => {
        history.push(`${getBasePath()}shopping/CreateBooking`);
    }, [history]);

    useDeepCompareEffect(() => {
        const updates = {};
        if (isSearchInitiated) {
            updates.activeFilters = initialActiveFilters;
        } else if (showSelectedFareSummary && !addOrderMode && !exchangeOrderMode
            && faresSelected.size === 2 && tripType === TRIP_TYPE_RETURN) {
            updates.openSelectedFareSummary = true;
        }

        setData((state) => state.merge(updates));
        setSelectedFares(faresSelected.toJS());
    }, [faresSelected]);

    useEffect(() => {
        if (!changeJourney) {
            let sortedSelectedFares = selectedFares;
            if (selectedFares.length === 2 && tripType === TRIP_TYPE_RETURN && !openReturn) {
                sortedSelectedFares = selectedFares.sort((a, b) => a.legId - b.legId);
            }

            const showSummary = showSelectedFareSummary
                && !addOrderMode
                && !exchangeOrderMode
                && tripType === TRIP_TYPE_RETURN && !openReturn;
            if (!showSummary) {
                onFaresSelected(sortedSelectedFares);
            }

            if (showSummary) {
                setData((state) => state.set('openSelectedFareSummary', true));
                onFaresSelected(sortedSelectedFares);
            } else if (selectedFares.length === 1
                && ([TRIP_TYPE_SINGLE, TRIP_TYPE_SEASON].includes(tripType) || (tripType === TRIP_TYPE_RETURN && openReturn))
                && !addOrderMode && !exchangeOrderMode) {
                handleCheckout();
            } else if (selectedFares.length === 2 && !addOrderMode && !exchangeOrderMode) {
                handleCheckout();
            }
        }
    }, [selectedFares, addOrderMode, changeJourney, exchangeOrderMode, openReturn, showSelectedFareSummary, tripType, onFaresSelected, handleCheckout]);

    const handleFilterToggle = ({ currentTarget }) => {
        gaEvent('fareSearchInitiateFilterFares');
        setData((state) => state.merge({ openFilters: true, filterAnchorEl: currentTarget }));
    };

    const handleFilterCheck = (event, isChecked) => {
        gaEvent('fareSearchFilterFareSelection');
        const { name } = event.currentTarget;
        // categories[0] - filter category
        // categories[1] - filter value
        const categories = name.split('!');

        const activeFiltersCategory = data.getIn(['activeFilters', categories[0]]);
        if (isChecked) {
            setData((state) => state.setIn(['activeFilters', categories[0], activeFiltersCategory.size], categories[1]));
        } else {
            setData((state) => state.deleteIn(['activeFilters', categories[0], activeFiltersCategory.indexOf(categories[1])]));
        }
    };

    const handleFilterRequestClose = () => {
        setData((state) => state.merge({ openFilters: false, filterAnchorEl: null }));
    };

    const handleFareChangeSelection = (fare, legId) => {
        const newSelectedFares = selectedFares.filter((item) => item.legId !== legId);

        setSelectedFares(newSelectedFares);
        onFaresSelected(newSelectedFares);
    };

    const handleFareSelection = (leg, fare, legId, legSolutionId) => {
        // Filter out not selected leg solutions and fare prices
        const newLeg = leg;
        newLeg.ShoppingLegSolutions = newLeg.ShoppingLegSolutions.filter((item) => (item.ShoppingLegSolutionId === legSolutionId));
        newLeg.ShoppingLegSolutions[0].ShoppingLegFares = newLeg.ShoppingLegSolutions[0].ShoppingLegFares.filter(
            (item) => (item.ShoppingLegFarePriceId === fare.priceId),
        );

        setSelectedFares((state) => [...state, {
            leg: newLeg, fare, legId, legSolutionId,
        }]);

        onClearChangeJourney();
    };

    const handleClearSelection = () => {
        setData((state) => state.merge({ openSelectedFareSummary: false, selectedFares: [] }));
        onFaresSelected(fromJS([]));
    };

    const handleQuickSearchSwitch = () => {
        setData((state) => state.merge({ quickSearch: !state.get('quickSearch') }));
    };

    const handleSnackBarClose = () => {
        setData((state) => state.merge({ showAlert: false }));
    };

    // Fare compatibility
    let explicitFareCompats = null;
    // look for ShoppingLegFareLinkedCompatiblePrices in the JSON response (Renfe is the only supplier that
    // will generate these) and pass them to to the FareLegs
    if (shoppingData.get('ShoppingLegFareLinkedCompatiblePrices')) {
        explicitFareCompats = shoppingData.get('ShoppingLegFareLinkedCompatiblePrices');
    }

    const shoppingWarningMessage = shoppingData.get('ShoppingWarningMessage') || '';

    // determine if fare matching based on same cabin class is enabled (DBB only).
    let fareMatchingBasedOnSameCabinClass = false;
    if (settings.get('ws.feature.shopping.dbbahn_fare_combinability_workaround') === 'true') {
        if (shoppingData.get('ShoppingLegs')
            && shoppingData.get('ShoppingLegs').size > 1
            && shoppingData.getIn(['ShoppingLegs', 0, 'ShoppingLegOrigStationSuppliers']).indexOf('DBB') !== -1
            && shoppingData.getIn(['ShoppingLegs', 1, 'ShoppingLegDestStationSuppliers']).indexOf('DBB') !== -1) {
            fareMatchingBasedOnSameCabinClass = true;
        }
    }

    const departDateString = formatDate(new Date(legDepartDate), { day: '2-digit', month: '2-digit', year: 'numeric' });
    const returnDateString = legReturnDate
        ? formatDate(new Date(legReturnDate), { day: '2-digit', month: '2-digit', year: 'numeric' }) : null;

    return (
        <div styleName="resultsSpacing">
            <SubNavbar
                heading={!addOrderMode && !exchangeOrderMode ? formatMessage(messages.title) : ''}
                selectedAccount={selectedAccount}
                addOrderMode={addOrderMode}
                exchangeOrderMode={exchangeOrderMode}
            >
                {!addOrderMode && !exchangeOrderMode && (
                    <Button
                        variant="contained"
                        id="srtToggleFilters"
                        color="secondary"
                        style={inlineStyles.buttons}
                        styleName="toggleFiltersButton"
                        onClick={handleFilterToggle}
                    >
                        <FilterListIcon />
                        {formatMessage(messages.lblToggleFilters)}
                    </Button>
                )}
                {!addOrderMode && !exchangeOrderMode && (
                    <Button
                        variant="contained"
                        id="srtSearchAgain"
                        style={inlineStyles.buttons}
                        onClick={() => {
                            gaEvent('fareSearchSearchAgain');
                            history.goBack();
                        }}
                    >
                        {formatMessage(messages.lblSearchAgain)}
                    </Button>
                )}
            </SubNavbar>
            {(addOrderMode || exchangeOrderMode)
                || (
                    <ShoppingSearch
                        quickSearchEnabled
                        quickSearch={data.get('quickSearch')}
                        onQuickSearchSwitch={handleQuickSearchSwitch}
                    />
                )}
            {
                !(showSelectedFareSummary
                    && !addOrderMode
                    && !exchangeOrderMode
                    && faresSelected.size === 2)
                || (
                    <SelectedFareSummary
                        open={data.get('openSelectedFareSummary')}
                        onClearSelection={handleClearSelection}
                        onCheckout={handleCheckout}
                        selectedFares={faresSelected}
                        departDateString={departDateString}
                        returnDateString={returnDateString}
                        inboundMsg={formatMessage(messages.lblInbound)}
                        outboundMsg={formatMessage(messages.lblOutbound)}
                        accountDetails={shoppingData.get('ShoppingResultsAccountDetails')}
                    />
                )
            }
            <div className={addOrderMode || exchangeOrderMode ? '' : 'container'} id="srtSearchResults">
                <div className="row">
                    <div className="col-12">
                        {
                            (shoppingData.size > 0) ? shoppingData.get('ShoppingLegs').map((leg, index) => {
                                let legType = index === 0 ? formatMessage(messages.lblOutbound) : formatMessage(messages.lblInbound);
                                const selectedFare = selectedFares.find((item) => (
                                    item.legId === leg.get('ShoppingLegId')
                                ));
                                const otherFareSelected = selectedFares.find((item) => (
                                    item.legId !== leg.get('ShoppingLegId')
                                ));

                                if (tripType === TRIP_TYPE_SEASON) {
                                    legType = formatMessage(messages.lblSeasonPass);
                                }

                                return (
                                    <FareLegsContainer
                                        key={index}
                                        shoppingResultsMode
                                        legType={legType}
                                    >
                                        <FareLeg
                                            leg={leg.toJS()}
                                            legId={leg.get('ShoppingLegId')}
                                            booking={false}
                                            departDateString={departDateString}
                                            returnDateString={returnDateString}
                                            endDateString={(index === 0) ? returnDateString : ''}
                                            onChangeSelection={handleFareChangeSelection}
                                            onSelection={handleFareSelection}
                                            selectedFare={selectedFare}
                                            otherFareSelected={otherFareSelected}
                                            explicitFareCompats={explicitFareCompats}
                                            fareMatchingBasedOnSameCabinClass={fareMatchingBasedOnSameCabinClass}
                                            fareFilter={constructFareFilterFunction(data.get('activeFilters').toJS())}
                                            tripType={tripType}
                                            addOrderMode={addOrderMode}
                                            exchangeOrderMode={exchangeOrderMode}
                                        />
                                    </FareLegsContainer>
                                );
                            }).toJS() : null
                        }
                    </div>
                </div>
            </div>
            <Popover
                anchorEl={data.get('filterAnchorEl')}
                onClose={handleFilterRequestClose}
                open={data.get('openFilters')}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <FilterList
                    id="srtFareFilter"
                    onFilterCheck={handleFilterCheck}
                    activeFilters={data.get('activeFilters')}
                />
            </Popover>
            <ExtendedSnackbar
                id="srtShoppingResultSnackBar"
                open={data.get('showAlert') && shoppingWarningMessage !== ''}
                message={shoppingWarningMessage}
                onClose={handleSnackBarClose}
            />
        </div>
    );
};

ShoppingResults.propTypes = {
    intl: PropTypes.object.isRequired,
    settings: PropTypes.object,
    selectedAccount: PropTypes.string,
    legDepartDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf(Date),
    ]),
    legReturnDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf(Date),
    ]),
    tripType: PropTypes.number,
    openReturn: PropTypes.bool,
    results: PropTypes.object,
    onFaresSelected: PropTypes.func,
    faresSelected: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    showSelectedFareSummary: PropTypes.bool,
    addOrderMode: PropTypes.bool,
    exchangeOrderMode: PropTypes.bool,
    isSearchInitiated: PropTypes.bool,
    history: PropTypes.object,
    changeJourney: PropTypes.bool,
    onClearChangeJourney: PropTypes.func,
};

ShoppingResults.contextTypes = {
    router: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
    const queryStateLast = !ownProps.addOrderMode && !ownProps.exchangeOrderMode
        ? state.getIn(['shopping', 'recentSearches', 'searches']).first() : state.getIn(['shopping', 'query']);
    return {
        loginId: state.getIn(['settings', 'UserLoginId']),
        settings: state.get('settings'),
        selectedAccount: queryStateLast.get('accountName'),
        legDepartDate: queryStateLast.get('departDate'),
        legReturnDate: queryStateLast.get('returnDate'),
        tripType: queryStateLast.get('tripType'),
        openReturn: queryStateLast.get('openReturn'),
        results: state.getIn(['shopping', 'results', 'results']),
        faresSelected: state.getIn(['shopping', 'results', 'faresSelected']),
        showSelectedFareSummary: false,
        isSearchInitiated: state.getIn(['shopping', 'results', 'isSearchInitiated']),
        changeJourney: state.getIn(['shopping', 'results', 'changeJourney']),
    };
};

const mapDispatchToProps = (dispatch) => ({
    onFaresSelected: bindActionCreators(onFaresSelectedAction, dispatch),
    onClearChangeJourney: bindActionCreators(clearChangeJourney, dispatch),
});

// Use named export for unconnected component (for tests)
export { ShoppingResults as ShoppingResultsAlias };

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShoppingResults));
