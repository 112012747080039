import { defineMessages } from 'react-intl';

export default defineMessages({
    lblPhone: {
        id: 'app.common.contact.phone',
        defaultMessage: 'Phone',
    },
    lblEmail: {
        id: 'app.common.contact.email',
        defaultMessage: 'Email',
    },
});
