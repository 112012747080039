import { snow, darkGray2 } from '../../colors';

export default {
    subnavbar: {
        backgroundColor: snow,
    },
    stickybar: {
        transform: null,
        zIndex: 1100,
    },
    title: {
        whiteSpace: 'nowrap',
    },
    hintAccounts: {
        color: darkGray2,
    },
    list: {
        width: 330,
    },
    accountsInput: {
        minWidth: 256,
    },
    accountToolbar: {
        flexGrow: 1,
        justifyContent: 'center',
        display: 'flex',
    },
    reactSelectWrappedControl: {
        marginTop: 'unset !important',
    },
};
