import { darkGray } from '../../../colors';

export default {
    subheaderBorder: {
        marginBottom: 10,
        marginTop: -10,
        border: 0,
        height: '1px',
        background: darkGray,
        backgroundImage: 'linear-gradient(to right, #ccc, #333, #ccc)',
    },
    hr: {
        marginTop: 15,
        marginBottom: 15,
    },
    fareSearchAllowed: {
        marginTop: '40px',
    },
    accountRow: {
        minHeight: '600px',
    },
    accountName: {
        marginBottom: 6,
    },
    additionalOptionsButtons: {
        marginTop: 10,
    },
};
