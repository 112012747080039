import { fromJS } from 'immutable';
import { ADD_TO_RECENT_SEARCHES } from './actionsRecentSearches';

const MAX_RECENT_SEARCHES = 4;

const initialRecentSearchesState = fromJS({
    searches: [],
});

export default (state = initialRecentSearchesState, action) => {
    switch (action.type) {
        case ADD_TO_RECENT_SEARCHES:
            if (!action.query) return state;
            if (state.get('searches').size === MAX_RECENT_SEARCHES) {
                return state.set('searches', state.get('searches').pop().unshift(action.query));
            }
            if (!state.get('searches').size) return state.setIn(['searches', '0'], action.query);
            return state.set('searches', state.get('searches').unshift(action.query));
        default:
            return state;
    }
};
