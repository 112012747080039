import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './messagesFareItinerary';

const CarbonOffset = ({ value }) => (
    <div style={{ fontSize: 12 }}>
        <FormattedMessage {...messages.lblCarbonOffset} values={{ carbonOffset: value }} />
    </div>
);

CarbonOffset.propTypes = {
    value: PropTypes.string,
};

export default CarbonOffset;
