const styles = {
    container: {
        display: 'inline-flex',
        width: '100%',
    },
    depArr: {
        width: 140,
        paddingRight: 15,
        marginTop: 30,
    },
    datepicker: {
        flexGrow: 1,
    },
    datepickerInput: {
        textTransform: 'uppercase',
    },
};

export default styles;
