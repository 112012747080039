import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import SeasonSegmentRow from './SeasonSegmentRow';
import TicketableFareGroupTitle from '../../../components/TicketableFareGroupTitle/TicketableFareGroupTitle';
import FareRulesMatrix from '../../../Shopping/components/Fare/FareRulesMatrix';
import StationInformationDialog from '../../../components/StationInformation/StationInformationDialog';
import { gaEvent } from '../../../utils/googleAnalytics';

import styles from './styles';
import messages from './messages';
import { ORIGINS, closeFareRulesGA, viewFareRulesGA } from './constants';

const StyledTableCell = styled(TableCell)({
    ...styles.description,
});

const SeasonTable = ({
    fareRulesDetails,
    orderDetails,
    origin,
    selectedTicketableFareId,
}) => {
    const [fareRulesSeason, setFareRulesSeason] = useState(false);
    const [stationCode, setStationCode] = useState(null);

    const handleCloseFareRulesMatrix = () => {
        const event = closeFareRulesGA[origin];
        gaEvent(event);
        setFareRulesSeason(false);
    };

    const handleOpenFareRulesMatrix = (e) => {
        e.preventDefault();
        const event = viewFareRulesGA[origin];
        gaEvent(event);
        setFareRulesSeason(true);
    };

    const handleCloseStationInfoDialog = () => {
        setStationCode(null);
    };

    const handleOpenStationInfoDialog = (code) => (e) => {
        e.preventDefault();
        setStationCode(code);
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            origin === ORIGINS.EXCHANGE && (
                                <TableCell>
                                    <FormattedMessage {...messages.lblExchangeItem} />
                                </TableCell>
                            )
                        }
                        <TableCell>
                            <FormattedMessage {...messages.lblOrigin} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblDestination} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblStartDate} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblEndDate} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblCabinClass} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblFare} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblTotalPasses} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblUnusedPasses} />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage {...messages.lblPrice} />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <StyledTableCell
                            id="srtBookingSeasonTitle"
                            colSpan={origin === ORIGINS.EXCHANGE ? 10 : 9}
                        >
                            <TicketableFareGroupTitle ticketableFareGroup={orderDetails} />
                        </StyledTableCell>
                    </TableRow>
                    <SeasonSegmentRow
                        isExchange={origin === ORIGINS.EXCHANGE}
                        selectedTicketableFareId={selectedTicketableFareId}
                        origin={orderDetails?.departStationName}
                        origCode={orderDetails?.departStationCode}
                        destination={orderDetails?.arriveStationName}
                        destCode={orderDetails?.arriveStationCode}
                        startDate={orderDetails?.startDate}
                        endDate={orderDetails?.endDate}
                        cabinClass={orderDetails?.cabinClass}
                        fare={orderDetails?.fare.replace('_', ' ')}
                        onOpenFareRules={handleOpenFareRulesMatrix}
                        price={orderDetails?.farePrice}
                        numberOfPasses={orderDetails?.numberOfPasses}
                        unusedPasses={orderDetails?.unusedPasses}
                        handleOpenStationInfoDialog={handleOpenStationInfoDialog}
                    />
                </TableBody>
            </Table>
            <FareRulesMatrix
                open={fareRulesSeason}
                handleClose={handleCloseFareRulesMatrix}
                fareRulesDetails={fareRulesSeason ? fareRulesDetails : []}
                paxTitle={fareRulesSeason ? orderDetails.description : ''}
                isBookingPage={origin === ORIGINS.BOOKING}
                exchangeOrderMode={origin === ORIGINS.EXCHANGE}
                isCancelOrderPage={origin === ORIGINS.CANCEL}
                isVoidOrderPage={origin === ORIGINS.VOID}
            />
            <StationInformationDialog
                stationCode={stationCode}
                open={!!stationCode}
                onClose={handleCloseStationInfoDialog}
            />
        </>
    );
};

SeasonTable.propTypes = {
    fareRulesDetails: PropTypes.array,
    intl: PropTypes.object,
    orderDetails: PropTypes.object,
    origin: PropTypes.oneOf(Object.values(ORIGINS)),
    selectedTicketableFareId: PropTypes.string,
};

export { SeasonTable as SeasonTableAlias };

export default injectIntl(SeasonTable);
