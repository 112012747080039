import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import TableView from '../../../components/TableView/TableView';

import styles from './styles';
import messages from './messagesPassengerInformation';
import { bookingSearchApi } from '../../apiBooking';

class OtherBookings extends PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        lastActiveTab: PropTypes.string,
        onFetchingError: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            activeTab: this.props.lastActiveTab,
            searchResults: [],
        };
    }

    componentDidMount() {
        const {
            firstName, lastName, email, intl, onFetchingError,
        } = this.props;

        const searchData = {
            firstName,
            firstLastName: lastName,
            email,
            phone: '',
            departureDate: null,
            bookingLocator: '',
            supplierLocator: '',
            ticketLocator: '',
            referenceNumber: '',
            customerNumber: '',
        };

        return bookingSearchApi(
            {
                searchData,
                intl,
            },
            (response) => {
                onFetchingError({ message: response.errorResponse.message });
            },
            (response) => {
                this.setState({ searchResults: response.successResponse.data.BookingSearchResults });
            },
        );
    }

    getResultsValue = (value) => ((value === null) ? '' : value.trim());

    tableHeaders = () => {
        const { intl: { formatMessage } } = this.props;
        return [
            formatMessage(messages.lblBookingLocator),
            formatMessage(messages.lblDepartDate),
            formatMessage(messages.lblFrom),
            formatMessage(messages.lblOrderStatus),
        ];
    };

    transformResultsToTableViewFormat = (results) => (
        results.map((item) => [
            { // 'Booking Locator'
                id: `${item.BookingRecordLoc}`,
                value: item.BookingRecordLoc.trim(),
                link: '',
                clickOnly: true,
                displayFlag: item.BookingRecordLoc === this.state.activeTab,
                queryItems: {
                    srtAgencyCode: item.BookingAgencyCode,
                    srtChannelCode: item.BookingChannelCode,
                    srtCompanyCode: item.BookingCompanyCode,
                    srtDistCode: item.BookingDistributorCode,
                    srtPOSCode: item.BookingPOSCode,
                    srtRecLocator: item.BookingRecordLoc,
                },
            },
            { // 'Depart Date',
                id: `${item.BookingRecordLoc}_DepartDate`,
                value: item.BookingDepartureDate,
                link: '',
            },
            { // 'From',
                id: `${item.BookingRecordLoc}_From`,
                value: this.getResultsValue(item.BookingOrderDetailsLegTravelSegmentOrigStationName),
                link: '', // TODO replace with station information link
            },
            { // 'Order status',
                id: `${item.BookingRecordLoc}_OrderStatus`,
                value: this.getResultsValue(item.BookingOrderStatus),
                link: '',
            },
        ])
    );

    render() {
        const { searchResults } = this.state;
        const results = this.transformResultsToTableViewFormat(searchResults);

        return (
            <TableView
                id="srtBookingsSearchTable"
                tableHeaders={this.tableHeaders()}
                data={results}
                showFilter={false}
                fixedHeader
                isSortable
                responsiveWidth={200}
                wrapperHeight="100%"
                responsive
                style={styles.otherBookingsTable}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    lastActiveTab: state.getIn(['booking', 'lastActiveTab']),
});

export { OtherBookings as OtherBookingsAlias };
export default connect(mapStateToProps)(injectIntl(OtherBookings, styles, { allowMultiple: true }));
