export default {
    buttons: {
        margin: 10,
        textTransform: 'capitalize',
        fontSize: 15,
    },
    journeyDetails: {
        marginTop: 20,
        padding: '20px 0',
    },
};
