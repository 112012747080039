import { createTheme } from '@mui/material/styles';
import colors from './colors';

const palette = {
    primary: {
        light: colors.buttonGreen,
        main: colors.buttonGreen,
        contrastText: '#fff',
    },
    secondary: {
        main: colors.buttonBlue,
    },
    text: {
        primary: colors.textPrimary,
    },
    accent1Color: colors.buttonBlue, // used for dropdown text color for selected item and date time picker
};

export default createTheme({
    typography: {
        fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
        suppressWarning: true,
    },
    palette,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1600,
        },
    },
    components: {
        Button: {
            color: `${colors.buttonGrey} !important`,
        },
        appBar: {
            textColor: `${colors.textPrimary} !important`,
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 'unset !important',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontWeight: 'unset !important',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: '0px 12px !important',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: '-14 !important',
                },
            },
        },
        PrivateSwitchBase: {
            styleOverrides: {
                root: {
                    padding: '0 12px !important',
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                colorSecondary: {
                    '&$checked': {
                        color: `${colors.buttonGreen} !important`,
                        '& + $bar': {
                            backgroundColor: `${colors.buttonGreen} !important`,
                        },
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                colorSecondary: {
                    '&$checked': {
                        color: `${colors.buttonGreen} !important`,
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                colorSecondary: {
                    '&$checked': {
                        color: `${colors.buttonGreen} !important`,
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginTop: '14px !important',
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                container: {
                    '&$spacing-xs-8': {
                        margin: 'unset !important',
                    },
                },
                item: {
                    '&$grid-xs-4': {
                        padding: '8px !important',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                scroller: {
                    '&.MuiTabs-fixed': {
                        '& svg': {
                            color: 'white !important',
                            fill: 'white !important',
                        },
                    },
                },
            },
        },
    },
});
