import { darkGray, midGray4 } from '../../colors';

export default {
    expandableList: {
        fontSize: 13,
        lineHeight: '25px',
        minHeight: 25,
    },
    expandable: {
        fontSize: 13,
        fontWeight: 'normal',
        textTransform: 'none',
        paddingLeft: 0,
    },
    flatButton: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    refresh: {
        marginTop: '10%',
        marginLeft: '45%',
    },
    tooltip: {
        position: 'relative',
        left: 10,
        top: -10,
    },
    fixedTable: {
        tableLayout: 'fixed',
        overflowWrap: 'break-word',
    },
    sortLabel: {
        display: 'flex',
        whiteSpace: 'normal',
    },
    activeLabel: {
        color: midGray4,
        '&:hover': {
            color: darkGray,
        },
    },
    sortLabelIcon: {
        width: 14,
        height: 14,
        marginLeft: 1,
    },
    rowItem: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: 13,
    },
    rowItemBase: {
        whiteSpace: 'nowrap',
        height: 48,
        lineHeight: 1.5,
    },
    rowHeadItem: {
        cursor: 'pointer',
        fontSize: 12,
    },
};
