import { defineMessages } from 'react-intl';

export default defineMessages({
    userUpdated: {
        id: 'app.admin.editUser.userUpdated',
        defaultMessage: 'User profile updated.',
    },
    userUpdatedLocale: {
        id: 'app.admin.editUser.userUpdatedLocale',
        defaultMessage: 'User profile updated. Locale change will take effect on your next login.',
    },
    userDeleted: {
        id: 'app.admin.editUser.userDeleted',
        defaultMessage: 'User successfully deleted.',
    },
    errGetUser: {
        id: 'app.admin.editUser.errGetUser',
        defaultMessage: 'Could not get user details. Please try again!',
    },
    errEditUser: {
        id: 'app.admin.editUser.errEditUser',
        defaultMessage: 'Could not update user profile. Please try again!',
    },
    errDeleteUser: {
        id: 'app.admin.editUser.errDeleteUser',
        defaultMessage: 'Could not delete the user. Please try again!',
    },
});
