import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedDate, FormattedTime } from 'react-intl';
import { List } from 'immutable';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../../shoppingSearch.css';
import messages from '../../../messagesShopping';
import { updateSearchQuery } from '../../actionsShoppingSearch';
import inlineStyles from './styles';
import {
    TRIP_TYPE_RETURN,
    TRIP_TYPE_SINGLE,
    TRIP_TYPE_SEASON,
} from '../../../util';
import { gaEvent } from '../../../../utils/googleAnalytics';

const buildDateText = (aDate, separator = '@') => {
    // force to be a timestamp
    if (!aDate || typeof aDate !== 'number') return '';
    return (
        <span>
            <FormattedDate value={new Date(aDate)} />
            {' '}
            {separator}
            {' '}
            <FormattedTime value={new Date(aDate)} hour12={false} />
        </span>
    );
};

const determineTextByTripType = (tripType, returnDate, timeOptionInboundDate, options) => {
    let txt = '';
    switch (tripType) {
        case TRIP_TYPE_RETURN:
            txt = buildDateText(returnDate, timeOptionInboundDate ? options.byString : '@');
            break;
        case TRIP_TYPE_SINGLE:
            txt = options.single;
            break;
        case TRIP_TYPE_SEASON:
            txt = options.season;
            break;
        default:
            break;
    }
    return txt;
};

const StyledPopover = styled(Popover)(() => ({
    '& .MuiPaper-root': {
        ...inlineStyles.popover,
    },
}));

const RecentSearchesContent = injectIntl(({
    searches,
    handleSearchSelection,
    intl: {
        formatMessage,
    },
}) => {
    const byString = formatMessage(messages.lblBy);

    const menuItems = searches?.map((query, index) => {
        const tripType = query.get('tripType');
        return (
            <MenuItem
                id={`srtSearchEntry${index}`}
                onClick={() => handleSearchSelection(query)}
                key={index}
                style={inlineStyles.menuItem}
            >
                <div className="row" style={inlineStyles.row}>
                    <div>
                        <div className="col-6 text-left">{query.get('txtDepart')}</div>
                        <div className="col-6 text-right">
                            {
                                buildDateText(query.get('departDate'), query.get('timeOptionOutboundDate') ? byString : '@')
                            }
                        </div>
                    </div>
                    <div>
                        <div className="col-6 text-left">{query.get('txtArrive')}</div>
                        <div className="col-6 text-right">
                            {
                                determineTextByTripType(tripType, query.get('returnDate'), query.get('timeOptionInboundDate'), {
                                    single: formatMessage(messages.lblSingle),
                                    season: formatMessage(messages.lblSeason),
                                    byString,
                                })
                            }
                        </div>
                    </div>
                </div>
            </MenuItem>
        );
    }).toJS();

    return searches?.size
        ? menuItems
        : <p data-testid="noRecentSearches">{formatMessage(messages.msgNoRecentSearches)}</p>;
});

RecentSearchesContent.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func,
    }),
    searches: PropTypes.instanceOf(List),
    handleSearchSelection: PropTypes.func,
};

const RecentSearches = ({
    onSearchSelection,
    searches,
    disable,
    intl: {
        formatMessage,
    },
}) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);


    const handleClick = (event) => {
        // This prevents ghost click.
        event.preventDefault();
        gaEvent('fareSearchRecentSearches');

        setOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleRequestClose = () => {
        setOpen(false);
    };

    const handleSearchSelection = (query) => {
        gaEvent('fareSearchSelectRecent');
        onSearchSelection(query);
        handleRequestClose();
    };

    return (
        <div>
            <Button
                variant="text"
                id="srtRecentSearchButton"
                data-testid="srtRecentSearchButton"
                styleName="recentSearchButton"
                onClick={handleClick}
                disabled={disable}
            >
                {formatMessage(messages.lblRecentSearches)}
                <ExpandMoreIcon />
            </Button>
            <StyledPopover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                onClose={handleRequestClose}
            >
                <RecentSearchesContent
                    handleSearchSelection={handleSearchSelection}
                    searches={searches}
                />
            </StyledPopover>
        </div>
    );
};

RecentSearches.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func,
    }),
    searches: PropTypes.instanceOf(List),
    onSearchSelection: PropTypes.func,
    disable: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    searches: state.getIn(['shopping', 'recentSearches', 'searches']),
});

const mapDispatchToProps = (dispatch) => ({
    onSearchSelection: bindActionCreators(updateSearchQuery, dispatch),
});

// This alias will be used to access bare component for unit testing
export { RecentSearches as RecentSearchesAlias, RecentSearchesContent };

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl((memo(RecentSearches))));
