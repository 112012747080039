import { defineMessages } from 'react-intl';

export default defineMessages({
    search: {
        id: 'app.admin.manageUsers.search',
        defaultMessage: 'Search',
    },
    userId: {
        id: 'app.admin.manageUsers.userId',
        defaultMessage: 'User ID',
    },
    firstName: {
        id: 'app.admin.manageUsers.firstName',
        defaultMessage: 'First Name',
    },
    lastName: {
        id: 'app.admin.manageUsers.lastName',
        defaultMessage: 'Last Name',
    },
    emailAddress: {
        id: 'app.admin.manageUsers.emailAddress',
        defaultMessage: 'Email Address',
    },
    searchNoResults: {
        id: 'app.admin.manageUsers.searchNoResults',
        defaultMessage: 'Unable to find a matching User(s) - please check your search criteria and try again.',
    },
    userRole: {
        id: 'app.admin.userRole',
        defaultMessage: 'User Role',
    },
    accountGroup: {
        id: 'app.admin.accountGroup',
        defaultMessage: 'Account Group',
    },
    account: {
        id: 'app.admin.account',
        defaultMessage: 'Account',
    },
    noSearchCriteria: {
        id: 'app.admin.manageUsers.noSearchCriteria',
        defaultMessage: 'At least one search criteria needs to be specified.',
    },
    downloadUsersCSV: {
        id: 'app.admin.manageUsers.downloadUsersCsv',
        defaultMessage: 'Download All Users',
    },
});
