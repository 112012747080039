import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { fromJS } from 'immutable';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styles from './styles';
import messages from '../../messages';
import ReservationsDialog from '../../../../../../Shopping/CreateBooking/components/ReservationsDialog/ReservationsDialog';
import PreviewDialog from '../PreviewDialog/PreviewDialog';

const adaptDataForReservationsDialog = (trainJourneysData) => {
    const shoppingLegs = trainJourneysData.data.ShoppingLegs.map((leg) => leg.ShoppingLegSolutions.map((solution) => ({
        leg: {
            ...leg,
            ShoppingLegSolutions: [solution],
        },
        fare: {
            priceId: solution.ShoppingLegFares[0].ShoppingLegFarePriceId,
            isOverbooked: solution.ShoppingLegFares[0].isOverbooked,
            price: parseFloat(solution.ShoppingLegFares[0].ShoppingLegFareTotalPrice),
            optionalPrices: solution.ShoppingLegFares[0].OptionalPrices,
            currency: solution.ShoppingLegFares[0].ShoppingLegFareTotalPriceCurrency,
            linkedReturnPrices: [],
            seatPreferences: {
                reservable: solution.ShoppingLegFares[0].ShoppingLegFareSeatPreferenceData.ShoppingLegFareSeatPreferenceDataReservable,
                reservables: solution.ShoppingLegFares[0].ShoppingLegFareSeatPreferenceData.ShoppingLegFareSeatPreferenceDataReservables.map((r) => ({
                    linkedPasseger: r.LinkedPasseger,
                    linkedTravelSegment: r.LinkedTravelSegment,
                    reservable: r.Reservable,
                })),
                preferences: solution.ShoppingLegFares[0].ShoppingLegFareSeatPreferenceData.ShoppingLegFareSeatPreferences,
            },
            rules: solution.ShoppingLegFares[0].ShoppingLegFareRules,
            amenities: solution.ShoppingLegFares[0].ShoppingLegFareServicesAndAmenities,
            fareCodes: solution.ShoppingLegFares[0].ShoppingLegFareCodeInfo,
            fareRulesDetails: solution.ShoppingLegFares[0].ShoppingLegFareRulesDetails,
            discountApplied: solution.ShoppingLegFares[0].ShoppingLegFareDiscountApplied,
            campaignInfo: solution.ShoppingLegFares[0].ShoppingLegCampaignInfo,
        },
        legId: leg.ShoppingLegId,
        legSolutionId: solution.ShoppingLegSolutionId,
    }))).flat();

    const shoppingPassengers = trainJourneysData.data.ShoppingPassengers.map((passenger) => ({
        ...passenger,
        ShoppingPassengerRefId: passenger.ShoppingPassengerRefId,
    }));

    return { shoppingLegs, shoppingPassengers };
};

const TrainJourneySelector = ({
    journeys, trainJourneysData, ticketableFareGroups, confirmSeatsData, refreshBookingParams, closeAllDialogs,
}) => {
    const [openSeatSelectorDialog, setOpenSeatSelectorDialog] = useState(false);
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const [selectedSeats, setSelectedSeats] = useState({});
    const [selectedJourneyData, setSelectedJourneyData] = useState();

    const { shoppingLegs, shoppingPassengers } = adaptDataForReservationsDialog(trainJourneysData);

    const handleSeatReservationsChange = (e) => {
        setSelectedSeats(e);
        setOpenPreviewDialog(true);
    };

    const handleJourneySelectClick = (journeyId) => {
        const selectedJourney = shoppingLegs.find((journey) => journey.legSolutionId === journeyId);

        setSelectedJourneyData(selectedJourney);
        setOpenSeatSelectorDialog(true);
    };

    return (
        <div style={styles.root}>
            <Paper elevation={1}>
                <FormattedMessage {...messages.lblAmendSeatReservationsDialogOutbound} />
            </Paper>
            <div style={styles.journeysWrap}>
                {journeys.map((journey, index) => (
                    <Paper key={index}>
                        <div style={styles.journeyHeader}>
                            <div>
                                <span style={styles.journeyNumber}>
                                    #
                                    {index + 1}
                                </span>
                                <span style={styles.journeyInfo}>
                                    <FormattedMessage {...messages.lblAmendSeatReservationsDialogDuration} />
                                    :
                                    {' '}
                                    {journey.ShoppingLegSolutionDuration}
                                    {' '}
                                    <FormattedMessage {...messages.lblAmendSeatReservationsDialogChanges} />
                                    :
                                    {' '}
                                    {journey.ShoppingLegSolutionNumChanges}
                                </span>
                            </div>

                            <FormControlLabel
                                style={{ ...styles.toggleRoot, ...styles.toggleLabel }}
                                onChange={() => null}
                                control={<Switch style={styles.toggleIcon} />}
                                label="View Details"
                            />

                        </div>
                        <Grid container style={styles.journeyDetails}>
                            <Grid item xs={6} style={styles.stationColumn}>
                                <Typography style={styles.stationName}>{journey.ShoppingLegSolutionDepartStationName}</Typography>
                                <Typography style={styles.stationName}>{journey.ShoppingLegSolutionArriveStationName}</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ alignItems: 'flex-end', ...styles.stationColumn }}>
                                <Typography style={styles.timeText}>{journey.ShoppingLegSolutionDepartTime}</Typography>
                                <Typography style={styles.timeText}>{journey.ShoppingLegSolutionArriveTime}</Typography>
                            </Grid>
                        </Grid>
                        {journey.ShoppingLegSolutionMarketingCarriers && journey.ShoppingLegSolutionMarketingCarriers[0] && (
                            <img
                                src={`${process.env.API_HOST}${process.env.BASE_PATH_SUFFIX}${journey.ShoppingLegSolutionMarketingCarrierLogoPath}`}
                                alt={`${journey.ShoppingLegSolutionMarketingCarriers[0]} logo`}
                                style={styles.logo}
                            />
                        )}

                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={styles.selectButton}
                            onClick={() => handleJourneySelectClick(journey.ShoppingLegSolutionId)}
                        >
                            <FormattedMessage {...messages.lblAmendSeatReservationsDialogSelectThisJourney} />
                        </Button>
                    </Paper>
                ))}
            </div>
            <ReservationsDialog
                open={openSeatSelectorDialog}
                faresSelected={fromJS([selectedJourneyData])} // should choose 1
                seatReservation
                bikeReservations={[]}
                shoppingPassengers={fromJS(shoppingPassengers)}
                onChangeSeatReservations={handleSeatReservationsChange}
                onDiscardSeatReservations={() => { setOpenSeatSelectorDialog(false); }}
                selectedSeatReservations={selectedSeats}
                onChangeBikeReservations={() => null}
                onDiscardBikeReservations={() => null}
                selectedBikeReservations={{}}
            />
            <PreviewDialog
                open={openPreviewDialog}
                handleDialogClose={() => setOpenPreviewDialog(false)}
                ticketableFareGroups={ticketableFareGroups}
                selectedSeats={selectedSeats}
                confirmSeatsData={confirmSeatsData}
                refreshBookingParams={refreshBookingParams}
                closeAllDialogs={closeAllDialogs}
            />
        </div>
    );
};

TrainJourneySelector.propTypes = {
    journeys: PropTypes.array,
    trainJourneysData: PropTypes.object,
    ticketableFareGroups: PropTypes.array,
    confirmSeatsData: PropTypes.object,
    refreshBookingParams: PropTypes.any,
    closeAllDialogs: PropTypes.func,
};

export default TrainJourneySelector;
