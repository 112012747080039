import styled from 'styled-components';

export const Wrapper = styled.div`
    min-height: 400px;
`;

export const Container = styled.div`
    text-align: center;
`;

export const SeatMapWrapper = styled.div`
    margin-top: 30px;

    .seatmap-wrapper, .seatmap-legend  {
        box-sizing: content-box;
        margin: 0 auto;
    }
`;

export const Title = styled.div`
    margin-bottom: 20px;
    font-weight: 500;
    text-align: left;
`;
