import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import FeeInfoItem from './FeeInfoItem';
import messages from '../messagesManageAccounts';

function FeeInfo(props) {
    const { formatMessage } = props.intl;
    const labels = {
        lblFeeType: formatMessage(messages.lblFeeType),
        lblFeeValue: formatMessage(messages.lblFeeValue),
        lblFeeMinValue: formatMessage(messages.lblFeeMinValue),
    };
    const { errors } = props;
    return (
        <div id="FeesInformation" className="row" data-testid="FeeInfo">
            <div className="col-12">
                {props.fees.map((fee, index) => {
                    const error = (errors && errors.length) ? errors.find((searchItem) => searchItem.index === index) : {};

                    return (
                        <FeeInfoItem
                            key={index}
                            index={index}
                            handleChange={props.handleChangeFee}
                            type={fee.type}
                            amount={fee.amount}
                            minValue={fee.minValue}
                            labels={labels}
                            feeTypes={props.feeTypes}
                            errors={error || {}}
                        />
                    );
                })}
            </div>
        </div>
    );
}

FeeInfo.propTypes = {
    fees: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        amount: PropTypes.string,
        minValue: PropTypes.string,
    })),
    feeTypes: PropTypes.object.isRequired,
    handleChangeFee: PropTypes.func.isRequired,
    errors: PropTypes.array,
    intl: PropTypes.object,
};

// This alias will be used to access bare component for unit testing
export { FeeInfo as FeeInfoAlias };

export default injectIntl(FeeInfo);
