export default {
    refresh: {
        marginTop: '10%',
        marginLeft: '45%',
    },
    button: {
        marginTop: 5,
        marginBottom: 5,
    },
    dialogPaper: {
        overflowY: 'hidden',
    },
    dialogActionsRoot: {
        width: '100%',
    },
};
