import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import CustomInfoDialog from '../../../components/CustomInfoDialog/CustomInfoDialog';
import { gaEvent } from '../../../../utils/googleAnalytics';

import messages from './messages';
import styles from './styles';

const StyledDivContainer = styled('div')(() => ({
    ...styles.contentContainer,
}));

const StyledPaper = styled(Paper)(() => ({
    ...styles.paperContainer,
}));

const StyledTable = styled(Table)(() => ({
    ...styles.bookingTable,
}));

const StyledTableBody = styled(TableBody)(() => ({
    ...styles.tableWrapper,
}));

const StyledTableCell = styled(TableCell)(() => ({
    ...styles.bookingTableRow,
}));

function CustomInformation({
    booking,
    handleCloseCustomInfoDialog,
    handleOpenCustomInfoDialog,
    openCustomInfoDialog,
}) {
    return (
        <StyledDivContainer>
            <StyledPaper elevation={1}>
                <h2><FormattedMessage {...messages.lblCustomInformation} /></h2>
                <StyledTable>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <FormattedMessage {...messages.lblCustomInformationType} />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage {...messages.lblCustomInformationValue} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <StyledTableBody>
                        <TableRow>
                            <StyledTableCell>
                                <FormattedMessage {...messages.lblCustomInformationReferenceNumber} />
                            </StyledTableCell>
                            <StyledTableCell id="srtBookingCustomInfo_refNumber">
                                {booking.BookingExternalIdentification.BookingExternalIdReferenceNumber}
                            </StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell>
                                <FormattedMessage {...messages.lblCustomInformationPONumber} />
                            </StyledTableCell>
                            <StyledTableCell id="srtBookingCustomInfo_poNumber">
                                {booking.BookingExternalIdentification.BookingExternalIdPoNumber}
                            </StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell>
                                <FormattedMessage {...messages.lblCustomInformationCustomerNumber} />
                            </StyledTableCell>
                            <StyledTableCell id="srtBookingCustomInfo_customer">
                                {booking.BookingExternalIdentification.BookingExternalIdCustomerNumber}
                            </StyledTableCell>
                        </TableRow>
                        {booking.BookingCustomInformation.map((customInfo, index) => (
                            <TableRow key={`ci_${index}`}>
                                <TableCell id={`srtCustomInfoType_${index}`}>
                                    {customInfo.BookingCustomInformationType}
                                </TableCell>
                                <TableCell id={`srtCustomInfoValue_${index}`}>
                                    {customInfo.BookingCustomInformationValue}
                                </TableCell>
                            </TableRow>
                        ))}
                    </StyledTableBody>
                </StyledTable>
                <Button
                    variant="contained"
                    id="srtBookingEditCustomInformation"
                    color="secondary"
                    onClick={() => {
                        gaEvent('bookingCustomInformationOpen');
                        handleOpenCustomInfoDialog();
                    }}
                >
                    <FormattedMessage {...messages.btnCustomInformationEdit} />
                </Button>
                <CustomInfoDialog
                    booking={booking}
                    onCloseCustomInfoDialog={handleCloseCustomInfoDialog}
                    open={openCustomInfoDialog}
                />
            </StyledPaper>
        </StyledDivContainer>
    );
}

CustomInformation.propTypes = {
    booking: PropTypes.object.isRequired,
    handleCloseCustomInfoDialog: PropTypes.func.isRequired,
    handleOpenCustomInfoDialog: PropTypes.func.isRequired,
    openCustomInfoDialog: PropTypes.bool.isRequired,
};

export default CustomInformation;
