import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputComponent from './InputComponent';

export default function Control(props) {
    return (
        <TextField
            fullWidth={props.selectProps.fullWidth}
            InputLabelProps={{
                shrink: !!props.selectProps.placeholder || (props.selectProps.InputLabelProps && props.selectProps.InputLabelProps.shrink),
            }}
            variant={props.variant || 'standard'}
            disabled={props.selectProps.isDisabled}
            InputProps={{
                inputComponent: InputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    ref: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            error={props.selectProps.error}
            helperText={props.selectProps.helperText}
            label={props.selectProps.label}
            value=""
            style={props.selectProps.controlPropsStyle}
        />
    );
}

Control.propTypes = {
    innerRef: PropTypes.func,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object,
    children: PropTypes.node,
    variant: PropTypes.string,
};
