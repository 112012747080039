import { defineMessages } from 'react-intl';

export default defineMessages({
    lblBillingTotal: {
        id: 'app.shopping.manageBooking.billingSummary.billingTotal',
        defaultMessage: 'Total',
    },
    lblBillingBalanceDue: {
        id: 'app.shopping.manageBooking.billingSummary.billingBalanceDue',
        defaultMessage: 'Balance Due',
    },
    lblBillingDate: {
        id: 'app.shopping.manageBooking.billingSummary.billingDate',
        defaultMessage: 'Date/Time (UTC)',
    },
    lblBillingOrder: {
        id: 'app.shopping.manageBooking.billingSummary.billingOrder',
        defaultMessage: 'Order',
    },
    lblBillingPriceType: {
        id: 'app.shopping.manageBooking.billingSummary.billingPriceType',
        defaultMessage: 'Price Type',
    },
    lblBillingDescription: {
        id: 'app.shopping.manageBooking.billingSummary.billingDescription',
        defaultMessage: 'Description',
    },
    lblBillingRevenues: {
        id: 'app.shopping.manageBooking.billingSummary.billingRevenues',
        defaultMessage: 'Revenues',
    },
    lblBillingReceipts: {
        id: 'app.shopping.manageBooking.billingSummary.billingReceipts',
        defaultMessage: 'Receipts',
    },
    lblBillingBalance: {
        id: 'app.shopping.manageBooking.billingSummary.billingBalance',
        defaultMessage: 'Balance',
    },
    lblBillingSummary: {
        id: 'app.shopping.manageBooking.billingSummary.billingSummary',
        defaultMessage: 'Billing Summary',
    },
    btnAddFeeDiscount: {
        id: 'app.shopping.manageBooking.bookingSummary.addFeeDiscount',
        defaultMessage: 'Add Fee/Discount',
    },
    lblCharges: {
        id: 'app.shopping.manageBooking.billingSummary.charges',
        defaultMessage: 'Total Charges',
    },
    lblPayments: {
        id: 'app.shopping.manageBooking.billingSummary.payments',
        defaultMessage: 'Total Payments',
    },
    btnMakePayment: {
        id: 'app.shopping.manageBooking.bookingSummary.makePayment',
        defaultMessage: 'Make Payment',
    },
    btnIssueRefund: {
        id: 'app.shopping.manageBooking.bookingSummary.issueRefund',
        defaultMessage: 'Issue Refund',
    },
    lblTotalAmount: {
        id: 'app.shopping.manageBooking.billingSummary.totalAmount',
        defaultMessage: 'Total: {amount}',
    },
    lblAdditionalCharges: {
        id: 'app.shopping.manageBooking.billingSummary.additionalCharges',
        defaultMessage: 'Additional Charges',
    },
    lblItemized: {
        id: 'app.shopping.manageBooking.billingSummary.itemized',
        defaultMessage: 'Itemized',
    },
    lblOverview: {
        id: 'app.shopping.manageBooking.billingSummary.overview',
        defaultMessage: 'Overview',
    },
});
