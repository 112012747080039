export default {
    popover: {
        minWidth: 350,
        textAlign: 'center',
        padding: '10px 0',
    },
    menuItem: {
        lineHeight: '32px',
        padding: '5px 2px 5px 2px',
        height: 'auto',
    },
    row: {
        margin: 0,
    },
};
