import React from 'react';
import PropTypes from 'prop-types';

import './fare.css';
import { decodeHtml } from '../../../utils';
import Linkify from '../../../components/Linkify/Linkify';

const FareRules = ({ rules }) => (
    rules.map((rule, index) => (
        <div key={`rule_${index}`}>
            {rule.category && <div styleName="ruleCategory">{rule.category}</div>}
            {rule.descriptions.map((ruleDescription) => (
                <div styleName="breakWordRule" key={`rule_${ruleDescription}`}>
                    <Linkify>{decodeHtml(ruleDescription)}</Linkify>
                </div>

            ))}
        </div>
    ))
);

FareRules.propTypes = {
    rules: PropTypes.array,
};

export default FareRules;
