import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import styles from './BookingView/styles';

const ContentContainerDiv = styled('div')({
    ...styles.contentContainer,
});

const PaperContainer = styled(Paper)({
    ...styles.paperContainer,
});

const TitleContainerDiv = styled('div')({
    ...styles.titleContainer,
});

const TitleDiv = styled('div')({
    ...styles.title,
});

const ErrorFallback = ({
    messageComponent,
    error,
}) => (
    <ContentContainerDiv>
        <PaperContainer elevation={1}>
            <TitleContainerDiv role="alert">
                <TitleDiv className="col-auto mr-auto">
                    <h2>{messageComponent}</h2>
                    <h4 style={{ color: 'red' }}>Internal Application Error: Contact SilverAgent Service Operations</h4>
                    <pre>{error.message}</pre>
                </TitleDiv>
            </TitleContainerDiv>
        </PaperContainer>
    </ContentContainerDiv>
);

ErrorFallback.propTypes = {
    messageComponent: PropTypes.object,
    error: PropTypes.object,
};

export { ErrorFallback as ErrorFallbackAlias };

export default injectIntl(ErrorFallback);
