import { defineMessages } from 'react-intl';

export default defineMessages({
    lblTitle: {
        id: 'app.booking.exchangeOrder.title',
        defaultMessage: 'Exchange Order',
    },
    lblExchangeItem: {
        id: 'app.booking.exchangeOrder.exchangeItem',
        defaultMessage: 'Exchange Item?',
    },
    lblLeg: {
        id: 'app.booking.exchangeOrder.leg',
        defaultMessage: 'Leg',
    },
    lblOrigin: {
        id: 'app.booking.exchangeOrder.origin',
        defaultMessage: 'Origin',
    },
    lblDestination: {
        id: 'app.booking.exchangeOrder.destination',
        defaultMessage: 'Destination',
    },
    lblSeasonStartDate: {
        id: 'app.booking.exchangeOrder.seasonStartDate',
        defaultMessage: 'Season Start Date',
    },
    lblSeasonEndDate: {
        id: 'app.booking.exchangeOrder.seasonEndDate',
        defaultMessage: 'Season End Date',
    },
    lblDepartStation: {
        id: 'app.booking.exchangeOrder.departStation',
        defaultMessage: 'Depart Station',
    },
    lblDepartDate: {
        id: 'app.booking.exchangeOrder.departDate',
        defaultMessage: 'Depart Date',
    },
    lblArriveStation: {
        id: 'app.booking.exchangeOrder.arriveStation',
        defaultMessage: 'Arrive Station',
    },
    lblArriveDate: {
        id: 'app.booking.exchangeOrder.arriveDate',
        defaultMessage: 'Arrive Date',
    },
    lblPrice: {
        id: 'app.booking.exchangeOrder.price',
        defaultMessage: 'Price',
    },
    btnClose: {
        id: 'app.booking.exchangeOrder.close',
        defaultMessage: 'Close',
    },
    btnRequestExchange: {
        id: 'app.booking.exchangeOrder.requestExchange',
        defaultMessage: 'Request Exchange',
    },
    btnSearch: {
        id: 'app.booking.exchangeOrder.search',
        defaultMessage: 'Search for Fares',
    },
    errExchangeSummary: {
        id: 'app.booking.exchangeOrder.errExchangeSummary',
        defaultMessage: 'Could not load exchange summary. Please try again.',
    },
    btnExchangeOrder: {
        id: 'app.booking.exchangeOrder.exchangeOrder',
        defaultMessage: 'Exchange Order',
    },
    lblConfirmDialogTitle: {
        id: 'app.booking.exchangeOrder.confirmDialog.title',
        defaultMessage: 'Exchange Order',
    },
    lblConfirmDialogMessage: {
        id: 'app.booking.exchangeOrder.confirmDialog.message',
        defaultMessage: 'Continuing will cancel the existing ticket and replace it with a new ticket.',
    },
    btnConfirmDialogYes: {
        id: 'app.booking.exchangeOrder.confirmDialog.yes',
        defaultMessage: 'Continue',
    },
    btnConfirmDialogNo: {
        id: 'app.booking.exchangeOrder.confirmDialog.no',
        defaultMessage: 'Cancel',
    },
    lblExchangedHeader: {
        id: 'app.booking.exchangeOrder.exchangedHeader',
        defaultMessage: 'Exchanged Products',
    },
    lblCancelledTFFirst: {
        id: 'app.booking.exchangeOrder.cancelledTFFirst',
        defaultMessage: 'The following will be cancelled as the result of this exchange:',
    },
    lblCancelledTFLast: {
        id: 'app.booking.exchangeOrder.cancelledTFLast',
        defaultMessage: 'If desired, a replacement ticket can be added now.',
    },
    lblCancelledTFFor: {
        id: 'app.booking.exchangeOrder.cancelledTFFor',
        defaultMessage: '{fare} for {name}',
    },
    lblInstructionsPartDisabled: {
        id: 'app.booking.exchangeOrder.instructionsPartDisabled',
        defaultMessage: 'Note: one or more tickets on this order cannot be exchanged.',
    },
    lblPassengers: {
        id: 'app.booking.exchangeOrder.passengers',
        defaultMessage: 'Passengers',
    },
});
