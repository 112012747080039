import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import LinearProgress from '@mui/material/LinearProgress';

import messages from './messagesCreateUser';
import { getUserProfileApi, addUserProfileApi } from '../apiAdmin';
import User from '../components/User';
import ExtendedSnackbar from '../../components/ExtendedSnackbar/ExtendedSnackbar';
import { gaEvent } from '../../utils/googleAnalytics';

const CreateUser = ({
    intl: { formatMessage },
    loginId,
    suppliers,
}) => {
    const [isFetching, setIsFetching] = useState(false);
    const [processingAction, setProcessingAction] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [user, setUser] = useState({});

    useEffect(() => {
        const transformUserData = (data) => {
            const userData = {};

            if (data.UserManagementUserLoginId) {
                userData.userLoginId = data.UserManagementUserLoginId;
            }

            if (data.UserManagementProfileRoleInfo) {
                if (data.UserManagementProfileRoleInfo.UserManagementProfileRoleInfoAllDescendantRoles) {
                    userData.userRoles = data.UserManagementProfileRoleInfo.UserManagementProfileRoleInfoAllDescendantRoles;
                }

                // We are assigning the first role from available descendant roles as default
                if (data.UserManagementProfileRoleInfo.UserManagementProfileRoleInfoDirectDescendantRoles) {
                    [userData.role] = data.UserManagementProfileRoleInfo.UserManagementProfileRoleInfoDirectDescendantRoles;
                }
            }

            // We are assigning default locale from the parent user
            if (data.UserManagementProfileSrtLocale) {
                userData.locale = data.UserManagementProfileSrtLocale;
            }

            // We are assigning groups from the parent user
            if (data.UserManagementAssocAccountGroups) {
                userData.assocAccGroups = data.UserManagementAssocAccountGroups;
            }

            // We are assigning accounts from the parent user
            if (data.UserManagementAssocAccounts) {
                userData.assocAccounts = data.UserManagementAssocAccounts;
            }

            if (data.UserManagementUserProfileSuppliers) {
                userData.suppliers = data.UserManagementUserProfileSuppliers.split(',');
            } else if (suppliers) {
                userData.suppliers = suppliers.toJS().map((item) => item.value);
            }

            if (data.UserManagementUserProfileTnCsDate) {
                userData.acceptTNC = data.UserManagementUserProfileTnCsDate;
            }

            if (data.UserManagementUserProfileCNRef) {
                userData.certFileName = data.UserManagementUserProfileCNRef;
            }

            if (data.AccountManagementAllAccounts) {
                userData.allAccounts = data.AccountManagementAllAccounts;
            }

            if (data.AccountManagementAllAccountsGroup) {
                userData.allAccountsGroup = data.AccountManagementAllAccountsGroup;
            }

            return userData;
        };
        setIsFetching(true);
        getUserProfileApi(
            loginId,
            (response) => {
                setAlertText(response.errorResponse.message);
                setIsFetching(false);
            },
            (response) => {
                setAlertText('');
                setUser(transformUserData(response.successResponse.data));
                setIsFetching(false);
            },
        );
    }, [loginId, suppliers]);

    const handleSnackbarClose = () => {
        setAlertText('');
    };

    const handleCancel = () => {
        gaEvent('adminCreateUserCancel'); // Current cancel button on create user doesn't do anything. Leaving it blank for now.
    };

    const handleSubmit = (formData) => {
        gaEvent('adminCreateUserSave');

        setProcessingAction(true);
        setUser(formData);

        addUserProfileApi(
            formData.toJS(),
            (response) => {
                setAlertText(response.errorResponse.message);
                setProcessingAction(false);
            },
            (response) => {
                const alertTextValue = formatMessage(messages[response.successResponse ? 'userCreated' : 'errCreateUser']);
                setAlertText(alertTextValue);
                setProcessingAction(false);
            },
        );
    };

    return (
        <div className="container-fluid">
            <div>
                {isFetching && <LinearProgress data-testid="linear-progress" />}
            </div>
            <div className="row" data-testid="row">
                <div className="col-12" data-testid="col">
                    {
                        isFetching ? null
                            : (
                                <User
                                    addMode
                                    suppliers={suppliers}
                                    user={user}
                                    processingAction={processingAction}
                                    handleCancel={handleCancel}
                                    handleSubmit={handleSubmit}
                                />
                            )
                    }
                    <ExtendedSnackbar
                        id="create-user-snackbar"
                        open={(alertText !== '')}
                        message={alertText}
                        onClose={handleSnackbarClose}
                    />
                </div>
            </div>
        </div>
    );
};

CreateUser.propTypes = {
    intl: PropTypes.object.isRequired,
    loginId: PropTypes.string.isRequired,
    suppliers: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    loginId: state.getIn(['settings', 'UserLoginId']),
    suppliers: state.getIn(['settings', 'SupplierList', 'supplyChannelList'])?.map((item) => (fromJS({
        value: item.get('0'),
        label: item.get('1'),
    }))),
});

// This alias will be used to access bare component for unit testing
export { CreateUser as CreateUserAlias };

export default connect(mapStateToProps, null)(injectIntl(CreateUser));
