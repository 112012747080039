export default {
    listItem: {
        padding: 0,
    },
    icon: {
        marginRight: 10,
        position: 'absolute',
        right: 0,
        height: 48,
    },
    innerList: {
        marginLeft: 20,
    },
    sidebar: {
        padding: 0,
    },
    link: {
        display: 'block',
        fontSize: 14,
        color: '#2994A8',
        textDecoration: 'none',
        padding: 16,
        width: '100%',
        '&:hover': {
            textDecoration: 'none',
        },
        '&:focus': {
            textDecoration: 'none',
        },
    },
    activeLink: {
        color: '#FFFFFF',
        backgroundColor: '#2994A8',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
        '&:focus': {
            textDecoration: 'none',
        },
    },
};
