import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';

import DisplayLatLong from './DisplayLatLong';
import { decodeHtml } from '../../utils';
import './stationInformation.css';

import inlineStyles from './styles';

function StationInformationView({ stationInformation }) {
    const setStationInfoElement = (itemValue) => {
        const infoValueIsBoolean = itemValue.get('StationAttrDetailedInfoValueNVIsBoolean');
        const infoValue = itemValue.get('StationAttrDetailedInfoValueNVValue');
        let stationInfoElement = decodeHtml(infoValue);
        if (infoValueIsBoolean || infoValue === 'yes' || infoValue === 'no' || infoValue === 'true' || infoValue === 'false') {
            const isValueChecked = infoValueIsBoolean ? infoValue : (infoValue === 'yes' || infoValue === 'true');
            stationInfoElement = (
                <Checkbox
                    checked={isValueChecked}
                    disabled
                />
            );
        }
        return stationInfoElement;
    };

    const setStationLocation = (stationGeneralInfo, indexValue) => {
        const latitude = stationGeneralInfo.get('StationAttrGeneralInfoLatitude');
        const longitude = stationGeneralInfo.get('StationAttrGeneralInfoLongitude');

        return (
            <DisplayLatLong
                indexValue={indexValue}
                latitude={latitude}
                longitude={longitude}
            />
        );
    };

    return (
        <div className="row">
            <div className="col-12" styleName="stationName">
                {stationInformation.getIn(['StationAttrGeneralInfo', 'StationAttrGeneralInfoStationName'])}
            </div>
            <div className="col-12">
                {stationInformation.getIn(['StationAttrGeneralInfo', 'StationAttrGeneralInfoAddress1'])}
            </div>
            <div className="col-12">
                {stationInformation.getIn(['StationAttrGeneralInfo', 'StationAttrGeneralInfoCity'])}
            </div>
            <div className="col-12">
                {stationInformation.getIn(['StationAttrGeneralInfo', 'StationAttrGeneralInfoPostalCode'])}
            </div>
            <div className="col-12">
                {stationInformation.getIn(['StationAttrGeneralInfo', 'StationAttrGeneralInfoCountryName'])}
            </div>
            <div className="col-12" styleName="stationDetailedInfo">
                {stationInformation.get('StationAttrDetailedInfo').map((item, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={inlineStyles.expansion}>
                            {item.get('StationAttrDetailedInfoName')}
                        </AccordionSummary>
                        <AccordionDetails style={inlineStyles.stationInfoView}>
                            <div style={inlineStyles.expansion}>
                                {item.get('StationAttrDetailedInfoValues').map((itemValue, indexValue) => (
                                    <div
                                        className="row"
                                        key={`${index}_${indexValue}`}
                                        styleName={indexValue % 2 === 0 ? 'odd' : 'even'}
                                    >
                                        <div className="col-6" styleName="stationInfoName">
                                            {itemValue.get('StationAttrDetailedInfoValueNVName')}
                                        </div>
                                        <div className="col-6">
                                            {setStationInfoElement(itemValue)}
                                        </div>
                                    </div>
                                )).toJS()}
                                {item.get('StationAttrDetailedInfoCode') === 'SLD'
                                    && setStationLocation(stationInformation.get('StationAttrGeneralInfo'), index)}
                            </div>
                        </AccordionDetails>

                    </Accordion>
                )).toJS()}
            </div>
        </div>
    );
}

StationInformationView.propTypes = {
    stationInformation: PropTypes.object,
};

export default StationInformationView;
