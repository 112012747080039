import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { injectIntl, FormattedMessage } from 'react-intl';
import messages from './messagesNotes';

const NotesView = (props) => {
    const { formatMessage } = props.intl;
    const {
        editSupported,
        errors,
        filter,
        multiline,
        note,
        notes,
        NotesTableComponent,
        notesTableId,
        noteType,
        onFilterChange,
        onNoteChange,
        selectNoteType,
    } = props;

    const handleNoteChange = ({ target: { value } }) => {
        onNoteChange({ note: value });
    };

    const handleNoteTypeChange = ({ target: { value } }) => {
        onNoteChange({ noteType: value });
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-5 offset-7">
                    <TextField
                        id="srtNotesTableFilter"
                        placeholder={formatMessage(messages.lblFilter)}
                        onChange={onFilterChange}
                        value={filter}
                        fullWidth
                        variant="standard"
                    />
                </div>
                <div className="col-12">
                    <NotesTableComponent
                        id={notesTableId}
                        notes={notes}
                        isDialog
                    />
                </div>
                {editSupported && (
                    <>
                        <div className="col-12">
                            <FormattedMessage {...messages.lblAddNote} />
                        </div>
                        {selectNoteType && (
                            <div className="col-12">
                                <TextField
                                    id="srtNoteType"
                                    label={<FormattedMessage {...messages.lblNoteType} />}
                                    onChange={handleNoteTypeChange}
                                    value={noteType}
                                    error={!!errors.noteType}
                                    helperText={errors.noteType}
                                    select
                                    variant="standard"
                                >
                                    <MenuItem value="AGENT_NOTE"><FormattedMessage {...messages.lblAgentNoteType} /></MenuItem>
                                </TextField>
                            </div>
                        )}
                        <div className="col-12">
                            <TextField
                                id="srtNote"
                                label={<FormattedMessage {...messages.lblNote} />}
                                onChange={handleNoteChange}
                                value={note}
                                fullWidth
                                multiline={multiline}
                                minRows={multiline ? 4 : null}
                                error={!!errors.note}
                                helperText={errors.note}
                                variant="standard"
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

NotesView.propTypes = {
    editSupported: PropTypes.bool,
    errors: PropTypes.object,
    filter: PropTypes.string,
    intl: PropTypes.object,
    multiline: PropTypes.bool,
    note: PropTypes.string,
    notes: PropTypes.array.isRequired,
    NotesTableComponent: PropTypes.func.isRequired,
    notesTableId: PropTypes.string.isRequired,
    noteType: PropTypes.string,
    onFilterChange: PropTypes.func,
    onNoteChange: PropTypes.func,
    selectNoteType: PropTypes.bool,
};

export { NotesView as NotesViewAlias };

export default injectIntl(NotesView);
