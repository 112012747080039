import { fromJS } from 'immutable';
import {
    TRIP_TYPE_RETURN,
    TRIP_TYPE_SEASON,
    ADULT_PAX_AGE,
} from '../util';

import {
    ADD_DISCOUNT,
    ADD_PASSENGER,
    ADMIN_DATA_CHANGED,
    CHANGE_ACCOUNT,
    CHANGE_ARRIVE_STATION,
    CHANGE_DEPART_DATE,
    CHANGE_DEPART_MAX_CONNECTONS,
    CHANGE_DEPART_STATION,
    CHANGE_DISCOUNT_NUMBER,
    CHANGE_DISCOUNT_PASSENGER,
    CHANGE_DISCOUNT_PROGRAM,
    FARE_QUALIFIER_CHANGED,
    CHANGE_OPEN_RETURN,
    CHANGE_PASSENGER, CHANGE_PROMO_CODE,
    CHANGE_RAILCARD_ID,
    CHANGE_RETURN_DATE,
    CHANGE_RETURN_MAX_CONNECTONS,
    CHANGE_TRIP_TYPE, CHANGE_VALIDATION_CODE,
    CHANGE_VIA_AVOID_STATION,
    CHANGE_VIA_AVOID_TYPE,
    CLEAR_ARRIVE_STATION,
    CLEAR_DEPART_STATION,
    CLEAR_END_DATE,
    CLEAR_FIELDS,
    CLEAR_PASSENGERS,
    CLEAR_VIA_AVOID_STATION,
    DISMISS_ERROR_RESPONSE,
    RECEIVE_NEW_USER_STATE,
    REFRESH_USER_STATE,
    REMOVE_DISCOUNT,
    REMOVE_PASSENGER,
    REMOVE_VIA_AVOID,
    SWAP_STATIONS,
    UPDATE_SEARCH_QUERY,
} from './actionsShoppingSearch';

import { getViaAvoidSuffix } from './components/constants';

const departDate = new Date();
departDate.setDate(departDate.getDate() + 1);
departDate.setHours(6, 0, 0, 0);
const returnDate = new Date(departDate.getTime());
returnDate.setHours(18);
const startDate = new Date();
startDate.setHours(0, 0, 0, 0);
const weekInMillis = 604800000;

const defaultDiscount = {
    discountProgram: null,
    discountNumber: 1,
    railcardId: '',
    passenger: 0,
    changed: false,
};

const initialQueryState = fromJS({
    isFetchingUserState: false,
    txtDepart: '',
    departCode: '',
    departCodeSuppliers: '',
    departCountryCode: '',
    timeOptionOutboundDate: 0,
    openReturn: false,
    departDate: departDate.getTime(),
    txtArrive: '',
    arriveCode: '',
    arriveCodeSuppliers: '',
    arriveCountryCode: '',
    returnDate: returnDate.getTime(),
    timeOptionInboundDate: 0,
    viaAvoidCodeOutbound: '',
    viaAvoidCodeInbound: '',
    tripType: TRIP_TYPE_RETURN,
    txtViaAvoidOutbound: '',
    txtViaAvoidInbound: '',
    viaAvoidTypeOutbound: 1,
    viaAvoidTypeInbound: 1,
    availableAccounts: [],
    availableSuppliers: [],
    locale: '',
    accountName: '',
    context: '',
    paxAge: [ADULT_PAX_AGE],
    discounts: [defaultDiscount],
    resultsRepresentation: 'LegSolutionOrdered',
    includeFareRules: true,
    errorResponse: '',
    shouldCallRefreshUserState: true,
    departMaxConnections: null,
    returnMaxConnections: null,
    promoCode: '',
    validationCode: '',
});

const deleteDiscountPassenger = (discounts) => discounts.map((discount) => discount.set('passenger', defaultDiscount.passenger));

export default (state = initialQueryState, action) => {
    switch (action.type) {
        case REFRESH_USER_STATE:
            return state.merge({
                isFetchingUserState: true,
            });
        case RECEIVE_NEW_USER_STATE: {
            const updates = {
                isFetchingUserState: false,
            };

            if (action.error) {
                updates.errorResponse = action.payload;
            } else {
                updates.availableAccounts = fromJS(action.payload.availableAccounts);
                updates.availableSuppliers = fromJS(action.payload.availableSuppliers);
                updates.locale = fromJS(action.payload.locale);
                updates.shouldCallRefreshUserState = false;
            }

            return state.merge(updates);
        }
        case CHANGE_ACCOUNT:
            return state.merge({
                accountName: action.name,
                context: action.context,
            });
        case CHANGE_TRIP_TYPE: {
            /*
                trip types:
                    0 - return
                    2 - single
                    4 - season
             */
            const updates = {
                tripType: action.tripType,
                openReturn: false,
            };

            /*
            when switching to return we have to change the return date
            to correspond to the depart date
         */
            const departDateTemp = state.get('departDate');
            if (action.tripType === TRIP_TYPE_RETURN && departDateTemp >= state.get('returnDate')) {
                const returnTemp = new Date(departDateTemp);
                returnTemp.setHours(18);
                updates.returnDate = returnTemp.getTime();
                updates.timeOptionInboundDate = 0;
            }

            if (action.tripType === TRIP_TYPE_SEASON) {
                updates.departDate = startDate.getTime();
                updates.returnDate = null;
            } else if (state.get('tripType') === TRIP_TYPE_SEASON) {
                updates.departDate = departDate.getTime();
                updates.returnDate = returnDate ? returnDate.getTime() : null;
            }

            return state.merge(updates);
        }
        case CHANGE_DEPART_STATION:
            return state.merge({
                txtDepart: action.txt,
                departCode: action.code,
                departCodeSuppliers: action.supplier,
                departCountryCode: action.countryCode,
            });
        case CHANGE_OPEN_RETURN:
            return state.merge({
                openReturn: !state.get('openReturn'),
                returnMaxConnections: null,
                returnDate: new Date(state.get('departDate')).setHours(18),
                viaAvoidCodeInbound: '',
                viaAvoidTypeInbound: 1,
                txtViaAvoidInbound: '',
            });
        case CHANGE_DEPART_DATE: {
            const updates = {
                timeOptionOutboundDate: action.timeOption,
                departDate: action.date,
            };

            /*
         when changing  depart date we have to change the return date
         to correspond to the depart date
         */
            if (state.get('tripType') === TRIP_TYPE_RETURN && action.date >= state.get('returnDate')) {
                const returnTemp = new Date(action.date);
                returnTemp.setHours(18);
                updates.returnDate = returnTemp.getTime();
                updates.timeOptionInboundDate = state.get('timeOptionInboundDate');
            }

            /*
         when changing Season Pass start date we have to change the end date
         to correspond to the start date
         */
            if (state.get('tripType') === TRIP_TYPE_SEASON && state.get('returnDate')
                && (state.get('returnDate') - action.date < weekInMillis)) {
                const returnTemp = new Date(action.date + weekInMillis);
                updates.returnDate = returnTemp.getTime();
            }

            return state.merge(updates);
        }
        case CHANGE_ARRIVE_STATION:
            return state.merge({
                txtArrive: action.txt,
                arriveCode: action.code,
                arriveCodeSuppliers: action.supplier,
                arriveCountryCode: action.countryCode,
            });
        case CHANGE_RETURN_DATE:
            return state.merge({
                timeOptionInboundDate: action.timeOption,
                returnDate: action.date,
            });
        case SWAP_STATIONS:
            return state.merge({
                txtDepart: state.get('txtArrive'),
                departCode: state.get('arriveCode'),
                departCodeSuppliers: state.get('arriveCodeSuppliers'),
                departCountryCode: state.get('arriveCountryCode'),
                txtArrive: state.get('txtDepart'),
                arriveCode: state.get('departCode'),
                arriveCodeSuppliers: state.get('departCodeSuppliers'),
                arriveCountryCode: state.get('departCountryCode'),
            });
        case CLEAR_END_DATE:
            return state.merge({
                returnDate: null,
            });
        case CHANGE_VIA_AVOID_STATION:
            return state.merge({
                [`viaAvoidCode${getViaAvoidSuffix(action.direction)}`]: action.code,
                [`txtViaAvoid${getViaAvoidSuffix(action.direction)}`]: action.txt,
            });
        case CHANGE_VIA_AVOID_TYPE:
            return state.merge({
                [`viaAvoidType${getViaAvoidSuffix(action.direction)}`]: action.viaAvoidType,
            });
        case REMOVE_VIA_AVOID:
            return state.merge({
                [`viaAvoidCode${getViaAvoidSuffix(action.direction)}`]: '',
                [`viaAvoidType${getViaAvoidSuffix(action.direction)}`]: 1,
                [`txtViaAvoid${getViaAvoidSuffix(action.direction)}`]: '',
            });
        case ADD_PASSENGER:
            return state
                .setIn(['paxAge', state.get('paxAge').size], ADULT_PAX_AGE)
                .update('discounts', deleteDiscountPassenger);
        case CHANGE_PASSENGER:
            return state.setIn(['paxAge', action.index], action.paxAge);
        case REMOVE_PASSENGER:
            return state
                .deleteIn(['paxAge', action.index])
                .update('discounts', deleteDiscountPassenger);
        case CLEAR_PASSENGERS:
            return state.merge({ paxAge: [ADULT_PAX_AGE] });
        case ADD_DISCOUNT:
            return state.setIn(['discounts', state.get('discounts').size], fromJS(defaultDiscount));
        case REMOVE_DISCOUNT:
            return state.deleteIn(['discounts', action.index]);
        case CHANGE_DISCOUNT_PROGRAM:
            return state.setIn(['discounts', action.index, 'discountProgram'], action.discountProgram);
        case FARE_QUALIFIER_CHANGED:
            return state.setIn(['discounts', action.index, 'changed'], action.changed);
        case CHANGE_DISCOUNT_NUMBER:
            return state.setIn(['discounts', action.index, 'discountNumber'], action.discountNumber);
        case CHANGE_DISCOUNT_PASSENGER:
            return state.setIn(['discounts', action.index, 'passenger'], action.passenger);
        case CHANGE_RAILCARD_ID:
            return state.setIn(['discounts', action.index, 'railcardId'], action.railcardId);
        case CLEAR_FIELDS:
            return state.merge({
                txtDepart: '',
                departCode: '',
                departCodeSuppliers: '',
                departCountryCode: '',
                timeOptionOutboundDate: 0,
                departDate: departDate.getTime(),
                txtArrive: '',
                arriveCode: '',
                arriveCodeSuppliers: '',
                arriveCountryCode: '',
                returnDate: returnDate.getTime(),
                timeOptionInboundDate: 0,
                viaAvoidCodeOutbound: '',
                viaAvoidCodeInbound: '',
                tripType: TRIP_TYPE_RETURN,
                txtViaAvoidOutbound: '',
                txtViaAvoidInbound: '',
                viaAvoidTypeOutbound: 1,
                viaAvoidTypeInbound: 1,
                accountName: '',
                context: '',
                paxAge: [ADULT_PAX_AGE],
                discounts: [defaultDiscount],
                errorResponse: '',
                departMaxConnections: null,
                returnMaxConnections: null,
                openReturn: false,
                promoCode: '',
                validationCode: '',
            });
        case CLEAR_DEPART_STATION: {
            const txtIndex = state.get('txtDepart').indexOf(',');
            return state.merge({
                txtDepart: action.text.length <= txtIndex || txtIndex === -1 ? action.text : '',
                departCode: '',
                departCodeSuppliers: '',
                departCountryCode: '',
                viaAvoidCodeOutbound: '',
                viaAvoidCodeInbound: '',
                txtViaAvoidOutbound: '',
                txtViaAvoidInbound: '',
                viaAvoidTypeOutbound: 1,
                viaAvoidTypeInbound: 1,
                discounts: [defaultDiscount],
            });
        }
        case CLEAR_ARRIVE_STATION: {
            const txtIndex = state.get('txtArrive').indexOf(',');
            return state.merge({
                txtArrive: action.text.length <= txtIndex || txtIndex === -1 ? action.text : '',
                arriveCode: '',
                arriveCodeSuppliers: '',
                arriveCountryCode: '',
            });
        }
        case CLEAR_VIA_AVOID_STATION: {
            const txtIndex = state.get(`txtViaAvoid${getViaAvoidSuffix(action.direction)}`).indexOf(',');
            return state.merge({
                [`viaAvoidCode${getViaAvoidSuffix(action.direction)}`]: '',
                [`txtViaAvoid${getViaAvoidSuffix(action.direction)}`]: action.text.length <= txtIndex || txtIndex === -1 ? action.text : '',
            });
        }
        case CHANGE_DEPART_MAX_CONNECTONS: {
            return state.merge({
                departMaxConnections: action.maxConnections,
            });
        }
        case CHANGE_RETURN_MAX_CONNECTONS: {
            return state.merge({
                returnMaxConnections: action.maxConnections,
            });
        }
        case DISMISS_ERROR_RESPONSE:
            return state.merge({
                errorResponse: '',
            });
        case UPDATE_SEARCH_QUERY:
            return action.query;
        case ADMIN_DATA_CHANGED:
            return state.set('shouldCallRefreshUserState', true);
        case CHANGE_PROMO_CODE:
            return state.set('promoCode', action.promoCode);
        case CHANGE_VALIDATION_CODE:
            return state.set('validationCode', action.validationCode);
        default:
            return state;
    }
};
