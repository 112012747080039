import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { styled } from '@mui/system';
import Icon from '@mui/material/Icon';
import FoodVariant from 'mdi-material-ui/FoodVariant';
import ChefHat from 'mdi-material-ui/ChefHat';
import CarKey from 'mdi-material-ui/CarKey';
import AccountStar from 'mdi-material-ui/AccountStar';
import classNames from 'classnames';

import inlineStyles from './fareStyles';

const StyledAmenityBadgeContainer = styled('div')(() => ({
    ...inlineStyles.amenityBadgeContainer,
}));

const StyledAmenityBadge = styled('div')(() => ({
    ...inlineStyles.amenityBadge,
}));

const StyledIconComponent = styled('div')(({ theme }) => ({
    fontSize: theme.typography.pxToRem(16),
    margin: theme.spacing(0.5),
}));

const amenities = {
    audio: {
        type: 'fa',
        icon: 'fa-volume-high',
    },
    baggage: {
        type: 'fa',
        icon: 'fa-suitcase',
    },
    bar: {
        type: 'fa',
        icon: 'fa-martini-glass',
    },
    bicycle: {
        type: 'fa',
        icon: 'fa-bicycle',
        text: 'Free',
    },
    bicycle_available: {
        type: 'fa',
        icon: 'fa-bicycle',
    },
    buffet: {
        type: 'fa',
        icon: ['fa-wine-glass', 'fa-bell-concierge'],
    },
    dining: {
        type: 'fa',
        icon: 'fa-utensils',
    },
    hot_food: {
        type: 'fa',
        icon: 'fa-bell-concierge',
    },
    internet: {
        type: 'fa',
        icon: 'fa-wifi',
        text: 'Free',
    },
    internet_available: {
        type: 'fa',
        icon: 'fa-wifi',
    },
    lounge: {
        type: 'fa',
        icon: 'fa-couch',
        text: 'Car',
    },
    lounge_access: {
        type: 'fa',
        icon: 'fa-couch',
        text: 'Station',
    },
    meal: {
        type: 'mdi',
        icon: FoodVariant,
        text: 'Free',
    },
    network: {
        type: 'fa',
        icon: 'fa-wifi',
    },
    newspaper: {
        type: 'fa',
        icon: 'fa-newspaper',
    },
    parking: {
        type: 'fa',
        icon: 'fa-square-parking',
    },
    power_socket: {
        type: 'fa',
        icon: 'fa-plug',
    },
    priority_boarding: {
        type: 'mdi',
        icon: AccountStar,
    },
    private: {
        type: 'fa',
        icon: 'fa-person-booth',
    },
    quiet: {
        type: 'fa',
        icon: 'fa-volume-xmark',
    },
    restaurant: {
        type: 'fa',
        icon: ['fa-circle', 'fa-utensils'],
    },
    restaurant_1st: {
        type: 'mdi',
        icon: ChefHat,
    },
    snack: {
        type: 'fa',
        icon: 'fa-cookie-bite',
    },
    multiple_fares: {
        type: 'fa',
        icon: 'fa-layer-group',
    },
    taxi: {
        type: 'fa',
        icon: 'fa-taxi',
    },
    toilet: {
        type: 'fa',
        icon: 'fa-toilet-paper',
    },
    trolley: {
        type: 'fa',
        icon: 'fa-cart-shopping',
    },
    valet_parking: {
        type: 'mdi',
        icon: CarKey,
    },
    video: {
        type: 'fa',
        icon: 'fa-video',
    },
    wheelchair: {
        type: 'fa',
        icon: 'fa-wheelchair',
    },
    wheelchair_res: {
        type: 'fa',
        icon: 'fa-wheelchair',
        text: 'Reserve',
    },
};

function FareAmenities({ fareAmenities }) {
    return (
        <div className="amenities">
            <Helmet
                script={[
                    {
                        src: 'https://use.fontawesome.com/releases/v6.2.0/js/solid.js',
                        integrity: 'sha384-H6YBpm4VRWLTfp0nRZIrLoT2zc1rWaEEYxYC+HyWXxSKY+AUn5evalgkgT0EpMDN',
                        crossOrigin: 'anonymous',
                    },
                    {
                        src: 'https://use.fontawesome.com/releases/v6.2.0/js/fontawesome.js',
                        integrity: 'sha384-KZ1LJTCtJ2KL1x2pH7hR3CElXzG3s0P624sTHaiTFoSKBMBlwu0mrvkjm8jKox6f',
                        crossOrigin: 'anonymous',
                    },
                ]}
            />
            {fareAmenities.reduce((icons, item) => {
                const amenity = amenities[item.code.toLowerCase()];
                if (amenity) {
                    const iconTypes = {
                        fa: {
                            component: Icon,
                            props: { title: item.desc },
                            className: (amenityIcon) => classNames('fa', amenityIcon),
                        },
                        mdi: {
                            component: amenity.icon,
                            props: { titleAccess: item.desc },
                        },
                    };

                    const iconType = iconTypes[amenity.type];
                    const amenityIcons = Array.isArray(amenity.icon) ? amenity.icon : [amenity.icon];

                    let icon = amenityIcons.map((amenityIcon, index) => (
                        <StyledIconComponent
                            as={iconType.component}
                            className={iconType.className && iconType.className(amenityIcon)}
                            data-fa-transform={classNames({
                                'shrink-6': amenityIcons.length > 1,
                                'up-4': amenityIcons.length > 1 && !index,
                                'left-4': amenityIcons.length > 1 && !index,
                                'down-4': amenityIcons.length > 1 && index,
                                'right-4': amenityIcons.length > 1 && index,
                            })}
                            key={`amenity_${item.code}_${amenityIcon}`}
                            alt={item.code}
                            {...iconType.props}
                        />
                    ));

                    if (Array.isArray(amenity.icon)) {
                        icon = <span key={`amenity_${item.code}`} className="fa-layers fa-fw">{icon}</span>;
                    }

                    if (amenity.text) {
                        icon = (
                            <StyledAmenityBadgeContainer key={`amenity_${item.code}`}>
                                <div>{icon}</div>
                                <StyledAmenityBadge>{amenity.text}</StyledAmenityBadge>
                            </StyledAmenityBadgeContainer>
                        );
                    }

                    icons.push(icon);
                }
                return icons;
            }, [])}
        </div>
    );
}

FareAmenities.propTypes = {
    fareAmenities: PropTypes.array,
    className: PropTypes.string,
};

export default FareAmenities;
