export const black = 'black';
export const blackShadow = 'rgba(0, 0, 0, 0.38)';
export const darkGray = '#333';
export const darkGray2 = '#555';
export const darkGray3 = '#444';
export const darkGrayShadow = 'rgba(51, 51, 51, 0.87)';
export const darkShadow = 'rgba(0, 0, 0, 0.227451)';
export const grayShadow = 'rgba(153, 153, 153, 0.2)';
export const lightBlue = '#E8F2F9';
export const lightGray = '#E0E0E0';
export const lightGray2 = '#ddd';
export const lightGray3 = '#ccc';
export const lightPink = '#FAEBEB';
export const lightShadow = 'rgba(0, 0, 0, 0.156863)';
export const limeGreen = '#00C575';
export const midBlue = '#428BCA';
export const midGray = 'rgba(158, 158, 158, 13)';
export const midGray2 = '#e7e7e7';
export const midGray3 = '#777';
export const midGray4 = '#9e9e9e';
export const midGray5 = '#ccc';
export const midGray6 = '#748490';
export const orange = '#FF6600';
export const pelorous = '#2994A8';
export const red = 'red';
export const redLightGray = '#E0D8D8';
export const darkRed = '#611A15';
export const lightRed = '#fdecea';
export const snow = '#FCFCFC';
export const white = 'white';
export const whiteSmoke = '#F5F5F5';
export const whiteSmoke2 = '#f9f9f9';
export const whiteSmoke3 = '#f8f8f8';

const colors = {
    buttonGreen: '#00C575',
    buttonGreenDisabled: '#77DB8B',
    buttonBlue: '#2994A8',
    buttonGrey: '#EDEDED',
    buttonOrange: '#FE8007',
    buttonRed: '#FF4C39',
    buttonRedDisabled: '#FF8B7E',
    textPrimary: darkGray,
    backgroundColor: '#eaffee',
    linkColor: midBlue,
};

export const defaultFareColorClassSchema = {
    red: 'red', //  '#FF4C39',
    green: 'green', //  '#2DC84F',
    blue: 'blue', //  '#2994A8',
    orange: 'orange', //  '#FF9A39' ex. orange
    purple: 'purple', //  '#9900FF'
    yellow: 'yellow', //  '#FFFF00'
};

export default colors;
