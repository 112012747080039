import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import WarningIcon from '@mui/icons-material/Warning';

import TdoRegionsView from './TdoRegionsView';
import PaperTicket from './PaperTicket';
import PickupWarningMessage from './PickupWarningMessage';
import SmsOptions from './SmsOptions';
import ShippingAddress from './ShippingAddress';
import SmartCardPassengersInfo from './SmartCardPassengersInfo';
import { hasSupplier, isTDOAvailAtDepartureStation } from './utils';
import { formatPrice } from '../../utils';

import messages from './messagesTicketDelivery';
import inlineStyles from './styles';
import './ticketDelivery.css';
import TicketDeliveryDetails from '../TicketDeliveryDetails/TicketDeliveryDetails';

function TdoByType({
    canadianProvinces,
    countries,
    errors,
    intl,
    isFetching,
    isTdoMail,
    onCloseStationInformationDialog,
    onCloseStationsTicketDialog,
    onOpenStationInformationDialog,
    onOpenStationsTicketDialog,
    onPickUpStationChange,
    onSmartCardNumberChange,
    onSmsCountryChange,
    onSmsNumberChange,
    onTdoAddressChange,
    onTdoChange,
    onTdoMailNameChange,
    onTdoPaperEmailChange,
    onTdoRegionChange,
    passengers,
    regionMaps,
    smartCardStations,
    smsCodeOptions,
    states,
    stationCode,
    stationInformationDialogOpen,
    stationName,
    stationsTicketDialogOpen,
    suppliers,
    tdo,
    tdoMailAddress,
    tdoMailName,
    tdoPickUpStation,
    tdOptions,
    tdoPaperEmail,
    tdoRegion,
    tdoRegions,
    tdoSmartCardNumber,
    tdoSmsCountry,
    tdoSmsNumber,
    title,
}) {
    const getRegionCountries = () => {
        let regionCountries = countries;
        if (tdoRegion !== 'GLOBAL') {
            // perform a look-up of the region
            const region = regionMaps.find((item) => item.get('region') === tdoRegion);
            regionCountries = region ? region.get('countryList') : regionCountries;
        }
        return regionCountries;
    };

    const setWarningMessage = (message, key) => (
        <div style={inlineStyles.warning} key={key}>
            <WarningIcon style={inlineStyles.warningIcon} />
            <span>{message}</span>
        </div>
    );
    const electronicWarningNoMail = <FormattedMessage {...messages.lblTdoElectronicNoMail} />;
    const electronicWarningAskedMail = <FormattedMessage {...messages.lblTdoElectronicAskedMail} />;
    const electronicWarningDBPrint = <FormattedMessage {...messages.lblTdoElectronicDBPrint} />;
    const regionCountries = isTdoMail ? getRegionCountries() : {};

    const tdoMailAddressView = isTdoMail && !!tdoRegion && (
        <ShippingAddress
            tdoMailName={tdoMailName}
            onTdoMailNameChange={onTdoMailNameChange}
            tdoMailAddress={tdoMailAddress}
            onTdoAddressChange={onTdoAddressChange}
            countries={regionCountries}
            states={states}
            canadianProvinces={canadianProvinces}
            regionMaps={regionMaps}
            errors={errors}
        />
    );

    const smartCardView = tdo === 'SCT' && (
        <SmartCardPassengersInfo
            passengers={passengers}
            errors={errors}
            smartCardStations={smartCardStations}
            isFetching={isFetching}
            tdoSmartCardNumber={tdoSmartCardNumber}
            tdoPickUpStation={tdoPickUpStation}
            onPickUpStationChange={onPickUpStationChange}
            onSmartCardNumberChange={onSmartCardNumberChange}
        />
    );

    const handleTdoChange = (event, value) => {
        const countryCodeTemp = tdoMailAddress ? tdoMailAddress.countryCode : '';
        const countryCode = isTdoMail && regionCountries.size === 1 ? regionCountries.getIn([0, 'code']) : countryCodeTemp;
        onTdoChange(value, countryCode);
    };

    return (
        <div>
            <div styleName="typeTitle">
                {title}
            </div>
            {isTdoMail && tdoRegions && !!tdoRegions.length && (
                <TdoRegionsView
                    tdoRegions={tdoRegions}
                    tdoRegion={tdoRegion}
                    onTdoRegionChange={onTdoRegionChange}
                />
            )}
            {tdOptions.map((item) => {
                let warningMessage = null;
                let smsOptionsView = null;
                let paperEmailView = null;
                const showAdditional = item.BookingTDOCode === tdo;

                if (showAdditional) {
                    if (['ETK', 'PAH'].includes(tdo)) {
                        warningMessage = setWarningMessage(electronicWarningAskedMail, 'electronicWarningAskedMail');
                    } else if (tdo === 'EML') {
                        warningMessage = setWarningMessage(electronicWarningNoMail, 'electronicWarningNoMail');
                        if (hasSupplier('DBB', suppliers)) {
                            warningMessage = [warningMessage, setWarningMessage(electronicWarningDBPrint, 'electronicWarningDBPrint')];
                        }
                    } else if (item.BookingTDOCode.indexOf('SMS') > -1) {
                        smsOptionsView = (
                            <SmsOptions
                                smsCodeOptions={smsCodeOptions}
                                tdoSmsCountry={tdoSmsCountry}
                                tdoSmsNumber={tdoSmsNumber}
                                onSmsCountryChange={onSmsCountryChange}
                                onSmsNumberChange={onSmsNumberChange}
                                errors={errors}
                            />
                        );
                    } else if (['TVM', 'TOF'].includes(tdo) && !isTDOAvailAtDepartureStation(tdOptions, tdo)) {
                        warningMessage = (
                            <PickupWarningMessage
                                tdOptions={tdOptions}
                                tdo={tdo}
                                stationCode={stationCode}
                                stationName={stationName}
                                suppliers={suppliers}
                                stationsTicketDialogOpen={stationsTicketDialogOpen}
                                stationInformationDialogOpen={stationInformationDialogOpen}
                                onOpenStationsTicketDialog={onOpenStationsTicketDialog}
                                onCloseStationsTicketDialog={onCloseStationsTicketDialog}
                                onOpenStationInformationDialog={onOpenStationInformationDialog}
                                onCloseStationInformationDialog={onCloseStationInformationDialog}
                            />
                        );
                    } else if (tdo === 'PRN') {
                        paperEmailView = (
                            <PaperTicket
                                tdoPaperEmail={tdoPaperEmail}
                                onTdoPaperEmailChange={onTdoPaperEmailChange}
                                errors={errors}
                            />
                        );
                    }
                }
                const tdoDescription = `${item.BookingTDODesc} (${formatPrice(item.BookingTDOFee, item.BookingTDOFeeCurr, intl)})`;
                return (
                    <div key={item.BookingTDOCode}>
                        <RadioGroup
                            name="tdoOptions"
                            value={tdo}
                            onChange={handleTdoChange}
                        >
                            <FormControlLabel
                                id={`tdo_${item.BookingTDOCode}`}
                                value={item.BookingTDOCode}
                                label={tdoDescription}
                                style={inlineStyles.radioButton}
                                control={<Radio />}
                            />
                        </RadioGroup>
                        {item.BookingTDOMarketingCarrierRefs && (
                            <div style={inlineStyles.details}>
                                <TicketDeliveryDetails details={item.BookingTDOMarketingCarrierRefs} />
                            </div>
                        )}
                        {showAdditional && (
                            <>
                                {warningMessage}
                                {smsOptionsView}
                                {tdoMailAddressView}
                                {smartCardView}
                                {paperEmailView}
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

TdoByType.propTypes = {
    canadianProvinces: PropTypes.object,
    countries: PropTypes.object,
    errors: PropTypes.object,
    intl: PropTypes.object,
    isFetching: PropTypes.bool,
    isTdoMail: PropTypes.bool,
    onCloseStationInformationDialog: PropTypes.func,
    onCloseStationsTicketDialog: PropTypes.func,
    onOpenStationInformationDialog: PropTypes.func,
    onOpenStationsTicketDialog: PropTypes.func,
    onPickUpStationChange: PropTypes.func,
    onSmartCardNumberChange: PropTypes.func,
    onSmsCountryChange: PropTypes.func,
    onSmsNumberChange: PropTypes.func,
    onTdoAddressChange: PropTypes.func,
    onTdoChange: PropTypes.func,
    onTdoMailNameChange: PropTypes.func,
    onTdoPaperEmailChange: PropTypes.func,
    onTdoRegionChange: PropTypes.func,
    passengers: PropTypes.object,
    regionMaps: PropTypes.object,
    smartCardStations: PropTypes.object,
    smsCodeOptions: PropTypes.array,
    states: PropTypes.object,
    stationCode: PropTypes.string,
    stationInformationDialogOpen: PropTypes.bool,
    stationName: PropTypes.string,
    stationsTicketDialogOpen: PropTypes.bool,
    suppliers: PropTypes.array,
    tdo: PropTypes.string,
    tdoMailAddress: PropTypes.object,
    tdoMailName: PropTypes.string,
    tdoPickUpStation: PropTypes.object,
    tdOptions: PropTypes.array,
    tdoPaperEmail: PropTypes.string,
    tdoRegion: PropTypes.string,
    tdoRegions: PropTypes.array,
    tdoSmartCardNumber: PropTypes.object,
    tdoSmsCountry: PropTypes.string,
    tdoSmsNumber: PropTypes.string,
    title: PropTypes.node,
};

export { TdoByType as TdoByTypeAlias };
export default injectIntl(TdoByType);
