export default {
    button: {
        margin: 8,
    },
    label: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 5,
        flexDirection: 'row-reverse',
        marginLeft: 0,
    },
    labelWithMargins: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 0,
        flexDirection: 'row-reverse',
        marginLeft: 0,
    },
    refreshContainer: {
        position: 'absolute',
        display: 'inline-block',
    },
    homeLink: {
        padding: 0,
        marginBottom: 15,
    },
    AssocAutoComplete: {
        marginBottom: 14,
    },
};
