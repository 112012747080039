import { defineMessages } from 'react-intl';

export default defineMessages({
    lblSelectProgram: {
        id: 'app.shopping.createBooking.discountsLoyalties.selectProgram',
        defaultMessage: 'Select Program',
    },
    lblDiscountCards: {
        id: 'app.shopping.createBooking.discountsLoyalties.discountCards',
        defaultMessage: 'Discount Cards:',
    },
    lblDiscountCardNumber: {
        id: 'app.shopping.createBooking.discountsLoyalties.discountCardNumber',
        defaultMessage: 'Discount Card number',
    },
    lblDiscountAuthorization: {
        id: 'app.shopping.createBooking.discountsLoyalties.discountAuthorization',
        defaultMessage: 'Authorization #',
    },
    lblLoyaltyCardNumber: {
        id: 'app.shopping.createBooking.discountsLoyalties.loyaltyCardNumber',
        defaultMessage: 'Loyalty Card number',
    },
    lblDiscountsLoyalties: {
        id: 'app.shopping.createBooking.discountsLoyalties.discountsLoyalties',
        defaultMessage: 'Fare Discounts and Loyalty Information',
    },
    lblCorporateCard: {
        id: 'app.shopping.createBooking.discountsLoyalties.corporateCard',
        defaultMessage: 'Corporate Card:',
    },
    lblLoyaltyCard: {
        id: 'app.shopping.createBooking.discountsLoyalties.loyaltyCard',
        defaultMessage: 'Loyalty Card:',
    },
});
