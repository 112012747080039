import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import inlineStyles from './styles';

const GetHelpTable = ({
    headerLabel,
    helpText,
}) => (
    <div>
        <dl>
            <dt>
                <h5 style={inlineStyles.noBottomMargin}>
                    <FormattedMessage {...headerLabel} />
                    :
                </h5>
            </dt>
            <dd>
                <FormattedMessage {...helpText} />
            </dd>
        </dl>
    </div>
);
GetHelpTable.propTypes = {
    headerLabel: PropTypes.object,
    helpText: PropTypes.object,
};
export default GetHelpTable;
