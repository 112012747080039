import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

const CancelPenaltyDialog = ({
    open,
    handleClose,
    penalty,
    penaltyCurrency,
    cancelledDueToStrike,
}) => (
    <Dialog
        open={open}
        onClose={handleClose}
        disableEnforceFocus
        maxWidth={false}
    >
        <DialogTitle>
            <FormattedMessage {...messages.lblCancellationPenaltyTitle} />
        </DialogTitle>
        <DialogContent>
            {
                cancelledDueToStrike
                    ? <FormattedMessage {...messages.lblCancellationPenaltyDisruptionContent} />
                    : (
                        <>
                            <FormattedMessage {...messages.lblCancellationPenaltyAllowedContent} />
                            {` ${penaltyCurrency} ${parseFloat(penalty).toFixed(2)}`}
                        </>
                    )
            }
        </DialogContent>
        <DialogActions>
            <div className="row">
                <div className="col-12 col-sm-12">
                    <Button
                        variant="contained"
                        id="srtCancelPenaltyClose"
                        onClick={handleClose}
                        fullWidth
                    >
                        <FormattedMessage {...messages.btnClose} />
                    </Button>
                </div>
            </div>
        </DialogActions>
    </Dialog>
);

CancelPenaltyDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    penalty: PropTypes.number.isRequired,
    penaltyCurrency: PropTypes.string.isRequired,
    cancelledDueToStrike: PropTypes.bool.isRequired,
};

export default CancelPenaltyDialog;
