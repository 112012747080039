import styled from 'styled-components';
import TableCell from '@mui/material/TableCell';
import { pelorous } from '../../../../colors';

export const Title = styled.h1`
    font-size: 24px;
    font-weight: 500;
`;

export const Link = styled.div`
    text-decoration: underline;
    cursor: pointer;
    color: ${pelorous};
`;

export const StationLink = styled.a`
    text-decoration: none;
    cursor: pointer;
`;

export const TableWrapper = styled.div`
    border-top: 1px solid rgba(224, 224, 224, 1);
`;

export const TicketableFareGroupTitleCell = styled(TableCell)`
    font-size: 14px;
    font-weight: 600;
`;

export default Link;
