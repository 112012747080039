import { lightGray } from '../../../colors';

export default {
    tableContainer: {
        marginBottom: 15,
        marginTop: 15,
    },
    dialogPaper: {
        overflowY: 'visible',
    },
    dialogActionsRoot: {
        width: '100%',
    },
    dialogContent: {
        borderTop: `1px solid ${lightGray}`,
        borderBottom: `1px solid ${lightGray}`,
    },
    refreshIndicator: {
        zIndex: 2000,
        marginTop: '5%',
        marginLeft: '48%',
    },
    availableCouponsColumn: {
        width: 160,
    },
    returnedCouponsColumn: {
        width: 170,
    },
};
