import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';

import ExtendedSnackbar from '../../../../components/ExtendedSnackbar/ExtendedSnackbar';
import messages from './messagesReservations';
import './reservations.css';
import SeatPreferences from './SeatPreferences';
import BikeReservation from './BikeReservation';

const getPassengerType = (paxAge, intl) => {
    let passengerType = '';
    if (paxAge < 16) {
        passengerType = intl.formatMessage(messages.lblPassengerChild);
    } else if (paxAge < 26) {
        passengerType = intl.formatMessage(messages.lblPassengerYouth);
    } else if (paxAge < 55) {
        passengerType = intl.formatMessage(messages.lblPassengerAdult);
    } else {
        passengerType = intl.formatMessage(messages.lblPassengerSenior);
    }
    return passengerType;
};

const ReservationsView = ({
    faresSelected,
    bikeReservations,
    seatReservation,
    shoppingPassengers,
    onPassengerClick,
    onChangeReservation,
    onChangeAllSegmentReservation,
    onChangeAllLegReservation,
    onChangePassengerReservation,
    onChangeBikeReservation,
    passengerId,
    selectedSeatReservations,
    selectedBikeReservations,
    intl,
    exchangeOrderMode,
}) => {
    const { formatMessage } = intl;
    const lblLeg = formatMessage(messages.lblLeg);
    const lblPassenger = formatMessage(messages.lblPassenger);
    const lblTo = formatMessage(messages.lblTo);
    const [data, setData] = useState({ alertText: '', isProcessing: false });
    const [selectedPax, setSelectedPax] = useState(0);

    const handleSnackbarClose = () => {
        setData({ ...data, alertText: '' });
    };

    const handlePassengerClick = (passengerRefId, indexPax) => () => {
        onPassengerClick(passengerRefId);
        setSelectedPax(indexPax);
    };

    return (
        <div className="container-fluid" styleName="dialogContent">
            {faresSelected.toJS().map((item, index) => (
                <div key={item.legId} className="row" styleName="legContent" data-test="faresSelected">
                    <div className="col-12">
                        <div className="row" styleName="segment legSegment">
                            <div className="col-4" styleName="legSegmentLeft">
                                {`${lblLeg} ${index + 1}: ${item.leg.ShoppingLegOrigStationName} ${lblTo} ${item.leg.ShoppingLegDestStationName}`}
                            </div>
                            <div className="col-8" styleName="legSegmentRight">
                                {shoppingPassengers.toJS().map((passenger, indexPax) => (
                                    <Button
                                        variant="contained"
                                        id={`${item.legId}_pax${indexPax}`}
                                        key={`${item.legId}_${indexPax}`}
                                        onClick={handlePassengerClick(passenger.ShoppingPassengerRefId, indexPax + 1)}
                                        styleName="tabButtons"
                                        color={passengerId === passenger.ShoppingPassengerRefId ? 'primary' : 'default'}
                                    >
                                        {`${lblPassenger} ${indexPax + 1}: ${getPassengerType(passenger.ShoppingPassengerAge, intl)}`}
                                    </Button>
                                ))}
                            </div>
                        </div>
                        {seatReservation && (
                            <SeatPreferences
                                travelSegments={item.leg.ShoppingLegSolutions[0].ShoppingFareTravelSegments}
                                shoppingContext={item.leg.ShoppingLegSolutions[0].ShoppingContext}
                                seatPreferences={item.fare.seatPreferences}
                                optionalPrices={item.fare.optionalPrices}
                                fareCodes={item.fare.fareCodes}
                                passengerId={passengerId}
                                paxLength={shoppingPassengers.size}
                                onChangeReservation={onChangeReservation}
                                onChangeAllSegmentReservation={onChangeAllSegmentReservation}
                                onChangeAllLegReservation={onChangeAllLegReservation}
                                onChangePassengerReservation={onChangePassengerReservation}
                                selectedSeatReservations={selectedSeatReservations}
                                exchangeOrderMode={exchangeOrderMode}
                                selectedPax={selectedPax}
                            />
                        )}
                        {!seatReservation && bikeReservations && (
                            <BikeReservation
                                travelSegments={item.leg.ShoppingLegSolutions[0].ShoppingFareTravelSegments}
                                bikeReservations={bikeReservations.toJS()}
                                passengerId={passengerId}
                                onChangeBikeReservation={onChangeBikeReservation}
                                selectedBikeReservations={selectedBikeReservations}
                            />
                        )}
                    </div>
                </div>
            ))}
            <ExtendedSnackbar
                id="srtReservationsSnackBar"
                open={data?.alertText !== ''}
                message={data?.alertText}
                onClose={handleSnackbarClose}
            />
        </div>
    );
};

ReservationsView.propTypes = {
    intl: PropTypes.object,
    faresSelected: PropTypes.object.isRequired,
    bikeReservations: PropTypes.object,
    seatReservation: PropTypes.bool,
    shoppingPassengers: PropTypes.object.isRequired,
    selectedSeatReservations: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    selectedBikeReservations: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    onPassengerClick: PropTypes.func,
    onChangeReservation: PropTypes.func,
    onChangeAllSegmentReservation: PropTypes.func,
    onChangeAllLegReservation: PropTypes.func,
    onChangePassengerReservation: PropTypes.func,
    onChangeBikeReservation: PropTypes.func,
    passengerId: PropTypes.string,
    exchangeOrderMode: PropTypes.bool,
};

ReservationsView.defautProps = {
    selectedSeatReservations: null,
    selectedBikeReservations: null,
    bikeReservations: [],
    seatReservation: true,
    exchangeOrderMode: false,
};

export { ReservationsView as ReservationsViewAlias };

export default injectIntl(ReservationsView);
