import React, { useEffect } from 'react';
import StickyBox from 'react-sticky-box';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ReactSelectWrapped from '../ReactSelectWrapped/ReactSelectWrapped';
import messages from './messagesSubNavbar';

import './subNavbar.css';
import inlineStyles from './styles';

const SubNavbar = ({
    heading,
    accounts,
    children,
    intl: { formatMessage },
    selectedAccount,
    onAccountChange,
    disabled,
    addOrderMode = false,
    exchangeOrderMode = false,
    configBasedAuth = false,
}) => {
    useEffect(() => {
        const handleOneAccount = () => {
            if (!selectedAccount?.text && accounts?.length === 1) {
                onAccountChange({ value: accounts[0].text });
            }
        };
        handleOneAccount();
    }, [accounts, selectedAccount, onAccountChange]);

    const handleAccountChange = ({ value }) => {
        const account = accounts.find((item) => item.text === value) || {};
        const accountText = account.text.replace(/^value:/i, '');
        onAccountChange(accountText, account.value);
    };

    let inputAccounts = null;
    if (!configBasedAuth && selectedAccount) {
        const selectedAccountText = selectedAccount && (typeof selectedAccount === 'string'
            ? selectedAccount
            : selectedAccount.text);

        inputAccounts = (
            <ReactSelectWrapped
                fullWidth
                id="srtAccounts"
                style={inlineStyles.accountsInput}
                value={
                    selectedAccountText && typeof selectedAccountText !== 'object'
                        ? { value: selectedAccountText, label: selectedAccountText }
                        : null
                }
                onChange={handleAccountChange}
                label={formatMessage(messages.lblAccount)}
                placeholder={formatMessage(messages.lblSelectAccount)}
                disabled={!accounts || disabled}
                options={accounts ? accounts.map((item) => ({
                    value: item.text,
                    label: item.text,
                })) : [{
                    value: selectedAccountText,
                    label: selectedAccountText,
                }]}
                fastFilter
                windowing
                controlPropsStyle={inlineStyles.reactSelectWrappedControl}
            />
        );
    }

    const content = (
        <div className="container-fluid" style={inlineStyles.subnavbar}>
            <div className={addOrderMode || exchangeOrderMode ? '' : 'container'} stylename="subNavbarContainer">
                <AppBar position="static" color="default" elevation={0}>
                    <Toolbar disableGutters style={inlineStyles.subnavbar}>
                        <div>
                            <span stylename="title">{heading}</span>
                        </div>
                        <div style={inlineStyles.accountToolbar}>
                            {inputAccounts}
                        </div>
                        {!addOrderMode && !exchangeOrderMode && children}
                    </Toolbar>
                </AppBar>
            </div>
        </div>
    );

    const navBar = addOrderMode || exchangeOrderMode ? content
        : (
            <StickyBox style={inlineStyles.stickybar}>
                {content}
            </StickyBox>
        );

    return navBar;
};

SubNavbar.propTypes = {
    heading: PropTypes.node.isRequired,
    accounts: PropTypes.array,
    children: PropTypes.node,
    intl: PropTypes.object,
    selectedAccount: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    onAccountChange: PropTypes.func,
    disabled: PropTypes.bool,
    addOrderMode: PropTypes.bool,
    exchangeOrderMode: PropTypes.bool,
    configBasedAuth: PropTypes.bool,
};

export { SubNavbar as SubNavbarAlias };
export default injectIntl(SubNavbar);
