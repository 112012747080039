import React from 'react';
import PropTypes from 'prop-types';

export default function ValueContainer(props) {
    return (
        <div className={props.selectProps.classes.valueContainer}>
            {props.children}
        </div>
    );
}

ValueContainer.propTypes = {
    selectProps: PropTypes.object,
    children: PropTypes.node,
};
