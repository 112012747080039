import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import messages from '../messagesShoppingSearch';
import '../shoppingSearch.css';
import { TRIP_TYPE_SEASON } from '../../util';
import Discounts from './Discounts';
import styles from './styles';

const StyledDiv = styled('div')(() => ({
    ...styles.rowBottomAlign,
}));
function DiscountCard({
    searchQuery,
    promoCodeLabel,
    validationCodeLabel,
    onChangeField,
    onChangeDiscountNumber,
    onChangeDiscountPassenger,
    onChangeDiscountProgram,
    onChangeRailcardId,
    handleAddDiscount,
    handleRemoveDiscount,
    onChangeFareQualifier,
}) {
    const isNotDuplicate = (value, index, arrayObj) => (
        !value?.railcardId
        // a number of value 0 is false if converted to a boolean. any other value is true
        || value?.passenger === undefined
        || value?.passenger === null
        // if the item has a different index to another item in the array
        // with the same railcard id, it's a duplicate
        || index === arrayObj.findIndex((item) => (
            item?.passenger === value?.passenger
            && item?.railcardId === value?.railcardId
        ))
    );

    const filteredDiscounts = searchQuery.discounts.filter(isNotDuplicate);

    useEffect(() => {
        filteredDiscounts.forEach((item, index) => {
            onChangeDiscountPassenger(item.passenger, index);
            if (!item.changed) {
                onChangeDiscountProgram(item.discountProgram, index);
                onChangeRailcardId(item.railcardId, index);
                onChangeDiscountNumber(item.discountNumber, index);
                onChangeFareQualifier(!item.changed ? true : item.changed, index);
            }
        });
    }, [filteredDiscounts, onChangeDiscountPassenger, onChangeDiscountProgram, onChangeRailcardId, onChangeDiscountNumber, onChangeFareQualifier]);

    return (
        <div className="col-12 col-sm-6" styleName="discountsColumn-paddingAdjust">
            <div styleName="contentContainer discountsContainer">
                <h2><FormattedMessage {...messages.lblDiscounts} /></h2>
                {searchQuery.tripType !== TRIP_TYPE_SEASON && (
                    <div className="row">
                        <StyledDiv className="col-6">
                            <TextField
                                id="srtPromoCodeIdentifier"
                                name="promoCode"
                                label={promoCodeLabel}
                                fullWidth
                                onChange={onChangeField}
                                value={searchQuery.promoCode}
                                variant="standard"
                            />
                        </StyledDiv>
                        <StyledDiv className="col-6">
                            <TextField
                                id="srtValidationCodeIdentifier"
                                name="validationCode"
                                label={validationCodeLabel}
                                fullWidth
                                onChange={onChangeField}
                                value={searchQuery.validationCode}
                                variant="standard"
                            />
                        </StyledDiv>
                    </div>
                )}
                <div>
                    {filteredDiscounts.map((item, index) => (
                        <Discounts
                            key={`discounts${index}`}
                            index={index}
                            errors={searchQuery.errors}
                            journey={searchQuery.journeyDetails}
                            disabled={searchQuery.disable}
                            railCards={searchQuery.railCards}
                            availableSuppliers={searchQuery.availableSuppliers}
                            discountProgram={item.discountProgram}
                            railcardId={item.railcardId}
                            passenger={item.passenger}
                            discountId={index}
                            totalDiscounts={filteredDiscounts.length}
                            maxNumDiscountRows={searchQuery.maxNumDiscountRows}
                            onAddDiscount={handleAddDiscount}
                            onRemoveDiscount={() => handleRemoveDiscount(index)}
                            onChangeDiscountProgram={({ value }) => {
                                onChangeDiscountProgram(value || null, index);
                                onChangeFareQualifier(!item.changed ? true : item.changed, index);
                            }}
                            onChangeRailcardId={({ target: { value } }) => {
                                onChangeRailcardId(value, index);
                                onChangeFareQualifier(!item.changed ? true : item.changed, index);
                            }}
                            onChangePassenger={({ target: { value } }) => {
                                onChangeDiscountPassenger(value, index);
                                onChangeFareQualifier(!item.changed ? true : item.changed, index);
                            }}
                            paxAge={searchQuery.paxAge}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

DiscountCard.propTypes = {
    searchQuery: PropTypes.object.isRequired,
    promoCodeLabel: PropTypes.string.isRequired,
    onChangeField: PropTypes.func.isRequired,
    onChangeDiscountProgram: PropTypes.func.isRequired,
    onChangeDiscountNumber: PropTypes.func.isRequired,
    onChangeRailcardId: PropTypes.func.isRequired,
    onChangeDiscountPassenger: PropTypes.func.isRequired,
    onChangeFareQualifier: PropTypes.func.isRequired,
    validationCodeLabel: PropTypes.string.isRequired,
    handleAddDiscount: PropTypes.func.isRequired,
    handleRemoveDiscount: PropTypes.func,
};

export { DiscountCard as DiscountCardAlias };

export default injectIntl(DiscountCard);
