import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import styles from './styles';

const DateSelector = (props) => {
    const {
        id,
        dayMapName,
        monthMapName,
        yearMapName,
        labels,
        showFullDate,
        displayYears,
        values,
        errors,
    } = props;

    const renderDays = (month, year) => {
        const daysInMonth = new Date(year, month, 0).getDate();
        const render = [];
        for (let day = 1; day <= daysInMonth; ++day) {
            render.push(<MenuItem key={day} id={`${id}Day${day}`} value={`${day}`}>{day}</MenuItem>);
        }
        return render;
    };

    const handleChangeYear = ({ target: { value } }) => {
        props.onChange({ [yearMapName]: value });
    };

    const handleChangeMonth = ({ target: { value } }) => {
        props.onChange({ [monthMapName]: value });
    };

    const handleChangeDay = ({ target: { value } }) => {
        props.onChange({ [dayMapName]: value });
    };

    const column = (showFullDate) ? 'col-sm-4' : 'col-sm-6';
    const currentMonth = values[monthMapName];
    const currentYear = values[yearMapName];
    const currentDay = values[dayMapName];

    return (
        <div className="row" style={styles.row}>
            <div className={`col-12 ${column}`}>
                <TextField
                    id={`${id}Month`}
                    name={monthMapName}
                    label={labels.fieldLabel}
                    InputLabelProps={{ shrink: true }}
                    placeholder={labels.month}
                    fullWidth
                    onChange={handleChangeMonth}
                    error={!!errors[monthMapName]}
                    helperText={errors[monthMapName]}
                    value={currentMonth}
                    select
                    variant="standard"
                >
                    <MenuItem id={`${id}Month1`} value="1">1</MenuItem>
                    <MenuItem id={`${id}Month2`} value="2">2</MenuItem>
                    <MenuItem id={`${id}Month3`} value="3">3</MenuItem>
                    <MenuItem id={`${id}Month4`} value="4">4</MenuItem>
                    <MenuItem id={`${id}Month5`} value="5">5</MenuItem>
                    <MenuItem id={`${id}Month6`} value="6">6</MenuItem>
                    <MenuItem id={`${id}Month7`} value="7">7</MenuItem>
                    <MenuItem id={`${id}Month8`} value="8">8</MenuItem>
                    <MenuItem id={`${id}Month9`} value="9">9</MenuItem>
                    <MenuItem id={`${id}Month10`} value="10">10</MenuItem>
                    <MenuItem id={`${id}Month11`} value="11">11</MenuItem>
                    <MenuItem id={`${id}Month12`} value="12">12</MenuItem>
                </TextField>
            </div>
            {
                showFullDate
                    ? (
                        <div className={`col-12 ${column}`}>
                            <TextField
                                id={`${id}Day`}
                                name={dayMapName}
                                label="  "
                                InputLabelProps={{ shrink: true }}
                                placeholder={labels.day}
                                fullWidth
                                onChange={handleChangeDay}
                                error={!!errors[dayMapName]}
                                helperText={errors[dayMapName]}
                                value={currentDay}
                                select
                                variant="standard"
                            >
                                {renderDays(currentMonth, currentYear)}
                            </TextField>
                        </div>
                    )
                    : null
            }
            <div className={`col-12 ${column}`}>
                <TextField
                    id={`${id}Year`}
                    name={yearMapName}
                    label="  "
                    InputLabelProps={{ shrink: true }}
                    placeholder={labels.year}
                    fullWidth
                    onChange={handleChangeYear}
                    error={!!errors[yearMapName]}
                    helperText={errors[yearMapName]}
                    value={currentYear}
                    select
                    variant="standard"
                >
                    {displayYears.map((item, index) => (
                        <MenuItem
                            id={`${id}Year_${item}`}
                            key={index}
                            value={`${item}`}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        </div>
    );
};

DateSelector.propTypes = {
    id: PropTypes.string,
    showFullDate: PropTypes.bool,
    dayMapName: PropTypes.string,
    monthMapName: PropTypes.string,
    yearMapName: PropTypes.string,
    displayYears: PropTypes.array,
    errors: PropTypes.object,
    values: PropTypes.object,
    labels: PropTypes.shape({
        day: PropTypes.string,
        month: PropTypes.string,
        year: PropTypes.string,
        fieldLabel: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
};

export { DateSelector as DateSelectorAlias };
export default DateSelector;
