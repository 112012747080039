import styled from 'styled-components';
import Fab from '@mui/material/Fab';

export const StyledFab = styled(Fab)`
    position: fixed;
    bottom: 40px;
    right: -28px;
    transform: rotate(-90deg);
    text-transform: none;
`;

export default StyledFab;
