import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import ListSubheader from '@mui/material/ListSubheader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/system';
import { FormattedMessage } from 'react-intl';

import messages from '../messagesManageAccounts';
import styles from './accountGroupStyles';

const StyledListSubheader = styled(ListSubheader)(() => ({
    ...styles.subHeader,
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    ...styles.label,
}));

function BookingSync(
    {
        bookingSyncAllowed,
        syncUrl,
        partnerName,
        onBookingSyncAllowedChange,
        onSyncUrlChange,
        onPartnerNameChange,
        errors,
    },
) {
    const [localSyncUrl, setLocalSyncUrl] = useState(syncUrl);
    const [localPartnerName, setLocalParterName] = useState(partnerName);
    const [localBookingSyncAllowed, setLocalBookingSyncAllowed] = useState(bookingSyncAllowed);

    const handleSyncUrlChange = (e) => {
        onSyncUrlChange({ target: { name: 'syncUrl', value: e.target.value } });
        setLocalSyncUrl(e.target.value);
    };

    const handlePartnerNameChange = (e) => {
        onPartnerNameChange({ target: { name: 'partnerName', value: e.target.value } });
        setLocalParterName(e.target.value);
    };

    const handleBookingSyncAllowedChange = (e, checked) => {
        onBookingSyncAllowedChange(e, checked);
        setLocalBookingSyncAllowed(checked);
    };

    return (
        <>
            <StyledListSubheader>
                <FormattedMessage {...messages.lblGroupBookingSyncDetails} />
            </StyledListSubheader>
            <StyledFormControlLabel
                control={(
                    <Switch
                        id="srtBookingSyncAllowed"
                        checked={localBookingSyncAllowed}
                        onChange={handleBookingSyncAllowedChange}
                    />
                )}
                label={<FormattedMessage {...messages.lblGroupBookingSyncAllowed} />}
                labelPlacement="start"
            />
            <TextField
                id="srtSyncUrl"
                name="syncUrl"
                label={<FormattedMessage {...messages.lblGroupSyncUrl} />}
                fullWidth
                value={localSyncUrl}
                onChange={handleSyncUrlChange}
                error={!bookingSyncAllowed ? false : !!errors.syncUrl}
                helperText={!bookingSyncAllowed ? null : errors.syncUrl}
                disabled={!localBookingSyncAllowed}
            />

            <TextField
                id="srtAccGroupPartnerName"
                name="accGroupPartnerName"
                label={<FormattedMessage {...messages.lblGroupPartnerName} />}
                fullWidth
                value={localPartnerName}
                onChange={handlePartnerNameChange}
                error={!bookingSyncAllowed ? false : !!errors.partnerName}
                helperText={!bookingSyncAllowed ? null : errors.partnerName}
                disabled={!localBookingSyncAllowed}
            />
        </>
    );
}

BookingSync.propTypes = {
    bookingSyncAllowed: PropTypes.bool,
    syncUrl: PropTypes.string,
    partnerName: PropTypes.string,
    onPartnerNameChange: PropTypes.func.isRequired,
    onBookingSyncAllowedChange: PropTypes.func.isRequired,
    onSyncUrlChange: PropTypes.func.isRequired,
    errors: PropTypes.object,
};

export { BookingSync as BookingSyncAlias };
export default BookingSync;
