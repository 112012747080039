/**
 * Created by vmarusic on 7/27/17.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
    lblApproved: {
        id: 'app.shopping.authorizeRefundDialog.approved',
        defaultMessage: 'Approved',
    },
    lblDenied: {
        id: 'app.shopping.authorizeRefundDialog.denied',
        defaultMessage: 'Denied',
    },
    lblTravelSegments: {
        id: 'app.shopping.authorizeRefundDialog.travelSegments',
        defaultMessage: 'Travel Segments',
    },
    lblCouponsToAuthorize: {
        id: 'app.shopping.authorizeRefundDialog.couponsToAuthorize',
        defaultMessage: 'Number of Coupons To Authorize',
    },
    lblAuthorizedCoupons: {
        id: 'app.shopping.authorizeRefundDialog.authorizedCoupons',
        defaultMessage: 'Number of Previously Authorized Coupons',
    },
    lblTitle: {
        id: 'app.shopping.authorizeRefundDialog.title',
        defaultMessage: 'Authorize Refund',
    },
    lblTo: {
        id: 'app.shopping.authorizeRefundDialog.to',
        defaultMessage: 'to',
    },
    btnClose: {
        id: 'app.shopping.authorizeRefundDialog.close',
        defaultMessage: 'Discard Changes',
    },
    btnSubmit: {
        id: 'app.shopping.authorizeRefundDialog.submit',
        defaultMessage: 'Submit Authorize Refund',
    },
    lblReceiptDate: {
        id: 'app.shopping.authorizeRefundDialog.receiptDate',
        defaultMessage: 'Receipt Date',
    },
    lblCode: {
        id: 'app.shopping.authorizeRefundDialog.code',
        defaultMessage: 'Code',
    },
    lblCouponDescription: {
        id: 'app.shopping.authorizeRefundDialog.couponDescription',
        defaultMessage: 'Coupons Description',
    },
    lblRefundDescription: {
        id: 'app.shopping.authorizeRefundDialog.refundDescription',
        defaultMessage: 'Refund Description',
    },
    lblAmount: {
        id: 'app.shopping.authorizeRefundDialog.amount',
        defaultMessage: 'Amount',
    },
    lblPenalty: {
        id: 'app.shopping.authorizeRefundDialog.penalty',
        defaultMessage: 'Cancellation Penalty',
    },
    lblPenaltyCurrency: {
        id: 'app.shopping.authorizeRefundDialog.penaltyCurrency',
        defaultMessage: 'Cancellation Penalty Currency',
    },
    lblNumberOfCoupons: {
        id: 'app.shopping.authorizeRefundDialog.numberOfCoupons',
        defaultMessage: 'No. of Coupons',
    },
    lblNotes: {
        id: 'app.shopping.authorizeRefundDialog.notes',
        defaultMessage: 'Notes',
    },
    errNoReceiptDate: {
        id: 'app.shopping.authorizeRefundDialog.noReceiptDate',
        defaultMessage: '^Please enter a receipt date.',
    },
    errNoCouponsSelected: {
        id: 'app.shopping.authorizeRefundDialog.noCouponsSelected',
        defaultMessage: 'Please select coupons to authorize.',
    },
    errNoCode: {
        id: 'app.shopping.authorizeRefundDialog.noCode',
        defaultMessage: '^Please enter a code',
    },
    errNoDescription: {
        id: 'app.shopping.authorizeRefundDialog.noDescription',
        defaultMessage: '^Please enter a description',
    },
    errRefundNotNumber: {
        id: 'app.shopping.authorizeRefundDialog.errRefundNotNumber',
        defaultMessage: '^Please enter a valid amount',
    },
    errPenaltyNotNumber: {
        id: 'app.shopping.authorizeRefundDialog.errPenaltyNotNumber',
        defaultMessage: '^Please enter a valid amount',
    },
});
