import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

import messages from '../messagesReservations';
import inlineStyles from '../styles';

const SeatSelector = ({
    formatMessage,
    handleChange,
    TravelSegmentID,
    passengerId,
    other,
    selectedNearbyCoach,
    selectedReservation,
    selectedNearbySeat,
}) => (
    <div className="col-12">
        <div className="row">
            <div className="col-6">
                <TextField
                    id={`srt${TravelSegmentID}_${passengerId}_${other.value}_coach`}
                    placeholder={formatMessage(messages.lblCoach)}
                    onChange={handleChange('nearbyCoach', TravelSegmentID)}
                    value={selectedNearbyCoach}
                    disabled={other.value === 'SPECIFICSEAT' ? false : !selectedReservation[other.value]}
                    fullWidth
                    type="text"
                    inputProps={{
                        style: inlineStyles.content,
                    }}
                    variant="standard"
                />
            </div>
            <div className="col-6">
                <TextField
                    id={`srt${TravelSegmentID}_${passengerId}_${other.value}_seat`}
                    placeholder={formatMessage(messages.lblSeat)}
                    onChange={handleChange('nearbySeat', TravelSegmentID)}
                    value={selectedNearbySeat}
                    disabled={other.value === 'SPECIFICSEAT' ? false : !selectedReservation[other.value]}
                    fullWidth
                    type="number"
                    inputProps={{
                        style: inlineStyles.content,
                    }}
                    variant="standard"
                />
            </div>
        </div>
    </div>
);

SeatSelector.propTypes = {
    formatMessage: PropTypes.func,
    TravelSegmentID: PropTypes.string,
    passengerId: PropTypes.string,
    handleChange: PropTypes.func,
    other: PropTypes.object,
    selectedNearbyCoach: PropTypes.string,
    selectedReservation: PropTypes.object,
    selectedNearbySeat: PropTypes.string,
};

export default SeatSelector;
