import enUSTranslationMessages from './translations/en-US.json';
import enGBTranslationMessages from './translations/en-GB.json';
import frFRTranslationMessages from './translations/fr-FR.json';

if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill');// eslint-disable-line
    require('@formatjs/intl-pluralrules/dist/locale-data/fr'); // eslint-disable-line
    require('@formatjs/intl-pluralrules/dist/locale-data/en'); // eslint-disable-line
}
if (!Intl.RelativeTimeFormat) {
    require('@formatjs/intl-relativetimeformat/polyfill'); // eslint-disable-line
    require('@formatjs/intl-relativetimeformat/dist/locale-data/fr'); // eslint-disable-line
    require('@formatjs/intl-relativetimeformat/dist/locale-data/en'); // eslint-disable-line
}

export default {
    'en-US': enUSTranslationMessages,
    'en-GB': enGBTranslationMessages,
    'fr-FR': frFRTranslationMessages,
};
