export default (size, top, left) => ({
    position: 'absolute',
    padding: size * 0.2,
    top,
    left,
    width: size,
    height: size,
    zIndex: 2,
    borderRadius: '50%',
    lineHeight: 1,
});
