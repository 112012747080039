import { defineMessages } from 'react-intl';

export default defineMessages({
    all: {
        id: 'app.shopping.stationAutoComplete.all',
        defaultMessage: 'All',
    },
    lblNoSuggestions: {
        id: 'app.shopping.stationAutoComplete.noSuggestions',
        defaultMessage: 'No suggestions',
    },
});
