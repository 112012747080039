import { defineMessages } from 'react-intl';

export default defineMessages({
    lblSeatReservations: {
        id: 'app.shopping.createBooking.bookingOptions.seatReservations',
        defaultMessage: 'Seat Reservations',
    },
    lblBikeReservations: {
        id: 'app.shopping.createBooking.bookingOptions.bikeReservations',
        defaultMessage: 'Bike Reservations',
    },
    lblSupplement: {
        id: 'app.shopping.createBooking.bookingOptions.supplement',
        defaultMessage: 'Supplement',
    },
    lblTravelCards: {
        id: 'app.shopping.createBooking.bookingOptions.travelCards',
        defaultMessage: 'Travelcards',
    },
    lblOptions: {
        id: 'app.shopping.createBooking.bookingOptions.lblOptions',
        defaultMessage: 'Options',
    },
    lblType: {
        id: 'app.shopping.createBooking.bookingOptions.type',
        defaultMessage: 'Type',
    },
    lblDescription: {
        id: 'app.shopping.createBooking.bookingOptions.description',
        defaultMessage: 'Description',
    },
    lblPrice: {
        id: 'app.shopping.createBooking.bookingOptions.price',
        defaultMessage: 'Price',
    },
    lblAddOnboardServices: {
        id: 'app.shopping.createBooking.bookingOptions.addOnboardServices',
        defaultMessage: 'Additional services',
    },
    lblIncluded: {
        id: 'app.shopping.createBooking.bookingOptions.included',
        defaultMessage: 'Included',
    },
    lblPlusBusSupplements: {
        id: 'app.shopping.createBooking.bookingOptions.plusBusSupplements',
        defaultMessage: 'Local Bus Service',
    },
    lblPlusBusSupplementsButton: {
        id: 'app.shopping.createBooking.bookingOptions.plusBusSupplementsButton',
        defaultMessage: 'Local Bus Services',
    },

});
