import React, { Component } from 'react';
import { connect, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fromJS, is } from 'immutable';
import { styled } from '@mui/system';
import { ErrorBoundary } from 'react-error-boundary';

import BookingSummary from '../BookingSummary/BookingSummary';
import SuperOrdersView from '../SuperOrdersView/SuperOrdersView';
import OrderNotes from '../OrderNotes/OrderNotes';
import OrderPassengers from '../OrderPassengers/OrderPassengers';
import CustomInformation from '../CustomInformation/CustomInformation';

import { tdoSmsCallingCodesApi } from '../../../../Shopping/apiShopping';
import { updateCardCollectionApi } from '../../../apiBooking';

import { findTDOSMSBookingCode } from '../../../../components/TicketDelivery/utils';
import { getContext } from '../../utils';
import { dateTimeStrToDate } from '../../../../utils/datetimeUtils';
import localeSettings from '../../../../localeSettings';

import { setCancelOrderId } from '../../actionsManageBooking';
import messages from './messagesBookingView';
import styles from './styles';
import ExtendedSnackbar from '../../../../components/ExtendedSnackbar/ExtendedSnackbar';
import { gaSetAccount, gaEvent } from '../../../../utils/googleAnalytics';
import ErrorFallBack from '../ErrorFallBack';

const StyledViewContainerDiv = styled('div')({
    ...styles.viewContainerPadding,
});
class BookingView extends Component {
    static propTypes = {
        accountContexts: PropTypes.array,
        booking: PropTypes.object.isRequired,
        configBasedAuth: PropTypes.bool,
        disableCustomerEmailConfirmation: PropTypes.bool,
        disableCustomerEmailSending: PropTypes.bool,
        enableReturns: PropTypes.bool,
        id: PropTypes.string.isRequired,
        ignoreDisableEmailSendingSuppliers: PropTypes.array,
        intl: PropTypes.object,
        onAmendCTR: PropTypes.func,
        onAllowAnyCardCollect: PropTypes.func,
        showAmendCTR: PropTypes.bool,
        showExchangeOrderButton: PropTypes.bool,
        showVoidOrderButton: PropTypes.bool,
        updateIsProcessingSubmit: PropTypes.func,
        isProcessingAction: PropTypes.bool,
        enableBookingAddFee: PropTypes.bool,
        onSetCancelOrderId: PropTypes.func.isRequired,
    };

    static defaultProps = {
        configBasedAuth: false,
        accountContexts: [],
        disableCustomerEmailSending: false,
        ignoreDisableEmailSendingSuppliers: [],
    };

    initialState = {
        alertText: '',
        bookingWarningMessage: '',
        cancelOrderId: null,
        disableAllowAnyCardCollectButton: {},
        openAddFeeDiscountDialog: false,
        openAddOrderDialog: false,
        openAmendSeatReservationDialog: false,
        openOSDMCancelOrderDialog: false,
        openAuthorizeRefundDialog: null,
        openCancelOrderDialog: null,
        openConfirmedCardDialog: null,
        openConfirmOrderDialog: null,
        openCustomInfoDialog: false,
        openEmailConfirmationDialog: null,
        openExchangeOrderDialog: null,
        openIssueRefundDialog: false,
        openMakePayment: false,
        openNotesDialog: false,
        openPaxInfoDialog: -1,
        openResendTicketDialog: null,
        openReturnCouponsDialog: null,
        openStationInformationDialog: null,
        openSupplierNotesDialog: null,
        openTicketDelivery: null,
        openVoidOrderDialog: null,
        smsCodeOptions: [],
    };

    state = {
        data: fromJS(this.initialState),
    };

    constructor(props) {
        super(props);
        const initialAlertText = props.booking?.BookingWarningMessages?.join(' ') || '';
        this.state = {
            data: fromJS({
                ...this.initialState,
                bookingWarningMessage: initialAlertText,
            }),
        };
    }

    componentDidMount() {
        if (!this.props.booking?.BookingAccountName) {
            this.handleNoAccountError();
        }

        if (this.props.booking?.BookingBillingSummary?.some((element) => (!element.BookingBillingDesc))) {
            this.handleMissingDescriptionError();
        }

        if (this.props.booking?.BookingOrders && this.props.booking?.BookingOrders.length > 0) {
            const smsTdoCode = findTDOSMSBookingCode(this.props.booking.BookingOrders[0].BookingOrderTDOData.BookingTDOs);
            if (smsTdoCode) {
                this.getTdoSmsCallingCodes(smsTdoCode);
            }
        }
        gaSetAccount(getContext(this.props.booking.queryItems));
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.booking?.BookingWarningMessages?.length) {
            const newMessage = nextProps.booking.BookingWarningMessages.join(' ');
            if (!prevState.data.equals(prevState.data.set('bookingWarningMessage', newMessage))) {
                return {
                    data: prevState.data.set('bookingWarningMessage', ''),
                };
            }
        }
        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        // deep comparison of props and Immutable.js data
        return !shallowEqual(this.props, nextProps) || !is(this.state.data, nextState.data);
    }

    getTdoSmsCallingCodes = (tdoCode) => {
        tdoSmsCallingCodesApi(
            tdoCode,
            (response) => {
                this.setState((state) => ({
                    data: state.data.merge({
                        alertText: response.errorResponse.message,
                        isFetching: false,
                    }),
                }));
            },
            (response) => {
                const smsCodeOptions = response.successResponse.data.codes;
                this.setState((state) => ({
                    data: state.data.merge({
                        smsCodeOptions,
                    }),
                }));
            },
        );
    };

    handleStateUpdate = (key, defaultValue) => (value) => {
        const finalValue = defaultValue !== undefined ? defaultValue : value;
        this.setState((state) => ({ data: state.data.set(key, fromJS(finalValue)) }));
    }

    // Dialog Handlers
    handleOpenVoidOrderDialog = (orderId, voidInfo) => {
        const { intl: { formatMessage } } = this.props;
        let { data } = this.state;
        gaEvent('voidOrder');
        if (this.voidIsExpired(voidInfo)) {
            data = data.merge({ alertText: formatMessage(messages.btnVoidOrderError) });
        } else {
            data = data.merge({ openVoidOrderDialog: orderId });
        }
        this.setState({ data });
    };

    handleSupplierNotesDialogDisplay = (orderId) => {
        gaEvent(orderId ? 'supplierNotesOpen' : 'supplierNotesClose');
        this.handleStateUpdate('openSupplierNotesDialog', orderId)();
    }

    handleNotesDialogDisplay = (value) => {
        gaEvent(value ? 'addNotesOpen' : 'addNotesClose');
        this.handleStateUpdate('openNotesDialog', value)();
    }

    handleOpenAddFeeDiscountDialog = () => {
        gaEvent('AddFeeDiscountOpen');
        this.handleStateUpdate('openAddFeeDiscountDialog', true)();
    }

    handleCloseAddFeeDiscountDialog = () => {
        gaEvent('AddFeeDiscountClose');
        this.handleStateUpdate('openAddFeeDiscountDialog', false)();
    }

    handleOpenAmendSeatReservationDialog = (orderId) => {
        gaEvent('AmendSeatReservationOpen');
        this.handleStateUpdate('openAmendSeatReservationDialog', orderId)();
    }

    handleCloseAmendSeatReservationDialog = () => {
        gaEvent('AmendSeatReservationClose');
        this.handleStateUpdate('openAmendSeatReservationDialog', false)();
    }

    handleAddOrderDialog = (isOpen) => {
        gaEvent(isOpen ? 'addOrderOpenDialog' : 'addOrderCloseDialog');
        this.handleStateUpdate('openAddOrderDialog', isOpen)();
    }

    handleDialogSnackbarError = (alertText) => {
        this.setState((state) => ({ data: state.data.merge({ alertText }) }));
    };

    handleCloseCancelOrderDialog = (refundPossible, isSendTicketPossible) => {
        this.handleStateUpdate('openCancelOrderDialog', null)();
        this.handleStateUpdate('openIssueRefundDialog',
            (refundPossible || isSendTicketPossible) && 'cancelOrder')();
    }

    handleCloseVoidOrderDialog = (refundPossible) => {
        this.handleStateUpdate('openVoidOrderDialog', null)();
        this.handleStateUpdate('openIssueRefundDialog', refundPossible && 'voidOrder')();
    }
    // END Dialog Handlers

    handleAllowAnyCardCollect = (orderId) => {
        gaEvent('updateBookingRecordAllowAnyCardCollect');
        const {
            intl: { formatMessage },
            booking,
            onAllowAnyCardCollect,
        } = this.props;

        const bookingData = {
            ...booking.queryItems,
            srtBookingOrderId: orderId,
        };

        updateCardCollectionApi(
            bookingData,
            (response) => {
                gaEvent('updateBookingRecordAllowAnyCardCollectError', response.errorResponse.errorMessage);
                this.setState((state) => ({
                    data: state.data.merge({
                        alertText: formatMessage(messages.anyCardCollectError, { error: response.errorResponse.errorMessage }),
                        isFetching: false,
                    }),
                }));
            },
            () => {
                this.setState((state) => ({
                    data: state.data.merge({
                        alertText: formatMessage(messages.anyCardCollectHasBeenAdded),
                        isFetching: false,
                    }),
                }));

                this.setState((state) => ({
                    data: state.data.mergeIn(['disableAllowAnyCardCollectButton'], { [orderId]: true }),
                }));

                onAllowAnyCardCollect(booking.queryItems);
            },
        );
    };

    handleSnackBarClose = () => {
        this.setState((state) => ({
            data: state.data.merge({ alertText: '' }),
        }));
    };

    voidIsExpired = (voidInfo) => {
        const { intl: { formatMessage } } = this.props;

        const dateFormat = formatMessage(localeSettings.dateFormat);
        const timeFormat = formatMessage(localeSettings.timeFormat);

        return voidInfo && voidInfo.BookingOrderVoidExpirationDate
            && dateTimeStrToDate(voidInfo.BookingOrderVoidExpirationDate, dateFormat, timeFormat, 0) < new Date();
    };

    handleNoAccountError = () => {
        const { configBasedAuth, intl: { formatMessage } } = this.props;
        if (!configBasedAuth) {
            this.setState((state) => ({ data: state.data.merge({ alertText: formatMessage(messages.noAccountError) }) }));
        }
    };

    handleMissingDescriptionError = () => {
        const { intl: { formatMessage } } = this.props;

        this.setState((state) => ({ data: state.data.set('alertText', formatMessage(messages.noDescriptionError)) }));
    };

    handleOpenCancelOrderDialog = (orderId) => {
        const { onSetCancelOrderId } = this.props;
        gaEvent('cancelOrder');
        onSetCancelOrderId(orderId);
        this.handleStateUpdate('openCancelOrderDialog')(orderId);
    };

    handleOpenOSDMCancelOrderDialog = () => {
        this.handleStateUpdate('openOSDMCancelOrderDialog', true)();
    };

    handleCloseOSDMCancelOrderDialog = () => {
        this.handleStateUpdate('openOSDMCancelOrderDialog', false)();
    };

    handleMakePaymentWarning = (warningMesssage) => {
        this.setState((state) => ({ data: state.data.set('alertText', warningMesssage) }));
    };

    render() {
        const {
            accountContexts,
            booking,
            configBasedAuth,
            disableCustomerEmailConfirmation,
            disableCustomerEmailSending,
            enableReturns,
            id,
            ignoreDisableEmailSendingSuppliers,
            onAmendCTR,
            showAmendCTR,
            showExchangeOrderButton,
            showVoidOrderButton,
            updateIsProcessingSubmit,
            isProcessingAction,
            enableBookingAddFee,
        } = this.props;
        const { data } = this.state;

        const bookingAccountContext = booking ? getContext(booking.queryItems) : '';
        const orderAccount = configBasedAuth ? accountContexts[0] : {
            value: bookingAccountContext,
            text: booking && booking.BookingAccountName
                ? booking.BookingAccountName : '',
        };
        const alertText = data.get('alertText');

        return (
            <StyledViewContainerDiv id={id} className="container">
                <ErrorBoundary
                    fallbackRender={(props) => (
                        <ErrorFallBack
                            {...props}
                            messageComponent={<FormattedMessage {...messages.lblBookingSummary} />}
                        />
                    )}
                >
                    <BookingSummary
                        booking={booking}
                        bookingViewData={data}
                        handleCloseAddFeeDiscountDialog={this.handleCloseAddFeeDiscountDialog}
                        handleCloseAddOrderDialog={() => this.handleAddOrderDialog(false)}
                        handleCloseIssueRefundDialog={this.handleStateUpdate('openIssueRefundDialog', false)}
                        handleCloseMakePaymentDialog={this.handleStateUpdate('openMakePayment', false)}
                        handleOpenAddFeeDiscountDialog={this.handleOpenAddFeeDiscountDialog}
                        handleOpenAddOrderDialog={() => this.handleAddOrderDialog(true)}
                        handleOpenIssueRefundDialog={this.handleStateUpdate('openIssueRefundDialog', true)}
                        handleOpenMakePaymentDialog={this.handleStateUpdate('openMakePayment', true)}
                        orderAccount={orderAccount}
                        enableBookingAddFee={enableBookingAddFee}
                        handleMakePaymentWarning={this.handleMakePaymentWarning}
                    />
                </ErrorBoundary>
                <ErrorBoundary
                    fallbackRender={(props) => (
                        <ErrorFallBack
                            {...props}
                            messageComponent={<FormattedMessage {...messages.lblPassengers} />}
                        />
                    )}
                >
                    <OrderPassengers
                        key={booking?.BookingRecordLoc}
                        handleClosePassengerInfoDialog={this.handleStateUpdate('openPaxInfoDialog', -1)}
                        handleOpenPassengerInfoDialog={this.handleStateUpdate('openPaxInfoDialog')}
                        handleDialogSnackbarError={this.handleDialogSnackbarError}
                        openPaxInfoDialog={data.get('openPaxInfoDialog')}
                        passengers={booking?.BookingPassengers}
                        booking={booking}
                        updateIsProcessingSubmit={updateIsProcessingSubmit}
                        isProcessingAction={isProcessingAction}
                    />
                </ErrorBoundary>
                <ErrorBoundary
                    fallbackRender={(props) => (
                        <ErrorFallBack
                            {...props}
                            messageComponent={<FormattedMessage {...messages.lblOrderDetailsTitle} />}
                        />
                    )}
                >
                    <SuperOrdersView
                        booking={booking}
                        bookingViewData={data}
                        disableCustomerEmailConfirmation={disableCustomerEmailConfirmation}
                        disableCustomerEmailSending={disableCustomerEmailSending}
                        enableReturns={enableReturns}
                        handleAllowAnyCardCollect={this.handleAllowAnyCardCollect}
                        handleCloseAuthorizeRefundDialog={this.handleStateUpdate('authorizeRefundDialog', null)}
                        handleCloseOSDMCancelOrderDialog={this.handleCloseOSDMCancelOrderDialog}
                        handleOpenOSDMCancelOrderDialog={this.handleOpenOSDMCancelOrderDialog}
                        handleCloseCancelOrderDialog={this.handleCloseCancelOrderDialog}
                        handleCloseConfirmedCardDialog={this.handleStateUpdate('openConfirmedCardDialog', null)}
                        handleCloseConfirmOrderDialog={this.handleStateUpdate('openConfirmOrderDialog', null)}
                        handleCloseEmailConfirmationDialog={this.handleStateUpdate('openEmailConfirmationDialog', null)}
                        handleCloseExchangeOrderDialog={this.handleStateUpdate('openExchangeOrderDialog', null)}
                        handleCloseResendTicketDialog={this.handleStateUpdate('openResendTicketDialog', null)}
                        handleCloseReturnCouponsDialog={this.handleStateUpdate('openReturnCouponsDialog', null)}
                        handleCloseStationInfoDialog={this.handleStateUpdate('openStationInformationDialog', null)}
                        handleCloseTicketDelivery={this.handleStateUpdate('openTicketDelivery', null)}
                        handleCloseTrackingInfoDialog={this.handleStateUpdate('openTrackingInfoDialog', null)}
                        handleCloseVoidOrderDialog={this.handleCloseVoidOrderDialog}
                        handleOpenAuthorizeRefundDialog={this.handleStateUpdate('authorizeRefundDialog')}
                        handleOpenCancelOrderDialog={this.handleOpenCancelOrderDialog}
                        handleOpenConfirmedCardDialog={this.handleStateUpdate('openConfirmedCardDialog')}
                        handleOpenConfirmOrderDialog={this.handleStateUpdate('openConfirmOrderDialog')}
                        handleOpenEmailConfirmationDialog={this.handleStateUpdate('openEmailConfirmationDialog')}
                        handleOpenExchangeOrderDialog={this.handleStateUpdate('openExchangeOrderDialog')}
                        handleOpenResendTicketDialog={this.handleStateUpdate('openResendTicketDialog')}
                        handleOpenReturnCouponsDialog={this.handleStateUpdate('openReturnCouponsDialog')}
                        handleOpenStationInfoDialog={this.handleStateUpdate('openStationInformationDialog')}
                        handleOpenTicketDelivery={this.handleStateUpdate('openTicketDelivery')}
                        handleOpenTrackingInfoDialog={this.handleStateUpdate('openTrackingInfoDialog')}
                        handleOpenVoidOrderDialog={this.handleOpenVoidOrderDialog}
                        handleSupplierNotesDialogDisplay={this.handleSupplierNotesDialogDisplay}
                        handleCloseAmendSeatReservationDialog={this.handleCloseAmendSeatReservationDialog}
                        handleOpenAmendSeatReservationDialog={this.handleOpenAmendSeatReservationDialog}
                        ignoreDisableEmailSendingSuppliers={ignoreDisableEmailSendingSuppliers}
                        onAmendCTR={onAmendCTR}
                        orderAccount={orderAccount}
                        showAmendCTR={showAmendCTR}
                        showExchangeOrderButton={showExchangeOrderButton}
                        showVoidOrderButton={showVoidOrderButton}
                        voidIsExpired={this.voidIsExpired}
                        isProcessingAction={isProcessingAction}
                    />
                </ErrorBoundary>
                <ErrorBoundary
                    fallbackRender={(props) => (
                        <ErrorFallBack
                            {...props}
                            messageComponent={<FormattedMessage {...messages.lblCustomInformation} />}
                        />
                    )}
                >
                    <CustomInformation
                        booking={booking}
                        handleCloseCustomInfoDialog={this.handleStateUpdate('openCustomInfoDialog', false)}
                        handleOpenCustomInfoDialog={this.handleStateUpdate('openCustomInfoDialog', true)}
                        openCustomInfoDialog={data.get('openCustomInfoDialog')}
                    />
                </ErrorBoundary>
                <ErrorBoundary
                    fallbackRender={(props) => (
                        <ErrorFallBack
                            {...props}
                            messageComponent={<FormattedMessage {...messages.lblNotes} />}
                        />
                    )}
                >
                    <OrderNotes
                        booking={booking}
                        handleNotesDialogDisplay={this.handleNotesDialogDisplay}
                        openNotesDialog={data.get('openNotesDialog')}
                    />
                </ErrorBoundary>
                <ExtendedSnackbar
                    id="srtBookingSnackBar"
                    open={(alertText !== '') && data.get('bookingWarningMessage') === ''}
                    message={alertText}
                    onClose={this.handleSnackBarClose}
                />
                {data.get('bookingWarningMessage') !== ''
                    && (
                        <ExtendedSnackbar
                            id="srtBookingWarningMessage"
                            open={data.get('bookingWarningMessage') !== ''}
                            message={data.get('bookingWarningMessage')}
                            onClose={() => {
                                this.setState((state) => ({ data: state.data.merge({ bookingWarningMessage: '' }) }));
                            }}
                        />
                    )}
            </StyledViewContainerDiv>
        );
    }
}

const mapDispatchToProps = {
    onSetCancelOrderId: setCancelOrderId,
};

export { BookingView as BookingViewAlias };

export default connect(null, mapDispatchToProps)(injectIntl(BookingView));
