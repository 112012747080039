import { whiteSmoke2, midGray2 } from '../../colors';

export default {
    indicator: {
        marginTop: '15%',
        marginLeft: '49%',
    },
};

export const withStylesTheme = (theme) => ({
    tabRoot: {
        padding: '6px 24px',
        [theme.breakpoints.up('md')]: {
            minWidth: 0,
            fontSize: 14,
        },
    },
    tabsRoot: {
        backgroundColor: whiteSmoke2,
        fontSize: 14,
    },
    tabsIndicator: {
        backgroundColor: midGray2,
    },
});
