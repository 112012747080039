export default {
    confirmationCardOptionsTitle: {
        display: 'flex',
        alignItems: 'center',
        height: 48,
    },
    usePaymentCardButton: {
        height: 48,
    },
    confirmationNotRequired: {
        marginTop: 5,
        marginBottom: 5,
    },
    messageContainer: {
        marginTop: 5,
    },
};
