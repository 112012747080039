import { defaultFareColorClassSchema } from '../../../colors';

export default {
    row: {
        display: 'flex',
        '&:not(:last-child)': {
            marginBottom: 25,
        },
    },
    icon: {
        color: defaultFareColorClassSchema.orange,
    },
    content: {
        marginLeft: 20,
        marginTop: 5,
    },
    summary: {
        fontWeight: 600,
    },
    description: {
        marginLeft: 30,
        marginTop: 5,
    },
    alertRow: {
        padding: '10px 20px',
        margin: '10px 0',
        backgroundColor: '#E5E5E5',
        border: '1px solid #000',
        borderRadius: '10px',
    },
    warning: {
        display: 'flex',
        alignItems: 'center',
    },
    alertHeading: {
        textDecoration: 'underline',
        fontWeight: 'bold',
    },
};
