import styled from 'styled-components';
import TableCell from '@mui/material/TableCell';
import FareAmenities from '../../../Shopping/components/Fare/FareAmenities';

export const InfoBox = styled.div`
    margin-top: 30px;
    padding: 15px;
    background-color: #fff3cd;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
`;

export const StyledTablCell = styled(TableCell)`
    border: 0;
    padding-left: 5px;
`;

export const StyledFareAmenities = styled(FareAmenities)`
    svg {
        color: black;
        cursor: pointer;
    }
`;

export const SegmentRowTableCell = styled(TableCell)`
    border-bottom: ${({ $splitFareGroup }) => ($splitFareGroup ? '0' : '1px solid rgba(224, 224, 224, 1)')};
`;
