import React from 'react';
import PropTypes from 'prop-types';
import inlineStyles from './styles';

const SeatDetails = ({ seats, segment, seatAttributesOpen }) => {
    const seatList = seats.split(', ');
    const isSingleSeat = seatList.length === 1;

    return (
        <div style={inlineStyles.seatDetailsWrapper}>
            {seatList.map((seat, index) => (
                <div key={index} style={{ ...inlineStyles.seatContainer, marginTop: isSingleSeat ? 10 : 5 }}>
                    <span style={inlineStyles.seatTitle}>{seat.trim()}</span>
                    {seatAttributesOpen && segment?.BookingLegTravelSegmentSeatDetails?.length ? (
                        <div style={inlineStyles.seatAttributes}>
                            {segment.BookingLegTravelSegmentSeatDetails.map((seatDetail, detailIndex) => {
                                const seatNumber = seat.match(/\d+$/)[0];
                                if (seatDetail.BookingLegTravelSegmentSeatNumber === seatNumber) {
                                    return (
                                        <div key={detailIndex} style={inlineStyles.seatDetail}>
                                            <p style={inlineStyles.seatDetailText}>{seatDetail.BookingLegTravelSegmentSeatAttributes.join(', ')}</p>
                                            <p style={inlineStyles.seatDetailText}>{seatDetail.BookingLegTravelSegmentSeatDirection || '-'}</p>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    ) : <div />}
                </div>
            ))}
        </div>
    );
};

SeatDetails.propTypes = {
    seats: PropTypes.string.isRequired,
    segment: PropTypes.object,
    seatAttributesOpen: PropTypes.bool,
};

export default SeatDetails;
