import colors from '../colors';

export default {
    h2: {
        fontSize: 24,
        fontWeight: 400,
        marginBottom: 0,
    },
    flatButton: {
        color: `${colors.linkColor} !important`,
        '&:hover': {
            backgroundColor: `${colors.backgroundColor} !important`,
        },
        float: 'right',
        fontWeight: `${300} !important`,
        fontSize: '14px !important',
        textTransform: 'none !important',
    },

    flatButtonLabel: {
        fontWeight: 300,
        fontSize: 14,
        textTransform: 'none',
    },
    refreshContainer: {
        marginLeft: '45%',
    },
    refresh: {
        position: 'relative',
    },
    paper: {
        paddingTop: 16,
        marginTop: 36,
        paddingBottom: 36,
    },
};
