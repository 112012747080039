import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getBasePath } from './utils';

import App from './App/App';

import Booking from './Booking/Booking';

import Shopping from './Shopping/Shopping';
import Admin from './Admin/Admin';
import Login from './Login/Login';
import SecureRoute from './Login/components/SecureRoute';

export default (
    <>
        <Route path={getBasePath()}>
            {({ match, location }) => (
                <App location={location}>
                    <Switch>
                        <Route exact path={[match.path, `${match.path}login`]} component={Login} />
                        <Route>
                            {(props) => (
                                <SecureRoute {...props}>
                                    <Switch>
                                        <Route path={`${props.match.path}shopping`} component={Shopping} {...props} />
                                        <Route path={`${props.match.path}booking`} component={Booking} {...props} />
                                        <Route path={`${props.match.path}admin`} component={Admin} {...props} />
                                    </Switch>
                                </SecureRoute>
                            )}
                        </Route>
                    </Switch>
                </App>
            )}
        </Route>
    </>
);
