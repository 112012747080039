import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { getAnalytics } from '../api';
import { gaInitialize, gaPageView } from '../utils/googleAnalytics';

function App({ children, location }) {
    useEffect(() => {
        getAnalytics().then((data) => { gaInitialize(data.successResponse.data); });
    }, []);

    useEffect(() => {
        gaPageView(location);
    }, [location]);

    return children;
}

App.propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
};

export default App;
