import { black } from '../../../../colors';

export default {
    buttons: {
        margin: 5,
    },
    dialogPaper: {
        overflowY: 'hidden',
        fontSize: 12,
        color: black,
        maxWidth: 768,
    },
    content: {
        fontSize: 12,
        height: 'auto',
    },
};
