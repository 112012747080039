export default {
    accountGroup: {
        minHeight: 600,
    },
    subHeader: {
        marginTop: 30,
    },
    helpText: {
        marginTop: 2,
        lineHeight: 1,
        fontWeight: '200',
        fontStyle: 'italic',
    },
    fareFilter: {
        marginTop: 20,
    },
    label: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '10px 0px',
    },
    headerFare: {
        fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
        fontWeight: 400,
        color: '#333',
    },
};
