import validate from 'validate.js';

import { validateIsDebitCardHelper } from '../../../utils';
import { creditCardNumberRegex, creditCardNumberLength } from '../../../utils/finance';
import { confirmationOptions } from '../../utils';
import { cardNumberMockedRegex, postalCodeRegex } from '../../../utils/regex';

import messages from './messagesMakePayment';

validate.validators.isValidExpiryDate = (value, options, key, attributes) => {
    const dateTemp = new Date(Date.now());
    const checkDay = validate.isEmpty(attributes.expiryDateDay) || Number(attributes.expiryDateDay) < dateTemp.getDate();

    return Number(attributes.expiryDateYear) === dateTemp.getFullYear() && Number(attributes.expiryDateMonth) < dateTemp.getMonth() && checkDay
        ? options.message
        : null;
};

validate.validators.isValidStartDate = (value, options, key, attributes) => {
    const dateTemp = new Date(Date.now());
    return Number(attributes.startDateYear) > dateTemp.getFullYear() || (Number(attributes.startDateMonth) - 1) > dateTemp.getMonth()
        ? options.message
        : null;
};

const cardNumberConstraints = (formatMessage) => (attribute, attributes) => (
    // check if its a mock number
    (!cardNumberMockedRegex.test(attribute)) ? {
        presence: {
            message: formatMessage(messages.errPaymentCardNumberEmpty),
            allowEmpty: false,
        },
        format: {
            pattern: creditCardNumberRegex(attributes.method),
            message: formatMessage(messages.errPaymentCardNumberInvalid),
        },
        length: {
            ...creditCardNumberLength(attributes.method),
            wrongLength: formatMessage(messages.errPaymentCardNumberInvalid),
        },
    }
        : null
);

export const paymentConstraints = (formatMessage, isTermsOfServiceVisible, isBraintree) => ({
    method: {
        presence: { message: formatMessage(messages.errPaymentMethodEmpty), allowEmpty: false },
    },
    cardName: {
        presence: { message: formatMessage(messages.errPaymentCardNameEmpty), allowEmpty: false },
    },
    // TODO we should utilize isValidCreditCard method from ChargeCardUtility to validate.
    cardNumber: !isBraintree && cardNumberConstraints(formatMessage),
    expiryDateMonth: !isBraintree && {
        presence: {
            message: formatMessage(messages.errDateInvalid),
            allowEmpty: false,
        },
        isValidExpiryDate: {
            message: formatMessage(messages.errDateInvalid),
        },
    },
    expiryDateYear: !isBraintree && {
        presence: {
            message: formatMessage(messages.errYearInvalid),
            allowEmpty: false,
        },
        isValidExpiryDate: {
            message: formatMessage(messages.errDateInvalid),
        },
    },
    startDateMonth: (month, attr) => {
        if (validateIsDebitCardHelper(attr.method)) {
            return {
                presence: {
                    message: formatMessage(messages.errDateInvalid),
                    allowEmpty: false,
                },
                isValidStartDate: {
                    message: formatMessage(messages.errDateInvalid),
                },
            };
        }
        return null;
    },
    startDateYear: (month, attr) => {
        if (validateIsDebitCardHelper(attr.method)) {
            return {
                presence: {
                    message: formatMessage(messages.errYearInvalid),
                    allowEmpty: false,
                },
                isValidStartDate: {
                    message: formatMessage(messages.errYearInvalid),
                },
            };
        }
        return null;
    },
    issueNumber: (number, attr) => (
        (validateIsDebitCardHelper(attr.method)) ? {
            presence: { message: formatMessage(messages.errPaymentIssueNumber), allowEmpty: false },
        } : null
    ),
    securityCode: !isBraintree && ((code, attr) => {
        const { method } = attr;
        const message = formatMessage(messages.errSecurityCode);
        return ['AX', 'NONE'].includes(method)
            ? { presence: { message, allowEmpty: false }, length: { is: 4, wrongLength: message } }
            : { presence: { message, allowEmpty: false }, length: { is: 3, message } };
    }),
    countryCode: (value, attr) => (
        attr.method !== 'NONE'
            ? { presence: { message: formatMessage(messages.errCountryEmpty), allowEmpty: false } }
            : null
    ),
    address1: (value, attr) => (
        attr.method !== 'NONE'
            ? { presence: { message: formatMessage(messages.errAddressLine1Empty), allowEmpty: false } }
            : null
    ),
    city: (value, attr) => (
        attr.method !== 'NONE'
            ? { presence: { message: formatMessage(messages.errCityEmpty), allowEmpty: false } }
            : null
    ),
    stateOrProvince: (state, attr) => {
        let validator = null;
        const code = attr.countryCode;
        if (attr.method !== 'NONE' && ['CA', 'US'].includes(code)) {
            const message = formatMessage(messages[code === 'CA' ? 'errProvinceEmpty' : 'errStateEmpty']);
            validator = {
                presence: { message, allowEmpty: false },
            };
        }
        return validator;
    },
    postalCode: (postalCode, attr) => {
        const code = attr.countryCode;
        let req;
        if (attr.method !== 'NONE') {
            req = {
                presence: { message: formatMessage(messages.errPostalCodeEmpty), allowEmpty: false },
            };
            switch (code) {
                case 'CA':
                case 'US':
                    req.format = {
                        pattern: postalCodeRegex[code],
                        message: formatMessage(messages.errPostalCodeInvalid),
                    };
                    break;
                default:
                    req.length = {
                        maximum: 15,
                        wrongLength: formatMessage(messages.errPostalCodeInvalid),
                    };
            }
        }
        return req;
    },
    termsOfServiceCheck: (termsOfServiceCheck, attr) => {
        if (isTermsOfServiceVisible(attr.confirmation.type)) {
            return {
                inclusion: {
                    message: formatMessage(messages.errTermsOfService),
                    within: [true],
                },
            };
        }
        return null;
    },
});

export const validateConfirmationHelper = (type, hasEmail, formatMessage) => {
    let constraints = {
        vdEmail: hasEmail ? {
            presence: {
                message: formatMessage(messages.errConfirmationEmail),
                allowEmpty: false,
            },
            email: {
                message: formatMessage(messages.errConfirmationEmail),
            },
        } : null,
    };
    switch (type) {
        case confirmationOptions.creditCard:
        case confirmationOptions.debitCard:
            constraints = Object.assign(constraints, {
                firstName: {
                    presence: {
                        message: formatMessage(messages.errConfirmationFirstName),
                        allowEmpty: false,
                    },
                },
                lastName: {
                    presence: {
                        message: formatMessage(messages.errConfirmationLastName),
                        allowEmpty: false,
                    },
                },
                cardNumber: cardNumberConstraints,
                expiryDateMonth: {
                    presence: {
                        message: formatMessage(messages.errYearInvalid),
                        allowEmpty: false,
                    },
                    isValidExpiryDate: {
                        message: formatMessage(messages.errDateInvalid),
                    },
                },
                expiryDateYear: {
                    presence: {
                        message: formatMessage(messages.errYearInvalid),
                        allowEmpty: false,
                    },
                    isValidExpiryDate: {
                        message: formatMessage(messages.errDateInvalid),
                    },
                },
                sortCode: (type === confirmationOptions.debitCard) ? {
                    presence: formatMessage(messages.errConfirmationSortCode),
                } : null,
            });
            break;
        case confirmationOptions.loyaltyCard:
            constraints = Object.assign(constraints, {
                expiryDateDay: {
                    presence: {
                        message: formatMessage(messages.errDayInvalid),
                        allowEmpty: false,
                    },
                    isValidExpiryDate: {
                        message: formatMessage(messages.errDateInvalid),
                    },
                },
                expiryDateMonth: {
                    presence: {
                        message: formatMessage(messages.errMonthInvalid),
                        allowEmpty: false,
                    },
                    isValidExpiryDate: {
                        message: formatMessage(messages.errDateInvalid),
                    },
                },
                expiryDateYear: {
                    presence: {
                        message: formatMessage(messages.errYearInvalid),
                        allowEmpty: false,
                    },
                    isValidExpiryDate: {
                        message: formatMessage(messages.errDateInvalid),
                    },
                },
                loyaltyCardProgram: {
                    presence: {
                        message: formatMessage(messages.errConfirmationLoyaltyProgram),
                        allowEmpty: false,
                    },
                },
                loyaltyCardIdentifier: {
                    presence: {
                        message: formatMessage(messages.errConfirmationLoyaltyCardIdentifier),
                        allowEmpty: false,
                    },
                },
            });
            break;
        default:
    }
    return constraints;
};
