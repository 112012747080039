import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './components/ValueDocumentCoupons/messages';

export const getContext = (queryItems) => { // eslint-disable-line import/prefer-default-export
    const context = [];
    if (queryItems.srtDistCode) context.push(queryItems.srtDistCode);
    if (queryItems.srtChannelCode) context.push(queryItems.srtChannelCode);
    if (queryItems.srtPOSCode) context.push(queryItems.srtPOSCode);

    if (queryItems.srtAgencyCode) {
        context.push(queryItems.srtAgencyCode);
    } else if (queryItems.srtCompanyCode) {
        context.push('');
    }

    if (queryItems.srtCompanyCode) {
        context.push(queryItems.srtCompanyCode);
    }

    return context.join(':');
};

export const getReleaseStatus = (isReleasable, releasedAt) => {
    if ((isReleasable === undefined || isReleasable === null) && !releasedAt) {
        return isReleasable;
    }

    let releaseStatus = null;
    if (isReleasable && !releasedAt) {
        releaseStatus = messages.lblEligibleForRelease;
    } else if (!isReleasable && !releasedAt) {
        releaseStatus = messages.lblNotEligibleForRelease;
    } else if (releasedAt === 'online') {
        releaseStatus = messages.lblReleasedOnline;
    } else if (releasedAt === 'offline') {
        releaseStatus = messages.lblReleasedOffline;
    }

    return releaseStatus ? <FormattedMessage {...releaseStatus} /> : false;
};

export const checkCurrentTDO = (order) => {
    const { BookingOrderTDOData: { BookingFulfillmentInfo: { BookingOrderTicketDeliveryDetails } } } = order;
    return BookingOrderTicketDeliveryDetails;
};
